import { useLayoutEffect, useState } from "react";
import useAutoservico from "../../../store/autoservico/useAutoservico";
import S from "../Autoservico.module.css";
import LogoConcash from "../../../assets/images/logo-negativo.svg";

export const HeaderAuto = ({estilo, adm}) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const { logout } = useAutoservico();

    return (
      <>
      <div  className={S.MHeaderContainer}>
          {adm && adm !== "" && <img src={adm} width={120} alt="logo" />}
          <img src={LogoConcash} width={138} alt="logo" />
      </div>
    </>
  );
  };