import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Logo from "../../assets/logo.png";
import { ACCESS_KEY, RECAPTCHA_KEY } from "../../site.config";
import api from "../../services/api";
import Swal from "sweetalert2";
import { getCpfCnpj, login, getToken } from "../../services/auth";
import styled, { css } from "styled-components";
import { Form, Container } from "./styles";
import ReCAPTCHA from "react-google-recaptcha";
import PasswordStrengthBar from "react-password-strength-bar";
import { V3TextCustom } from "../../styles/themes/components/input";
import { V3Button } from "../../styles/themes/components/button";
import { V3Text5, V3Title } from "../../styles/themes/components/texts";
import { BsCheck } from "react-icons/bs";
import family from "../../assets/images/novo/family.png";
import ConcashLogo from "../../styles/themes/v3/assets/logo Concash.png";

import * as S from "./esqueci.styles";
import { v3Colors } from "../../styles/themes/v3/colors";

class NovaSenha extends Component {
  constructor(props) {
    super(props);
    const { token } = this.props.match.params.token;
    console.log(this.props.match.params.token);
    this.state = {
      sucesso: false,
      password: "",
      errNumeros: false,
      errMaiusculas: false,
      errMinusculas: false,
      errTamanho: false,
      errSimbolo: false,
      passwordErr: false,
      cpass: "",
      cpassErr: false,
      token: this.props.match.params.token,
      recaptchaResponse: "",
      error: "",
    };
  }

  onChangeRecaptch(value) {
    this.setState({ recaptchaResponse: value });
  }

  handleSignIn = async (e) => {
    console.log("click");
    e.preventDefault();
    const { password, cpass, token, recaptchaResponse } = this.state;
    if (!password) {
      this.setState({ passwordErr: true });
      return null;
    } else if (password != cpass) {
      this.setState({ cpassErr: true });
      return null;
    } else {
      Swal.fire({
        title: "Aguarde ...",
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        const cpf = getCpfCnpj();
        console.log(cpf);
        await api.put("/api/site/1.0/esqueci-senha/nova-senha", {
          password,
          token,
          recaptchaResponse,
          cpf,
        });

        Swal.close();
        this.props.history.push("/app");
      } catch (err) {
        Swal.close();
        Swal.fire("Houve um problema no processamento desta requisição.");
      }
    }
  };

  handlePass = (text) => {
    const { hasNumber, hasUpperCase, hasLowerCase, hasSpecialChar } =
      this.props;

    if (!hasNumber(text)) {
      this.setState({
        errNumeros: true,
        passwordErr: true,
        password: text,
      });
    } else {
      this.setState({
        errNumeros: false,
        passwordErr: false,
        password: text,
      });
    }

    if (!hasUpperCase(text)) {
      this.setState({
        errMaiusculas: true,
        passwordErr: true,
        password: text,
      });
    } else {
      this.setState({
        errMaiusculas: false,
        passwordErr: false,
        password: text,
      });
    }

    if (!hasLowerCase(text)) {
      this.setState({
        errMinusculas: true,
        passwordErr: true,
        password: text,
      });
    } else {
      this.setState({
        errMinusculas: false,
        passwordErr: false,
        password: text,
      });
    }

    if (text.length >= 10) {
      this.setState({
        errTamanho: false,
        passwordErr: false,
        password: text,
      });
    } else {
      this.setState({
        errTamanho: true,
        passwordErr: true,
        password: text,
      });
    }

    if (!hasSpecialChar(text)) {
      this.setState({
        errSimbolo: true,
        passwordErr: true,
        password: text,
      });
    } else {
      this.setState({
        errSimbolo: false,
        passwordErr: false,
        password: text,
      });
    }
  };

  handleCPass = (text) => {
    const { password } = this.props;
    this.setState({ cPass: text });
    if (text !== password) {
      this.setState({ cPassErr: true });
    } else {
      this.setState({ cPassErr: false });
    }
  };

  render() {
    return (
      <>
        {!this.state.mobile ? (
          <>
            <div>
              <S.Container>
                <S.BoxLogin style={{ zIndex: 999 }}>
                  <S.Row>
                    <img src={ConcashLogo} width={162} height={44} />
                  </S.Row>
                  {this.state.sucesso ? (
                    <>
                      <S.RowCenter style={{ marginTop: 30, marginBottom: 10 }}>
                        <div
                          style={{
                            display: "flex",
                            width: 50,
                            height: 50,
                            backgroundColor: v3Colors.primary,
                            borderRadius: "50%",
                            alignItems: "center",
                            justifyContent: "center",
                            color: v3Colors.white,
                          }}
                        >
                          <BsCheck size={40} />
                        </div>
                      </S.RowCenter>
                      <S.RowCenter style={{ marginBottom: 30 }}>
                        <V3Title style={{ textAlign: "center" }}>
                          Conta criada com sucesso!
                        </V3Title>
                      </S.RowCenter>
                      <S.RowCenter style={{ marginBottom: 30 }}>
                        <V3Text5 style={{ color: v3Colors.black }}>
                          Agora é só entrar em sua conta.
                        </V3Text5>
                      </S.RowCenter>
                      <S.RowCenter>
                        <V3Button
                          onClick={() => (window.location.href = "/")}
                          estilo="primary"
                          width={330}
                          text="Entrar"
                        />
                      </S.RowCenter>
                    </>
                  ) : (
                    <>
                      <S.RowCenter style={{ marginTop: 30, marginBottom: 15 }}>
                        <V3Title>Redefina a senha</V3Title>
                      </S.RowCenter>

                      <S.Row style={{ marginTop: 15 }}>
                        <V3TextCustom
                          size="small"
                          label="Senha"
                          onChange={(e) => this.handlePass(e.target.value)}
                          sx={{
                            width: 447,
                          }}
                          value={this.state.password}
                          error={this.state.passwordErr}
                          type="password"
                        />
                      </S.Row>
                      {this.state.senha ||
                        (this.state.passwordErr && (
                          <>
                            <S.Row
                              style={{
                                flexDirection: "column",
                                paddingTop: 10,
                              }}
                            >
                              <V3Text5
                                style={{
                                  fontWeight: "bold",
                                  color: v3Colors.black,
                                }}
                              >
                                Sua senha deve conter:
                              </V3Text5>
                              <V3Text5
                                style={{
                                  color: this.state.errTamanho
                                    ? v3Colors.black
                                    : v3Colors.greenLight,
                                }}
                              >
                                ● No mínimo 10 caracteres
                                {!this.state.errTamanho && (
                                  <BsCheck
                                    color={v3Colors.greenLight}
                                    size={20}
                                  />
                                )}
                              </V3Text5>
                              <V3Text5
                                style={{
                                  color: this.state.errSimbolo
                                    ? v3Colors.black
                                    : v3Colors.greenLight,
                                }}
                              >
                                ● No mínimo 1 símbolo (!@#$%^&*)
                                {!this.state.errSimbolo && (
                                  <BsCheck
                                    color={v3Colors.greenLight}
                                    size={20}
                                  />
                                )}
                              </V3Text5>
                              <V3Text5
                                style={{
                                  color: this.state.errMaiusculas
                                    ? v3Colors.black
                                    : v3Colors.greenLight,
                                }}
                              >
                                ● Letras maiúsculas
                                {!this.state.errMaiusculas && (
                                  <BsCheck
                                    color={v3Colors.greenLight}
                                    size={20}
                                  />
                                )}
                              </V3Text5>
                              <V3Text5
                                style={{
                                  color: this.state.errMinusculas
                                    ? v3Colors.black
                                    : v3Colors.greenLight,
                                }}
                              >
                                ● Letras minúsculas
                                {!this.state.errMinusculas && (
                                  <BsCheck
                                    color={v3Colors.greenLight}
                                    size={20}
                                  />
                                )}
                              </V3Text5>
                              <V3Text5
                                style={{
                                  color: this.state.errNumeros
                                    ? v3Colors.black
                                    : v3Colors.greenLight,
                                }}
                              >
                                ● Números
                                {!this.state.errNumeros && (
                                  <BsCheck
                                    color={v3Colors.greenLight}
                                    size={20}
                                  />
                                )}
                              </V3Text5>
                            </S.Row>
                          </>
                        ))}
                      <S.Row style={{ marginTop: 15, marginBottom: 15 }}>
                        <V3TextCustom
                          sx={{ width: 447 }}
                          label="Confirme a nova senha"
                          onChange={(e) => {
                            this.handleCPass(e.target.value);
                          }}
                          error={this.state.cpassErr}
                          size="small"
                          helperText={
                            this.state.cpassErr &&
                            "Senha não confere. Verifique-a."
                          }
                          type="password"
                          value={this.state.cpass}
                          required
                        />
                      </S.Row>
                      <S.RowCenter style={{ marginBottom: 20 }}>
                        <ReCAPTCHA
                          sitekey={RECAPTCHA_KEY}
                          onChange={(e) => this.onChangeRecaptch(e)}
                          accessKey={ACCESS_KEY}
                        />
                      </S.RowCenter>

                      <S.RowCenter>
                        <V3Button
                          onClick={this.handleSignIn}
                          estilo="primary"
                          width={"100%"}
                          text="Redefinir a senha"
                        />
                      </S.RowCenter>
                    </>
                  )}
                </S.BoxLogin>
                <S.BoxSideRight>
                  <img src={family} style={{ position: "fixed", top: 0 }} />
                </S.BoxSideRight>
              </S.Container>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default withRouter(NovaSenha);
