import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Logo from "../../assets/logo.png";
import { RECAPTCHA_KEY } from '../../site.config';
import api from "../../services/api";
import Swal from 'sweetalert2';
import { login, setCpfCnpj, tipoPessoa } from "../../services/auth";
import styled, { css } from 'styled-components';
import { Form, Container, Footer } from "./styles";
import ReCAPTCHA from 'react-google-recaptcha';
import SolicitacaoEsqueciSenha from "../../components/SolicitacaoEsqueciSenha";
import CriarConta from "../../components/CriarConta";
import { cpfCnpj2 } from "../../mask/cpfcnpj";


class UpdatePassword extends Component {
  state = {
    cpfCnpj: "",
    password: "",
    displayModal: "none",
    error: ""
  };
  
  handleAbreModal = e => {
      this.setState({ displayModal: "block" });
  }


  handleSignIn = async e => {
    e.preventDefault();
    const { cpfCnpj, password} = this.state;
    if (!cpfCnpj || !password ) {
      Swal.fire ('Preencha CPF/CNPJ,senha e o recaptcha para continuar!');
    } else {

      Swal.fire ({
         title: 'Aguarde ...',
         onBeforeOpen: () => {
           Swal.showLoading ()
         }
      });

      try {
        const regex = /\d/g;
        let newCpfCnpj = cpfCnpj.match(regex);
        newCpfCnpj = newCpfCnpj.join("");
        console.log(newCpfCnpj);        
        const response = await api.post("/api/auth/signin-client", { cpfCnpj:newCpfCnpj, password }); 
        console.log(response.data.accessToken);
        console.log(response.data);
        login(response.data.accessToken);
        setCpfCnpj(newCpfCnpj);
        /*cpfCnpj(newCpfCnpj);*/
        Swal.close();
        this.props.history.push("/app");
      } catch (err) {
        Swal.close();
        Swal.fire ('Houve um problema com o login, verifique suas credenciais.');
      }
    }
  };

  render() {
    return (
      <>
          <Container>
            <Form onSubmit={this.handleSignIn}>
              <img src={Logo} alt="ConCash logo" />
              {this.state.error && <p>{this.state.error}</p>}
              <input
                type="cpfCnpj"
                placeholder="CPF/CNPJ"
                maxLength={18}
                value={this.state.cpfCnpj}
                onChange={e => this.setState({ cpfCnpj: cpfCnpj2(e.target.value) })}
              />
              <input
                type="password"
                placeholder="Senha"                
                onChange={e => this.setState({ password: e.target.value })}
              />
              <button type="submit">Entrar</button>
              <SolicitacaoEsqueciSenha  display={this.state.displayModal} />
              <Footer>
                <CriarConta display={this.state.displayModal}></CriarConta>
              </Footer>
              
            </Form>
          </Container>

      </>
    );
  }
}

export default withRouter(UpdatePassword);
