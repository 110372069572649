import React, { useState } from "react";
import { Button } from 'react-bootstrap';
import Swal from "sweetalert2";
import {
  BoxHead,
  BoxHeadMobile,
  TextHeadBadgeDesktop,
  TextHeadBadgeMobile,
} from "./styles";
import { useSelector } from "react-redux";
import "./style.css";
import { colors } from "../../../../../styles/colors";
import { BadgeEtapaDesktop, BadgeEtapaMobile } from "../BadgeEtapa";
import { V3Button } from "../../../../../styles/themes/components/button";
import api from "../../../../../services/api";
export function StatusBarCotaDesktop({ list, atual, cota, documento }) {
  const { token, cpfCnpj, tokenConsort } = useSelector(state => state.otpCnp);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      tokenConsort: tokenConsort
    }
  }
  const { data } = api.get(`api/concash/1.0/consorcio-grupo-cota/visualizar-proposta?cpfCnpj=${documento}&grupo=${cota.grupo}&cota=${cota.cota}`, config)
  const razao = ((atual - 1) * 100) / list.length;
  return (
    <>
      {(cota.valorProposta > 1 && !cota.linkSelfie && !cota.linkTermo && (cota.etapas[0].status=='Finished') &&cota.etapas[1].status=='Running') ?
        <div>
          <div style={{ padding: "0 20px", textAlign: "-webkit-center", marginTop: 10, marginBottom: 20 }}>
            <V3Button
              onClick={() => window.open(`https://api.whatsapp.com/send/?phone=551140405686&text=Ol%C3%A1%2C+quero+vender+minha+cota+de+consorcio+de+numero+${cota.cota}++do+grupo+${cota.grupo}%2C+da+CNP+Cons%C3%B3rcio+para+a++Concash%E2%80%8B`, "_blank")}
              estilo="primary"
              width={"50%"}
             
              text=  {"Aceitar proposta de " + cota.valorProposta.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            />
          </div>
          <div style={{ padding: "0 20px", textAlign: "-webkit-center" }}>
            Proposta valida por apenas 24 horas
          </div>
        </div> :
        cota.valorProposta == 1 ?
          <div>
            <div style={{ padding: "0 20px", textAlign: "center", marginTop: 10, marginBottom: 20 }}>
              Opa, ainda estamos gerando sua oferta
            </div>
            <div style={{ padding: "0 20px", textAlign: "-webkit-center" }}>
              <V3Button
                onClick={() => sweetAlert()}
                estilo="primary"
                width={"50%"}
                text="Vender outra cota"
              />
            </div>
          </div> :
          cota.valorProposta == 0 ?
            <div>
              <div style={{ padding: "0 20px", textAlign: "center", marginTop: 10, marginBottom: 20 }}>
                Infelizmente não temos oferta para esta cota
              </div>
              <div style={{ padding: "0 20px", textAlign: "-webkit-center" }}>
                <V3Button
                  onClick={() => sweetAlert()}
                  estilo="primary"
                  width={"50%"}
                  text="Vender outra cota"
                />
              </div>
            </div> :
            cota.valorProposta == -1 ?
              <div>
                <div style={{ padding: "0 20px", textAlign: "center", marginTop: 10, marginBottom: 20 }}>
                  Infelizmente sua proposta expirou, voce pode reenviar a mesma e geraremos um novo valor
                </div>
                <div style={{ padding: "0 20px", textAlign: "-webkit-center" }}>
                  <V3Button
                    onClick={() => reenviarCota(cota.grupo, cota.cota, documento)}
                    estilo="primary"
                    width={"50%"}
                    text="Reenviar cota para venda"
                  />
                </div>
              </div> :
              (cota.linkTermo || cota.linkSelfie) ?
                <div>
                  <div style={{ padding: "0 20px", textAlign: "center" }}>
                    Existem ações aguardando você no processo de venda da sua cota:
                  </div>
                  <div style={{ padding: "0 20px", textAlign: "-webkit-center" }}>
                    {cota.linkTermo ?
                      <V3Button
                      onClick={()=> window.open(cota.linkTermo, "_blank")}
                        estilo="primary"
                        width={"25%"}
                        text="Assinar Contrato"
                      /> : <></>
                    }
                    <br></br>
                    {cota.linkSelfie ?
                      <V3Button
                      onClick={()=> window.open(cota.linkSelfie, "_blank")}
                        estilo="primary"
                        width={"25%"}
                        text="Enviar Selfie"
                      /> : <></>
                    }
                  </div>
                </div> : <br></br>
      }
      <BoxHead >
        <TextHeadBadgeDesktop><b style={{ marginRight: 10 }}>Grupo: </b>{cota.grupo}</TextHeadBadgeDesktop>
        <TextHeadBadgeDesktop><b style={{ marginRight: 10 }}>Cota: </b>{cota.cota}</TextHeadBadgeDesktop>
      </BoxHead>

      <div style={{ padding: "0 20px" }}>
        <div
          style={{ minHeight: 180, backgroundColor: "#ececec", borderRadius: 25 }}
        >
          <div
            style={{
              height: 48,
              backgroundColor: "#FFF",
              borderRadius: 25,
              border: "1px solid #CECECE",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            }}
          />
          <div
            style={{
              height: 40,
              backgroundColor: colors.darkGreen,
              top: -45,
              position: "relative",
              margin: "0 5px",
              borderRadius: 25,
              width: `${razao}%`,
            }}
          />
          <span
            style={{
              position: "relative",
              top: -78,
              marginLeft: 18,
              color: razao >= 28 ? "#FFF" : "#000",
            }}
          >
            Progresso: {atual - 1} de {list.length} etapas
          </span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              gap: 20,
              position: "relative",
              top: -50,
              padding: "0 15px",
            }}
          >
            {list.map((i, p) => {
              return (
                <BadgeEtapaDesktop
                  descricao={i.descricao}
                  concluido={i.concluido}
                  active={atual - 1 === p}
                  status={i.status}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export function StatusBarCotaMobile({ list, atual, cota, documento }) {
  const [size, setSize] = useState(0);
  const [complete, setComplete] = useState(0);
  const razao = ((atual - 1) * 100) / list.length;

  return (
    <>
         {(cota.valorProposta > 1 && !cota.linkSelfie && !cota.linkTermo && (cota.etapas[0].status=='Finished') &&cota.etapas[1].status=='Running')?
        <div>
          <div style={{ padding: "0 20px", textAlign: "center" }}>
            Temos uma excelente oferta para você
            <div>        <b style={{ marginRight: 10 }}>{!cota.valorProposta ? "Em Processamento" : cota.valorProposta.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b></div>
          </div>
          <div style={{ padding: "0 20px", textAlign: "-webkit-center" }}>
            <V3Button
              onClick={() => window.open(`https://api.whatsapp.com/send/?phone=551140405686&text=Ol%C3%A1%2C+quero+vender+minha+cota+de+consorcio+de+numero+${cota.cota}++do+grupo+${cota.grupo}%2C+da+CNP+Cons%C3%B3rcio+para+a++Concash%E2%80%8B`, "_blank")}
              estilo="primary"
              width={"50%"}
              text="Aceito"
            />
          </div>
          <div style={{ padding: "0 20px", textAlign: "-webkit-center" }}>
            Proposta valida por apenas 24 horas
          </div>
        </div> :
        cota.valorProposta == 1 ?
          <div>
            <div style={{ padding: "0 10px", textAlign: "center", marginTop: 10, marginBottom: 20 }}>
              Opa, ainda estamos gerando sua oferta
            </div>
            <div style={{ padding: "0 20px", textAlign: "-webkit-center", marginTop: 10, marginBottom: 20  }}>
              <V3Button
                onClick={() => sweetAlert()}
                estilo="primary"
                width={"60%"}
                text="Vender outra cota"
              />
            </div>
          </div> :
          cota.valorProposta == 0 ?
            <div>
              <div style={{ padding: "0 20px", textAlign: "center", marginTop: 10, marginBottom: 20 }}>
                Infelizmente não temos oferta para esta cota
              </div>
              <div style={{ padding: "0 20px", textAlign: "-webkit-center" }}>
                <V3Button
                  onClick={() => sweetAlert()}
                  estilo="primary"
                  width={"60%"}
                  text="Vender outra cota"
                />
              </div>
            </div> :
            cota.valorProposta == -1 ?
              <div>
                <div style={{ padding: "0 20px", textAlign: "center", marginTop: 10, marginBottom: 20 }}>
                  Infelizmente sua proposta expirou, voce pode reenviar a mesma e geraremos um novo valor
                </div>
                <div style={{ padding: "0 20px", textAlign: "-webkit-center" }}>
                  <V3Button
                    onClick={() => reenviarCota(cota.grupo, cota.cota, documento)}
                    estilo="primary"
                    width={"70%"}
                    text="Reenviar cota para venda"
                  />
                </div>
              </div> :
              (cota.linkTermo || cota.linkSelfie) ?
                <div>
                  <div style={{ padding: "0 20px", textAlign: "center", marginTop: 10, marginBottom: 20 }}>
                    Existem ações aguardando você no processo de venda da sua cota:
                  </div>
                  <div style={{ padding: "0 20px", textAlign: "-webkit-center" }}>
                    {cota.linkTermo ?
                    <V3Button
                      onClick={()=> window.open(cota.linkTermo, "_blank")}
                        estilo="primary"
                        width={"25%"}
                        text="Assinar Contrato"
                      /> : <></>
                    }
                    <br></br>
                    {cota.linkSelfie ?
                      <V3Button
                      onClick={()=> window.open(cota.linkSelfie, "_blank")}
                        estilo="primary"
                        width={"25%"}
                        text="Enviar Selfie"
                      /> : <></>
                    }
                  </div>
                  <br/>
                </div> : <br></br>
      }
      <div style={{ padding: "0px 10px 30px 10px", marginTop: 0 }}>
        <BoxHeadMobile >
          <TextHeadBadgeMobile><b style={{ marginRight: 10 }}>Grupo: </b>{cota.grupo}</TextHeadBadgeMobile>
          <TextHeadBadgeMobile><b style={{ marginRight: 10 }}>Cota: </b>{cota.cota}</TextHeadBadgeMobile>
        </BoxHeadMobile>
        <div style={{ height: 20 }} />
        <span
          style={{
            marginLeft: 18,
            color: colors.darkGreen,
            fontWeight: "bold",
          }}
        >
          Progresso: {atual - 1} de {list.length} etapas
        </span>
        <div
          style={{
            height: "75%",
            backgroundColor: "#ececec",
            borderRadius: 10,
            paddingBottom: 10,
          }}
        >
          <div
            style={{
              height: 18,
              backgroundColor: "#FFF",
              borderRadius: 15,
              border: "1px solid #CECECE",
              boxShadow: "box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            }}
          />
          <div
            style={{
              height: 10,
              backgroundColor: colors.darkGreen,
              top: -14,
              position: "relative",
              margin: "0 5px",
              borderRadius: 15,
              width: `${razao}%`,
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              gap: 10,
            }}
          >
            {list.map((i, p) => {
              return (
                <BadgeEtapaMobile
                  descricao={i.descricao}
                  concluido={i.concluido}
                  active={atual - 1 === p}
                  status={i.status}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

function sweetAlert() {
  (async () => {
  await Swal.fire({
      title: 'Insira os dados da sua carta',
      html:
        '<input style="width:30%" id="swal-input1" type="text" pattern="[0-9]*" placeholder="Grupo" class="swal2-input">' +
        '<input style="width:30%" id="swal-input2" type="text" pattern="[0-9]*" placeholder="Cota" class="swal2-input itemRight">',
      focusConfirm: false,
      confirmButtonText:"Enviar"
    }).then((result) => {
      var grupo = document.getElementById('swal-input1').value;
      var cota = document.getElementById('swal-input2').value
      if (result.isConfirmed) {
          Swal.fire(
              'Enviado!',
              'Em breve você verá a cota enviada na sua lista de negociações. 😉',
              'success'
          )
      }
  })
  })()
}

function reenviarCota(grupo, cota, documento) {
  (async () => {
    Swal.fire(
      'Obrigado!',
      'Você reenviou sua cota para precificação, acompanhe seu e-mail e esse portal!',
      'success'
    )
  })()
}