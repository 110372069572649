import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import api from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import { Link, withRouter } from "react-router-dom";

export class Parcela extends Component {
    constructor(props) {
        super(props);

        const { codigoCota } = this.props;

        this.state = {
            codigoCota: codigoCota,
            parcelas: [],
            error: "",
        };
    }

    async componentDidMount() {
        this.obtemParcelas(this.state.codigoCota);
    }

    async obtemParcelas(codigo) {
        try {
            const response = await api.post(
                "api/concash/1.0/consorcio-grupo-cota-parcela/listar",
                { codigoCota: codigo }
            );
            this.setState({ parcelas: response.data });
        } catch (err) {
            Swal.fire("Houve um problema para obter as parcelas.");
        }
    }

    handleFormOpenComprovante = async (comprovante, e) => {
        e.preventDefault();
        try {
            Swal.fire({
                title: "Aguarde ...",
                onBeforeOpen: () => {
                    Swal.showLoading();
                },
            });

            const response = await api.post(
                "api/concash/1.0/consorcio-grupo-cota-parcela/download",
                {
                    comprovante: comprovante,
                },
                { responseType: "blob" }
            );

            const file = new Blob([response.data], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;

            Swal.close();
        } catch (err) {
            this.setState({
                error: "Houve um problema com o processamento da requisição.",
            });
        }
    };

    handleDeletarParcela = async (codigo, e) => {
        var classe = this;
        Swal.fire({
            title: "Você tem certeza que deseja excluir essa parcela?",
            text: "Após exclusão não será possivel a reversão",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim!",
            cancelButtonText: "Não!",
        }).then((result) => {
            if (result.value) {
                classe.handleDeletarParcelaEfetivar(codigo, e);
            }
        });
    };

    handleDeletarParcelaEfetivar = async (codigo, e) => {
        e.preventDefault();
        if (!codigo) {
            Swal.fire("Código indefinido!");
        } else {
            try {
                let codigo = this.state.codigoCota;
                const response = await api.put(
                    "api/concash/1.0/consorcio-grupo-cota-parcela/excluir",
                    {
                        codigo,
                    }
                );
                this.obtemPropostas(codigo);
                Swal.fire("Parcela excluída com sucesso!");
            } catch (err) {
                Swal.fire("Houve um problema com a exclusão.");
            }
        }
    };

    render() {
        return (
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Parcelas</h4>
                            <p className="card-description"></p>

                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th> Código </th>
                                            <th> Valor </th>
                                            <th> Numero Parcela </th>
                                            <th> Vencimento </th>
                                            <th> Status </th>
                                            <th> Boleto </th>
                                            <th> Comprovante </th>
                                            <th className="text-center">
                                                {" "}
                                                Ação{" "}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.parcelas.map(
                                            (parcela, index) => (
                                                <tr key={index}>
                                                    <td> {parcela.codigo} </td>
                                                    <td> {parcela.valor} </td>
                                                    <td>
                                                        {" "}
                                                        {
                                                            parcela.numero_parcela
                                                        }{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        {
                                                            parcela.vencimento
                                                        }{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        {parcela.situacao}{" "}
                                                    </td>
                                                    <td>
                                                        {parcela.boleto && (
                                                            <button
                                                                type="button"
                                                                onClick={(e) =>
                                                                    this.handleFormOpenComprovante(
                                                                        parcela.boleto,
                                                                        e
                                                                    )
                                                                }
                                                                className="btn btn-primary mr-2"
                                                            >
                                                                Download
                                                            </button>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {parcela.comprovante && (
                                                            <button
                                                                type="button"
                                                                onClick={(e) =>
                                                                    this.handleFormOpenComprovante(
                                                                        parcela.comprovante,
                                                                        e
                                                                    )
                                                                }
                                                                className="btn btn-primary mr-2"
                                                            >
                                                                Download
                                                            </button>
                                                        )}
                                                    </td>

                                                    <td className="text-center">
                                                        <Link
                                                            className="m-3 btn btn-sm btn-danger"
                                                            onClick={(e) =>
                                                                this.handleDeletarParcela(
                                                                    parcela.codigo,
                                                                    e
                                                                )
                                                            }
                                                        >
                                                            Deletar
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Parcela;
