import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";
import { AlteraNome } from '../../actions';
import styled, { css } from 'styled-components';



const BoxInformativo = styled.div`

      text-align:center;
      color: white;
      padding:10px;
      background: #2196f3;
      height: 80px;
      width: 150px;
      margin-top:20px;
      cursor:pointer;
      border-radius: 10px;

`;


export class ComercialProspect extends Component {


  constructor(props){
        super(props);
        this.state = {
          show: false,
          totalLanding: '',
          totalPortal: '',
          totalFaleConosco: '',
        };
  }

  async componentDidMount() {
      this.obtemTotalizadores();
  }


  async  obtemTotalizadores(){
    try {
          const response = await api.post("api/concash/1.0/prospect/total", {});
          this.setState({
            totalLanding:response.data.totalLanding,
            totalFaleConosco:response.data.totalFaleConosco,
            totalPortal:response.data.totalPortal
          });
    } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter a cota."
          });
    }
  }


  handleProspectLanding() {
      this.props.history.push("/comercial/lista-prospect-landing");
  };

  handleProspectFaleConosco() {
      this.props.history.push("/comercial/lista-prospect-fale-conosco");
  };

  handleProspectPortal(){
      this.props.history.push("/comercial/lista-prospect-portal");
  };



  render() {


    return (

      <div>
        <div className="page-header">
          <h3 className="page-title"> Comercial - Prospect </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>
        <div className="row">


          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h3 className="page-title">  </h3>
                <div className="row">

                    <BoxInformativo className="col-md-6" onClick={e => this.handleProspectPortal()}>
                      Contatos Portal<br/>
                      ({this.state.totalPortal})
                    </BoxInformativo>

                    <BoxInformativo className="col-md-5 offset-md-1" onClick={e => this.handleProspectLanding()} >
                      Contatos Landing<br/>
                      ({this.state.totalLanding})
                    </BoxInformativo>

                </div>

                <div className="row">

                    <BoxInformativo className="col-md-6" onClick={e => this.handleProspectFaleConosco()}>
                      Contatos Fale Conosco<br/>
                      ({this.state.totalFaleConosco})
                    </BoxInformativo>

                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default withRouter(ComercialProspect);
