import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import Checkbox from "../../lib/Checkbox";
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";



export class BasicElements extends Component {



  constructor(props){

    super(props);

    this.state = {
          funcionalidade: '',
          url: '',
          codigoFuncionalidade: '',
          grupo: '',
          labelForm: '',
          labelButton: '',
          acoes: [],
          funcionalidadesAcoes: [],
          marcados:[],
          error: "",
          action: ""
    };
  }


  async componentDidMount() {

        if(this.props[0].location.state !== undefined){
            const func  = this.props[0].location.state.funcionalidade
            this.setState({funcionalidade: func,url: func.url,grupo: func.grupoFuncionalidade.codigo},this.obtemFuncionalidadesAcoes);
            this.setState({labelForm: "Editar ",labelButton: "Configurar"});
        }else{
            this.setState({labelForm: "Adicionar ",labelButton: "Configurar"});
        }



  }

  async obtemFuncionalidadesAcoes() {
    try {
          const func      = this.state.funcionalidade;
          const response2 = await api.post("api/sistema/1.0/funcionalidade-acao/listar", {  codigo: func.codigo,status: 'ATIVO'   });
          var fAcoes = [];
          for(var i=0; i < response2.data.length;i++){
              fAcoes[i] = response2.data[i].acao.acao;
          }
          this.setState({ funcionalidadeAcoes: fAcoes});

          try {
                const response = await api.post("api/sistema/1.0/acao/listar", {});
                this.setState({ acoes: response.data});
          } catch (err) {
                this.setState({
                  error:
                    "Houve um problema para obter os grupos de ações."
                });
          }

    } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter as configurações."
          });
    }
  }

  handleCheckboxChange = () => {

  }

  handleForm = async e => {
    e.preventDefault();
    const { funcionalidade,
            url,
            grupo,
            action} = this.state;
    if (!funcionalidade
        || !url
        || !grupo
        || !action) {
      this.setState({ error: "Preencha os campos obrigatórios para continuar!" });
    } else {
      try {

          const response = await api.put("api/sistema/1.0/funcionalidade/configurar", {
              url,funcionalidade,grupo
            });
          this.setState({ error: "Edição realizada com sucesso!" });

      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o processamento da requisição."
        });
      }
    }
  };

  render() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> {this.state.labelForm} Funcionalidade : {this.state.funcionalidade.funcionalidade} </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>

        <div className="row">

          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"></h4>
                {this.state.error && <p className="card-description">{this.state.error}</p>}
                <form className="forms-sample" onSubmit={this.handleForm}>
                {this.state.acoes.map((ac,index) => (
                    <Checkbox label={ac.acao} marcados={this.state.funcionalidadeAcoes} valor={ac.codigo} funcionalidade={this.state.funcionalidade.codigo} key={index}/>
                ))}

                <div className="row">
                    <div className="col-md-12">
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
         </div>
       </div>
    )
  }
}

export default withRouter(BasicElements);
