import React, { Component, Fragment } from "react";
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2'
import api from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import { Link, withRouter } from "react-router-dom";
import moment from 'moment';





export class CotaArquivo extends Component {



  constructor(props) {

    super(props);

    const { codigoCota, codigoCliente, transferido }     = this.props;

    this.state = {
      codigoCota: codigoCota,
      codigoCliente: codigoCliente,
      transferido: transferido,
      arquivos:[],
      nome:"",
      proximoVencimento:"",
      dataAssembleia:"",
      observacao:"",
      error: ""
    };


  }

  handleChangeProximoVencimento = date => {
    this.setState({
      startDate: date
    });
    this.setState({
      proximoVencimento: moment(date).format("DD/MM/yyyy")
    });
  };

  handleChangeDataAssembleia = date => {
    this.setState({
      startDate: date
    });
    this.setState({
      dataAssembleia: moment(date).format("DD/MM/yyyy")
    });
  };


  async componentDidMount() {
        this.obtemArquivos(this.state.codigoCota);
  }


  async  obtemArquivos(codigo){
      try {
            const response = await api.post("api/concash/1.0/consorcio-grupo-cota-arquivo/listar", {codigoCota:codigo});
            this.setState({ arquivos: response.data});
      } catch (err) {
            this.setState({
              error:
                "Houve um problema para obter os arquivos."
            });
      }
  }


  handleFormArquivo = async e => {
    e.preventDefault();
    const { nome,
            observacao,
            arquivo } = this.state;
    if (!arquivo) {
      this.setState({ error: "Preencha os campos obrigatórios para continuar!" });
    } else {
      try {

            Swal.fire ({
               title: 'Aguarde ...',
               onBeforeOpen: () => {
                 Swal.showLoading ()
               }
            });

            let codigo        = this.state.codigoCota;
            const formData    = new FormData();
            formData.append('file',arquivo);
            formData.append('codigoCota',codigo);
            formData.append('nome',nome);
            formData.append('observacao',observacao);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            const response  = await api.post("api/concash/1.0/consorcio-grupo-cota-arquivo/adicionar",formData,config);
            this.obtemArquivos(codigo);

            Swal.close();

      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o processamento da requisição."
        });
      }
    }
  };

  handleFormOpenArquivo = async (arquivo,e) => {
    e.preventDefault();
      try {

            Swal.fire ({
               title: 'Aguarde ...',
               onBeforeOpen: () => {
                 Swal.showLoading ()
               }
            });

            const response  = await api.post("api/concash/1.0/consorcio-grupo-cota-arquivo/download",{
              arquivo:arquivo
            },{ responseType: 'blob' });


            const file      = new Blob([response.data], { type: "application/pdf" });
            const fileURL   = URL.createObjectURL(file);
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;

            Swal.close();

      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o processamento da requisição."
        });
      }
  };

  handleFormTransferencia = async e => {


    e.preventDefault();
    const { codigoCota,
            proximoVencimento,
            dataAssembleia
          } = this.state;
    if (!proximoVencimento
        || !dataAssembleia) {
        Swal.fire ('Preencha os campos obrigatórios para continuar!');
      this.setState({ error: "Preencha os campos obrigatórios para continuar!" });
    } else {
      try {

            Swal.fire ({
               title: 'Aguarde ...',
               onBeforeOpen: () => {
                 Swal.showLoading ()
               }
            });

            const response = await api.put("api/concash/1.0/consorcio-grupo-cota/transferir", {
              codigo:codigoCota,proximoVencimento,dataAssembleia
            });

            Swal.close();
            Swal.fire ('Transferência de cota finalizada com sucesso.');

      } catch (err) {
        Swal.fire ('Houve um problema com o processamento da requisição.');
      }
    }
  };



  handleDeletarArquivo = async (codigo,e) => {

    var classe = this;
    Swal.fire({
       title: 'Você tem certeza que deseja excluir esse arquivo?',
       text: 'Após exclusão não será possivel a reversão',
       icon: 'warning',
       showCancelButton: true,
       confirmButtonColor: '#3085d6',
       cancelButtonColor: '#d33',
       confirmButtonText: 'Sim!',
       cancelButtonText: 'Não!'
    }).then((result) => {
       if(result.value){
         classe.handleDeletarArquivoEfetivar(codigo,e);
      }
    })
  };


  handleDeletarArquivoEfetivar = async (codigo,e) => {

      e.preventDefault();
      if (!codigo) {
        Swal.fire ('Código indefinido!');
      } else {
        try {
           let codigo        = this.state.codigoCota;
           const response    = await api.put("api/concash/1.0/consorcio-grupo-cota-arquivo/excluir", {
                codigo
           });
           this.obtemArquivos(codigo);
           Swal.fire ('Arquivo excluído com sucesso!');

        } catch (err) {
          Swal.fire ('Houve um problema com a exclusão.');
        }
      }
  };



  render() {

    return (

      <div className="row">

        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Transferência</h4>
              <p className="card-description"></p>

              {this.state.transferido != "SIM" &&
                    <form className="forms-sample" onSubmit={this.handleFormArquivo}>


                    <div className="row">

                      <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                          <div className="card-body">


                                <Form.Group className="row">
                                  <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Titulo</label>
                                  <div className="col-sm-9">
                                  <Form.Control type="text" className="form-control" id="nome" value={this.state.nome} onChange={e => this.setState({ nome: e.target.value })}  size="sm"/>
                                  </div>
                                </Form.Group>

                                <Form.Group className="row">
                                  <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Observação</label>
                                  <div className="col-sm-9">
                                  <Form.Control type="text" className="form-control" id="observacao" value={this.state.observacao} onChange={e => this.setState({ observacao: e.target.value })} size="sm"/>
                                  </div>
                                </Form.Group>


                                <Form.Group className="row">
                                  <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Arquivo</label>
                                  <div className="col-sm-9">
                                  <Form.Control type="file" className="form-control" id="file" onChange={e =>  this.setState({arquivo:e.target.files[0]}) }   size="sm"/>
                                  </div>
                                </Form.Group>

                                <div className="text-right">
                                  <button type="submit" className="btn btn-primary mr-2">Adicionar</button>
                                </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </form>
              }

              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th> Data  </th>
                      <th> Titulo  </th>
                      <th> Observação </th>
                      <th> Arquivo </th>
                      <th className="text-center"> Ação </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.arquivos.map((arquivo,index) => (
                    <tr key={index}>
                      <td> {arquivo.dataIni} </td>
                      <td> {arquivo.nome} </td>
                      <td> {arquivo.observacao} </td>
                      <td>
                           {arquivo.arquivo &&
                               <button type="button" onClick={e =>this.handleFormOpenArquivo(arquivo.arquivo,e)} className="btn btn-primary mr-2">Download</button>
                           }
                     </td>
                      <td className="text-center">
                          {this.state.transferido != "SIM" &&
                          <Link
                            className="m-3 btn btn-sm btn-danger"
                            onClick={e =>this.handleDeletarArquivo(arquivo.codigo,e)}
                          >
                            Deletar
                          </Link>
                          }
                      </td>
                    </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {this.state.transferido != "SIM" &&

              <form className="forms-sample" autocomplete="off"  onSubmit={this.handleFormTransferencia}>


              <div className="row" style={{marginTop:"30px"}}>

                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">


                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Próximo Vencimento</label>
                            <div className="col-sm-9">
                                <DatePicker className="form-control w-100"  value={this.state.proximoVencimento} id="proximoVencimento"
                                  dateFormat="dd/MM/yyyy"
                                  onChange={this.handleChangeProximoVencimento}
                                  locale='pt-BR'
                                />
                            </div>
                          </Form.Group>

                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Data Assémbleia</label>
                            <div className="col-sm-9">
                                <DatePicker className="form-control w-100"  value={this.state.dataAssembleia} id="proximoVencimento"
                                  dateFormat="dd/MM/yyyy"
                                  onChange={this.handleChangeDataAssembleia}
                                  locale='pt-BR'
                                />
                            </div>
                          </Form.Group>

                          <div className="text-right">
                            <button type="submit" className="btn btn-primary mr-2">Finalizar Transferência</button>
                          </div>
                    </div>
                  </div>
                </div>

              </div>

              </form>

              }

            </div>
          </div>
        </div>
      </div>

    )
  }
}


export default CotaArquivo;
