import BoasVindas from "../../../assets/boas_vindas_verde.svg";
import TriagemBoasVindas from "../../../assets/triagem_boas_vindas_verde.svg";
import EnvioDeProposta from "../../../assets/envio_de_proposta_verde.svg";
import CadastroDoCliente from "../../../assets/cadastro_do_cliente_verde.svg";
import TriagemDocumentos from "../../../assets/triagem_documentos_verde.svg";
import RegistroDeProposta from "../../../assets/registro_de_proposta_verde.svg";
import TriagemTransferencia from "../../../assets/triagem_transferencia_verde.svg";
import Pagamento from "../../../assets/pagamento_verde.svg";
import ConfirmacaoPagamento from "../../../assets/confirmacao_pagamento_verde.svg";
import { IoMdMailUnread } from "react-icons/io";
import { BiTransfer } from "react-icons/bi";
import { BsInfoCircle } from "react-icons/bs";
import { GiTakeMyMoney } from "react-icons/gi";


export const IconeEtapaOtp = (idEtapa) => {
    switch (idEtapa) {
      case "Dados Recebidos":
        return <IoMdMailUnread size={30} />;
      case "Cota Precificada":
        return <IoMdMailUnread size={30} />;
      case "Aguardando Transferência":
        return <IoMdMailUnread size={30} />;
      case "Aguardando Pagamento":
        return <IoMdMailUnread size={30} />;
      case "Pago":
        return <IoMdMailUnread size={30} />;
      case "Nogiciação Finalizada":
        return <IoMdMailUnread size={30} />;
        case "Processo de transferência de titularidade pela Administradora":
          return <BiTransfer size={20} />;
      
          default:
            return <GiTakeMyMoney size={20} />;
    }
  }
export const IconeEtapaOtpMobile = (idEtapa) => {
    switch (idEtapa) {
      case "Dados Recebidos":
        return <IoMdMailUnread size={28} />;
      case "Cota Precificada":
        return <IoMdMailUnread size={28} />;
      case "Aguardando Transferência":
        return <IoMdMailUnread size={28} />;
      case "Aguardando Pagamento":
        return <IoMdMailUnread size={28} />;
      case "Aguardando aceite de oferta":
        return <IoMdMailUnread size={28} />;
      case "Oferta":
        return <IoMdMailUnread size={28} />;
      case "Assinatura dos termos":
        return <IoMdMailUnread size={28} />;
      case "Análise":
        return <IoMdMailUnread size={28} />;
      case "Pago":
        return <IoMdMailUnread size={28} />;
      case "Nogiciação Finalizada":
        return <IoMdMailUnread size={28} />;
      case "Validação com a administradora":
        return <IoMdMailUnread size={28} />;
      case "Pagamento agendado":
        return <IoMdMailUnread size={28} />;
      case "Pagamento realizado":
        return <GiTakeMyMoney size={28} />;
      case "Processo de transferência de titularidade pela Administradora":
        return <BiTransfer size={28} />;
      case "":
        return <GiTakeMyMoney size={28} />;
      case " ":
        return <GiTakeMyMoney size={28} />;
      
      default:
        return <GiTakeMyMoney size={28} />;
    }
  }

  export const iconeEtapa = (idEtapa, element) => {
    switch (idEtapa) {
      case 1:
        return <img src={BoasVindas} data-tooltip={element} width={25} />;
      case 2:
        return <img src={TriagemBoasVindas} />;
      case 3:
        return <img src={EnvioDeProposta} />;
      case 4:
        return <img src={CadastroDoCliente} />;
      case 5:
        return <img src={TriagemDocumentos} />;
      case 6:
        return <img src={RegistroDeProposta} />;
      case 7:
        return <img src={TriagemTransferencia} />;
      case 8:
        return <img src={Pagamento} />;
      case 9:
        return <img src={ConfirmacaoPagamento} />;
      default:
        return "-";
    }
  }


  export const administradorasList = {
    62: "SANTANDER BRASIL ADMINISTRADORA DE CONSORCIO LTDA.",
    123: "CNP CONSORCIO S.A. ADMINISTRADORA DE CONSORCIOS",
  }

  export const measures = [0, 8, 25.5, 46, 79.5, 130, 215, 400, 1190, 4000];
  export const measuresMobile = [0, 9.5, 25, 47.5, 77.5, 125.5, 210.5, 380.5, 1130.5, 3000];
  export const tooltips = [
    "",
    "Boas Vindas",
    "Triagem boas vindas",
    "Envio de proposta",
    "Cadastro do cliente",
    "Triagem documentos",
    "Registro de proposta",
    "Triagem transferência ",
    "Pagamento",
    "Confirmação de pagamento",
  ];

  export const tooltipsPt1 = [
    "",
    "Boas Vindas",
    "Triagem boas",
    "Envio de",
    "Cadastro do",
    "Triagem",
    "Registro de",
    "Triagem Transferência ",
    "Pagamento",
    "Confirmação de",
  ];

  export const tooltipsPt2 = [
    "",
    "",
    "vindas",
    "proposta",
    "cliente",
    "documentos",
    "proposta",
    "",
    "",
    "pagamento",
  ];
