import React, { useCallback, useEffect, useState } from "react";
import * as Base from "../styles";
import * as S from "./styles";

import { CotaCardDesktop, CotaCardMobile } from "./components/CotaCard";
import { useDispatch, useSelector } from "react-redux";

import { IoIosArrowBack } from "react-icons/io";
import { StatusBarCotaDesktop, StatusBarCotaMobile } from "./components/StatusBar";
import api from "../../../services/api";
import{FloatButton} from '../../../components/FloatButton'
import { BsWhatsapp } from "react-icons/bs";
function ChartQuotes() {
  const dispatch = useDispatch();
  const { token, cpfCnpj,tokenConsort } = useSelector(state => state.otpCnp);
  const [cotas, setCotas] = useState([]);
  const [cotaSelecionada, setCotaSelecionada] = useState(null);
  const [document, setDocument] = useState("");
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  async function handleGetData() {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        tokenConsort: tokenConsort
      }
    }
    const doc = cpfCnpj.replace(/[^0-9]/g, '');
    setDocument(doc)
    const { data } = await api.get(`api/concash/1.0/consorcio-grupo-cota/negociacoes?cpfCnpj=${doc}&idAdministradora=123`, config)
    const dataR = data;

    function filterEtapa(cotaB, index) {
      let et = null;
      console.log(cotaB);

      const etapas = cotaB.etapas;

      for (let i = 0; i < etapas.length; i++) {
        const item = etapas[i];
        if (!item.concluido && !et) {
          et = {
            position: i + 1,
            total: etapas.length,
            ...item,
          };
        }
      }
      let etapaFinal;

      if(et) {
        etapaFinal = et;
      } else {
        etapaFinal = {
          position: etapas.length + 1,
          total: etapas.length,
          ...etapas[etapas.length],
        }
      }


      return {
        cota: cotaB.cota,
        grupo: cotaB.grupo,
        etapas: cotaB.etapas,
        etapaAtual: etapaFinal,
        valorProposta: cotaB.valorProposta,
        linkTermo: cotaB.linkTermo,
        linkSelfie: cotaB.linkSelfie
      };
    }

    const dados = dataR.map((item, index) => {
      return filterEtapa(item, index);
    });
    setCotas(dados);
  }



  const handleResize = useCallback(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    handleGetData();
  }, []);
  return (
    <>
      {windowSize.width > 820 ? ( // Desktop
        cotaSelecionada ? (
          <>
            <Base.ContainerDesktop>
              <S.MainBoxDesktop>
                <S.HeaderBaseDesktop />
                <S.SelectedContainerDesktop>
                  <S.LineDesktop>
                    <div style={{ cursor: 'pointer'}} onClick={() => setCotaSelecionada(null)}>
                      <IoIosArrowBack size={34} />
                      <span style={{ fontWeight: 'bold', fontSize: 15}}>Voltar</span>
                    </div>
                  </S.LineDesktop>
                  <div>
                    <StatusBarCotaDesktop list={cotaSelecionada.etapas} atual={cotaSelecionada.etapaAtual.position} cota={cotaSelecionada} documento={document}/>
                  </div>
                </S.SelectedContainerDesktop>
              </S.MainBoxDesktop>
            </Base.ContainerDesktop>
          </>
        ) : (
          <Base.ContainerMobile>
            <S.MainBoxMobile>
              <S.HeaderBaseDesktop />
              <S.BoxQuotes>
              <S.TitleBoxMobile>
                <S.TitlePMobile style={{ alignSelf: "left" }}>
                  Minhas Cotas
                </S.TitlePMobile>
              </S.TitleBoxMobile>
              {cotas.length>0? 
              cotas.map((quote) => (
                <CotaCardMobile
                cota={quote}
                onClick={() => setCotaSelecionada(quote)}
                adm={123}
                />
                )):<></>
              }

              </S.BoxQuotes>

            </S.MainBoxMobile>
            <FloatButton
              direction="right"
              color="#25d366"
              radius="100px"
              opcional
              link="https://api.whatsapp.com/send/?phone=551140405686&text=Ol%C3%A1%2C+quero+vender+minha+cota+de+consorcio+para+a+Concash​"
              icon={<BsWhatsapp size={30} color={"#FFF"} />}
            />
          </Base.ContainerMobile>
        )
      ) : ( // Mobile
        cotaSelecionada ? (
          <>
            <Base.ContainerMobile>
              <S.MainBoxMobile>
                <S.HeaderBaseDesktop />
          <S.BoxStatus>

                <S.SelectedContainerMobile>
                  <S.LineMobile>
                    <div style={{ cursor: 'pointer'}} onClick={() => setCotaSelecionada(null)}>
                      <IoIosArrowBack size={34} />
                      <span style={{ fontWeight: 'bold', fontSize: 15}}>Voltar</span>
                    </div>
                  </S.LineMobile>
                  <div>
                    <StatusBarCotaMobile list={cotaSelecionada.etapas} atual={cotaSelecionada.etapaAtual.position} cota={cotaSelecionada}  documento={document}/>
                  </div>
                </S.SelectedContainerMobile>
          </S.BoxStatus>
              </S.MainBoxMobile>
            </Base.ContainerMobile>
          </>
        ) : (
          <Base.ContainerMobile>
            <S.MainBoxMobile>
              <S.HeaderBaseDesktop />
            <S.BoxQuotes>

              <S.TitleBoxMobile>
                <S.TitlePMobile style={{ alignSelf: "left" }}>
                  Minhas Cotas
                </S.TitlePMobile>
              </S.TitleBoxMobile>

              {cotas.length>0? 
              cotas.map((quote) => (
                <CotaCardMobile
                cota={quote}
                onClick={() => setCotaSelecionada(quote)}
                adm={123}
                />
                )):<></>
              }
            </S.BoxQuotes>
            </S.MainBoxMobile>
            <FloatButton
              direction="right"
              color="#25d366"
              radius="100px"
              opcional
              link="https://api.whatsapp.com/send/?phone=551140405686&text=Ol%C3%A1%2C+quero+vender+minha+cota+de+consorcio+para+a+Concash​"
              icon={<BsWhatsapp size={30} color={"#FFF"} />}
            />
          </Base.ContainerMobile>
        )
      )}
    </>
  );
}

export default ChartQuotes;
