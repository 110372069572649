import React, { useState, useEffect } from "react";
import { BaseDesktop, BaseMobile } from "../components/common/Base";
import S from "../Autoservico.module.css";
import { InfoDefault } from "../components/common/Info";
import { CotaCard } from "../components/Cards/CotaCard";
import { FinalizarEscolherConta } from "./Forms";
import useAutoservico from "../../../store/autoservico/useAutoservico";
import api from "../../../services/api";
import { idAdm } from "./detalhes";
import { useSelector } from "react-redux";
import { Skeleton, Stack } from "@mui/material";
import { V3Modal } from "../../../styles/themes/components/modal";

export const MinhasCotas = ({ estilo }) => {
  const auto = useAutoservico();
  const service = window.location.pathname
    .replace("/autoservico/", "")
    .replace("autoservico", "")
    .split("/");
  const adm = service[0];

  const state = useSelector((state) => state.autoservico);
  const [ loading ,setLoading ] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [pendentes, setPendentes] = useState(false);
  const [vendidas, setVendidas] = useState(false);
  const [propostas, setPropostas] = useState(0);
  const [cotas, setCotas] = useState([]);

  async function handleGetQuotes() {
    const { cpfCnpj } = auto.getData();
    const doc = cpfCnpj.replace(/\D/g, "");
    await api
      .get(
        `/api/concash/1.0/consorcio-grupo-cota/negociacoes?cpfCnpj=${doc}&idAdministradora=${idAdm[adm]}&Tokenconsort=${state.otp}`,{
          headers: {
            TokenConsort: state.tokenConsort
          }
        }
      )
      .then(async ({ data }) => {
        let cotasNovas = [];

        const novasCotas = await data.map((element, i) => {
          const fasePagamento = element.etapas.find((etapa) => etapa.fase == "Disbursement");
          const fasePago = element.etapas.find((etapa) => etapa.fase == "Payment");
          let cotaNova = {
            id: i,
            adm: element.administradora,
            idAtendimento: element.idAtendimento,
            grupo: element.grupo,
            cota: element.cota,
            etapas: element.etapas,
            contrato: element.contrato,
            statusAtendimento: element.statusAtendimento,
            propostas: element.propostas.map((ct) => {return ct}),
            vendida: fasePagamento.concluido,
            linkAssinatura: element?.linkTermo,
            valorVenda: element?.propostas[0]?.valorProposta,
            prazoPagamento: element?.propostas[0]?.prazo,
            pago: fasePago?.concluido
          };
          return cotaNova;
        })

        setCotas(novasCotas);
        let sold = [];
        let pending = [];

        novasCotas.filter((i, key) => {
          const fasePagamento = i.etapas.find((etapa) => etapa.fase == "Payment");
        if(fasePagamento.concluido){
          sold.push(i);
        } else {
          pending.push(i);
        }});
        let propes = 0;
        novasCotas?.map((p) => {
          if (!p.vendida && p.propostas.length > 0 && p.valorVenda) {
            propes = propes + 1;
          }
        });
        setPropostas(propes);
        setVendidas(sold);
        setPendentes(pending);
        setLoading(false);
      })
      .catch((e) => {
        if (e.response.data.status == 401) {
          localStorage.clear();
          return window.location.reload();
        }
        setErrorText("Houve um erro ao carregar suas cotas.")
        setHasError(true);
        setLoading(false);
      });
  }


  useEffect(() => {
    if(!cotas.length) {
      handleGetQuotes();
    }
  }, []);

  if (estilo)
    return (
      <div>
        <V3Modal
          open={hasError}
          actionClose={() => {
            setHasError(false);
            window.location.href = "/autoservico/"+service[0]+"/meus-dados"
          }}
          title={errorText}
          customButtonColor={estilo?.primary}
          type="warning"
        />
        {isMobile ? (
           loading ? (<>
           <BaseMobile estilo={estilo}>
           <div className={S.contentIntern}>
           <Stack spacing={1}>
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 10,
                      }}
                    >
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={40}
                        height={40}
                      />
                      <Skeleton variant="rectangular" width={"100%"} height={40} />
                      <Skeleton variant="rounded" width={"100%"} height={40} />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 10,
                      }}
                    >
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={40}
                        height={40}
                      />
                      <Skeleton variant="rectangular" width={"100%"} height={40} />
                      <Skeleton variant="rounded" width={"100%"} height={40} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 10,
                      }}
                    >
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={40}
                        height={40}
                      />
                      <Skeleton variant="rectangular" width={"100%"} height={40} />
                      <Skeleton variant="rounded" width={"100%"} height={40} />
                    </div>
                  </div>
                </Stack>
                </div>
                </BaseMobile>
           </>) : (
          <>
            <BaseMobile estilo={estilo}>
              <div className={S.contentIntern}>
                <h1 className={S.titlePage}>Minhas Cotas</h1>
                <InfoDefault
                  message={
                    propostas > 1 ? (
                      <span>
                        Você tem <b>{propostas} propostas</b> para suas cotas de
                        consórcio
                      </span>
                    ) : (
                      <span>
                        Você tem <b>{propostas} proposta</b> para sua cota de
                        consórcio
                      </span>
                    )
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {pendentes &&
                  pendentes.map((cota) => (
                    <CotaCard cota={cota} estilo={estilo} />
                  ))}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    gap: 10,
                    marginBottom: 5,
                    alignItems: "center",
                  }}
                >
                  <span
                    className={S.title18}
                    style={{
                      color: "#838383",
                    }}
                  >
                    Cotas vendidas
                  </span>
                  <div className={S.line} />
                </div>
                {vendidas
                  ? vendidas.map((vend) => (
                      <CotaCard cota={vend} estilo={estilo} />
                    ))
                  : ""}
              </div>
            </BaseMobile>
          </>)
        ) : loading ? (
          <>
            <BaseDesktop estilo={estilo}>
              <div style={{ width: 920 }}>
                <Stack spacing={1}>
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <div style={{ display: "flex", gap: 15 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 10,
                      }}
                    >
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={40}
                        height={40}
                      />
                      <Skeleton variant="rectangular" width={210} height={40} />
                      <Skeleton variant="rounded" width={210} height={40} />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 10,
                      }}
                    >
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={40}
                        height={40}
                      />
                      <Skeleton variant="rectangular" width={210} height={40} />
                      <Skeleton variant="rounded" width={210} height={40} />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 10,
                      }}
                    >
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={40}
                        height={40}
                      />
                      <Skeleton variant="rectangular" width={210} height={40} />
                      <Skeleton variant="rounded" width={210} height={40} />
                    </div>
                  </div>
                </Stack>
              </div>
            </BaseDesktop>
          </>
        ) : (
          <BaseDesktop estilo={estilo}>
            <div style={{ width: 920 }}>
              <h1 className={S.titlePage}>Minhas Cotas</h1>
              <InfoDefault
                message={
                  propostas > 1 ? (
                    <span>
                      Você tem <b>{propostas} propostas</b> para suas cotas de
                      consórcio
                    </span>
                  ) : (
                    <span>
                      Você tem <b>{propostas} proposta</b> para sua cota de
                      consórcio
                    </span>
                  )
                }
              />
              <div className={S.contentDados}>
                {pendentes &&
                  pendentes.map((cota) => (
                    <CotaCard cota={cota} estilo={estilo} />
                  ))}
              </div>
              {vendidas.length > 0 && (
                <div className={S.contentDados}>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      gap: 10,
                      marginBottom: 5,
                      alignItems: "center",
                    }}
                  >
                    <span
                      className={S.title18}
                      style={{
                        color: "#838383",
                      }}
                    >
                      Cotas vendidas
                    </span>
                    <div className={S.line} />
                  </div>
                  {vendidas
                    ? vendidas.map((vend) => (
                        <CotaCard cota={vend} estilo={estilo} />
                      ))
                    : ""}
                </div>
              )}
            </div>
          </BaseDesktop>
        )}
      </div>
    );
};
