import React, { Component, PropTypes } from 'react';
import { Form } from 'react-bootstrap';
import api from "../services/api";

class ReguaTipoProcesso extends Component {

  contabilizado   = 0;
  contadorExcluir = 0;

  constructor(props){

    super(props);
    const { marcados }           = this.props;
    const { label }              = this.props;
    const { valor }              = this.props;
    const { tipoProcesso }       = this.props;
    const { funcao }             = this.props;
    var marcado                  = false;
    var prioridade               = 0;

    if(marcados[funcao.codigo]){
        marcado     = true;
        prioridade  = marcados[funcao.codigo];
    }

    this.state = {
      isChecked:     marcado,
      valor:         valor,
      prioridade:    prioridade,
      tipoProcesso:  tipoProcesso,
      funcao:        funcao
    }


  }

  toggleCheckboxChange = (e) => {


    this.setState(({ isChecked }) => (
      {
        isChecked: !isChecked,
      }
    ));
  }

  shouldComponentUpdate(nextProps,nextState){

     if(nextState.isChecked == true){
         if(this.contabilizado == 0){
           this.handleAdicionaConfiguracao();
           this.contabilizado = 1;
         }else{
           this.contabilizado = 0;
         }
     }else{
       if(this.contadorExcluir == 0){
         this.handleRemoveConfiguracao();
         this.contadorExcluir = 1;
       }else{
         this.contadorExcluir = 0;
       }
     }

     return true;
  }

  async handleRemoveConfiguracao(){

    if(!this.state.funcao.codigo
    || !this.state.tipoProcesso.codigo
    || !this.state.prioridade){

    }else{

      try {
           const response = await api.put("api/concash/1.0/processo-funcao/excluir", {
             codigoTipoProcesso:        this.state.tipoProcesso.codigo
            ,codigoFuncao      :        this.state.funcao.codigo
          });

      } catch (err) {
      }
    }

  }

  async handleAdicionaConfiguracao(){

    if(!this.state.funcao.codigo
    || !this.state.tipoProcesso.codigo
    || !this.state.prioridade){

        

    }else{

        
        try {
             const response = await api.put("api/concash/1.0/processo-funcao/editar", {
               codigoTipoProcesso:        this.state.tipoProcesso.codigo
              ,codigoFuncao      :        this.state.funcao.codigo
              ,prioridade        :        this.state.prioridade
              });
        } catch (err) {
        }

    }


  }





  render() {

    const { label }              = this.props;
    const { isChecked }          = this.state;
    const { valor }              = this.props;

    return (

      <div className="row" >
        <div className="col-md-12">
          <Form.Group className="row" >
            <div className="col-sm-2">
              <Form.Control type="checkbox" value={valor} checked={isChecked} onChange={ e => this.toggleCheckboxChange(e)}   size="sm" />
            </div>
            <div className="col-sm-2">
              <Form.Control type="number" value={this.state.prioridade}   onChange={e => this.setState({ prioridade: e.target.value })}   size="sm" />
            </div>
            <label className="col-sm-8 col-form-label">{label}</label>

          </Form.Group>
        </div>
      </div>
    );
  }
}


export default ReguaTipoProcesso;
