import React from "react";
import "./App.css";
import Routes from "./routes";
import pack from "../package.json";
import { Provider } from 'react-redux';
import {  persistor, store } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <Routes />
      </PersistGate>
    </Provider>
  );
}

export default App;
