import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import api from "../../services/api";
import { NewItem, NewSideBar } from "./Sidebar.styled";

import { HiOutlineDocumentText } from "react-icons/hi";
import { BsPlus } from "react-icons/bs";
import { BiUser } from "react-icons/bi";

export class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: [],
      nome: "",
      basicUiMenuOpen: true,
      label: "teste 1",
      isMobile: false,
    };
  }

  retornaMenu() {
    return this.state.menu;
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  async componentDidMount() {
    const tamanho = window.innerWidth;
    if (tamanho <= 800) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }

    try {
      const response = await api.post("api/sistema/1.0/menu/listar", {});

      this.setState({ menu: response.data });
    } catch (err) {
      this.setState({
        error: "Houve um problema para obter os grupos de ações.",
      });
    }
  }

  render() {
    if (this.state.isMobile) {
      return <></>;
    } else {
      return (
        <NewSideBar>
          <NewItem
            title="Minhas cotas"
            link="/consorcio-grupo-cota"
            icon={<HiOutlineDocumentText color="#FFF" size={24} />}
          />
          <NewItem
            title="Solicitar Proposta"
            link="/nova-cota"
            icon={<BsPlus color="#FFF" size={24} />}
          />
          <NewItem
            title="Meu cadastro"
            link="/backoffice/detalhamento-cliente"
            icon={<BiUser color="#FFF" size={24} />}
          />
          {/* <ul className="nav" style={{ width: 270, padding: 30 }}>
            <li className="nav-item">
              <Link className="nav-link" to="/consorcio-grupo-cota">
                <i className="mdi mdi-file-document-outline menu-icon"></i>
                <span className="menu-title">Minhas cotas</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/upload-analise">
                <i className="mdi mdi-plus menu-icon"></i>
                <span className="menu-title">Solicitar proposta</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/backoffice/detalhamento-cliente">
                <i className="mdi mdi-account-outline menu-icon"></i>
                <span className="menu-title">Meu cadastro</span>
              </Link>
            </li>
          </ul> */}
        </NewSideBar>
      );
    }
  }
}

export default withRouter(Sidebar);
