import React, { Component, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import Logo from "../../assets/logo.png";
import { RECAPTCHA_KEY } from "../../site.config";
import { Form } from "react-bootstrap";
import api from "../../services/api";
import Swal from "sweetalert2";
import { login } from "../../services/auth";
import styled, { css } from "styled-components";
import { FormCad, Container, Footer, Header, ContainerHeader } from "./styles";
import ReCAPTCHA from "react-google-recaptcha";
import SolicitacaoEsqueciSenha from "../../components/SolicitacaoEsqueciSenha";
import CriarConta from "../../components/CriarConta";
import PasswordChecker from "../../components/PaswordChecker";
import { cpf, cnpj } from "cpf-cnpj-validator";
import { cpfCnpj } from "../../mask/cpfcnpj";
import { validatorEmail } from "validator";
import isEmail from "validator/lib/isEmail";
import { maskCEP } from "../../mask/maskCEP";
import cep from "cep-promise";
import { isValidCNPJ } from "../../utils/validateCNPJ";
import * as S from "./cadastro.styles";
import family from "../../assets/images/novo/family.png";
import ConcashLogo from "../../styles/themes/v3/assets/logo Concash.png";
import { V3Link, V3Text5, V3Title } from "../../styles/themes/components/texts";
import { V3TextCustom } from "../../styles/themes/components/input";
import { V3Button, V3ButtonLink } from "../../styles/themes/components/button";
import { v3Colors } from "../../styles/themes/v3/colors";
import Lottie from "lottie-react";
import Loading from "../../assets/lottie/loading.json";
import {
  maskDataDeNascimento,
  v3FormataCNPJ,
  v3MaskCPF,
} from "../../styles/themes/utils/format";
import { InfoModal, V3Modal } from "../../styles/themes/components/modal";
import {
  hasLowerCase,
  hasNumber,
  hasSpecialChar,
  hasUpperCase,
  isPastDate,
  isValidDate,
  v3CheckPassword,
  v3ValidaCpfCnpj,
  v3ValidateEmail,
} from "../../styles/themes/utils/validate";
import { BsCheck } from "react-icons/bs";
import moment from "moment";
import { Backdrop } from "@mui/material";

const Cadastro = (props) => {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    const tela = window.matchMedia("(max-width:768px)").matches;
    setMobile(tela);
  }, []);

  return (
    <>
      <div>
        {mobile ? <CadastroMobile p={props} /> : <CadastroDesktop p={props} />}
      </div>
    </>
  );
};

const CadastroDesktop = (props) => {
  let { p } = props;
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [cpfCnpjErr, setCpfCnpjErr] = useState("");
  const [error, setError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);

  const [errTamanho, setErrTamanho] = useState(false);
  const [errSimbolo, setErrSimbolo] = useState(false);
  const [errMaiusculas, setErrMaiusculas] = useState(false);
  const [errMinisculas, setErrMinusculas] = useState(false);
  const [errNumeros, setErrNumeros] = useState(false);

  const [nomeRazao, setNomeRazao] = useState("");
  const [nomeRazaoErr, setNomeRazaoErr] = useState(false);
  const [dtNasc, setDtNasc] = useState("");
  const [dtNascErr, setDtNascErr] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [cpass, setCPass] = useState("");
  const [cpassErr, setCPassErr] = useState(false);
  const [sucesso, setSucesso] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [errorText, setErrorText] = useState("");

  function handleNomeRazao(t) {
    if (nomeRazao || nomeRazao === "") {
      setNomeRazao(t);
      setNomeRazaoErr(false);
    } else {
      setNomeRazao(t);
      setNomeRazaoErr(true);
    }
  }

  async function handleCadastro(e) {
    if (!nomeRazao || nomeRazaoErr) {
      setHasError(true);
      setErrorText("Preencha o campo Nome / Razão Social.");
      return setNomeRazaoErr(true);
    }
    if (!cpfCnpj || !v3ValidaCpfCnpj(cpfCnpj) || cpfCnpjErr) {
      setHasError(true);
      setErrorText("Preencha o campo com um CPF / CNPJ válido.");
      return setCpfCnpjErr(true);
    }

    if (!dtNasc || !isPastDate(dtNasc) || dtNascErr) {
      setHasError(true);
      setErrorText("Preencha corretamente o campo data de nascimento.");
      return setDtNascErr(true);
    }

    if (!email || !v3ValidateEmail(email) || emailErr) {
      setHasError(true);
      setErrorText("Informe um e-mail válido.");
      return setEmailErr(true);
    }

    if (!password || passwordErr || !v3CheckPassword(password)) {
      setHasError(true);
      setErrorText(
        "Sua senha deve conter números, letras e pelo menos um caractere especial e uma letra maiúscula."
      );
      return setPasswordErr(true);
    }

    if (password !== cpass) {
      setHasError(true);
      setErrorText("Senha e confirmação de senha não conferem.");
      return setCPassErr(true);
    }

    const dt = dtNasc.split("/");
    const newDT = `${dt[2]}-${dt[1]}-${dt[0]}`;
    const dtFinal = moment(newDT).format();

    const newCpfCnpj = cpfCnpj.replace(/[^\d]/g, "");
    setLoading(true);
    await api
      .post("/api/onboarding/1.0/cliente/cadastrar-cliente", {
        nomeRazao: nomeRazao,
        cpfCnpj: newCpfCnpj,
        dataNascimento: dtFinal,
        email: email,
        senha: password,
      })
      .then(({ data }) => {
        setLoading(false);
        setSucesso(true);
      })
      .catch((error) => {
        setSucesso(false);
        setLoading(false);
        setError(true);
        setErrorMessage(error.response.data);
      });
  }

  function handlePass(text) {
    if (text.length >= 10 && v3CheckPassword(text)) {
      setPasswordErr(false);
    } else {
      setPasswordErr(true);
    }

    if (!hasNumber(text)) {
      setErrNumeros(true);
      setPassword(text);
    } else {
      setErrNumeros(false);
      setPassword(text);
    }

    if (!hasUpperCase(text)) {
      setErrMaiusculas(true);
      setPassword(text);
    } else {
      setErrMaiusculas(false);
      setPassword(text);
    }

    if (!hasLowerCase(text)) {
      setErrMinusculas(true);
      setPassword(text);
    } else {
      setErrMinusculas(false);
      setPassword(text);
    }

    if (text.length >= 10) {
      setErrTamanho(false);
      setPassword(text);
    } else {
      setErrTamanho(true);
      setPassword(text);
    }

    if (!hasSpecialChar(text)) {
      setErrSimbolo(true);
      setPassword(text);
    } else {
      setErrSimbolo(false);
      setPassword(text);
    }
  }

  function handleCPass(text) {
    setCPass(text);
    if (text !== password) {
      setCPassErr(true);
    } else {
      setCPassErr(false);
    }
  }

  function handleCpfCnpj(text) {
    const valid = v3ValidaCpfCnpj(text);

    const validating = text.replace(/[^\d]/g, "");
    if (validating.length <= 14) {
      if (validating.length <= 11) {
        const c = v3MaskCPF(validating);
        setCpfCnpjErr(!valid);
        setCpfCnpj(c);
      } else {
        setCpfCnpjErr(!valid);

        const cn = v3FormataCNPJ(validating);
        setCpfCnpj(cn);
      }
    }
  }

  function handleEmail(t) {
    const text = t.replaceAll(" ", "");
    if (v3ValidateEmail(text) || text === "") {
      setEmail(text);
      setEmailErr(false);
    } else {
      setEmail(text);
      setEmailErr(true);
    }
  }

  function handleDataDeNascimento(text) {
    const validating = text.replace(/[^\d]/g, "");
    if (validating.length <= 8) {
      setDtNasc(maskDataDeNascimento(text));
      if (isPastDate(text)) {
        setDtNascErr(false);
      } else {
        setDtNascErr(true);
      }
    }
  }

  return (
    <>
      <V3Modal
        open={hasError}
        style={{ zIndex: 999 }}
        actionClose={() => setHasError(false)}
        title={errorText}
        type="warning"
      />
      <V3Modal
        open={hasSuccess}
        style={{ zIndex: 999 }}
        actionClose={() => setHasSuccess(false)}
        title={successText}
        type="success"
      />
      <div>
        <S.Container>
          <S.BoxLogin style={{ zIndex: 998 }}>
            <S.Row>
              <img src={ConcashLogo} width={162} height={44} />
            </S.Row>
            {sucesso ? (
              <>
                <S.RowCenter style={{ marginTop: 30, marginBottom: 10 }}>
                  <div
                    style={{
                      display: "flex",
                      width: 50,
                      height: 50,
                      backgroundColor: v3Colors.primary,
                      borderRadius: "50%",
                      alignItems: "center",
                      justifyContent: "center",
                      color: v3Colors.white,
                    }}
                  >
                    <BsCheck size={40} />
                  </div>
                </S.RowCenter>
                <S.RowCenter style={{ marginBottom: 30 }}>
                  <V3Title style={{ textAlign: "center" }}>
                    Conta criada com sucesso!
                  </V3Title>
                </S.RowCenter>
                <S.RowCenter style={{ marginBottom: 30 }}>
                  <V3Text5 style={{ color: v3Colors.black }}>
                    Agora é só entrar em sua conta.
                  </V3Text5>
                </S.RowCenter>
                <S.RowCenter>
                  <V3ButtonLink
                    href="/"
                    estilo="primary"
                    width={330}
                    text="Entrar"
                  />
                </S.RowCenter>
              </>
            ) : (
              <>
                <Backdrop open={loading} style={{ zIndex: 999 }}>
                  <div>
                    <Lottie animationData={Loading} />
                  </div>
                </Backdrop>
                <S.RowCenter style={{ marginTop: 30, marginBottom: 15 }}>
                  <V3Title>Criar Conta</V3Title>
                </S.RowCenter>
                {error && (
                  <S.Row>
                    <InfoModal
                      style={{ marginBottom: 15 }}
                      text={
                        errorMessage
                          ? errorMessage
                          : "Os dados não correspondem às informações em nossos registros. Verifique-as e tente novamente."
                      }
                      type="error"
                    />
                  </S.Row>
                )}
                <S.Row style={{ marginTop: 15 }}>
                  <V3TextCustom
                    size="small"
                    sx={{ width: 350 }}
                    onChange={(e) => {
                      handleNomeRazao(e.target.value);
                    }}
                    label="Nome / Razão Social"
                    value={nomeRazao}
                    error={nomeRazaoErr}
                    helperText={nomeRazaoErr && "Preencha Nome /Razão Social."}
                    required
                  />
                </S.Row>
                <S.Row style={{ marginTop: 15 }}>
                  <V3TextCustom
                    size="small"
                    sx={{ width: 447 }}
                    label="CPF / CNPJ"
                    onChange={(e) => {
                      handleCpfCnpj(e.target.value);
                    }}
                    error={cpfCnpjErr}
                    value={cpfCnpj}
                    helperText={cpfCnpjErr && "CPF / CNPJ inválido."}
                    required
                  />
                </S.Row>
                <S.Row style={{ marginTop: 15 }}>
                  <V3TextCustom
                    size="small"
                    sx={{ width: 447 }}
                    label="Data de Nascimento"
                    onChange={(e) => {
                      handleDataDeNascimento(e.target.value);
                    }}
                    error={dtNascErr}
                    value={dtNasc}
                    helperText={dtNascErr && "Data de nascimento inválida."}
                    required
                  />
                </S.Row>
                <S.Row style={{ marginTop: 15 }}>
                  <V3TextCustom
                    sx={{ width: 447 }}
                    label="E-mail"
                    onChange={(e) => {
                      handleEmail(e.target.value);
                    }}
                    error={emailErr}
                    size="small"
                    helperText={emailErr && "Preencha o campo."}
                    value={email}
                    type="email"
                    required
                  />
                </S.Row>
                <S.Row style={{ marginTop: 15 }}>
                  <V3TextCustom
                    size="small"
                    label="Senha"
                    onChange={(e) => handlePass(e.target.value)}
                    sx={{
                      width: 447,
                    }}
                    value={password}
                    error={passwordErr}
                    type="password"
                  />
                </S.Row>
                {password && (
                  <>
                    <S.Row style={{ flexDirection: "column", paddingTop: 10 }}>
                      <V3Text5
                        style={{ fontWeight: "bold", color: v3Colors.black }}
                      >
                        Sua senha deve conter:
                      </V3Text5>
                      <V3Text5
                        style={{
                          color: errTamanho
                            ? v3Colors.black
                            : v3Colors.greenLight,
                        }}
                      >
                        ● No mínimo 10 caracteres
                        {!errTamanho && (
                          <BsCheck color={v3Colors.greenLight} size={20} />
                        )}
                      </V3Text5>
                      <V3Text5
                        style={{
                          color: errSimbolo
                            ? v3Colors.black
                            : v3Colors.greenLight,
                        }}
                      >
                        ● No mínimo 1 símbolo (!@#$%^&*)
                        {!errSimbolo && (
                          <BsCheck color={v3Colors.greenLight} size={20} />
                        )}
                      </V3Text5>
                      <V3Text5
                        style={{
                          color: errMaiusculas
                            ? v3Colors.black
                            : v3Colors.greenLight,
                        }}
                      >
                        ● Letras maiúsculas
                        {!errMaiusculas && (
                          <BsCheck color={v3Colors.greenLight} size={20} />
                        )}
                      </V3Text5>
                      <V3Text5
                        style={{
                          color: errMinisculas
                            ? v3Colors.black
                            : v3Colors.greenLight,
                        }}
                      >
                        ● Letras minúsculas
                        {!errMinisculas && (
                          <BsCheck color={v3Colors.greenLight} size={20} />
                        )}
                      </V3Text5>
                      <V3Text5
                        style={{
                          color: errNumeros
                            ? v3Colors.black
                            : v3Colors.greenLight,
                        }}
                      >
                        ● Números
                        {!errNumeros && (
                          <BsCheck color={v3Colors.greenLight} size={20} />
                        )}
                      </V3Text5>
                    </S.Row>
                  </>
                )}
                <S.Row style={{ marginTop: 15, marginBottom: 15 }}>
                  <V3TextCustom
                    sx={{ width: 447 }}
                    label="Confirme a senha"
                    onChange={(e) => {
                      handleCPass(e.target.value);
                    }}
                    error={cpassErr}
                    size="small"
                    helperText={
                      cpassErr && "Confirmação não confere com a senha."
                    }
                    type="password"
                    value={cpass}
                    required
                  />
                </S.Row>
                <S.Row style={{ marginTop: 15, marginBottom: 15 }}>
                  <V3Text5
                    style={{ color: v3Colors.black, fontStyle: "italic" }}
                  >
                    * Preenchimento Obrigatório.
                  </V3Text5>
                </S.Row>
                <S.RowCenter>
                  <V3Button
                    onClick={handleCadastro}
                    estilo="primary"
                    width={"100%"}
                    text="Criar conta"
                  />
                </S.RowCenter>
                <S.RowCenter
                  style={{
                    paddingBottom: 30,
                    paddingTop: 30,
                    marginBottom: 30,
                    gap: 5,
                  }}
                >
                  <V3Text5 style={{ color: v3Colors.black }}>
                    Já tem conta?{" "}
                  </V3Text5>
                  <V3Link
                    href="/"
                    style={{ color: v3Colors.primary, fontWeight: "bold" }}
                  >
                    É só entrar
                  </V3Link>
                </S.RowCenter>
              </>
            )}
          </S.BoxLogin>
          <S.BoxSideRight>
            <img src={family} style={{ position: "fixed", top: 0 }} />
          </S.BoxSideRight>
        </S.Container>
      </div>
    </>
  );
};
const CadastroMobile = (props) => {
  let { p } = props;
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [cpfCnpjErr, setCpfCnpjErr] = useState("");
  const [error, setError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);

  const [errTamanho, setErrTamanho] = useState(false);
  const [errSimbolo, setErrSimbolo] = useState(false);
  const [errMaiusculas, setErrMaiusculas] = useState(false);
  const [errMinisculas, setErrMinusculas] = useState(false);
  const [errNumeros, setErrNumeros] = useState(false);

  const [nomeRazao, setNomeRazao] = useState("");
  const [nomeRazaoErr, setNomeRazaoErr] = useState(false);
  const [dtNasc, setDtNasc] = useState("");
  const [dtNascErr, setDtNascErr] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [cpass, setCPass] = useState("");
  const [cpassErr, setCPassErr] = useState(false);
  const [sucesso, setSucesso] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [errorText, setErrorText] = useState("");

  function handleNomeRazao(t) {
    if (nomeRazao || nomeRazao === "") {
      setNomeRazao(t);
      setNomeRazaoErr(false);
    } else {
      setNomeRazao(t);
      setNomeRazaoErr(true);
    }
  }

  async function handleCadastro(e) {
    if (!nomeRazao || nomeRazaoErr) {
      setHasError(true);
      setErrorText("Preencha o campo Nome / Razão Social.");
      return setNomeRazaoErr(true);
    }
    if (!cpfCnpj || !v3ValidaCpfCnpj(cpfCnpj) || cpfCnpjErr) {
      setHasError(true);
      setErrorText("Preencha o campo com um CPF / CNPJ válido.");
      return setCpfCnpjErr(true);
    }

    if (!dtNasc || !isPastDate(dtNasc) || dtNascErr) {
      setHasError(true);
      setErrorText("Preencha corretamente o campo data de nascimento.");
      return setDtNascErr(true);
    }

    if (!email || !v3ValidateEmail(email) || emailErr) {
      setHasError(true);
      setErrorText("Informe um e-mail válido.");
      return setEmailErr(true);
    }

    if (!password || passwordErr || !v3CheckPassword(password)) {
      setHasError(true);
      setErrorText(
        "Sua senha deve conter números, letras e pelo menos um caractere especial e uma letra maiúscula."
      );
      return setPasswordErr(true);
    }

    if (password !== cpass) {
      setHasError(true);
      setErrorText("Senha e confirmação de senha não conferem.");
      return setCPassErr(true);
    }

    const dt = dtNasc.split("/");
    const newDT = `${dt[2]}-${dt[1]}-${dt[0]}`;
    const dtFinal = moment(newDT).format();

    const newCpfCnpj = cpfCnpj.replace(/[^\d]/g, "");
    setLoading(true);
    await api
      .post("/api/onboarding/1.0/cliente/cadastrar-cliente", {
        nomeRazao: nomeRazao,
        cpfCnpj: newCpfCnpj,
        dataNascimento: dtFinal,
        email: email,
        senha: password,
      })
      .then(({ data }) => {
        setLoading(false);
        setSucesso(true);
      })
      .catch((error) => {
        setSucesso(false);
        setLoading(false);
        setError(true);
        setErrorMessage(error.response.data);
      });
  }

  function handlePass(text) {
    if (text.length >= 10 && v3CheckPassword(text)) {
      setPasswordErr(false);
    } else {
      setPasswordErr(true);
    }

    if (!hasNumber(text)) {
      setErrNumeros(true);
      setPassword(text);
    } else {
      setErrNumeros(false);
      setPassword(text);
    }

    if (!hasUpperCase(text)) {
      setErrMaiusculas(true);
      setPassword(text);
    } else {
      setErrMaiusculas(false);
      setPassword(text);
    }

    if (!hasLowerCase(text)) {
      setErrMinusculas(true);
      setPassword(text);
    } else {
      setErrMinusculas(false);
      setPassword(text);
    }

    if (text.length >= 10) {
      setErrTamanho(false);
      setPassword(text);
    } else {
      setErrTamanho(true);
      setPassword(text);
    }

    if (!hasSpecialChar(text)) {
      setErrSimbolo(true);
      setPassword(text);
    } else {
      setErrSimbolo(false);
      setPassword(text);
    }
  }

  function handleCPass(text) {
    setCPass(text);
    if (text !== password) {
      setCPassErr(true);
    } else {
      setCPassErr(false);
    }
  }
  function handleCpfCnpj(text) {
    const valid = v3ValidaCpfCnpj(text);

    const validating = text.replace(/[^\d]/g, "");
    if (validating.length <= 14) {
      if (validating.length <= 11) {
        const c = v3MaskCPF(validating);
        setCpfCnpjErr(!valid);
        setCpfCnpj(c);
      } else {
        setCpfCnpjErr(!valid);

        const cn = v3FormataCNPJ(validating);
        setCpfCnpj(cn);
      }
    }
  }

  function handleEmail(t) {
    const text = t.replaceAll(" ", "");
    if (v3ValidateEmail(text) || text === "") {
      setEmail(text);
      setEmailErr(false);
    } else {
      setEmail(text);
      setEmailErr(true);
    }
  }

  function handleDataDeNascimento(text) {
    const validating = text.replace(/[^\d]/g, "");
    if (validating.length <= 8) {
      setDtNasc(maskDataDeNascimento(text));
      if (isPastDate(text)) {
        setDtNascErr(false);
      } else {
        setDtNascErr(true);
      }
    }
  }

  return (
    <>
      <V3Modal
        open={hasError}
        style={{ zIndex: 999 }}
        actionClose={() => setHasError(false)}
        title={errorText}
        type="warning"
      />
      <V3Modal
        open={hasSuccess}
        style={{ zIndex: 999 }}
        actionClose={() => setHasSuccess(false)}
        title={successText}
        type="success"
      />
      <div>
        <S.Container>
          <S.BoxLoginMobile style={{ zIndex: 998 }}>
            <S.RowCenter>
              <a href="/" style={{ textDecoration: "none" }}>
                <img src={ConcashLogo} width={162} height={44} />
              </a>
            </S.RowCenter>
            {sucesso ? (
              <>
                <S.RowCenter style={{ marginTop: 30, marginBottom: 10 }}>
                  <div
                    style={{
                      display: "flex",
                      width: 50,
                      height: 50,
                      backgroundColor: v3Colors.primary,
                      borderRadius: "50%",
                      alignItems: "center",
                      justifyContent: "center",
                      color: v3Colors.white,
                    }}
                  >
                    <BsCheck size={40} />
                  </div>
                </S.RowCenter>
                <S.RowCenter style={{ marginBottom: 30 }}>
                  <V3Title style={{ textAlign: "center" }}>
                    Conta criada com sucesso!
                  </V3Title>
                </S.RowCenter>
                <S.RowCenter style={{ marginBottom: 30 }}>
                  <V3Text5 style={{ color: v3Colors.black }}>
                    Agora é só entrar em sua conta.
                  </V3Text5>
                </S.RowCenter>
                <S.RowCenter>
                  <V3ButtonLink
                    href="/"
                    estilo="primary"
                    width={330}
                    text="Entrar"
                  />
                </S.RowCenter>
              </>
            ) : (
              <>
                <Backdrop open={loading} style={{ zIndex: 999 }}>
                  <div>
                    <Lottie animationData={Loading} />
                  </div>
                </Backdrop>
                <S.RowCenter style={{ marginTop: 30, marginBottom: 15 }}>
                  <V3Title>Criar Conta</V3Title>
                </S.RowCenter>
                {error && (
                  <S.Row>
                    <InfoModal
                      style={{ marginBottom: 15 }}
                      text="Os dados não correspondem às informações em nossos registros. Verifique-as e tente novamente."
                      type="error"
                    />
                  </S.Row>
                )}
                <S.Row style={{ marginTop: 15 }}>
                  <V3TextCustom
                    size="small"
                    sx={{ width: 350 }}
                    onChange={(e) => {
                      handleNomeRazao(e.target.value);
                    }}
                    label="Nome / Razão Social"
                    value={nomeRazao}
                    error={nomeRazaoErr}
                    helperText={nomeRazaoErr && "Preencha Nome /Razão Social."}
                    required
                  />
                </S.Row>
                <S.Row style={{ marginTop: 15 }}>
                  <V3TextCustom
                    size="small"
                    sx={{ width: 447 }}
                    label="CPF / CNPJ"
                    onChange={(e) => {
                      handleCpfCnpj(e.target.value);
                    }}
                    error={cpfCnpjErr}
                    value={cpfCnpj}
                    helperText={cpfCnpjErr && "CPF / CNPJ inválido."}
                    required
                  />
                </S.Row>
                <S.Row style={{ marginTop: 15 }}>
                  <V3TextCustom
                    size="small"
                    sx={{ width: 447 }}
                    label="Data de Nascimento"
                    onChange={(e) => {
                      handleDataDeNascimento(e.target.value);
                    }}
                    error={dtNascErr}
                    value={dtNasc}
                    helperText={dtNascErr && "Data de nascimento inválida."}
                    required
                  />
                </S.Row>
                <S.Row style={{ marginTop: 15 }}>
                  <V3TextCustom
                    sx={{ width: 447 }}
                    label="E-mail"
                    onChange={(e) => {
                      handleEmail(e.target.value);
                    }}
                    error={emailErr}
                    size="small"
                    helperText={emailErr && "Preencha o campo."}
                    value={email}
                    type="email"
                    required
                  />
                </S.Row>
                <S.Row style={{ marginTop: 15 }}>
                  <V3TextCustom
                    size="small"
                    label="Senha"
                    onChange={(e) => handlePass(e.target.value)}
                    sx={{
                      width: 447,
                    }}
                    value={password}
                    error={passwordErr}
                    type="password"
                  />
                </S.Row>
                {password && (
                  <>
                    <S.Row style={{ flexDirection: "column", paddingTop: 10 }}>
                      <V3Text5
                        style={{ fontWeight: "bold", color: v3Colors.black }}
                      >
                        Sua senha deve conter:
                      </V3Text5>
                      <V3Text5
                        style={{
                          color: errTamanho
                            ? v3Colors.black
                            : v3Colors.greenLight,
                        }}
                      >
                        ● No mínimo 10 caracteres
                        {!errTamanho && (
                          <BsCheck color={v3Colors.greenLight} size={20} />
                        )}
                      </V3Text5>
                      <V3Text5
                        style={{
                          color: errSimbolo
                            ? v3Colors.black
                            : v3Colors.greenLight,
                        }}
                      >
                        ● No mínimo 1 símbolo (!@#$%^&*)
                        {!errSimbolo && (
                          <BsCheck color={v3Colors.greenLight} size={20} />
                        )}
                      </V3Text5>
                      <V3Text5
                        style={{
                          color: errMaiusculas
                            ? v3Colors.black
                            : v3Colors.greenLight,
                        }}
                      >
                        ● Letras maiúsculas
                        {!errMaiusculas && (
                          <BsCheck color={v3Colors.greenLight} size={20} />
                        )}
                      </V3Text5>
                      <V3Text5
                        style={{
                          color: errMinisculas
                            ? v3Colors.black
                            : v3Colors.greenLight,
                        }}
                      >
                        ● Letras minúsculas
                        {!errMinisculas && (
                          <BsCheck color={v3Colors.greenLight} size={20} />
                        )}
                      </V3Text5>
                      <V3Text5
                        style={{
                          color: errNumeros
                            ? v3Colors.black
                            : v3Colors.greenLight,
                        }}
                      >
                        ● Números
                        {!errNumeros && (
                          <BsCheck color={v3Colors.greenLight} size={20} />
                        )}
                      </V3Text5>
                    </S.Row>
                  </>
                )}
                <S.Row style={{ marginTop: 15, marginBottom: 15 }}>
                  <V3TextCustom
                    sx={{ width: 447 }}
                    label="Confirme a senha"
                    onChange={(e) => {
                      handleCPass(e.target.value);
                    }}
                    error={cpassErr}
                    size="small"
                    helperText={cpassErr && "Senha não confere. Verifique-a."}
                    type="password"
                    value={cpass}
                    required
                  />
                </S.Row>
                <S.Row style={{ marginTop: 15, marginBottom: 15 }}>
                  <V3Text5
                    style={{ color: v3Colors.black, fontStyle: "italic" }}
                  >
                    * Preenchimento Obrigatório.
                  </V3Text5>
                </S.Row>
                <S.RowCenter>
                  <V3Button
                    onClick={handleCadastro}
                    estilo="primary"
                    width={"100%"}
                    text="Criar conta"
                  />
                </S.RowCenter>
                <S.RowCenter
                  style={{
                    paddingBottom: 30,
                    paddingTop: 30,
                    marginBottom: 30,
                    gap: 5,
                  }}
                >
                  <V3Text5 style={{ color: v3Colors.black }}>
                    Já tem conta?{" "}
                  </V3Text5>
                  <V3Link
                    href="/"
                    style={{ color: v3Colors.primary, fontWeight: "bold" }}
                  >
                    É só entrar
                  </V3Link>
                </S.RowCenter>
              </>
            )}
          </S.BoxLoginMobile>
        </S.Container>
      </div>
    </>
  );
};

export default withRouter(Cadastro);
