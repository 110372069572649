export const colors = {
  darkGreen: "#16898E",
  softDarkGreen: "#28A588",
  mediumGreen: "#20CFA7",
  black: "#14133B",
  graySoft: "#A2A2A2",
  gray: "#8F8F8F",
  darkGray: "#808080",
  hardGray: "#5b6674",
  lightGray: "#F2F2F2",
  bgPage: "#f8f9fa",
  border: "#bababa",
  softBox: "#DCDCDC",
  facebook: "#3b5998",
  linkedin: "#0e76a8",
  instagram: "#d62976",
  cardGreen: "#dff7fa",
  borderCardGreen: "#a5e7ea",
  altGreen: "#05898f",
  alert: '#e63946',
  info: "#0dcaf0",
};
