import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import api from "../../services/api";
import { Link } from "react-router-dom";
import SweetAlert from 'sweetalert2-react';
import Swal from 'sweetalert2'



export class WhatsAPPListaPendentes extends Component {



  constructor(){
        super();
        this.state = {
          pendentes: [],
          show: false
        };
  }

  async componentDidMount() {

        try {
          const response = await api.post("/api/concash/1.0/whatsapp-notificacao/listar", {});
          this.setState({ pendentes: response.data});
        } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter os dados."
          });
        }
  }



  render() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Mensagens Pendentes de Leitura </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th> Nome </th>
                          <th> CPF </th>
                          <th> E-mail </th>
                          <th className="text-center"> Ação </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.pendentes.map((pendente,index) => (
                        <tr key={index}>
                          <td> {pendente.nome}  </td>
                          <td> {pendente.cpf}   </td>
                          <td> {pendente.email}   </td>
                          <td className="text-center">
                              <Link
                                          to={{pathname: "/whatsapp-sessao/conversa",state: {
                                            codigoCliente: pendente.codigoCliente, codigoProspect: pendente.codigoProspect
                                          }}}
                                          className="m-3 btn btn-sm btn-success"
                                        >
                                        Conversa
                              </Link>
                          </td>
                        </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default WhatsAPPListaPendentes
