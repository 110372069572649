import axios from "axios";
import { getToken } from "./auth";
var nodeEnv = process.env.REACT_APP_API_CLIENTES_URL;
const api = axios.create({
  baseURL: nodeEnv || "https://hml.concash.com.br/clientes",
});

api.interceptors.request.use(async (config) => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
