import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import api from "../../services/api";
import { Link } from "react-router-dom";
import SweetAlert from 'sweetalert2-react';
import Swal from 'sweetalert2'
import Pagination from "../../components/Pagination";




export class BasicElements extends Component {



  constructor(){
        super();
        this.state = {
          usuarios: [],
          usuario_nome:'',
          usuario_email:'',
          currentPage:1,
          totalRecords:null,
          show: false
        };
  }

  async componentDidMount() {

        this.obtemListaUsuarios();
  }

  async obtemListaUsuarios(){

      try {

        const {
          currentPage,
          usuario_nome,
          usuario_email
        } = this.state;

        const response = await api.post("api/sistema/1.0/usuario/listar", { pagina: currentPage, email: usuario_email, name: usuario_nome });
        this.setState({ totalRecords: response.data.totalElements,usuarios: response.data.content});
      } catch (err) {
        this.setState({
          error:
            "Houve um problema para obter a lista de usuarios."
        });
      }

  }

  onPageChanged = data => {

    var classe = this;
    const { currentPage, totalPages, pageLimit } = data;
    this.setState({ currentPage },() => {
        classe.obtemListaUsuarios();
    });
  };

  handleUsuarioNome = async (e) => {
      e.preventDefault();
      var classe = this;
      this.setState({ usuario_email: "",totalRecords:null}, () => {
          classe.obtemListaUsuarios();
      });
  };

  handleKeyPressNome = (event) => {
    
    if(event.key === 'Enter'){
      var classe = this;
      this.setState({ usuario_email: "",totalRecords:null}, () => {
        classe.obtemListaUsuarios();
      });
    }
  }

  handleUsuarioEmail = async (e) => {
      e.preventDefault();
      var classe = this;
      this.setState({ usuario_nome: "",totalRecords:null}, () => {
          classe.obtemListaUsuarios();
      });
  };

  handleKeyPressEmail = (event) => {
    
    if(event.key === 'Enter'){
      var classe = this;
      this.setState({ usuario_nome: "",totalRecords:null}, () => {
        classe.obtemListaUsuarios();
      });
    }
  }



  handleDeletar = async (codigo,e) => {

    var classe = this;
    Swal.fire({
       title: 'Você tem certeza que deseja excluir esse registro?',
       text: 'Após exclusão não será possivel a reversão',
       icon: 'warning',
       showCancelButton: true,
       confirmButtonColor: '#3085d6',
       cancelButtonColor: '#d33',
       confirmButtonText: 'Confirmar!'
    }).then((result) => {
       if(result.value){
         classe.handleDeletarEfetivar(codigo,e);
      }
    })
  };

  handleDeletarEfetivar = async (id,e) => {

      e.preventDefault();
      if (!id) {
        this.setState({ error: "Código indefinido!" });
      } else {
        try {
           const response = await api.put("api/sistema/1.0/usuario/excluir", {
                id
           });
        } catch (err) {
          this.setState({
            error:
              "Houve um problema com a exclusão."
          });
        }
      }
  };

  render() {

    const {
      totalRecords
    } = this.state;


    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Usuários </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">

              <div className="row" style={{backgroundColor:'rgb(222, 226, 230)'}}>

                <div className="col-md-6" style={{marginTop:'30px'}}>
                    <div className="row">
                      <div className="col-md-9">
                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Nome</label>
                            <div className="col-sm-9">
                              <Form.Control type="text" style={{height:'30px'}} className="form-control" id="usuario_nome" onKeyPress={this.handleKeyPressNome} value={this.state.usuario_nome} onChange={e => this.setState({ usuaro_nome: e.target.value })} />
                            </div>
                          </Form.Group>
                      </div>
                      <div className="col-md-3">
                        <button type="submit" className="btn btn-primary mr-2" onClick={e =>this.handleUsuarioNome(e)}>Filtrar</button>
                      </div>
                    </div>
                  </div>
                <div className="col-md-6" style={{marginTop:'30px'}}>
                      <div className="row">
                        <div className="col-md-9">
                            <Form.Group className="row">
                              <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">E-mail</label>
                              <div className="col-sm-9">
                                <Form.Control type="text" style={{height:'30px'}} className="form-control" id="usuario_email" onKeyPress={this.handleKeyPressEmail} value={this.state.usuario_email} onChange={e => this.setState({ usuario_email: e.target.value })} />
                              </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3">
                          <button type="submit" className="btn btn-primary mr-2" onClick={e =>this.handleUsuarioEmail(e)}>Filtrar</button>
                        </div>
                      </div>
                  </div>
                </div>


                  <p className="card-description text-right">
                                <Link
                                  to={{pathname: "/usuario/detalhe"}}
                                  className="m-3 btn btn-sm btn-success"
                                >
                                Cadastrar
                      </Link></p>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th> Nome </th>
                          <th> E-mail </th>
                          <th className="text-center"> Ação </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.usuarios.map((usuario,index) => (
                        <tr key={index}>
                          <td> {usuario.name}  </td>
                          <td> {usuario.email}  </td>
                          <td className="text-center"><Link
                                          to={{pathname: "/usuario/detalhe",state: {
                                            usuario: usuario
                                          }}}
                                          className="m-3 btn btn-sm btn-success"
                                        >
                                        Editar
                              </Link>
                              <Link
                                              to={{pathname: "/usuario/configurar",state: {
                                                usuario: usuario
                                              }}}
                                              className="m-3 btn btn-sm btn-success"
                                            >
                                            Configurar
                              </Link>

                              <Link
                                              to={{pathname: "/usuario/visualizacao",state: {
                                                usuario: usuario
                                              }}}
                                              className="m-3 btn btn-sm btn-success"
                                            >
                                            Regras
                              </Link>

                              <Link
                                className="m-3 btn btn-sm btn-danger"
                                onClick={e =>this.handleDeletar(usuario.id,e)}
                              >
                                Deletar
                              </Link>
                          </td>
                        </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div style={{
                                display: 'flex',
                                marginTop: '50px',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                  {!!this.state.totalRecords &&
                  <Pagination
                    totalRecords={totalRecords}
                    pageLimit={20}
                    pageNeighbours={1}
                    onPageChanged={this.onPageChanged}
                  />
                 }
                 </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BasicElements
