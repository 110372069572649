import React, { useState } from 'react';
import S from "./Cota.module.css";
import MainStyle from "../../../Autoservico.module.css";
import { ButtonMain } from '../../buttons';
import { RiCloseLine } from 'react-icons/ri';
import { IoMdCheckmark } from 'react-icons/io';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { BsExclamationDiamond, BsQuestionCircle } from 'react-icons/bs';
import { IoChevronDownSharp, IoChevronUpSharp } from "react-icons/io5";


export const CotaCard = ({ estilo, cota, vendida }) => {
  const [ showStatus, setShowStatus ] = useState(false);
  const service = window.location.pathname.replace("/autoservico/", "").split("/");

  const corEtapa = {
    "Finished": "#00B423",
    "Running": "#FFF",
    "NotStarted": "#FFF",
    "Error": "#FF0000",
    "Pendency": "#FF0000",
    "ExpiredSLA": "#FF0000",
  }
  const bordaEtapa = {
    "Finished": "#00B423",
    "Running": "#EFA71F",
    "NotStarted": "#AFAFAF",
    "Error": "#FF0000",
    "Pendency": "#FF0000",
    "ExpiredSLA": "#FF0000",
  }
  const iconeEtapa = {
    "Finished": <IoMdCheckmark size={18} color="#FFF" />,
    "Running": <HiOutlineDotsVertical  size={18} color="#FFF" />,
    "NotStarted": <HiOutlineDotsVertical  size={18} color="#FFF" />,
    "Error": <RiCloseLine size={18} color="#FFF" />,
    "Pendency": <BsExclamationDiamond size={18} color="#FFF" />,
    "ExpiredSLA": <BsExclamationDiamond size={18} color="#FFF" />,
  }

  return (
    <div className={S.cardBox}>
      <div>
        <h1 className={S.cotaTitle}>{cota.adm}</h1>
      </div>
      <div className={S.cotaDados}>
        <div style={{ display: "flex"}}>
          <div style={{ minWidth: 84, fontWeight: 'bold', fontSize: 15}}><span>Grupo</span></div>
           <span>{cota.grupo}</span>
        </div>
        <div style={{ display: "flex"}}>
          <div style={{ minWidth: 84, fontWeight: 'bold', fontSize: 15}}><span>Cota</span></div>
           <span>{cota.cota}</span>
        </div>
        <div style={{ display: "flex"}}>
          <div style={{ minWidth: 84, fontWeight: 'bold', fontSize: 15}}><span>Contrato</span></div>
           <span>{cota.contrato}</span>
        </div>
      </div>
      <div>
        {cota?.vendida ? (
          <>
        {
          cota?.pago ? (
            <div className={S.valoresRow}>
            <div className={S.boxValores}>
              <span className={MainStyle.text15}>Valor da venda:</span>
              <span className={MainStyle.title24} style={{ textAlign: "left"}}>{new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(cota?.valorVenda)}</span>
              <span className={MainStyle.text15}>Pagamento: <b>Confirmado!</b></span>
            </div>
  
              {/* <div>
              <BsQuestionCircle size={20} />
              </div> */}
            </div>
          ) : (
            <div className={S.valoresRow}>
            <div className={S.boxValores}>
              <span className={MainStyle.text15}>Valor da venda:</span>
              <span className={MainStyle.title24} style={{ textAlign: "left"}}>{new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(cota?.valorVenda)}</span>
              <span className={MainStyle.text15}>Pagamento à vista em até <b>10 dias úteis</b></span>
            </div>
  
              {/* <div>
              <BsQuestionCircle size={20} />
              </div> */}
            </div>
          )
        }

          {/* <div className={S.valoresRow}>

          <div className={S.boxValores}>
            <span className={MainStyle.text15}>Valor da venda:</span>
            <span className={MainStyle.title24} style={{ textAlign: "left"}}>{new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(cota?.valorVenda)}</span>
            <span className={MainStyle.text15}>Pagamento à vista em até <b>10 dias úteis</b></span>
          </div>

            <div>
            <BsQuestionCircle size={20} />
            </div>
          </div> */}
        <div style={{ paddingTop:10, marginBottom: 20}}>
           {cota?.etapas.map((etapa) => (
                  <div style={{ display: "flex", gap: 10, alignItems: "center", marginTop: 10 }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: corEtapa[etapa.status], border: `2px solid ${bordaEtapa[etapa.status]}`, borderRadius:"50%" }}>
                      {iconeEtapa[etapa.status]}
                    </div>
                    <span className={S.text15} style={{ color: bordaEtapa[etapa.status] }}>{etapa.descricao}</span>
                  </div>
                 ))}
        </div>
        <div style={{ paddingTop:20, paddingBottom: 20,   borderTop: "1px solid #dedede"}}>
          {cota?.linkAssinatura ? (
            <>
            <div style={{ display: "flex", justifyContent: "space-between", maxHeight: 150, marginBottom: 10}}>
                <div className={S.actionLeft}>
                  <h3 className={S.textDestak} style={{ color: estilo?.primary}}>Assinar</h3>
                </div>
              <div>
                <ButtonMain estilo={estilo} texto={"Assinar contrato"} tamanho={134} onClick={() => window.location.href = `/autoservico/${service[0]}/assinar/${cota?.idAtendimento}`} />
              </div>
            </div>
            <span className={S.text15} >{cota?.dadosVenda.mensagem}</span>
            </>
          ) : (
          <span className={S.text15} >{cota?.mensagem}</span>
          )}
        </div>
          </>
        ) : (
          <>
          <div  className={S.actionCota}>
            {cota?.propostas.length > 0 
            && cota?.propostas[0].valorProposta 
            ? (
              <> 
               <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center"}} >
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between"}} onClick={() => setShowStatus(!showStatus)}>
                  {cota?.statusAtendimento == 11 ? (
                                      <div className={S.actionLeft}>
                                      <h3 className={S.textDestak} style={{ color: estilo?.primary}}>{window.innerWidth > 768 ? "Aceitou proposta" : "Aceitou"}</h3>
                                      <span className={S.subTextAction}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(cota?.valorVenda))}</span>
                                  </div>
                  ) : (
                  <div className={S.actionLeft}>
                  <h3 className={S.textDestak} style={{ color: estilo?.primary}}>{cota?.propostas.length > 1 ?  `${cota?.propostas.length} Propostas` : `${cota?.propostas.length} Proposta` }</h3>
                  <span className={S.subTextAction}>nesta cota</span>
              </div>
                  )}

                </div>
                  <div style={{ display: "flex", padding: 15, alignItems: "center", justifyContent: "center"}} onClick={() => setShowStatus(!showStatus)}>
                    {showStatus ? (<IoChevronUpSharp size={24} />) : (<IoChevronDownSharp size={24} />)}
                    
                  </div>
                  <div>
                    <ButtonMain estilo={estilo} texto={cota?.statusAtendimento == 11 ? "Acompanhar venda" : "Ver propostas"} tamanho={134} onClick={() => window.location.href = `/autoservico/${service[0]}/minhas-cotas/${cota.idAtendimento}`} />
                  </div>
              </div>
              {showStatus && (
                cota?.etapas.map((etapa) => {
                  if(etapa.fase === "ContractInformation" && etapa.status === "NotStarted" && !etapa?.concluido && cota?.statusAtendimento == 11) {
                    return(
                      <div style={{ display: "flex", gap: 10, alignItems: "center", marginTop: 10 }}>
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: corEtapa["Running"], border: `2px solid ${bordaEtapa["Running"]}`, borderRadius:"50%" }}>
                        {iconeEtapa["Running"]}
                      </div>
                      <span className={S.text15} style={{ color: bordaEtapa["Running"] }}>Você aceitou a proposta - Em processamento</span>
                    </div>
                    )
                  } else {
                    return (
                      <div style={{ display: "flex", gap: 10, alignItems: "center", marginTop: 10 }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: corEtapa[etapa.status], border: `2px solid ${bordaEtapa[etapa.status]}`, borderRadius:"50%" }}>
                          {iconeEtapa[etapa.status]}
                        </div>
                        <span className={S.text15} style={{ color: bordaEtapa[etapa.status] }}>{etapa.descricao}</span>
                      </div>
                     )
                  }
                })
              )}
              </>
            ) : (
              <>
              <div className={S.actionLeft}>
                <h3 className={S.textDestak} style={{ color: estilo?.primary}}>{`Sem propostas`}</h3>
                <span className={S.subTextAction}>nesta cota</span>
              </div>
              </>
            )}
        </div>
          </>
        )}
      </div>
    </div>
  );
}

