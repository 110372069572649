import React from 'react';
import { IoMdArrowDropright } from 'react-icons/io';

// import { Container } from './styles';

function TitlePage({title}) {
  return (
    <div className="page-header">
    <div className='title-box'>
    <IoMdArrowDropright  /> 
    <h2 className="page-title"> {title} </h2>
    </div>
  </div>
  )
}

export default TitlePage;