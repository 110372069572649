import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import api from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import { Link, withRouter } from "react-router-dom";
import IntlCurrencyInput from "react-intl-currency-input";
import cep from "cep-promise";

const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};

export class SolicitarReanalise extends Component {
    constructor(props) {
        super(props);

        const { codigoCota } = this.props;

        this.state = {
            codigoCota: codigoCota,
            reanalises: [],
            error: "",
        };
    }

    async componentDidMount() {
        this.obtemReanalises(this.state.codigoCota);
    }

    handleChangeValorContraProposta = (event, value, maskedValue) => {
        event.preventDefault();
        this.setState({
            valorContraProposta: maskedValue
                .replace("R$", "")
                .replace(".", "")
                .replace(",", ".")
                .trim(),
        });
    };

    async obtemReanalises(codigo) {
        try {
            const response = await api.post(
                "api/concash/1.0/consorcio-grupo-cota-reanalise/listar",
                { contrato: codigo }
            );
            this.setState({ reanalises: response.data });
        } catch (err) {
            this.setState({
                error: "Houve um problema para obter as reanalises.",
            });
        }
    }

    handleFormReanalise = async (e) => {
        e.preventDefault();
        try {
            let codigo = this.state.codigoCota;
            
            let codigoProposta = this.state.codigoProposta;
            let contraproposta = this.state.valorContraProposta;
            const response = await api.post(
                "api/concash/1.0/consorcio-grupo-cota-reanalise/adicionar",
                {
                    codigoCota: codigo,
                    codigoProposta: codigoProposta,
                    contraproposta,
                }
            );

            this.obtemReanalises(codigo);
            Swal.fire("Reanálise solicitada com sucesso!");
        } catch (err) {
            this.setState({
                error: "Houve um problema com o processamento da requisição.",
            });
        }
    };

    handleGerarProposta = async (codigo, e) => {
        e.preventDefault();
        try {
            const response = await api.put(
                "api/concash/1.0/consorcio-grupo-cota-reanalise/transferir",
                {
                    codigo: codigo,
                }
            );

            this.obtemReanalises(codigo);
            Swal.fire("Proposta gerada com sucesso!");
        } catch (err) {
            this.setState({
                error: "Houve um problema com o processamento da requisição.",
            });
        }
    };

    render() {
        return (
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Solicitar Reanálise</h4>
                            <p className="card-description"></p>

                            <form
                                className="forms-sample"
                                onSubmit={this.handleFormReanalise}
                            >
                                <div className="row">
                                    <div className="col-md-12 grid-margin stretch-card">
                                        <div className="card">
                                            {this.state.reanalises.length <
                                                1 && (
                                                <div className="card-body">
                                                    <h4 className="card-title"></h4>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <Form.Group className="row">
                                                                <label className="col-sm-5 col-form-label">
                                                                    Valor
                                                                    Contra-Proposta
                                                                </label>
                                                                <div className="col-sm-7">
                                                                    <IntlCurrencyInput
                                                                        currency="BRL"
                                                                        value={
                                                                            this
                                                                                .state
                                                                                .valorContraProposta
                                                                        }
                                                                        config={
                                                                            currencyConfig
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .handleChangeValorContraProposta
                                                                        }
                                                                    />
                                                                </div>
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="text-right">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary mr-2"
                                                            >
                                                                Solicitar
                                                                Reanálise
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {this.state.reanalises.length >
                                                0 && (
                                                <div className="card-body">
                                                    <h4 className="card-title">
                                                        Reanálises Solicitadas
                                                    </h4>

                                                    <div className="table-responsive">
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        {" "}
                                                                        Código{" "}
                                                                    </th>
                                                                    <th>
                                                                        {" "}
                                                                        Status{" "}
                                                                    </th>
                                                                    <th>
                                                                        {" "}
                                                                        Valor{" "}
                                                                    </th>
                                                                    <th className="text-center">
                                                                        {" "}
                                                                        Ação{" "}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.reanalises.map(
                                                                    (
                                                                        reanalise,
                                                                        index
                                                                    ) => (
                                                                        <tr
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <td>
                                                                                {" "}
                                                                                {
                                                                                    reanalise.codigo
                                                                                }{" "}
                                                                            </td>
                                                                            <td>
                                                                                {" "}
                                                                                {
                                                                                    reanalise.situacao
                                                                                }{" "}
                                                                            </td>
                                                                            <td>
                                                                                {" "}
                                                                                {
                                                                                    reanalise.valor
                                                                                }{" "}
                                                                            </td>
                                                                            <td className="text-center">
                                                                                {reanalise.situacao ==
                                                                                    "REANALISE_APROVADA" && (
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-primary mr-2"
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            this.handleGerarProposta(
                                                                                                reanalise.codigo,
                                                                                                e
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Gerar
                                                                                        Proposta
                                                                                    </button>
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SolicitarReanalise;
