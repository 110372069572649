import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { v3Colors } from "../../v3/colors";
import { Autocomplete, TextField } from "@mui/material";
import { colors } from "../../../colors";
import { styled as wstyled } from "@mui/system";
import {
  ButtonMain,
  ButtonOutlined,
} from "../../../../pages/AutoServico/components/buttons";

export const V3TextInput = (props) => {
  const { label, width } = props;

  return (
    <>
      <V3StyledText
        label={label}
        sx={{ width: width }}
        activeBorderColor="green"
      ></V3StyledText>
    </>
  );
};

const V3StyledText = wstyled(TextField)(({ theme }) => ({
  borderColor: "#ABABAB !important",

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: `10px`,
      borderColor: "#ABABAB",
      borderWidth: `2px`,
      color: "#979797 !important",
      fontWeight: "400",
      margin: 0,
      padding: 0,
    },
    "&.Mui-focused": {
      backgroundColor: v3Colors.inputDisabled,
      borderColor: "#ABABAB !important",
      borderRadius: `10px`,
      borderWidth: `2px`,

      color: v3Colors.black,
      fontWeight: "400",
    },
  },
}));

export const V3Autocomplete = wstyled(Autocomplete)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "10px",
    maxHeight: 45,
    paddingBottom: 5,
    display: "flex",
    flex: 1,
    justifyContent: "center",
    textAlign: "center",
    font: "normal normal normal 15px/20px Roboto",
    letterSpacing: 0,
    color: "#979797",
    opacity: 1,
  },

  "&.Mui-disabled": {
    backgroundColor: "red",
    borderColor: "#ABABAB",
    borderRadius: `10px`,

    color: v3Colors.black,
    fontWeight: "bold",
  },

  "& .MuiFormControl-fullWidth": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
  },

  "& .MuiOutlinedInput-root": {
    padding: 0,
    "& fieldset": {
      borderRadius: `10px`,
      borderColor: "#ABABAB",
      borderWidth: `1px`,
      color: "#979797 !important",
      fontWeight: "bold",
    },
    "&.Mui-focused": {
      backgroundColor: v3Colors.inputDisabled,
      borderColor: "#ABABAB",
      borderRadius: `10px`,

      color: v3Colors.black,
      fontWeight: "bold",
    },
  },
}));
export const V3AutocompleteWhite = wstyled(Autocomplete)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "10px",
    maxHeight: 45,
    paddingBottom: 5,
    display: "flex",
    flex: 1,
    justifyContent: "center",
    textAlign: "center",
    font: "normal normal normal 15px/20px Roboto",
    letterSpacing: 0,
    color: "#979797",
    backgroundColor: "#FFF",
    opacity: 1,
    width: 260,
  },

  "& .MuiFormControl-fullWidth": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
  },

  "& .MuiOutlinedInput-root": {
    padding: 0,
    "& fieldset": {
      borderRadius: `10px`,
      borderColor: "#ABABAB",
      borderWidth: `1px`,
      color: "#979797 !important",
      fontWeight: "bold",
    },
    "&.Mui-focused": {
      backgroundColor: v3Colors.inputDisabled,
      borderColor: "#ABABAB",
      borderRadius: `10px`,

      color: v3Colors.black,
      fontWeight: "bold",
    },
  },
}));

export const V3Select = (props) => {
  const { options, id, name, action, label, width, disabled } = props;
  const [value, setValue] = useState("");

  return (
    <>
      <V3Autocomplete
        id={id}
        sx={{
          bgcolor: v3Colors.white,
          borders: 0,
          display: "flex",
          borderColor: "transparent !important",
          width: width,
          maxHeight: 45,
          alignItems: "center",
          justifyContent: "center",
        }}
        options={options}
        onChange={(event, newValue) => {
          setValue(newValue);
          action(newValue);
        }}
        value={value}
        disabled={disabled}
        renderInput={(params) => (
          <V3TextCustom
            label={label}
            {...params}
            sx={{
              border: 0,
              padding: 0,
            }}
          />
        )}
      />
    </>
  );
};

export const V3CustomTextField = (props) => {
  const { width, label, required, onChange, value } = props;

  const V3CustomTextFieldStyled = wstyled(TextField)(({ theme }) => ({
    borderRadius: 20,
    root: {
      "& .Mui-focused": {
        borderRadius: `10px`,
        borderColor: "#ABABAB",
        color: "#979797 !important",
      },
      "& .MuiInputAdornment-sizeSmall": {
        borderRadius: `10px`,
        borderColor: "#ABABAB",
        color: "#979797 !important",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: `10px`,
          borderColor: "#ABABAB",
          color: "#979797 !important",
          width: width,
        },
      },
    },
  }));

  return (
    <V3CustomTextFieldStyled
      style={{}}
      size="small"
      onChange={onChange}
      label={label}
      required={required}
      value={value}
    />
  );
};

export const V3TextCustom = wstyled(TextField)(({ theme }) => ({
  "& .Mui-focused": {
    borderRadius: `10px`,
    borderColor: "#ABABAB",
    color: "#979797 !important",
  },
  "& .MuiInputAdornment-sizeSmall": {
    borderRadius: `10px`,
    borderColor: "#ABABAB",
    color: "#979797 !important",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: `10px`,
      borderColor: "#ABABAB",
      color: "#979797 !important",
    },
  },
}));

export const V3TextCustomOTP = wstyled(TextField)(({ theme }) => ({
  "& .Mui-focused": {
    minWidth: "260px !important",
    borderRadius: `10px`,
    borderColor: "#ABABAB",
    color: "#000",
  },
  "& .MuiInputAdornment-sizeSmall": {
    minWidth: "260px !important",

    backgroundColor: "rgba(255, 255, 255, 0.9)",
    borderRadius: `10px`,
    borderColor: "#ABABAB",
    color: "#000",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      minWidth: "260px !important",
      backgroundColor: "rgba(255, 255, 255, 0.9)",
      borderRadius: `10px`,
      borderColor: "#ABABAB",
      color: "#000",
    },
  },
}));

export const V3FileInput = (props) => {
  const { label, width, action, recuo, onChange, fileName } = props;
  const [file, setFile] = useState("");

  function handleFile(e) {
    const arquivo = e.target.files[0];
    if (e.target.files[0]) {
      setFile(arquivo);
      action(arquivo);
    } else {
      setFile(null);
      action(null);
    }
  }

  const FileInput = styled.input`
    display: none !important;
  `;

  const Label = styled.label`
    display: flex;
    cursor: pointer;
  `;

  const Text = styled.span`
    text-align: left;
    font: normal normal normal 13px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  `;

  const GradBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent linear-gradient(180deg, #ffffff 0%, #dfdfdf 100%) 0%
      0% no-repeat padding-box !important;
    box-shadow: 0px 1px 3px #00000066;
    border-radius: 5px;
    width: 117px;
    height: 25px;
  `;

  return (
    <>
      <label class="input-personalizado">
        <div
          style={{
            display: "flex",

            height: 45,
            width: width,
            justifyContent: "space-between",
            border: "1px solid #979797",
            alignItems: "center",
            paddingRight: 10,
            paddingLeft: 10,
            borderRadius: 10,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Text>{fileName ? fileName : file?.name}</Text>
          </div>
          <div
            style={{
              position: "relative",
              top: -23,
              left: recuo ? recuo : -135,
              padding: 3,
              backgroundColor: "#FFF",
            }}
          >
            <Text>{label}</Text>
          </div>

          <GradBox>
            <Text>Escolher Arquivo</Text>
          </GradBox>
          <FileInput
            size="small"
            type="file"
            onChange={onChange}
            // onChange={(e) => handleFile(e)}
            class="input-file"
            accept=".pdf"
          />
        </div>
      </label>
    </>
  );
};

export const V3FileInputMobile = (props) => {
  const { label, width, action, recuo, onChange, fileName } = props;
  const [file, setFile] = useState("");

  function handleFile(e) {
    const arquivo = e.target.files[0];
    if (e.target.files[0]) {
      setFile(arquivo);
      action(arquivo);
    } else {
      setFile(null);
      action(null);
    }
  }

  const FileInput = styled.input`
    display: none !important;
  `;

  const Label = styled.label`
    display: flex;
    cursor: pointer;
  `;

  const Text = styled.span`
    text-align: left;
    font: normal normal normal 13px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  `;

  const GradBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent linear-gradient(180deg, #ffffff 0%, #dfdfdf 100%) 0%
      0% no-repeat padding-box !important;
    box-shadow: 0px 1px 3px #00000066;
    border-radius: 5px;
    width: 117px;
    height: 25px;
  `;

  return (
    <>
      <label class="input-personalizado" style={{ display: "flex", flex: 1 }}>
        <div
          style={{
            display: "flex",

            height: 45,
            width: width,
            justifyContent: "space-between",
            border: "1px solid #979797",
            alignItems: "center",
            paddingRight: 10,
            paddingLeft: 10,
            borderRadius: 10,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Text>{fileName ? fileName : file?.name}</Text>
          </div>
          <div
            style={{
              position: "relative",
              top: -23,
              left: recuo ? recuo : -135,
              padding: 3,
              backgroundColor: "#FFF",
            }}
          >
            {!fileName && <Text style={{ fontSize: 8 }}>{label}</Text>}
          </div>

          <GradBox>
            <Text>Escolher Arquivo</Text>
          </GradBox>
          <FileInput
            size="small"
            type="file"
            onChange={onChange}
            // onChange={(e) => handleFile(e)}
            class="input-file"
            accept=".pdf"
          />
        </div>
      </label>
    </>
  );
};

export const AutoServicoFileInput = ({
  label,
  width,
  action,
  isWhite,
  onChange,
  accept,
  estilo,
}) => {
  const [file, setFile] = useState("");

  function handleFile(e) {
    const arquivo = e.target.files[0];
    if (e.target.files[0]) {
      setFile(arquivo);
      action(arquivo);
    } else {
      setFile(null);
      action(null);
    }
  }

  const FileInput = styled.input`
    display: none !important;
  `;

  const Label = styled.label`
    display: flex;
    cursor: pointer;
  `;

  return (
    <>
      <label
        class="input-personalizado"
        style={{
          display: "flex",
          flex: 1,
          width: "100%",
        }}
      >
        {isWhite ? (
          <ButtonOutlined texto={label} tamanho={width} estilo={estilo} />
        ) : (
          <ButtonMain texto={label} tamanho={width} estilo={estilo} />
        )}
        <FileInput
          size="small"
          type="file"
          onChange={onChange}
          // onChange={(e) => handleFile(e)}
          class="input-file"
          accept={accept}
        />
      </label>
    </>
  );
};

export const V3Searcheable = ({
  options,
  id,
  name,
  action,
  label,
  initial,
}) => {
  const [value, setValue] = React.useState();

  const [etiqueta, setEtiqueta] = useState(initial ? initial.label : label);
  useEffect(() => {
    setValue(initial);
  }, []);

  useEffect(() => {}, [value]);

  return (
    <>
      <Autocomplete
        id={id}
        sx={{
          bgcolor: colors.lightGray,
          borders: 0,
          display: "flex",
          borderRadius: 15,
          borderColor: "transparent !important",
          flex: 1,
        }}
        options={options}
        defaultValue={initial}
        defaultChecked
        onChange={(event, newValue) => {
          if (!newValue) {
            setValue(null);
            action(null);
          } else {
            setValue(newValue);
            action(newValue);
          }
        }}
        value={value}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            label={initial ? initial.label : label}
            {...params}
            sx={{
              borderRadius: 15,
              bgcolor: colors.lightGray,
              border: 0,
              display: "flex",
              flex: 1,
            }}
          />
        )}
      />
    </>
  );
};

export const InputCustomizedWhite = styled.input`
  display: flex;
  height: 45px;
  background-color: #fff;
  border: 1px solid #cecece;
  border-radius: 12px;
  padding: 5px 12px;
`;
