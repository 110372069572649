// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  border-collapse: collapse;
  width: 100%;
  color: #000000;
}

td,
th {
  font: normal normal normal 15px/20px Roboto;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.green-box {
  display: flex;
  margin-left: 30px;
}

.mid-box {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  margin-left: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ConsorcioGrupoCota/style.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,cAAc;AAChB;;AAEA;;EAEE,2CAA2C;EAC3C,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,SAAO;EACP,sBAAsB;EACtB,iBAAiB;AACnB","sourcesContent":["table {\n  border-collapse: collapse;\n  width: 100%;\n  color: #000000;\n}\n\ntd,\nth {\n  font: normal normal normal 15px/20px Roboto;\n  text-align: left;\n  padding: 8px;\n}\n\ntr:nth-child(even) {\n  background-color: #dddddd;\n}\n\n.green-box {\n  display: flex;\n  margin-left: 30px;\n}\n\n.mid-box {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  margin-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
