import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Line, Doughnut, Bar, Pie } from "react-chartjs-2";
import { Sparklines, SparklinesBars } from "react-sparklines";
import { ProgressBar, Dropdown } from "react-bootstrap";
import api from "../../services/api";
// import DatePicker from 'react-datepicker';
// import { Dropdown } from 'react-bootstrap';
import "./styles.css";

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      inputValue: "",
      areaData: [],
      barData: [],
      doughnutPieData: [],
      // isMobile: true,
    };
  }

  ajustarTela() {
    const tamanho = window.innerWidth;
    console.log(tamanho);
    if (tamanho <= 800) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  }

  async componentDidMount() {
    const tamanho = window.innerWidth;
    console.log(tamanho);
    if (tamanho <= 800) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }

    try {
      const response = await api.post(
        "api/concash/1.0/grafico-prospect-cliente/listar",
        {}
      );
      const dataClienteMes = {
        labels: response.data.label,
        datasets: response.data.dados,
      };
      this.setState({ areaData: dataClienteMes });

      const responseCota = await api.post(
        "api/concash/1.0/grafico-mes-cota/listar",
        {}
      );
      const dataCotaMes = {
        labels: responseCota.data.label,
        datasets: responseCota.data.dados,
      };
      this.setState({ barData: dataCotaMes });

      const responseCotaStatus = await api.post(
        "api/concash/1.0/grafico-cota-status/listar",
        {}
      );

      const vDoughnutPieData = {
        datasets: [
          {
            data: responseCotaStatus.data.totais,
            backgroundColor: [
              "rgba(255, 99, 132, 0.5)",
              "rgba(54, 162, 235, 0.5)",
              "rgba(255, 206, 86, 0.5)",
              "rgba(75, 192, 192, 0.5)",
              "rgba(153, 102, 255, 0.5)",
              "rgba(255, 159, 64, 0.5)",
              "rgba(255, 109, 61, 0.5)",
              "rgba(111, 129, 56, 0.5)",
              "rgba(30, 139, 32, 0.5)",
            ],
            borderColor: [
              "rgba(255,99,132,1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
              "rgba(255, 109, 61, 1)",
              "rgba(111, 129, 56, 1)",
              "rgba(30, 139, 32, 1)",
            ],
          },
        ],

        labels: responseCotaStatus.data.label,
      };

      this.setState({ doughnutPieData: vDoughnutPieData });
    } catch (err) {
      this.setState({
        error: "Houve um problema para obter os parâmetros.",
      });
    }
  }

  componentWillUnmount() {
    const tamanho = window.innerWidth;
    console.log(tamanho);
    if (tamanho <= 800) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  }

  options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  areaOptions = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      yAxes: [
        {
          gridLines: {
            color: "#F2F6F9",
          },
          ticks: {
            beginAtZero: true,
            min: 0,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: "#F2F6F9",
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    elements: {
      point: {
        radius: 2,
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    stepsize: 1,
  };
  usersDoughnutChartData = {
    datasets: [
      {
        data: [80, 34, 100],
        backgroundColor: ["#19d895", "#2196f3", "#dde4eb"],
        borderColor: ["#19d895", "#2196f3", "#dde4eb"],
      },
    ],
    labels: ["Request", "Email"],
  };

  usersDoughnutChartOptions = {
    cutoutPercentage: 70,
    animationEasing: "easeOutBounce",
    animateRotate: true,
    animateScale: false,
    responsive: true,
    maintainAspectRatio: true,
    showScale: true,
    legend: {
      display: false,
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
  };

  amountDueBarData = {
    labels: [
      "Day 1",
      "Day 2",
      "Day 3",
      "Day 4",
      "Day 5",
      "Day 6",
      "Day 7",
      "Day 8",
      "Day 9",
      "Day 10",
    ],
    datasets: [
      {
        label: "Profit",
        data: [39, 19, 25, 16, 31, 39, 12, 18, 33, 24],
        backgroundColor: [
          "#2196f3",
          "#2196f3",
          "#2196f3",
          "#2196f3",
          "#2196f3",
          "#2196f3",
          "#2196f3",
          "#2196f3",
          "#2196f3",
          "#2196f3",
        ],
        borderColor: [
          "#2196f3",
          "#2196f3",
          "#2196f3",
          "#2196f3",
          "#2196f3",
          "#2196f3",
          "#2196f3",
          "#2196f3",
          "#2196f3",
          "#2196f3",
        ],
        borderWidth: 2,
        fill: true,
      },
    ],
  };

  amountDueBarOptions = {
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },

    scales: {
      responsive: true,
      maintainAspectRatio: true,
      yAxes: [
        {
          display: false,
          gridLines: {
            color: "rgba(0, 0, 0, 0.03)",
          },
        },
      ],
      xAxes: [
        {
          display: false,
          barPercentage: 0.4,
          gridLines: {
            display: false,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
  };

  doughnutPieOptions = {
    responsive: true,
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };

  render() {
    return (
      <div
        className="row"
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <img
          src={require("../../assets/images/logo-dashboard.png")}
          style={{ width: "70%" }}
          alt="logo"
        />
        {this.state.isMobile ? (
          <div>
            <a href="/backoffice/detalhamento-cliente"></a>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}
export default withRouter(Dashboard);
