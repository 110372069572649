import React, { Component } from "react";
import { Form } from "react-bootstrap";
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";
import { AlteraNome } from "../../actions";
import styled, { css } from "styled-components";
import Endereco from "../../components/Endereco";
import Telefone from "../../components/Telefone";
import Banco from "../../components/Banco";
import Proposta from "../../components/Proposta";
// import CotaInfo from "../../components/CotaInfo";
import ConverteCliente from "../../components/ConverteCliente";
import Swal from "sweetalert2";

const BoxInformativo = styled.div`
  text-align: center;
  color: white;
  padding: 10px;
  background: #2196f3;
  width: 150px;
  height: 40px;
  margin-top: 20px;
  border-radius: 10px 10px 0px 0px;
  cursor: pointer;

  :hover {
    background: #16898e;
  }
`;

export class ComercialDetalhamentoProspect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      prospect: [],
      faleConosco: [],
      extrato: [],
      statusProspect: "",
      blocoProspect: true,
      blocoFaleConosco: false,
      blocoExtrato: false,
      blocoAcao: false,
      backgroundProspect: "#ccc",
      backgroundFaleConosco: "#2196f3",
      backgroundExtrato: "#2196f3",
      backgroundAcao: "#2196f3",
    };
  }

  async componentDidMount() {
    if (this.props[0].location.state !== undefined) {
      const codigo = this.props[0].location.state.codigo;
      this.obtemProspect(codigo);
    }
  }

  async obtemProspect(codigo) {
    try {
      const response = await api.post("api/concash/1.0/prospect/detalhe", {
        codigo: codigo,
      });

      this.setState({
        prospect: response.data,
        faleConosco: response.data.faleConosco,
        extrato: response.data.extrato,
      });
    } catch (err) {
      this.setState({
        error: "Houve um problema para obter o prospect.",
      });
    }
  }

  handleFormOpenExtrato = async (e) => {
    e.preventDefault();
    try {
      Swal.fire({
        title: "Aguarde ...",
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      let arquivo = this.state.extrato.arquivo;
      const response = await api.post(
        "api/concash/1.0/prospect/download",
        {
          arquivo: arquivo,
        },
        { responseType: "blob" }
      );

      const file = new Blob([response.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;

      Swal.close();
    } catch (err) {
      this.setState({
        error: "Houve um problema com o processamento da requisição.",
      });
    }
  };

  alteraAbas = (aba) => {
    const abas = ["Prospect", "Fale Conosco", "Extrato", "Ação"];

    var tmp_prospect = false;
    var tmp_fale_conosco = false;
    var tmp_extrato = false;
    var tmp_acao = false;
    var bck_prospect = "#2196f3";
    var bck_fale_conosco = "#2196f3";
    var bck_extrato = "#2196f3";
    var bck_acao = "#2196f3";

    for (var i = 0; i < abas.length; i++) {
      if (aba == "Prospect") {
        tmp_prospect = true;
        bck_prospect = "#ccc";
      }
      if (aba == "Fale Conosco") {
        tmp_fale_conosco = true;
        bck_fale_conosco = "#ccc";
      }
      if (aba == "Extrato") {
        tmp_extrato = true;
        bck_extrato = "#ccc";
      }
      if (aba == "Ação") {
        tmp_acao = true;
        bck_acao = "#ccc";
      }
    }

    this.setState({
      blocoProspect: tmp_prospect,
      blocoFaleConosco: tmp_fale_conosco,
      blocoExtrato: tmp_extrato,
      blocoAcao: tmp_acao,
    });
    this.setState({
      backgroundProspect: bck_prospect,
      backgroundFaleConosco: bck_fale_conosco,
      backgroundExtrato: bck_extrato,
      backgroundAcao: bck_acao,
    });
  };

  handleFormStatusProspect = async (e) => {
    e.preventDefault();
    const { statusProspect, prospect } = this.state;
    if (!statusProspect || !prospect) {
      Swal.fire("Por favor, informe todos os dados!");
    } else {
      try {
        Swal.fire({
          title: "Aguarde ...",
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });

        const response = await api.post("api/concash/1.0/prospect/editar", {
          nome: prospect.nome,
          cpf: prospect.cpf,
          codigo: prospect.codigo,
          situacao: statusProspect,
          email: prospect.email,
          genero: prospect.genero,
          cidade: prospect.cidade,
          uf: prospect.uf,
          ddd1: prospect.ddd1,
          telefone1: prospect.telefone1,
          ddd2: prospect.ddd2,
          telefone2: prospect.telefone2,
          profissao: prospect.profissao,
        });

        Swal.fire("Status alterado com sucesso!");
      } catch (err) {
        Swal.fire("Houve um problema na requisição!");
      }
    }
  };

  render() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Comercial - Detalhamento / Prospect </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb"></ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <BoxInformativo
                    className="col-md-2"
                    style={{ background: this.state.backgroundProspect }}
                    onClick={(e) => this.alteraAbas("Prospect")}
                  >
                    Prospect
                  </BoxInformativo>

                  <BoxInformativo
                    className="col-md-2"
                    style={{ background: this.state.backgroundFaleConosco }}
                    onClick={(e) => this.alteraAbas("Fale Conosco")}
                  >
                    Fale Conosco
                  </BoxInformativo>

                  <BoxInformativo
                    className="col-md-2"
                    style={{ background: this.state.backgroundExtrato }}
                    onClick={(e) => this.alteraAbas("Extrato")}
                  >
                    Extrato
                  </BoxInformativo>

                  <BoxInformativo
                    className="col-md-2"
                    style={{ background: this.state.backgroundAcao }}
                    onClick={(e) => this.alteraAbas("Ação")}
                  >
                    Ação
                  </BoxInformativo>
                </div>

                {this.state.blocoProspect && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{ background: "#ccc", minHeight: "300px" }}
                    >
                      <div className="row" style={{ marginTop: "30px" }}>
                        <div className="col-md-12 grid-margin stretch-card">
                          <div className="card">
                            <div className="card-body">
                              <div
                                className="row"
                                style={{ marginTop: "20px" }}
                              >
                                <div className="col-sm-2">
                                  <b>Nome:</b>
                                </div>
                                <div className="col-sm-2">
                                  {this.state.prospect.nome}
                                </div>
                                <div className="col-sm-2">
                                  <b>CPF:</b>
                                </div>
                                <div className="col-sm-2">
                                  {this.state.prospect.cpf}
                                </div>
                                <div className="col-sm-2">
                                  <b>E-mail:</b>
                                </div>
                                <div className="col-sm-2">
                                  {this.state.prospect.email}
                                </div>
                              </div>

                              <div
                                className="row"
                                style={{ marginTop: "20px" }}
                              >
                                <div className="col-sm-2">
                                  <b>Gênero:</b>
                                </div>
                                <div className="col-sm-2">
                                  {this.state.prospect.genero}
                                </div>
                                <div className="col-sm-2">
                                  <b>Profissão:</b>
                                </div>
                                <div className="col-sm-2">
                                  {this.state.prospect.profissão}
                                </div>
                                <div className="col-sm-2">
                                  <b>Telefone 1:</b>
                                </div>
                                <div className="col-sm-2">
                                  {this.state.prospect.ddd1}{" "}
                                  {this.state.prospect.telefone1}
                                </div>
                              </div>

                              <div
                                className="row"
                                style={{ marginTop: "20px" }}
                              >
                                <div className="col-sm-2">
                                  <b>Telefone 2:</b>
                                </div>
                                <div className="col-sm-2">
                                  {this.state.prospect.ddd2}{" "}
                                  {this.state.prospect.telefone2}
                                </div>
                                <div className="col-sm-2">
                                  <b>Cidade:</b>
                                </div>
                                <div className="col-sm-2">
                                  {this.state.prospect.cidade}
                                </div>
                                <div className="col-sm-2">
                                  <b>UF:</b>
                                </div>
                                <div className="col-sm-2">
                                  {this.state.prospect.uf}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.blocoFaleConosco && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{ background: "#ccc", minHeight: "300px" }}
                    >
                      <div className="row" style={{ marginTop: "30px" }}>
                        <div className="col-md-12 grid-margin stretch-card">
                          <div className="card">
                            {this.state.faleConosco && (
                              <div className="card-body">
                                <div
                                  className="row"
                                  style={{ marginTop: "20px" }}
                                >
                                  <div className="col-sm-2">
                                    <b>Nome:</b>
                                  </div>
                                  <div className="col-sm-2">
                                    {this.state.faleConosco.nome}
                                  </div>
                                  <div className="col-sm-2">
                                    <b>E-mail:</b>
                                  </div>
                                  <div className="col-sm-2">
                                    {this.state.faleConosco.email}
                                  </div>
                                  <div className="col-sm-2">
                                    <b>Empresa:</b>
                                  </div>
                                  <div className="col-sm-2">
                                    {this.state.faleConosco.empresa}
                                  </div>
                                </div>

                                <div
                                  className="row"
                                  style={{ marginTop: "20px" }}
                                >
                                  <div className="col-sm-2">
                                    <b>Mensagem:</b>
                                  </div>
                                  <div className="col-sm-10">
                                    {this.state.faleConosco.mensagem}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.blocoExtrato && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{ background: "#ccc", minHeight: "300px" }}
                    >
                      <div className="row" style={{ marginTop: "30px" }}>
                        <div className="col-md-12 grid-margin stretch-card">
                          <div className="card">
                            {this.state.extrato && (
                              <div className="card-body">
                                <div
                                  className="row"
                                  style={{ marginTop: "20px" }}
                                >
                                  <div className="col-sm-2">
                                    <b>Nome:</b>
                                  </div>
                                  <div className="col-sm-2">
                                    {this.state.extrato.nome}
                                  </div>
                                  <div className="col-sm-2">
                                    <b>E-mail:</b>
                                  </div>
                                  <div className="col-sm-2">
                                    {this.state.extrato.email}
                                  </div>
                                  <div className="col-sm-2">
                                    <b>Telefone:</b>
                                  </div>
                                  <div className="col-sm-2">
                                    {this.state.extrato.telefone}
                                  </div>
                                </div>

                                <div
                                  className="row"
                                  style={{ marginTop: "20px" }}
                                >
                                  <div className="col-sm-2">
                                    <b>Administradora:</b>
                                  </div>
                                  <div className="col-sm-2">
                                    {this.state.extrato.administradora}
                                  </div>
                                  <div className="col-sm-2">
                                    <b>Valor Crédito:</b>
                                  </div>
                                  <div className="col-sm-2">
                                    {this.state.extrato.valorCredito}
                                  </div>
                                  <div className="col-sm-2">
                                    <b>Valor Pago:</b>
                                  </div>
                                  <div className="col-sm-2">
                                    {this.state.extrato.valorPago}
                                  </div>
                                </div>

                                <div
                                  className="row"
                                  style={{ marginTop: "20px" }}
                                >
                                  <div className="col-sm-2">
                                    <b>Total Parcelas:</b>
                                  </div>
                                  <div className="col-sm-2">
                                    {this.state.extrato.totalParcelas}
                                  </div>
                                  <div className="col-sm-2">
                                    <b>Parcelas Pagas:</b>
                                  </div>
                                  <div className="col-sm-2">
                                    {this.state.extrato.parcelasPagas}
                                  </div>
                                  <div className="col-sm-2">
                                    <b>Saldo Para Quitação:</b>
                                  </div>
                                  <div className="col-sm-2">
                                    {this.state.extrato.saldoParaQuitacao}
                                  </div>
                                </div>

                                <div
                                  className="row"
                                  style={{ marginTop: "20px" }}
                                >
                                  <div className="col-sm-2">
                                    <b>Categoria:</b>
                                  </div>
                                  <div className="col-sm-2">
                                    {this.state.extrato.categoria}
                                  </div>
                                  <div className="col-sm-2">
                                    <b>Consórcio Cancelado:</b>
                                  </div>
                                  <div className="col-sm-2">
                                    {this.state.extrato.consorcioCancelado}
                                  </div>
                                  <div className="col-sm-2">
                                    <b>Consórcio Contemplado:</b>
                                  </div>
                                  <div className="col-sm-2">
                                    {this.state.extrato.consorcioContemplado}
                                  </div>
                                </div>

                                <div
                                  className="row"
                                  style={{ marginTop: "20px" }}
                                >
                                  <div className="col-sm-2">
                                    <b>Anexo:</b>
                                  </div>
                                  <div className="col-sm-2">
                                    {this.state.extrato.anexo}
                                  </div>
                                  <div className="col-sm-2">
                                    <b>Arquivo:</b>
                                  </div>
                                  <div className="col-sm-2">
                                    {this.state.extrato.arquivo && (
                                      <button
                                        onClick={(e) =>
                                          this.handleFormOpenExtrato(e)
                                        }
                                        type="submit"
                                        className="btn btn-primary mr-2"
                                      >
                                        Download
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.blocoAcao && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{ background: "#ccc", minHeight: "300px" }}
                    >
                      <div className="row" style={{ marginTop: "30px" }}>
                        <div className="col-md-12 grid-margin stretch-card">
                          <div className="card">
                            <div className="card-body">
                              <div
                                className="row"
                                style={{ marginTop: "20px" }}
                              >
                                <div className="col-sm-12">Status Prospect</div>
                              </div>
                              <div
                                className="row"
                                style={{ marginTop: "20px" }}
                              >
                                <div className="col-sm-12">
                                  <form
                                    className="forms-sample"
                                    onSubmit={this.handleFormStatusProspect}
                                  >
                                    <div
                                      className="row"
                                      style={{ marginTop: "40px" }}
                                    >
                                      <div className="col-md-12 grid-margin stretch-card">
                                        <div className="card">
                                          <div className="card-body">
                                            <Form.Group className="row">
                                              <select
                                                className="form-control"
                                                value={
                                                  this.state.statusProspect
                                                }
                                                onChange={(e) =>
                                                  this.setState({
                                                    statusProspect:
                                                      e.target.value,
                                                  })
                                                }
                                                id="origem"
                                              >
                                                <option value="">
                                                  Selecione
                                                </option>
                                                <option value="EM_ABERTO">
                                                  EM_ABERTO
                                                </option>
                                                <option value="EM_PROCESSO">
                                                  EM_PROCESSO
                                                </option>
                                                <option value="FINALIZADO">
                                                  FINALIZADO
                                                </option>
                                              </select>
                                            </Form.Group>
                                            <div className="text-right">
                                              <button
                                                type="submit"
                                                className="btn btn-primary mr-2"
                                              >
                                                Alterar Status Prospect
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>

                              <div
                                className="row"
                                style={{ marginTop: "20px" }}
                              >
                                <div className="col-sm-12">
                                  Converter Prospect
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ marginTop: "20px" }}
                              >
                                <div className="col-sm-12">
                                  <ConverteCliente
                                    prospect={this.state.prospect}
                                    convertClienteNome={
                                      this.state.prospect.nome
                                    }
                                    convertClienteCPF={this.state.prospect.cpf}
                                    convertClienteEmail={
                                      this.state.prospect.email
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ComercialDetalhamentoProspect);
