export const TOKEN_KEY = "@concash-Token";
export const CPFCNPJ = "@cpf-cnpj";
export const TIPOPESSOA = "@tipoPessoa";
export const CLIENTE = "@cliente";
export const isAuthenticated = () => sessionStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => sessionStorage.getItem(TOKEN_KEY);
export const getCpfCnpj = () => sessionStorage.getItem(CPFCNPJ);
export const getTipoPessoa = () => sessionStorage.getItem(TIPOPESSOA);
export const getCliente = () => sessionStorage.getItem(CLIENTE);
export const login = (token) => {
  sessionStorage.setItem(TOKEN_KEY, token);
};
export const setTokenSession = (token) => {
  return login(token)
}
export const tipoPessoa = (tipoPessoa) => {
  sessionStorage.setItem(TIPOPESSOA, tipoPessoa);
};
export const setCpfCnpj = (cpfCnpj) => {
  sessionStorage.setItem(CPFCNPJ, cpfCnpj);
};
export const setCliente = (cliente) => {
  sessionStorage.setItem(CLIENTE, cliente);
};
export const logout = () => {
  sessionStorage.removeItem(TOKEN_KEY);
};

export const executaLogout = () => {
  logout();
  window.location.reload(false);
};
