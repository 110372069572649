import moment from "moment";

export const formataCPF = (text) => {
  //retira os caracteres indesejados...
  const texto = text.replace(/[^\d]/g, "");
  //realizar a formatação...
  const textoF = texto
    .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
    .slice(0, 14);
  return textoF;
};

export const formataCNPJ = (text) => {
  //retira os caracteres indesejados...
  const texto = text.replace(/[^\d]/g, "");
  //realizar a formatação...
  const textoF = texto
    .replace(/\D+/g, "") // não deixa ser digitado nenhuma letra
    .replace(/(\d{2})(\d)/, "$1.$2") // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2") // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");

  return textoF;
};

export function maskDecimal(input) {
  if (typeof input === "undefined" || input == null) return 0;

  let valor = "";

  if (typeof input.value !== "undefined") {
    valor = input.value;
  } else {
    valor = input;
  }

  if (valor.length == 2) return valor;

  let v = valor;
  v = v.replace(/\D/g, "");
  v = v.replace(/(\d{1,2})$/, ",$1");
  v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  return v;
}
function realNaN(chart) {
  const value = parseInt(chart, 10);
  return isNaN(value);
}

function createFormater(mask) {
  const computedMask = mask.split("");

  return (value) => {
    if (value.length === 0) {
      return "";
    }

    let valueIndex = 0;
    return computedMask
      .map((chart, index) => {
        const v = value[valueIndex];

        if (!v && realNaN(chart) && index === 0) {
          return chart;
        }

        if (!v) {
          return "";
        }

        if (realNaN(chart)) {
          return chart;
        }

        valueIndex += 1;
        return v;
      })
      .join("");
  };
}

const cellphone = createFormater("(11) 91234-5678");
const phone = createFormater("(11) 1234-5678");

export const cellphoneFormater = (text) => {
  text = text.replace(/([^0-9])/g, "").slice(0, 11);

  if (text.length < 11) {
    return phone(text);
  }
  return cellphone(text);
};

export function maskDataDeNascimento(text) {
  let maskedValue = text.replace(/\D/g, "");
  maskedValue = maskedValue.replace(/^(\d{2})(\d)/, "$1/$2");
  maskedValue = maskedValue.replace(/(\d{2})(\d)/, "$1/$2");
  return maskedValue;
}

export function isPastDate(dateString) {
  const date = moment(dateString, "DD/MM/YYYY");
  return date.isValid() && date.isBefore(moment());
}

  function getMoney(str) {
    const res = str?.replace(/[\D]+/g, '');
    return parseInt(res);
  };

export function formatReal(str) {
let numb = getMoney(str);
  var tmp = numb + "";
  tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
  if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

  return tmp;
}

export const formatCpfCnpj = (text) => {
  const txt = String(text)
  const texto = txt.replace(/[^\d]/g, "");
  if(texto.length <= 11) {
    return formataCPF(texto)
  } else {
    return formataCNPJ(texto)
  }
}

export function formatCnae(e){
  const inputValue = e;
  if(inputValue) {
      const numericValue = inputValue?.replace(/[^0-9]/g, '');
      let maskedValue = numericValue?.substring(0, 4);
    
      if (numericValue?.length > 4) {
        maskedValue += '-' + numericValue[4];
      }
      
      if (numericValue.length > 5) {
        maskedValue += '/' + numericValue.substring(5, 7);
      }
  
      return maskedValue;
  } else {
      return "";
  }
}