import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import api from "../../services/api";
import { Link } from "react-router-dom";
import SweetAlert from 'sweetalert2-react';
import Swal from 'sweetalert2'
import Pagination from "../../components/Pagination";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AlteraNome } from '../../actions';




export class FinanceiroListaPagamentosAutorizados extends Component {



  constructor(props){

        super(props);

        this.state = {
          autorizados: [],
        };
  }

  async componentDidMount() {

        this.obtemPagamentosAprovados();
  }

  async obtemPagamentosAprovados(){

      try {
        const response = await api.post("api/concash/1.0/visao-pagamentos-autorizados/listar", {  });
        this.setState({ autorizados: response.data});
      } catch (err) {
        this.setState({
          error:
            "Houve um problema para obter a lista de pagamentos aprovados."
        });
      }
  }


  render() {



    return (

      <div>
        <div className="page-header">
          <h3 className="page-title"> Pagamentos Aprovados </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>


        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">

                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th> Administradora </th>
                          <th> Grupo </th>
                          <th> Cota </th>
                          <th> Cliente </th>
                          <th className="text-center"> Ação </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.autorizados.map((autorizado,index) => (
                        <tr key={index}>
                          <td> {autorizado.administradora} </td>
                          <td> {autorizado.grupo} </td>
                          <td> {autorizado.contrato} </td>
                          <td> {autorizado.nome} </td>
                          <td className="text-center"><Link
                                            to={{pathname: "/financeiro/detalhamento-pagamento-autorizado",state: {
                                              codigo: autorizado.codigo, despesa: autorizado.despesa, lance: autorizado.lance,parcela: autorizado.parcela, processo: autorizado.processo, pagamento: autorizado.pagamento
                                            }}}
                                            className="m-3 btn btn-sm btn-success"
                                          >
                                          Detalhe
                                </Link>
                          </td>
                        </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = store => ({
  payload: store.AutenticacaoReducer.nome
});

function mapDispatchToProps(dispatch) {
    return {
        alteraNome: (id) => {
            dispatch(AlteraNome(id));
        },
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(FinanceiroListaPagamentosAutorizados);
