import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import ReguaCheckBox from "../../lib/ReguaCheckBox";
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";
import Swal from 'sweetalert2';



export class BasicElements extends Component {



  constructor(props){

    super(props);

    this.state = {
          administradora: '',
          labelForm: '',
          labelButton: '',
          configurados: [],
          cessionarias: [],
          reguas: [],
          reguasSelect: [],
          modelos: [],
          marcados:[],
          error: "",
          action: ""
    };
  }


  async componentDidMount() {

        if(this.props[0].location.state !== undefined){
            const adm  = this.props[0].location.state.administradora
            this.setState({administradora: adm},this.obtemRegua);
            this.setState({labelForm: "Editar Régua ",labelButton: "Configurar"});
        }else{
            this.setState({labelForm: "Adicionar Régua ",labelButton: "Configurar"});
        }
  }

  async obtemRegua() {
    try {
          const adm      = this.state.administradora;
          const response = await api.post("api/concash/1.0/regra-proposta/listar", {  codigoAdministradora: this.state.administradora.codigo,status: 'ATIVO' });
          var fAcoes = [];

          for(var i=0; i < response.data.length;i++){
              fAcoes[response.data[i].cessionaria.codigo] = response.data[i].sequencia + "|" + response.data[i].modeloRegraProposta.codigo;;
          }
          this.setState({ configurados: fAcoes});

          try {

                try {
                      const response3 = await api.post("api/concash/1.0/cessionaria/listar", {});
                      
                      this.setState({ cessionarias: response3.data});
                } catch (err) {
                      this.setState({
                        error:
                          "Houve um problema para obter as cessionarias."
                      });
                }

                try {
                      const response4 = await api.post("api/concash/1.0/modelo-regra-proposta/listar", {});
                      this.setState({ modelos: response4.data});
                } catch (err) {
                      this.setState({
                        error:
                          "Houve um problema para obter as cessionarias."
                      });
                }

          } catch (err) {
                this.setState({
                  error:
                    "Houve um problema para obter os parametros e ações."
                });
          }


    } catch (err) {
          this.setState({
            error:
              "Houve um para obter as configurações."
          });
    }
  }

  handleFormRegua = async (e) => {
    
    try {
      this.state.reguasSelect.map(async function(item){
        const response = await api.put("api/concash/1.0/regra-proposta/editar", {
          codigoCessionaria:         item.codigoCessionaria
         ,codigoAdministradora:      item.codigoAdministradora
         ,codigoModeloRegraProposta: item.codigoModeloRegraProposta
         ,sequencia:                 item.sequencia
       })

       if(!response){
        Swal.fire({
          title: 'Erro!',
          text: 'Erro ao cadastrar a régua!',
          icon: 'error',
          confirmButtonText: 'OK'
        })
       }

      })
      
      Swal.fire("Régua cadastrada com sucesso!");
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: 'Erro ao cadastrar a régua!',
        icon: 'error',
        confirmButtonText: 'OK'
      })
    }
    
  };

  render() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> {this.state.labelForm} Administradora : {this.state.administradora.nome} </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>

        <div className="row">

          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"></h4>
                {this.state.error && <p className="card-description">{this.state.error}</p>}
                <form className="forms-sample" onSubmit={this.handleForm}>
                {this.state.cessionarias.map((ap,index) => (
                  <ReguaCheckBox administradora={this.state.administradora} label={ap.nomeFantasia}  cessionaria={ap} modelos={this.state.modelos} marcados={this.state.configurados} reguasSelect={this.state.reguasSelect} key={index}/>
                ))}
                <div className="text-right">
                  <button onClick={this.handleFormRegua} className="btn btn-primary mr-2" type="button">Salvar</button>
                </div>
                </form>
              </div>
              
            </div>
          </div>
         </div>
       </div>
    )
  }
}

export default withRouter(BasicElements);
