import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";
import { AlteraNome } from '../../actions';
import styled, { css } from 'styled-components';



const BoxInformativo = styled.div`

      text-align:center;
      color: white;
      padding:10px;
      background: #2196f3;
      height: 80px;
      width: 150px;
      margin-top:20px;
      border-radius: 10px;

`;


export class TelaNegociacoes extends Component {


  constructor(props){
        super(props);
        this.state = {
          show: false,
          totalCotas: '',
          totalValorCredito: '',
          totalCotasEmAnalise: '',
          totalCotasNegociadas: '',
          totalReanalisesAprovadas: '',
          totalCotasRecusadas: '',
          totalProposta: '',
        };
  }

  async componentDidMount() {
      this.obtemTotalizadores();
  }

  async  obtemTotalizadores(){
    try {
          const response = await api.post("api/concash/1.0/consorcio-grupo-cota/total", {});
          this.setState({
            totalCotas               : response.data.totalCotas,
            totalValorCredito        : response.data.totalValorCredito,
            totalCotasEmAnalise      : response.data.totalCotasEmAnalise,
            totalCotasNegociadas     : response.data.totalCotasNegociadas,
            totalReanalisesAprovadas : response.data.totalReanalisesAprovadas,
            totalCotasRecusadas      : response.data.totalCotasRecusadas,
            totalProposta            : response.data.totalProposta

          });
    } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter a cota."
          });
    }
  }

  handleNegociacoes    = async e => {
      e.preventDefault();
      this.props.history.push("/comercial/negociacoes");
  };

  handleProspect = async e => {
      e.preventDefault();
      this.props.history.push("/comercial/prospect");
  };


  render() {


    return (

      <div>
        <div className="page-header">
          <h3 className="page-title"> Comercial - Negociações </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>
        <div className="row">

          <div className="col-md-4 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">

                    <div className="col-md-10 offset-md-1" style={{ textAlign:"left", background: "#2196f3", minHeight: "300px", color: "white", padding: "10px"}}>

                        <ul style={{listStyle: "none",marginLeft: "0px",padding: "5px", fontSize:"14px"}}>
                           <li>
                              <Link
                                           to={{pathname: "/comercial/lista-reprovado",state: {
                                             tipo: "PROPOSTAS_RECUSADAS"
                                           }}}
                                           className="m-12" style={{color:"white"}}
                                         >
                                         Propostas Recusadas({this.state.totalCotasRecusadas})
                               </Link>
                           </li>
                           <li>
                              <Link
                                           to={{pathname: "/visao-reanalise-aprovada",state: {
                                             tipo: "REANALISE_APROVADA"
                                           }}}
                                           className="m-12" style={{color:"white"}}
                                         >
                                         Reanálises Aprovadas({this.state.totalReanalisesAprovadas})
                               </Link>
                           </li>
                           <li><Link
                                        to={{pathname: "/comercial/lista-em-negociacao",state: {
                                          tipo: "EM_NEGOCIACAO"
                                        }}}
                                        className="m-12" style={{color:"white"}}
                                      >
                                      Em Negociação({this.state.totalCotasEmAnalise})
                            </Link></li>
                        </ul>

                    </div>

                </div>
              </div>
            </div>
          </div>

          <div className="col-md-8 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h3 className="page-title"> Volume das cotas </h3>
                <div className="row">

                    <BoxInformativo className="col-md-6">
                      Cotas<br/>
                      {this.state.totalCotas}
                    </BoxInformativo>

                    <BoxInformativo className="col-md-5 offset-md-1">
                      Cotas Negociadas<br/>
                      {this.state.totalCotasNegociadas}
                    </BoxInformativo>

                </div>

                <div className="row">

                    <BoxInformativo className="col-md-6">
                      Valor de Crédito<br/>
                      {this.state.totalValorCredito}
                    </BoxInformativo>

                    <BoxInformativo className="col-md-5 offset-md-1">
                      Cotas Aguardando Cliente<br/>
                      {this.state.totalCotasEmAnalise}
                    </BoxInformativo>

                </div>

                <div className="row">

                    <BoxInformativo className="col-md-6">
                      Valor Proposta<br/>
                      {this.state.totalProposta}
                    </BoxInformativo>

                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default withRouter(TelaNegociacoes);
