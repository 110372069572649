import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import api from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import { Link, withRouter } from "react-router-dom";
import {
  Autocomplete,
  Backdrop,
  Checkbox,
  CircularProgress,
  Radio,
  TextField,
} from "@mui/material";
import { Card } from "../../pages/Backoffice/styles";
import { maskPhoneWithoutDDD } from "../../mask/maskPhoneWithoutDDD";
import * as S from "../../pages/Backoffice/cadastro.styles";
import { V3Modal, V3ModalConfirm } from "../../styles/themes/components/modal";

import Lottie from "lottie-react";
import Loading from "../../assets/lottie/loading.json";
import {
  V3Autocomplete,
  V3TextCustom,
} from "../../styles/themes/components/input";
import { FaMobileAlt } from "react-icons/fa";
import { V3Text5 } from "../../styles/themes/components/texts";
import { RxTrash } from "react-icons/rx";
import { V3Button } from "../../styles/themes/components/button";

export class Telefone extends Component {
  constructor(props) {
    super(props);

    const { codigoCliente } = this.props;
    this.state = {
      codigoCliente: codigoCliente,
      telefones: [],
      tiposTelefones: [],
      error: "",
      isChecked: false,
      open: true,
      tipoTelefoneDesc: "",
      melhorContatoDesc: "",
      isMobile: false,
      melhorContato: null,
      openConfirm: false,
      deleteId: null,
      whatsapp: "",
      hasSuccess: false,
      successText: "",
      hasError: false,
      errorText: "",
    };
  }

  async componentDidMount() {
    this.setState({ isMobile: window.innerWidth <= 800 });
    await this.obtemTiposTelefones();
    await this.obtemTelefones(this.state.codigoCliente);
    this.setState({ open: false });
  }

  async obtemTelefones(codigo) {
    try {
      const response = await api.get(
        "api/concash/1.0/cliente-telefone/listar",
        {}
      );
      this.setState({ telefones: response.data });
    } catch (err) {
      this.setState({
        hasError: true,
        errorText: err.response.data
          ? err.response.data
          : "Houve um erro ao carregar os telefones",
      });
    }
  }

  async obtemTiposTelefones() {
    try {
      const response = await api.post(
        "api/concash/1.0/cliente-area/tipos-telefone",
        {}
      );
      var newResp = [];
      response.data.map(function (item) {
        newResp.push({ id: item.ttfId, label: item.ttfDescricao });
      });
      this.setState({ tiposTelefones: newResp });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Atenção",
        text: err.response.data,
      });
    }
  }

  handleFormTelefone = async (e) => {
    e.preventDefault();
    const {
      tipoTelefone,
      telefone,
      ddd,
      melhorContato,
      codigoCliente,
      isChecked,
      whatsapp,
    } = this.state;

    if (!tipoTelefone || tipoTelefone === "") {
      return this.setState({
        errorText: "Selecione o tipo de telefone.",
        hasError: true,
      });
    }

    if (!ddd || ddd === "") {
      return this.setState({
        errorText: "Preencha o DDD da sua localidade.",
        hasError: true,
      });
    }
    if (!telefone || telefone === "") {
      return this.setState({
        errorText: "Preencha seu telefone.",
        hasError: true,
      });
    }
    if (!melhorContato || melhorContato === "") {
      return this.setState({
        errorText: "Informe se este é seu contato principal.",
        hasError: true,
      });
    }
    if (!whatsapp || whatsapp === "") {
      return this.setState({
        errorText: "Informe se este contato é WhatsApp.",
        hasError: true,
      });
    }

    if (!tipoTelefone || !telefone || !ddd || melhorContato === "") {
    } else {
      try {
        this.setState({ open: true });
        let codigoCliente = this.state.codigoCliente;
        const newTelefone = parseInt(telefone.replace("-", ""));
        const response = await api.post(
          "api/concash/1.0/cliente-telefone/adicionar",
          {
            tipo: parseInt(tipoTelefone),
            telefone: newTelefone,
            ddd: parseInt(ddd),
            cliente: codigoCliente,
            principal: parseInt(melhorContato),
            whatsapp: parseInt(whatsapp),
          }
        );
        this.obtemTelefones(codigoCliente);
        this.setState({
          tipoTelefone: "",
          telefone: "",
          ddd: "",
          melhorContato: null,
          whatsapp: null,
          isChecked: false,
          open: false,
          successText: "Telefone adicionado com sucesso!",
          hasSuccess: true,
        });
      } catch (err) {
        this.setState({
          open: false,
          hasError: true,
          errorText: err.response.data
            ? err.response.data
            : "Erro ao adicionar telefone",
        });
      }
    }
  };
  cancelDelete() {
    this.setState({ openConfirm: false, deleteId: null });
  }

  deleteItem(item) {
    this.setState({ openConfirm: true, deleteId: item });
  }

  handleDeletarTelefone = async (e) => {
    try {
      this.setState({ open: true, openConfirm: false });
      let codigoCliente = this.state.codigo;
      const response = await api.delete(
        "api/concash/1.0/cliente-telefone/excluir/" + this.state.deleteId
      );
      this.setState({ telefones: [] });
      this.obtemTelefones(codigoCliente);
      this.setState({
        open: false,
        hasSuccess: true,
        successText: "Telefone excluído com sucesso!",
      });
    } catch (err) {
      this.setState({
        open: false,
        hasError: true,
        errorText: "Erro ao excluir telefone.",
      });
    }
  };

  toggleCheckboxChange = (e) => {
    this.setState(({ isChecked }) => ({
      isChecked: !isChecked,
    }));
  };

  melhorContOptions = [
    { label: "Sim", id: 1 },
    { label: "Não", id: 2 },
  ];

  tipoTelefone = {
    0: "Telefone",
    1: "Celular",
    2: "Comercial",
  };

  handleTelefone(n) {
    const numero = n.replace(/[^0-9]/g, "");
    const tamanho = numero.length;
    let retorno = "";

    if (tamanho < 8) {
      this.setState({ telefone: numero });
    } else if (tamanho >= 8) {
      retorno = maskPhoneWithoutDDD(numero);
      this.setState({ telefone: retorno });
    } else if (tamanho > 9) {
      this.setState({ telefone: this.state.telefone });
    }
  }

  handleDDD(ddd) {
    const numero = ddd.replace(/[^0-9]/g, "");

    numero.length <= 2
      ? this.setState({ ddd: numero })
      : this.setState({ ddd: this.state.ddd });
  }

  render() {
    const { isChecked } = this.state;
    return (
      <>
        {this.state.isMobile ? (
          <>
            <V3Modal
              open={this.state.hasError}
              actionClose={() => this.setState({ hasError: false })}
              title={"Atenção!"}
              text={this.state.errorText}
              type="error"
            />
            <V3Modal
              open={this.state.hasSuccess}
              actionClose={() => this.setState({ hasSuccess: false })}
              title={"Sucesso!"}
              text={this.state.successText}
              type="success"
            />
            <V3ModalConfirm
              open={this.state.openConfirm}
              title="Tem certeza que deseja excluir este telefone?"
              text="A exclusão é irreversível."
              actionClose={() => this.cancelDelete()}
              actionConfirm={() => this.handleDeletarTelefone()}
              textCancel="Não"
              textConfirm="Sim"
            />
            {this.state.telefones &&
              this.state.telefones.map((telefone, index) => (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between",
                    minHeight: 120,
                    borderBottom: "1px solid #979797",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "#000",
                    }}
                  >
                    <V3Text5 style={{ color: "#000", fontWeight: "bold" }}>
                      ({telefone.ddd}){" "}
                      {maskPhoneWithoutDDD(String(telefone.telefone))}
                    </V3Text5>
                    <V3Text5 style={{ color: "#000" }}>
                      Tipo: {this.tipoTelefone[telefone.tipo]}
                    </V3Text5>
                    <V3Text5 style={{ color: "#000" }}>
                      Melhor Contato: {telefone.principal == 1 ? "Sim" : "Não"}
                    </V3Text5>
                    <V3Text5 style={{ color: "#000" }}>
                      É WhatsApp: {telefone.whatsapp == 1 ? "Sim" : "Não"}
                    </V3Text5>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.deleteItem(telefone.id, e)}
                  >
                    <RxTrash color="#000" size={25} />
                  </div>
                </div>
              ))}
            {this.state.telefones !== null &&
              this.state.telefones.length !== 0 && (
                <S.Row style={{ marginBottom: 20, marginTop: 25 }}>
                  <V3Text5>ADICIONAR OUTRO TELEFONE</V3Text5>
                </S.Row>
              )}
            {!this.state.telefones.length && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  minHeight: 150,
                }}
              >
                <FaMobileAlt color="#979797" size={40} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <V3Text5>Nenhum telefone cadastrado</V3Text5>
                  <V3Text5 style={{ fontWeight: "bold" }}>
                    Preencha abaixo para adicionar
                  </V3Text5>
                </div>
              </div>
            )}
            <S.ContentBoxMobile>
              <S.Row>
                <V3Autocomplete
                  disablePortal
                  size="small"
                  style={{ width: "100%" }}
                  id="tipoTelefone"
                  options={this.state.tiposTelefones}
                  value={
                    this.state.tipoTelefone ? this.state.tipoTelefoneDesc : null
                  }
                  onChange={(e, v) =>
                    this.setState({
                      tipoTelefone: v ? v.id : null,
                      tipoTelefoneDesc: v ? v.label : null,
                    })
                  }
                  renderInput={(params) => (
                    <V3TextCustom {...params} label="Tipo de telefone" />
                  )}
                />
              </S.Row>
              <S.Row>
                <V3TextCustom
                  id="ddd"
                  label="DDD"
                  size="small"
                  style={{ width: "100%" }}
                  value={this.state.ddd}
                  onChange={(e) => this.handleDDD(e.target.value)}
                  variant="outlined"
                />
              </S.Row>
              <S.Row>
                <V3TextCustom
                  className="input-form-miu"
                  id="telefone"
                  size="small"
                  style={{ width: "100%" }}
                  label="Número de Telefone"
                  value={this.state.telefone}
                  onChange={(e) => this.handleTelefone(e.target.value)}
                  variant="outlined"
                />
              </S.Row>
              <S.Row>
                <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
                  <V3Text5 style={{ color: "#000", marginRight: 15, flex: 1 }}>
                    Melhor Contato?
                  </V3Text5>
                  <Radio
                    checked={this.state.melhorContato === "1"}
                    onChange={() => this.setState({ melhorContato: "1" })}
                    value="1"
                    name="radio-buttons"
                    label="Sim"
                    inputProps={{ "aria-label": "A" }}
                  />
                  <V3Text5 style={{ color: "#000" }}>Sim</V3Text5>
                  <Radio
                    checked={this.state.melhorContato === "0"}
                    onChange={() => this.setState({ melhorContato: "0" })}
                    value="0"
                    name="radio-buttons"
                    label="Não"
                    inputProps={{ "aria-label": "B" }}
                  />
                  <V3Text5 style={{ color: "#000" }}>Não</V3Text5>
                </div>
              </S.Row>
              <S.Row>
                <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
                  <V3Text5 style={{ color: "#000", marginRight: 15, flex: 1 }}>
                    É WhatsApp?
                  </V3Text5>
                  <Radio
                    checked={this.state.whatsapp === "1"}
                    onChange={() => this.setState({ whatsapp: "1" })}
                    value="1"
                    name="radio-buttons"
                    label="Sim"
                    inputProps={{ "aria-label": "A" }}
                  />
                  <V3Text5 style={{ color: "#000" }}>Sim</V3Text5>
                  <Radio
                    checked={this.state.whatsapp === "0"}
                    onChange={() => this.setState({ whatsapp: "0" })}
                    value="0"
                    name="radio-buttons"
                    label="Não"
                    inputProps={{ "aria-label": "B" }}
                  />
                  <V3Text5 style={{ color: "#000" }}>Não</V3Text5>
                </div>
              </S.Row>
              <S.Row>
                <V3Button
                  onClick={this.handleFormTelefone}
                  estilo="primary"
                  width={432}
                  text="Salvar"
                />
              </S.Row>
            </S.ContentBoxMobile>
          </>
        ) : (
          <>
            <S.ContentBox>
              <V3Modal
                open={this.state.hasError}
                actionClose={() => this.setState({ hasError: false })}
                title={"Atenção!"}
                text={this.state.errorText}
                type="error"
              />
              <V3Modal
                open={this.state.hasSuccess}
                actionClose={() => this.setState({ hasSuccess: false })}
                title={"Sucesso!"}
                text={this.state.successText}
                type="success"
              />
              <V3ModalConfirm
                open={this.state.openConfirm}
                title="Tem certeza que deseja excluir este telefone?"
                text="A exclusão é irreversível."
                actionClose={() => this.cancelDelete()}
                actionConfirm={() => this.handleDeletarTelefone()}
                textCancel="Não"
                textConfirm="Sim"
              />
              {this.state.telefones &&
                this.state.telefones.map((telefone, index) => (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                      minHeight: 120,
                      borderBottom: "1px solid #979797",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        color: "#000",
                      }}
                    >
                      <V3Text5 style={{ color: "#000", fontWeight: "bold" }}>
                        ({telefone.ddd}){" "}
                        {maskPhoneWithoutDDD(String(telefone.telefone))}
                      </V3Text5>
                      <V3Text5 style={{ color: "#000" }}>
                        Tipo: {this.tipoTelefone[telefone.tipo]}
                      </V3Text5>
                      <V3Text5 style={{ color: "#000" }}>
                        Melhor Contato:{" "}
                        {telefone.principal == 1 ? "Sim" : "Não"}
                      </V3Text5>
                      <V3Text5 style={{ color: "#000" }}>
                        É WhatsApp: {telefone.whatsapp == 1 ? "Sim" : "Não"}
                      </V3Text5>
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={(e) => this.deleteItem(telefone.id, e)}
                    >
                      <RxTrash color="#000" size={25} />
                    </div>
                  </div>
                ))}
              {this.state.telefones !== null &&
                this.state.telefones.length !== 0 && (
                  <S.Row>
                    <V3Text5>ADICIONAR OUTRO TELEFONE</V3Text5>
                  </S.Row>
                )}
              {!this.state.telefones.length && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    minHeight: 150,
                  }}
                >
                  <FaMobileAlt color="#979797" size={40} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <V3Text5>Nenhum telefone cadastrado</V3Text5>
                    <V3Text5 style={{ fontWeight: "bold" }}>
                      Preencha abaixo para adicionar
                    </V3Text5>
                  </div>
                </div>
              )}
              <S.Row>
                <V3Autocomplete
                  className="box-form-input-miu"
                  disablePortal
                  size="small"
                  id="tipoTelefone"
                  options={this.state.tiposTelefones}
                  value={
                    this.state.tipoTelefone ? this.state.tipoTelefoneDesc : null
                  }
                  onChange={(e, v) =>
                    this.setState({
                      tipoTelefone: v ? v.id : null,
                      tipoTelefoneDesc: v ? v.label : null,
                    })
                  }
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <V3TextCustom {...params} label="Tipo de telefone" />
                  )}
                />
                <V3TextCustom
                  id="ddd"
                  label="DDD"
                  sx={{ maxWidth: 70 }}
                  size="small"
                  style={{ maxWwidth: 70 }}
                  value={this.state.ddd}
                  onChange={(e) => this.handleDDD(e.target.value)}
                  variant="outlined"
                />
                <V3TextCustom
                  className="input-form-miu"
                  id="telefone"
                  size="small"
                  label="Número de Telefone"
                  value={this.state.telefone}
                  onChange={(e) => this.handleTelefone(e.target.value)}
                  variant="outlined"
                />
              </S.Row>
              <S.Row>
                <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
                  <V3Text5 style={{ color: "#000", marginRight: 15 }}>
                    Melhor Contato?
                  </V3Text5>
                  <Radio
                    checked={this.state.melhorContato === "1"}
                    onChange={() => this.setState({ melhorContato: "1" })}
                    value="1"
                    name="radio-buttons"
                    label="Sim"
                    inputProps={{ "aria-label": "A" }}
                  />
                  <V3Text5 style={{ color: "#000" }}>Sim</V3Text5>
                  <Radio
                    checked={this.state.melhorContato === "0"}
                    onChange={() => this.setState({ melhorContato: "0" })}
                    value="0"
                    name="radio-buttons"
                    label="Não"
                    inputProps={{ "aria-label": "B" }}
                  />
                  <V3Text5 style={{ color: "#000" }}>Não</V3Text5>
                </div>
                <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
                  <V3Text5 style={{ color: "#000", marginRight: 15 }}>
                    É WhatsApp?
                  </V3Text5>
                  <Radio
                    checked={this.state.whatsapp === "1"}
                    onChange={() => this.setState({ whatsapp: "1" })}
                    value="1"
                    name="radio-buttons"
                    label="Sim"
                    inputProps={{ "aria-label": "A" }}
                  />
                  <V3Text5 style={{ color: "#000" }}>Sim</V3Text5>
                  <Radio
                    checked={this.state.whatsapp === "0"}
                    onChange={() => this.setState({ whatsapp: "0" })}
                    value="0"
                    name="radio-buttons"
                    label="Não"
                    inputProps={{ "aria-label": "B" }}
                  />
                  <V3Text5 style={{ color: "#000" }}>Não</V3Text5>
                </div>
              </S.Row>
              <S.Row style={{ padding: 5 }}>
                <V3Button
                  onClick={this.handleFormTelefone}
                  estilo="primary"
                  width={432}
                  text="Salvar"
                />
              </S.Row>
            </S.ContentBox>
          </>
        )}
      </>
    );
  }
}

export default Telefone;
