import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";



export class FormGrupoFuncionalidade extends Component {



  constructor(props){

    super(props);

    this.state = {
          grupo: '',
          app: '',
          labelForm: '',
          labelButton: '',
          aplicativos: [],
          error: "",
          action: ""
    };
  }


  async componentDidMount() {

    if(this.props[0].location.state !== undefined){
        const group  = this.props[0].location.state.grupo
        
        this.setState({grupo: group.grupo,app: group.aplicativo.codigo});
        this.setState({labelForm: "Editar Grupo",labelButton: "Editar",action: "editar"});
    }else{
        this.setState({labelForm: "Adicionar Grupo",labelButton: "Adicionar",action: "adicionar"});
    }

    try {
          const response = await api.post("api/sistema/1.0/aplicativo/listar", {});
          this.setState({ aplicativos: response.data});
    } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter os aplicativos."
          });
    }
  }

  handleForm = async e => {
    e.preventDefault();
    const { grupo,
            app,
            action} = this.state;
    if (!grupo
        || !app
        || !action) {
      this.setState({ error: "Preencha os campos obrigatórios para continuar!" });
    } else {
      try {

        if(action == "editar"){
          const response = await api.put("api/sistema/1.0/grupo-funcionalidade/"+action, {
              grupo,app
          });
          this.setState({ error: "Edição realizada com sucesso!" });
        }else{
          const response = await api.post("api/sistema/1.0/grupo-funcionalidade/"+action, {
              grupo,app
            });
          this.props.history.push("/grupo-funcionalidade");
        }

      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o processamento da requisição."
        });
      }
    }
  };

  render() {


    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> {this.state.labelForm} </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>

        <div className="row">

          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"></h4>
                {this.state.error && <p className="card-description">{this.state.error}</p>}
                <form className="forms-sample" onSubmit={this.handleForm}>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group className="row" >
                      <label className="col-sm-5 col-form-label">Aplicativo</label>
                      <div className="col-sm-7">
                        <select className="form-control" value={this.state.app} onChange={e => this.setState({ app: e.target.value })}>
                            <option value="" key={0}>Selecione</option>
                          {this.state.aplicativos.map((apl,index) => (
                            <option value={apl.codigo} key={index}>{apl.nome}</option>
                          ))}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Grupo</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="grupo" value={this.state.grupo} placeholder="Nome do Grupo de Funcionalidade" onChange={e => this.setState({ grupo: e.target.value })} size="sm" />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="text-right">
                    <button type="submit" className="btn btn-primary mr-2">{this.state.labelButton}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
         </div>
       </div>
    )
  }
}

export default withRouter(FormGrupoFuncionalidade);
