import React, { Component } from "react";
import Swal from "sweetalert2";
import api from "../../services/api";


import moment from "moment";
import { Backdrop, CircularProgress } from "@mui/material";

export class HistoricoProposta extends Component {
    constructor(props) {
        super(props);

        const { idClienteConsorcio  } = this.props;
        
        this.state = {
            idClienteConsorcio: idClienteConsorcio,
            historicos: [],
            open: true,
            isLoading: false
        };
        
    }

    async componentDidMount() {
        this.obtemHistoricos(this.state.idClienteConsorcio);
        this.setState({open:false})
    }

    async obtemHistoricos(codigo) {
        try {
            this.setState({ isLoading: true });
            const response = await api.get(
                `/api/concash/1.0/cliente-proposta/listar-historico/${this.state.idClienteConsorcio}` 
            );
            this.setState({ historicos: response.data, isLoading: false });
        } catch (err) {
            this.setState({ isLoading: false });
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: 'Não foi possível realizar a operação!'
              })
        }
    }

    

    render() {
        return (
            this.state.isLoading ? (
                <>
                {/* <div style={{ display: 'flex', flex:1, width: '100%', height:'100%', alignItems:'center', justifyContent:'center'}}>
                    <ReactLoading type={"bars"} color={'#20cfa7 '} height={66} width={37} />

                </div> */}
                <div style={{ display: 'flex', flex:1, width: '100%', height:'100%', alignItems:'center', justifyContent:'center'}}>
                    <Backdrop
                    sx={{ color: '#16B6C5', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.isLoading}
                    >
                    <CircularProgress color="inherit" />
                    </Backdrop>
        
                </div>                
                </>
            ) : (
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th> Data/Hora</th>
                                            <th> Descrição </th>
                                            <th> Valor </th>
                                            <th> Tipo </th>
                                            <th> Status </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.historicos && this.state.historicos.map(
                                            (hist, index) => (
                                                <tr key={index}>
                                                    <td>{moment(hist.dataAlteracao).format("DD/MM/yyyy HH:mm")}</td>
                                                    <td>{hist.descricaoProposta}</td>
                                                    {hist.tipo == 'CONTRA' && (
                                                        <td>
                                                            {hist.contraPropostaValor.toLocaleString("pt-br", {
                                                                style: "currency",
                                                                currency: "BRL",
                                                            })} 
                                                        </td>
                                                    )}
                                                    {hist.tipo == 'PROPOSTA' && (
                                                        <td>
                                                            {hist.valorProposta.toLocaleString("pt-br", {
                                                                style: "currency",
                                                                currency: "BRL",
                                                            })} 
                                                        </td>
                                                    )}
                                                    <td>
                                                        {hist.tipo == 'PROPOSTA' ? 'Proposta' : 'Contra Proposta'}
                                                    </td>
                                                    <td>{hist.propostaStatus ? hist.propostaStatus : hist.contraPropostaStatus}</td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
        );
    }
}

export default HistoricoProposta;