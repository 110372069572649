export function validarCPF(cpf) {
    cpf = cpf.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos
  
    if (cpf.length !== 11) {
      return false;
    }
  
    // Verificação dos dígitos verificadores
    let soma = 0;
    for (let i = 0; i < 9; i++) {
      soma += parseInt(cpf.charAt(i)) * (10 - i);
    }
  
    let resto = soma % 11;
    let digitoVerificador1 = resto < 2 ? 0 : 11 - resto;
  
    soma = 0;
    for (let i = 0; i < 10; i++) {
      soma += parseInt(cpf.charAt(i)) * (11 - i);
    }
  
    resto = soma % 11;
    let digitoVerificador2 = resto < 2 ? 0 : 11 - resto;
  
    return (
      parseInt(cpf.charAt(9)) === digitoVerificador1 &&
      parseInt(cpf.charAt(10)) === digitoVerificador2
    );
  }

export function validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos
  
    if (cnpj.length !== 14) {
      return false;
    }
  
    // Verificação dos dígitos verificadores
    const multiplicadores1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    const multiplicadores2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  
    const calcularDigito = (cnpj, multiplicadores) => {
      let soma = 0;
      for (let i = 0; i < multiplicadores.length; i++) {
        soma += parseInt(cnpj.charAt(i)) * multiplicadores[i];
      }
  
      const resto = soma % 11;
      return resto < 2 ? 0 : 11 - resto;
    };
  
    const digitoVerificador1 = calcularDigito(cnpj, multiplicadores1);
    const digitoVerificador2 = calcularDigito(cnpj, multiplicadores2);
  
    return (
      parseInt(cnpj.charAt(12)) === digitoVerificador1 &&
      parseInt(cnpj.charAt(13)) === digitoVerificador2
    );
  }

  export function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }


export function validateDate(dateString) {
  const date = new Date(dateString);
  const minDate = new Date(Date.now() - 100 * 365 * 24 * 60 * 60 * 1000); // 100 anos atrás
  const maxDate = new Date(); // data atual
  
  if (date < minDate || date > maxDate) {
    return false; // data fora dos limites permitidos (100 anos atrás e não pode ser futura)
  }
  
  return true;
}


export function validateMobilePhone(phone) {
  const digitsOnly = phone.replace(/\D/g, '');
  if (digitsOnly.length !== 11 || digitsOnly.charAt(2) !== '9') {
    return false;
  }
}



