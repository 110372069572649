import React, { useState, useEffect } from "react";
import { BaseDesktop, BaseMobile } from "../components/common/Base";
import S from "../Autoservico.module.css";
import { ButtonMain } from "../components/buttons";
import { FaCheck } from "react-icons/fa";
import { Assinar } from "./assinar";

export const Finalizado = ({ estilo }) => {
  const service = window.location.pathname.replace("/autoservico/", "").split("/");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


  const dadosPessoais = {
    nome: "João da Silva",
    cpfCnpj: "123.456.789-00",
    rg: "MG1211444-44",
    fotosDoc: false,
    telefone: "(44) 5555-9900",
    endereco: {
      logradouro: "Rua da Esperança",
      comprovante: true,
      cep: "99999-100",
      complemento: "AP 101",
      bairro: "Casa Verde",
      cidade: "Veredas Tropical",
      uf: "RR"
    },
    dadosBancarios: {
      idBanco: 208,
      banco: "Banco BTG Pactual",
      agencia: "9999",
      conta:"01010101",
      digitoConta: "2",
      tipoConta: "Corrente",
      chavePIX: "falfjasçfjwr2342343243",
      tipoChavePIX: "Aleatória"
    }
  }

  const cota = {
    id: 2,
    grupo: "2628",
    cota: "147",
    contrato: "13516849789794",
    adm: "CNP Assurances",
    valorCota: "R$ 4.874,52",
    diasPagamento: "8"
  }

  if (estilo)
    return (
      <div>
        {isMobile ? (

<>

<BaseMobile estilo={estilo}>
<div className={S.flexColumn} style={{ width: "100%" }}>
              <div style={{ display: "flex", flexDirection:"column", gap: 15, alignItems: "center", marginBottom: 20}}>
                <div style={{ display:"flex", alignItems: "center", justifyContent: "center", width: 50, height: 50, backgroundColor: estilo?.secondary, borderRadius: "50%" }}>
                  <FaCheck size={34} color="#FFF" />
                </div>
                  <h1 className={S.titlePage} style={{ textAlign: "center" }}>Recebemos seu<br />contrato assinado!</h1>
              </div>
            <div style={{ display: "flex", flexDirection: "column", marginTop: 30, marginBottom: 30 }}>
                <span>Agora a sua cota está em processo de transferência de titularidade. Lembramos que este processo <b>pode levar alguns dias</b>.<br />O pagamento será feito após a transferência concluída, em até <b>8 dias úteis</b>.<br /><br /> Caso tenha alguma dúvida, entre em contato conosco através do ícone de mensagem no canto inferior direito da tela.</span>
            </div>

              <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
                <ButtonMain estilo={estilo} texto={"Minhas cotas"} tamanho={"100%"} onClick={() => window.location.href = `/autoservico/${service[0]}/minhas-cotas`} />
              </div>
            </div>

</BaseMobile>

</>
         
        ) : (
          <>
<BaseDesktop estilo={estilo}>
            <div className={S.flexColumn} style={{ width: "100%" }}>
              <div style={{ display: "flex", gap: 15, alignItems: "center", marginBottom: 20}}>
                <div style={{ display:"flex", alignItems: "center", justifyContent: "center", width: 50, height: 50, backgroundColor: estilo?.secondary, borderRadius: "50%" }}>
                  <FaCheck size={34} color="#FFF" />
                </div>
                  <h1 className={S.titlePage}>Recebemos seu contrato assinado!</h1>
              </div>
              <h3 className={S.text15}>Para confirmar a venda, você deve assinar digitalmente o contrato. Toque no botão abaixo para iniciar e siga as instruções.</h3>
            <div style={{ display: "flex", flexDirection: "column", marginTop: 30, marginBottom: 30 }}>
                <span>Agora a sua cota está em processo de transferência de titularidade. Lembramos que este processo <b>pode levar alguns dias</b>.<br />O pagamento será feito após a transferência concluída, em até <b>8 dias úteis</b>.<br /><br /> Caso tenha alguma dúvida, entre em contato conosco através do ícone de mensagem no canto inferior direito da tela.</span>
            </div>

              <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
                <ButtonMain estilo={estilo} texto={"Minhas cotas"} tamanho={"330px"} onClick={() => window.location.href = `/autoservico/${service[0]}/minhas-cotas`} />
              </div>
            </div>
          </BaseDesktop>
        </>
          
        )}
      </div>
    );
};
