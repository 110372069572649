import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import ReguaTipoProcesso from "../../lib/ReguaTipoProcesso";
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";



export class TipoProcessoConfigurar extends Component {



  constructor(props){

    super(props);

    this.state = {
          tipoProcesso: '',
          labelForm: '',
          labelButton: '',
          configurados: [],
          funcoes: [],
          marcados:[],
          error: "",
          action: ""
    };
  }


  async componentDidMount() {

        if(this.props[0].location.state !== undefined){
            const tipoPro  = this.props[0].location.state.tipoProcesso;
            this.setState({tipoProcesso: tipoPro},this.obtemProcessoFuncoes);
            this.setState({labelForm: "Editar Tipo Processo x Funções ",labelButton: "Configurar"});
        }else{
            this.setState({labelForm: "Adicionar Tipo Processo x Funções ",labelButton: "Configurar"});
        }
  }

  async obtemProcessoFuncoes() {

    try {

          const tipoPro  = this.props[0].location.state.tipoProcesso;
          const response = await api.post("api/concash/1.0/processo-funcao/listar", {  codigoTipoProcesso: this.state.tipoProcesso.codigo,status: 'ATIVO' });
          var fAcoes = [];

          for(var i=0; i < response.data.length;i++){
              fAcoes[response.data[i].funcao.codigo] = response.data[i].prioridade;
          }
          this.setState({ configurados: fAcoes});

          try {

                try {
                      const response3 = await api.post("api/sistema/1.0/funcao/listar", {});
                      this.setState({ funcoes: response3.data});
                } catch (err) {
                      this.setState({
                        error:
                          "Houve um problema para obter as funções."
                      });
                }

          } catch (err) {
                this.setState({
                  error:
                    "Houve um problema para obter os tipos de processos e funções."
                });
          }


    } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter os tipos de processos e funções."
          });
    }
  }



  render() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> {this.state.labelForm} Tipo Processo : {this.state.tipoProcesso.nome} </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>

        <div className="row">

          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"></h4>
                {this.state.error && <p className="card-description">{this.state.error}</p>}
                <form className="forms-sample" onSubmit={this.handleForm}>
                {this.state.funcoes.map((funcao,index) => (
                  <ReguaTipoProcesso tipoProcesso={this.state.tipoProcesso} label={funcao.funcao}  funcao={funcao} marcados={this.state.configurados} key={index}/>
                ))}
                <div className="row">
                    <div className="col-md-12">
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
         </div>
       </div>
    )
  }
}

export default withRouter(TipoProcessoConfigurar);
