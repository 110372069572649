import React, { Component, Fragment } from "react";
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2'
import api from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import { Link, withRouter } from "react-router-dom";



export class Extrato extends Component {


  constructor(props) {

    super(props);

    const { codigoCota, extrato }     = this.props;

    this.state = {
      codigoCota: codigoCota,
      extrato: extrato,
      file: null,
      error: ""
    };

  }



  async componentDidMount() {
  }

  handleFormExtrato = async e => {
    e.preventDefault();
    const { file} = this.state;
    if (!file) {
      this.setState({ error: "Preencha os campos obrigatórios para continuar!" });
    } else {
      try {

            Swal.fire ({
               title: 'Aguarde ...',
               onBeforeOpen: () => {
                 Swal.showLoading ()
               }
            });

            let codigo        = this.state.codigoCota;
            const formData    = new FormData();
            formData.append('file',file);
            formData.append('cota',codigo);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            const response  = await api.post("api/concash/1.0/consorcio-grupo-cota/cadastrar-extrato",formData,config);
            this.setState({urlExtrato:true});
            this.setState({extrato:response.data.url});

            Swal.close();

      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o processamento da requisição."
        });
      }
    }
  };




  handleFormOpenExtrato = async e => {
    e.preventDefault();
      try {

            Swal.fire ({
               title: 'Aguarde ...',
               onBeforeOpen: () => {
                 Swal.showLoading ()
               }
            });

            let extrato     = this.state.extrato;
            const response  = await api.post("api/concash/1.0/consorcio-grupo-cota/download",{
              extrato:extrato
            },{ responseType: 'blob' });

            const file      = new Blob([response.data], { type: "application/pdf" });
            //Build a URL from the file
            const fileURL   = URL.createObjectURL(file);
            //Open the URL on new Window
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;

            Swal.close();

      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o processamento da requisição."
        });
      }
  };


  render() {

    return (

      <div className="row">

        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Extrato</h4>
              <p className="card-description"></p>

              <form className="forms-sample" onSubmit={this.handleFormExtrato}>

              <div className="row">


                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Extrato</label>
                            <div className="col-sm-9">
                            <Form.Control type="file" className="form-control" id="file" onChange={e =>  this.setState({file:e.target.files[0]}) }   size="sm"/>
                            </div>
                          </Form.Group>
                          <div className="text-right">
                            <button type="submit" className="btn btn-primary mr-2">Adicionar</button>
                          </div>
                    </div>
                  </div>
                </div>

              </div>

              </form>

              <div className="table-responsive">
              <form className="forms-sample" onSubmit={this.handleFormOpenExtrato}>
                    <table className="table table-striped">
                        <thead>
                          <tr>
                            <th> URL Extrato </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.urlExtrato &&

                                <tr >
                                  <td>{ this.state.extrato } &nbsp;&nbsp;&nbsp;<button type="submit" className="btn btn-primary mr-2">Download</button></td>
                                </tr>
                        }
                        </tbody>
                      </table>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}


export default Extrato;
