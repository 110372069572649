import { Backdrop } from "@mui/material";
import React from "react";
import { GrClose } from "react-icons/gr";
import styled from "styled-components";
import { v3Colors } from "../../v3/colors";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { FaCheckCircle } from "react-icons/fa";
import { V3Text5, V3TextH1 } from "../texts";
import { V3Button } from "../button";
import { MdCancel } from "react-icons/md";
import { RiErrorWarningLine } from "react-icons/ri";

export const V3Modal = (props) => {
  const { open, type, actionClose, title, text, html, customButtonColor } =
    props;
  return (
    <>
      <Backdrop open={open} style={{ zIndex: 999 }}>
        <ModalBoxStyled>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <GrClose
              onClick={() => actionClose(false)}
              style={{ cursor: "pointer" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 5,
              marginBottom: 10,
            }}
          >
            {type === "success" && (
              <FaCheckCircle color={v3Colors.primary} size={50} />
            )}
            {type === "error" && (
              <MdCancel color={v3Colors.redAlert} size={50} />
            )}
            {type === "warning" && (
              <RiErrorWarningLine color={"#FC0"} size={50} />
            )}

            <V3TextH1 style={{ textAlign: "center" }}>{title}</V3TextH1>
            <V3Text5 style={{ textAlign: "center", marginTop: 10 }}>
              {text}
            </V3Text5>
            {html}
          </div>

          <V3Button
            style={{ backgroundColor: customButtonColor }}
            width={"100%"}
            estilo={customButtonColor ? "custom" : "primary"}
            customColor={customButtonColor}
            text="Fechar"
            onClick={() => actionClose(false)}
          />
        </ModalBoxStyled>
      </Backdrop>
    </>
  );
};
export const V3ModalConfirm = (props) => {
  const {
    open,
    actionClose,
    actionConfirm,
    title,
    text,
    textConfirm,
    textCancel,
    customButtonColor
  } = props;
  return (
    <>
      <Backdrop open={open} style={{ zIndex: 999 }}>
        <ModalBoxStyled>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <GrClose
              onClick={() => actionClose()}
              style={{ cursor: "pointer" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 5,
              marginBottom: 10,
            }}
          >
            <V3TextH1 style={{ textAlign: "center", marginTop: 15 }}>
              {title}
            </V3TextH1>
            <V3Text5
              style={{ textAlign: "center", marginTop: 10, marginBottom: 15 }}
            >
              {text}
            </V3Text5>
          </div>
          <div style={{ display: "flex", gap: 10 }}>
            <V3Button
              style={{ backgroundColor: customButtonColor }}
              width={"100%"}
              estilo={"primary"}
              text={textConfirm}
              onClick={() => actionConfirm()}
            />
            <V3Button
              width={"100%"}
              estilo={"alert"}
              text={textCancel}
              onClick={() => actionClose()}
            />
          </div>
        </ModalBoxStyled>
      </Backdrop>
    </>
  );
};
export const V3ModalConfirmAutoServico = (props) => {
  const {
    open,
    actionClose,
    actionConfirm,
    title,
    text,
    textConfirm,
    textCancel,
    customButtonColor
  } = props;
  return (
    <>
      <Backdrop open={open} style={{ zIndex: 999 }}>
        <ModalBoxStyled>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <GrClose
              onClick={() => actionClose()}
              style={{ cursor: "pointer" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 5,
              marginBottom: 10,
            }}
          >
            <V3TextH1 style={{ textAlign: "center", marginTop: 15 }}>
              {title}
            </V3TextH1>
            <V3Text5
              style={{ textAlign: "center", marginTop: 10, marginBottom: 15 }}
            >
              {text}
            </V3Text5>
          </div>
          <div style={{ display: "flex", gap: 10 }}>
            <V3Button
              width={"100%"}
              estilo={"custom"}
              customColor={"rgba(132, 132, 132, 0.3)"}
              text={textCancel}
              customTextColor={"#010101"}
              onClick={() => actionClose()}
            />
            <V3Button
              style={{ backgroundColor: customButtonColor }}
              width={"100%"}
              estilo={"custom"}
              text={textConfirm}
              customColor={customButtonColor}
              onClick={() => actionConfirm()}
            />
          </div>
        </ModalBoxStyled>
      </Backdrop>
    </>
  );
};

const ModalBoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 390px;
  max-height: 306px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding: 20px;
  justify-content: space-between;
`;

const InfoErrorS = styled.div`
  display: flex;
  width: 330px;
  justify-content: center;
  gap: 10px;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
`;

export const InfoModal = (props) => {
  const { type, text } = props;

  const border = {
    error: "red",
  };
  const bg = {
    error: "#FFE8E8",
  };
  const color = {
    error: "red",
  };
  const textColor = {
    error: "red",
  };

  return (
    <>
      <InfoErrorS
        style={{
          backgroundColor: bg[type],
          border: `1px solid ${border[type]}`,
        }}
      >
        <div>
          <HiOutlineExclamationCircle color={color[type]} />
        </div>
        <div>
          <V3Text5
            style={{
              display: "flex",
              flex: 1,
              color: textColor[type],
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            {text}
          </V3Text5>
        </div>
      </InfoErrorS>
    </>
  );
};
