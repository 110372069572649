import React, { useEffect } from 'react';
import S from "../Autoservico.module.css";
import { MdArrowForwardIos } from "react-icons/md";


export const ButtonMain = ({estilo, texto, tamanho, onClick, disabled, textColor, disabledColor, icon}) => {


    return (
        <div className={S.buttonMain} style={{ backgroundColor: disabled ? disabledColor ? "#00B423" : "#D9D9D9" : estilo?.primary, color: textColor ? textColor : "#FFF", width: tamanho, minHeight: 45, cursor: disabled ? "not-allowed" : "pointer" }} onClick={!disabled ? onClick : () => {}}>
            {icon}{texto}
        </div>
    );
}


export const ButtonOutlined = ({estilo, texto, tamanho, onClick, disabled}) => {


    return (
        <div className={S.buttonMain} style={{ backgroundColor: disabled ? "#D9D9D9" : "#FFF", width: tamanho, color: disabled ? "#FFF" : estilo.primary, border: disabled ? "#D9D9D9" : `2px solid ${estilo.primary}` }} onClick={!disabled && onClick}>
            {texto}
        </div>
    );
}


export const ButtonAction = ({estilo, texto, tamanho, onClick, disabled}) => {


    return (
        <div className={S.buttonAction} style={{ backgroundColor: disabled ? "#D9D9D9" : "#FFF", width: tamanho, color: disabled ? "#DEDEDE" : "#000", border: disabled ? "#D9D9D9" : `2px solid #C9C9C9` }} onClick={!disabled && onClick}>
            <span>{texto}</span> <MdArrowForwardIos size={24} />
        </div>
    );
}


export const SimpleButtonIcon = ({estilo, texto, tamanho, icon, onClick, disabled}) => {


    return (
        <div className={S.simpleButton} style={{ width: tamanho }} onClick={!disabled && onClick}>
            {icon} <span style={{ color: estilo.primary}}>{texto}</span>
        </div>
    );
}

