import React, { useCallback, useEffect, useState } from "react";
import * as S from "./styles";
import AutoJumpInputs from "../../components/AutoJump";
import CNPLogo from "../../assets/images/cnp.svg";
import {
  InputCustomizedWhite,
  V3AutocompleteWhite,
  V3CustomTextField,
  V3TextCustom,
} from "../../styles/themes/components/input";
import { V3Button, V3RadioButton } from "../../styles/themes/components/button";
import { useDispatch, useSelector } from "react-redux";
import { updateAuthenticate } from "../../store/otpCnp";
import ChartQuotes from "./ChartQuotes";
import LoadingCustom from "../../components/LoadingCustom";
import { formataCNPJ, formataCPF } from "../../utils/format";
import { V3Modal } from "../../styles/themes/components/modal";
import api from "../../services/api";
import { Box, Tab, Tabs } from "@mui/material";
import { v3MaskPhone } from "../../styles/themes/utils/format";
import { BsPhoneVibrate } from "react-icons/bs";
import { MdOutlineMail } from "react-icons/md";
import { validateEmail } from "../../styles/themes/utils/validate";

const OTP = () => {
  const dispatch = useDispatch();

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [contact, setContact] = useState(null);
  const [contactList, setContactList] = useState([]);
  const [seconds, setSeconds] = useState(30);
  const [loading, setLoading] = useState(true);
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [errorText, setErrorText] = useState("");
  const [hasDoc, setHasDoc] = useState(false);
  const [aba, setAba] = useState("phone");
  const [emailError, setEmailError] = useState(false);

  const { logged } = useSelector((state) => state.otpCnp);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let interval = null;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setIsActive(true);
        setSeconds(seconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setIsActive(false);
      setSeconds(30);
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isActive, seconds]);

  const startCountdown = () => {
    setIsActive(true);
  };

  async function handleGetCode() {
    if(!cpfCnpj){

      setHasError(true);
      return setErrorText("Preencha o campo CPF / CNPJ.")
    }
    if (!isActive) {
      setHasDoc(true);
      const docNum = cpfCnpj.replace(/[^0-9]/g, "");
      const tel = phone.replace(/[^0-9]/g, "");
      const endpoint = `/api/auth/otp-request`
      const { data } = await api.get(endpoint, {headers: {
        document: docNum,
        authType: aba,
        token: "concash",
      }});
      let listCombo = [];
      if(aba==='email'){
        listCombo.push({ id: data.email, label: data.email });
      }else{
        listCombo.push({ id: data.phone, label: data.phone });
      }

      setContactList(listCombo);
      setContact(listCombo[0].label);
      startCountdown();
    }
  }

  async function handleVerify() {
    if (code.length < 6) {
      setErrorText("Informe seu código de verificação com 6 dígitos");
      return setHasError(true);
    }

    const docNum = cpfCnpj.replace(/[^0-9]/g, "")

    setLoading(true);
    try {
      await api
        .post(
          `api/auth/otp-validate?cpfCnpj=${docNum}`, {},
          {headers: {
            token: code,
          }}
        )
        .then(({ data }) => {
          dispatch(
            updateAuthenticate({
              token: data.token,
              cpfCnpj: cpfCnpj,
              phone: phone,
              tokenConsort:  data.tokenConsort,
              email: email,
            })
          );
          setSuccessText("Login Realizado com sucesso!");
          setLoading(false);
          return setHasSuccess(true);
        });
    } catch (e) {
      setLoading(false);
      setErrorText("Algo de errado aconteceu, verifique o código informado.");
      return setHasError(true);
    }
  }

  function handleChangeCpfCnpj(e) {
    const text = e.target.value.replace(/[^0-9]/g, "");
    if (text.length <= 11) {
      setCpfCnpj(formataCPF(text));
    } else {
      setCpfCnpj(formataCNPJ(text));
    }
  }

  function handleChangePhone(e) {
    const text = e.target.value;

    const formatted = v3MaskPhone(text);
    setPhone(formatted);
    setContactList([{ id: formatted, label: formatted }]);
    setContact({ id: formatted, label: formatted });
  }

  function handleChangeEmail(e) {
    const text = e.target.value;
    const isValid = validateEmail(text);
    setEmail(text);
    setContactList([{ id: text, label: text }]);
    setContact({ id: text, label: text });
    setEmailError(!isValid);
  }

  const handleResize = useCallback(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    const uri = window.location.search.replace("?", "").split("&");
    let listCombo = [];
    uri.map((i) => {
      if (i.includes("telephone=")) {
        const tel = i.replace("telephone=", "").replace("%20", " ");
        setPhone(tel);
        listCombo.push({ id: tel, label: tel });
      }
      if (i.includes("email=")) {
        const mail = i.replace("email=", "");
        setEmail(mail);
        listCombo.push({ id: mail, label: mail });
      }
      if (i.includes("document=")) {
        const doc = i.replace("document=", "");
        if (doc.length > 11) {
          const d = formataCNPJ(doc);
          setHasDoc(true);
          setCpfCnpj(d);
        } else {
          const d = formataCPF(doc);
          setHasDoc(true);
          setCpfCnpj(d);
        }
      }
      setLoading(false);
      setContactList(listCombo);
      setContact(listCombo[1]?.label);
    });
  }, []);

  return (
    <>
      <V3Modal
        open={hasError}
        actionClose={() => setHasError(false)}
        title={"Atenção!"}
        text={errorText}
        type="error"
      />
      <V3Modal
        open={hasSuccess}
        actionClose={() => setHasSuccess(false)}
        title={"Sucesso!"}
        text={successText}
        type="success"
      />
      {loading && <LoadingCustom open={loading} />}
      {windowSize.width > 820 ? ( // Desktop
        <>
          {logged ? (
            <>
              <ChartQuotes />
            </>
          ) : (
            <S.ContainerDesktop>
              <S.BoxLoginDesktop>
                <S.LogosBoxDesktop>
                  <img
                    src={require("../../assets/logo.png")}
                    width={"120px"}
                    height={"33px"}
                  />
                  <img src={CNPLogo} width={100} />
                </S.LogosBoxDesktop>
                {hasDoc ? (
                  <>
                    <S.DocBox>
                      <span>{cpfCnpj}</span>
                    </S.DocBox>
                    <br></br>
                    <InputCustomizedWhite
                        style={{ width: 260 }}
                        onChange={handleChangeCpfCnpj}
                        value={contact}
                        readOnly="true"
                        placeholder="Método autenticação"
                        required
                      />
                    <AutoJumpInputs acao={setCode} />
                    <S.ButtonBoxDesktop>
                    
                      <V3Button
                        text="Avançar"
                        estilo={
                          !code || code.length < 6 ? "disabled" : "primary"
                        }
                        width={"120px"}
                        height={40}
                        onClick={handleVerify}
                        disabled={!code || code.length < 6}
                      />
                    </S.ButtonBoxDesktop>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        padding: "6px 0",
                      }}
                    >
                      <InputCustomizedWhite
                        style={{ width: 310 }}
                        onChange={handleChangeCpfCnpj}
                        value={cpfCnpj}
                        placeholder="CPF/CNPJ"
                        required
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 10,
                        marginTop: 20,
                      }}
                    >
                      <V3RadioButton
                        width={310}
                        fontSize={"10px"}
                        text="Verificação por E-Mail"
                        selected={aba === "email"}
                        icon={<MdOutlineMail size={20} />}
                        onClick={() => {
                          setAba("email");
                        }}
                      />
                    </div>
                    {aba === "phone" && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            padding: "6px 0",
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                        </div>
                        <V3Button
                          text={
                            isActive
                              ? "Solicitar Codigo em " + seconds + "s"
                              : "Solicitar Código"
                          }
                          estilo={isActive ? "neutro" : "primary"}
                          height={40}
                          width={310}
                          size="sm"
                          tamFont={14}
                          disable={isActive}
                          onClick={() => {
                            handleGetCode();
                          }}
                        />
                      </>
                    )}
                    {aba === "email" && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            padding: "6px 0",
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                        </div>
                        <V3Button
                          text={
                            isActive
                              ? "Solicitar Codigo em " + seconds + "s"
                              : "Solicitar Código"
                          }
                          estilo={isActive ? "neutro" : "primary"}
                          height={40}
                          width={310}
                          size="sm"
                          tamFont={14}
                          disable={isActive}
                          onClick={() => {
                            handleGetCode();
                            
                          }}
                        />
                      </>
                    )}
                  </>
                )}
              </S.BoxLoginDesktop>
            </S.ContainerDesktop>
          )}
        </>
      ) : (
        // Mobile
        <>
          {logged ? (
            <>
              <ChartQuotes />
            </>
          ) : (
            <S.ContainerMobile>
              <S.BoxLoginMobile>
                <S.LogosBoxDesktop>
                  <img
                    src={require("../../assets/logo.png")}
                    width={"120px"}
                    height={"33px"}
                  />
                  <img src={CNPLogo} width={100} />
                </S.LogosBoxDesktop>
                {hasDoc ? (
                  <>
                    <S.DocBox>
                      <span>{cpfCnpj}</span>
                    </S.DocBox>
                    <br></br>
                    <InputCustomizedWhite
                        style={{ width: 260 }}
                        onChange={handleChangeCpfCnpj}
                        value={contact}
                        readOnly="true"
                        placeholder="Método autenticação"
                        required
                      />
                    <AutoJumpInputs acao={setCode} />
                    <S.ButtonBoxDesktop>

                      <V3Button
                        text="Avançar"
                        estilo={
                          !code || code.length < 6 ? "disabled" : "primary"
                        }
                        width={"120px"}
                        height={40}
                        onClick={handleVerify}
                        disabled={!code || code.length < 6}
                      />
                    </S.ButtonBoxDesktop>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        padding: "6px 0",
                      }}
                    >
                      <InputCustomizedWhite
                        style={{ width: "100%" }}
                        onChange={handleChangeCpfCnpj}
                        value={cpfCnpj}
                        placeholder="CPF/CNPJ"
                        required
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 10,
                        marginTop: 20,
                      }}
                    >
                      <V3RadioButton
                        width={"100%"}
                        fontSize={"10px"}
                        text="Celular / SMS"
                        selected={aba === "phone"}
                        icon={<BsPhoneVibrate size={20} />}
                        onClick={() => {
                          setAba("phone");
                        }}
                      />
                      <V3RadioButton
                        width={"100%"}
                        fontSize={"10px"}
                        text="E-Mail"
                        selected={aba === "email"}
                        icon={<MdOutlineMail size={20} />}
                        onClick={() => {
                          setAba("email");
                        }}
                      />
                    </div>
                    {aba === "phone" && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            padding: "6px 0",
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                        </div>
                        <V3Button
                          text={
                            isActive
                              ? "Solicitar Codigo em " + seconds + "s"
                              : "Solicitar Código"
                          }
                          estilo={isActive ? "neutro" : "primary"}
                          height={40}
                          width={"100%"}
                          size="sm"
                          tamFont={14}
                          disable={isActive}
                          onClick={() => {
                            handleGetCode();
                          }}
                        />
                      </>
                    )}
                    {aba === "email" && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            padding: "6px 0",
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                        </div>
                        <V3Button
                          text={
                            isActive
                              ? "Solicitar Codigo em " + seconds + "s"
                              : "Solicitar Código"
                          }
                          estilo={isActive ? "neutro" : "primary"}
                          height={40}
                          width={"100%"}
                          size="sm"
                          tamFont={14}
                          disable={isActive}
                          onClick={() => {
                            handleGetCode();
                          }}
                        />
                      </>
                    )}
                  </>
                )}
              </S.BoxLoginMobile>
            </S.ContainerMobile>
          )}
        </>
      )}
    </>
  );
};

export default OTP;
