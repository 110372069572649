import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import api from "../../services/api";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { AlteraNome } from '../../actions';


export const BoxConfiguracao = styled.div`
    text-align:center;
    font-size:120px;
    cursor:pointer;
`;

export class TelaConfiguracao extends Component {


  constructor(props){
        super(props);
        this.state = {
          show: false
        };
  }

  async componentDidMount() {

  }

  handleUsuario = async e => {
      e.preventDefault();
      this.props.history.push("/usuario");
  };

  handleAplicativo = async e => {
      e.preventDefault();
      this.props.history.push("/aplicativo");
  };

  handleFuncionalidade = async e => {
      e.preventDefault();
      this.props.history.push("/funcionalidade");
  };

  handleFuncao = async e => {
      e.preventDefault();
      this.props.history.push("/funcao");
  };

  handleSimulador = async e => {
      e.preventDefault();
      this.props.history.push("/simulador");
  };

  handleGrupoFuncionalidade = async e => {
      e.preventDefault();
      this.props.history.push("/grupo-funcionalidade");
  };

  handleAcao = async e => {
      e.preventDefault();
      this.props.history.push("/acao");
  };

  render() {


    return (

      <div>
        <div className="page-header">
          <h3 className="page-title"> Configuração </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">

                    <BoxConfiguracao className="col-md-3" onClick={ e => this.handleUsuario(e)}>
                        <span className="mdi mdi-account" data-tip="Usuários"></span>
                        <p>Usuários</p>
                    </BoxConfiguracao>

                    <BoxConfiguracao className="col-md-3" onClick={ e => this.handleAplicativo(e)}>
                        <span className="mdi mdi-android-debug-bridge" data-tip="Aplicativos"></span>
                        <p>Aplicativos</p>
                    </BoxConfiguracao>

                    <BoxConfiguracao className="col-md-3" onClick={ e => this.handleFuncionalidade(e)}>
                        <span className="mdi mdi-ballot" data-tip="Funcionalidades"></span>
                        <p>Funcionalidades</p>
                    </BoxConfiguracao>

                    <BoxConfiguracao className="col-md-3" onClick={ e => this.handleUsuario(e)}>
                        <span className="mdi mdi-boom-gate" data-tip="Permissões"></span>
                        <p>Permissões</p>
                    </BoxConfiguracao>

                </div>

                <div className="row">

                    <BoxConfiguracao className="col-md-3" onClick={ e => this.handleSimulador(e)}>
                        <span className="mdi mdi-desktop-mac-dashboard" data-tip="Simulador"></span>
                        <p>Simulador</p>
                    </BoxConfiguracao>

                    <BoxConfiguracao className="col-md-3" onClick={ e => this.handleGrupoFuncionalidade(e)}>
                        <span className="mdi mdi-book-open-page-variant" data-tip="Grupo de Funcionalidades"></span>
                        <p>Grupo de Funcionalidades</p>
                    </BoxConfiguracao>

                    <BoxConfiguracao className="col-md-3" onClick={ e => this.handleFuncao(e)}>
                        <span className="mdi mdi-google-street-view" data-tip="Perfis"></span>
                        <p>Perfis</p>
                    </BoxConfiguracao>

                    <BoxConfiguracao className="col-md-3" onClick={ e => this.handleAcao(e)}>
                        <span className="mdi mdi-run" data-tip="Ação"></span>
                        <p>Ação</p>
                    </BoxConfiguracao>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(TelaConfiguracao);
