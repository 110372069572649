import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker, { registerLocale } from "react-datepicker";
import FloatingLabelInput from 'react-floating-label-input';
import moment from 'moment';
import api from "../../services/api";
import { cellphoneFormater,currencyFormater,numberFormater} from '../../lib/formaters';
import IntlCurrencyInput from "react-intl-currency-input"
import InputFloat from 'react-floating-input'
import ptBR from "date-fns/locale/pt-BR";
import Swal from 'sweetalert2'
import { Link, withRouter } from "react-router-dom";
import Endereco from "../../components/Endereco";
import Telefone from "../../components/Telefone";
import Banco from "../../components/Banco";
import Cota from "../../components/Cota";
import Kyc from "../../components/Kyc";
import Mensagem from "../../components/Mensagem";
import ClienteCampos from "../../components/ClienteCampos";
import { getCpfCnpj } from '../../services/auth';
import { cpfCnpj } from '../../mask/cpfcnpj';


registerLocale("pt-BR", ptBR);




export class ClienteForm extends Component {



  constructor(props){

        super(props);

        this.state = {
          startDate: new Date(),
          cpf: '',
          nome: '',
          email: '',
          etapa: '',
          profissao: '',
          obj_cliente: '',
          canal: '',
          genero: '',
          renda: '',
          conjuge_nome: '',
          conjuge_cpf: '',
          cep: '',
          tipoTelefone: '',
          ddd: '',
          telefone: '',
          tipoEndereco: '',
          bairro: '',
          numero: '',
          logradouro: '',
          rg: '',
          nacionalidade: '',
          estadoCivil: '',
          uf: '',
          cidade: '',
          codigoClienteOrigem: '',
          codigo: '',
          labelForm: '',
          administradoras: [],
          questionario: [],
          formCompleto:false,
          ufs: [],
          cidades: [],
          bancos: [],
          labelPagina: '',
          recebimentos: [],
          telefones: [],
          enderecos: [],
          cotas: [],
          mensagens: [],
          error: ""
        };
  }




  async componentDidMount() {
    
    const cpfCnpj = getCpfCnpj();
    const response = await api.post("api/concash/1.0/cliente/buscar", { cpfCnpj:cpfCnpj});
    
    if(response.data){
        const cliente  = response.data;
        this.setState({cpf: cliente.cpfCnpj,obj_cliente:cliente,rg: cliente.rg,estadoCivil: cliente.estadoCivil,nacionalidade: cliente.nacionalidade,formCompleto:true,etapa: cliente.etapa,nome: cliente.nome,codigo: cliente.codigo,email: cliente.email,profissao: cliente.profissao,renda: cliente.renda,conjuge_nome: cliente.conjuge_nome,conjuge_cpf: cliente.conjuge_cpf,canal: cliente.canal,genero: cliente.genero});
        this.setState({labelForm: "Editar Cliente",labelButton: "Editar",action: "editar",labelPagina: cliente.nome + " - " + cliente.codigo});
    }else{
        
        this.setState({labelForm: "Adicionar Cliente",labelButton: "Adicionar",action: "adicionar"});
    }

  }



  handleForm = async e => {
    e.preventDefault();
    const { nome,
            cpf,
            email,
            renda,
            profissao,
            etapa,
            conjuge_nome,
            conjuge_cpf,
            nacionalidade,
            rg,
            estadoCivil,
            canal,
            genero,
            codigo,
            action} = this.state;
    if (!nome
        || !email
        || !etapa
        || !cpf
        || !action) {
      this.setState({ error: "Preencha os campos obrigatórios para continuar!" });
    } else {
      try {

        if(action == "editar"){
          const response = await api.put("api/concash/1.0/cliente/"+action, {
              nome,cpf,email,etapa,profissao,rg,estadoCivil,nacionalidade,canal,genero,renda,conjuge_cpf,conjuge_nome,codigo
            });

            Swal.fire ('Cliente editado com sucesso!');

        }else{
          const response = await api.post("api/concash/1.0/cliente/"+action, {
              nome,cpf,email,etapa,profissao,canal,genero,renda,conjuge_cpf,conjuge_nome
            });
            this.props.history.push("/cliente");
        }



      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o processamento da requisição."
        });
      }
    }
  };


  handleFormTransferencia = async e => {
    e.preventDefault();
    const { codigoClienteOrigem,
            codigo} = this.state;
    if (!codigoClienteOrigem
        || !codigo) {
      this.setState({ error: "Preencha os campos obrigatórios para continuar!" });
    } else {
      try {

          const response = await api.post("api/concash/1.0/cliente/transferir", {
              clienteOrigem:codigoClienteOrigem,clienteDestino:codigo
            });
            this.props.history.push("/cliente");

      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o processamento da requisição."
        });
      }
    }
  };






  render() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> {this.state.labelForm} {this.state.labelPagina}</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>

        <div className="row">

          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"></h4>
                <p className="card-description"></p>
                {this.state.error && <p className="card-description">{this.state.error}</p>}

                <form className="forms-sample" onSubmit={this.handleForm}>

                <div className="row">
                  <div className="col-md-12">

                  </div>
                </div>


                    <Form.Group className="row">
                      <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Etapa</label>
                      <div className="col-sm-9">
                        <select className="form-control" value={this.state.etapa} onChange={e => this.setState({ etapa: e.target.value })} id="etapa">
                          <option value="" key="0">Selecione {this.state.etapa}</option>
                          <option value="CADASTRO_USUARIO" key="1">CADASTRO_USUARIO</option>
                          <option value="VALIDACAO_EMAIL" key="2">VALIDACAO_EMAIL</option>
                          <option value="CADASTRO_CPF" key="3">CADASTRO_CPF</option>
                          <option value="QUESTIONARIO" key="4">QUESTIONARIO</option>
                          <option value="BLOQUEADO" key="5">BLOQUEADO</option>
                          <option value="VALIDADO" key="6">VALIDADO</option>
                        </select>
                      </div>
                    </Form.Group>

                    <Form.Group className="row">
                      <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Nome</label>
                      <div className="col-sm-9">
                      <Form.Control type="text"  className="form-control" id="nome" value={this.state.nome} onChange={e => this.setState({ nome: e.target.value })}  size="sm"/>
                      </div>
                    </Form.Group>
                    <Form.Group className="row">
                      <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">CPF</label>
                      <div className="col-sm-9">
                      <Form.Control type="text" className="form-control" id="cpf" value={cpfCnpj(this.state.cpf)} onChange={e => this.setState({ cpf: e.target.value })}  size="sm"/>
                      </div>
                    </Form.Group>
                    <Form.Group className="row">
                      <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">RG</label>
                      <div className="col-sm-9">
                      <Form.Control type="text" className="form-control" id="rg" value={this.state.rg} onChange={e => this.setState({ rg: e.target.value })}  size="sm"/>
                      </div>
                    </Form.Group>
                    <Form.Group className="row">
                      <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Nacionalidade</label>
                      <div className="col-sm-9">
                      <Form.Control type="text" className="form-control" id="nacionalidade" value={this.state.nacionalidade} onChange={e => this.setState({ nacionalidade: e.target.value })}  size="sm"/>
                      </div>
                    </Form.Group>
                    <Form.Group className="row">
                      <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Estado Civil</label>
                      <div className="col-sm-9">
                      <Form.Control type="text" className="form-control" id="estadoCivil" value={this.state.estadoCivil} onChange={e => this.setState({ estadoCivil: e.target.value })}  size="sm"/>
                      </div>
                    </Form.Group>
                    <Form.Group className="row">
                      <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">E-mail</label>
                      <div className="col-sm-9">
                      <Form.Control type="text" className="form-control" id="cpf" value={this.state.email} onChange={e => this.setState({ email: e.target.value })}  size="sm"/>
                      </div>
                    </Form.Group>
                    <Form.Group className="row">
                      <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Genêro</label>
                      <div className="col-sm-9">
                        <select className="form-control" value={this.state.genero} onChange={e => this.setState({ genero: e.target.value })} id="codigoAdministradora">
                          <option value="">Selecione</option>
                          <option value="M">M</option>
                          <option value="F">F</option>
                        </select>
                      </div>
                    </Form.Group>
                    <Form.Group className="row">
                      <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">CANAL</label>
                      <div className="col-sm-9">
                        <select className="form-control" value={this.state.canal} onChange={e => this.setState({ canal: e.target.value })} id="codigoAdministradora">
                          <option value="">Selecione</option>
                          <option value="GOOGLE">GOOGLE</option>
                          <option value="FACEBOOK">FACEBOOK</option>
                          <option value="INDICACAO">INDICACAO</option>
                          <option value="INLIRA">INLIRA</option>
                        </select>
                      </div>
                    </Form.Group>
                    <Form.Group className="row">
                      <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Profissão</label>
                      <div className="col-sm-9">
                      <Form.Control type="text" className="form-control" id="profissao" value={this.state.profissao} onChange={e => this.setState({ profissao: e.target.value })}  size="sm"/>
                      </div>
                    </Form.Group>
                    <Form.Group className="row">
                      <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Renda</label>
                      <div className="col-sm-9">
                      <Form.Control type="text" className="form-control" id="renda" value={this.state.renda} onChange={e => this.setState({ renda: e.target.value })}  size="sm"/>
                      </div>
                    </Form.Group>
                    <Form.Group className="row">
                      <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Conjuge Nome</label>
                      <div className="col-sm-9">
                      <Form.Control type="text" className="form-control" id="conjuge_nome" value={this.state.conjuge_nome} onChange={e => this.setState({ conjuge_nome: e.target.value })}  size="sm"/>
                      </div>
                    </Form.Group>
                    <Form.Group className="row">
                      <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Conjuge CPF</label>
                      <div className="col-sm-9">
                      <Form.Control type="text" className="form-control" id="conjuge_cpf" value={this.state.conjuge_cpf} onChange={e => this.setState({ conjuge_cpf: e.target.value })}  size="sm"/>
                      </div>
                    </Form.Group>
                    <div className="text-right">
                      <button type="submit" className="btn btn-primary mr-2">{this.state.labelButton}</button>
                    </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {this.state.formCompleto &&
        <div className="row">

          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Transferir CPF</h4>
                <p className="card-description"></p>

                <form className="forms-sample" onSubmit={this.handleFormTransferencia}>


                <div className="row">

                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                            <Form.Group className="row">
                              <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Código Cliente Origem</label>
                              <div className="col-sm-6">
                              <Form.Control type="text" className="form-control" id="codigoClienteOrigem" value={this.state.codigoClienteOrigem} onChange={e => this.setState({ codigoClienteOrigem : e.target.value }) } size="sm"/>
                              </div>
                            </Form.Group>
                            <div className="text-right">
                              <button type="submit" className="btn btn-primary mr-2">Transferir</button>
                            </div>
                      </div>
                    </div>
                  </div>

                </div>

                </form>

              </div>
            </div>
          </div>

        </div>
        }


        {this.state.formCompleto &&
            <Kyc codigoCliente={this.state.codigo} />
        }

        {this.state.formCompleto &&
            <Endereco codigoCliente={this.state.codigo} />
        }

        {this.state.formCompleto &&
            <Telefone codigoCliente={this.state.codigo} />
        }

        {this.state.formCompleto &&
            <Banco codigoCliente={this.state.codigo} />
        }

        {this.state.formCompleto &&
            <Cota cliente={this.state.obj_cliente} origem="CLIENTE" />
        }

        {this.state.formCompleto &&
            <Mensagem codigoCliente={this.state.codigo} />
        }
      </div>
    )
  }
}

export default withRouter(ClienteForm);
