import React from "react";
import styled from "styled-components";

import { BsCheckLg } from "react-icons/bs";
import { v3Colors } from "../../v3/colors";

export const V3Button = (props) => {
  const {
    white,
    text,
    width,
    size,
    height,
    onClick,
    estilo,
    id,
    tamFont,
    disabled,
    customColor,
    customTextColor
  } = props;
  const backgroundColor = {
    primary: v3Colors.primary,
    secondary: v3Colors.secondary,
    white: v3Colors.secondary,
    neutro: v3Colors.white,
    alert: "#F63B30",
    disabled: v3Colors.opacGray,
    custom: customColor,
  };
  const textColor = {
    primary: v3Colors.white,
    secondary: v3Colors.primary,
    white: v3Colors.primary,
    neutro: v3Colors.primary,
    alert: v3Colors.white,
    disabled: "rgba(252, 252, 252, 0.4)",
    custom: customTextColor ? customTextColor : "#FFF",
  };

  const border = {
    primary: v3Colors.primary,
    secondary: v3Colors.secondary,
    white: v3Colors.secondary,
    neutro: v3Colors.white,
    alert: "#F63B30",
    disabled: "transparent",
    custom: "transparent",
  };

  const fontSize = {
    sm: "0.9375rem",
    md: "1.25rem",
  };

  const padding = {
    sm: "12px 20px",
    md: "18px 40px",
  };

  const StyledLink = styled.button`
    display: flex;
  padding: ${size ? padding[size] : "12px 20px"},
    color: ${textColor[estilo]};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    max-height: ${height ? height : "45px"};

    &:visited {
      color: ${textColor[estilo]};
    }
  `;

  return (
    <>
      <StyledLink
        id={id}
        onClick={!disabled && onClick}
        style={{
          backgroundColor: backgroundColor[estilo],
          textDecoration: "none",
          border: `2px solid ${border[estilo]}`,
          height: height ? height : 45,
          width: width ? width : 160,
          borderRadius: 10,
          textAlign: "center",
          fontFamily: "Roboto",
          fontWeight: "bold",
          fontSize: tamFont ? tamFont : size ? fontSize[size] : 15,
          letterSpacing: 0,
          // textColor: textColor[estilo],
        }}
      >
        <span style={{ color: textColor[estilo], padding: 0, margin: 0 }}>
          {text}
        </span>
      </StyledLink>
    </>
  );
};
export const V3ButtonLink = (props) => {
  const { white, text, width, size, height, href, estilo } = props;
  const backgroundColor = {
    primary: v3Colors.primary,
    secondary: v3Colors.secondary,
    white: v3Colors.secondary,
    neutro: v3Colors.white,
  };
  const textColor = {
    primary: v3Colors.white,
    secondary: v3Colors.primary,
    white: v3Colors.primary,
    neutro: v3Colors.primary,
  };
  const fontSize = {
    sm: "0.9375rem",
    md: "1.25rem",
  };

  const padding = {
    sm: "12px 20px",
    md: "18px 40px",
  };

  const StyledLink = styled.a`
    display: flex;
  padding: ${size ? padding[size] : "12px 20px"},
    color: ${textColor[estilo]};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    max-height: ${height ? height : "45px"};

    &:visited {
      color: ${textColor[estilo]};
    }
  `;

  return (
    <>
      <StyledLink
        href={href}
        style={{
          backgroundColor: backgroundColor[estilo],
          textDecoration: "none",
          border: `2px solid ${
            estilo !== "neutro" ? v3Colors.primary : "transparent"
          }`,
          height: height ? height : 45,
          width: width ? width : 160,
          borderRadius: 10,
          textAlign: "center",
          fontFamily: "Roboto",
          fontWeight: "bold",
          fontSize: size ? fontSize[size] : 15,
          letterSpacing: 0,
          // textColor: textColor[estilo],
        }}
      >
        <span style={{ color: textColor[estilo], padding: 0, margin: 0 }}>
          {text}
        </span>
      </StyledLink>
    </>
  );
};

export const V3RadioButton = (props) => {
  const { text, width, selected, fontSize, icon, onClick } = props;

  const RadioContainer = styled.button`
    display: flex;
    max-height: 45px;
    border: ${selected ? `1px solid ${v3Colors.primary}` : "1px solid #ababab"};
    border-radius: 50px;
    padding: 10px;
    background-color: ${selected ? v3Colors.secondary : "#fff"};
    cursor: pointer;
  `;

  const RadioCircle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border: ${selected ? `1px solid ${v3Colors.primary}` : "1px solid #ababab"};
    background-color: ${selected ? v3Colors.primary : "#fff"};
    border-radius: 50%;
    margin-right: 10px;
  `;

  const RadioTextBox = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
  `;

  const RadioText = styled.span`
    text-align: left;
    font: normal normal normal ${fontSize ? fontSize : "15px/20px"} Roboto;
    letter-spacing: 0px;
    color: ${selected ? v3Colors.primary : "#ababab"};
    font-weight: bold;
    opacity: 1;
  `;

  return (
    <>
      <RadioContainer style={{ width: width ? width : 206 }} onClick={onClick}>
        <RadioCircle>
          <BsCheckLg color={selected ? "#FFF" : "transparent"} />
        </RadioCircle>
        <RadioTextBox>
          <RadioText>{text}</RadioText>
          <div
            style={{
              color: selected ? v3Colors.primary : v3Colors.inputBorder,
            }}
          >
            {icon ? icon : ""}
          </div>
        </RadioTextBox>
      </RadioContainer>
    </>
  );
};
