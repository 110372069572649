import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import api from "../../services/api";
import { Link } from "react-router-dom";
import SweetAlert from 'sweetalert2-react';
import Swal from 'sweetalert2'
import Pagination from "../../components/Pagination";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AlteraNome } from '../../actions';




export class FinanceiroInclusaoValores extends Component {

  constructor(props){

        super(props);

        this.state = {
          cotas: [],
          currentPage:1,
          totalRecords:null,
          show: false
        };
  }

  async componentDidMount() {

        this.obtemListaCotas();
  }

  async obtemListaCotas(){

      try {

        const {
          currentPage,
        } = this.state;

        const response = await api.post("api/concash/1.0/visao-documentacao-aprovada/listar", {  });
        this.setState({ cotas: response.data});

      } catch (err) {
        this.setState({
          error:
            "Houve um problema para obter a lista de cotas."
        });
      }

  }




  render() {


    const {
      totalRecords
    } = this.state;


    return (

      <div>
        <div className="page-header">
          <h3 className="page-title"> Inclusão de valores </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>

        <div className="row">
                 <div className="col-md-12 grid-margin stretch-card">
                   <div className="card">
                     <div className="card-body">


                   <div className="row">

                      <div className="col-md-1">

                      </div>

                      <div className="col-md-10">

                         <div className="table-responsive" style={{"marginBottom":"30px"}}>
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th className="text-center"> Ação </th>
                                  <th> Cliente   </th>
                                  <th> Administradora   </th>
                                  <th> Grupo    </th>
                                  <th> Cota </th>
                                  <th> Cota Inativa </th>
                                  <th> Etapa </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.cotas.map((cota,index) => (
                                <tr key={index}>
                                  <td className="text-center"><Link
                                                  to={{pathname: "/financeiro/inclusao-valores/incluir",state: {
                                                    cota: cota
                                                  }}}
                                                  className="m-3 btn btn-sm btn-success"
                                                >
                                                Editar
                                      </Link>
                                  </td>
                                  <td> {cota.nome} </td>
                                  <td> {cota.administradora} </td>
                                  <td> {cota.grupo} </td>
                                  <td> {cota.contrato}</td>
                                  <td> 0 </td>
                                  <td> {cota.etapa} </td>
                                </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                 </div>
              </div>
          </div>
        </div>
      </div>
    )
  }
}



export default (FinanceiroInclusaoValores);
