import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import BoxMensagemOperador from './BoxMensagemOperador';
import BoxArquivoOperador from './BoxArquivoOperador';


const bubleOperador = {

   color:      "white",
   textAlign:  "justify",
   padding:    "15px",
   marginTop:  "15px",
   marginRight:  "15px",
   fontSize:   "12px",
   background: "blue",
   borderRadius:"10px",
}

const bubleOperadorData = {

   color:      "black",
   fontSize:   "8px",
   textAlign:  "right",
}

export class BubbleOperador extends Component {


  constructor(props){
        super(props);
  }


  render() {

    const { mensagem,data,arquivo }  = this.props;

    return (
      <>
        <div className="row">
            {arquivo
              ? <BoxArquivoOperador  arquivo={arquivo} />
              : <BoxMensagemOperador mensagem={mensagem} />
            }
         </div>
         <div className="row">
            <div className="col-md-8 offset-md-4" style={bubleOperadorData}>
             {data}
            </div>
          </div>
       </>
    )
  }
}

export default BubbleOperador;
