import React, { Component } from "react";
// import { Form } from "react-bootstrap";
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";
import { AlteraNome } from "../../actions";
import styled, { css } from "styled-components";
import Endereco from "../../components/Endereco";
import Telefone from "../../components/Telefone";
import Banco from "../../components/Banco";
import Cota from "../../components/Cota";
import Kyc from "../../components/Kyc";
import CabecalhoCliente from "../../components/CabecalhoCliente";
import Swal from "sweetalert2";
import Stepper from "react-stepper-horizontal";
import { getCliente, getCpfCnpj, getTipoPessoa } from "../../services/auth";
import TitlePage from "../../components/common/Title";
// import "./style.css";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import Representante from "../../components/Representante";
import { Aba, Card } from "./styles";
import { cpfCnpj, cpfCnpj2 } from "../../mask/cpfcnpj";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Email from "../../components/Email";
import { cpf } from "cpf-cnpj-validator";
import { BsCardList, BsFillPersonCheckFill } from "react-icons/bs";
import { GrDocumentText, GrLocation } from "react-icons/gr";
import { TiPhoneOutline } from "react-icons/ti";
import { RiBankLine } from "react-icons/ri";
import {
  MdAlternateEmail,
  MdAttachFile,
  MdOutlineAlternateEmail,
  MdOutlineLocationOn,
} from "react-icons/md";
import { TbFile3D, TbFiles, TbReportMoney } from "react-icons/tb";
import { FaMobileAlt, FaMoneyCheckAlt } from "react-icons/fa";
import { HiOutlineDocumentText } from "react-icons/hi";

import * as S from "./cadastro.styles";

import ImportacaoCliente from "../../components/ImportacaoCliente";
import {
  V3Autocomplete,
  V3TextCustom,
} from "../../styles/themes/components/input";
import { maskDataDeNascimento } from "../../styles/themes/utils/format";
import {
  isPastDate,
  v3ValidateEmail,
} from "../../styles/themes/utils/validate";
import moment from "moment";
import { V3Text5 } from "../../styles/themes/components/texts";
import { V3Button } from "../../styles/themes/components/button";
import { V3Modal } from "../../styles/themes/components/modal";
import Lottie from "lottie-react";
import Loading from "../../assets/lottie/loading.json";

const BoxInformativo = styled.div`
  text-align: center;
  color: white;
  padding: 10px;
  background: #16b6c5;
  width: 150px;
  height: 40px;
  margin-top: 20px;
  border-radius: 10px 10px 0px 0px;
  cursor: pointer;

  :hover {
    background: #16898e;
  }
`;

export class BackofficeDetalhamentoCliente extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: false,
      isLoading: false,
      show: false,
      cota: [],
      cliente: [],
      id: "",
      cpfCnpj: "",
      nomeRazao: "",
      email: "",
      profissao: "",
      genero: "",
      renda: "",
      conjuge_nome: "",
      conjuge_cpf: "",
      activeStep: 0,
      rg: "",
      dataNascimento: new Date(),
      nacionalidade: "",
      idNacionalidade: "",
      estadoCivil: "",
      codigo: "",
      grupo: [],
      questionario: [],
      administradora: [],
      blocoCadastro: true,
      blocoEndereco: false,
      blocoTelefone: false,
      blocoEmail: false,
      blocoBanco: false,
      blocoCotas: false,
      blocoKyc: false,
      backgroundCadastro: "#ccc",
      backgroundEndereco: "#16B6C5",
      backgroundTelefone: "#16B6C5",
      backgroundEmail: "#16B6C5",
      backgroundBanco: "#16B6C5",
      backgroundCotas: "#16B6C5",
      backgroundKyc: "#16B6C5",
      blocoAberto: "Cadastro",
      enderecos: [],
      recebimentos: [],
      telefones: [],
      propostas: [],
      cotas: [],
      profissoes: [],
      nacionalidades: [],
      estadosCivis: [],
      generos: [],
      emails: [],

      open: true,
      isEmail: true,
      hasErrorCpfCnpj: false,
      dtNascErr: false,
      emailErr: false,
      estadosCivisDesc: "",
      profissaoDesc: "",
      generoDesc: "",
      hasSuccess: false,
      hasError: false,
      errorText: "",
      successText: "",
    };
  }

  async componentDidMount() {
    const tamanho = window.innerWidth;
    this.setState({ isLoading: true });
    this.setState({ isMobile: tamanho <= 800 });

    const cpfCnpj = getCpfCnpj();
    const response = await api
      .post("api/concash/1.0/cliente/buscar", {
        cpfCnpj: cpfCnpj,
      })
      .catch((error) => {
        this.setState({
          hasError: true,
          errorText: "Erro ao buscar cliente.",
        });
        console.log(error);
      });

    const cliente = response.data;

    const nacionalidadesList = await api
      .post("/api/concash/1.0/cliente-area/nacionalidades")
      .then(({ data }) => {
        let nacionalidadesArr = [];
        data.forEach((item) => {
          if (item.nacId === cliente.idNascionalidade) {
            this.setState({ nacionalidadeDesc: item.nacNome });
          }
          nacionalidadesArr.push({ label: item.nacNome, id: item.nacId });
        });
        return nacionalidadesArr;
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log(err);
      });

    const profissoesList = await api
      .post("/api/concash/1.0/cliente-area/profissoes")
      .then(({ data }) => {
        let profissoesArr = [];

        data.forEach((item) => {
          if (cliente.idProfissao === item.prfId) {
            this.setState({ profissaoDesc: item.prfNome });
          }
          profissoesArr.push({ label: item.prfNome, id: item.prfId });
        });
        return profissoesArr;
      })
      .catch((error) => {
        this.setState({
          hasError: true,
          errorText: "Erro ao buscar profissões.",
        });
        console.log(error);
      });
    const generosList = await api
      .post("/api/concash/1.0/cliente-area/generos")
      .then(({ data }) => {
        let generosArr = [];
        data.forEach((item) => {
          generosArr.push({ label: item.sexSexo, id: item.sexId });
          if (item.sexId === cliente.idSexo) {
            this.setState({ generoDesc: item.sexSexo });
          }
        });
        return generosArr;
      })
      .catch((error) => {
        this.setState({
          hasError: true,
          errorText: "Erro ao buscar generos.",
        });
        console.log(error);
      });

    const estadosCivisList = await api
      .post("/api/concash/1.0/cliente-area/estados-civis")
      .then(({ data }) => {
        let estadosCivisArr = [];
        data.forEach((item) => {
          estadosCivisArr.push({ label: item.etcEstadoCivil, id: item.etcId });
          if (item.etcId === cliente.idEstadoCivil) {
            this.setState({ estadosCivisDesc: item.etcEstadoCivil });
          }
        });
        return estadosCivisArr;
      })
      .catch((error) => {
        this.setState({
          hasError: true,
          errorText: "Erro ao buscar estados civis.",
        });
        console.log(error);
      });
    const newdata = cpfCnpj2(String(cliente.cpfCnpj));
    this.setState({
      cliente: cliente,
      id: cliente.id,
      nomeRazao: cliente.nomeRazao,
      codigo: cliente.codigo,
      cpfCnpj: cpfCnpj2(String(cliente.cpfCnpj)),
      nacionalidade: cliente.idNascionalidade,
      rg: cliente.numDocumento,
      dataNascimento: cliente.dataNascimento
        ? moment(cliente.dataNascimento).format("DD/MM/YYYY")
        : "",
      renda: cliente.renda,
      profissao: cliente.idProfissao,
      genero: cliente.idSexo,
      email: cliente.email,
      estadoCivil: cliente.idEstadoCivil,
      conjuge_nome: cliente.conjugeNome,
      conjuge_cpf: cpfCnpj2(String(cliente.conjugeCpf)),
      isLoading: false,
      nacionalidades: nacionalidadesList,
      profissoes: profissoesList,
      estadosCivis: estadosCivisList,
      generos: generosList,
      open: false,
    });
  }

  handleFormatCoin(num) {
    this.setState({
      renda: num.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      }),
    });
  }

  handleCpfCnpj(text) {
    const newdata = cpfCnpj(text);
    this.setState({ cpfCnpj: newdata });
  }

  handleChangeRenda(renda) {
    if (renda.length > 6) {
      this.setState({ renda: this.state.renda });
    } else {
      const numBruto = parseFloat(renda);
      const numStr = renda.replace(/[^0-9\\.]+/g, "");
      this.setState({ renda: numStr.toLocaleString() });
    }
  }

  handleCpfConjuge(text) {
    if (text.length >= 11) {
      if (text.length <= 11 && text.length > 0) {
        if (cpf.isValid(text)) {
          this.setState({ hasErrorCpfCnpj: false });
        } else {
          this.setState({ hasErrorCpfCnpj: true });
        }
      } else {
        if (cpf.isValid(text)) {
          this.setState({ hasErrorCpfCnpj: false });
        } else {
          this.setState({ hasErrorCpfCnpj: true });
        }
      }
    } else {
      this.setState({ hasErrorCpfCnpj: true });
    }
    const newdata = cpfCnpj(text);
    this.setState({ conjuge_cpf: newdata });
  }

  async obtemProfissoes() {
    try {
      const response = await api.post(
        "/api/concash/1.0/cliente-area/profissoes",
        { codigo: this.state.codigoCliente }
      );
      var newResp = [];
      response.data.map(function (item) {
        newResp.push({ id: item.prfId, label: item.prfNome });
      });
      this.setState({ profissoes: newResp });
    } catch (err) {
      this.setState({
        error: "Houve um problema para obter a lista de nacionalidades.",
      });
    }
  }

  alteraAbas = (aba) => {
    this.setState({ blocoAberto: aba });
    const abas = [
      "Cadastro",
      "Endereço",
      "Telefone",
      "Banco",
      "Cotas",
      "Representante",
      "Importacao",
      "KYC",
    ];

    var tmp_cadastro = false;
    var tmp_endereco = false;
    var tmp_telefone = false;
    var tmp_email = false;
    var tmp_banco = false;
    var tmp_cotas = false;
    var tmp_repres = false;
    var tmp_impotacao = false;
    var tmp_kyc = false;
    var bck_cadastro = "#16B6C5";
    var bck_endereco = "#16B6C5";
    var bck_telefone = "#16B6C5";
    var bck_email = "#16B6C5";
    var bck_banco = "#16B6C5";
    var bck_repre = "#16B6C5";
    var bck_cotas = "#16B6C5";
    var bck_kyc = "#16B6C5";

    for (var i = 0; i < abas.length; i++) {
      if (aba == "Cadastro") {
        tmp_cadastro = true;
        bck_cadastro = "#ccc";
      }
      if (aba == "Endereço") {
        tmp_endereco = true;
        bck_endereco = "#ccc";
      }
      if (aba == "Telefone") {
        tmp_telefone = true;
        bck_telefone = "#ccc";
      }
      if (aba == "Email") {
        tmp_email = true;
        bck_email = "#ccc";
      }
      if (aba == "Banco") {
        tmp_banco = true;
        bck_banco = "#ccc";
      }
      if (aba == "Cotas") {
        tmp_cotas = true;
        bck_cotas = "#ccc";
      }
      if (aba == "Representante") {
        tmp_repres = true;
        bck_repre = "#ccc";
      }
      if (aba == "Importacao") {
        tmp_impotacao = true;
      }
      if (aba == "KYC") {
        tmp_kyc = true;
        bck_kyc = "#ccc";
      }
    }

    this.setState({
      blocoCadastro: tmp_cadastro,
      blocoEndereco: tmp_endereco,
      blocoTelefone: tmp_telefone,
      blocoEmail: tmp_email,
      blocoBanco: tmp_banco,
      blocoCotas: tmp_cotas,
      blocoRepres: tmp_repres,
      blocoImportacao: tmp_impotacao,
      blocoKyc: tmp_kyc,
    });
    this.setState({
      backgroundCadastro: bck_cadastro,
      backgroundEndereco: bck_endereco,
      backgroundTelefone: bck_telefone,
      backgroundEmail: bck_email,
      backgroundBanco: bck_banco,
      backgroundCotas: bck_cotas,
      backgroundRepres: bck_repre,
      backgroundKyc: bck_kyc,
    });
  };

  generoOptions = [
    { label: "Masculino", id: 1 },
    { label: "Feminino", id: 2 },
    { label: "Outros", id: 3 },
  ];
  canalOptions = [
    { label: "Google", id: 1 },
    { label: "Facebook", id: 2 },
    { label: "Telefone", id: 3 },
    { label: "Indicação", id: 4 },
  ];
  handleFormCliente = async (e) => {
    e.preventDefault();
    const {
      id,
      nomeRazao,
      cpfCnpj,
      email,
      renda,
      profissao,
      conjuge_nome,
      conjuge_cpf,
      nacionalidade,
      rg,
      estadoCivil,
      dataNascimento,
      genero,
      codigo,
    } = this.state;
    if (!nomeRazao || !email || !cpfCnpj || !dataNascimento) {
      Swal.fire("Preencha os campos obrigatórios para continuar!");
    } else if (this.state.hasErrorCpfCnpj) {
      Swal.fire("Conjugue CPF inválido!");
    } else {
      try {
        const cpfCnpjTrue = cpfCnpj.replace(/[^0-9]/g, "");
        const rendaNova = parseFloat(renda);
        const cpfCnpjCJ = conjuge_cpf.replace(/[^0-9]/g, "");
        const dt = dataNascimento.split("/");
        const newDT = `${dt[2]}-${dt[1]}-${dt[0]}`;
        const dtFinal = moment(newDT).format();

        const response = await api
          .put("api/concash/1.0/cliente/editar", {
            id,
            nomeRazao,
            cpfCnpj: cpfCnpjTrue,
            email,
            idProfissao: profissao,
            numDocumento: rg,
            idEstadoCivil: estadoCivil,
            idNascionalidade: nacionalidade,
            dataNascimento: dtFinal,
            idSexo: genero,
            renda: rendaNova,
            conjugeCpf: cpfCnpjCJ,
            conjugeNome: conjuge_nome,
            codigo,
          })
          .then(() => {
            this.setState({
              hasSuccess: true,
              successText: "Dados salvos com sucesso!",
            });
          });
      } catch (err) {
        this.setState({
          hasError: true,
          errorText: "Não foi possível realizar a operação!",
        });
        console.log(err);
      }
    }
  };

  handleDataDeNascimento(text) {
    const validating = text.replace(/[^\d]/g, "");
    if (validating.length <= 8) {
      this.setState({ dataNascimento: maskDataDeNascimento(text) });
      if (isPastDate(text)) {
        this.setState({ dtNascErr: false });
      } else {
        this.setState({ dtNascErr: true });
      }
    }
  }

  handleEmail(t) {
    const text = t.replaceAll(" ", "");
    if (v3ValidateEmail(text) || text === "") {
      this.setState({
        email: text,
        emailErr: false,
      });
    } else {
      this.setState({
        email: text,
        emailErr: true,
      });
    }
  }

  handleChangeNacionalidade = (selectedOption) => {
    this.setState({ selectedOption });
    this.setState({
      nacionalidade: selectedOption.value,
    });
  };
  render() {
    const tipoPessoa = getTipoPessoa();

    return (
      <>
        <Backdrop open={this.state.isLoading} style={{ zIndex: 999 }}>
          <div>
            <Lottie animationData={Loading} />
          </div>
        </Backdrop>
        {this.state.isMobile ? (
          <>
            <V3Modal
              open={this.state.hasError}
              actionClose={() => this.setState({ hasError: false })}
              title={"Atenção!"}
              text={this.state.errorText}
              type="error"
            />
            <V3Modal
              open={this.state.hasSuccess}
              actionClose={() => this.setState({ hasSuccess: false })}
              title={this.state.successText}
              type="success"
            />
            <div>
              <div style={{ padding: "0px 20px", margin: 0 }}>
                <S.CadastroTitle>Meu cadastro</S.CadastroTitle>
                <div
                  style={{
                    display: "flex",
                    overflow: "auto",
                    flexWrap: "nowrap",
                    gap: 5,
                    marginTop: 20,
                    marginBottom: 30,
                    overflowY: "visible",
                  }}
                >
                  <S.CadastroAba
                    active={this.state.blocoAberto === "Cadastro"}
                    icon={<HiOutlineDocumentText size={20} />}
                    title="Dados Pessoais"
                    onClick={(e) => this.alteraAbas("Cadastro")}
                  />
                  <S.CadastroAba
                    active={this.state.blocoAberto === "Endereço"}
                    icon={<MdOutlineLocationOn size={20} />}
                    title="Endereço"
                    onClick={(e) => this.alteraAbas("Endereço")}
                  />
                  <S.CadastroAba
                    active={this.state.blocoAberto === "Telefone"}
                    icon={<FaMobileAlt size={20} />}
                    title="Telefone"
                    onClick={(e) => this.alteraAbas("Telefone")}
                  />

                  <S.CadastroAba
                    active={this.state.blocoAberto === "Banco"}
                    icon={<RiBankLine size={20} />}
                    title="Banco"
                    onClick={(e) => this.alteraAbas("Banco")}
                  />
                  <S.CadastroAba
                    active={this.state.blocoAberto === "Importacao"}
                    icon={<MdAttachFile size={20} />}
                    title="Anexos"
                    onClick={(e) => this.alteraAbas("Importacao")}
                  />
                  {tipoPessoa == "PJ" && (
                    <S.CadastroAba
                      active={this.state.blocoAberto === "Representante"}
                      icon={<BsFillPersonCheckFill size={20} />}
                      title="Representante"
                      onClick={(e) => this.alteraAbas("Representante")}
                    />
                  )}
                </div>
                {this.state.blocoCadastro && (
                  <S.ContentBoxMobile>
                    <S.Row>
                      <V3TextCustom
                        id="nome"
                        className="input-flex"
                        label="Nome / Razão Social"
                        value={this.state.nomeRazao}
                        onChange={(e) =>
                          this.setState({
                            nomeRazao: e.target.value,
                          })
                        }
                        variant="outlined"
                        size="small"
                        required
                      />
                    </S.Row>
                    <S.Row>
                      <V3TextCustom
                        className="input-flex"
                        id="cpfCnpj"
                        label="CPF / CNPJ "
                        inputProps={{ maxLength: 18 }}
                        value={this.state.cpfCnpj}
                        onChange={(e) => this.handleCpfCnpj(e.target.value)}
                        variant="outlined"
                        disabled
                        size="small"
                        required
                      />
                    </S.Row>
                    <S.Row>
                      <V3TextCustom
                        size="small"
                        className="input-flex"
                        label="Data de Nascimento"
                        value={this.state.dataNascimento}
                        onChange={(e) =>
                          this.handleDataDeNascimento(e.target.value)
                        }
                        error={this.state.dtNascErr}
                        helperText={
                          this.state.dtNascErr && "Data de nascimento inválida."
                        }
                        required
                      />
                    </S.Row>
                    <S.Row>
                      <V3TextCustom
                        className="input-flex"
                        id="email"
                        label="E-mail"
                        value={this.state.email}
                        error={this.state.emailErr}
                        onChange={(e) => {
                          e.target.value.includes("@")
                            ? this.setState({
                                email: e.target.value,
                                isEmail: true,
                              })
                            : this.setState({
                                email: e.target.value,
                                isEmail: false,
                              });
                        }}
                        variant="outlined"
                        helperText={this.state.emailErr && "E-mail inválido."}
                        size="small"
                        required
                      />
                    </S.Row>
                    <S.Row>
                      <V3TextCustom
                        className="input-flex"
                        size="small"
                        id="rg"
                        label="RG / RNE"
                        value={this.state.rg}
                        onChange={(e) =>
                          this.setState({
                            rg: e.target.value,
                          })
                        }
                        variant="outlined"
                      />
                    </S.Row>
                    <S.Row>
                      <V3Autocomplete
                        className="input-flex"
                        disablePortal
                        size="small"
                        id="nacionalidade"
                        options={this.state.nacionalidades}
                        onChange={(e, input) => {
                          if (input) {
                            this.setState({
                              nacionalidade: input.id,
                              nacionalidadeDesc: input.label,
                            });
                          } else {
                            this.setState({
                              nacionalidade: null,
                              nacionalidadeDesc: null,
                            });
                          }
                        }}
                        value={
                          this.state.nacionalidadeDesc
                            ? this.state.nacionalidadeDesc
                            : ""
                        }
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <V3TextCustom {...params} label="Nacionalidade" />
                        )}
                      />
                    </S.Row>
                    <S.Row>
                      <V3Autocomplete
                        className="input-flex"
                        disablePortal
                        id="genero"
                        options={this.state.generos}
                        onChange={(e, input) =>
                          this.setState({
                            genero: input.id,
                            generoDesc: input.label,
                          })
                        }
                        sx={{ width: 300 }}
                        size="small"
                        value={
                          this.state.generoDesc ? this.state.generoDesc : ""
                        }
                        renderInput={(params) => (
                          <V3TextCustom {...params} label="Gênero" />
                        )}
                      />
                    </S.Row>
                    <S.Row>
                      <V3Autocomplete
                        className="input-flex"
                        disablePortal
                        id="profissao"
                        size="small"
                        options={this.state.profissoes}
                        onChange={(e, input) => {
                          this.setState({
                            profissao: input.id,
                            profissaoDesc: input.label,
                          });
                        }}
                        sx={{ width: 300 }}
                        value={
                          this.state.profissaoDesc
                            ? this.state.profissaoDesc
                            : ""
                        }
                        renderInput={(params) => (
                          <V3TextCustom {...params} label="Profissão" />
                        )}
                      />
                    </S.Row>

                    <S.Row style={{ marginTop: 15 }}>
                      <V3Autocomplete
                        className="input-flex"
                        disablePortal
                        size="small"
                        id="estadoCivil"
                        options={this.state.estadosCivis}
                        onChange={(e, input) =>
                          this.setState({
                            estadoCivil: input.id,
                          })
                        }
                        value={
                          this.state.estadosCivisDesc
                            ? this.state.estadosCivisDesc
                            : ""
                        }
                        renderInput={(params) => (
                          <V3TextCustom {...params} label="Estado Civil" />
                        )}
                      />
                    </S.Row>

                    <S.Row>
                      <V3Text5 style={{ color: "#000", fontStyle: "italic" }}>
                        * Preenchimento Obrigatório
                      </V3Text5>
                    </S.Row>
                    <S.Row>
                      <V3Button
                        onClick={this.handleFormCliente}
                        estilo="primary"
                        width={"100%"}
                        text="Salvar"
                      />
                    </S.Row>
                  </S.ContentBoxMobile>
                )}

                <div className="" style={{ padding: 0 }}>
                  <div className="">
                    <div className="" style={{ paddingTop: 10 }}>
                      {this.state.cliente.nome && (
                        <CabecalhoCliente cliente={this.state.cliente} />
                      )}

                      {this.state.blocoEndereco && (
                        <Endereco codigoCliente={this.state.cliente.id} />
                      )}

                      {this.state.blocoTelefone && (
                        <Telefone codigoCliente={this.state.cliente.id} />
                      )}

                      {this.state.blocoEmail && (
                        <Email codigoCliente={this.state.cliente.id} />
                      )}

                      {this.state.blocoBanco && (
                        <Banco codigoCliente={this.state.cliente.id} />
                      )}

                      {this.state.blocoCotas && (
                        <div className="row">
                          <div
                            className="col-md-12"
                            style={{
                              background: "#ccc",
                              minHeight: "300px",
                              padding: "20px",
                            }}
                          >
                            <Cota
                              cliente={this.state.cliente}
                              origem="BACKOFFICE"
                            />
                          </div>
                        </div>
                      )}

                      {this.state.blocoImportacao && (
                        <ImportacaoCliente
                          cliente={this.state.cliente}
                          origem="BACKOFFICE"
                        />
                      )}

                      {this.state.blocoRepres && (
                        <Representante
                          cliente={this.state.cliente}
                          origem="BACKOFFICE"
                        />
                      )}

                      {this.state.blocoKyc && (
                        <div className="row">
                          <div
                            className="col-md-12"
                            style={{
                              background: "#ccc",
                              minHeight: "300px",
                              padding: "20px",
                            }}
                          >
                            <Kyc codigoCliente={this.state.cliente.id} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <V3Modal
              open={this.state.hasError}
              actionClose={() => this.setState({ hasError: false })}
              title={"Atenção!"}
              text={this.state.errorText}
              type="error"
            />
            <V3Modal
              open={this.state.hasSuccess}
              actionClose={() => this.setState({ hasSuccess: false })}
              title={this.state.successText}
              type="success"
            />
            <div>
              <div style={{ padding: "0px 20px", margin: 0 }}>
                <S.CadastroTitle>Meu cadastro</S.CadastroTitle>
                <div
                  style={{
                    display: "flex",
                    overflow: "visible",
                    flexWrap: "nowrap",
                    gap: 5,
                    marginTop: 20,
                    marginBottom: 30,
                  }}
                >
                  <S.CadastroAba
                    active={this.state.blocoAberto === "Cadastro"}
                    icon={<HiOutlineDocumentText size={20} />}
                    title="Dados Pessoais"
                    onClick={(e) => this.alteraAbas("Cadastro")}
                  />
                  <S.CadastroAba
                    active={this.state.blocoAberto === "Endereço"}
                    icon={<MdOutlineLocationOn size={20} />}
                    title="Endereço"
                    onClick={(e) => this.alteraAbas("Endereço")}
                  />
                  <S.CadastroAba
                    active={this.state.blocoAberto === "Telefone"}
                    icon={<FaMobileAlt size={20} />}
                    title="Telefone"
                    onClick={(e) => this.alteraAbas("Telefone")}
                  />

                  <S.CadastroAba
                    active={this.state.blocoAberto === "Banco"}
                    icon={<RiBankLine size={20} />}
                    title="Banco"
                    onClick={(e) => this.alteraAbas("Banco")}
                  />
                  <S.CadastroAba
                    active={this.state.blocoAberto === "Importacao"}
                    icon={<MdAttachFile size={20} />}
                    title="Anexos"
                    onClick={(e) => this.alteraAbas("Importacao")}
                  />
                  {tipoPessoa == "PJ" && (
                    <S.CadastroAba
                      active={this.state.blocoAberto === "Representante"}
                      icon={<BsFillPersonCheckFill size={20} />}
                      title="Representante"
                      onClick={(e) => this.alteraAbas("Representante")}
                    />
                  )}
                </div>
                {this.state.blocoCadastro && (
                  <S.ContentBox>
                    <S.Row>
                      <V3TextCustom
                        id="nome"
                        className="input-flex"
                        label="Nome / Razão Social"
                        value={this.state.nomeRazao}
                        onChange={(e) =>
                          this.setState({
                            nomeRazao: e.target.value,
                          })
                        }
                        variant="outlined"
                        size="small"
                        required
                      />
                      <V3TextCustom
                        className="input-flex"
                        id="cpfCnpj"
                        label="CPF / CNPJ "
                        inputProps={{ maxLength: 18 }}
                        value={this.state.cpfCnpj}
                        onChange={(e) => this.handleCpfCnpj(e.target.value)}
                        variant="outlined"
                        disabled
                        size="small"
                        required
                      />
                    </S.Row>
                    <S.Row>
                      <V3TextCustom
                        size="small"
                        className="input-flex"
                        label="Data de Nascimento"
                        value={this.state.dataNascimento}
                        onChange={(e) =>
                          this.handleDataDeNascimento(e.target.value)
                        }
                        error={this.state.dtNascErr}
                        helperText={
                          this.state.dtNascErr && "Data de nascimento inválida."
                        }
                        required
                      />
                      <V3TextCustom
                        className="input-flex"
                        id="email"
                        label="E-mail"
                        value={this.state.email}
                        error={this.state.emailErr}
                        onChange={(e) => {
                          e.target.value.includes("@")
                            ? this.setState({
                                email: e.target.value,
                                isEmail: true,
                              })
                            : this.setState({
                                email: e.target.value,
                                isEmail: false,
                              });
                        }}
                        variant="outlined"
                        helperText={this.state.emailErr && "E-mail inválido."}
                        size="small"
                        required
                      />
                    </S.Row>
                    <S.Row>
                      <V3TextCustom
                        className="input-flex"
                        size="small"
                        id="rg"
                        label="RG / RNE"
                        value={this.state.rg}
                        onChange={(e) =>
                          this.setState({
                            rg: e.target.value,
                          })
                        }
                        variant="outlined"
                      />
                      <V3Autocomplete
                        className="input-flex"
                        disablePortal
                        size="small"
                        id="nacionalidade"
                        options={this.state.nacionalidades}
                        onChange={(e, input) => {
                          if (input) {
                            this.setState({
                              nacionalidade: input.id,
                              nacionalidadeDesc: input.label,
                            });
                          } else {
                            this.setState({
                              nacionalidade: null,
                              nacionalidadeDesc: null,
                            });
                          }
                        }}
                        value={
                          this.state.nacionalidadeDesc
                            ? this.state.nacionalidadeDesc
                            : ""
                        }
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <V3TextCustom {...params} label="Nacionalidade" />
                        )}
                      />
                    </S.Row>
                    <S.Row>
                      <V3Autocomplete
                        className="input-flex"
                        disablePortal
                        id="genero"
                        options={this.state.generos}
                        onChange={(e, input) =>
                          this.setState({
                            genero: input.id,
                            generoDesc: input.label,
                          })
                        }
                        sx={{ width: 300 }}
                        size="small"
                        value={
                          this.state.generoDesc ? this.state.generoDesc : ""
                        }
                        renderInput={(params) => (
                          <V3TextCustom {...params} label="Gênero" />
                        )}
                      />
                      <V3Autocomplete
                        className="input-flex"
                        disablePortal
                        id="profissao"
                        size="small"
                        options={this.state.profissoes}
                        onChange={(e, input) => {
                          this.setState({
                            profissao: input.id,
                            profissaoDesc: input.label,
                          });
                        }}
                        sx={{ width: 300 }}
                        value={
                          this.state.profissaoDesc
                            ? this.state.profissaoDesc
                            : ""
                        }
                        renderInput={(params) => (
                          <V3TextCustom {...params} label="Profissão" />
                        )}
                      />
                    </S.Row>

                    <S.Row style={{ marginTop: 15, maxWidth: "49%" }}>
                      <V3Autocomplete
                        className="input-flex"
                        disablePortal
                        size="small"
                        id="estadoCivil"
                        options={this.state.estadosCivis}
                        onChange={(e, input) =>
                          this.setState({
                            estadoCivil: input.id,
                          })
                        }
                        value={
                          this.state.estadosCivisDesc
                            ? this.state.estadosCivisDesc
                            : ""
                        }
                        renderInput={(params) => (
                          <V3TextCustom {...params} label="Estado Civil" />
                        )}
                      />
                    </S.Row>

                    <S.Row>
                      <V3Text5 style={{ color: "#000", fontStyle: "italic" }}>
                        * Preenchimento Obrigatório
                      </V3Text5>
                    </S.Row>
                    <S.Row>
                      <V3Button
                        onClick={this.handleFormCliente}
                        estilo="primary"
                        width={"49%"}
                        text="Salvar"
                      />
                    </S.Row>
                  </S.ContentBox>
                )}

                <div className="" style={{ padding: 0 }}>
                  <div className="">
                    <div className="" style={{ paddingTop: 10 }}>
                      {this.state.cliente.nome && (
                        <CabecalhoCliente cliente={this.state.cliente} />
                      )}

                      {this.state.blocoEndereco && (
                        <Endereco codigoCliente={this.state.cliente.id} />
                      )}

                      {this.state.blocoTelefone && (
                        <Telefone codigoCliente={this.state.cliente.id} />
                      )}

                      {this.state.blocoBanco && (
                        <Banco codigoCliente={this.state.cliente.id} />
                      )}

                      {this.state.blocoCotas && (
                        <div className="row">
                          <div
                            className="col-md-12"
                            style={{
                              background: "#ccc",
                              minHeight: "300px",
                              padding: "20px",
                            }}
                          >
                            <Cota
                              cliente={this.state.cliente}
                              origem="BACKOFFICE"
                            />
                          </div>
                        </div>
                      )}

                      {this.state.blocoImportacao && (
                        <ImportacaoCliente
                          cliente={this.state.cliente}
                          origem="BACKOFFICE"
                        />
                      )}

                      {this.state.blocoRepres && (
                        <Representante
                          cliente={this.state.cliente}
                          origem="BACKOFFICE"
                        />
                      )}

                      {this.state.blocoKyc && (
                        <div className="row">
                          <div
                            className="col-md-12"
                            style={{
                              background: "#ccc",
                              minHeight: "300px",
                              padding: "20px",
                            }}
                          >
                            <Kyc codigoCliente={this.state.cliente.id} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default withRouter(BackofficeDetalhamentoCliente);
