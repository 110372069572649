import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import api from "../../services/api";
import { Link } from "react-router-dom";
import SweetAlert from 'sweetalert2-react';
import Swal from 'sweetalert2'



export class CotaDocumentacaoAssinadaLista extends Component {



  constructor(){
        super();
        this.state = {
          cotas: [],
          show:  false
        };
  }

  async componentDidMount() {

        try {
          const response = await api.post("api/concash/1.0/visao-documentacao-assinada/listar", {});
          this.setState({ cotas: response.data});
        } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter as cotas com a documentação assinada."
          });
        }
  }


  render() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Cotas - Documentação Assinada </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th> Administradora </th>
                          <th> Grupo </th>
                          <th> Cota </th>
                          <th> Cliente </th>
                          <th className="text-center"> Ação </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.cotas.map((cota,index) => (
                        <tr key={index}>
                          <td> {cota.administradora} </td>
                          <td> {cota.grupo} </td>
                          <td> {cota.contrato} </td>
                          <td> {cota.nome} </td>
                          <td className="text-center"><Link
                                          to={{pathname: "/aprovar-documentos",state: {
                                            cota: cota
                                          }}}
                                          className="m-3 btn btn-sm btn-success"
                                        >
                                        Homologar
                              </Link>
                          </td>
                        </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CotaDocumentacaoAssinadaLista
