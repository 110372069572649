import React, { useEffect, useLayoutEffect, useState } from "react";
import useAutoservico from "../../store/autoservico/useAutoservico";
import { v3FormataCNPJ, v3MaskCPF } from "../../styles/themes/utils/format";
import { validarCNPJ, validarCPF } from "../../utils/validadores";
import { V3Modal } from "../../styles/themes/components/modal";
import LoadingCustom from "../../components/LoadingCustom";
import { LoginScreenDesktop, LoginScreenMobile } from "./screens/login";
import { useDispatch, useSelector } from "react-redux";
import api from "../../services/api";
import { maskPhone } from "../../mask/maskPhone";
import { setTokenSession } from "../../services/auth";
import { atribuirTokenConsort, autoAuth } from "../../store/autoservico";
import { login } from "../../services/auth"; 

export const LoginAutoServico = ({ estilo }) => {
  const service = window.location.pathname
    .replace("/autoservico/", "")
    .replace("autoservico", "")
    .split("/");
  const adm = service[0];
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [hasSuccess, setHasSuccess] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [cpfErr, setCpfErr] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState("");
  const [solicitado, setSolicitado] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [seconds, setSeconds] = useState(30);
  const [code, setCode] = useState("");

  const [listMethods, setListMethods] = useState("");
  const { logout } = useAutoservico();
  const { logged } = useSelector((state) => state.autoservico);

  function handleCpfCnpj(text) {
    const validating = text.replace(/[^\d]/g, "");

    if (validating.length <= 14) {
      setCpfCnpj(validating);
      if (validating.length <= 11) {
        const cpfs = v3MaskCPF(validating);
        setCpfCnpj(cpfs);
        setCpfErr(!validarCPF(validating));
      } else {
        const cn = v3FormataCNPJ(validating);
        setCpfCnpj(cn);
        setCpfErr(!validarCNPJ(validating));
      }
    }
  }

  async function handleSearchByCpfCnpj(e) {
    e.preventDefault();
    const validating = cpfCnpj.replace(/[^\d]/g, "");

    if (!cpfCnpj) {
      setErrorText("Preencha corretamente com um CPF / CNPJ válido.");
      return setHasError(true);
    }

    if (cpfErr) {
      setLoading(false);
      setErrorText("CPF / CNPJ inválido");
      return setHasError(true);
    }

    if (validating.length <= 14) {
      if (validating.length <= 11) {
        const valid = validarCPF(validating);
        if (!valid) {
          setLoading(false);
          setErrorText("CPF / CNPJ inválido");
          return setHasError(true);
        }
      } else {
        const valid = validarCNPJ(validating);
        if (!valid) {
          setLoading(false);
          setErrorText("CPF / CNPJ inválido");
          return setHasError(true);
        }
      }
    }

    setLoading(true);

    await api
      .get(
        `/api/concash/1.0/consorcio-grupo-cota/forma-autenticacao?cpfCnpj=${validating}`
      )
      .then(({ data }) => {
        setListMethods(data?.autenticacoes);
      });

    setLoading(false);
  }

  function startCountdown() {
    setIsActive(true);
  }

  async function handleRequestCode() {
    if (!selectedMethod) {
      setErrorText("Selecione o método de autenticação");
      return setHasError(true);
    }

    const documento = cpfCnpj.replace(/[^\d]/g, "");

    await api
      .get("api/auth/otp-request", {
        headers: {
          Authtype: selectedMethod.tipo,
          Document: documento,
        },
      })
      .then(({ data }) => {
        setSolicitado(true);
        startCountdown();
      }).catch((e) => {
        setErrorText("Erro ao solicitar o código");
        setHasError(true);
      })
  }

  function handleBackToRequest() {
    setSolicitado(false);
    setSelectedMethod("");
  }

  async function handleLogin() {
    const documento = cpfCnpj.replace(/[^\d]/g, "");
    let formData = new FormData();
    formData.append("cpfCnpj", documento);
    setLoading(true);
    await api
      .post(`/api/auth/otp-validate?cpfCnpj=${documento}&token=${code.toUpperCase()}`)
      .then(({ data }) => {
        handleGetAtendimentos(data);
      })
      .catch((e) => {
        setErrorText("Erro ao autenticar");
        setHasError(true);
        return setLoading(false);
      })

  }

  async function handleGetAtendimentos(dt) {
    try {
      const token = await dt?.token;
      login(await token);
      const {data} = await api.get(
        "/api/concash/1.0/consorcio-grupo-cota/recuperar-dados-atendimento",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        
    const loginData = {
      cpfCnpj: await cpfCnpj,
      method: await selectedMethod.tipo,
      token: await token,
      otp: code.toUpperCase(),
      tokenConsort: await dt?.tokenConsort,
      username: await data?.dadosPessoais?.nomeRazao,
      apelido: await data?.dadosPessoais?.nomeRazao.split(" ")[0],
      userType: "CLIENTE",
      tipoPessoa: await cpfCnpj.replace(/[^\d]/g, "").length <= 11 ? "PF" : "PJ",
      idAtendimento: await data?.idAtendimento,
      nome: await data?.dadosPessoais?.nomeRazao,
      email: await data?.dadosPessoais?.email,
      phone: await data?.dadosPessoais?.telefone,
    };


    dispatch(autoAuth(await loginData));
    setLoading(false);
    window.location.href = `/autoservico/${adm}/minhas-cotas`;
    } catch (error) {
      setLoading(false);
      setErrorText("Houve um erro ao reconhecer o código!");
      return setHasError(true);
    }
  } 

  useEffect(() => {
    let interval = null;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setIsActive(true);
        setSeconds(seconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setIsActive(false);
      setSeconds(30);
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isActive, seconds]);

  useEffect(() => {
    if (logged) {
      window.location.href = "/autoservico";
    }
  }, []);

  useLayoutEffect(() => {
    const parameterDoc = new URLSearchParams(window.location.search);
    if(!cpfCnpj &&  parameterDoc.get("document")) {
      handleCpfCnpj(parameterDoc.get("document"));
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <V3Modal
        open={hasError}
        actionClose={() => setHasError(false)}
        title={errorText}
        customButtonColor={estilo?.primary}
        type="warning"
      />
      <V3Modal
        open={hasSuccess}
        actionClose={() => setHasSuccess(false)}
        title={successText}
        type="success"
      />
      <LoadingCustom open={loading} />
      {isMobile ? (
        <LoginScreenMobile
          handleLogin={handleLogin}
          estilo={estilo}
          selectedMethod={selectedMethod}
          solicitado={solicitado}
          isActive={isActive}
          isMobile={isMobile}
          setCode={setCode}
          code={code}
          seconds={seconds}
          handleBackToRequest={handleBackToRequest}
          handleRequestCode={handleRequestCode}
          listMethods={listMethods}
          setSelectedMethod={setSelectedMethod}
          cpfCnpj={cpfCnpj}
          handleCpfCnpj={handleCpfCnpj}
          cpfErr={cpfErr}
          handleSearchByCpfCnpj={handleSearchByCpfCnpj}
        />
      ) : (
        <LoginScreenDesktop
          handleLogin={handleLogin}
          estilo={estilo}
          selectedMethod={selectedMethod}
          solicitado={solicitado}
          isActive={isActive}
          isMobile={isMobile}
          setCode={setCode}
          code={code}
          seconds={seconds}
          handleBackToRequest={handleBackToRequest}
          handleRequestCode={handleRequestCode}
          listMethods={listMethods}
          setSelectedMethod={setSelectedMethod}
          cpfCnpj={cpfCnpj}
          handleCpfCnpj={handleCpfCnpj}
          cpfErr={cpfErr}
          handleSearchByCpfCnpj={handleSearchByCpfCnpj}
        />
      )}
    </>
  );
};
