import React, { useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { MdLogout } from "react-icons/md";
import styled from "styled-components";
import { executaLogout } from "../../services/auth";

export const BlockUser = ({ user }) => {
  const [visible, setVisible] = useState(false);
  const str = user;
  const capitalized = str[0]?.toUpperCase() + str?.substr(1).toLowerCase();

  const LogoutButton = styled.div`
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px #00000029;
    border-radius: 10px;
    opacity: 1;
    position: absolute;
    width: 180px;
    height: 65px;
    top: 60px;
    right: 85px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `;

  const ButtonIO = styled.div`
    display: flex;
    width: 160px;
    height: 45px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;

    &:hover {
      background: #f5f5f5;
    }
  `;

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: 10,
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onMouseEnter={() => setVisible(true)}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 30,
            height: 30,
            backgroundColor: "#16898E",
            borderRadius: "50%",
          }}
        >
          <span
            style={{
              fontFamily: "Roboto",
              fontSize: 16,
              color: "#FFF",
            }}
          >
            {user[0]?.toUpperCase()}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ fontFamily: "Roboto", fontSize: 15 }}>
            Olá {capitalized}
          </span>
          <IoMdArrowDropdown />
        </div>
        {visible && (
          <LogoutButton onMouseLeave={() => setVisible(false)}>
            <ButtonIO onClick={executaLogout}>
              <MdLogout size={24} color={"#16898E"} />
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <span
                  style={{
                    color: "#16898E",
                    marginLeft: 8,
                    fontWeight: "bold",
                  }}
                >
                  Sair
                </span>
              </div>
            </ButtonIO>
          </LogoutButton>
        )}
      </div>
    </>
  );
};
