import styled from "styled-components";

export const Card = styled.div`
  background: #fff;
  margin: 0 auto 48px;

  margin-left: 0;
  select{
    height:45px;
  }
  button[type="RESET"]{
    background: #ff0043;
    border: 2px;
    border-color: #ff0043;
    border-radius: 6px;
    border-style: solid;
    margin-right: 10px;
    box-shadow: 0px 2px 16px rgb(26 162 168 / 40%), 0px 2px 6px rgb(0 0 0 / 10%);
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Poppins',sans-serif;
    font-size: 16px;
    font-weight: 600;
    -webkit-letter-spacing: 0.25px;
    -moz-letter-spacing: 0.25px;
    -ms-letter-spacing: 0.25px;
    letter-spacing: 0.25px;
    line-height: 1.18;
    padding: 16px 32px;
    outline: 0;
    text-align: center;
    -webkit-text-decoration: none;
    text-decoration: none;
    text-transform: uppercase;
  }
  button[type="submit"]{
    background: #16B6C5;
    border: 2px;
    border-color: #16B6C5;
    border-radius: 6px;
    border-style: solid;
    margin-right: 20px;
    box-shadow: 0px 2px 16px rgb(26 162 168 / 40%), 0px 2px 6px rgb(0 0 0 / 10%);
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Poppins',sans-serif;
    font-size: min(14px,1vw);
    font-weight: 600;
    -webkit-letter-spacing: 0.25px;
    -moz-letter-spacing: 0.25px;
    -ms-letter-spacing: 0.25px;
    letter-spacing: 0.25px;
    line-height: 1.18;
    padding: 16px 32px;
    outline: 0;
    text-align: center;
    -webkit-text-decoration: none;
    text-decoration: none;
    text-transform: uppercase;
    width:95%;
  }
  
   input::file-selector-button {
    background: #16B6C5;
    border: 2px;
    border-color: #16B6C5;
    border-radius: 6px;
    border-style: solid;
    box-shadow: 0px 2px 16px rgb(26 162 168 / 40%), 0px 2px 6px rgb(0 0 0 / 10%);
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Poppins',sans-serif;
    font-size: max(14px, 1vw) !important;
    font-weight: 600;
    -webkit-letter-spa
   }

   input[type=file]::file-selector-button:hover {
    background-color: #16898e;
    border: 2px solid #00cec9;
  }
`;