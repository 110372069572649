import React, { Component } from "react";

import Swal from "sweetalert2";
import api from "../../services/api";

import Stepper from "react-stepper-horizontal";

export class Assinatura extends Component {
    constructor(props) {
        super(props);

        const { codigoCota, situacao } = this.props;

        this.state = {
            codigoCota: codigoCota,
            situacao: situacao,
            documentos: [],
            refazer: false,
            stepActive: null,
            error: "",
        };
    }

    async componentDidMount() {
        this.obtemDocumentos(this.state.codigoCota);
    }

    async obtemDocumentos(codigo) {
        try {
            const response = await api.post(
                "api/concash/1.0/consorcio-grupo-cota/documentos",
                { codigoCota: codigo }
            );

            if (response.data.length > 0) {
                if (response.data[0].cota.situacao === "DOCUMENTACAO") {
                    this.setState({ refazer: true });
                }
            }

            var status = 0;
            var docs = response.data;
            for (var i = 0; i < docs.length; i++) {
                if (docs[i].tipo === "TERMO_CESSAO" && status < 2) {
                    status = 1;
                }

                if (docs[i].tipo === "COMPRA_VENDA") {
                    status = 2;
                }
            }

            if (this.state.situacao !== "DOCUMENTACAO" && status === 0) {
                status = null;
            }

            this.setState({ documentos: response.data, activeStep: status });
        } catch (err) {
            this.setState({
                error: "Houve um problema para obter os documentos.",
            });
        }
    }

    handleFormOpenDocumento = async (e, documento) => {
        e.preventDefault();
        try {
            Swal.fire({
                title: "Aguarde ...",
                onBeforeOpen: () => {
                    Swal.showLoading();
                },
            });

            const response = await api.post(
                "api/concash/1.0/consorcio-grupo-cota/download",
                {
                    extrato: documento,
                },
                { responseType: "blob" }
            );

            const file = new Blob([response.data], { type: "application/pdf" });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;

            Swal.close();
        } catch (err) {
            this.setState({
                error: "Houve um problema com o processamento da requisição.",
            });
        }
    };

    handleFormOpenZIP = async (e, documento) => {
        e.preventDefault();
        try {
            Swal.fire({
                title: "Aguarde ...",
                onBeforeOpen: () => {
                    Swal.showLoading();
                },
            });

            const response = await api.post(
                "api/concash/1.0/consorcio-grupo-cota/download-zip",
                {
                    extrato: documento,
                },
                { responseType: "blob" }
            );

            const file = new Blob([response.data], { type: "application/zip" });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;

            Swal.close();
        } catch (err) {
            this.setState({
                error: "Houve um problema com o processamento da requisição.",
            });
        }
    };

    handleRefazerDocumentacao = async (e) => {
        e.preventDefault();
        const { codigoCota } = this.state;
        if (!codigoCota) {
            Swal.fire("Código cota desconhecido!");
        } else {
            try {
                await api.put(
                    "api/concash/1.0/consorcio-grupo-cota-assinatura/refazer",
                    {
                        codigo: codigoCota,
                    }
                );
                this.obtemDocumentos(codigoCota);
                Swal.fire("Documentos cancelados com sucesso!");
            } catch (err) {
                Swal.fire("Ocorreu um erro no processo!");
            }
        }
    };

    render() {
        return (
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Documentos</h4>
                            <p className="card-description"></p>

                            <form className="forms-sample"></form>

                            <Stepper
                                steps={[
                                    { title: "Documentação" },
                                    { title: "Termo de Cessão" },
                                    { title: "Compra e Venda" },
                                ]}
                                activeStep={this.state.activeStep}
                            />

                            <div className="table-responsive">
                                <table
                                    className="table table-striped"
                                    style={{ marginTop: "50px" }}
                                >
                                    <thead>
                                        <tr>
                                            <th> Tipo </th>
                                            <th> Status </th>
                                            <th className="text-center">
                                                {" "}
                                                Documento{" "}
                                            </th>
                                            <th className="text-center">
                                                {" "}
                                                Assinatura{" "}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.documentos.map(
                                            (documento, index) => (
                                                <tr key={index}>
                                                    <td> {documento.tipo} </td>
                                                    <td>
                                                        {" "}
                                                        {documento.action}{" "}
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="text-right">
                                                            <button
                                                                type="submit"
                                                                onClick={(e) =>
                                                                    this.handleFormOpenDocumento(
                                                                        e,
                                                                        documento.fileUrl
                                                                    )
                                                                }
                                                                className="btn btn-primary mr-2"
                                                            >
                                                                Visualizar
                                                                Documento
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        {!!documento.apiDownload && (
                                                            <div className="text-right">
                                                                <button
                                                                    type="submit"
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        this.handleFormOpenZIP(
                                                                            e,
                                                                            documento.apiDownload
                                                                        )
                                                                    }
                                                                    className="btn btn-primary mr-2"
                                                                >
                                                                    Visualizar
                                                                    Assinatura
                                                                </button>
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>

                                    {this.state.refazer && (
                                        <tfoot>
                                            <tr>
                                                <td className="text-center">
                                                    <button
                                                        type="button"
                                                        onClick={(e) =>
                                                            this.handleRefazerDocumentacao(
                                                                e
                                                            )
                                                        }
                                                        className="btn btn-primary mr-2"
                                                    >
                                                        Refazer Assinaturas
                                                    </button>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    )}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Assinatura;
