import React, { Component } from "react";
import { Form } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import FloatingLabelInput from "react-floating-label-input";
import moment from "moment";
import api from "../../services/api";
import SimuladorSimplificado from "../../components/SimuladorSimplificado";
import Proposta from "../../components/Proposta";
import Assinatura from "../../components/Assinatura";
import Inlira from "../../components/Inlira";
import Pagamento from "../../components/Pagamento";
import Extrato from "../../components/Extrato";
import {
  cellphoneFormater,
  currencyFormater,
  numberFormater,
} from "../../lib/formaters";
import IntlCurrencyInput from "react-intl-currency-input";
import InputFloat from "react-floating-input";
import ptBR from "date-fns/locale/pt-BR";
import Swal from "sweetalert2";
import { Link, withRouter } from "react-router-dom";
import { cpfCnpj } from "../../mask/cpfcnpj";
import { Card, Content, Header, Illustration } from "./styles";
import {
  Autocomplete,
  Backdrop,
  Checkbox,
  CircularProgress,
  InputLabel,
  TextField,
} from "@mui/material";

registerLocale("pt-BR", ptBR);

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export class UploadAnaliseForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      extrato: "",
      error: "",
      categoria: "",
      administradora: "",
      administradoras: [],
      open: true,
      status: "",
      situacao: "",
      isMobile: false,
      tipos: [],
    };
  }

  async componentDidMount() {
    this.setState({ isMobile: window.innerWidth <= 800 });

    await this.obtemTipos();
    await this.obtemAdministradoras();
    this.setState({ open: false });
  }

  async obtemTipos() {
    try {
      const response = await api.post(
        "api/concash/1.0/cliente-area/tipos-consorcio",
        {}
      );
      var newResp = [];
      response.data.map(function (item) {
        newResp.push({ id: item.ctcId, label: item.ctcDescricao });
      });
      this.setState({ tipos: newResp });
    } catch (err) {
      this.setState({
        error: err.response.data,
      });
    }
  }

  async obtemAdministradoras() {
    try {
      const response = await api.post(
        "api/concash/1.0/cliente-area/administradoras",
        {}
      );
      var newResp = [];
      response.data.map(function (item) {
        newResp.push({ id: item.sadId, label: item.sadNome });
      });
      this.setState({ administradoras: newResp });
    } catch (err) {
      this.setState({
        error: err.response.data,
      });
    }
  }

  handleExtrato = function (arquivo) {
    console.log(arquivo);
    console.log(arquivo.name);
    let arqParts = String(arquivo.name).split(".");
    console.log(arqParts[arqParts.length - 1]);
    if (arqParts[arqParts.length - 1] != "pdf") {
      alert("selecione um arquivo PDF");
    } else {
      this.setState({ extrato: arquivo });
    }
  };

  handleForm = async (e) => {
    e.preventDefault();

    const { extrato, administradora, categoria, status, situacao } = this.state;
    console.log(this.state);
    let erroFormato = false;
    if (extrato) {
      let arqParts = String(extrato.name).split(".");
      console.log(arqParts[arqParts.length - 1]);
      if (arqParts[arqParts.length - 1] != "pdf") {
        erroFormato = true;
      }
    }
    if (erroFormato) {
      Swal.fire({
        icon: "error",
        title: "Atenção",
        text: "Selecione o arquivo em PDF para continuar!",
      });
    } else if (
      !extrato ||
      !administradora ||
      !categoria ||
      status === "" ||
      situacao === ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Atenção",
        text: "Informe a administradora, categoria, status, situação e arquivo no formato PDF para continuar!",
      });
    } else {
      this.setState({ open: true });

      try {
        const formData = new FormData();
        formData.append("file", extrato);
        formData.append("administradora", administradora);
        formData.append("tipoConsorcio", categoria);
        formData.append("statusCota", status);
        formData.append("situacaoCota", situacao);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };

        const response = await api.post(
          `/api/concash/1.0/cliente/importar-cota`,
          formData,
          config
        );

        if (response.status === 200) {
          Swal.fire("Operação realizada com sucesso!");
          this.setState({
            extrato: "",
            administradora: "",
            telefone: "",
            categoria: "",
            status: "",
            situacao: "",
            open: false,
          });
          window.location.reload();
        } else {
          this.setState({ open: false });
          Swal.fire(response.data);
        }
      } catch (err) {
        this.setState({ open: false });
        Swal.fire(err.response.data);
      }
    }
  };

  statusOptions = [
    { label: "Cancelada", id: 0 },
    { label: "Ativa", id: 1 },
    { label: "Em Atraso", id: 2 },
  ];

  situacaoOptions = [
    { label: "Contemplada", id: 1 },
    { label: "Não Contemplada", id: 0 },
  ];

  render() {
    const const_cliente = this.state.cliente;

    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> {this.state.labelForm} </h3>
        </div>

        <div className="row">
          {this.state.isMobile ? (
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <p className="card-description"></p>
                  <Header>
                    <h4 className="card-title">Nova proposta</h4>
                  </Header>
                  <Card>
                    <p className="card-description"></p>
                    {this.state.error && (
                      <p className="card-description">{this.state.error}</p>
                    )}
                    <form className="forms-sample" onSubmit={this.handleForm}>
                      <div className="col-sm-12">
                        <Backdrop
                          sx={{
                            color: "#16B6C5",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                          }}
                          open={this.state.open}
                        >
                          <CircularProgress color="inherit" />
                        </Backdrop>
                        <div className="row-mobile">
                          <Autocomplete
                            className="input-flex"
                            disablePortal
                            id="categoria"
                            options={this.state.administradoras}
                            onChange={(e, v) =>
                              this.setState({ administradora: v ? v.id : null })
                            }
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField {...params} label="Administradora" />
                            )}
                          />
                        </div>
                        <div className="row-mobile">
                          <Autocomplete
                            className="input-flex"
                            disablePortal
                            id="categoria"
                            options={this.state.tipos}
                            onChange={(e, v) =>
                              this.setState({ categoria: v ? v.id : null })
                            }
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField {...params} label="Categoria" />
                            )}
                          />
                        </div>
                        <div className="row-mobile">
                          <Autocomplete
                            className="input-flex"
                            disablePortal
                            id="status"
                            options={this.statusOptions}
                            onChange={(e, v) =>
                              this.setState({ status: v ? v.id : null })
                            }
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField {...params} label="Status" />
                            )}
                          />
                        </div>
                        <div className="row-mobile">
                          <Autocomplete
                            className="input-flex"
                            disablePortal
                            id="situacao"
                            options={this.situacaoOptions}
                            onChange={(e, v) =>
                              this.setState({ situacao: v ? v.id : null })
                            }
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField {...params} label="Situação" />
                            )}
                          />
                        </div>
                        <div className="row-mobile">
                          <InputLabel style={{ marginLeft: 5, marginTop: 10 }}>
                            Extrato
                          </InputLabel>
                        </div>
                        <div className="row-mobile">
                          <TextField
                            type="file"
                            className="input-form-miu"
                            id="extrato"
                            label="  "
                            accept=".pdf"
                            onChange={(e) =>
                              this.setState({ extrato: e.target.files[0] })
                            }
                            variant="outlined"
                          />
                        </div>

                        <div className="row">
                          <div
                            className="col-sm-12 col"
                            style={{ marginTop: "15px" }}
                          >
                            <div className="text-right">
                              <button
                                type="submit"
                                className="btn btn-primary mr-6"
                              >
                                ENVIAR
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </Card>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <p className="card-description"></p>
                  <Header>
                    <h4 className="card-title">Nova proposta</h4>
                  </Header>
                  <Card>
                    <p className="card-description"></p>
                    {this.state.error && (
                      <p className="card-description">{this.state.error}</p>
                    )}
                    <form className="forms-sample" onSubmit={this.handleForm}>
                      <div className="col-sm-12">
                        <Backdrop
                          sx={{
                            color: "#16B6C5",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                          }}
                          open={this.state.open}
                        >
                          <CircularProgress color="inherit" />
                        </Backdrop>
                        <div className="row">
                          <Autocomplete
                            className="box-form-input-miu"
                            disablePortal
                            id="categoria"
                            options={this.state.administradoras}
                            onChange={(e, v) =>
                              this.setState({ administradora: v ? v.id : null })
                            }
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField {...params} label="Administradora" />
                            )}
                          />
                          <Autocomplete
                            className="box-form-input-miu"
                            disablePortal
                            id="categoria"
                            options={this.state.tipos}
                            onChange={(e, v) =>
                              this.setState({ categoria: v ? v.id : null })
                            }
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField {...params} label="Categoria" />
                            )}
                          />
                        </div>
                        <div className="row" style={{ marginTop: "15px" }}>
                          <Autocomplete
                            className="box-form-input-miu"
                            disablePortal
                            id="status"
                            options={this.statusOptions}
                            onChange={(e, v) =>
                              this.setState({ status: v ? v.id : null })
                            }
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField {...params} label="Status" />
                            )}
                          />
                          <Autocomplete
                            className="box-form-input-miu"
                            disablePortal
                            id="situacao"
                            options={this.situacaoOptions}
                            onChange={(e, v) =>
                              this.setState({ situacao: v ? v.id : null })
                            }
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField {...params} label="Situação" />
                            )}
                          />
                        </div>

                        <div className="row" style={{ marginTop: "15px" }}>
                          <div
                            className="col-sm-6"
                            style={{ paddingLeft: "0px", paddingRight: "0px" }}
                          >
                            <div className="box-form-input-miu">
                              <TextField
                                type="file"
                                className="input-form-miu"
                                id="extrato"
                                label="  "
                                accept=".pdf"
                                onChange={(e) =>
                                  this.setState({ extrato: e.target.files[0] })
                                }
                                variant="outlined"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="col-sm-12 col"
                            style={{ marginTop: "15px" }}
                          >
                            <div className="text-right">
                              <button
                                type="submit"
                                className="btn btn-primary mr-6"
                              >
                                ENVIAR
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </Card>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(UploadAnaliseForm);
