import styled from "styled-components";

export const NewSideBar = ({ children }) => {
  return (
    <>
      <NewS>{children}</NewS>
    </>
  );
};

const NewS = styled.div`
  display: flex;
  padding: 30px 35px;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  margin-left: 83px;
  margin-top: 35px;
  min-width: 270px;
  max-width: 270px;
  height: 665px;
  background: transparent linear-gradient(0deg, #7fdec1 0%, #06878d 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 20px;
  gap: 25px;
  opacity: 1;
`;

export const NewItem = ({ icon, title, link }) => {
  const NewI = styled.a`
    display: flex;
    width: 100%;
    height: 30px;
    text-decoration: none !important;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  `;

  const NewText = styled.span`
        font-family: Roboto,
        font-size: 15px;
        font-weight: bold;
        color: #FFF;
    opacity: 1;
        &:hover {
            opacity: 0.7; 
        }
    `;

  return (
    <>
      <div style={{ textDecoration: "none !important" }}>
        <NewI href={link}>
          <div style={{ color: "FFF !important" }}>{icon}</div>
          <NewText>{title}</NewText>
        </NewI>
      </div>
    </>
  );
};

export const NewItemMobile = ({ icon, title, link }) => {
  const NewI = styled.a`
    display: flex;
    width: 100%;
    min-height: 45px;
    text-decoration: none !important;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 12px 30px;
    color: #05898f;
  `;

  const NewText = styled.span`
        font-family: Roboto,
        font-size: 15px;
        font-weight: bold;
        color: #05898F;
    opacity: 1;
        &:hover {
            opacity: 0.7; 
        }
    `;

  return (
    <>
      <div style={{ textDecoration: "none !important" }}>
        <NewI href={link}>
          <div style={{ color: "FFF !important" }}>{icon}</div>
          <NewText>{title}</NewText>
        </NewI>
      </div>
    </>
  );
};
