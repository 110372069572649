import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";
import { AlteraNome } from '../../actions';




export class TelaProdutoInicial extends Component {


  constructor(props){
        super(props);
        this.state = {
          show: false
        };
  }

  async componentDidMount() {

  }

  handleReanalise    = async e => {
      e.preventDefault();
      this.props.history.push("/produtos/reanalise");
  };

  handleSendDemanda    = async e => {
      e.preventDefault();
      this.props.history.push("/financeiro/demanda");
  };

  handleInclusaoValores = async e => {
      e.preventDefault();
      this.props.history.push("/financeiro/inclusao-valores");
  };


  render() {


    return (

      <div>
        <div className="page-header">
          <h3 className="page-title"> Produtos </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row" style={{"marginBottom":"50px"}}>

                    <div className="col-md-4" style={{"text-align":"center"}}>
                       <button type="submit" className="btn btn-primary mr-2" style={{"width":"80%","height":"100px"}} onClick={e =>this.handleReanalise(e)}>MESA DE REANÁLISE</button>
                    </div>

                    <div className="col-md-4" style={{"text-align":"center"}}>
                       <button type="submit" className="btn btn-primary mr-2" style={{"width":"80%","height":"100px"}} onClick={e =>this.handleInclusaoValores(e)}>RELATÓRICO DE PARCELAS</button>
                    </div>

                    <div className="col-md-4" style={{"text-align":"center"}}>
                       <button type="submit" className="btn btn-primary mr-2" style={{"width":"80%","height":"100px"}} onClick={e =>this.handleInclusaoValores(e)}>BACEN</button>
                    </div>

                </div>

                <div className="row">

                    <div className="col-md-4" style={{"text-align":"center"}}>
                       <button type="submit" className="btn btn-primary mr-2" style={{"width":"80%","height":"100px"}} onClick={e =>this.handleSendDemanda(e)}>RELATÓRIO DE LANCES</button>
                    </div>

                    <div className="col-md-4" style={{"text-align":"center"}}>
                       <button type="submit" className="btn btn-primary mr-2" style={{"width":"80%","height":"100px"}} onClick={e =>this.handleInclusaoValores(e)}>ÍNDICES</button>
                    </div>

                    <div className="col-md-4" style={{"text-align":"center"}}>
                    </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(TelaProdutoInicial);
