import React from 'react';
import S from "./Base.module.css";
import { IoMdInformationCircle } from "react-icons/io";


export const InfoDefault = ({message}) => {
  return (
    <div className={S.infoMessageBox}>
      <div>
      <IoMdInformationCircle size={24} color={"#EFA71F"} />
      </div>
      {message}
    </div>
  );
}
