import React, { Component } from "react";
import api from "../../services/api";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
// import CotaInfo from "../../components/CotaInfo";
import PagamentoInfo from "../../components/PagamentoInfo";
import DespesaInfo from "../../components/DespesaInfo";
import ParcelaInfo from "../../components/ParcelaInfo";
import LanceInfo from "../../components/LanceInfo";
import CabecalhoCota from "../../components/CabecalhoCota";

const BoxInformativo = styled.div`
  text-align: center;
  color: white;
  padding: 10px;
  background: #2196f3;
  width: 150px;
  height: 40px;
  margin-top: 20px;
  border-radius: 10px 10px 0px 0px;
  cursor: pointer;

  :hover {
    background: #16898e;
  }
`;

export class FinanceiroDetalhamentoOrdemPagamento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      cota: [],
      codigoDespesa: "",
      codigoPagamento: "",
      codigoLance: "",
      codigoParcela: "",
      codigoProcesso: "",
      cliente: [],
      grupo: [],
      administradora: [],
      blocoCota: false,
      blocoPagamento: false,
      backgroundCota: "#ccc",
      backgroundPagamento: "#2196f3",
    };
  }

  async componentDidMount() {
    if (this.props[0].location.state !== undefined) {
      const codigo = this.props[0].location.state.codigo;
      const codigoDespesa = this.props[0].location.state.despesa;
      const codigoPagamento = this.props[0].location.state.pagamento;
      const codigoProcesso = this.props[0].location.state.processo;
      const codigoLance = this.props[0].location.state.lance;
      const codigoParcela = this.props[0].location.state.parcela;
      this.setState({
        codigoDespesa: codigoDespesa,
        codigoPagamento: codigoPagamento,
        codigoProcesso: codigoProcesso,
        codigoParcela: codigoParcela,
        codigoLance: codigoLance,
      });
      this.obtemCota(codigo);
    }
  }

  async obtemCota(codigo) {
    try {
      const response = await api.post(
        "api/concash/1.0/consorcio-grupo-cota/detalhe",
        { codigo: codigo }
      );
      this.setState({
        cota: response.data,
        blocoCota: true,
        cliente: response.data.cliente,
        grupo: response.data.grupo,
        administradora: response.data.grupo.consorcio.administradora,
      });
    } catch (err) {
      this.setState({
        error: "Houve um problema para obter a cota.",
      });
    }
  }

  alteraAbas = (aba) => {
    const abas = ["Cota", "Pagamento"];

    var tmp_cota = false;
    var tmp_pagamento = false;
    var bck_cota = "#2196f3";
    var bck_pagamento = "#2196f3";

    for (var i = 0; i < abas.length; i++) {
      if (aba === "Cota") {
        tmp_cota = true;
        bck_cota = "#ccc";
      }
      if (aba === "Pagamentos") {
        tmp_pagamento = true;
        bck_pagamento = "#ccc";
      }
    }

    this.setState({ blocoCota: tmp_cota, blocoPagamento: tmp_pagamento });
    this.setState({
      backgroundCota: bck_cota,
      backgroundPagamento: bck_pagamento,
    });
  };

  render() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">
            {" "}
            Ordem de Pagamento - Cota : ({this.state.cota.contrato}){" "}
          </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb"></ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                {this.state.cliente.cpf && (
                  <CabecalhoCota
                    cliente={this.state.cliente}
                    cota={this.state.cota}
                    grupo={this.state.grupo}
                    administradora={this.state.administradora}
                  />
                )}

                <div className="row">
                  <BoxInformativo
                    className="col-md-1"
                    style={{ background: this.state.backgroundCota }}
                    onClick={(e) => this.alteraAbas("Cota")}
                  >
                    Cota
                  </BoxInformativo>

                  <BoxInformativo
                    className="col-md-2"
                    style={{ background: this.state.backgroundPagamento }}
                    onClick={(e) => this.alteraAbas("Pagamentos")}
                  >
                    Pagamento
                  </BoxInformativo>
                </div>

                {this.state.blocoCota && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        background: "#ccc",
                        minHeight: "300px",
                        padding: "20px",
                      }}
                    >
                      {/* <CotaInfo cota={this.state.cota} administradora={this.state.administradora} grupo={this.state.grupo} /> */}
                    </div>
                  </div>
                )}

                {this.state.blocoPagamento && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        background: "#ccc",
                        minHeight: "300px",
                        padding: "20px",
                      }}
                    >
                      {this.state.codigoDespesa != "" && (
                        <DespesaInfo
                          codigoDespesaCota={this.state.codigoDespesa}
                          mostraAcao={true}
                          codigoProcesso={this.state.codigoProcesso}
                        />
                      )}

                      {this.state.codigoPagamento != "" && (
                        <PagamentoInfo
                          codigoPagamento={this.state.codigoPagamento}
                          mostraAcao={true}
                          codigoProcesso={this.state.codigoProcesso}
                        />
                      )}

                      {this.state.codigoLance != "" && (
                        <LanceInfo
                          codigoLance={this.state.codigoLance}
                          mostraAcao={true}
                          codigoProcesso={this.state.codigoProcesso}
                        />
                      )}

                      {this.state.codigoParcela != "" && (
                        <ParcelaInfo
                          codigoParcela={this.state.codigoParcela}
                          mostraAcao={true}
                          codigoProcesso={this.state.codigoProcesso}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(FinanceiroDetalhamentoOrdemPagamento);
