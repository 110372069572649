import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";



export class FormFuncionalidade extends Component {



  constructor(props){

    super(props);

    this.state = {
          funcionalidade: '',
          url: '',
          menu: '',
          codigo: '',
          grupo: '',
          labelForm: '',
          labelButton: '',
          grupoFuncionalidade: [],
          error: "",
          action: ""
    };
  }


  async componentDidMount() {

    if(this.props[0].location.state !== undefined){
        const func  = this.props[0].location.state.funcionalidade
        this.setState({funcionalidade: func.funcionalidade,codigo: func.codigo,menu: func.menu,url: func.url,grupo: func.grupoFuncionalidade.codigo});
        this.setState({labelForm: "Editar Funcionalidade",labelButton: "Editar",action: "editar"});
    }else{
        this.setState({labelForm: "Adicionar Funcionalidade",labelButton: "Adicionar",action: "adicionar"});
    }

    try {
          const response = await api.post("api/sistema/1.0/grupo-funcionalidade/listar", {});
          this.setState({ grupoFuncionalidade: response.data});
    } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter os grupos de funcionalidade."
          });
    }
  }

  handleForm = async e => {
    e.preventDefault();
    const { funcionalidade,
            url,
            grupo,
            menu,
            codigo,
            action} = this.state;
    if (!funcionalidade
        || !url
        || !menu
        || !grupo
        || !action) {
      this.setState({ error: "Preencha os campos obrigatórios para continuar!" });
    } else {
      try {

        if(action == "editar"){
          const response = await api.put("api/sistema/1.0/funcionalidade/"+action, {
              url,funcionalidade,grupo,codigo,menu
            });
          this.setState({ error: "Edição realizada com sucesso!" });
        }else{
          const response = await api.post("api/sistema/1.0/funcionalidade/"+action, {
              url,funcionalidade,grupo,menu
            });
          this.props.history.push("/funcionalidade");
        }

      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o processamento da requisição."
        });
      }
    }
  };

  render() {


    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> {this.state.labelForm} </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>

        <div className="row">

          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"></h4>
                {this.state.error && <p className="card-description">{this.state.error}</p>}
                <form className="forms-sample" onSubmit={this.handleForm}>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group className="row" >
                      <label className="col-sm-5 col-form-label">Grupo Funcionalidade</label>
                      <div className="col-sm-7">
                        <select className="form-control" value={this.state.grupo} onChange={e => this.setState({ grupo: e.target.value })}>
                            <option value="" key={0}>Selecione</option>
                          {this.state.grupoFuncionalidade.map((gp,index) => (
                            <option value={gp.codigo} key={index}>{gp.grupo}</option>
                          ))}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Funcionalidade</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="funcionalidade" value={this.state.funcionalidade} onChange={e => this.setState({ funcionalidade: e.target.value })} placeholder="Funcionalidade" size="sm" />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">URL</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="url" value={this.state.url} placeholder="URL" size="sm" onChange={e => this.setState({ url: e.target.value })} />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="row" >
                        <label className="col-sm-5 col-form-label">Menu?</label>
                        <div className="col-sm-7">
                          <select className="form-control" value={this.state.menu} onChange={e => this.setState({ menu: e.target.value })}>
                              <option value="S" >Sim</option>
                              <option value="N" >Não</option>
                          </select>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="text-right">
                    <button type="submit" className="btn btn-primary mr-2">{this.state.labelButton}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
         </div>
       </div>
    )
  }
}

export default withRouter(FormFuncionalidade);
