import styled from "styled-components";
import { v3Colors } from "../../v3/colors";

export const V3Text5 = styled.span`
  text-align: left;
  font: normal normal normal 15px Roboto;
  letter-spacing: 0px;
  color: #898989;
  opacity: 1;
`;
export const V3TextH1 = styled.h1`
  text-align: left;
  font: normal normal bold 25px/23px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`;
export const V3Title = styled.h1`
  text-align: left;
  font: normal normal 30px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`;

export const V3TextFooter = styled.span`
  text-align: left;
  font: italic normal normal 15px Roboto;
  letter-spacing: 0px;
  color: #898989;
  opacity: 0.5;
`;

export const V3TextInputInfo = styled.span`
  text-align: left;
  font: italic normal normal 15px/30px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`;

export const V3PropostaText = styled.h1`
  text-align: left;
  font: bold 48px Roboto;
  font-weight: 700;
  letter-spacing: 0px;
  color: ${v3Colors.primary};
  opacity: 1;
`;

export const V3Link = styled.a`
  text-align: left;
  font: normal normal normal 15px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
`;
