import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import api from "../../services/api";
import { Link } from "react-router-dom";
import SweetAlert from 'sweetalert2-react';
import Swal from 'sweetalert2'
import Pagination from "../../components/Pagination";



export class FormConfirmacaoPagamento extends Component {



  constructor(){
        super();
        this.state = {
          cotas: [],
          contrato:'',
          currentPage:1,
          totalRecords:null,
          show: false
        };
  }

  async componentDidMount() {

  }

  async obtemListaCotas(){

      try {

        const {
          currentPage,
          contrato
        } = this.state;

        const response = await api.post("api/concash/1.0/consorcio-grupo-cota/listar", { pagina: currentPage, contrato: contrato });
        this.setState({ totalRecords: response.data.totalElements,cotas: response.data.content});
      } catch (err) {
        this.setState({
          error:
            "Houve um problema para obter a lista de cotas."
        });
      }

  }



  render() {

    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Demanda </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">

              <div className="row" style={{backgroundColor:'rgb(222, 226, 230)'}}>

                <div className="col-md-12" style={{marginTop:'30px'}}>
                    <div className="row">
                      <div className="col-md-3">
                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>CPF/CNPJ Cliente​</label>
                          </Form.Group>
                      </div>
                      <div className="col-md-3">
                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Nome Consorciado</label>
                          </Form.Group>
                      </div>
                      <div className="col-md-2">
                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Administradora</label>
                          </Form.Group>
                      </div>
                      <div className="col-md-2">
                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Grupo</label>
                          </Form.Group>
                      </div>
                      <div className="col-md-2">
                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Cota</label>
                          </Form.Group>
                      </div>
                    </div>

                    <div className="row" style={{marginTop:'-20px'}}>
                          <div className="col-md-3">
                              <Form.Group className="row">
                                <div className="col-sm-12"  style={{textAlign:'center'}}>
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="cpf" value={this.state.cpf} onChange={e => this.setState({ cpf: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                          <div className="col-md-3">
                              <Form.Group className="row">
                                <div className="col-sm-12" style={{textAlign:'center'}}>
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="clienteNome" value={this.state.clienteNome} onChange={e => this.setState({ clienteNome: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                          <div className="col-md-2">
                              <Form.Group className="row">
                                <div className="col-sm-12"  style={{textAlign:'center'}}>
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="administradoraNome" value={this.state.administradoraNome} onChange={e => this.setState({ administradoraNome: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                          <div className="col-md-2">
                              <Form.Group className="row">
                                <div className="col-sm-12"  style={{textAlign:'center'}}>
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="grupo" value={this.state.grupo} onChange={e => this.setState({ grupo: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                          <div className="col-md-2">
                              <Form.Group className="row">
                                <div className="col-sm-12"  style={{textAlign:'center'}}>
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="cota" value={this.state.cota} onChange={e => this.setState({ cota: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                              <Form.Group className="row">
                                <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>CPF/CNPJ Consorciado​</label>
                              </Form.Group>
                          </div>
                          <div className="col-md-3">
                              <Form.Group className="row">
                                <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Cessionário</label>
                              </Form.Group>
                          </div>
                          <div className="col-md-2">
                          </div>
                          <div className="col-md-2">
                          </div>
                          <div className="col-md-2">
                          </div>
                        </div>

                        <div className="row" style={{marginTop:'-20px'}}>
                              <div className="col-md-3">
                                  <Form.Group className="row">
                                    <div className="col-sm-12"  style={{textAlign:'center'}}>
                                      <Form.Control type="text" style={{height:'30px'}} className="form-control" id="cnpjConsorciado" value={this.state.cnpjConsorciado} onChange={e => this.setState({ cnpjConsorciado: e.target.value })} />
                                    </div>
                                  </Form.Group>
                              </div>
                              <div className="col-md-3">
                                  <Form.Group className="row">
                                    <div className="col-sm-12" style={{textAlign:'center'}}>
                                      <Form.Control type="text" style={{height:'30px'}} className="form-control" id="consorcioNome" value={this.state.consorcioNome} onChange={e => this.setState({ consorcioNome: e.target.value })} />
                                    </div>
                                  </Form.Group>
                              </div>
                              <div className="col-md-2">
                              </div>
                              <div className="col-md-2">
                              </div>
                              <div className="col-md-2">
                              </div>
                        </div>
                        <div className="row">
                          <div className="col-md-2">
                              <Form.Group className="row">
                                <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Movimento</label>
                              </Form.Group>
                          </div>
                          <div className="col-md-2">
                              <Form.Group className="row">
                                <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Pagador</label>
                              </Form.Group>
                          </div>
                          <div className="col-md-2">
                              <Form.Group className="row">
                                <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Forma PGTO</label>
                              </Form.Group>
                          </div>
                          <div className="col-md-1">
                              <Form.Group className="row">
                                <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Cód BCO</label>
                              </Form.Group>
                          </div>
                          <div className="col-md-2">
                              <Form.Group className="row">
                                <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Banco</label>
                              </Form.Group>
                          </div>
                          <div className="col-md-2">
                              <Form.Group className="row">
                                <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Agência</label>
                              </Form.Group>
                          </div>
                          <div className="col-md-1">
                              <Form.Group className="row">
                                <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Conta</label>
                              </Form.Group>
                          </div>
                        </div>

                        <div className="row" style={{marginTop:'-20px'}}>
                              <div className="col-md-2">
                                  <Form.Group className="row">
                                    <div className="col-sm-12"  style={{textAlign:'center'}}>
                                      <Form.Control type="text" style={{height:'30px'}} className="form-control" id="movimento" value={this.state.movimento} onChange={e => this.setState({ movimento: e.target.value })} />
                                    </div>
                                  </Form.Group>
                              </div>
                              <div className="col-md-2">
                                  <Form.Group className="row">
                                    <div className="col-sm-12" style={{textAlign:'center'}}>
                                      <Form.Control type="text" style={{height:'30px'}} className="form-control" id="pagador" value={this.state.pagador} onChange={e => this.setState({ pagador: e.target.value })} />
                                    </div>
                                  </Form.Group>
                              </div>
                              <div className="col-md-2">
                                  <Form.Group className="row">
                                    <div className="col-sm-12"  style={{textAlign:'center'}}>
                                      <Form.Control type="text" style={{height:'30px'}} className="form-control" id="formaPagamento" value={this.state.formaPagamento} onChange={e => this.setState({ formaPagamento: e.target.value })} />
                                    </div>
                                  </Form.Group>
                              </div>
                              <div className="col-md-1">
                                  <Form.Group className="row">
                                    <div className="col-sm-12"  style={{textAlign:'center'}}>
                                      <Form.Control type="text" style={{height:'30px'}} className="form-control" id="codigoBanco" value={this.state.codigoBanco} onChange={e => this.setState({ codigoBanco: e.target.value })} />
                                    </div>
                                  </Form.Group>
                              </div>
                              <div className="col-md-2">
                                  <Form.Group className="row">
                                    <div className="col-sm-12"  style={{textAlign:'center'}}>
                                      <Form.Control type="text" style={{height:'30px'}} className="form-control" id="banco" value={this.state.banco} onChange={e => this.setState({ banco: e.target.value })} />
                                    </div>
                                  </Form.Group>
                              </div>
                              <div className="col-md-2">
                                  <Form.Group className="row">
                                    <div className="col-sm-12"  style={{textAlign:'center'}}>
                                      <Form.Control type="text" style={{height:'30px'}} className="form-control" id="agencia" value={this.state.agencia} onChange={e => this.setState({ agencia: e.target.value })} />
                                    </div>
                                  </Form.Group>
                              </div>
                              <div className="col-md-1">
                                  <Form.Group className="row">
                                    <div className="col-sm-12"  style={{textAlign:'center'}}>
                                      <Form.Control type="text" style={{height:'30px'}} className="form-control" id="conta" value={this.state.conta} onChange={e => this.setState({ conta: e.target.value })} />
                                    </div>
                                  </Form.Group>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-2">
                                  <Form.Group className="row">
                                    <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>CPF/CNPJ Beneficiário</label>
                                  </Form.Group>
                              </div>
                              <div className="col-md-2">
                                  <Form.Group className="row">
                                    <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Nome Beneficiário</label>
                                  </Form.Group>
                              </div>
                              <div className="col-md-2">
                                  <Form.Group className="row">
                                    <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Valor R$</label>
                                  </Form.Group>
                              </div>
                              <div className="col-md-2">
                                  <Form.Group className="row">
                                    <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Vencim.</label>
                                  </Form.Group>
                              </div>
                              <div className="col-md-2">
                                  <Form.Group className="row">
                                    <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Dt de Pgto.</label>
                                  </Form.Group>
                              </div>
                            </div>


                            <div className="row" style={{marginTop:'-20px'}}>
                                  <div className="col-md-2">
                                      <Form.Group className="row">
                                        <div className="col-sm-12"  style={{textAlign:'center'}}>
                                          <Form.Control type="text" style={{height:'30px'}} className="form-control" id="movimento" value={this.state.movimento} onChange={e => this.setState({ movimento: e.target.value })} />
                                        </div>
                                      </Form.Group>
                                  </div>
                                  <div className="col-md-2">
                                      <Form.Group className="row">
                                        <div className="col-sm-12" style={{textAlign:'center'}}>
                                          <Form.Control type="text" style={{height:'30px'}} className="form-control" id="pagador" value={this.state.pagador} onChange={e => this.setState({ pagador: e.target.value })} />
                                        </div>
                                      </Form.Group>
                                  </div>
                                  <div className="col-md-2">
                                      <Form.Group className="row">
                                        <div className="col-sm-12"  style={{textAlign:'center'}}>
                                          <Form.Control type="text" style={{height:'30px'}} className="form-control" id="formaPagamento" value={this.state.formaPagamento} onChange={e => this.setState({ formaPagamento: e.target.value })} />
                                        </div>
                                      </Form.Group>
                                  </div>
                                  <div className="col-md-2">
                                      <Form.Group className="row">
                                        <div className="col-sm-12"  style={{textAlign:'center'}}>
                                          <Form.Control type="text" style={{height:'30px'}} className="form-control" id="banco" value={this.state.banco} onChange={e => this.setState({ banco: e.target.value })} />
                                        </div>
                                      </Form.Group>
                                  </div>
                                  <div className="col-md-2">
                                      <Form.Group className="row">
                                        <div className="col-sm-12"  style={{textAlign:'center'}}>
                                          <Form.Control type="text" style={{height:'30px'}} className="form-control" id="agencia" value={this.state.agencia} onChange={e => this.setState({ agencia: e.target.value })} />
                                        </div>
                                      </Form.Group>
                                  </div>
                            </div>

                            <div className="row" style={{marginTop:'20px',marginBottom:'20px'}}>
                                  <div className="col-md-4">
                                      <button type="submit" className="btn btn-primary mr-2" onClick={e =>this.handleNome(e)} style={{width:'100%'}}>Enviar</button>
                                  </div>
                                  <div className="col-md-8">
                                  </div>
                            </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FormConfirmacaoPagamento
