import React from "react";
import S from "./Base.module.css";
import Cell from "../Cell";
import { useSelector } from "react-redux";
import { TbFileDescription } from "react-icons/tb";
import { MdOutlinePersonOutline, MdLogout } from "react-icons/md";

import useAutoservico from "../../../../store/autoservico/useAutoservico";

export const SidebarDesktop = ({ estilo }) => {
  const service = window.location.pathname
    .replace("/autoservico/", "")
    .split("/");

  const estado = useSelector((state) => state.autoservico);

  const { logout } = useAutoservico();

  function handleLogout() {
    logout();
    localStorage.clear();
    window.location.href = `/autoservico/${service[0]}/login`;
  }

  if (estilo)
    return (
      <>
        <div className={S.sidebarBox}>
          <Cell estilo={estilo} tipo="PROFILE" texto={estado.apelido} />
          <Cell
            estilo={estilo}
            tipo="MENU"
            texto={"Minhas cotas"}
            link={`/autoservico/${service[0]}/minhas-cotas`}
            icone={<TbFileDescription size={24} color={estilo?.secondary} />}
          />
          <Cell
            estilo={estilo}
            tipo="MENU"
            texto={"Meus Dados"}
            link={`/autoservico/${service[0]}/meus-dados`}
            icone={
              <MdOutlinePersonOutline size={24} color={estilo?.secondary} />
            }
          />
          <div className={S.boxLogout}>
            <Cell
              estilo={estilo}
              tipo="LOGOUT"
              onClick={handleLogout}
              texto={"Sair"}
              icone={<MdLogout size={24} color={estilo?.secondary} />}
            />
          </div>
        </div>
      </>
    );
};

export const SidebarMobile = ({ estilo }) => {
  const service = window.location.pathname
    .replace("/autoservico/", "")
    .split("/");

  const estado = useSelector((state) => state.autoservico);

  const { logout } = useAutoservico();

  function handleLogout() {
    logout();
    localStorage.clear();
    window.location.href = `/autoservico/${service[0]}/login`;
  }

  if (estilo)
    return (
      <>
        <div className={S.sidebarBoxMobile}>
          <Cell estilo={estilo} tipo="PROFILE" texto={estado.apelido} />
          <Cell
            estilo={estilo}
            tipo="MENU"
            texto={"Minhas cotas"}
            link={`/autoservico/${service[0]}/minhas-cotas`}
            icone={<TbFileDescription size={24} color={estilo?.secondary} />}
          />
          <Cell
            estilo={estilo}
            tipo="MENU"
            texto={"Meus Dados"}
            link={`/autoservico/${service[0]}/meus-dados`}
            icone={
              <MdOutlinePersonOutline size={24} color={estilo?.secondary} />
            }
          />
          <div className={S.boxLogout}>
            <Cell
              estilo={estilo}
              tipo="LOGOUT"
              onClick={handleLogout}
              texto={"Sair"}
              icone={<MdLogout size={24} color={estilo?.secondary} />}
            />
          </div>
        </div>
      </>
    );
};
