import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import api from "../../services/api";
import cep from 'cep-promise'
import { Link, withRouter } from "react-router-dom";
import { cpfCnpj } from '../../mask/cpfcnpj';



export class FormCessionaria extends Component {



  constructor(props){

    super(props);

    this.state = {
          nomeFantasia: '',
          razaoSocial: '',
          cnpj: '',
          logradouro:'',
          logradouroNumero:'',
          cep:'',
          bairro:'',
          cidade:'',
          uf:'',
          email:'',
          telefone:'',
          whatsapp:'',
          complemento:'',
          inscricaoEstadual:'',
          dataFundacao:'',
          nacionalidade:'',
          nomenclatura:'',
          codigo:'',
          labelForm: '',
          cidades:[],
          ufs:[],
          labelButton: '',
          error: "",
          action: ""
    };
  }


  async componentDidMount() {

    if(this.props[0].location.state !== undefined){
        const cessionaria  = this.props[0].location.state.cessionaria
        var classe = this;
        this.setState({nomeFantasia: cessionaria.nomeFantasia,email:cessionaria.email,telefone:cessionaria.telefone,nacionalidade:cessionaria.nacionalidade,whatsapp:cessionaria.whatsapp,inscricaoEstadual:cessionaria.inscricaoEstadual,dataFundacao:cessionaria.dataFundacao,logradouro:cessionaria.logradouro,logradouroNumero:cessionaria.logradouroNumero,complemento:cessionaria.complemento,cep:cessionaria.cep,nomenclatura: cessionaria.nomenclatura,codigo: cessionaria.codigo,cnpj: cessionaria.cnpj,bairro: cessionaria.bairro,cidade: cessionaria.cidade.id,uf: cessionaria.cidade.uf.id,razaoSocial: cessionaria.razaoSocial},() =>{
            classe.callCidades(cessionaria.cidade.uf.id,cessionaria.cidade.nome);
        });
        this.setState({labelForm: "Editar Cessionaria",labelButton: "Editar",action: "editar"});
    }else{
        this.setState({labelForm: "Adicionar Cessionaria",labelButton: "Adicionar",action: "adicionar"});
    }

    this.obtemUFs();

  }

  async  obtemUFs(){
    try {
          const response = await api.post("api/sistema/1.0/estado/listar", {});
          this.setState({ ufs: response.data});
    } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter as unidades federativas."
          });
    }

  }


  callCidades = async (e,city) => {

        this.setState({ uf: e });
        let estado = e;
        try {
              var classe = this;
              const response = await api.post("api/sistema/1.0/cidade/listar", {id:estado});
              this.setState({ cidades: response.data},() => {
                    classe.state.cidades.map((cidade,index) => {
                          if(city == cidade.nome){
                              classe.setState({ cidade: cidade.id });
                          }
                    });
              });
        } catch (err) {
              this.setState({
                error:
                  "Houve um problema para obter as cidades."
              });
        }
  }

  callCep = async e => {

        cep(e.target.value).then( result => {

              this.setState({ bairro: result.neighborhood});
              this.setState({ logradouro: result.street});
              var classe = this;
              this.state.ufs.map((uf,index) => {
                  if(result.state == uf.uf){
                     classe.setState({ uf: uf.id });
                     classe.callCidades(uf.id,result.city);
                  }
              });
            }
        );

        this.setState({ cep: e.target.value });
        let estado = e.target.value;
  }

  handleForm = async e => {
    e.preventDefault();
    const { nomeFantasia,
            razaoSocial,
            cnpj,
            nomenclatura,
            logradouro,
            logradouroNumero,
            bairro,
            cep,
            cidade,
            complemento,
            email,
            dataFundacao,
            nacionalidade,
            telefone,
            whatsapp,
            inscricaoEstadual,
            codigo,
            action} = this.state;
    if (!nomeFantasia
        || !cnpj
        || !razaoSocial
        || !nomenclatura
        || !action) {
      this.setState({ error: "Preencha os campos obrigatórios para continuar!" });
    } else {
      try {

        if(action == "editar"){
          const response = await api.put("api/concash/1.0/cessionaria/"+action, {
              nomeFantasia,razaoSocial,cnpj,codigo,nomenclatura,logradouro,logradouroNumero,bairro,cep,complemento, email, dataFundacao, nacionalidade,telefone, whatsapp, inscricaoEstadual, cidadeCodigo:cidade
            });
        }else{
          const response = await api.post("api/concash/1.0/cessionaria/"+action, {
              nomeFantasia,razaoSocial,cnpj,nomenclatura,logradouro,logradouroNumero,bairro,cep,complemento, email, dataFundacao, nacionalidade,telefone, whatsapp, inscricaoEstadual, cidadeCodigo:cidade
            });
        }

        this.props.history.push("/cessionaria");

      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o processamento da requisição."
        });
      }
    }
  };

  render() {


    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> {this.state.labelForm} </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>

        <div className="row">

          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"></h4>
                {this.state.error && <p className="card-description">{this.state.error}</p>}
                <form className="forms-sample" onSubmit={this.handleForm}>

                <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">Nome</label>
                        <div className="col-sm-9">
                        <Form.Control type="text" id="nomeFantasia" value={this.state.nomeFantasia} placeholder="Nome da Cessionaria"  onChange={e => this.setState({ nomeFantasia: e.target.value })}  size="sm" />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">Razão Social</label>
                        <div className="col-sm-9">
                        <Form.Control type="text" id="nome" value={this.state.razaoSocial} placeholder="Razão Social"  onChange={e => this.setState({ razaoSocial: e.target.value })}  size="sm" />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">CNPJ</label>
                        <div className="col-sm-9">
                        <Form.Control type="text" id="cnpj" value={cpfCnpj(this.state.cnpj)} maxLength={18} placeholder="CNPJ"  onChange={e => this.setState({ cnpj: e.target.value })}  size="sm" />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">Nomenclatura</label>
                        <div className="col-sm-9">
                        <Form.Control type="text" id="nomenclatura" value={this.state.nomenclatura} placeholder="Nomenclatura"  onChange={e => this.setState({ nomenclatura: e.target.value })}  size="sm" />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Inscrição Estadual</label>
                        <div className="col-sm-9">
                        <Form.Control type="text" className="form-control" id="inscricaoEstadual" value={this.state.inscricaoEstadual} onChange={e => this.setState({ inscricaoEstadual: e.target.value })} size="sm"/>
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Nacionalidade</label>
                        <div className="col-sm-9">
                        <Form.Control type="text" className="form-control" id="nacionalidade" value={this.state.nacionalidade} onChange={e => this.setState({ nacionalidade: e.target.value })} size="sm"/>
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Data Fundação</label>
                        <div className="col-sm-9">
                        <Form.Control type="text" className="form-control" id="dataFundacao" value={this.state.dataFundacao} onChange={e => this.setState({ dataFundacao: e.target.value })} size="sm"/>
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">E-mail</label>
                        <div className="col-sm-9">
                        <Form.Control type="text" id="email" value={this.state.email} placeholder="E-mail"  onChange={e => this.setState({ email: e.target.value })}  size="sm" />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">CEP</label>
                        <div className="col-sm-9">
                        <Form.Control type="text" className="form-control" id="cep" value={this.state.cep} onChange={e => this.callCep(e)} size="sm"/>
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Logradouro</label>
                        <div className="col-sm-9">
                        <Form.Control type="text" className="form-control" id="logradouro" value={this.state.logradouro} onChange={e => this.setState({ logradouro: e.target.value })} size="sm"/>
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Numero</label>
                        <div className="col-sm-9">
                        <Form.Control type="text" className="form-control" id="logradouroNumero" value={this.state.logradouroNumero} size="sm" onChange={e => this.setState({ logradouroNumero: e.target.value })}/>
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Bairro</label>
                        <div className="col-sm-9">
                        <Form.Control type="text" className="form-control" id="bairro" value={this.state.bairro} size="sm" onChange={e => this.setState({ bairro: e.target.value })}/>
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">UF</label>
                        <div className="col-sm-9">
                          <select className="form-control"   value={this.state.uf} onChange={e =>  this.callCidades(e.target.value) } id="codigoAdministradora">
                            <option value="">Selecione</option>
                            {this.state.ufs.map((uf,index) => (
                              <option value={uf.id} key={index}>{uf.nome}</option>
                            ))}
                          </select>
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Cidade</label>
                        <div className="col-sm-9">
                          <select className="form-control"  value={this.state.cidade} onChange={e => this.setState({ cidade: e.target.value })  } id="codigoAdministradora">
                            <option value="">Selecione</option>
                            {this.state.cidades.map((cidade,index) => (
                              <option value={cidade.id} key={index}>{cidade.nome}</option>
                            ))}
                          </select>
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Complemento</label>
                        <div className="col-sm-9">
                        <Form.Control type="text" className="form-control" id="complemento" value={this.state.complemento} onChange={e => this.setState({ complemento: e.target.value })} size="sm"/>
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Telefone</label>
                        <div className="col-sm-9">
                        <Form.Control type="text" className="form-control" id="telefone" value={this.state.telefone} onChange={e => this.setState({ telefone: e.target.value })} size="sm"/>
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">WhatsApp</label>
                        <div className="col-sm-9">
                        <Form.Control type="text" className="form-control" id="whatsapp" value={this.state.whatsapp} onChange={e => this.setState({ whatsapp: e.target.value })} size="sm"/>
                        </div>
                      </Form.Group>

                    </div>
                  </div>
                  <div className="text-right">
                    <button type="submit" className="btn btn-primary mr-2">{this.state.labelButton}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
         </div>
       </div>
    )
  }
}

export default withRouter(FormCessionaria)
