import styled from "styled-components";
import { colors } from "../../../../../styles/colors";

export const ContainerDesktop = styled.div`
  display: flex;
  width: 95%;
  height: 110px;
  background-color: rgba(255, 255, 255, 0.61);
  border-radius: 5px;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 15px;
  &:hover {
    background-color: ${colors.borderCardGreen};
  }
`;

export const ItemBoxDesktop = styled.div`
  display: flex;
  width: 140px;
  max-width: 140px;
  height: 90px;
  background: ${colors.cardGreen};
  border: 1px solid ${colors.borderCardGreen};
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`;

export const CenterBoxDesktop = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 15px;
`;

export const BadgeItems = ({ border, background, chave, valor }) => {
  return (
    <div
      style={{
        display: "flex",
        width: 100,
        height: 30,
        border: `0.5px solid ${border}`,
        borderRadius: 8,
        backgroundColor: background,
      }}
    >
      <div
        style={{
          display: "flex",
          width: "50%",
          fontSize: 11,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: border,
        }}
      >
        <span>{chave}</span>
      </div>
      <div
        style={{
          display: "flex",
          width: "50%",
          fontSize: 11,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {valor}
      </div>
    </div>
  );
};

// Mobile

export const ContainerMobile = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  width: 95%;

  background-color: rgba(255, 255, 255, 0.81);
  border-radius: 5px;
  margin: 5px 0;
  padding: 10px;
  align-items: space-between;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    background-color: ${colors.borderCardGreen};
  }

  @media (max-width: 768px) {
    min-height: 120px;
    max-height: 160px;
  }
  @media (min-width: 769px) {
    min-height: 100px;
    max-height: 160px;
  }
`;

export const ItemBoxMobile = styled.div`
  display: flex;
width: 100%;
flex-direction: row;
  height: 40px;
  background: ${colors.cardGreen};
  border: 1px solid ${colors.borderCardGreen};
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 8px;
`;

export const CenterBoxMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  min-height: 120px;
`;
