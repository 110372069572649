import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import CheckboxFuncaoFuncionalidade from "../../lib/CheckboxFuncaoFuncionalidade";
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";
import Swal from 'sweetalert2'


export class BasicElements extends Component {



  constructor(props){

    super(props);

    this.state = {
          funcao: '',
          labelForm: '',
          labelButton: '',
          acoes: [],
          funcionalidadesAcoes: [],
          marcados:[],
          error: "",
          action: ""
    };
  }


  async componentDidMount() {


        if(this.props[0].location.state !== undefined){
            const obj_funcao  = this.props[0].location.state.funcao
            this.setState({funcao: obj_funcao},this.obtemFuncionalidadesAcoes);
            this.setState({labelForm: "Editar ",labelButton: "Configurar"});
        }else{
            this.setState({labelForm: "Adicionar ",labelButton: "Configurar"});
        }

  }

  async obtemFuncionalidadesAcoes() {
    try {
          const func      = this.state.funcionalidade;
          const response2 = await api.post("api/sistema/1.0/funcao-permissao/listar", {  codigo: this.state.funcao.codigo,status: 'ATIVO' });
          var fAcoes = [];

          for(var i=0; i < response2.data.length;i++){
              fAcoes[i] = response2.data[i].funcionalidadeAcao.codigo;
          }
          this.setState({ acoes: fAcoes});

          try {
                const response = await api.post("api/sistema/1.0/funcionalidade-acao/registros", {});
                this.setState({ funcionalidadesAcoes: response.data});
          } catch (err) {
                this.setState({
                  error:
                    "Houve um problema para obter as funcionalidade e ações."
                });
          }

    } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter as configurações."
          });
    }
  }



  render() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> {this.state.labelForm} Função : {this.state.funcao.funcao} </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>

        <div className="row">

          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"></h4>
                {this.state.error && <p className="card-description">{this.state.error}</p>}
                <form className="forms-sample" onSubmit={this.handleForm}>
                {this.state.funcionalidadesAcoes.map((fa,index) => (
                    <CheckboxFuncaoFuncionalidade  marcados={this.state.acoes} funcao={this.state.funcao.codigo}  funcionalidade={fa} key={index}/>
                ))}
                <div className="row">
                    <div className="col-md-12">
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
         </div>
       </div>
    )
  }
}

export default withRouter(BasicElements);
