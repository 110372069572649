import React, { Component } from "react";
import { Form } from "react-bootstrap";
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import { AlteraNome } from "../../actions";
import styled, { css } from "styled-components";
import SimuladorSimplificado from "../../components/SimuladorSimplificado";
import Pagamento from "../../components/Pagamento";
// import CotaInfo from "../../components/CotaInfo";
import PagamentoInfo from "../../components/PagamentoInfo";
import DespesaInfo from "../../components/DespesaInfo";
import CabecalhoCota from "../../components/CabecalhoCota";
import {
  cellphoneFormater,
  currencyFormater,
  numberFormater,
} from "../../lib/formaters";
import IntlCurrencyInput from "react-intl-currency-input";
import InputFloat from "react-floating-input";
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";

const BoxInformativo = styled.div`
  text-align: center;
  color: white;
  padding: 10px;
  background: #2196f3;
  width: 150px;
  height: 40px;
  margin-top: 20px;
  border-radius: 10px 10px 0px 0px;
  cursor: pointer;

  :hover {
    background: #16898e;
  }
`;

registerLocale("pt-BR", ptBR);

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export class FinanceiroDetalhamentoCadastroLanceParcela extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      cota: [],
      codigoProcesso: "",
      contemplado: "",
      cliente: [],
      processo: [],
      startDate: new Date(),
      grupo: [],
      numeroParcela: 0,
      vencimentoParcela: "",
      boletoParcela: "",
      boletoLance: "",
      boletoAssembleia: "",
      valorParcela: 0.0,
      valorLance: 0.0,
      lanceSimulado: 0.0,
      administradora: [],
      blocoCota: true,
      blocoPagamento: false,
      backgroundCota: "#ccc",
      backgroundPagamento: "#2196f3",
    };
  }

  async componentDidMount() {
    if (this.props[0].location.state !== undefined) {
      const codigo = this.props[0].location.state.codigo;
      const codigoProcesso = this.props[0].location.state.processo;
      this.setState({ codigoProcesso: codigoProcesso });
      this.obtemCota(codigo);
      this.obtemProcesso(codigoProcesso);
      this.obtemPropostaAceita(codigo);
    }
  }

  async obtemCota(codigo) {
    try {
      const response = await api.post(
        "api/concash/1.0/consorcio-grupo-cota/detalhe",
        { codigo: codigo }
      );
      this.setState({
        cota: response.data,
        cliente: response.data.cliente,
        grupo: response.data.grupo,
        administradora: response.data.grupo.consorcio.administradora,
      });
    } catch (err) {
      this.setState({
        error: "Houve um problema para obter a cota.",
      });
    }
  }

  async obtemPropostaAceita(codigo) {
    try {
      const response = await api.post(
        "api/concash/1.0/consorcio-grupo-cota-proposta/proposta-aceita",
        { codigoCota: codigo }
      );
      var resultado = JSON.parse(response.data.resultado);
      this.setState({ lanceSimulado: resultado.contemplacao.lance.lance });
    } catch (err) {
      this.setState({
        error: "Houve um problema para obter a cota.",
      });
    }
  }

  async obtemProcesso(codigo) {
    try {
      const response = await api.post("api/concash/1.0/processo/detalhe", {
        codigo: codigo,
      });
      this.setState({ processo: response.data });
    } catch (err) {
      this.setState({
        error: "Houve um problema para obter o processo.",
      });
    }
  }

  handleChangeValorParcela = (event, value, maskedValue) => {
    event.preventDefault();
    this.setState({
      valorParcela: maskedValue
        .replace("R$", "")
        .replace(".", "")
        .replace(",", ".")
        .trim(),
    });
  };

  handleChangeValorLance = (event, value, maskedValue) => {
    event.preventDefault();
    this.setState({
      valorLance: maskedValue
        .replace("R$", "")
        .replace(".", "")
        .replace(",", ".")
        .trim(),
    });
  };

  handleChangeVencimentoParcela = (date) => {
    this.setState({
      vencimentoParcela: moment(date).format("DD/MM/yyyy"),
    });
  };

  alteraAbas = (aba) => {
    const abas = ["Cota", "Pagamento"];

    var tmp_cota = false;
    var tmp_pagamento = false;
    var bck_cota = "#2196f3";
    var bck_pagamento = "#2196f3";

    for (var i = 0; i < abas.length; i++) {
      if (aba == "Cota") {
        tmp_cota = true;
        bck_cota = "#ccc";
      }
      if (aba == "Pagamentos") {
        tmp_pagamento = true;
        bck_pagamento = "#ccc";
      }
    }

    this.setState({ blocoCota: tmp_cota, blocoPagamento: tmp_pagamento });
    this.setState({
      backgroundCota: bck_cota,
      backgroundPagamento: bck_pagamento,
    });
  };

  handleFormCadastrarParcela = async (e) => {
    e.preventDefault();
    const {
      codigoProcesso,
      vencimentoParcela,
      numeroParcela,
      valorParcela,
      boletoParcela,
    } = this.state;
    if (
      !codigoProcesso ||
      !vencimentoParcela ||
      !numeroParcela ||
      !valorParcela ||
      !boletoParcela
    ) {
      Swal.fire("Preencha os campos obrigatórios para continuar!");
    } else {
      try {
        Swal.fire({
          title: "Aguarde ...",
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });

        const formData = new FormData();
        formData.append("file", boletoParcela);
        formData.append("codigo", codigoProcesso);
        formData.append("vencimentoParcela", vencimentoParcela);
        formData.append("numeroParcela", numeroParcela);
        formData.append("valorParcela", valorParcela);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const response = await api.post(
          "api/concash/1.0/consorcio-grupo-cota-parcela/adicionar",
          formData,
          config
        );

        Swal.close();
        Swal.fire("Parcela adicionada com sucesso!");
      } catch (err) {
        Swal.fire("Houve um problema com o processamento da requisição.");
      }
    }
  };

  handleFormCadastrarResultadoAssembleia = async (e) => {
    e.preventDefault();
    const { codigoProcesso, contemplado, boletoAssembleia } = this.state;
    if (!codigoProcesso || !contemplado || !boletoAssembleia) {
      Swal.fire("Preencha os campos obrigatórios para continuar!");
    } else {
      try {
        Swal.fire({
          title: "Aguarde ...",
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });

        const formData = new FormData();
        formData.append("file", boletoAssembleia);
        formData.append("codigo", codigoProcesso);
        formData.append("contemplado", contemplado);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const response = await api.put(
          "api/concash/1.0/assembleia/editar",
          formData,
          config
        );

        Swal.close();
        Swal.fire("Resultado assembléia salva com sucesso!");
      } catch (err) {
        Swal.fire("Houve um problema com o processamento da requisição.");
      }
    }
  };

  handleFormCadastrarLance = async (e) => {
    e.preventDefault();
    const { codigoProcesso, valorLance } = this.state;
    if (!codigoProcesso || !valorLance) {
      Swal.fire("Preencha os campos obrigatórios para continuar!");
    } else {
      try {
        Swal.fire({
          title: "Aguarde ...",
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });

        const response = await api.post(
          "api/concash/1.0/consorcio-grupo-cota-lance/adicionar",
          {
            codigoProcesso: codigoProcesso,
            valorLance,
          }
        );

        Swal.close();
        Swal.fire("Lance cadastrado com sucesso!");
      } catch (err) {
        Swal.fire("Houve um problema com o processamento da requisição.");
      }
    }
  };

  render() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">
            {" "}
            Cadastro Lance/Parcela - Cota : ({this.state.cota.contrato}){" "}
          </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb"></ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                {this.state.cliente.cpf && (
                  <CabecalhoCota
                    cliente={this.state.cliente}
                    cota={this.state.cota}
                    grupo={this.state.grupo}
                    administradora={this.state.administradora}
                  />
                )}

                <div className="row">
                  <BoxInformativo
                    className="col-md-1"
                    style={{ background: this.state.backgroundCota }}
                    onClick={(e) => this.alteraAbas("Cota")}
                  >
                    Cota
                  </BoxInformativo>

                  <BoxInformativo
                    className="col-md-2"
                    style={{ background: this.state.backgroundPagamento }}
                    onClick={(e) => this.alteraAbas("Pagamentos")}
                  >
                    Cadastro
                  </BoxInformativo>
                </div>

                {this.state.blocoCota && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        background: "#ccc",
                        minHeight: "300px",
                        padding: "20px",
                      }}
                    >
                      {/* {this.state.cota.grupo &&
                          // <CotaInfo cota={this.state.cota} administradora={this.state.administradora} grupo={this.state.grupo} />
                        } */}
                    </div>
                  </div>
                )}

                {this.state.blocoPagamento && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        background: "#ccc",
                        minHeight: "300px",
                        padding: "20px",
                      }}
                    >
                      {this.state.processo.etapa == "CADASTRO_PARCELA" && (
                        <form
                          className="forms-sample"
                          onSubmit={this.handleFormCadastrarParcela}
                        >
                          <div className="row">
                            <div className="col-md-12 grid-margin stretch-card">
                              <div className="card">
                                <div className="card-body">
                                  <Form.Group className="row">
                                    <label className="col-sm-5 col-form-label">
                                      Vencimento Parcela
                                    </label>
                                    <div className="col-sm-7">
                                      <DatePicker
                                        className="form-control w-100"
                                        value={this.state.vencimentoParcela}
                                        id="vencimentoParcela"
                                        dateFormat="dd/MM/yyyy"
                                        selected={this.state.startDate}
                                        onChange={
                                          this.handleChangeVencimentoParcela
                                        }
                                        locale="pt-BR"
                                      />
                                    </div>
                                  </Form.Group>

                                  <Form.Group className="row">
                                    <label className="col-sm-5 col-form-label">
                                      Numero Parcela
                                    </label>
                                    <div className="col-sm-7">
                                      <Form.Control
                                        type="number"
                                        id="parcelasPagas"
                                        value={this.state.numeroParcela}
                                        placeholder="Parcelas Pagas"
                                        size="sm"
                                        onChange={(e) =>
                                          this.setState({
                                            numeroParcela: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </Form.Group>

                                  <Form.Group className="row">
                                    <label className="col-sm-5 col-form-label">
                                      Valor Parcela
                                    </label>
                                    <div className="col-sm-7">
                                      <IntlCurrencyInput
                                        currency="BRL"
                                        value={this.state.valorParcela}
                                        config={currencyConfig}
                                        onChange={this.handleChangeValorParcela}
                                      />
                                    </div>
                                  </Form.Group>

                                  <Form.Group className="row">
                                    <label
                                      htmlFor="examplePropostal"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Boleto
                                    </label>
                                    <div className="col-sm-9">
                                      <Form.Control
                                        type="file"
                                        className="form-control"
                                        onChange={(e) =>
                                          this.setState({
                                            boletoParcela: e.target.files[0],
                                          })
                                        }
                                        size="sm"
                                      />
                                    </div>
                                  </Form.Group>

                                  <div className="text-right">
                                    <button
                                      type="submit"
                                      className="btn btn-primary mr-2"
                                    >
                                      Cadastrar Parcela
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}

                      {this.state.processo.etapa == "ASSEMBLEIA" && (
                        <form
                          className="forms-sample"
                          onSubmit={this.handleFormCadastrarResultadoAssembleia}
                        >
                          <div className="row">
                            <div className="col-md-12 grid-margin stretch-card">
                              <div className="card">
                                <div className="card-body">
                                  <Form.Group className="row">
                                    <label className="col-sm-5 col-form-label">
                                      Contemplado
                                    </label>
                                    <div className="col-sm-7">
                                      <select
                                        className="form-control"
                                        id="contemplado"
                                        value={this.state.contemplado}
                                        onChange={(e) =>
                                          this.setState({
                                            contemplado: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="">Selecione</option>
                                        <option value="SIM">Sim</option>
                                        <option value="NAO">Não</option>
                                      </select>
                                    </div>
                                  </Form.Group>

                                  <Form.Group className="row">
                                    <label
                                      htmlFor="examplePropostal"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Boleto
                                    </label>
                                    <div className="col-sm-9">
                                      <Form.Control
                                        type="file"
                                        className="form-control"
                                        onChange={(e) =>
                                          this.setState({
                                            boletoAssembleia: e.target.files[0],
                                          })
                                        }
                                        size="sm"
                                      />
                                    </div>
                                  </Form.Group>

                                  <div className="text-right">
                                    <button
                                      type="submit"
                                      className="btn btn-primary mr-2"
                                    >
                                      Gravar Resultado Assembléia
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}

                      {this.state.processo.etapa == "CADASTRO_LANCE" && (
                        <form
                          className="forms-sample"
                          onSubmit={this.handleFormCadastrarLance}
                        >
                          <div className="row">
                            <div className="col-md-12 grid-margin stretch-card">
                              <div className="card">
                                <div className="card-body">
                                  <Form.Group className="row">
                                    <label className="col-sm-5 col-form-label">
                                      Lance Simulado
                                    </label>
                                    <div className="col-sm-7">
                                      <Form.Control
                                        type="text"
                                        readOnly
                                        value={this.state.lanceSimulado}
                                        placeholder="Lance Simulado"
                                        size="sm"
                                      />
                                    </div>
                                  </Form.Group>

                                  <Form.Group className="row">
                                    <label className="col-sm-5 col-form-label">
                                      Valor Lance
                                    </label>
                                    <div className="col-sm-7">
                                      <IntlCurrencyInput
                                        currency="BRL"
                                        value={this.state.valorLance}
                                        config={currencyConfig}
                                        onChange={this.handleChangeValorLance}
                                      />
                                    </div>
                                  </Form.Group>

                                  <div className="text-right">
                                    <button
                                      type="submit"
                                      className="btn btn-primary mr-2"
                                    >
                                      Cadastrar Lance
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(FinanceiroDetalhamentoCadastroLanceParcela);
