export const idAdm = {
  cnp: 123,
  bb: 115,
  santander: 62,
  porto: 46,
  randon: 51,
  volkswagen: 138,
  chevrolet: 22,
  banrisul: 114,
  concash: 0,
};