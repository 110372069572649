import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import styled, { css } from 'styled-components';


export class BoxAUDIO extends Component {

  constructor(props){
        super(props);
  }


  render() {

    const { base64 }  = this.props;
    
    return (
      <>
        <audio controls="true" src={base64.replace("data:application/octet-stream;","data:audio/ogg;")}  style={{width:"90%"}}>
        </audio>
      </>
    )
  }
}

export default BoxAUDIO;
