import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import api from "../../services/api";
import { Link } from "react-router-dom";
import SweetAlert from 'sweetalert2-react';
import Swal from 'sweetalert2'
import Pagination from "../../components/Pagination";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AlteraNome } from '../../actions';
import { ListClienteSessao } from './ListaCliente'
import { ListProspectSessao } from './ListaProspect'




export class WhatsappSessao extends Component {



  constructor(props){

        super(props);

        this.state = {
          sessoes: [],
          cliente_nome:'',
          cliente_etapa:'CADASTRO_USUARIO',
          cliente_cpf:'',
          filtrarEtapa:false,
          cliente_email:'',
          currentPage:1,
          totalRecords:null,
          show: false
        };
  }

  async componentDidMount() {

        this.obtemListaSessoes();
  }

  async obtemListaSessoes(){



      try {
        const {
          currentPage,
          cliente_nome,
          cliente_cpf,
          cliente_email,
          filtrarEtapa,
          cliente_etapa
        } = this.state;

        const response = await api.post("api/concash/1.0/whatsapp-sessao/listar", { pagina: currentPage,nome:cliente_nome,cpf:cliente_cpf,etapa:cliente_etapa,email:cliente_email,filtrarEtapa:filtrarEtapa });
        this.setState({ totalRecords: response.data.totalElements,sessoes: response.data.content});
      } catch (err) {
        this.setState({
          error:
            "Houve um problema para obter a lista de sessões."
        });
      }

  }

  handleNome = async (e) => {
      e.preventDefault();
      var classe = this;
      this.setState({ cliente_cpf: "",cliente_email: "",filtrarEtapa:false,totalRecords:null}, () => {
          classe.obtemListaSessoes();
      });
  };

  handleCPF = async (e) => {
      e.preventDefault();
      var classe = this;
      this.setState({ cliente_nome: "",cliente_email: "",filtrarEtapa:false,totalRecords:null}, () => {
          classe.obtemListaSessoes();
      });
  };

  handleEmail = async (e) => {
      e.preventDefault();
      var classe = this;
      this.setState({ cliente_cpf: "",cliente_nome: "",filtrarEtapa:false,totalRecords:null}, () => {
          classe.obtemListaSessoes();
      });
  };

  handleEtapa = async (e) => {
      e.preventDefault();
      var classe = this;
      this.setState({ cliente_cpf: "",cliente_email: "",cliente_nome: "",filtrarEtapa:true,totalRecords:null}, () => {
          classe.obtemListaSessoes();
      });
  };


  onPageChanged = data => {
    var classe = this;
    const { currentPage, totalPages, pageLimit } = data;
    this.setState({ currentPage },() => {
        classe.obtemListaSessoes();
    });
  };

  render() {


    const {
      totalRecords
    } = this.state;


    const { payload,alteraNome } = this.props;

    return (

      <div>
        <div className="page-header">
          <h3 className="page-title"> WhatsApp  - Contatos </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">

                <div className="row" style={{backgroundColor:'rgb(222, 226, 230)'}}>

                  <div className="col-md-6" style={{marginTop:'30px'}}>
                      <div className="row">
                        <div className="col-md-9">
                            <Form.Group className="row">
                              <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Etapa</label>
                              <div className="col-sm-9">
                                <select className="form-control" style={{height:'30px'}} value={this.state.cliente_etapa}  onChange={e => this.setState({ cliente_etapa: e.target.value })} id="etapa">
                                  <option value="QUESTIONARIO" >QUESTIONARIO</option>
                                  <option value="VALIDACAO_EMAIL" >VALIDACAO_EMAIL</option>
                                  <option value="CADASTRO_CPF" >CADASTRO_CPF</option>
                                  <option value="CADASTRO_USUARIO" >CADASTRO_USUARIO</option>
                                  <option value="VALIDADO" >VALIDADO</option>
                                  <option value="BLOQUEADO" >BLOQUEADO</option>
                                </select>
                              </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3">
                          <button type="submit" className="btn btn-primary mr-2" onClick={e =>this.handleEtapa(e)}>Filtrar</button>
                        </div>
                      </div>
                    </div>
                  <div className="col-md-6" style={{marginTop:'30px'}}>
                        <div className="row">
                          <div className="col-md-9">
                              <Form.Group className="row">
                                <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Nome</label>
                                <div className="col-sm-9">
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="cliente_nome" value={this.state.cliente_nome} onChange={e => this.setState({ cliente_nome: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                          <div className="col-md-3">
                            <button type="submit" className="btn btn-primary mr-2" onClick={e =>this.handleNome(e)}>Filtrar</button>
                          </div>
                        </div>
                    </div>
                  </div>


                  <div className="row" style={{backgroundColor:'rgb(222, 226, 230)'}}>

                    <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-9">
                              <Form.Group className="row">
                                <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">CPF</label>
                                <div className="col-sm-9">
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="cliente_cpf" value={this.state.cliente_cpf} onChange={e => this.setState({ cliente_cpf: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                          <div className="col-md-3">
                            <button type="submit" className="btn btn-primary mr-2" onClick={e =>this.handleCPF(e)}>Filtrar</button>
                          </div>
                        </div>
                      </div>
                    <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-9">
                                <Form.Group className="row">
                                  <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">E-mail</label>
                                  <div className="col-sm-9">
                                    <Form.Control type="text" style={{height:'30px'}} className="form-control" id="cliente_email" value={this.state.cliente_email} onChange={e => this.setState({ cliente_email: e.target.value })} />
                                  </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-3">
                              <button type="submit" className="btn btn-primary mr-2" onClick={e =>this.handleEmail(e)}>Filtrar</button>
                            </div>
                          </div>
                      </div>
                    </div>



                  <p className="card-description text-right">
                                <Link
                                  to={{pathname: "/whatsapp-sessao/adicionar-contato"}}
                                  className="m-3 btn btn-sm btn-success"
                                >Adicionar Contato
                      </Link></p>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th> Nome   </th>
                          <th> CPF    </th>
                          <th> E-mail </th>
                          <th className="text-center"> Ação </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.sessoes.map((sessao,index) => (
                          sessao.cliente
                            ?  <ListClienteSessao   sessao={sessao}  index={index}  />
                            :  <ListProspectSessao  sessao={sessao}  index={index}  />
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div style={{
                                display: 'flex',
                                marginTop: '50px',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                  {!!this.state.totalRecords &&
                  <Pagination
                    totalRecords={totalRecords}
                    pageLimit={20}
                    pageNeighbours={1}
                    onPageChanged={this.onPageChanged}
                  />
                 }
                 </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = store => ({
  payload: store.AutenticacaoReducer.nome
});

function mapDispatchToProps(dispatch) {
    return {
        alteraNome: (id) => {
            dispatch(AlteraNome(id));
        },
    };
}


export default connect(mapStateToProps,mapDispatchToProps)(WhatsappSessao);
