import React from 'react';
import {IoIosArrowForward} from "react-icons/io"
import { BadgeItems, CenterBoxDesktop, CenterBoxMobile, ContainerDesktop, ContainerMobile, ItemBoxDesktop, ItemBoxMobile } from './styles';
import { IconeEtapaOtp, IconeEtapaOtpMobile, IconsList, administradorasList } from '../../items';
import { colors } from '../../../../../styles/colors';
import{FloatButton} from '../../../../../components/FloatButton'
import { BsWhatsapp } from "react-icons/bs";
export function CotaCardDesktop({onClick, cota, adm}) {
  return(
    <>
    <ContainerDesktop onClick={onClick}>
        <ItemBoxDesktop>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: 'center', color: colors.altGreen, gap: 8}}>
            {IconeEtapaOtp(cota.etapaAtual.descricao)}
            <span style={{ fontSize: 10, color: colors.altGreen, textAlign: 'center' }}>{cota.etapaAtual.descricao}</span>
            </div>
        </ItemBoxDesktop>
        <CenterBoxDesktop>
        <div>
            <span style={{ fontWeight: 'bold'}}>{administradorasList[adm]}</span>
        </div>
        <div style={{ display: 'flex', gap: 15}}>
        <span>Grupo: {cota.grupo}</span>
        <span>Cota: {cota.cota}</span>

        </div>
        </CenterBoxDesktop>
        <IoIosArrowForward style={{ width: 90, height: "40%", cursor: 'pointer'}} />
        <FloatButton
              direction="right"
              color="#25d366"
              radius="100px"
              opcional
              link="https://api.whatsapp.com/send/?phone=551140405686&text=Ol%C3%A1%2C+quero+vender+minha+cota+de+consorcio+para+a+Concash​"
              icon={<BsWhatsapp size={30} color={"#FFF"} />}
            />
    </ContainerDesktop>
    </>
  )
}

export function CotaCardMobile({onClick, cota, adm}) {
  return(
    <>
    <ContainerMobile onClick={onClick}>
        <ItemBoxMobile>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: "center", justifyContent: 'center', color: colors.altGreen, gap: 8}}>
            {IconeEtapaOtpMobile(cota.etapaAtual?.descricao)}
            <span style={{ fontSize: 12, color: colors.altGreen, textAlign: 'left', lineHeight: 0.9 }}>{cota.etapaAtual?.descricao ? cota.etapaAtual?.descricao : "Pagamento realizado"}</span>
            </div>
        </ItemBoxMobile>
        <CenterBoxMobile>
        <div>
            <span style={{ fontWeight: 'bold', fontSize: 12, lineHeight: 1.5}}>{administradorasList[adm]}</span>
        </div>
        <div style={{ display: 'flex', flex: 1, gap: 15, alignContent: 'flex-end', marginTop: 8, marginBottom: 8}}>
        <span style={{ fontWeight: '400', fontSize: 12, lineHeight: 0}}>Grupo: {cota.grupo}</span>
        <span style={{ fontWeight: '400', fontSize: 12, lineHeight: 0}}>Cota: {cota.cota}</span>

        </div>
        </CenterBoxMobile>
    </ContainerMobile>
    </>
  )
}
