import React, { useEffect, useState } from "react";
import useAutoservico from "../../store/autoservico/useAutoservico";
import { useSelector } from "react-redux";
import { LoginAutoServico } from "./login";
import { MinhasCotas } from "./screens/MinhasCotas";
import { DetalheCota } from "./screens/DetalheCota";
import { MeusDados } from "./screens/MeusDados";
import LogoBB from "../../assets/bb-color.png";
import {
  FormDadosBancarios,
  FormDadosPessoais,
  FormEndereco,
} from "./screens/Forms";
import LoadingCustom from "../../components/LoadingCustom";
import { Finalizado } from "./screens/Finalizado";
import { Assinar } from "./screens/assinar";
import LogoCNP from "../../assets/CNPSeguradora.png";
import BBLogo from "../../assets/images/bb-x.png";

export const AutoServico = () => {
  const service = window.location.pathname
    .replace("/autoservico/", "")
    .replace("autoservico", "")
    .replace("/autoservico", "")
    .split("/");
  const [loading, setLoading] = useState(false);

  const { logout, GetData, estilizar, tokenValido, getTokenAutoServico } =
    useAutoservico();
  const { logged, token } = useSelector((state) => state.autoservico);
  const [estilo, setEstilo] = useState("");

  function goto(page) {
    return (window.location.href = page);
  }

  useEffect(() => {
    if (service[0] === "cnp") {
      setEstilo({
        bgBox: "#002364",
        buttonColor: "#D70064",
        primary: "#D70064",
        secondary: "#002364",
        urlAdm: LogoCNP,
      });
    }
    if (service[0] === "santander") {
      setEstilo({
        bgBox: "#E90B18",
        buttonColor: "#E90B18",
        primary: "#E90B18",
        secondary: "#E90B18",
        urlAdm:
          "https://logospng.org/download/santander/logo-santander-icon-4096.png",
      });
    }
    if (service[0] === "concash" || !service[0]) {
      setEstilo({
        bgBox: "#16898e",
        buttonColor: "#16898e",
        primary: "#16898e",
        secondary: "#16898e",
        urlAdm: "",
      });
    }
    if (service[0] === "porto") {
      setEstilo({
        bgBox: "#0046c0",
        buttonColor: "#0046c0",
        primary: "#0046c0",
        secondary: "#0046c0",
        urlAdm:
          "https://th.bing.com/th/id/R.d4455655b8102eb7ae5aeb807ded4c39?rik=ZTa6UWtdHKioZw&pid=ImgRaw&r=0",
      });
    }
    if (service[0] === "volkswagen") {
      setEstilo({
        bgBox: "#001e50",
        buttonColor: "#001e50",
        primary: "#001e50",
        secondary: "#001e50",
        urlAdm:
          "https://assets.website-files.com/62684f12494ac95bb7d84f4a/62d1a77954e5432508e3f6bf_volks-logo.png",
      });
    }
    if (service[0] === "randon") {
      setEstilo({
        bgBox: "#00aeef",
        buttonColor: "#00aeef",
        primary: "#00aeef",
        secondary: "#000",
        urlAdm:
          "https://gerenciadorsites.randonconsorcios.com.br/Uploads/Empresas/7/LogoCNR.jpg",
      });
    }
    if (service[0] === "bb") {
      setEstilo({
        bgBox: "##fff212",
        buttonColor: "#fff212",
        primary: "#0067ab",
        secondary: "#0067ab",
        urlAdm: BBLogo,
      });
    }
    if (!service[0] && !logged) {
      goto(`/autoservico/concash`);
    }
    if ((!service[1] || service[1] == " " || service[1] == "") && logged) {
      goto(`/autoservico/${service[0]}/minhas-cotas`);
    }

    if (service[1] === "login" && logged) {
      goto(`/autoservico/${service[0]}/minhas-cotas`);
    }
    if (service[1] === "minhas-cotas" && !logged) {
      goto(`/autoservico/${service[0]}/login`);
    }

    if (!service[1] && logged) {
      goto(`/autoservico/${service[0]}/minhas-cotas`);
    }

    if (!tokenValido && getTokenAutoServico) {
      logout();
      goto(`/autoservico/${service[0]}/login`);
    }
  }, []);

  return (
    <>
      <LoadingCustom open={loading} />
      {logged ? (
        <>
          {!service[2] && service[1] === "minhas-cotas" && (
            <MinhasCotas estilo={estilo} />
          )}
          {service[2] && service[1] === "minhas-cotas" && (
            <DetalheCota estilo={estilo} />
          )}
          {service[1] === "meus-dados" && <MeusDados estilo={estilo} />}

          {service[1] === "processo-finalizado" && (
            <Finalizado estilo={estilo} />
          )}
          {service[1] === "assinar" && <Assinar estilo={estilo} />}
        </>
      ) : (
        <>
          <LoginAutoServico estilo={estilo} />
        </>
      )}
    </>
  );
};
