import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";
import { AlteraNome } from '../../actions';
import styled, { css } from 'styled-components';
import Endereco from "../../components/Endereco";
import Telefone from "../../components/Telefone";
import Banco from "../../components/Banco";
import Cota from "../../components/Cota";
import Kyc from "../../components/Kyc";
import CabecalhoCliente from "../../components/CabecalhoCliente";



const BoxInformativo = styled.div`

      text-align:center;
      color: white;
      padding:10px;
      background: #2196f3;
      width: 150px;
      height: 40px;
      margin-top:20px;
      border-radius: 10px 10px 0px 0px;
      cursor: pointer;

      :hover{
        background: #16898e;
      }

`;


export class ComercialDetalhamento extends Component {


  constructor(props){
        super(props);
        this.state = {
          show: false,
          cota: [],
          cliente: [],
          grupo: [],
          questionario: [],
          administradora: [],
          blocoCadastro: true,
          blocoEndereco: false,
          blocoTelefone: false,
          blocoBanco:    false,
          blocoCotas:    false,
          blocoKyc:      false,
          backgroundCadastro: "#ccc",
          backgroundEndereco: "#2196f3",
          backgroundTelefone: "#2196f3",
          backgroundBanco:    "#2196f3",
          backgroundCotas:    "#2196f3",
          backgroundKyc:      "#2196f3",
          enderecos: [],
          recebimentos: [],
          telefones: [],
          propostas: [],
          cotas: []
        };
  }

  async componentDidMount() {

    if(this.props[0].location.state !== undefined){
        const codigo  = this.props[0].location.state.codigo
        this.obtemCota(codigo);
    }

  }


  async  obtemCota(codigo){
    try {
          const response = await api.post("api/concash/1.0/consorcio-grupo-cota/detalhe", {codigo:codigo});
          this.obtemQuestionario(response.data.cliente.codigo);
          this.setState({cota:response.data,cliente:response.data.cliente,grupo:response.data.grupo,administradora:response.data.grupo.consorcio.administradora});
    } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter a cota."
          });
    }

  }


  async  obtemQuestionario(codigo){
    try {
          const response = await api.post("api/concash/1.0/cliente/questionario", {codigo:codigo});
          if(response.data){
              this.setState({ questionario: response.data.Events[0].Questions});
          }
    } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter o questionário."
          });
    }
  }



  alteraAbas = aba => {


      const abas = ["Cadastro","Endereço","Telefone","Banco","Cotas","KYC"];

      var tmp_cadastro = false;
      var tmp_endereco = false;
      var tmp_telefone = false;
      var tmp_banco    = false;
      var tmp_cotas    = false;
      var tmp_kyc      = false;
      var bck_cadastro = "#2196f3";
      var bck_endereco = "#2196f3";
      var bck_telefone = "#2196f3";
      var bck_banco    = "#2196f3";
      var bck_cotas    = "#2196f3";
      var bck_kyc      = "#2196f3";

      for(var i = 0; i < abas.length; i++){

            if(aba == "Cadastro"){ tmp_cadastro = true; bck_cadastro = "#ccc";  }
            if(aba == "Endereço"){ tmp_endereco = true; bck_endereco = "#ccc"; }
            if(aba == "Telefone"){ tmp_telefone = true; bck_telefone = "#ccc"; }
            if(aba == "Banco"){    tmp_banco    = true; bck_banco    = "#ccc"; }
            if(aba == "Cotas"){    tmp_cotas    = true; bck_cotas    = "#ccc"; }
            if(aba == "KYC"){      tmp_kyc      = true; bck_kyc      = "#ccc"; }
      }

      this.setState({ blocoCadastro : tmp_cadastro, blocoEndereco: tmp_endereco, blocoTelefone: tmp_telefone, blocoBanco: tmp_banco, blocoCotas: tmp_cotas, blocoKyc: tmp_kyc });
      this.setState({ backgroundCadastro : bck_cadastro, backgroundEndereco: bck_endereco, backgroundTelefone: bck_telefone, backgroundBanco: bck_banco, backgroundCotas: bck_cotas, backgroundKyc: bck_kyc });

  };


  render() {


    return (

      <div>
        <div className="page-header">
          <h3 className="page-title"> Backoffice - Detalhamento / Cliente: {this.state.cliente.nome} - {this.state.cliente.cpf}  </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>
        <div className="row">


          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">

               <div className="card-body">

                {this.state.cliente.nome &&
                  <CabecalhoCliente cliente={this.state.cliente} />
                }

                <div className="row">

                    <BoxInformativo className="col-md-2" style={{background:this.state.backgroundCadastro}} onClick={ e => this.alteraAbas("Cadastro") }>
                      Cadastro
                    </BoxInformativo>

                    <BoxInformativo className="col-md-2" style={{background:this.state.backgroundEndereco}} onClick={ e => this.alteraAbas("Endereço") }>
                      Endereço
                    </BoxInformativo>

                    <BoxInformativo className="col-md-2" style={{background:this.state.backgroundTelefone}} onClick={ e => this.alteraAbas("Telefone") }>
                      Telefone
                    </BoxInformativo>

                    <BoxInformativo className="col-md-2" style={{background:this.state.backgroundBanco}} onClick={ e => this.alteraAbas("Banco") }>
                      Banco
                    </BoxInformativo>

                    <BoxInformativo className="col-md-2" style={{background:this.state.backgroundCotas}} onClick={ e => this.alteraAbas("Cotas") }>
                      Cotas
                    </BoxInformativo>

                    <BoxInformativo className="col-md-2" style={{background:this.state.backgroundKyc}} onClick={ e => this.alteraAbas("KYC") }>
                      KYC
                    </BoxInformativo>

                </div>

                {this.state.blocoCadastro &&
                <div className="row">

                    <div className="col-md-12" style={{background:"#ccc",minHeight:"300px"}}>



                        <div className="row" style={{marginTop:'20px'}}>
                          <div className="col-md-12 grid-margin stretch-card">
                            <div className="card">
                              <div className="card-body">

                                <div className="row" style={{marginTop:'20px'}}>

                                      <div className="col-sm-2">
                                        <b>Nome:</b>
                                      </div>
                                      <div className="col-sm-2">
                                        {this.state.cliente.nome}
                                      </div>
                                      <div className="col-sm-2">
                                        <b>CPF:</b>
                                      </div>
                                      <div className="col-sm-2">
                                        {this.state.cliente.cpf}
                                      </div>
                                      <div className="col-sm-2">
                                        <b>RG:</b>
                                      </div>
                                      <div className="col-sm-2">
                                        {this.state.cliente.rg}
                                      </div>
                          </div>

                          <div className="row" style={{marginTop:'20px'}}>
                                      <div className="col-sm-2">
                                        <b>E-mail:</b>
                                      </div>
                                      <div className="col-sm-2">
                                        {this.state.cliente.email}
                                      </div>
                                      <div className="col-sm-2">
                                        <b>Nacionalidade:</b>
                                      </div>
                                      <div className="col-sm-2">
                                        {this.state.cliente.nacionalidade}
                                      </div>
                                      <div className="col-sm-2">
                                        <b>Estado Civil:</b>
                                      </div>
                                      <div className="col-sm-2">
                                        {this.state.cliente.estadoCivil}
                                      </div>
                          </div>

                          <div className="row" style={{marginTop:'20px'}}>
                                      <div className="col-sm-2">
                                        <b>Profissão:</b>
                                      </div>
                                      <div className="col-sm-2">
                                        {this.state.cliente.profissao}
                                      </div>
                                      <div className="col-sm-2">
                                        <b>Renda:</b>
                                      </div>
                                      <div className="col-sm-2">
                                        {this.state.cliente.renda}
                                      </div>
                                      <div className="col-sm-2">
                                        <b>Gênero:</b>
                                      </div>
                                      <div className="col-sm-2">
                                        {this.state.cliente.genero}
                                      </div>
                          </div>

                          <div className="row" style={{marginTop:'20px'}}>
                                      <div className="col-sm-2">
                                        <b>Data de Entrada:</b>
                                      </div>
                                      <div className="col-sm-2">
                                        {this.state.cliente.dataIni}
                                      </div>
                                      <div className="col-sm-2">
                                        <b>Canal:</b>
                                      </div>
                                      <div className="col-sm-2">
                                        {this.state.cliente.canal}
                                      </div>
                                      <div className="col-sm-2">
                                        <b>Forma de Contato:</b>
                                      </div>
                                      <div className="col-sm-2">
                                      </div>
                              </div>
                          </div>
                        </div>
                      </div>

                      </div>

                  </div>

                </div>
                }

                {this.state.blocoEndereco &&
                    <div className="row">

                        <div className="col-md-12" style={{background:"#ccc",minHeight:"300px", padding:"20px"}}>
                            <Endereco codigoCliente={this.state.cliente.codigo} />
                        </div>

                    </div>
                }

                {this.state.blocoTelefone &&
                  <div className="row">

                      <div className="col-md-12" style={{background:"#ccc",minHeight:"300px", padding:"20px"}}>
                          <Telefone codigoCliente={this.state.cliente.codigo} />
                      </div>

                  </div>
                }

                {this.state.blocoBanco &&
                  <div className="row">

                      <div className="col-md-12" style={{background:"#ccc",minHeight:"300px", padding:"20px"}}>
                          <Banco codigoCliente={this.state.cliente.codigo} />
                      </div>

                  </div>

                }

                {this.state.blocoCotas &&
                  <div className="row">

                      <div className="col-md-12" style={{background:"#ccc",minHeight:"300px", padding:"20px"}}>
                          <Cota cliente={this.state.cliente} origem="BACKOFFICE" />
                      </div>

                  </div>
                }

                {this.state.blocoKyc &&
                  <div className="row">

                      <div className="col-md-12" style={{background:"#ccc",minHeight:"300px", padding:"20px"}}>
                          <Kyc codigoCliente={this.state.cliente.codigo} />
                      </div>

                  </div>
                }

              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default withRouter(ComercialDetalhamento);
