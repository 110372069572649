import { Backdrop } from '@mui/material';
import React from 'react';
import Lottie from "lottie-react";
import Loading from "../../assets/lottie/loading.json";

function LoadingCustom({open}) {
  return(
    <Backdrop
    sx={{
      color: "#16B6C5",
      zIndex: (theme) => theme.zIndex.drawer + 1,
    }}
    open={open}
  >
    <Lottie animationData={Loading} />
  </Backdrop>
  )
}

export default LoadingCustom;