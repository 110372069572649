import styled from "styled-components";
import { colors } from "../../../styles/colors";
import ConcashLogo from "../../../assets/logo.png";
import LogoutIcon from "../../../assets/logout.png";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/otpCnp";


export const MainBoxDesktop = styled.div`
  display: flex;
  flex-direction: column;
  width: 85vw;
  height:  ${window.innerHeight}px;
  margin: 50px;
  align-items: center;
  justify-content: flex-start;
  background: rgba(255, 255, 255, 0.61);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.7px);
  -webkit-backdrop-filter: blur(7.7px);
`;

export const HeaderDesktop = styled.div`
  display: flex;
  height: 70px;
  border-radius: 16px 16px 0 0;
  border: 1px solid #cecece;
  width: 100%;
  background-color: #fff;
  padding: 15px 0 15px 15px;
  align-items: center;
  justify-content: space-between;
`;

export const BoxQuotes = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 20px 20px 50px 20px;
  overflow: auto;

  @media (min-width: 769px) {
    padding: 20px 20px 50px 20px;
  }
`;

export const BoxStatus = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow: auto;

  @media (min-width: 769px) {
    padding: 20px 20px 50px 20px;
  }
`;

export const LogoutDesktop = styled.div`
  display: flex;
  height: 70px;
  width: 70px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 0 16px 0 0;
  border-left: 1px solid #dedede;

  &:hover {
    background-color: #eee;
  }
`;

export const TitlePDesktop = styled.span`
  font-family: Roboto;
  font-size: 30px;
  font-weight: bold;
  color: ${colors.darkGray};
  margin-top: 15px;
`;

export const SelectedContainerDesktop = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const LineDesktop = styled.div`
  display: flex;
  flex-direction: row;
  width: 65vw;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
`;


export const TitleBoxDesktop = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 24px;
  margin-bottom: 0;
  `;

export const HeaderBaseDesktop = () => {
const dispatch = useDispatch();
  function handleLogout() {
    dispatch(logout());
  }
  
  return(
    <HeaderDesktop>
    <img src={ConcashLogo} width={100}  />
    <LogoutDesktop onClick={handleLogout}>
      <img src={LogoutIcon} width={25} height={25} />
    </LogoutDesktop>
  </HeaderDesktop>
  )
}

// Mobile

export const MainBoxMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 85vw;
  height: 90vh;
  align-items: center;
  justify-content: flex-start;
  background: rgba(255, 255, 255, 0.61);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.7px);
  -webkit-backdrop-filter: blur(7.7px);
  overflow: auto;
`;


export const TitleBoxMobile = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 5px 25px;
  margin-bottom: 0;
`;

export const TitlePMobile = styled.span`
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  color: ${colors.darkGray};
  margin-top: 15px;
`;

export const SelectedContainerMobile = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100px;
  width: 90%;
`;

export const LineMobile = styled.div`
  display: flex;
  flex-direction: row;
  width: 65vw;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
`;
