import React, { Component }  from "react";
import { Autocomplete, TextField } from "@mui/material";

import "./style.css";

export class FieldFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchObjeto: "",
      searchObjetos: [],
      searchObjetoDesc: "",
    };
  }

  onFieldChange(value) {
    const fieldName = this.props.atributo;
    const fieldValue = value;
    this.props.onChange(fieldName, fieldValue);
  }

  render() {
    return (
        <label class="input-personalizado">
            <div style={{
                    display: "flex",
                    height: 60,
                    width: 510,
                    justifyContent: "space-between",
                    border: "1px solid #979797",
                    alignItems: "center",
                    paddingRight: 10,
                    paddingLeft: 10,
                    borderRadius: 5,
                 }}
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span className={ this.props.arquivo?.name ? "text-box" : "text-label" }>
                    {this.props.arquivo?.name ? this.props.arquivo.name : this.props.label} 
                  </span>
                </div>
                <div style={{ position: "relative", top: -30, left: -125, padding: 3, backgroundColor: "#FFF", }} >
                  <span className="text-header-label"> {this.props.arquivo?.name ? this.props.label : "" } </span>
                </div>
                <div className="gradient-box">
                    <span className="text-box">Escolher Arquivo</span>
                </div>
                <input type="file" class="input-file" accept=".pdf" onChange={(e) => { this.onFieldChange(e.target.files[0]) }} />
            </div>
        </label>
    )
  }
}
export default FieldFile;
