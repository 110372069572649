import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import api from "../../services/api";
import { Link } from "react-router-dom";
import SweetAlert from 'sweetalert2-react';
import Swal from 'sweetalert2'



export class ProcessoPendentes extends Component {



  constructor(){
        super();
        this.state = {
          pendentes: [],
          show: false
        };
  }

  async componentDidMount() {

        try {
          const response = await api.post("/api/concash/1.0/processo/registros", {});
          this.setState({ pendentes: response.data});
        } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter os dados."
          });
        }
  }



  render() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Processos Pendentes </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th> Código </th>
                          <th> Tipo </th>
                          <th> Data </th>
                          <th className="text-center"> Ação </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.pendentes.map((pendente,index) => (
                        <tr key={index}>
                          <td> {pendente.codigo}  </td>
                          <td> {pendente.etapa}   </td>
                          <td> {pendente.dataIni}   </td>
                          <td className="text-center">

                            {pendente.etapa == "EM_NEGOCIACAO" &&
                                <Link
                                          to={{pathname: "/backoffice/detalhamento-cota",state: {
                                            codigo: pendente.cota.codigo
                                          }}}
                                          className="m-3 btn btn-sm btn-success"
                                        >
                                        Detalhe
                                </Link>
                              }

                              {pendente.etapa == "COTA_REPROVADA" &&
                                  <Link
                                            to={{pathname: "/backoffice/detalhamento-cota",state: {
                                              codigo: pendente.cota.codigo
                                            }}}
                                            className="m-3 btn btn-sm btn-success"
                                          >
                                          Detalhe
                                  </Link>
                                }

                                {pendente.etapa == "AGUARDANDO_DOCUMENTACAO" &&
                                    <Link
                                              to={{pathname: "/backoffice/detalhamento-cota",state: {
                                                codigo: pendente.cota.codigo
                                              }}}
                                              className="m-3 btn btn-sm btn-success"
                                            >
                                            Detalhe
                                    </Link>
                                  }

                                  {pendente.etapa == "DOCUMENTACAO_ASSINADA" &&
                                      <Link
                                                to={{pathname: "/backoffice/detalhamento-cota-backoffice",state: {
                                                  codigo: pendente.cota.codigo
                                                }}}
                                                className="m-3 btn btn-sm btn-success"
                                              >
                                              Detalhe
                                      </Link>
                                    }


                                    {pendente.etapa == "REPROVADO_KYC" &&
                                        <Link
                                                  to={{pathname: "/backoffice/detalhamento-cliente",state: {
                                                    cliente: pendente.cliente
                                                  }}}
                                                  className="m-3 btn btn-sm btn-success"
                                                >
                                                Detalhe
                                        </Link>
                                      }

                                      {pendente.etapa == "PROSPECT_FALE_CONOSCO" &&
                                          <Link
                                                    to={{pathname: "/comercial/prospect/detalhamento-prospect",state: {
                                                      codigo: pendente.prospect.codigo
                                                    }}}
                                                    className="m-3 btn btn-sm btn-success"
                                                  >
                                                  Detalhe
                                          </Link>
                                        }

                                        {pendente.etapa == "PROSPECT_LANDING" &&
                                            <Link
                                                      to={{pathname: "/comercial/prospect/detalhamento",state: {
                                                        codigo: pendente.prospect.codigo
                                                      }}}
                                                      className="m-3 btn btn-sm btn-success"
                                                    >
                                                    Detalhe
                                            </Link>
                                        }

                                        {pendente.etapa == "PROSPECT_PORTAL" &&
                                            <Link
                                                      to={{pathname: "/comercial/prospect/detalhamento-prospect",state: {
                                                        codigo: pendente.prospect.codigo
                                                      }}}
                                                      className="m-3 btn btn-sm btn-success"
                                                    >
                                                    Detalhe
                                            </Link>
                                          }


                                          {pendente.etapa == "REANALISE" &&
                                                  <Link
                                                            to={{pathname: "/produtos/reanalise/analisar",state: {
                                                              proposta: pendente.reanalise
                                                            }}}
                                                            className="m-3 btn btn-sm btn-success"
                                                          >
                                                          Detalhe
                                                  </Link>
                                          }    

                          </td>
                        </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ProcessoPendentes;
