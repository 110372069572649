import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";

export class ConverteCliente extends Component {
  constructor(props) {
    super(props);

    const {
      prospect,
      convertClienteNome,
      convertClienteCPF,
      convertClienteEmail,
    } = this.props;

    this.state = {
      prospect: prospect,
      convertClienteNome: convertClienteNome,
      convertClienteCPF: convertClienteCPF,
      convertClienteEmail: convertClienteEmail,
      error: "",
    };
  }

  async componentDidMount() {}

  handleFormConvertCliente = async (e) => {
    e.preventDefault();
    const {
      convertClienteNome,
      convertClienteCPF,
      convertClienteEmail,
      prospect,
    } = this.state;
    if (
      !convertClienteNome ||
      !convertClienteCPF ||
      !convertClienteEmail ||
      !prospect
    ) {
      Swal.fire("Por favor, informe todos os dados!");
    } else {
      try {
        Swal.fire({
          title: "Aguarde ...",
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });

        const response = await api
          .post("api/concash/1.0/cliente/adicionar", {
            nome: convertClienteNome,
            cpf: convertClienteCPF,
            email: convertClienteEmail,
          })
          .catch((err) => console.log(err));

        const responseTelefone = await api
          .post("api/concash/1.0/cliente-telefone/adicionar", {
            clienteCodigo: response.data.codigo,
            tipo: "CELULAR",
            telefone: prospect.telefone1,
            ddd: prospect.ddd1,
          })
          .catch((err) => {
            console.log(err);
          });

        const responseSessao = await api
          .put("api/concash/1.0/whatsapp-sessao/editar", {
            codigoCliente: response.data.codigo,
            codigoProspect: prospect.codigo,
          })
          .catch((err) => {
            console.log(err);
          });

        Swal.fire("Prospect convertido com sucesso!");
      } catch (err) {
        Swal.fire("Houve um problema na requisição!");
      }
    }
  };

  render() {
    return (
      <form className="forms-sample" onSubmit={this.handleFormConvertCliente}>
        <div className="row" style={{ marginTop: "40px" }}>
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <Form.Group className="row">
                  <label
                    htmlFor="examplePropostal"
                    className="col-sm-12 col-form-label"
                  >
                    Nome
                  </label>
                  <div className="col-sm-12">
                    <Form.Control
                      type="text"
                      className="form-control"
                      id="caption"
                      value={this.state.convertClienteNome}
                      onChange={(e) =>
                        this.setState({ convertClienteNome: e.target.value })
                      }
                      size="sm"
                    />
                  </div>
                </Form.Group>
                <Form.Group className="row">
                  <label
                    htmlFor="examplePropostal"
                    className="col-sm-12 col-form-label"
                  >
                    E-mail
                  </label>
                  <div className="col-sm-12">
                    <Form.Control
                      type="text"
                      className="form-control"
                      id="caption"
                      value={this.state.convertClienteEmail}
                      onChange={(e) =>
                        this.setState({ convertClienteEmail: e.target.value })
                      }
                      size="sm"
                    />
                  </div>
                </Form.Group>
                <Form.Group className="row">
                  <label
                    htmlFor="examplePropostal"
                    className="col-sm-12 col-form-label"
                  >
                    CPF
                  </label>
                  <div className="col-sm-12">
                    <Form.Control
                      type="text"
                      className="form-control"
                      id="caption"
                      value={this.state.convertClienteCPF}
                      onChange={(e) =>
                        this.setState({ convertClienteCPF: e.target.value })
                      }
                      size="sm"
                    />
                  </div>
                </Form.Group>
                <div className="text-right">
                  <button type="submit" className="btn btn-primary mr-2">
                    Converter Prospect Para Cliente
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default ConverteCliente;
