import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import api from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import { Link, withRouter } from "react-router-dom";
import {
  Autocomplete,
  Backdrop,
  Checkbox,
  CircularProgress,
  Radio,
  TextField,
} from "@mui/material";
import { Card } from "../../pages/Backoffice/styles";
import { maskPhoneWithoutDDD } from "../../mask/maskPhoneWithoutDDD";
import isEmail from "validator/lib/isEmail";
import { FaClosedCaptioning } from "react-icons/fa";
import * as S from "../../pages/Backoffice/cadastro.styles";
import { V3Modal, V3ModalConfirm } from "../../styles/themes/components/modal";

import Lottie from "lottie-react";
import Loading from "../../assets/lottie/loading.json";
import {
  V3Autocomplete,
  V3TextCustom,
} from "../../styles/themes/components/input";
import { FaMobileAlt } from "react-icons/fa";
import { V3Text5 } from "../../styles/themes/components/texts";
import { RxTrash } from "react-icons/rx";
import { V3Button } from "../../styles/themes/components/button";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { v3ValidateEmail } from "../../styles/themes/utils/validate";

export class Email extends Component {
  constructor(props) {
    super(props);

    const { codigoCliente } = this.props;

    this.state = {
      codigoCliente: codigoCliente,
      telefones: [],
      tiposTelefones: [],
      error: "",
      isChecked: false,
      open: true,
      erroEmail: false,
      email: "",
      emails: [],
      melhorContatoDesc: "",
      isMobile: false,
      melhorContato: null,
      openConfirm: false,
      deleteId: null,
      whatsapp: "",
      hasSuccess: false,
      successText: "",
      hasError: false,
      errorText: "",
    };
  }

  async componentDidMount() {
    this.setState({ isMobile: window.innerWidth <= 800 });

    await this.obtemEmails();
    this.setState({ open: false });
  }

  async obtemEmails() {
    try {
      const response = await api.get("/api/concash/1.0/cliente-area/emails");
      this.setState({ emails: response.data });
    } catch (err) {
      this.setState({
        hasError: true,
        errorText: "Erro ao buscar emails.",
      });
      console.log(err);
    }
  }

  cancelDelete() {
    this.setState({ openConfirm: false, deleteId: null });
  }

  deleteItem(item) {
    this.setState({ openConfirm: true, deleteId: item });
  }

  handleFormEmail = async (e) => {
    e.preventDefault();
    const { email, melhorContato, erroEmail } = this.state;

    if (!email || email === "" || !v3ValidateEmail(email)) {
      return this.setState({
        hasError: true,
        errorText: "Preencha o campo e-mail corretamente.",
      });
    }
    if (!melhorContato || melhorContato === "") {
      return this.setState({
        hasError: true,
        errorText: "Informe se este é o melhor contato.",
      });
    }
    this.setState({ open: true });
    if (!email || melhorContato === "" || erroEmail) {
    } else {
      let codigoCliente = this.state.codigoCliente;

      api
        .post(`/api/concash/1.0/cliente-area/cadastrar-email`, {
          cceEmail: email,
          principal: parseInt(melhorContato == 2 ? 0 : 1),
          cliente: codigoCliente,
        })
        .then((response) => {
          // Success
          this.setState({ emails: [] });
          this.obtemEmails(codigoCliente);
          this.setState({
            email: "",
            melhorContato: "",
            open: false,
            successText: "E-mail adicionado com sucesso!",
            hasSuccess: true,
          });
        })
        .catch((error) => {
          this.obtemEmails(codigoCliente);
          this.setState({
            email: "",
            melhorContato: "",
            open: false,
            hasError: true,
            errorText: error.response.data
              ? error.response.data
              : "Erro ao adicionar e-mail.",
          });
          this.setState({ emails: [] });
        });
    }
  };

  handleDeletarEmail = async (e) => {
    try {
      this.setState({ open: true, openConfirm: false });
      let codigoCliente = this.state.codigo;
      const response = await api.delete(
        "api/concash/1.0/cliente-area/excluir-email/" + this.state.deleteId
      );
      this.setState({ emails: [] });
      this.obtemEmails(codigoCliente);
      this.setState({
        open: false,
        hasSuccess: true,
        successText: "E-mail exluído com sucesso!",
      });
    } catch (err) {
      this.setState({
        open: false,
        hasError: true,
        errorText: "Erro ao excluir e-mail.",
      });
    }
  };

  toggleCheckboxChange = (e) => {
    this.setState(({ isChecked }) => ({
      isChecked: !isChecked,
    }));
  };

  melhorContOptions = [
    { label: "Sim", id: 1 },
    { label: "Não", id: 2 },
  ];

  handleEmail(texto) {
    if (isEmail(texto)) {
      this.setState({ erroEmail: false });
    } else {
      this.setState({ erroEmail: true });
    }
    this.setState({ email: texto });
  }

  handleDDD(ddd) {
    const numero = ddd.replace(/[^0-9]/g, "");

    numero.length <= 2
      ? this.setState({ ddd: numero })
      : this.setState({ ddd: this.state.ddd });
  }

  render() {
    const { isChecked } = this.state;
    return (
      <>
        {this.state.isMobile ? (
          <>
            <S.ContentBoxMobile>
              <V3Modal
                open={this.state.hasError}
                actionClose={() => this.setState({ hasError: false })}
                title={"Atenção!"}
                text={this.state.errorText}
                type="error"
              />
              <V3Modal
                open={this.state.hasSuccess}
                actionClose={() => this.setState({ hasSuccess: false })}
                title={"Sucesso!"}
                text={this.state.successText}
                type="success"
              />
              <V3ModalConfirm
                open={this.state.openConfirm}
                title="Tem certeza que deseja excluir este e-mail?"
                text="A exclusão é irreversível."
                actionClose={() => this.cancelDelete()}
                actionConfirm={() => this.handleDeletarEmail()}
                textCancel="Não"
                textConfirm="Sim"
              />
              {this.state.emails &&
                this.state.emails.map((email, index) => (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                      minHeight: 120,
                      borderBottom: "1px solid #979797",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        color: "#000",
                      }}
                    >
                      <V3Text5 style={{ color: "#000", fontWeight: "bold" }}>
                        E-mail: {email.cceEmail}
                      </V3Text5>

                      <V3Text5 style={{ color: "#000" }}>
                        Melhor Contato: {email.principal == 1 ? "Sim" : "Não"}
                      </V3Text5>
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={(e) => this.deleteItem(email.id, e)}
                    >
                      <RxTrash color="#000" size={25} />
                    </div>
                  </div>
                ))}
              {this.state.emails !== null && this.state.emails.length !== 0 && (
                <S.Row style={{ marginTop: 35, marginBottom: 20 }}>
                  <V3Text5>ADICIONAR OUTRO E-MAIL</V3Text5>
                </S.Row>
              )}
              {!this.state.emails.length && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    minHeight: 150,
                  }}
                >
                  <MdOutlineAlternateEmail color="#979797" size={40} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <V3Text5>Nenhum e-mail cadastrado</V3Text5>
                    <V3Text5 style={{ fontWeight: "bold" }}>
                      Preencha abaixo para adicionar
                    </V3Text5>
                  </div>
                </div>
              )}
              <S.Row>
                <V3TextCustom
                  size="small"
                  id="email"
                  style={{ width: "100%" }}
                  label="E-mail"
                  value={this.state.email}
                  onChange={(e) => this.handleEmail(e.target.value)}
                  variant="outlined"
                />
              </S.Row>
              <S.Row>
                <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
                  <V3Text5 style={{ color: "#000", marginRight: 15 }}>
                    Melhor Contato?
                  </V3Text5>
                  <Radio
                    checked={this.state.melhorContato === "1"}
                    onChange={() => this.setState({ melhorContato: "1" })}
                    value="1"
                    name="radio-buttons"
                    label="Sim"
                    inputProps={{ "aria-label": "A" }}
                  />
                  <V3Text5 style={{ color: "#000" }}>Sim</V3Text5>
                  <Radio
                    checked={this.state.melhorContato === "2"}
                    onChange={() => this.setState({ melhorContato: "2" })}
                    value="2"
                    name="radio-buttons"
                    label="Não"
                    inputProps={{ "aria-label": "B" }}
                  />
                  <V3Text5 style={{ color: "#000" }}>Não</V3Text5>
                </div>
              </S.Row>
              <S.Row style={{ padding: 5 }}>
                <V3Button
                  onClick={this.handleFormEmail}
                  estilo="primary"
                  width={"100%"}
                  text="Salvar"
                />
              </S.Row>
            </S.ContentBoxMobile>
            <Backdrop
              sx={{
                color: "#16B6C5",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={this.state.open}
            >
              <Lottie animationData={Loading} />
            </Backdrop>
          </>
        ) : (
          <>
            <S.ContentBox>
              <V3Modal
                open={this.state.hasError}
                actionClose={() => this.setState({ hasError: false })}
                title={"Atenção!"}
                text={this.state.errorText}
                type="error"
              />
              <V3Modal
                open={this.state.hasSuccess}
                actionClose={() => this.setState({ hasSuccess: false })}
                title={"Sucesso!"}
                text={this.state.successText}
                type="success"
              />
              <V3ModalConfirm
                open={this.state.openConfirm}
                title="Tem certeza que deseja excluir este e-mail?"
                text="A exclusão é irreversível."
                actionClose={() => this.cancelDelete()}
                actionConfirm={() => this.handleDeletarEmail()}
                textCancel="Não"
                textConfirm="Sim"
              />
              {this.state.emails &&
                this.state.emails.map((email, index) => (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                      minHeight: 120,
                      borderBottom: "1px solid #979797",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        color: "#000",
                      }}
                    >
                      <V3Text5 style={{ color: "#000", fontWeight: "bold" }}>
                        E-mail: {email.cceEmail}
                      </V3Text5>

                      <V3Text5 style={{ color: "#000" }}>
                        Melhor Contato: {email.principal == 1 ? "Sim" : "Não"}
                      </V3Text5>
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={(e) => this.deleteItem(email.id, e)}
                    >
                      <RxTrash color="#000" size={25} />
                    </div>
                  </div>
                ))}
              {this.state.emails !== null && this.state.emails.length !== 0 && (
                <S.Row>
                  <V3Text5>ADICIONAR OUTRO E-MAIL</V3Text5>
                </S.Row>
              )}
              {!this.state.emails.length && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    minHeight: 150,
                  }}
                >
                  <MdOutlineAlternateEmail color="#979797" size={40} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <V3Text5>Nenhum e-mail cadastrado</V3Text5>
                    <V3Text5 style={{ fontWeight: "bold" }}>
                      Preencha abaixo para adicionar
                    </V3Text5>
                  </div>
                </div>
              )}
              <S.Row>
                <V3TextCustom
                  size="small"
                  id="email"
                  style={{ width: "50%" }}
                  label="E-mail"
                  value={this.state.email}
                  onChange={(e) => this.handleEmail(e.target.value)}
                  variant="outlined"
                />
                <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
                  <V3Text5 style={{ color: "#000", marginRight: 15 }}>
                    Melhor Contato?
                  </V3Text5>
                  <Radio
                    checked={this.state.melhorContato === "1"}
                    onChange={() => this.setState({ melhorContato: "1" })}
                    value="1"
                    name="radio-buttons"
                    label="Sim"
                    inputProps={{ "aria-label": "A" }}
                  />
                  <V3Text5 style={{ color: "#000" }}>Sim</V3Text5>
                  <Radio
                    checked={this.state.melhorContato === "2"}
                    onChange={() => this.setState({ melhorContato: "2" })}
                    value="2"
                    name="radio-buttons"
                    label="Não"
                    inputProps={{ "aria-label": "B" }}
                  />
                  <V3Text5 style={{ color: "#000" }}>Não</V3Text5>
                </div>
              </S.Row>
              <S.Row style={{ padding: 5 }}>
                <V3Button
                  onClick={this.handleFormEmail}
                  estilo="primary"
                  width={432}
                  text="Salvar"
                />
              </S.Row>
            </S.ContentBox>
            <Backdrop
              sx={{
                color: "#16B6C5",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={this.state.open}
            >
              <Lottie animationData={Loading} />
            </Backdrop>
          </>
        )}
      </>
    );
  }
}

export default Email;
