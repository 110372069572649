import React, { useState } from "react";
import S from "./Base.module.css";
import LogoConcash from "../../../../assets/images/logo-negativo.svg";
import { useSelector } from "react-redux";
import { MdOutlineMenu, MdOutlineClose } from "react-icons/md";
import { Backdrop } from "@mui/material";
import { SidebarDesktop, SidebarMobile } from "./Sidebar";

export const TopbarDesktop = ({ admImg, estilo }) => {
  if (estilo)
    return (
      <div className={S.topbarContainer}>
        {estilo?.urlAdm && estilo?.urlAdm !== "" && (
          <img src={estilo?.urlAdm} height={35} alt="logo" />
        )}
        <img src={LogoConcash} width={120} alt="logo" />
      </div>
    );
};

export const TopbarMobile = ({ admImg, estilo }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  if (estilo)
    return (
      <>
        <Backdrop
          open={menuOpen}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <div className={S.menuRight}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                height: 50,
              }}
            >
              <img
                src={LogoConcash}
                width={110}
                alt="logo"
                style={{ filter: "grayscale(100%)", opacity: 0.4 }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 40,
                  height: 40,
                }}
                onClick={() => setMenuOpen(false)}
              >
                <MdOutlineClose size={28} />
              </div>
            </div>
            <div className={S.subCol}></div>

            <SidebarMobile estilo={estilo} style={{ zIndex: 1000 }} />
          </div>
        </Backdrop>
        {!menuOpen && (
          <div className={S.topbarContainerMobile}>
            <div style={{ display: "flex", gap: 10 }}>
              {estilo?.urlAdm && estilo?.urlAdm !== "" && (
                <img src={estilo?.urlAdm} height={35} alt="logo" />
              )}
              <img src={LogoConcash} width={90} alt="logo" />
            </div>
            <div onClick={() => setMenuOpen(true)}>
              <MdOutlineMenu size={28} />
            </div>
          </div>
        )}
      </>
    );
};
