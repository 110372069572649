import styled from "styled-components";
import { colors } from "../../styles/colors";

// Desktop

export const ContainerDesktop = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    background: rgb(32,207,167);
    background: linear-gradient(190deg, rgba(32,207,167,1) 0%, rgba(22,137,142,1) 47%, rgba(40,165,136,1) 100%);
    height:100vh;
`;

export const LogosBoxDesktop = styled.div`
    display: flex;
    gap: 20px;
    padding: 10px;
    align-items: flex-end;
`;
export const DefaultBoxDesktop = styled.div`
    display: flex;
    gap: 20px;
    padding: 10px;
`;

export const ButtonBoxDesktop = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    gap: 15px;
`;

export const BoxLoginDesktop = styled.div`
    display: flex;
    flex-direction: column;
    width: 450px;
    height: 400px;
    padding: 20px;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.39);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4.1px);
    -webkit-backdrop-filter: blur(4.1px);
    border: 1px solid rgba(255, 255, 255, 0.73);
`;

// Mobile

export const ContainerMobile = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    background: rgb(32,207,167);
    background: linear-gradient(190deg, rgba(32,207,167,1) 0%, rgba(22,137,142,1) 47%, rgba(40,165,136,1) 100%);
    height: 100vh;
    padding: 0 15px;
    overscroll-behavior: none;
    `;

export const BoxLoginMobile = styled.div`
    display: flex;
    flex-direction: column;
    width: 85vw;
    height:60vh;
    padding: 20px;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.39);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4.1px);
    -webkit-backdrop-filter: blur(4.1px);
    border: 1px solid rgba(255, 255, 255, 0.73);
`;

export const DocBox = styled.div`
    display: flex;
    width: 260px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border: 1px solid #CECECE;
    background-color: #FFF;
    border-radius: 10px;
    margin-top: 10px;
`;