import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";



export class FormUsuario extends Component {



  constructor(props){

    super(props);

    this.state = {
          name: '',
          email: '',
          id:'',
          username: '',
          administradora: '',
          funcao: '',
          password: '',
          labelForm: '',
          labelButton: '',
          administradoras: [],
          funcoes: [],
          error: "",
          action: ""
    };
  }


  async componentDidMount() {

    if(this.props[0].location.state !== undefined){
        const usuario  = this.props[0].location.state.usuario
        this.setState({name: usuario.name,administradora: usuario.administradora,funcao: usuario.funcao,id: usuario.id,username: usuario.username,email: usuario.email});
        this.setState({labelForm: "Editar Usuário",labelButton: "Editar",action: "editar"});
    }else{
        this.setState({labelForm: "Adicionar Usuário",labelButton: "Adicionar",action: "adicionar"});
    }

    try {
          const response = await api.post("api/concash/1.0/administradora/registros", {});
          this.setState({ administradoras: response.data});
    } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter as administradoras."
          });
    }

    try {
          const response = await api.post("api/sistema/1.0/funcao/listar", {});
          this.setState({ funcoes: response.data});
    } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter as funções."
          });
    }

  }

  handleForm = async e => {
    e.preventDefault();
    const { name,
            email,
            password,
            username,
            administradora,
            funcao,
            id,
            action} = this.state;
    if (!name
        || !email
        || !password
        || !username
        || !administradora
        || !action) {
          this.setState({ error: "Confirme todos os dados antes de sair desta tela!",color: '#FF0000' });
    } else {
      try {

        if(action == "editar"){
          
          const response = await api.put("api/sistema/1.0/usuario/"+action, {
              name,email,password,username,id,funcao,administradora
            });
           this.props.history.push("/usuario");
        }else{
          const response = await api.post("api/sistema/1.0/usuario/"+action, {
              name,email,password,username,administradora,funcao
            });
          this.props.history.push("/usuario");
        }

      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o processamento da requisição."
        });
      }
    }
  };

  render() {


    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> {this.state.labelForm} </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>

        <div className="row">

          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"></h4>
                {this.state.error && <p className="card-description">{this.state.error}</p>}
                <form className="forms-sample" onSubmit={this.handleForm}>

                <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Nome</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="name" value={this.state.name}  onChange={e => this.setState({ name: e.target.value })} placeholder="Nome" size="sm" />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">E-mail</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="email" value={this.state.email} placeholder="E-mail"  onChange={e => this.setState({ email: e.target.value })} size="sm" />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Username</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="username" value={this.state.username} placeholder="Username" size="sm"  onChange={e => this.setState({ username: e.target.value })} />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Senha</label>
                        <div className="col-sm-7">
                        <Form.Control type="password" id="password" value={this.state.password} placeholder="Senha" size="sm"  onChange={e => this.setState({ password: e.target.value })} />
                        </div>
                      </Form.Group>
                      <Form.Group className="row" >
                        <label className="col-sm-5 col-form-label">Administradora</label>
                        <div className="col-sm-7">
                              <select className="form-control" value={this.state.administradora} onChange={e => this.setState({ administradora: e.target.value })} id="administradora">
                                <option value="">Selecione</option>
                                {this.state.administradoras.map((ad,index) => (
                                  <option value={ad.codigo} key={index}>{ad.nome}</option>
                                ))}
                              </select>
                        </div>
                      </Form.Group>
                      <Form.Group className="row" >
                        <label className="col-sm-5 col-form-label">Função</label>
                        <div className="col-sm-7">
                              <select className="form-control" value={this.state.funcao} onChange={e => this.setState({ funcao: e.target.value })} id="administradora">
                                <option value="">Selecione</option>
                                {this.state.funcoes.map((func,index) => (
                                  <option value={func.codigo} key={index}>{func.funcao}</option>
                                ))}
                              </select>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="text-right">
                    <button type="submit" className="btn btn-primary mr-2">{this.state.labelButton}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
         </div>
       </div>
    )
  }
}

export default withRouter(FormUsuario)
