import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import api from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import { Link, withRouter } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import isEmail from "validator/lib/isEmail";
import { cpfCnpj } from "../../mask/cpfcnpj";
import {
  DateTimePicker,
  DesktopDatePicker,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Card } from "../../pages/Backoffice/styles";
import { cpf } from "cpf-cnpj-validator";
import moment from "moment";
import { IoIosArrowDown } from "react-icons/io";
import { BsCheckCircleFill } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import { AiOutlineFileZip } from "react-icons/ai";
import { V3Modal, V3ModalConfirm } from "../../styles/themes/components/modal";
import * as S from "../../pages/Backoffice/cadastro.styles";

import Lottie from "lottie-react";
import Loading from "../../assets/lottie/loading.json";
import {
  V3Autocomplete,
  V3TextCustom,
} from "../../styles/themes/components/input";
import { FaMobileAlt } from "react-icons/fa";
import { V3Text5 } from "../../styles/themes/components/texts";
import { RxTrash } from "react-icons/rx";
import { V3Button } from "../../styles/themes/components/button";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { RiBankLine } from "react-icons/ri";
import { v3MaskCPF } from "../../styles/themes/utils/format";

export class Representante extends Component {
  constructor(props) {
    super(props);

    const { cliente } = this.props;
    console.log(cliente);
    this.state = {
      codigoCliente: cliente.id,
      obj_cliente: cliente,
      representantes: [],
      open: true,
      error: "",
      cpf: "",
      rgCpf: "",
      comprovanteBancario: "",
      comprovante: "",

      hasErrorCpfCnpj: false,
      hasErrorEmail: false,
      dataNascimento: null,
      selectedRep: "",
      doc1: "",
      doc2: "",
      doc3: "",
      isMobile: false,
      melhorContato: null,
      openConfirm: false,
      deleteId: null,
      whatsapp: "",
      hasSuccess: false,
      successText: "",
      hasError: false,
      errorText: "",
    };
  }

  async componentDidMount() {
    await this.obtemRepresentantes(this.state.codigoCliente);
    this.setState({ open: false });
    const tamanho = window.innerWidth;

    this.setState({ isMobile: tamanho <= 800 });
  }

  async obtemRepresentantes(codigo) {
    try {
      const response = await api.get(
        "/api/concash/1.0/cliente-area/representantes?id=" +
          this.state.codigoCliente
      );
      this.setState({ representantes: response.data });
    } catch (err) {
      this.setState({ open: false });
      Swal.fire({
        icon: "error",
        title: "Atenção",
        text: err.response.data,
      });
    }
  }

  handleEmail(texto) {
    if (isEmail(texto)) {
      this.setState({ hasErrorEmail: false });
    } else {
      this.setState({ hasErrorEmail: true });
    }
    this.setState({ email: texto });
  }

  handleSelectRep(rep) {
    this.handleGetDocs(rep);
    if (rep === this.state.selectedRep) {
      this.setState({ selectedRep: "" });
    } else {
      this.setState({
        selectedRep: rep,
      });
    }
  }

  async handleGetDocs(id) {
    this.setState({ open: true });
    const documentsRepres = await api
      .get(
        `/api/concash/1.0/cliente-area/documentos-representante?id=${this.state.codigoCliente}`
      )
      .catch((error) => {
        this.setState({
          hasError: true,
          errorText: "Erro ao buscar documentos.",
        });
        console.log(error);
      });

    documentsRepres.data.map(
      ({
        representante,
        primeiroDocumento,
        segundoDocumento,
        terceiroDocumento,
        quartoDocumento,
      }) => {
        console.log("representante " + representante);
        if (representante === id) {
          this.setState({
            doc1: primeiroDocumento,
            doc2: segundoDocumento,
            doc3: terceiroDocumento,
            open: false,
          });
        }
      }
    );
  }

  handleCpf(text) {
    if (text.length >= 11) {
      if (text.length <= 11 && text.length > 0) {
        if (cpf.isValid(text)) {
          this.setState({ hasErrorCpfCnpj: false });
        } else {
          this.setState({ hasErrorCpfCnpj: true });
        }
      } else {
        if (cpf.isValid(text)) {
          this.setState({ hasErrorCpfCnpj: false });
        } else {
          this.setState({ hasErrorCpfCnpj: true });
        }
      }
    } else {
      this.setState({ hasErrorCpfCnpj: true });
    }
    const newdata = cpfCnpj(text);
    this.setState({ cpf: newdata });
  }

  handleFormRepresentante = async (e) => {
    e.preventDefault();
    console.log(this.state);
    const {
      nome,
      dataNascimento,
      cpf,
      email,
      codigoCliente,
      numeroDocumento,
      hasErrorEmail,
      hasErrorCpfCnpj,
    } = this.state;
    if (
      !nome ||
      !dataNascimento ||
      !cpf ||
      !email ||
      !numeroDocumento ||
      hasErrorEmail ||
      hasErrorCpfCnpj
    ) {
      Swal.fire({
        icon: "error",
        title: "Atenção",
        text: "Preencha os campos obrigatórios para continuar!",
      });
    } else {
      try {
        this.setState({ open: true });
        let codigoCliente = this.state.codigoCliente;
        const newCpfCnpj = cpf.replace(/[^0-9]/g, "");
        const response = await api.post(
          "/api/concash/1.0/cliente-area/cadastrar-representante",
          {
            cpf: newCpfCnpj,
            dataNascimento: moment(dataNascimento).format("yyyy-MM-DD"),
            email: email,
            idCliente: codigoCliente,
            nome: nome,
            numeroDocumento: numeroDocumento,
          }
        );

        this.obtemRepresentantes();
        Swal.fire("Operação realizada com sucesso!");
        this.setState({
          cpf: "",
          dataNascimento: "",
          email: "",
          nome: "",
          numeroDocumento: "",
          open: false,
          hasErrorCpfCnpj: false,
          hasErrorEmail: "",
        });
      } catch (err) {
        this.setState({ open: false });
        Swal.fire({
          icon: "error",
          title: "Atenção",
          text: err.response.data,
        });
      }
    }
  };

  handleData = async function (data) {
    this.setState({ dataNascimento: data.$d });
  };

  handleDeletarRepresentante = async (id, e) => {
    var classe = this;
    Swal.fire({
      title: "Você tem certeza que deseja excluir esse representante?",
      text: "Após exclusão não será possivel a reversão",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
    }).then((result) => {
      if (result.value) {
        classe.handleDeletarRepresentanteEfetivar(id, e);
      }
    });
  };

  handleDeletarRepresentanteEfetivar = async (codigo, e) => {
    e.preventDefault();
    if (!codigo) {
      this.setState({ error: "Código indefinido!" });
    } else {
      try {
        this.setState({ open: true });
        let codigoCliente = this.state.codigoCliente;
        const response = await api.delete(
          "/api/concash/1.0/cliente-area/excluir-representante/" + codigo
        );
        this.obtemRepresentantes(codigoCliente);
        this.setState({ open: false });
        Swal.fire("Operação realizada com sucesso!");
      } catch (err) {
        this.setState({ open: false });
        Swal.fire({
          icon: "error",
          title: "Atenção",
          text: err.response.data,
        });
      }
    }
  };

  handleDocRG = async (e) => {
    e.preventDefault();
    this.setState({ open: true });
    const { rgCpf } = this.state;
    if (!rgCpf) {
      console.log(this.state);
      Swal.fire({
        icon: "error",
        title: "Atenção",
        text: "Selecione o arquivo com RG/CPF ou CNH para continuar!",
      });
      this.setState({ open: false });
    } else {
      try {
        const formData = new FormData();
        formData.append("documento", rgCpf);
        formData.append("tipo", 1);
        formData.append("idRepresentante", this.state.selectedRep);

        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const response = await api.post(
          `/api/concash/1.0/cliente-area/anexar-documento-representante/${this.state.selectedRep}`,
          formData,
          config
        );
        if (response.status === 200) {
          Swal.fire("Operação realizada com sucesso!");
          this.setState({
            rgCpf: "",
            open: false,
            fileInputKey: Date.now(),
          });
          e.target.value = null;
        } else {
          Swal.fire(response.data);
          this.setState({ open: false });
        }
      } catch (err) {
        Swal.fire(err.response.data);
        this.setState({ open: false });
      }
    }
  };
  handleFormComprovante = async (e) => {
    e.preventDefault();
    this.setState({ open: true });
    const { comprovante } = this.state;
    if (!comprovante) {
      console.log(this.state);
      Swal.fire({
        icon: "error",
        title: "Atenção",
        text: "Selecione o arquivo com o comprovante de residência para continuar!",
      });
      this.setState({ open: false });
    } else {
      try {
        const formData = new FormData();
        formData.append("documento", comprovante);
        formData.append("tipo", 2);
        formData.append("idRepresentante", this.state.selectedRep);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const response = await api.post(
          `/api/concash/1.0/cliente-area/anexar-documento-representante/${this.state.selectedRep}`,
          formData,
          config
        );
        if (response.status === 200) {
          Swal.fire("Operação realizada com sucesso!");
          this.setState({
            comprovante: "",
            fileInputKey2: Date.now(),
          });

          this.setState({ open: false });
        } else {
          Swal.fire(response.data);
          this.setState({ open: false });
        }
      } catch (err) {
        Swal.fire(err.response.data);
        this.setState({ open: false });
      }
    }
  };

  handleFormComprovanteBancario = async (e) => {
    e.preventDefault();
    this.setState({ open: true });
    const { comprovanteBancario } = this.state;
    if (!comprovanteBancario) {
      Swal.fire({
        icon: "error",
        title: "Atenção",
        text: "Selecione o arquivo com o comprovante de residência para continuar!",
      });
      this.setState({ open: false });
    } else {
      try {
        const formData = new FormData();
        formData.append("documento", comprovanteBancario);
        formData.append("tipo", 3);
        formData.append("idRepresentante", this.state.selectedRep);

        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const response = await api.post(
          `/api/concash/1.0/cliente-area/anexar-documento-representante/${this.state.selectedRep}`,
          formData,
          config
        );
        if (response.status === 200) {
          Swal.fire("Operação realizada com sucesso!");
          this.setState({
            comprovante: "",
            fileInputKey2: Date.now(),
          });

          this.setState({ open: false });
          this.handleClear();
        } else {
          Swal.fire(response.data);
          this.setState({ open: false });
        }
      } catch (err) {
        Swal.fire(err.response.data);
        this.setState({ open: false });
      }
    }
  };

  render() {
    return (
      <>
        <V3Modal
          open={this.state.hasError}
          actionClose={() => this.setState({ hasError: false })}
          title={"Atenção!"}
          text={this.state.errorText}
          type="error"
        />
        <V3Modal
          open={this.state.hasSuccess}
          actionClose={() => this.setState({ hasSuccess: false })}
          title={"Sucesso!"}
          text={this.state.successText}
          type="success"
        />
        <V3ModalConfirm
          open={this.state.openConfirm}
          title="Tem certeza que deseja excluir este e-mail?"
          text="A exclusão é irreversível."
          actionClose={() => this.cancelDelete()}
          actionConfirm={() => this.handleDeletarBanco()}
          textCancel="Não"
          textConfirm="Sim"
        />
        <Backdrop
          sx={{
            color: "#16B6C5",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={this.state.open}
        >
          <Lottie animationData={Loading} />
        </Backdrop>
        {this.state.isMobile ? (
          <>
            <S.ContentBoxMobile>
              {this.state.representantes &&
                this.state.representantes.map((rep, index) => (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                      minHeight: 120,
                      borderBottom: "1px solid #979797",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        color: "#000",
                      }}
                    >
                      <V3Text5 style={{ color: "#000", fontWeight: "bold" }}>
                        {/* {this.localizarBanco(recebimento.banco)} */}
                        {rep.nome}
                      </V3Text5>

                      <V3Text5 style={{ color: "#000" }}>
                        CPF: {v3MaskCPF(`${rep.cpf}`)}
                      </V3Text5>
                      <V3Text5 style={{ color: "#000" }}>
                        Data de Nascimento:{" "}
                        {moment(rep.dataNascimento).format("DD/MM/YYYY")}
                      </V3Text5>
                      <V3Text5 style={{ color: "#000" }}>
                        E-mail: {rep.email}
                      </V3Text5>
                      <V3Text5 style={{ color: "#000" }}>
                        RG / RNE: {rep.numeroDocumento}
                      </V3Text5>
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      // onClick={(e) => this.deleteItem(rep.id, e)}
                    >
                      <RxTrash color="#000" size={25} />
                    </div>
                  </div>
                ))}
              {this.state.representantes !== null &&
                this.state.representantes.length !== 0 && (
                  <S.Row style={{ marginBottom: 20, marginTop: 35 }}>
                    <V3Text5>ADICIONAR OUTRO REPRESENTANTE</V3Text5>
                  </S.Row>
                )}
              {!this.state.representantes.length && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    minHeight: 150,
                  }}
                >
                  <RiBankLine color="#979797" size={40} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <V3Text5>Nenhum banco cadastrado</V3Text5>
                    <V3Text5 style={{ fontWeight: "bold" }}>
                      Preencha abaixo para adicionar
                    </V3Text5>
                  </div>
                </div>
              )}

              <S.Row>
                <V3TextCustom
                  id="nome"
                  size="small"
                  label="Nome"
                  style={{ display: "flex", flex: 1, width: "100%" }}
                  value={this.state.nome}
                  onChange={(e) => this.setState({ nome: e.target.value })}
                  variant="outlined"
                />
              </S.Row>
              <S.Row>
                <V3TextCustom
                  style={{ display: "flex", flex: 1, width: "100%" }}
                  id="cpf"
                  label="CPF "
                  size="small"
                  inputProps={{ maxLength: 14 }}
                  value={this.state.cpf}
                  onChange={(e) => this.handleCpf(e.target.value)}
                  variant="outlined"
                />
              </S.Row>
              <S.Row>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  style={{ display: "flex", flex: 1, width: "100%" }}
                >
                  <MobileDatePicker
                    label="Data de Nascimento"
                    inputFormat="DD/MM/YYYY"
                    style={{ display: "flex", flex: 1, width: "100%" }}
                    labelForm="ptBR"
                    sx={{ width: "100%" }}
                    value={
                      this.state.dataNascimento
                        ? this.state.dataNascimento
                        : null
                    }
                    onChange={(newValue) => {
                      this.handleData(newValue);
                    }}
                    renderInput={(params) => (
                      <V3TextCustom
                        size="small"
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "dd/mm/aaaa",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </S.Row>
              <S.Row style={{ marginTop: 15 }}>
                <V3TextCustom
                  className="input-form-miu"
                  id="email"
                  label="E-mail"
                  size="small"
                  value={this.state.email}
                  onChange={(e) => this.handleEmail(e.target.value)}
                  variant="outlined"
                />
              </S.Row>
              <S.Row>
                <V3TextCustom
                  className="input-form-miu"
                  id="numeroDocumento"
                  label="RG/RNE "
                  size="small"
                  inputProps={{ maxLength: 11 }}
                  value={this.state.numeroDocumento}
                  onChange={(e) =>
                    this.setState({ numeroDocumento: e.target.value })
                  }
                  variant="outlined"
                />
              </S.Row>
              <S.Row style={{ marginTop: 15 }}>
                <V3Button
                  onClick={this.handleFormRepresentante}
                  estilo="primary"
                  width={"100%"}
                  text="Adicionar Representante"
                />
              </S.Row>
            </S.ContentBoxMobile>
          </>
        ) : (
          <>
            <S.ContentBox>
              {this.state.representantes &&
                this.state.representantes.map((rep, index) => (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                      minHeight: 120,
                      borderBottom: "1px solid #979797",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        color: "#000",
                      }}
                    >
                      <V3Text5 style={{ color: "#000", fontWeight: "bold" }}>
                        {/* {this.localizarBanco(recebimento.banco)} */}
                        {rep.nome}
                      </V3Text5>

                      <V3Text5 style={{ color: "#000" }}>
                        CPF: {v3MaskCPF(`${rep.cpf}`)}
                      </V3Text5>
                      <V3Text5 style={{ color: "#000" }}>
                        Data de Nascimento:{" "}
                        {moment(rep.dataNascimento).format("DD/MM/YYYY")}
                      </V3Text5>
                      <V3Text5 style={{ color: "#000" }}>
                        E-mail: {rep.email}
                      </V3Text5>
                      <V3Text5 style={{ color: "#000" }}>
                        RG / RNE: {rep.numeroDocumento}
                      </V3Text5>
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      // onClick={(e) => this.deleteItem(rep.id, e)}
                    >
                      <RxTrash color="#000" size={25} />
                    </div>
                  </div>
                ))}
              {this.state.representantes !== null &&
                this.state.representantes.length !== 0 && (
                  <S.Row>
                    <V3Text5>ADICIONAR OUTRO REPRESENTANTE</V3Text5>
                  </S.Row>
                )}
              {!this.state.representantes.length && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    minHeight: 150,
                  }}
                >
                  <RiBankLine color="#979797" size={40} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <V3Text5>Nenhum banco cadastrado</V3Text5>
                    <V3Text5 style={{ fontWeight: "bold" }}>
                      Preencha abaixo para adicionar
                    </V3Text5>
                  </div>
                </div>
              )}

              <S.Row>
                <V3TextCustom
                  className="input-form-miu"
                  id="nome"
                  size="small"
                  label="Nome"
                  value={this.state.nome}
                  onChange={(e) => this.setState({ nome: e.target.value })}
                  variant="outlined"
                />
                <V3TextCustom
                  className="input-form-miu"
                  id="cpf"
                  label="CPF "
                  size="small"
                  inputProps={{ maxLength: 14 }}
                  value={this.state.cpf}
                  onChange={(e) => this.handleCpf(e.target.value)}
                  variant="outlined"
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Data de Nascimento"
                    inputFormat="DD/MM/YYYY"
                    labelForm="ptBR"
                    value={
                      this.state.dataNascimento
                        ? this.state.dataNascimento
                        : null
                    }
                    onChange={(newValue) => {
                      this.handleData(newValue);
                    }}
                    renderInput={(params) => (
                      <V3TextCustom
                        size="small"
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "dd/mm/aaaa",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </S.Row>
              <S.Row style={{ marginTop: 15 }}>
                <V3TextCustom
                  className="input-form-miu"
                  id="email"
                  label="E-mail"
                  size="small"
                  value={this.state.email}
                  onChange={(e) => this.handleEmail(e.target.value)}
                  variant="outlined"
                />
                <V3TextCustom
                  className="input-form-miu"
                  id="numeroDocumento"
                  label="RG/RNE "
                  size="small"
                  inputProps={{ maxLength: 11 }}
                  value={this.state.numeroDocumento}
                  onChange={(e) =>
                    this.setState({ numeroDocumento: e.target.value })
                  }
                  variant="outlined"
                />
              </S.Row>
              <S.Row style={{ marginTop: 15 }}>
                <V3Button
                  onClick={this.handleFormRepresentante}
                  estilo="primary"
                  width={"49%"}
                  text="Adicionar Representante"
                />
              </S.Row>
            </S.ContentBox>
          </>
        )}
      </>
    );
  }
}

export default Representante;
