import React, { Component, Fragment } from "react";
// import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import api from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import { Link, withRouter } from "react-router-dom";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  Radio,
  TextField,
} from "@mui/material";
import { Card } from "../../pages/Backoffice/styles";
import { cpfCnpj } from "../../mask/cpfcnpj";
import { maskPhone } from "../../mask/maskPhone";
import { cpf } from "cpf-cnpj-validator";
import isEmail from "validator/lib/isEmail";
import { V3Modal, V3ModalConfirm } from "../../styles/themes/components/modal";
import * as S from "../../pages/Backoffice/cadastro.styles";

import Lottie from "lottie-react";
import Loading from "../../assets/lottie/loading.json";
import {
  V3Autocomplete,
  V3TextCustom,
} from "../../styles/themes/components/input";
import { FaMobileAlt } from "react-icons/fa";
import { V3Text5 } from "../../styles/themes/components/texts";
import { RxTrash } from "react-icons/rx";
import { V3Button } from "../../styles/themes/components/button";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { RiBankLine } from "react-icons/ri";
export class Banco extends Component {
  constructor(props) {
    super(props);

    const { codigoCliente } = this.props;

    this.state = {
      codigoCliente: codigoCliente,
      bancos: [],
      bancosSelec: [],
      recebimentos: [],
      error: "",
      open: true,
      bancoDesc: "",
      tipoContaDesc: "",
      padraoDesc: "",
      tipoChaveDesc: "",
      chavePix: "",
      isErrorChave: false,
      isMobile: false,
      melhorContato: null,
      openConfirm: false,
      deleteId: null,
      whatsapp: "",
      hasSuccess: false,
      successText: "",
      hasError: false,
      errorText: "",
    };
  }

  async componentDidMount() {
    this.setState({ isMobile: window.innerWidth <= 800 });

    await this.obtemBancos();
    await this.obtemRecebimentos(this.state.codigoCliente);
    this.setState({ open: false });
  }

  async obtemBancos() {
    try {
      const response = await api.post("api/concash/1.0/cliente/bancos", {
        codigo: this.state.codigoCliente,
      });
      var newResp = [];
      response.data.map(function (item) {
        newResp.push({ id: item.id, label: item.nome });
      });
      this.setState({ bancosSelec: newResp });
      this.setState({ bancos: response.data });
    } catch (err) {
      this.setState({ hasError: true, errorText: "Erro ao buscar bancos." });
    }
  }

  localizarBanco(b) {
    this.state.bancos.find((i) => {
      if (i.numero == b) {
        return i;
      }
    });
  }

  async obtemRecebimentos(codigo) {
    try {
      const response = await api.get(
        "api/concash/1.0/cliente-area/recebimento"
      );

      this.setState({ recebimentos: response.data });
    } catch (err) {
      this.setState({
        hasError: true,
        errorText: "Erro ao buscar recebimentos.",
      });
    }
  }

  handleFormBanco = async (e) => {
    e.preventDefault();
    const {
      banco,
      agencia,
      padrao,
      conta,
      digito,
      digitoAgencia,
      digitoConta,
      tipoConta,
      tipoChave,
      chavePix,
      isErrorChave,
    } = this.state;

    if (!banco || banco === "") {
      return this.setState({
        hasError: true,
        errorText: "Selecione o banco.",
      });
    }

    if (!agencia || agencia === "") {
      return this.setState({
        hasError: true,
        errorText: "Preencha a agência.",
      });
    }

    if (!conta || conta === "") {
      return this.setState({
        hasError: true,
        errorText: "Preencha a conta.",
      });
    }

    if (!digito || digito === "") {
      return this.setState({
        hasError: true,
        errorText: "Preencha o dígito da conta.",
      });
    }

    if (!tipoConta || tipoConta === "") {
      return this.setState({
        hasError: true,
        errorText: "Selecione o tipo da conta.",
      });
    }

    if (!tipoChave || tipoChave === "") {
      return this.setState({
        hasError: true,
        errorText: "Informe o tipo da sua chave PIX.",
      });
    }

    if (!chavePix || chavePix === "") {
      return this.setState({
        hasError: true,
        errorText: "Informe a sua chave PIX.",
      });
    }

    if (!banco || !agencia || !conta || !digito || !tipoConta) {
      return this.setState({
        hasError: true,
        errorText: "Preencha os campos obrigatórios",
      });
    }

    if (agencia.length > 4) {
      return this.setState({
        hasError: true,
        errorText: "O campo agência pode ter apenas 4 números!",
      });
    } else if (isErrorChave) {
      return this.setState({
        hasError: true,
        errorText: "Verifique sua Chave PIX!",
      });
    } else {
      try {
        let codigoCliente = this.state.codigoCliente;
        let tipo = tipoConta === 3 ? 0 : tipoConta;
        this.setState({ open: true });
        const response = await api.post(
          "api/concash/1.0/cliente-area/cadastrar-recebimento",
          {
            agencia: agencia,
            banco: banco,
            conta: conta,
            digitoConta: digito,
            principal: 1,
            tipoConta: tipo,
            cliente: codigoCliente,
            pix: chavePix,
            tipoPix: tipoChave,
          }
        );
        this.setState({ recebimentos: [] });
        this.obtemRecebimentos(codigoCliente);
        this.setState({
          banco: "",
          agencia: "",
          padrao: "",
          conta: "",
          digito: "",
          digitoAgencia: "",
          digitoConta: "",
          tipoConta: "",
          open: false,
          chavePix: "",
          tipoChaveDesc: "",
          tipoChave: "",
          hasSuccess: true,
          successText: "Banco cadastrado com sucesso!",
        });
      } catch (err) {
        this.setState({ open: false, recebimentos: [] });
        this.setState({ hasError: true, errorText: "Erro ao salvar banco." });
      }
    }
  };

  handleDeletarBanco = async (e) => {
    this.setState({ open: true, openConfirm: false });
    let codigoCliente = this.state.codigo;
    await api
      .delete(
        "api/concash/1.0/cliente-area/excluir-recebimento/" +
          this.state.deleteId
      )
      .then((d) => {
        this.setState({ emails: [] });
        this.obtemEmails(codigoCliente);
        this.setState({
          open: false,
          hasSuccess: true,
          successText: "Banco exluído com sucesso!",
        });
      })
      .catch((error) => {
        this.setState({
          hasError: true,
          errorText: "Erro ao excluir recebimento.",
        });
        console.log(error);
      });
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    this.setState({
      banco: selectedOption.value,
    });
  };

  padraoOptions = [
    { label: "Sim", id: 1 },
    { label: "Não", id: 2 },
  ];

  tipoOptions = [
    { label: "Poupança", id: 3 },
    { label: "Corrente", id: 1 },
  ];

  handleAgencia(ag) {
    const agencia = ag.replace(/[^0-9]/g, "");
    if (agencia.length <= 4) {
      this.setState({ agencia: agencia });
    }
  }

  handleConta(con) {
    const conta = con.replace(/[^0-9]/g, "");
    if (con.length <= 15) {
      this.setState({ conta: conta });
    } else {
      this.setState({ conta: this.state.conta });
    }
  }

  handleDigito(con) {
    const digito = con.replace(/[^0-9]/g, "");
    if (digito.length <= 1) {
      this.setState({ digito: digito });
    }
  }

  handleTipo(tipo) {
    this.setState({
      tipoConta: tipo.label ? tipo.id : null,
      tipoContaDesc: tipo.label ? tipo.label : null,
    });
  }

  handleChave(chave) {
    if (this.state.tipoChave == 1) {
      this.setState({
        chavePix: cpfCnpj(chave),
        isErrorChave: !cpf.isValid(chave),
      });
    } else if (this.state.tipoChave == 2) {
      this.setState({ chavePix: chave, isErrorChave: !isEmail(chave) });
    } else if (this.state.tipoChave == 3) {
      this.setState({
        chavePix: maskPhone(chave),
        isErrorChave: chave.length == 14 || chave.length == 15 ? false : true,
      });
    } else if (this.state.tipoChave == 4) {
      this.setState({ chavePix: chave });
    } else {
      Swal.fire({
        icon: "error",
        title: "Atenção",
        text: "Selecione o Tipo Chave",
      });
    }
  }

  tipoPixOptions = [
    { label: "CPF OU CNPJ", id: 1 },
    { label: "E-MAIL", id: 2 },
    { label: "CELULAR", id: 3 },
    { label: "CHAVE ALEATÓRIA", id: 4 },
  ];

  tipoChave = {
    1: "CPF OU CNPJ",
    2: "E-MAIL",
    3: "CELULAR",
    4: "CHAVE ALEATÓRIA",
  };

  cancelDelete() {
    this.setState({ openConfirm: false, deleteId: null });
  }

  deleteItem(item) {
    this.setState({ openConfirm: true, deleteId: item });
  }

  render() {
    const { selectedOption } = this.state;
    return (
      <>
        <V3Modal
          open={this.state.hasError}
          actionClose={() => this.setState({ hasError: false })}
          title={"Atenção!"}
          text={this.state.errorText}
          type="error"
        />
        <V3Modal
          open={this.state.hasSuccess}
          actionClose={() => this.setState({ hasSuccess: false })}
          title={"Sucesso!"}
          text={this.state.successText}
          type="success"
        />
        <V3ModalConfirm
          open={this.state.openConfirm}
          title="Tem certeza que deseja excluir esta conta?"
          text="A exclusão é irreversível."
          actionClose={() => this.cancelDelete()}
          actionConfirm={() => this.handleDeletarBanco()}
          textCancel="Não"
          textConfirm="Sim"
        />
        {this.state.isMobile ? (
          <>
            <S.ContentBoxMobile>
              {this.state.recebimentos &&
                this.state.recebimentos.map((recebimento, index) => (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                      minHeight: 120,
                      borderBottom: "1px solid #979797",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        color: "#000",
                      }}
                    >
                      <V3Text5 style={{ color: "#000", fontWeight: "bold" }}>
                        {recebimento.banco}-{recebimento.nomeBanco}
                      </V3Text5>
                      <V3Text5 style={{ color: "#000" }}>
                        Tipo de Conta:{" "}
                        {recebimento.tipoConta === "3"
                          ? "Poupança"
                          : "Corrente"}
                      </V3Text5>
                      <V3Text5 style={{ color: "#000" }}>
                        Agência: {recebimento.agencia}
                      </V3Text5>
                      <V3Text5 style={{ color: "#000" }}>
                        Conta: {recebimento.conta}-{recebimento.digitoConta}
                      </V3Text5>
                      <V3Text5 style={{ color: "#000" }}>
                        Tipo de Chave: {this.tipoChave[recebimento.tipoPix]}
                      </V3Text5>
                      <V3Text5 style={{ color: "#000" }}>
                        Chave PIX: {recebimento.pix}
                      </V3Text5>
                      {recebimento.principal !== 0 && (
                        <V3Text5 style={{ color: "#000", fontWeight: "bold" }}>
                          Conta Principal
                        </V3Text5>
                      )}
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={(e) => this.deleteItem(recebimento.id, e)}
                    >
                      <RxTrash color="#000" size={25} />
                    </div>
                  </div>
                ))}

              {!this.state.recebimentos.length && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    minHeight: 150,
                  }}
                >
                  <RiBankLine color="#979797" size={40} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <V3Text5>Nenhum banco cadastrado</V3Text5>
                    <V3Text5 style={{ fontWeight: "bold" }}>
                      Preencha abaixo para adicionar
                    </V3Text5>
                  </div>
                </div>
              )}

              {!this.state.recebimentos.length && (
                <>
                  <S.Row>
                    <V3Autocomplete
                      size="small"
                      id="banco"
                      options={this.state.bancosSelec}
                      onChange={(e, v) => {
                        if (!v) {
                          this.setState({
                            banco: null,
                            bancoDesc: null,
                          });
                        } else {
                          this.setState({
                            banco: v ? v.id : null,
                            bancoDesc: v ? v.label : null,
                          });
                        }
                      }}
                      value={this.state.banco ? this.state.bancoDesc : null}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <V3TextCustom {...params} label="Banco" />
                      )}
                    />
                  </S.Row>
                  <S.Row>
                    <V3TextCustom
                      className="input-form-miu"
                      size="small"
                      id="agencia"
                      label="Agência"
                      value={this.state.agencia}
                      onChange={(e) => this.handleAgencia(e.target.value)}
                      variant="outlined"
                    />
                  </S.Row>
                  <S.Row>
                    <div style={{ display: "flex", padding: "0 3px" }}>
                      <V3TextCustom
                        className="input-form-miu"
                        size="small"
                        id="conta"
                        label="Conta"
                        value={this.state.conta}
                        onChange={(e) => this.handleConta(e.target.value)}
                        variant="outlined"
                      />

                      <V3TextCustom
                        size="small"
                        id="digito"
                        label="Digito"
                        style={{ maxWidth: 70, marginLeft: 15 }}
                        value={this.state.digito}
                        onChange={(e) => this.handleDigito(e.target.value)}
                        variant="outlined"
                      />
                    </div>
                  </S.Row>
                  <S.Row>
                    <div style={{ display: "flex", flex: 1 }}>
                      <V3Autocomplete
                        size="small"
                        disablePortal
                        id="tipoConta"
                        style={{ minWidth: "100%" }}
                        options={this.tipoOptions}
                        value={
                          this.state.tipoConta ? this.state.tipoContaDesc : null
                        }
                        onChange={(e, v) => this.handleTipo(v)}
                        renderInput={(params) => (
                          <V3TextCustom {...params} label="Tipo de conta" />
                        )}
                      />
                    </div>
                  </S.Row>

                  <S.Row>
                    <Autocomplete
                      size="small"
                      id="tipoChave"
                      style={{ width: "100%" }}
                      options={this.tipoPixOptions}
                      onChange={(e, v) =>
                        this.setState({
                          tipoChave: v ? v.id : null,
                          tipoChaveDesc: v ? v.label : null,
                          chavePix: "",
                          isErrorChave: false,
                        })
                      }
                      value={
                        this.state.tipoChave ? this.state.tipoChaveDesc : null
                      }
                      renderInput={(params) => (
                        <V3TextCustom {...params} label="Tipo de chave" />
                      )}
                    />
                  </S.Row>
                  <S.Row>
                    <V3TextCustom
                      size="small"
                      error={this.state.isErrorChave}
                      style={{ width: "100%" }}
                      id="chavePix"
                      label="Chave Pix"
                      value={this.state.chavePix}
                      onChange={(e) => this.handleChave(e.target.value)}
                      variant="outlined"
                    />
                  </S.Row>
                  <S.Row>
                    <V3Button
                      onClick={this.handleFormBanco}
                      estilo="primary"
                      width={"100%"}
                      text="Adicionar Banco"
                    />
                  </S.Row>
                </>
              )}

              <Backdrop
                sx={{
                  color: "#16B6C5",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={this.state.open}
              >
                <Lottie animationData={Loading} />
              </Backdrop>
            </S.ContentBoxMobile>
          </>
        ) : (
          <>
            <S.ContentBox>
              {this.state.recebimentos &&
                this.state.recebimentos.map((recebimento, index) => (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                      minHeight: 120,
                      borderBottom: "1px solid #979797",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        color: "#000",
                      }}
                    >
                      <V3Text5 style={{ color: "#000", fontWeight: "bold" }}>
                        {recebimento.banco}-{recebimento.nomeBanco}
                      </V3Text5>

                      <V3Text5 style={{ color: "#000" }}>
                        Tipo de Conta:{" "}
                        {recebimento.tipoConta === "3"
                          ? "Poupança"
                          : "Corrente"}
                      </V3Text5>
                      <V3Text5 style={{ color: "#000" }}>
                        Agência: {recebimento.agencia}
                      </V3Text5>
                      <V3Text5 style={{ color: "#000" }}>
                        Conta: {recebimento.conta}-{recebimento.digitoConta}
                      </V3Text5>
                      <V3Text5 style={{ color: "#000" }}>
                        Tipo de Chave: {this.tipoChave[recebimento.tipoPix]}
                      </V3Text5>
                      <V3Text5 style={{ color: "#000" }}>
                        Chave PIX: {recebimento.pix}
                      </V3Text5>
                      {recebimento.principal !== 0 && (
                        <V3Text5 style={{ color: "#000", fontWeight: "bold" }}>
                          Conta Principal
                        </V3Text5>
                      )}
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={(e) => this.deleteItem(recebimento.id, e)}
                    >
                      <RxTrash color="#000" size={25} />
                    </div>
                  </div>
                ))}

              {!this.state.recebimentos.length && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    minHeight: 150,
                  }}
                >
                  <RiBankLine color="#979797" size={40} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <V3Text5>Nenhum banco cadastrado</V3Text5>
                    <V3Text5 style={{ fontWeight: "bold" }}>
                      Preencha abaixo para adicionar
                    </V3Text5>
                  </div>
                </div>
              )}
              {!this.state.recebimentos.length && (
                <>
                  <S.Row>
                    <V3Autocomplete
                      className="box-form-input-miu"
                      size="small"
                      id="banco"
                      options={this.state.bancosSelec}
                      onChange={(e, v) => {
                        if (!v) {
                          this.setState({
                            banco: null,
                            bancoDesc: null,
                          });
                        } else {
                          this.setState({
                            banco: v ? v.id : null,
                            bancoDesc: v ? v.label : null,
                          });
                        }
                      }}
                      value={this.state.banco ? this.state.bancoDesc : null}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <V3TextCustom {...params} label="Banco" />
                      )}
                    />
                    <V3TextCustom
                      className="input-form-miu"
                      size="small"
                      id="agencia"
                      label="Agência"
                      value={this.state.agencia}
                      onChange={(e) => this.handleAgencia(e.target.value)}
                      variant="outlined"
                    />
                  </S.Row>
                  <S.Row>
                    <div style={{ display: "flex", flex: 2, padding: "0 3px" }}>
                      <V3TextCustom
                        className="input-form-miu"
                        size="small"
                        id="conta"
                        label="Conta"
                        value={this.state.conta}
                        onChange={(e) => this.handleConta(e.target.value)}
                        variant="outlined"
                      />

                      <V3TextCustom
                        size="small"
                        id="digito"
                        label="Digito"
                        style={{ maxWidth: 70, marginLeft: 15 }}
                        value={this.state.digito}
                        onChange={(e) => this.handleDigito(e.target.value)}
                        variant="outlined"
                      />
                    </div>
                    <div style={{ display: "flex", flex: 2 }}>
                      <V3Autocomplete
                        size="small"
                        disablePortal
                        id="tipoConta"
                        style={{ width: "100%" }}
                        options={this.tipoOptions}
                        value={
                          this.state.tipoConta ? this.state.tipoContaDesc : null
                        }
                        onChange={(e, v) => this.handleTipo(v)}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <V3TextCustom {...params} label="Tipo de conta" />
                        )}
                      />
                    </div>
                  </S.Row>

                  <S.Row>
                    <Autocomplete
                      size="small"
                      className="box-form-input-miu"
                      id="tipoChave"
                      options={this.tipoPixOptions}
                      onChange={(e, v) =>
                        this.setState({
                          tipoChave: v ? v.id : null,
                          tipoChaveDesc: v ? v.label : null,
                          chavePix: "",
                          isErrorChave: false,
                        })
                      }
                      value={
                        this.state.tipoChave ? this.state.tipoChaveDesc : null
                      }
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <V3TextCustom {...params} label="Tipo de chave" />
                      )}
                    />
                    <V3TextCustom
                      className="input-form-miu"
                      size="small"
                      error={this.state.isErrorChave}
                      id="chavePix"
                      label="Chave Pix"
                      value={this.state.chavePix}
                      onChange={(e) => this.handleChave(e.target.value)}
                      variant="outlined"
                    />
                  </S.Row>
                  <S.Row style={{ padding: 5 }}>
                    <V3Button
                      onClick={this.handleFormBanco}
                      estilo="primary"
                      width={"49%"}
                      text="Adicionar Banco"
                    />
                  </S.Row>
                </>
              )}

              <Backdrop
                sx={{
                  color: "#16B6C5",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={this.state.open}
              >
                <Lottie animationData={Loading} />
              </Backdrop>
            </S.ContentBox>
          </>
        )}
      </>
    );
  }
}

export default Banco;
