import styled, { css } from "styled-components";
import { spacing } from "../Cadastro";

const BREAKPOINTS = {
  mobile: '360px',
  tablet: '768px',
  desktop: '1024px',
  large: '1440px',
};

export function respond(breakpoint, content) {
  return css`
      @media (min-width: ${BREAKPOINTS[breakpoint]}) {
          ${content}
      }
  `;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const Illustration = styled.div`
    background: #c2f5eb;
    background-image: url(/illustrations/hero-illustration-bg.svg);
    background-repeat: no-repeat;
    background-size: auto 210px;
    background-position: 100% -30px;
    height: 96px;
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;    

    ${respond(
      'tablet',
      css`
          order: 2;
          height: auto;
          width: 155px;
          border-top-left-radius: 0;
          border-bottom-right-radius: 4px;
          background-size: auto 400px;
          background-position: -177px 20px;
      `
  )}

  ${respond(
      'desktop',
      css`
          order: 2;
          height: auto;
          width: 220px;
          border-top-left-radius: 0;
          border-bottom-right-radius: 4px;
          background-size: auto 524px;
          background-position: -190px 20px;
      `
  )}
`;
export const Content = styled.div`
  
  width: 560px;
   h3{
    font-size: 18px;
    line-height: 1.33;
    color: #16898e;
    font-weight: 600;
    font-size: 24px;
   }
   
`;

export const Card = styled.div`
  background: #fff;
  border: 1px;
  border-color: #E5E6E5;
  border-radius: 12px;
  border-style: solid;
  margin: 0 auto 48px;
  select{
    height:45px;
  }
  input{
    height:45px;
  }
  button{
    background: #16B6C5;
    border: 2px;
    border-color: #16B6C5;
    border-radius: 6px;
    border-style: solid;
    box-shadow: 0px 2px 16px rgb(26 162 168 / 40%), 0px 2px 6px rgb(0 0 0 / 10%);
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Poppins',sans-serif;
    font-size: 16px;
    font-weight: 600;
    -webkit-letter-spacing: 0.25px;
    -moz-letter-spacing: 0.25px;
    -ms-letter-spacing: 0.25px;
    letter-spacing: 0.25px;
    line-height: 1.18;
    padding: 16px 32px;
    outline: 0;
    text-align: center;
    -webkit-text-decoration: none;
    text-decoration: none;
    text-transform: uppercase;
  }
  input[type="file"]{
    border: 1px solid #ccc;
    height:45px;
   }
   input::file-selector-button {
    background: #16B6C5;
    border: 2px;
    border-color: #16B6C5;
    border-radius: 6px;
    border-style: solid;
    box-shadow: 0px 2px 16px rgb(26 162 168 / 40%), 0px 2px 6px rgb(0 0 0 / 10%);
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Poppins',sans-serif;
    font-size: 16px;
    font-weight: 600;
    -webkit-letter-spa
   }

   input[type=file]::file-selector-button:hover {
    background-color: #16898e;
    border: 2px solid #00cec9;
  }
`;


export const Header = styled.header`
  width: 100%;
  p {
    font-family: 'Poppins',sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    margin: 0 0 15px 0;
    color:#4d4d4d;
  }
  button {
    background: transparent;
    color: #16898E;
    font-size: 16px;
    margin-top: 15px;
    height: 56px;
    border: 2px;
    border-color: #16898E;
    border-radius: 6px;
    border-style: solid;
    color: #16898E;
    text-align: center;
  }
  hr {
    margin: 20px 0;
    border: none;
    border-bottom: 1px solid #cdcdcd;
    width: 100%;
  }
  a {
    font-size: 16;
    font-weight: bold;
    color: #999;
    text-decoration: none;
  }    
  h4{
    color: #16898e !important;
    font-family: 'Poppins',sans-serif !important;
    font-size: 24px !important;
    font-weight: 600 !important;
  }
`;