import React, { Component } from 'react';
import Backward from "../../assets/backward.svg";
import { Link, withRouter, Redirect } from "react-router-dom";
import { Button, ButtonGroup } from 'react-bootstrap';
import { registerLocale } from "react-datepicker";
import api from "../../services/api";
import ptBR from "date-fns/locale/pt-BR";
import Swal from 'sweetalert2'
import { Backdrop, Checkbox, CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import styled from 'styled-components';
import { Aba } from '../Backoffice/styles';
import { BsCardList, BsCashCoin } from 'react-icons/bs';
import HistoricoProposta from '../../components/HistoricoProposta';
import { money } from '../../mask/money';
import FieldSelect from "../../components/Field/FieldSelect";
import FieldSelectComArray from "../../components/Field/FieldSelectComArray";
import { modalInfo } from "../../components/Modal/ModalInfo";
import CheckedCircle from "../../assets/checked_circle_verde.svg";

export class SucessoNovaCota extends Component {
  constructor(props){
    super(props);
    this.state = {}
  }

  async componentDidMount() {
      
  }

  render() {
    return (
      <>
        <div className="row">
          <div className='col-md-12' align="center">
            <img src={CheckedCircle}/>
          </div>
        </div>

        <div className="row" style={{ marginTop: "15px" }}>
          <div className='col-md-12' align="center" style={{ font: "normal normal normal 30px/30px Roboto" }}>
            <b>Solicitação enviada com sucesso!</b>
          </div>
        </div>

        <div className="row" style={{ marginTop: "30px" }}>
          <div className='col-md-12' align="center" style={{ font: "normal normal normal 15px/20px Roboto" }}>
            Aguarde nosso contato. Enviaremos a melhor proposta por e-mail, <br/>
            WhatsApp ou ligação, pelo número (11) 4040 5686.<br/>
            Obrigado por escolher a Concash.
          </div>
        </div>

        <div className="row" style={{ marginTop: "30px" }}>
            <div className='col-md-12' align="center">
                <Link to={{ pathname: "/consorcio-grupo-cota", state: {}, }}>
                    <button className="btn btn-primary mr-2" style={{ width: "330px", height: "45px", background: "#16898E 0% 0% no-repeat padding-box", borderColor: "#16898E", borderRadius: "10px" }}  >
                        <span style={{ font: "normal normal bold 15px/20px Roboto" }}>Ver minhas cotas</span>
                    </button>
                </Link>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(SucessoNovaCota)