import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import api from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import { Link, withRouter } from "react-router-dom";
import styled, { css } from "styled-components";

const ModalEsqueciSenha = styled.div`
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
`;

const ModalContentEsqueciSenha = styled.div`
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 50%; /* Could be more or less, depending on screen size */
`;

const ModalCloseEsqueciSenha = styled.div`
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    margin-top: -25px;

    :focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
    :hover {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
`;

export class CriarConta extends Component {
    constructor(props) {
        super(props);

        const { display } = this.props;

        this.state = {
            displayModal: display,
            username: "",
        };
    }

    async componentDidMount() {}

    handleAbreModal = (e) => {
        this.setState({ displayModal: "block" });
    };

    handleCloseModal = (e) => {
        this.setState({ displayModal: "none" });
    };

    handleFormCadastrar = async (e) => {
        e.preventDefault();
        this.props.history.push("/app");
    };

    render() {
        return (
            <>                
                <button
                >
                    <Link className="nav-link" to="/cadastro">
                    <span className="menu-title">Criar Conta</span>
                    </Link>
                </button>                
            </>
        );
    }
}

export default CriarConta;
