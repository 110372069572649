import React, { Component, Fragment } from "react";
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2'
import api from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import { Link, withRouter } from "react-router-dom";



export class LanceInfo extends Component {


  constructor(props) {

    super(props);


    const { codigoLance, codigoProcesso, mostraAcao }     = this.props;

    this.state = {
      codigoLance:codigoLance,
      codigoProcesso:codigoProcesso,
      lance: [],
      mostraAcao: mostraAcao,
      error: ""
    };

  }

  async componentDidMount() {

      this.obtemLance(this.state.codigoLance);
  }


  async  obtemLance(codigo){
    try {
          const response = await api.post("api/concash/1.0/consorcio-grupo-cota-lance/detalhe", {codigo:codigo});
          this.setState({lance:response.data});
    } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter o lance."
          });
    }

  }

  handleFormOpenBoleto = async (boleto,e) => {
    e.preventDefault();
      try {

            Swal.fire ({
               title: 'Aguarde ...',
               onBeforeOpen: () => {
                 Swal.showLoading ()
               }
            });

            const response  = await api.post("api/concash/1.0/consorcio-grupo-cota-parcela/download",{
              comprovante:boleto
            },{ responseType: 'blob' });


            const file      = new Blob([response.data], { type: "application/pdf" });
            const fileURL   = URL.createObjectURL(file);
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;

            Swal.close();

      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o processamento da requisição."
        });
      }
  };

  handleAprovarPagamento = async e => {
    e.preventDefault();
    const { codigoProcesso } = this.state;
    if (!codigoProcesso) {
      Swal.fire ('Informe o código do processo para continuar!');
    } else {
      try {

            const response    = await api.put("api/concash/1.0/aprovar-pagamento/aprovar", {
              codigo:codigoProcesso
            });
            Swal.fire ('Pagamento aprovado com sucesso!');

      } catch (err) {
        Swal.fire ('Houve um problema com o processamento da requisição.');
      }
    }
  };



  render() {

    return (

      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">


              <div className="row" style={{marginTop:'40px'}}>
                          <div className="col-sm-3">
                            <b>Status:</b>
                          </div>
                          <div className="col-sm-3">
                            {this.state.lance.situacao}
                          </div>
                          <div className="col-sm-3">
                            <b>Valor:</b>
                          </div>
                          <div className="col-sm-3">
                            {this.state.lance.valor}
                          </div>
              </div>
              <div className="row" style={{marginTop:'20px'}}>
                          <div className="col-sm-3">
                            <b>Boleto:</b>
                          </div>
                          <div className="col-sm-9">
                              {this.state.lance.boleto &&
                                  <button type="button" onClick={e =>this.handleFormOpenBoleto(this.state.lance.boleto,e)} className="btn btn-primary mr-2">Download</button>
                              }
                          </div>
              </div>
              {this.state.mostraAcao &&
              <div className="row" style={{marginTop:'20px'}}>
                          <div className="col-sm-12 text-right">
                              <button type="button" className="btn btn-primary mr-2" onClick={e =>this.handleAprovarPagamento(e)}>Aprovar Pagamento</button>
                          </div>
              </div>
              }

          </div>
        </div>
      </div>

     </div>

    )
  }
}


export default LanceInfo;
