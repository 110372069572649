import React, { Component, Fragment } from "react";
import Swal from "sweetalert2";
import api from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import { Link, withRouter } from "react-router-dom";
import { Backdrop, CircularProgress, TextField } from "@mui/material";
import isEmail from "validator/lib/isEmail";
import { cpfCnpj } from "../../mask/cpfcnpj";
import {
  DateTimePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { cpf } from "cpf-cnpj-validator";
import moment from "moment";
import { Card } from "./styles";
import TitlePage from "../common/Title";
import { BsCheckCircleFill } from "react-icons/bs";
import { MdAttachFile, MdCancel } from "react-icons/md";
import { AiOutlineFileZip } from "react-icons/ai";
import * as S from "../../pages/Backoffice/cadastro.styles";
import { V3Modal, V3ModalConfirm } from "../../styles/themes/components/modal";
import { V3Button, V3ButtonLink } from "../../styles/themes/components/button";
import { RxTrash } from "react-icons/rx";
import { V3Link, V3Text5 } from "../../styles/themes/components/texts";
import { v3Colors } from "../../styles/themes/v3/colors";
import {
  V3FileInput,
  V3FileInputMobile,
} from "../../styles/themes/components/input";

export class ImportacaoCliente extends Component {
  constructor(props) {
    super(props);

    const { cliente, tipoCliente } = this.props;
    this.state = {
      codigoCliente: cliente.id,
      cpfCnpj: cliente.cpfCnpj,
      tipoPessoa: String(cliente.cpfCnpj).length >= 14 ? "PJ" : "PF",
      obj_cliente: cliente,
      rgCpf: "",
      cnh: "",
      comprovante: "",
      comprovanteBancario: "",
      contratoSocial: "",
      representantes: [],
      open: false,
      error: "",
      cpf: "",
      isMobile: false,
      hasErrorCpfCnpj: false,
      hasErrorEmail: false,
      dataNascimento: null,
      fileInputKey: Date.now(),
      fileInputKey2: Date.now(),
      fileInputKey3: Date.now(),
      doc1: "",
      doc2: "",
      doc3: "",
      doc4: "",
      docsRep: {},
      melhorContato: null,
      openConfirm: false,
      deleteId: null,
      whatsapp: "",
      hasSuccess: false,
      successText: "",
      hasError: false,
      errorText: "",
    };
  }

  async componentDidMount() {
    this.handleGetDocs();
    this.setState({ isMobile: window.innerWidth <= 800 });
  }

  handleEmail(texto) {
    if (isEmail(texto)) {
      this.setState({ hasErrorEmail: false });
    } else {
      this.setState({ hasErrorEmail: true });
    }
    this.setState({ email: texto });
  }

  handleCpf(text) {
    if (text.length >= 11) {
      if (text.length <= 11 && text.length > 0) {
        if (cpf.isValid(text)) {
          this.setState({ hasErrorCpfCnpj: false });
        } else {
          this.setState({ hasErrorCpfCnpj: true });
        }
      } else {
        if (cpf.isValid(text)) {
          this.setState({ hasErrorCpfCnpj: false });
        } else {
          this.setState({ hasErrorCpfCnpj: true });
        }
      }
    } else {
      this.setState({ hasErrorCpfCnpj: true });
    }
    const newdata = cpfCnpj(text);
    this.setState({ cpf: newdata });
  }

  async handleGetDocs() {
    try {
      const documents = await api.get(
        `/api/concash/1.0/cliente-area/documentos?id=${this.state.codigoCliente}`
      );

      // if (this.state.tipoPessoa === "PJ") {
      //   const documentsRepres = await api
      //     .get(
      //       `/api/concash/1.0/cliente-area/documentos-representante?id=${this.state.codigoCliente}`
      //     )
      //     .catch((error) => {
      //       this.setState({
      //         hasError: true,
      //         errorText: "Erro ao buscar documentos.",
      //       });
      //       console.log(error);
      //     });

      //   this.setState({
      //     docsRep: documentsRepres.data[0],
      //     documentos: documentsRepres.data[0],

      //   });
      // }

      const dados = documents.data[0];

      this.setState({
        documentos: dados,
        doc1: dados?.primeiroDocumento,
        doc2: dados?.segundoDocumento,
        doc3: dados?.terceiroDocumento,
        doc4: dados?.quartoDocumento,
      });
    } catch (error) {
      console.log(error);
    }
  }

  handleClear() {
    const { cliente } = this.props;
    this.setState({
      codigoCliente: cliente.id,
      cpfCnpj: cliente.cpfCnpj,
      obj_cliente: cliente,
      rgCpf: "",
      cnh: "",
      comprovante: "",
      comprovanteBancario: "",
      contratoSocial: "",
      representantes: [],
      open: false,
      error: "",
      cpf: "",
      hasErrorCpfCnpj: false,
      hasErrorEmail: false,
      dataNascimento: null,
      fileInputKey: Date.now(),
      fileInputKey2: Date.now(),
      fileInputKey3: Date.now(),
    });
  }

  handleFormTransferencia = async (e) => {
    e.preventDefault();
    this.setState({ open: true });
    const { rgCpf } = this.state;
    if (!rgCpf) {
      this.setState({
        open: false,
        hasError: true,
        errorText: "Selecione o arquivo com RG/CPF ou CNH para continuar!",
      });
    } else {
      try {
        this.setState({ open: true });
        const formData = new FormData();
        formData.append("documento", rgCpf);
        formData.append("tipo", 1);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const response = await api.post(
          `/api/concash/1.0/cliente-area/anexar-documento/${this.state.codigoCliente}`,
          formData,
          config
        );
        if (response.status === 200) {
          this.setState({
            rgCpf: "",
            open: false,
            hasSuccess: true,
            successText: "Documento enviado com sucesso!",
            fileInputKey: Date.now(),
          });

          this.handleGetDocs();
        } else {
          this.setState({
            open: false,
            hasError: true,
            errorText: "Erro ao enviar documento.",
          });
        }
      } catch (err) {
        this.setState({
          open: false,
          hasError: true,
          errorText: "Erro ao enviar documento.",
        });
      }
    }
  };

  handleFormComprovante = async (e) => {
    e.preventDefault();
    this.setState({ open: true });
    const { comprovante } = this.state;
    if (!comprovante) {
      this.setState({
        open: false,
        hasError: true,
        errorText:
          "Selecione o arquivo com o comprovante de residência para continuar!",
      });
    } else {
      try {
        this.setState({ open: true });
        const formData = new FormData();
        formData.append("documento", comprovante);
        formData.append("tipo", 2);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const response = await api.post(
          `/api/concash/1.0/cliente-area/anexar-documento/${this.state.codigoCliente}`,
          formData,
          config
        );
        if (response.status === 200) {
          this.setState({
            open: false,
            comprovante: "",
            hasSuccess: true,
            successText: "Comprovante de endereço enviado com sucesso!",
            fileInputKey2: Date.now(),
          });

          this.handleGetDocs();
        } else {
          this.setState({
            open: false,
            hasError: true,
            errorText: "Erro ao enviar comprovante.",
          });
          this.handleGetDocs();
        }
      } catch (err) {
        this.setState({
          open: false,
          hasError: true,
          errorText: "Erro ao enviar comprovante.",
        });
        this.handleGetDocs();
      }
    }
  };
  handleFormComprovanteBancario = async (e) => {
    e.preventDefault();
    this.setState({ open: true });
    const { comprovanteBancario } = this.state;
    if (!comprovanteBancario) {
      this.setState({
        open: false,
        hasError: true,
        errorText: "Por favor anexe o comprovante bancário.",
      });
    } else {
      try {
        const formData = new FormData();
        formData.append("documento", comprovanteBancario);
        formData.append("tipo", 3);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const response = await api.post(
          `/api/concash/1.0/cliente-area/anexar-documento/${this.state.codigoCliente}`,
          formData,
          config
        );
        if (response.status === 200) {
          this.setState({
            open: false,
            hasSuccess: true,
            successText: "Comprovante bancário enviado com sucesso.",
            comprovante: "",
            fileInputKey2: Date.now(),
          });

          this.setState({ open: false });
          this.handleClear();
          this.handleGetDocs();
        } else {
          this.setState({
            open: false,
            hasError: true,
            errorText: "Erro ao enviar comprovante bancário.",
          });
        }
      } catch (err) {
        this.setState({
          open: false,
          hasError: true,
          errorText: "Erro ao enviar comprovante bancário.",
        });
      }
    }
  };

  handleFormPj = async (e) => {
    e.preventDefault();
    this.setState({ open: true });
    const { contratoSocial } = this.state;
    if (!contratoSocial) {
      this.setState({
        open: false,
        hasError: true,
        errorText: "Selecione o arquivo com o contrato social para continuar!",
      });
    } else {
      try {
        this.setState({ open: true });
        const formData = new FormData();
        formData.append("documento", contratoSocial);
        formData.append("tipo", 1);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const response = await api.post(
          `/api/concash/1.0/cliente-area/anexar-documento/${this.state.codigoCliente}`,
          formData,
          config
        );
        if (response.status === 200) {
          this.setState({
            hasSuccess: true,
            open: false,
            successText: "Contrato Social enviado com sucesso",
            contratoSocial: "",
            fileInputKey3: Date.now(),
          });

          this.setState({ open: false });
          this.handleClear();
          this.handleGetDocs();
        } else {
          this.setState({
            open: false,
            hasError: true,
            errorText: "Erro ao enviar contrato social.",
          });
          this.handleClear();
        }
      } catch (err) {
        this.setState({
          open: false,
          hasError: true,
          errorText: "Erro ao enviar contrato social.",
        });
        this.handleClear();
      }
    }
  };

  handleSendRG(doc) {
    this.setState({
      rgCpf: doc,
    });
  }

  handleSendCR(doc) {
    this.setState({
      comprovante: doc,
    });
  }

  handleSendCB(doc) {
    this.setState({
      comprovanteBancario: doc,
    });
  }

  cancelDelete() {
    this.setState({ openConfirm: false, deleteId: null });
  }

  // deleteItem(item) {
  //   this.setState({ openConfirm: true, deleteId: item });
  // }

  render() {
    const tipoPessoa = this.state.tipoPessoa;
    return (
      <>
        <V3Modal
          open={this.state.hasError}
          actionClose={() => this.setState({ hasError: false })}
          title={"Atenção!"}
          text={this.state.errorText}
          type="error"
        />
        <V3Modal
          open={this.state.hasSuccess}
          actionClose={() => this.setState({ hasSuccess: false })}
          title={"Sucesso!"}
          text={this.state.successText}
          type="success"
        />
        <V3ModalConfirm
          open={this.state.openConfirm}
          title="Tem certeza que deseja excluir este e-mail?"
          text="A exclusão é irreversível."
          actionClose={() => this.cancelDelete()}
          actionConfirm={() => this.handleDeletarBanco()}
          textCancel="Não"
          textConfirm="Sim"
        />
        {this.state.isMobile ? (
          <>
            <S.ContentBoxMobile>
              {this.state.documentos && (
                <>
                  {this.state.doc1 && (
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                        minHeight: 120,
                        borderBottom: "1px solid #979797",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          color: "#000",
                        }}
                      >
                        <V3Text5 style={{ color: "#000", fontWeight: "bold" }}>
                          {this.state.tipoPessoa === "PF"
                            ? "RG/CPF ou CNH"
                            : "Contrato Social"}
                        </V3Text5>
                        <br />
                        <V3Link
                          href={this.state.doc1}
                          target="_blank"
                          style={{
                            color: v3Colors.primary,
                            fontWeight: "bold",
                          }}
                        >
                          Visualizar Documento
                        </V3Link>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={(e) => this.deleteItem(doc.id, e)}
                      >
                        <RxTrash color="#000" size={25} />
                      </div>
                    </div>
                  )}
                  {this.state.doc2 && (
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                        minHeight: 120,
                        borderBottom: "1px solid #979797",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          color: "#000",
                        }}
                      >
                        <V3Text5 style={{ color: "#000", fontWeight: "bold" }}>
                          Comprovante de residência
                        </V3Text5>
                        <br />
                        <V3Link
                          href={this.state.doc2}
                          target="_blank"
                          style={{
                            color: v3Colors.primary,
                            fontWeight: "bold",
                          }}
                        >
                          Visualizar Documento
                        </V3Link>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={(e) => this.deleteItem(this.state.doc2, e)}
                      >
                        <RxTrash color="#000" size={25} />
                      </div>
                    </div>
                  )}
                  {this.state.doc3 && (
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                        minHeight: 120,
                        borderBottom: "1px solid #979797",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          color: "#000",
                        }}
                      >
                        <V3Text5 style={{ color: "#000", fontWeight: "bold" }}>
                          Comprovante bancário
                        </V3Text5>
                        <br />
                        <V3Link
                          href={this.state.doc3}
                          target="_blank"
                          style={{
                            color: v3Colors.primary,
                            fontWeight: "bold",
                          }}
                        >
                          Visualizar Documento
                        </V3Link>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={(e) => this.deleteItem(this.state.doc3, e)}
                      >
                        <RxTrash color="#000" size={25} />
                      </div>
                    </div>
                  )}
                  <S.Row>
                    <V3Text5>ADICIONE OU SUBSTITUA SEUS DOCUMENTOS</V3Text5>
                  </S.Row>
                </>
              )}

              {!this.state.documentos && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    minHeight: 150,
                  }}
                >
                  <MdAttachFile color="#979797" size={40} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <V3Text5>Nenhum e-mail cadastrado</V3Text5>
                    <V3Text5 style={{ fontWeight: "bold" }}>
                      Preencha abaixo para adicionar
                    </V3Text5>
                  </div>
                </div>
              )}
              {this.state.tipoPessoa === "PF" ? (
                <>
                  <S.Row style={{ marginBottom: 20 }}>
                    <V3FileInputMobile
                      label="RG/CPF ou CNH"
                      width={"100%"}
                      recuo={-40}
                      onChange={(e) =>
                        this.setState({
                          rgCpf: e.target.files[0],
                        })
                      }
                      fileName={this.state.rgCpf.name}
                    />
                  </S.Row>
                  <S.Row>
                    <V3Button
                      onClick={(e) => this.handleFormTransferencia(e)}
                      estilo="primary"
                      width={"100%"}
                      text="Adicionar Documento"
                    />
                  </S.Row>
                </>
              ) : (
                <>
                  <S.Row style={{ marginBottom: 20 }}>
                    <V3FileInputMobile
                      label="Contrato Social"
                      width={"100%"}
                      recuo={-43}
                      onChange={(e) =>
                        this.setState({
                          contratoSocial: e.target.files[0],
                        })
                      }
                      fileName={this.state.contratoSocial.name}
                    />
                  </S.Row>
                  <S.Row>
                    <V3Button
                      onClick={(e) => this.handleFormPj(e)}
                      estilo="primary"
                      text="Adicionar Documento"
                      width={"100%"}
                    />
                  </S.Row>
                </>
              )}

              <S.Row style={{ marginBottom: 20 }}>
                <V3FileInputMobile
                  label="Comprovante de residência"
                  recuo={-20}
                  width={"100%"}
                  onChange={(e) =>
                    this.setState({
                      comprovante: e.target.files[0],
                    })
                  }
                  fileName={this.state.comprovante.name}
                />
              </S.Row>
              <S.Row>
                <V3Button
                  onClick={(e) => this.handleFormComprovante(e)}
                  estilo="primary"
                  width={"100%"}
                  text="Adicionar Documento"
                />
              </S.Row>
              <S.Row style={{ marginBottom: 20, flex: 1 }}>
                <V3FileInputMobile
                  label="Comprovante bancário"
                  width={"100%"}
                  style={{ display: "flex", flex: 1 }}
                  recuo={-30}
                  onChange={(e) =>
                    this.setState({
                      comprovanteBancario: e.target.files[0],
                    })
                  }
                  fileName={this.state.comprovanteBancario.name}
                />
              </S.Row>
              <S.Row>
                <V3Button
                  onClick={(e) => this.handleFormComprovanteBancario(e)}
                  estilo="primary"
                  width={"100%"}
                  text="Adicionar Comprovante"
                />
              </S.Row>
            </S.ContentBoxMobile>
          </>
        ) : (
          <>
            <S.ContentBox>
              {this.state.documentos && (
                <>
                  {this.state.doc1 && (
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                        minHeight: 120,
                        borderBottom: "1px solid #979797",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          color: "#000",
                        }}
                      >
                        <V3Text5 style={{ color: "#000", fontWeight: "bold" }}>
                          {this.state.tipoPessoa === "PF"
                            ? "RG/CPF ou CNH"
                            : "Contrato Social"}
                        </V3Text5>
                        <br />
                        <V3Link
                          href={this.state.doc1}
                          target="_blank"
                          style={{
                            color: v3Colors.primary,
                            fontWeight: "bold",
                          }}
                        >
                          Visualizar Documento
                        </V3Link>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={(e) => this.deleteItem(doc.id, e)}
                      >
                        <RxTrash color="#000" size={25} />
                      </div>
                    </div>
                  )}
                  {this.state.doc2 && (
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                        minHeight: 120,
                        borderBottom: "1px solid #979797",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          color: "#000",
                        }}
                      >
                        <V3Text5 style={{ color: "#000", fontWeight: "bold" }}>
                          Comprovante de residência
                        </V3Text5>
                        <br />
                        <V3Link
                          href={this.state.doc2}
                          target="_blank"
                          style={{
                            color: v3Colors.primary,
                            fontWeight: "bold",
                          }}
                        >
                          Visualizar Documento
                        </V3Link>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={(e) => this.deleteItem(this.state.doc2, e)}
                      >
                        <RxTrash color="#000" size={25} />
                      </div>
                    </div>
                  )}
                  {this.state.doc3 && (
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                        minHeight: 120,
                        borderBottom: "1px solid #979797",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          color: "#000",
                        }}
                      >
                        <V3Text5 style={{ color: "#000", fontWeight: "bold" }}>
                          Comprovante bancário
                        </V3Text5>
                        <br />
                        <V3Link
                          href={this.state.doc3}
                          target="_blank"
                          style={{
                            color: v3Colors.primary,
                            fontWeight: "bold",
                          }}
                        >
                          Visualizar Documento
                        </V3Link>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={(e) => this.deleteItem(this.state.doc3, e)}
                      >
                        <RxTrash color="#000" size={25} />
                      </div>
                    </div>
                  )}
                  <S.Row>
                    <V3Text5>ADICIONE OU SUBSTITUA SEUS DOCUMENTOS</V3Text5>
                  </S.Row>
                </>
              )}

              {!this.state.documentos && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    minHeight: 150,
                  }}
                >
                  <MdAttachFile color="#979797" size={40} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <V3Text5>Nenhum e-mail cadastrado</V3Text5>
                    <V3Text5 style={{ fontWeight: "bold" }}>
                      Preencha abaixo para adicionar
                    </V3Text5>
                  </div>
                </div>
              )}
              {this.state.tipoPessoa === "PF" ? (
                <>
                  <S.Row style={{ marginBottom: 20 }}>
                    <V3FileInput
                      label="RG/CPF ou CNH"
                      width={550}
                      recuo={-150}
                      onChange={(e) =>
                        this.setState({
                          rgCpf: e.target.files[0],
                        })
                      }
                      fileName={this.state.rgCpf.name}
                    />
                    <V3Button
                      onClick={(e) => this.handleFormTransferencia(e)}
                      estilo="primary"
                      width={"49%"}
                      text="Adicionar Documento"
                    />
                  </S.Row>
                </>
              ) : (
                <>
                  <S.Row style={{ marginBottom: 20 }}>
                    <V3FileInput
                      label="Contrato Social"
                      width={550}
                      recuo={-150}
                      onChange={(e) =>
                        this.setState({
                          contratoSocial: e.target.files[0],
                        })
                      }
                      fileName={this.state.contratoSocial.name}
                    />
                    <V3Button
                      onClick={(e) => this.handleFormPj(e)}
                      estilo="primary"
                      width={"49%"}
                      text="Adicionar Documento"
                    />
                  </S.Row>
                </>
              )}

              <S.Row style={{ marginBottom: 20 }}>
                <V3FileInput
                  label="Comprovante de residência"
                  recuo={-120}
                  width={550}
                  onChange={(e) =>
                    this.setState({
                      comprovante: e.target.files[0],
                    })
                  }
                  fileName={this.state.comprovante.name}
                />
                <V3Button
                  onClick={(e) => this.handleFormComprovante(e)}
                  estilo="primary"
                  width={"49%"}
                  text="Adicionar Documento"
                />
              </S.Row>
              <S.Row style={{ marginBottom: 20 }}>
                <V3FileInput
                  label="Comprovante bancário"
                  width={550}
                  recuo={-130}
                  onChange={(e) =>
                    this.setState({
                      comprovanteBancario: e.target.files[0],
                    })
                  }
                  fileName={this.state.comprovanteBancario.name}
                />
                <V3Button
                  onClick={(e) => this.handleFormComprovanteBancario(e)}
                  estilo="primary"
                  width={"49%"}
                  text="Adicionar Comprovante"
                />
              </S.Row>
            </S.ContentBox>
            {/* <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body" style={{ paddingTop: 0 }}>
                  <Card>
                    <p className="card-description"></p>
                    <form
                      className="forms-sample"
                      onSubmit={this.handleFormRepresentante}
                    >
                      <Backdrop
                        sx={{
                          color: "#16B6C5",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={this.state.open}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                      {this.state.tipoPessoa == "PJ" && (
                        <div className="row" style={{ marginTop: "20px" }}>
                          <div className="box-form-input-miu">
                            <TextField
                              type="file"
                              className="input-form-miu"
                              id=""
                              label="  "
                              key={this.state.fileInputKey3}
                              onChange={(e) =>
                                this.setState({
                                  contratoSocial: e.target.files[0],
                                })
                              }
                              variant="outlined"
                            />
                          </div>
                          <div className="col-sm-4">
                            <button
                              type="submit"
                              className="btn btn-primary mr-6"
                              onClick={(e) => this.handleFormPj(e)}
                            >
                              IMPORTAR CONTRATO SOCIAL
                            </button>
                          </div>
                        </div>
                      )}
                      {this.state.tipoPessoa == "PF" && (
                        <>
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="box-form-input-miu">
                              <TextField
                                type="file"
                                className="input-form-miu"
                                id=""
                                label="  "
                                key={this.state.fileInputKey}
                                onChange={(e) =>
                                  this.setState({
                                    rgCpf: e.target.files[0],
                                  })
                                }
                                variant="outlined"
                              />
                            </div>
                            <div className="col-sm-4">
                              <button
                                type="submit"
                                className="btn btn-primary mr-6"
                                onClick={(e) => this.handleFormTransferencia(e)}
                              >
                                IMPORTAR RG/CPF ou CNH
                              </button>
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="box-form-input-miu">
                              <TextField
                                type="file"
                                className="input-form-miu"
                                id=""
                                label="  "
                                key={this.state.fileInputKey2}
                                onChange={(e) =>
                                  this.setState({
                                    comprovante: e.target.files[0],
                                  })
                                }
                                variant="outlined"
                              />
                            </div>
                            <div className="col-sm-4">
                              <button
                                type="submit"
                                className="btn btn-primary mr-6"
                                onClick={(e) => this.handleFormComprovante(e)}
                              >
                                IMPORTAR COMP. RESIDÊN.
                              </button>
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="box-form-input-miu">
                              <TextField
                                type="file"
                                className="input-form-miu"
                                id=""
                                label="  "
                                key={this.state.fileInputKey2}
                                onChange={(e) =>
                                  this.setState({
                                    comprovanteBancario: e.target.files[0],
                                  })
                                }
                                variant="outlined"
                              />
                            </div>
                            <div className="col-sm-4">
                              <button
                                type="submit"
                                className="btn btn-primary mr-6"
                                onClick={(e) =>
                                  this.handleFormComprovanteBancario(e)
                                }
                              >
                                IMPORTAR COMP. BANC.
                              </button>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="row" style={{ marginTop: "20px" }}></div>
                    </form>
                  </Card>
                  <TitlePage
                    title={"Arquivos"}
                    style={{ display: "flex", flex: 1, width: "100%" }}
                  />
                  <div className="row col-md-12">
                    <div
                      className="table-responsive"
                      style={{
                        display: "flex",
                        flex: 1,
                        padding: 20,
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <table className="table table-striped col-md-12">
                        <thead>
                          <th>Status</th>
                          <th style={{ display: "flex", flex: 1 }}>
                            Documento
                          </th>
                          <th></th>
                        </thead>
                        <tbody className="col-md-12">
                          {this.state.tipoPessoa === "PF" ? (
                            <>
                              <tr>
                                {this.state.doc1 ? (
                                  <>
                                    <td>
                                      <BsCheckCircleFill
                                        color="green"
                                        size={28}
                                      />
                                    </td>
                                    <td>RG/CPF ou CNH</td>
                                    <td
                                      style={{
                                        display: "flex",
                                        flex: 1,
                                        justifyContent: "right",
                                      }}
                                    >
                                      <a
                                        href={this.state.doc1}
                                        style={{
                                          display: "flex",
                                          padding: 10,
                                          backgroundColor: "green",
                                          width: 120,
                                          borderRadius: 8,
                                          alignItems: "center",
                                          textDecoration: "none",
                                          color: "#FFF",
                                        }}
                                        target="_blank"
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignContent: "center",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: 5,
                                          }}
                                        >
                                          <AiOutlineFileZip /> Abrir Arquivo
                                        </div>
                                      </a>
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>
                                      <MdCancel color="red" size={28} />
                                    </td>
                                    <td>RG/CPF ou CNH</td>
                                    <td></td>
                                  </>
                                )}
                              </tr>
                              <tr>
                                {this.state.doc2 ? (
                                  <>
                                    <td>
                                      <BsCheckCircleFill
                                        color="green"
                                        size={28}
                                      />
                                    </td>
                                    <td>Comprovante de Residência</td>
                                    <td
                                      style={{
                                        display: "flex",
                                        flex: 1,
                                        justifyContent: "right",
                                      }}
                                    >
                                      <a
                                        href={this.state.doc2}
                                        style={{
                                          display: "flex",
                                          padding: 10,
                                          backgroundColor: "green",
                                          width: 120,
                                          borderRadius: 8,
                                          alignItems: "center",
                                          textDecoration: "none",
                                          color: "#FFF",
                                        }}
                                        target="_blank"
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignContent: "center",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: 5,
                                          }}
                                        >
                                          <AiOutlineFileZip /> Abrir Arquivo
                                        </div>
                                      </a>
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>
                                      <MdCancel color="red" size={28} />
                                    </td>
                                    <td>Comprovante de Residência</td>
                                    <td></td>
                                  </>
                                )}
                              </tr>
                              <tr>
                                {this.state.doc3 ? (
                                  <>
                                    <td>
                                      <BsCheckCircleFill
                                        color="green"
                                        size={28}
                                      />
                                    </td>
                                    <td>Comprovante Bancário</td>
                                    <td
                                      style={{
                                        display: "flex",
                                        flex: 1,
                                        justifyContent: "right",
                                      }}
                                    >
                                      <a
                                        href={this.state.doc3}
                                        style={{
                                          display: "flex",
                                          padding: 10,
                                          backgroundColor: "green",
                                          width: 120,
                                          borderRadius: 8,
                                          alignItems: "center",
                                          textDecoration: "none",
                                          color: "#FFF",
                                        }}
                                        target="_blank"
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignContent: "center",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: 5,
                                          }}
                                        >
                                          <AiOutlineFileZip /> Abrir Arquivo
                                        </div>
                                      </a>
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>
                                      <MdCancel color="red" size={28} />
                                    </td>
                                    <td>Comprovante Bancário</td>
                                    <td></td>
                                  </>
                                )}
                              </tr>
                              <tr>
                                {this.state.doc4 ? (
                                  <>
                                    <td>
                                      <BsCheckCircleFill
                                        color="green"
                                        size={28}
                                      />
                                    </td>
                                    <td>Outro...</td>
                                    <td>
                                      <a
                                        href={this.state.doc4}
                                        style={{
                                          display: "flex",
                                          padding: 10,
                                          backgroundColor: "green",
                                          width: 120,
                                          borderRadius: 8,
                                          alignItems: "center",
                                          textDecoration: "none",
                                          color: "#FFF",
                                        }}
                                        target="_blank"
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignContent: "center",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: 5,
                                          }}
                                        >
                                          <AiOutlineFileZip /> Abrir Arquivo
                                        </div>
                                      </a>
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>
                                      <MdCancel color="red" size={28} />
                                    </td>
                                    <td>Outro...</td>
                                    <td></td>
                                  </>
                                )}
                              </tr>
                            </>
                          ) : (
                            <>
                              <tr>
                                {this.state.doc1 ? (
                                  <>
                                    <td>
                                      <BsCheckCircleFill
                                        color="green"
                                        size={28}
                                      />
                                    </td>
                                    <td>Contrato Social</td>
                                    <td
                                      style={{
                                        display: "flex",
                                        flex: 1,
                                        justifyContent: "right",
                                      }}
                                    >
                                      <a
                                        href={this.state.doc2}
                                        style={{
                                          display: "flex",
                                          padding: 10,
                                          backgroundColor: "green",
                                          width: 120,
                                          borderRadius: 8,
                                          alignItems: "center",
                                          textDecoration: "none",
                                          color: "#FFF",
                                        }}
                                        target="_blank"
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignContent: "center",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: 5,
                                          }}
                                        >
                                          <AiOutlineFileZip /> Abrir Arquivo
                                        </div>
                                      </a>
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>
                                      <MdCancel color="red" size={28} />
                                    </td>
                                    <td>Contrato Social</td>
                                    <td></td>
                                  </>
                                )}
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </>
        )}

        <div style={{ display: "flex", flex: 1, padding: 20 }}></div>
      </>
    );
  }
}

export default ImportacaoCliente;
