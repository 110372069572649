import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import api from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import { Link, withRouter } from "react-router-dom";

export class CabecalhoCliente extends Component {
  constructor(props) {
    super(props);

    const { cliente } = this.props;

    this.state = {
      cliente: cliente,
      error: "",
    };
  }

  async componentDidMount() {}

  render() {
    return (
      <div className="row" style={{ backgroundColor: "rgb(222, 226, 230)" }}>
        <div className="col-md-12" style={{ marginTop: "30px" }}>
          <div className="row">
            <div className="col-md-4">
              <Form.Group className="row">
                <label
                  htmlFor="examplePropostal"
                  className="col-sm-12 col-form-label"
                  style={{ textAlign: "center" }}
                >
                  CPF/CNPJ Cliente​
                </label>
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Group className="row">
                <label
                  htmlFor="examplePropostal"
                  className="col-sm-12 col-form-label"
                  style={{ textAlign: "center" }}
                >
                  Nome Consorciado
                </label>
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Group className="row">
                <label
                  htmlFor="examplePropostal"
                  className="col-sm-12 col-form-label"
                  style={{ textAlign: "center" }}
                >
                  E-mail
                </label>
              </Form.Group>
            </div>
          </div>

          <div className="row" style={{ marginTop: "-20px" }}>
            <div className="col-md-4">
              <Form.Group className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <Form.Control
                    type="text"
                    style={{ height: "30px" }}
                    className="form-control"
                    id="cpf"
                    value={this.state.cliente.cpf}
                  />
                </div>
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Group className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <Form.Control
                    type="text"
                    style={{ height: "30px" }}
                    className="form-control"
                    id="clienteNome"
                    value={this.state.cliente.nome}
                  />
                </div>
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Group className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <Form.Control
                    type="text"
                    style={{ height: "30px" }}
                    className="form-control"
                    id="clienteEmail"
                    value={this.state.cliente.email}
                  />
                </div>
              </Form.Group>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CabecalhoCliente;
