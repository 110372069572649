import styled from "styled-components";
import { colors } from "../../styles/colors";
import LogoCinza from "../../assets/images/logo-cinza.png";
import { GrClose } from "react-icons/gr";
import { NewItem, NewItemMobile } from "./Sidebar.styled";
import { HiOutlineDocumentText } from "react-icons/hi";
import { BsPlus } from "react-icons/bs";
import { BiUser } from "react-icons/bi";
import { MdLogout } from "react-icons/md";
import { executaLogout } from "../../services/auth";

export const HeaderContainerMobile = styled.div`
  display: flex;
  flex: 1;
  z-index: 999;

  padding: 10px;

  position: absolute;

  .box-menu {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: ${colors.darkGreen};
    border-radius: 8px;
    height: 100%;
  }

  .cell {
    display: flex;
  }

  .cell-top {
    display: flex;

    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 10px;
    color: #fff;
  }

  .cell-content {
    display: flex;

    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    color: #fff;
  }

  .link-arrow {
    text-decoration: none;
    color: #fff;
  }

  .buttons {
    display: flex;
    flex: 1;
    margin-top: 140px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
  }
  .link-btn {
    text-decoration: none;
  }

  .box-users {
    display: flex;
    flex: 1;
    padding: 20px;
  }
`;

export const LinkWhite = styled.a`
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  font-size: 1rem;
  color: #fff;
`;

export const TextUsers = styled.span`
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
`;

export const ButtonLarge = styled.button`
  display: flex;
  color: #fff;
  width: 309px;
  height: 76px;
  border-color: ${colors.mediumGreen};
  font-family: "Poppins", sans-serif;
  // padding: 16px 48px;
  border-radius: 4px;
  font-size: 17px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  &:hover {
    background-color: ${colors.softDarkGreen};
  }
  background-color: ${colors.mediumGreen};
`;

export const NewNavBar = styled.div`
  display: flex;
  flex: 1;
  top: 0px;
  left: 0px;
  height: 65px;
  padding: 13px 82px;
  justify-content: space-between;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000012;
  opacity: 1;
`;

export const NewNavBarMobile = styled.div`
  display: flex;
  flex: 1;
  top: 0px;
  left: 0px;
  height: 60px;
  padding: 18px 20px;
  justify-content: space-between;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000012;
  opacity: 1;
`;

export const MenuNavMobile = (props) => {
  const { action, user } = props;
  const str = user;
  const capitalized = str[0]?.toUpperCase() + str?.substr(1).toLowerCase();

  const Box = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    right: 0px;
    height: 100%;
    margin-left: 70px;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: -10px 0px 20px #00000040;
    opacity: 1;
  `;

  return (
    <>
      <Box>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 30,
          }}
        >
          <img src={LogoCinza} width={110} height={30} />
          <div onClick={action}>
            <GrClose color="#000" size={24} />
          </div>
        </div>
        <div style={{ display: "flex", padding: "10px 30px", gap: 10 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: 40,
              height: 40,
              backgroundColor: "#16898E",
              borderRadius: "50%",
            }}
          >
            <span
              style={{
                fontFamily: "Roboto",
                fontSize: 21,
                color: "#FFF",
              }}
            >
              {user[0]?.toUpperCase()}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ fontFamily: "Roboto", fontSize: 15 }}>
              Olá {capitalized}
            </span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 30,
            paddingBottom: 30,
            borderBottom: "1px solid #979797",
          }}
        >
          <NewItemMobile
            title="Minhas cotas"
            link="/consorcio-grupo-cota"
            icon={<HiOutlineDocumentText color="#05898F" size={24} />}
          />
          <NewItemMobile
            title="Solicitar Proposta"
            link="/upload-analise"
            icon={<BsPlus color="#05898F" size={24} />}
          />
          <NewItemMobile
            title="Meu cadastro"
            link="/backoffice/detalhamento-cliente"
            icon={<BiUser color="#05898F" size={24} />}
          />
        </div>
        <div style={{ display: "flex", padding: 30 }} onClick={executaLogout}>
          <MdLogout size={24} color={"#16898E"} />
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <span
              style={{
                color: "#16898E",
                marginLeft: 8,
                fontWeight: "bold",
              }}
            >
              Sair
            </span>
          </div>
        </div>
      </Box>
    </>
  );
};
