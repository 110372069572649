import React, { Component, Fragment } from "react";
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2'
import api from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";

export function spacing(points = 1) {
  const value = 16 * points;

  return `${value}px`;
}

const Ul = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;
    color: #7a7a7a;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    line-height: 1.333;
    margin: -20px 0 0;
    line-height: 1.666;
`;

const Li = styled.li`
    text-decoration: ${(props) =>
        props.state === 'valid' ? 'line-through' : 'none'};
    color: ${(props) => (props.state === 'invalid' ? '#ee3838' : 'inherit')};

    &:first-of-type {
        margin-bottom: ${spacing(0.25)};
        width: 100%;
    }

    &:not(:first-of-type) {
        margin-right: ${spacing(0.5)};

        &:before {
            content: '';
            display: inline-block;
            background: ${(props) =>
                props.state === 'invalid' ? '#ee3838' : '#7a7a7a'};
            border-radius: 100%;
            height: 4px;
            margin-right: ${spacing(0.5)};
            width: 4px;
            vertical-align: middle;
        }
    }
`;

export class PasswordChecker extends Component {


  constructor(props) {

    super(props);

    const { password, hasError }     = this.props;
    
    this.state = {
      password: password,
      hasError: hasError
    }; 

    

  }




  render() {
    const length =
    this.state.password.length >= 10 ? 'valid' : this.state.hasError ? 'invalid' : 'rest';
    const numbers = /[0-9]/.test(this.state.password)
        ? 'valid'
        : this.state.hasError
        ? 'invalid'
        : 'rest';
    const lowercase = /[a-z]/.test(this.state.password)
        ? 'valid'
        : this.state.hasError
        ? 'invalid'
        : 'rest';
    const uppercase = /[A-Z]/.test(this.state.password)
        ? 'valid'
        : this.state.hasError
        ? 'invalid'
        : 'rest';
    const symbols = /[!@#$%^&*()_]/.test(this.state.password)
        ? 'valid'
        : this.state.hasError
        ? 'invalid'
        : 'rest';
    return (
      <Ul>
        <Li state={this.state.hasError ? 'invalid' : 'rest'}>Sua senha deve ter:</Li>
        <Li state={length}>No mínimo 10 caracteres</Li>
        <Li state={uppercase}>Letras maiúsculas</Li>
        <Li state={lowercase}>Letras minúsculas</Li>
        <Li state={numbers}>Números</Li>
        <Li state={symbols}>Símbolos (!@#$%^&*()_)</Li>
      </Ul>
    )
  }
}


export default PasswordChecker;
