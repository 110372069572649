import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import api from "../../services/api";
import { Link } from "react-router-dom";
import SweetAlert from 'sweetalert2-react';
import Swal from 'sweetalert2'



export class VisaoReanaliseAprovada extends Component {



  constructor(){
        super();
        this.state = {
          reanalises: [],
          show:  false
        };
  }

  async componentDidMount() {

        try {
          const response = await api.post("api/concash/1.0/visao-reanalise-aprovada/listar", {});
          
          this.setState({ reanalises: response.data});
        } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter as reanalises aprovadas."
          });
        }
  }


  render() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Reanálises Aprovadas </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th> Administradora </th>
                          <th> Grupo </th>
                          <th> Cota </th>
                          <th> Cliente </th>
                          <th className="text-center"> Ação </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.reanalises.map((reanalise,index) => (
                        <tr key={index}>
                          <td> {reanalise.cota.grupo.consorcio.administradora} </td>
                          <td> {reanalise.cota.grupo.grupo} </td>
                          <td> {reanalise.cota.contrato} </td>
                          <td> {reanalise.cota.cliente.nome} </td>
                          <td className="text-center"><Link
                                          to={{pathname: "/comercial/negociacoes/detalhamento",state: {
                                            codigo: reanalise.cota.codigo
                                          }}}
                                          className="m-3 btn btn-sm btn-success"
                                        >
                                        Analisar
                              </Link>
                          </td>
                        </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default VisaoReanaliseAprovada
