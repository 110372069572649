import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ScrollToTop from "./lib/ScrollToTop";
import { isAuthenticated } from "./services/auth";
import pack from "../package.json";

import SignIn from "./pages/SignIn";
import NovaSenha from "./pages/EsqueciSenha";
import Dashboard from "./pages/Dashboard/Dashboard";
import Simulador from "./pages/Simulador/Simulador";
import Parametros from "./pages/Parametros/Lista";
import ParametrosForm from "./pages/Parametros/Form";
import WhatsappLista from "./pages/Whatsapp/Lista";
import WhatsappPendentes from "./pages/Whatsapp/Pendentes";
import WhatsappForm from "./pages/Whatsapp/Form";
import WhatsappSessao from "./pages/WhatsappSessao/Lista";
import WhatsappSessaoConversa from "./pages/WhatsappSessao/Conversa";
import Funcoes from "./pages/Funcao/Lista";
import FuncoesForm from "./pages/Funcao/Form";
import FuncaoConfigurar from "./pages/Funcao/Configurar";
import TipoParametro from "./pages/TipoParametro/Lista";
import TipoParametroForm from "./pages/TipoParametro/Form";
import TipoProcesso from "./pages/TipoProcesso/Lista";
import TipoProcessoForm from "./pages/TipoProcesso/Form";
import TipoProcessoConfigurar from "./pages/TipoProcesso/Configurar";
import Imposto from "./pages/Imposto/Lista";
import ImpostoForm from "./pages/Imposto/Form";
import Banco from "./pages/Banco/Lista";
import BancoForm from "./pages/Banco/Form";
import Prospect from "./pages/Prospect/Lista";
import ProspectForm from "./pages/Prospect/Form";
import ImpostoBase from "./pages/ImpostoBase/Lista";
import ImpostoBaseForm from "./pages/Imposto/Form";
import VisaoClienteBloqueadoKyc from "./pages/VisaoClienteBloqueadoKYC/Lista";
import VisaoClientePropostaRecusada from "./pages/VisaoClientePropostaRecusada/Lista";
import VisaoCotaDocumentacaoAssinada from "./pages/VisaoCotaDocumentacaoAssinada/Lista";
import VisaoCotaReprovadaLista from "./pages/VisaoCotaReprovada/Lista";
import VisaoCotaReprovadaForm from "./pages/VisaoCotaReprovada/AnalisarCotaReprovada";
import VisaoReanaliseAprovadaLista from "./pages/VisaoReanaliseAprovada/Lista";
import VisaoReanaliseAprovadaForm from "./pages/VisaoReanaliseAprovada/ReanaliseAprovada";
import HomologarDocumentos from "./pages/VisaoCotaDocumentacaoAssinada/aprovarDocumentacao";
import FormImportacaoSMS from "./pages/Importacao/FormImportacaoSMS";
import FormImportacaoEmail from "./pages/Importacao/FormImportacaoEmail";
import FormImportacaoProspect from "./pages/Importacao/FormImportacaoProspect";
import FormImportacaoCota from "./pages/Importacao/FormImportacaoCota";
import FormImportacaoRecon from "./pages/Importacao/FormImportacaoRecon";
import FormImportacaoBaseContemplacao from "./pages/Importacao/FormImportacaoBaseContemplacao";
import ImpostoRegime from "./pages/RegimeTributario/Lista";
import ImpostoRegimeForm from "./pages/RegimeTributario/Form";
import ImpostoPercentual from "./pages/ImpostoPercentual/Lista";
import ImpostoPercentualForm from "./pages/ImpostoPercentual/Form";
import ModeloRegraProposta from "./pages/ModeloRegraProposta/Lista";
import ModeloRegraPropostaForm from "./pages/ModeloRegraProposta/Form";
import TipoDespesa from "./pages/TipoDespesa/Lista";
import TipoDespesaForm from "./pages/TipoDespesa/Form";
import Despesa from "./pages/Despesa/Lista";
import DespesaForm from "./pages/Despesa/Form";
import Cliente from "./pages/Cliente/Lista";
import ClienteForm from "./pages/Cliente/Form";
import ConsorcioGrupoCota from "./pages/ConsorcioGrupoCota/Lista";
import DadosCota from "./pages/ConsorcioGrupoCota/DadosCota";
import Administradora from "./pages/Administradora/Lista";
import AdministradoraForm from "./pages/Administradora/Form";
import AdministradoraConfigurar from "./pages/Administradora/Configurar";
import AdministradoraRegua from "./pages/Administradora/Regua";
import Acao from "./pages/Acao/Lista";
import AcaoForm from "./pages/Acao/Form";
import Cessionaria from "./pages/Cessionaria/Lista";
import CessionariaForm from "./pages/Cessionaria/Form";
import CessionariaConfigurar from "./pages/Cessionaria/Configurar";
import TipoConsorcio from "./pages/TipoConsorcio/Lista";
import TipoConsorcioForm from "./pages/TipoConsorcio/Form";
import TipoConsorcioConfigurar from "./pages/TipoConsorcio/Configurar";
import Aplicativo from "./pages/Aplicativo/Lista";
import AplicativoForm from "./pages/Aplicativo/Form";
import GrupoFuncionalidade from "./pages/GrupoFuncionalidade/Lista";
import GrupoFuncionalidadeForm from "./pages/GrupoFuncionalidade/Form";
import Funcionalidade from "./pages/Funcionalidade/Lista";
import FuncionalidadeForm from "./pages/Funcionalidade/Form";
import FuncionalidadeConfigurar from "./pages/Funcionalidade/Configurar";
import Usuario from "./pages/Usuario/Lista";
import UsuarioForm from "./pages/Usuario/Form";
import Conta from "./pages/Conta/Form";
import UsuarioVisualizacao from "./pages/Usuario/Visualizacao";
import UsuarioConfigurar from "./pages/Usuario/Configurar";
import TelaProdutoInicial from "./pages/Produtos/Inicial";
import ProdutoReanalise from "./pages/Produtos/Reanalise";
import ProdutosReanaliseAnalise from "./pages/Produtos/Analise";
import TelaBackofficeInicial from "./pages/Backoffice/Inicial";
import TelaComercialInicial from "./pages/Comercial/Inicial";
import TelaComercialNegociacoes from "./pages/Comercial/Negociacoes";
import TelaComercialProspect from "./pages/Comercial/Prospect";
import TelaComercialLista from "./pages/Comercial/Lista";
import ComercialListaReprovado from "./pages/Comercial/ListaReprovado";
import ComercialListaNegociacao from "./pages/Comercial/ListaEmNegociacao";
import ComercialListaAguardandoDocumentacao from "./pages/Comercial/ListaAguardandoDocumentacao";
import BackofficeListaDocumentacaoAssinada from "./pages/Backoffice/ListaDocumentacaoAssinada";
import BackofficeListaDocumentacaoEmAberto from "./pages/Backoffice/ListaDocumentacaoEmAberto";
import BackofficeTransferenciaCota from "./pages/Backoffice/ListaTransferenciaCota";
import TelaBackofficeDetalhamento from "./pages/Backoffice/Detalhamento";
import TelaBackofficeDetalhamentoCota from "./pages/Backoffice/DetalhamentoCotaBackoffice";
import TelaDetalhamentoCota from "./pages/Backoffice/DetalhamentoCota";
import TelaBackofficeDetalhamentoCliente from "./pages/Backoffice/DetalhamentoCliente";
import TelaComercialDetalhamento from "./pages/Comercial/Detalhamento";
import TelaComercialDetalhamentoProspect from "./pages/Comercial/DetalhamentoProspect";
import TelaFinanceiraInicial from "./pages/Financeiro/Inicial";
import FinanceiroDemanda from "./pages/Financeiro/Demanda";
import FinanceiroDemandaForm from "./pages/Financeiro/FormDemanda";
import FinanceiroConfirmacaoPagamento from "./pages/Financeiro/FormConfirmacaoPagamento";
import FinanceiroInclusaoValores from "./pages/Financeiro/Inclusao";
import FinanceiroInclusaoValoresForm from "./pages/Financeiro/FormInclusao";
import FinanceiroOrdemPagamento from "./pages/Financeiro/ListaOrdemPagamento";
import FinanceiroDetalhamentoOrdemPagamento from "./pages/Financeiro/DetalhamentoOrdemPagamento";
import FinanceiroDetalhamentoPagamentoAutorizado from "./pages/Financeiro/DetalhamentoPagamentoAutorizado";
import FinanceiroPagamentosAutorizados from "./pages/Financeiro/ListaPagamentosAutorizados";
import FinanceiroListaCadastroLances from "./pages/Financeiro/ListaCadastroLances";
import FinanceiroListaCadastroParcelas from "./pages/Financeiro/ListaCadastroParcelas";
import FinanceiroListaAssembleia from "./pages/Financeiro/ListaAssembleia";
import ListaProspectFaleConosco from "./pages/Comercial/ListaProspectFaleConosco";
import ListaProspectLanding from "./pages/Comercial/ListaProspectLanding";
import ListaProspectPortal from "./pages/Comercial/ListaProspectPortal";
import ProcessoPendentes from "./pages/Processo/Pendentes";
import Configuracao from "./pages/Configuracao/Inicial";
import FinanceiroDetalhamentoCadastroLanceParcela from "./pages/Financeiro/DetalhamentoCadastroLanceParcela";
import LogBanco from "./pages/LogBanco/Lista";
import Navbar from "./pages/shared/Navbar";
import Sidebar from "./pages/shared/Sidebar";
import Footer from "./pages/shared/Footer";
import "./pages/App.scss";
import Cadastro from "./pages/Cadastro";
import { UploadCotaForm } from "./pages/UploadCota/Form";
import { UploadAnaliseForm } from "./pages/UploadAnalise/Form";
import UpdatePassword from "./pages/UpdatePassword";
import { PropostaForm } from "./pages/ConsorcioGrupoCota/Propostas";
import RecuperarSenha from "./pages/RecuperarSenha";
import NovaCota from "./pages/ConsorcioGrupoCota/NovaCota";
import SucessoNovaCota from "./pages/ConsorcioGrupoCota/SucessoNovaCota";
import OTPCNP from "./pages/OTP";
import { AutoServico } from "./pages/AutoServico";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <div className="container-scroller">
          <Navbar {...props} />
          <div className="container-fluid page-body-wrapper">
            <Sidebar />
            <div className="main-panel">
              <div className="content-wrapper" style={{ paddingLeft: "19px" }}>
                <Component {...props} />
              </div>
              <Footer />
            </div>
          </div>
        </div>
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => (
  <BrowserRouter>
    <ScrollToTop />
    <Switch>
      <Route path="/health">
        <h3>Versão{pack.version}</h3>
      </Route>
      <Route exact path="/" component={SignIn} />
      <Route exact path="/UpdatePassword" component={UpdatePassword} />
      <Route exact path="/Cadastro" component={Cadastro} />
      <Route exact path="/recuperar-senha" component={RecuperarSenha} />
      <Route exact path="/nova-senha/:token" component={NovaSenha} />
      <PrivateRoute path="/app" component={Dashboard} />
      <PrivateRoute path="/simulador" component={Simulador} />
      <PrivateRoute exact path="/parametro" component={Parametros} />
      <PrivateRoute exact path="/conta" component={Conta} />
      <PrivateRoute exact path="/configuracao" component={Configuracao} />
      <PrivateRoute
        exact
        path="/log-banco"
        component={(...props) => <LogBanco {...props} />}
      />
      <PrivateRoute
        exact
        path="/parametro/detalhe"
        component={(...props) => <ParametrosForm {...props} />}
      />
      <PrivateRoute
        exact
        path="/processo/pendentes"
        component={ProcessoPendentes}
      />
      <PrivateRoute
        exact
        path="/whatsapp/pendentes"
        component={WhatsappPendentes}
      />
      <PrivateRoute exact path="/whatsapp-sessao" component={WhatsappSessao} />
      <PrivateRoute
        exact
        path="/whatsapp-sessao/conversa"
        component={(...props) => <WhatsappSessaoConversa {...props} />}
      />
      <PrivateRoute exact path="/whatsapp" component={WhatsappLista} />
      <PrivateRoute
        exact
        path="/whatsapp/detalhe"
        component={(...props) => <WhatsappForm {...props} />}
      />
      <PrivateRoute exact path="/funcao" component={Funcoes} />
      <PrivateRoute
        exact
        path="/funcao/detalhe"
        component={(...props) => <FuncoesForm {...props} />}
      />
      <PrivateRoute
        exact
        path="/funcao/configurar"
        component={(...props) => <FuncaoConfigurar {...props} />}
      />
      <PrivateRoute exact path="/banco" component={Banco} />
      <PrivateRoute
        exact
        path="/banco/detalhe"
        component={(...props) => <BancoForm {...props} />}
      />
      <PrivateRoute
        exact
        path="/aprovar-documentos"
        component={(...props) => <HomologarDocumentos {...props} />}
      />
      <PrivateRoute exact path="/produtos" component={TelaProdutoInicial} />
      <PrivateRoute
        exact
        path="/produtos/reanalise"
        component={ProdutoReanalise}
      />
      <PrivateRoute
        exact
        path="/produtos/reanalise/analisar"
        component={(...props) => <ProdutosReanaliseAnalise {...props} />}
      />
      <PrivateRoute
        exact
        path="/comercial/prospect/detalhamento"
        component={(...props) => (
          <TelaComercialDetalhamentoProspect {...props} />
        )}
      />
      <PrivateRoute
        exact
        path="/comercial/negociacoes/detalhamento"
        component={(...props) => <TelaComercialDetalhamento {...props} />}
      />
      <PrivateRoute
        exact
        path="/comercial/negociacoes/lista"
        component={(...props) => <TelaComercialLista {...props} />}
      />
      <PrivateRoute
        exact
        path="/comercial/negociacoes"
        component={TelaComercialNegociacoes}
      />
      <PrivateRoute
        exact
        path="/comercial/prospect"
        component={TelaComercialProspect}
      />
      <PrivateRoute
        exact
        path="/comercial/lista-reprovado"
        component={ComercialListaReprovado}
      />
      <PrivateRoute
        exact
        path="/comercial/lista-em-negociacao"
        component={ComercialListaNegociacao}
      />
      <PrivateRoute
        exact
        path="/comercial/lista-aguardando-documentacao"
        component={ComercialListaAguardandoDocumentacao}
      />
      <PrivateRoute exact path="/comercial" component={TelaComercialInicial} />
      <PrivateRoute
        exact
        path="/comercial/lista-prospect-fale-conosco"
        component={ListaProspectFaleConosco}
      />
      <PrivateRoute
        exact
        path="/comercial/lista-prospect-landing"
        component={ListaProspectLanding}
      />
      <PrivateRoute
        exact
        path="/comercial/lista-prospect-portal"
        component={ListaProspectPortal}
      />
      <PrivateRoute
        exact
        path="/backoffice/detalhamento-cota"
        component={(...props) => <TelaDetalhamentoCota {...props} />}
      />
      <PrivateRoute
        exact
        path="/backoffice/detalhamento-cota-backoffice"
        component={(...props) => <TelaBackofficeDetalhamentoCota {...props} />}
      />
      <PrivateRoute
        exact
        path="/backoffice/detalhamento-cliente"
        component={(...props) => (
          <TelaBackofficeDetalhamentoCliente {...props} />
        )}
      />
      <PrivateRoute
        exact
        path="/backoffice/detalhamento"
        component={(...props) => <TelaBackofficeDetalhamento {...props} />}
      />
      <PrivateRoute
        exact
        path="/backoffice/documentacoes-assinadas"
        component={BackofficeListaDocumentacaoAssinada}
      />
      <PrivateRoute
        exact
        path="/backoffice/documentacoes-em-aberto"
        component={BackofficeListaDocumentacaoEmAberto}
      />
      <PrivateRoute
        exact
        path="/backoffice/transferencia-cota"
        component={BackofficeTransferenciaCota}
      />
      <PrivateRoute
        exact
        path="/backoffice"
        component={TelaBackofficeInicial}
      />
      <PrivateRoute
        exact
        path="/financeiro"
        component={TelaFinanceiraInicial}
      />
      <PrivateRoute
        exact
        path="/financeiro/demanda"
        component={FinanceiroDemanda}
      />
      <PrivateRoute
        exact
        path="/financeiro/inclusao-valores"
        component={FinanceiroInclusaoValores}
      />
      <PrivateRoute
        exact
        path="/financeiro/inclusao-valores/incluir"
        component={(...props) => <FinanceiroInclusaoValoresForm {...props} />}
      />
      <PrivateRoute
        exact
        path="/financeiro/demanda/incluir"
        component={(...props) => <FinanceiroDemandaForm {...props} />}
      />
      <PrivateRoute
        exact
        path="/financeiro/detalhamento-ordem-pagamento"
        component={(...props) => (
          <FinanceiroDetalhamentoOrdemPagamento {...props} />
        )}
      />
      <PrivateRoute
        exact
        path="/financeiro/confirmacao-pagamento"
        component={(...props) => <FinanceiroConfirmacaoPagamento {...props} />}
      />
      <PrivateRoute
        exact
        path="/financeiro/lista-ordem-pagamento"
        component={FinanceiroOrdemPagamento}
      />
      <PrivateRoute
        exact
        path="/financeiro/lista-pagamentos-autorizados"
        component={FinanceiroPagamentosAutorizados}
      />
      <PrivateRoute
        exact
        path="/financeiro/lista-cadastro-parcelas"
        component={FinanceiroListaCadastroParcelas}
      />
      <PrivateRoute
        exact
        path="/financeiro/lista-cadastro-lances"
        component={FinanceiroListaCadastroLances}
      />
      <PrivateRoute
        exact
        path="/financeiro/lista-assembleias"
        component={FinanceiroListaAssembleia}
      />
      <PrivateRoute
        exact
        path="/financeiro/detalhamento-pagamento-autorizado"
        component={(...props) => (
          <FinanceiroDetalhamentoPagamentoAutorizado {...props} />
        )}
      />
      <PrivateRoute
        exact
        path="/financeiro/detalhamento-cadastro-lance-parcela"
        component={(...props) => (
          <FinanceiroDetalhamentoCadastroLanceParcela {...props} />
        )}
      />
      <PrivateRoute exact path="/prospect" component={Prospect} />
      <PrivateRoute
        exact
        path="/prospect/detalhe"
        component={(...props) => <ProspectForm {...props} />}
      />
      <PrivateRoute exact path="/tipo-parametro" component={TipoParametro} />
      <PrivateRoute
        exact
        path="/tipo-parametro/detalhe"
        component={(...props) => <TipoParametroForm {...props} />}
      />
      <PrivateRoute exact path="/tipo-processo" component={TipoProcesso} />
      <PrivateRoute
        exact
        path="/tipo-processo/detalhe"
        component={(...props) => <TipoProcessoForm {...props} />}
      />
      <PrivateRoute
        exact
        path="/tipo-processo/configurar"
        component={(...props) => <TipoProcessoConfigurar {...props} />}
      />
      <PrivateRoute
        exact
        path="/visao-reanalise-aprovada"
        component={VisaoReanaliseAprovadaLista}
      />
      <PrivateRoute
        exact
        path="/reanalise-aprovada"
        component={(...props) => <VisaoReanaliseAprovadaForm {...props} />}
      />
      <PrivateRoute
        exact
        path="/analisar-cota"
        component={(...props) => <VisaoCotaReprovadaForm {...props} />}
      />
      <PrivateRoute
        exact
        path="/visao-cota-proposta-recusada"
        component={VisaoCotaReprovadaLista}
      />
      <PrivateRoute
        exact
        path="/visao-cliente-bloqueado-questionario"
        component={VisaoClienteBloqueadoKyc}
      />
      <PrivateRoute
        exact
        path="/visao-cliente-proposta-recusada"
        component={VisaoClientePropostaRecusada}
      />
      <PrivateRoute
        exact
        path="/visao-documentacao-assinada"
        component={VisaoCotaDocumentacaoAssinada}
      />
      <PrivateRoute exact path="/imposto" component={Imposto} />
      <PrivateRoute
        exact
        path="/imposto/detalhe"
        component={(...props) => <ImpostoForm {...props} />}
      />
      <PrivateRoute
        exact
        path="/imposto-percentual"
        component={ImpostoPercentual}
      />
      <PrivateRoute
        exact
        path="/importacao-grupo-historico"
        component={(...props) => <FormImportacaoBaseContemplacao {...props} />}
      />
      <PrivateRoute
        exact
        path="/importacao-recon"
        component={(...props) => <FormImportacaoRecon {...props} />}
      />
      <PrivateRoute
        exact
        path="/importacao-sms"
        component={(...props) => <FormImportacaoSMS {...props} />}
      />
      <PrivateRoute
        exact
        path="/importacao-email"
        component={(...props) => <FormImportacaoEmail {...props} />}
      />
      <PrivateRoute
        exact
        path="/importacao-prospect"
        component={(...props) => <FormImportacaoProspect {...props} />}
      />
      <PrivateRoute
        exact
        path="/importacao-cota"
        component={(...props) => <FormImportacaoCota {...props} />}
      />
      <PrivateRoute
        exact
        path="/imposto-percentual/detalhe"
        component={(...props) => <ImpostoPercentualForm {...props} />}
      />
      <PrivateRoute exact path="/imposto-base" component={ImpostoBase} />
      <PrivateRoute
        exact
        path="/imposto-base/detalhe"
        component={(...props) => <ImpostoBaseForm {...props} />}
      />
      <PrivateRoute
        exact
        path="/imposto-regime-tributario"
        component={ImpostoRegime}
      />
      <PrivateRoute
        exact
        path="/imposto-regime-tributario/detalhe"
        component={(...props) => <ImpostoRegimeForm {...props} />}
      />
      <PrivateRoute
        exact
        path="/modelo-regra-proposta"
        component={ModeloRegraProposta}
      />
      <PrivateRoute
        exact
        path="/modelo-regra-proposta/detalhe"
        component={(...props) => <ModeloRegraPropostaForm {...props} />}
      />
      <PrivateRoute exact path="/tipo-despesa" component={TipoDespesa} />
      <PrivateRoute
        exact
        path="/tipo-despesa/detalhe"
        component={(...props) => <TipoDespesaForm {...props} />}
      />
      <PrivateRoute exact path="/despesa" component={Despesa} />
      <PrivateRoute
        exact
        path="/despesa/detalhe"
        component={(...props) => <DespesaForm {...props} />}
      />
      <PrivateRoute exact path="/cliente" component={Cliente} />
      <PrivateRoute
        exact
        path="/cliente/detalhe"
        component={(...props) => <ClienteForm {...props} />}
      />
      <PrivateRoute
        exact
        path="/consorcio-grupo-cota"
        component={ConsorcioGrupoCota}
      />
      <PrivateRoute
        exact
        path="/proposta-cota"
        component={(...props) => <PropostaForm {...props} />}
      />
      <PrivateRoute
        exact
        path="/nova-cota"
        component={NovaCota}
      />
      <PrivateRoute
        exact
        path="/sucesso-nova-cota"
        component={SucessoNovaCota}
      />
      <PrivateRoute exact path="/upload-cota" component={UploadCotaForm} />
      <PrivateRoute
        exact
        path="/upload-analise"
        component={UploadAnaliseForm}
      />
      <PrivateRoute
        exact
        path="/consorcio-grupo-cota/detalhe"
        component={(...props) => <DadosCota {...props} />}
      />
      <PrivateRoute exact path="/tipo-consorcio" component={TipoConsorcio} />
      <PrivateRoute
        exact
        path="/tipo-consorcio/detalhe"
        component={(...props) => <TipoConsorcioForm {...props} />}
      />
      <PrivateRoute
        exact
        path="/tipo-consorcio/configurar"
        component={(...props) => <TipoConsorcioConfigurar {...props} />}
      />
      <PrivateRoute exact path="/administradora" component={Administradora} />
      <PrivateRoute
        exact
        path="/administradora/detalhe"
        component={(...props) => <AdministradoraForm {...props} />}
      />
      <PrivateRoute
        exact
        path="/administradora/configurar"
        component={(...props) => <AdministradoraConfigurar {...props} />}
      />
      <PrivateRoute
        exact
        path="/administradora/regua"
        component={(...props) => <AdministradoraRegua {...props} />}
      />
      <PrivateRoute exact path="/cessionaria" component={Cessionaria} />
      <PrivateRoute
        exact
        path="/cessionaria/detalhe"
        component={(...props) => <CessionariaForm {...props} />}
      />
      <PrivateRoute
        exact
        path="/cessionaria/configurar"
        component={(...props) => <CessionariaConfigurar {...props} />}
      />
      <PrivateRoute exact path="/aplicativo" component={Aplicativo} />
      <PrivateRoute
        exact
        path="/aplicativo/detalhe"
        component={(...props) => <AplicativoForm {...props} />}
      />
      <PrivateRoute
        exact
        path="/grupo-funcionalidade"
        component={GrupoFuncionalidade}
      />
      <PrivateRoute
        exact
        path="/grupo-funcionalidade/detalhe"
        component={(...props) => <GrupoFuncionalidadeForm {...props} />}
      />
      <PrivateRoute exact path="/funcionalidade" component={Funcionalidade} />
      <PrivateRoute
        exact
        path="/funcionalidade/detalhe"
        component={(...props) => <FuncionalidadeForm {...props} />}
      />
      <PrivateRoute
        exact
        path="/funcionalidade/configurar"
        component={(...props) => <FuncionalidadeConfigurar {...props} />}
      />
      <PrivateRoute exact path="/usuario" component={Usuario} />
      <PrivateRoute
        exact
        path="/usuario/detalhe"
        component={(...props) => <UsuarioForm {...props} />}
      />
      <PrivateRoute
        exact
        path="/usuario/visualizacao"
        component={(...props) => <UsuarioVisualizacao {...props} />}
      />
      <PrivateRoute
        exact
        path="/usuario/configurar"
        component={(...props) => <UsuarioConfigurar {...props} />}
      />
      <PrivateRoute exact path="/acao" component={Acao} />
      <PrivateRoute
        exact
        path="/acao/detalhe"
        component={(...props) => <AcaoForm {...props} />}
      />
      <Route
        exact
        path="/otp-cnp"
        component={(...props) => <OTPCNP {...props} />}
      />
      <Route
        exact
        path="/autoservico/*"
        component={(...props) => <AutoServico {...props} />}
      />
      <Route
        exact
        path="/autoservico"
        component={(...props) => <AutoServico {...props} />}
      />

      <Route path="*" component={() => <h1>Page not found</h1>} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
