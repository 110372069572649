import React, { useState, useEffect } from "react";
import { BaseDesktop, BaseMobile } from "../components/common/Base";
import S from "../Autoservico.module.css";
import { CardDadosBancarios, CardDadosPessoais, CardDadosRepresentante, CardEndereco } from "../components/Cards/CardsDados";
import { FinalizarEscolherConta, FormDadosBancarios, FormDadosPessoais, FormDadosRepresentante, FormEndereco } from "./Forms";
import { ModalFinalizar, ModalMain, ModalStandard } from "../components/common/ModalAutoServico";
import api from "../../../services/api";
import useAutoservico, { useToken } from "../../../store/autoservico/useAutoservico";
import { v3FormataCNPJ, v3MaskCPF } from "../../../styles/themes/utils/format";
import { maskCEP } from "../../../mask/maskCEP";
import { useDispatch, useSelector } from "react-redux";
import { logoutAuto, updateAtendimento } from "../../../store/autoservico";
import moment from "moment";
import { formatCpfCnpj } from "../../../utils/format";
import { listTiposConta, listTiposPix } from "./Forms/defaults";
import { idAdm } from "./detalhes";
import { V3Modal } from "../../../styles/themes/components/modal";
import LoadingCustom from "../../../components/LoadingCustom";

export const MeusDados = ({ estilo }) => {
 const dispatch = useDispatch();
  const service = window.location.pathname.replace("/autoservico/", "").split("/");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [screenOpen, setScreenOpen] = useState("");
  const [ question, setQuestion ] = useState(false);
  const [enviarFotoDoc, setEnviarFotoDoc] = useState(false);
  const [ dadosCliente, setDadosCliente ] = useState("");
  const [ listEstados, setListEstados ] = useState([]);
  const [ estado, setEstado ] = useState("");
  const [ cotasCliente, setCotasCliente] = useState([]);
  const [aceitou, setAceitou] = useState(false);
  const [ contasCadastradas, setContasCadastradas] = useState([]);
  const state = useSelector((state) => state.autoservico);
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [ loading, setLoading ]= useState(false);

  
  function handleNovaConta() {
    setScreenOpen("dados-bancarios");
  }

  const {getTokenAutoServico} = useAutoservico();

  async function handleGetUFs() {
    await api.post("/api/concash/1.0/cliente-area/estados", {})
    .then(({data}) => {
      setListEstados(data)
    })
    .catch((e) => {
      console.log(e);
    })
  }

  function verifyAceite(quotes){
    const current = quotes?.find((cota) => cota?.idAtendimento === state?.idAtendimento);
    setAceitou(current?.statusAtendimento == 11);
  }

  async function handleGetNegociacoes(d) {
    const doc = d.replace(/\D/g, "");
    await api
      .get(
        `/api/concash/1.0/consorcio-grupo-cota/negociacoes?cpfCnpj=${doc}&idAdministradora=${idAdm[service[0]]}&Tokenconsort=${state.otp}`,{
          headers: {
            TokenConsort: state.tokenConsort
          }
        }
      ).then(({data}) => {
        setCotasCliente(data);
        verifyAceite(data);
      dispatch(updateAtendimento(data[0]));
    })
    .catch((e) => {
      console.log(e);
      setCotasCliente([]);
      setErrorText("Erro ao buscar cotas.");
      return setHasError(true);
    })
  }

  async function handleGetDataCliente() {
    const token = getTokenAutoServico();

    const { data } = await api.get("/api/concash/1.0/consorcio-grupo-cota/recuperar-dados-atendimento", {
      headers: {
        Authorization: "Bearer " + token,
      },
    }).catch((e) => {
      dispatch(logoutAuto());
      window.location.href = "/autoservico/"+service[0]+"/login"
      return console.log(e)
    });
    
    let doc;
    if(String(data?.dadosPessoais.cpfCnpj).length <= 11) {
      const dc = String(data?.dadosPessoais.cpfCnpj).padStart(11, "0")
      doc = v3MaskCPF(dc);
    } else {
      const dc = String(data?.dadosPessoais.cpfCnpj).padStart(14, "0")
      doc = v3FormataCNPJ(dc)
    }
    handleGetNegociacoes(doc);
    const ctCad = data?.dadosBancarios.map((c, i) => {
      return {
        id: i,
        nome: data?.dadosPessoais.nomeRazao,
        ...c
      }
    });
    setContasCadastradas(ctCad);
    const dpPF = !!data?.dadosPessoais?.rg && !!String(data?.dadosPessoais?.estadoCivil) && !!String(data?.dadosPessoais?.genero) && !!data?.dadosPessoais?.orgaoEmissor && !!String(data?.dadosPessoais?.profissao);
    const dpPJ = !!data?.dadosPessoais?.cnae && !!data?.dadosPessoais?.nomeFantasia && !!String(data?.dadosPessoais?.paisCompanhia) && !!String(data?.dadosPessoais?.situacaoEconomica) && !!String(data?.dadosPessoais?.tipoCompanhia);  
    const dp = data?.dadosPessoais?.nomeRazao && data?.dadosPessoais?.cpfCnpj && data?.dadosPessoais?.dataNascimento && data?.dadosPessoais?.email && data?.dadosPessoais?.telefone  && data.enviouDocumentosIdentificacao && (state?.tipoPessoa === "PJ" ? dpPJ : dpPF);
    const end = data?.dadosEndereco.logradouro && (String(data?.dadosPessoais.cpfCnpj).length > 11 ? true : data?.enviouDocumentosResidencia) && data?.dadosEndereco.cep && data?.dadosEndereco.bairro && data?.dadosEndereco.cidade && data?.dadosEndereco.estado;
    const rep = state?.tipoPessoa === "PJ" && data?.dadosRepresentante?.nomeRepresentante && data?.dadosRepresentante?.cpfRepresentante && data?.dadosRepresentante?.dataNascimentoRepresentante && data?.dadosRepresentante?.emailRepresentante;
    const tipoCt = listTiposConta.find((t) => t.id == data?.dadosBancarios[0]?.tipoConta);
    const tipopx = listTiposPix.find((t) => t.id == data?.dadosBancarios[0]?.tipoPix);
    const estd = listEstados?.find((es) => es.estNome == data?.dadosEndereco?.estado);
    setEstado(estd?.estUf);

    setDadosCliente({
      dadosPessoais: {
        idAtendimento: data?.idAtendimento,
        concluido: dp,
        nome: data?.dadosPessoais?.nomeRazao,
        rg: data?.dadosPessoais?.rg,
        fotosDoc: data.enviouDocumentosIdentificacao,
        email: data?.dadosPessoais?.email,
        telefone: data?.dadosPessoais?.telefone,
        ...data?.dadosPessoais,
        cpfCnpj: doc,
        dataNascimento: data?.dadosPessoais.dataNascimento && moment(data?.dadosPessoais.dataNascimento.replace("BRT ", "").replace("BRST ", "")).format("DD/MM/YYYY")
      },
      endereco: {
        nomeRazao: data?.dadosPessoais?.nomeRazao,
        cpfCnpj: doc,
        ...data?.dadosEndereco,
        concluido: !!end,
        idAtendimento: data?.idAtendimento,
        logradouro: data?.dadosEndereco.logradouro,
        comprovante: data?.enviouDocumentosResidencia,
        cep: maskCEP(String(data?.dadosEndereco.cep).padStart(8, "0")),
        complemento: data?.dadosEndereco?.complemento ? data?.dadosEndereco?.complemento : "",
        bairro: data?.dadosEndereco.bairro,
        cidade: data?.dadosEndereco.cidade,
        uf:  data?.dadosEndereco.estado
      },
      dadosBancarios: {
        ...data?.dadosBancarios[0],
        concluido: data?.dadosBancarios.length > 0,
        nome: data?.dadosPessoais?.nomeRazao,
        cpfCnpj: doc,
        idAtendimento: data?.idAtendimento,
        idBanco: data?.dadosBancarios[0]?.banco,
        banco: data?.dadosBancarios[0]?.nomeBanco,
        agencia: data?.dadosBancarios[0]?.agencia,
        conta: data?.dadosBancarios[0]?.conta,
        digitoConta: data?.dadosBancarios[0]?.digitoConta,
        tipoContaId: data?.dadosBancarios[0]?.tipoConta,
        tipoConta: tipoCt && tipoCt?.label,
        chavePIX: data?.dadosBancarios[0]?.pix,
        tipoChavePIX: tipopx &&tipopx?.label,
        tipoChavePIXID: tipopx && tipopx?.id,
      },
      dadosRepresentante: {
        concluido: rep,
        ...data?.dadosRepresentante,
        dataNascimentoRepresentante:data?.dadosRepresentante ? moment(data?.dadosRepresentante?.dataNascimentoRepresentante).format("DD/MM/YYYY") : "",
        cnpjCliente: data?.dadosPessoais.cpfCnpj,
      }
    })

  }

  useEffect(() => {
    if(service[2] === "endereco") {
      setScreenOpen("endereco")
    }
    if(service[2] === "dados-pessoais") {
      setScreenOpen("dados-pessoais")
    }
    if(service[2] === "dados-bancarios") {
      setScreenOpen("dados-bancarios")
    }
    handleGetUFs();
    handleGetDataCliente();
  }, [])

  function handleSendPhotoDoc() {
    setEnviarFotoDoc(true);
    setScreenOpen("dados-pessoais");
  }
  function handleFormDoc() {
    setEnviarFotoDoc(false);
    setScreenOpen("dados-pessoais");
  }





  if (estilo && dadosCliente){
    return (
      <div>
        <V3Modal
          open={hasError}
          actionClose={() => {
            setHasError(false);
          }}
          title={errorText}
          customButtonColor={estilo?.primary}
          type="warning"
        />
        <FormEndereco estilo={estilo} open={screenOpen === "endereco"} clienteData={dadosCliente?.endereco} atualiza={handleGetDataCliente} closeScreen={() => setScreenOpen("")}  />
        <FormDadosPessoais estilo={estilo} open={screenOpen === "dados-pessoais"} sendDoc={enviarFotoDoc} clienteData={dadosCliente?.dadosPessoais} atualiza={handleGetDataCliente} closeScreen={() => setScreenOpen("")}  />
        <FormDadosRepresentante estilo={estilo} open={screenOpen === "dados-representante"} clienteData={dadosCliente?.dadosRepresentante} atualiza={handleGetDataCliente} closeScreen={() => setScreenOpen("")}  />
        <FormDadosBancarios estilo={estilo} open={screenOpen === "dados-bancarios"}  atualiza={handleGetDataCliente} clienteData={dadosCliente?.dadosBancarios} closeScreen={() => setScreenOpen("")}  />
        {!aceitou && <ModalFinalizar estilo={estilo} onClick={() => setScreenOpen("finalizar")} disabled={!dadosCliente.dadosPessoais.concluido || !dadosCliente.dadosBancarios.concluido || !dadosCliente.endereco.concluido} />}
        <ModalMain estilo={estilo} open={question} onClose={() => setQuestion(false)} texto={"Seus dados pessoais, endereço e cobrança serão utilizados para confeccionarmos seu contrato, termo de transferência e realizar o seu pagamento."} />
        <FinalizarEscolherConta idAtendimento={state?.idAtendimento} atualiza={handleGetDataCliente} contas={contasCadastradas} estilo={estilo}  open={screenOpen === "finalizar"} closeScreen={() => setScreenOpen("")}  novaConta={handleNovaConta} />
        {isMobile ? (
          <>
            <BaseMobile estilo={estilo}>
              <div className={S.contentIntern} style={{ alignItems:"center", padding: "15px 10px"}}>
                <h1 className={S.titlePage} style={{textAlign: "center"}}>Meus dados</h1>
                <h3 className={S.text15} style={{textAlign: "center"}}>Preencha ou confira todas as informações abaixo para seguirmos com a venda da sua cota.</h3>
                <div className={S.detalheCota2} style={{ backgroundColor: estilo.secondary}} onClick={() => setQuestion(true)}>
                    ?
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
              <CardDadosPessoais estilo={estilo} concluido={dadosCliente?.dadosPessoais.concluido} dados={dadosCliente?.dadosPessoais} openDoc={handleSendPhotoDoc} openScreen={handleFormDoc}/>
              <CardEndereco estilo={estilo} concluido={dadosCliente?.endereco.concluido} dados={dadosCliente?.endereco} openScreen={() => setScreenOpen("endereco")} />
              <CardDadosBancarios estilo={estilo} concluido={dadosCliente?.dadosBancarios.concluido} dados={dadosCliente?.dadosBancarios} openScreen={() =>setScreenOpen("dados-bancarios")} />
              {state.tipoPessoa === "PJ" && (
                <CardDadosRepresentante estilo={estilo} concluido={dadosCliente?.dadosRepresentante.concluido} dados={dadosCliente?.dadosRepresentante} openScreen={() =>setScreenOpen("dados-representante")} />
              )}
              </div>
            </BaseMobile>
          </>
        ) : (
          <BaseDesktop estilo={estilo}>
            <div className={S.flexColumn} style={{ width: "100%" }}>
              <h1 className={S.titlePage}>Meus Dados</h1>
              <div style={{ display: "flex", gap: 10}}>
              <h3 className={S.text15}>Preencha ou confira todas as informações abaixo para seguirmos com a venda da sua cota.</h3>
                <div className={S.detalheCota2} style={{ backgroundColor: estilo.secondary}} onClick={() => setQuestion(true)}>
                    ?
                </div>              
              </div>
                <div className={S.contentDados} style={{ gap: 10}}>
                <CardDadosPessoais estilo={estilo} concluido={dadosCliente?.dadosPessoais?.concluido} dados={dadosCliente?.dadosPessoais} openDoc={handleSendPhotoDoc}  openScreen={handleFormDoc}/>
                <CardEndereco estilo={estilo} concluido={dadosCliente?.endereco?.concluido} dados={dadosCliente?.endereco} openScreen={() => setScreenOpen("endereco")} />
                <CardDadosBancarios estilo={estilo} concluido={dadosCliente?.dadosBancarios?.concluido} dados={dadosCliente?.dadosBancarios} openScreen={() => setScreenOpen("dados-bancarios")} />
                {state.tipoPessoa === "PJ" && (
                <CardDadosRepresentante estilo={estilo} concluido={dadosCliente?.dadosRepresentante.concluido} dados={dadosCliente?.dadosRepresentante} openScreen={() =>setScreenOpen("dados-representante")} />
              )}
              </div>
            </div>
          </BaseDesktop>
        )}
      </div>
    ); } else {
      return <LoadingCustom open  />
    }
};
