import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
// import { spacing } from "../Cadastro";

const BREAKPOINTS = {
  mobile: "360px",
  tablet: "768px",
  desktop: "1024px",
  large: "1440px",
};

export function respond(breakpoint, content) {
  return css`
    @media (min-width: ${BREAKPOINTS[breakpoint]}) {
      ${content}
    }
  `;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const Card = styled.div` 
  button:hover{
    background:#1bab9a !important;
    border-color:#1bab9a !important;
  }
  a:hover{
    background:#1bab9a !important;
    border-color:#1bab9a !important;
  }
  button[type="submit"]{
    background: #16B6C5;
    border: 2px;
    border-color: #16B6C5;
    border-radius: 6px;
    border-style: solid;
    box-shadow: 0px 2px 16px rgb(26 162 168 / 40%), 0px 2px 6px rgb(0 0 0 / 10%);
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Poppins',sans-serif;
    font-size: 16px;
    font-weight: 600;
    -webkit-letter-spacing: 0.25px;
    -moz-letter-spacing: 0.25px;
    -ms-letter-spacing: 0.25px;
    letter-spacing: 0.25px;
    line-height: 1.18;
    padding: 16px 32px;
    outline: 0;
    text-align: center;
    -webkit-text-decoration: none;
    text-decoration: none;
    text-transform: uppercase;
  }
  a{
    background: #16B6C5;
    border: 2px;
    border-color: #16B6C5;
    border-radius: 6px;
    border-style: solid;
    box-shadow: 0px 2px 16px rgb(26 162 168 / 40%), 0px 2px 6px rgb(0 0 0 / 10%);
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Poppins',sans-serif;
    font-size: 16px;
    font-weight: 600;
    -webkit-letter-spacing: 0.25px;
    -moz-letter-spacing: 0.25px;
    -ms-letter-spacing: 0.25px;
    letter-spacing: 0.25px;
    line-height: 1.18;
    padding: 16px 32px;
    outline: 0;
    text-align: center;
    -webkit-text-decoration: none;
    text-decoration: none;
    text-transform: uppercase;
  }
   input::file-selector-button {
    background: #16B6C5;
    border: 2px;
    border-color: #16B6C5;
    border-radius: 6px;
    border-style: solid;
    box-shadow: 0px 2px 16px rgb(26 162 168 / 40%), 0px 2px 6px rgb(0 0 0 / 10%);
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Poppins',sans-serif;
    font-size: 16px;
    font-weight: 600;
    -webkit-letter-spa
   }

   input[type=file]::file-selector-button:hover {
    background-color: #16898e;
    border: 2px solid #00cec9;
  }
`;

export const Header = styled.header`
  width: 100%;
  p {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    margin: 0 0 15px 0;
    color: #4d4d4d;
  }
  button {
    background: transparent;
    color: #16898e;
    font-size: 16px;
    margin-top: 15px;
    height: 56px;
    border: 2px;
    border-color: #16898e;
    border-radius: 6px;
    border-style: solid;
    color: #16898e;
    text-align: center;
  }
  hr {
    margin: 20px 0;
    border: none;
    border-bottom: 1px solid #cdcdcd;
    width: 100%;
  }
  a {
    font-size: 16;
    font-weight: bold;
    color: #999;
    text-decoration: none;
  }
  h4 {
    color: #16898e !important;
    font-family: "Poppins", sans-serif !important;
    font-size: 24px !important;
    font-weight: 600 !important;
  }
`;

export const ContainerEtapa = styled.div`
  display: flex;
  flex: 1;
  padding: 20px;
  align-items: center;
  margin-top: -20px;
`;

export const CellEtapa = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 90px;
  height: 20px;
  justify-content: center;
  align-items: center;
  align-text: center;
  color: #888;
  padding: 5px;
`;

export const BoxEtapa = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 0px;
  max-width: 90px;
  min-width: 90px;
  height: 50px;
  text-align: center;
`;

export const DotsCheck = styled.div`
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #05898f;
  border: 1px solid #05898f;
  color: #000;
  align-items: center;
  justify-content: center;
  transform: translateY(50px);
  // position: absolute;
  z-index: 999;
  -webkit-box-shadow: 0px 0px 0px 4px #05898f, 0 0 8px 5px rgba(0, 0, 0, 0.8);
  box-shadow: 0px 0px 0px 4px #05898f, 0 0 8px 5px rgba(0, 0, 0, 0.8);
  cursor: pointer;
`;
export const Dots = styled.div`
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #bce6ec;
  color: #000;
  align-items: center;
  justify-content: center;
  transform: translateY(50px);
  // position: absolute;
  z-index: 999;
  -webkit-box-shadow: 0px 0px 0px 4px #bce6ec, 5px 5px 15px 5px rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 0px 4px #bce6ec, 5px 5px 15px 5px rgba(0, 0, 0, 0);
`;

export const ContainerDots = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  padding: 20px;
  margin-top: -20px;
  justify-content: space-between;
`;

export const TextCell = styled.span`
  font-size: 12px;
  text-align: center;
`;

export const TextDescricaoPasso = styled.span`
  font: normal normal normal 12px/14px Roboto;
  color: #000000;
`;

export const TextInfoPasso = styled.span`
  font: normal normal normal 11px/14px Roboto;
  color: #000000;
  opacity: 0.5;
`;

export const AbaCliente = styled.div`
  display: flex;
  height: 50px;
  border-radius: 50px;
  margin: 5px;
  align-items: center;
  color: #000;
  cursor: pointer;
  background-color: #16898e;
`;

export const BottomCliente = styled.div`
  display: flex;
  margin: 5px;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`;

export const Aba = ({ text, icon, action, initial }) => {
  const [aberto, setAberto] = useState(initial);
  function handleOpen() {
    setAberto(true);
  }

  function handleClose() {
    setAberto(false);
  }

  useEffect(() => {
    // initial ? setAberto(true) : setAberto(false);
  }, []);

  useEffect(() => {
    console.log("inicial " + initial);
    initial !== false ? handleOpen() : handleClose();
    // initial ? setAberto(true) : setAberto(false);
  }, [initial]);

  return (
    <AbaCliente
      style={{
        width: aberto ? 150 : 50,
      }}
      onMouseEnter={() => handleOpen()}
      onMouseLeave={initial ? () => handleOpen() : () => handleClose()}
      onClick={action}
    >
      <BottomCliente>{icon}</BottomCliente>
      <div>{aberto ? <span>{text}</span> : <span></span>}</div>
    </AbaCliente>
  );
};
