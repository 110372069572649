import React, { Component }  from "react";
import { Autocomplete, TextField } from "@mui/material";

export class FieldSelectComArray extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchObjeto: "",
      searchObjetos: [],
      searchObjetoDesc: "",
    };
  }

  onFieldChange(value) {
    const fieldName = this.props.atributo;
    const fieldValue = value;
    this.props.onChange(fieldName, fieldValue);
  }

  render() {
    return (
      <div>
        <Autocomplete
          className="input-form-miu alinharFiltro"
          options={this.props.opcoes}
          onChange={(e, input) => {
            var id = "";
            var label = "";
            if(null != input){
              id = input.value;
              label = input.label;
            }
            this.setState({
              searchObjeto: id,
              searchObjetoDesc: label,
            });
            this.onFieldChange(id)
          }}
          value={ this.props.atributoObjeto == "" ? "" : this.state.searchObjetoDesc  }
          renderInput={(params) => (
            <TextField {...params} label={this.props.label} error={ this.props.erro && this.props.atributoObjeto == "" ? true : false } helperText={ this.props.erro && this.props.atributoObjeto == "" ? this.props.mensagemErro : '' }/>
          )}
          noOptionsText={'Nenhum resultado'}
        />
      </div>
    )
  }
}
export default FieldSelectComArray;
