import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import api from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import IntlCurrencyInput from "react-intl-currency-input";
import { Link, withRouter } from "react-router-dom";

const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};

export class DespesaCota extends Component {
    constructor(props) {
        super(props);

        const { codigoCota } = this.props;

        this.state = {
            codigoCota: codigoCota,
            despesasCotas: [],
            despesas: [],
            boleto: "",
            tiposDespesas: [],
            codigoDespesa: "",
            valor: 0,
            observacao: "",
            tipo: "",
            error: "",
        };
    }

    async componentDidMount() {
        this.obtemDespesasCotas(this.state.codigoCota);
        this.obtemTiposDespesas();
    }

    async obtemDespesasCotas(codigo) {
        try {
            const response = await api.post(
                "api/concash/1.0/despesa-cota/listar",
                { codigoCota: codigo }
            );
            this.setState({ despesasCotas: response.data });
        } catch (err) {
            this.setState({
                error: "Houve um problema para obter as despesas das cotas.",
            });
        }
    }

    async obtemTiposDespesas() {
        try {
            const response = await api.post(
                "api/concash/1.0/tipo-despesa/listar",
                {}
            );
            this.setState({ tiposDespesas: response.data });
        } catch (err) {
            this.setState({
                error: "Houve um problema para obter os tipos de despesas.",
            });
        }
    }

    callDespesas = async (e, tipo) => {
        this.setState({ tipo: tipo });
        try {
            var classe = this;
            const response = await api.post("api/concash/1.0/despesa/listar", {
                tipo: tipo,
            });
            this.setState({ despesas: response.data });
        } catch (err) {
            this.setState({
                error: "Houve um problema para obter as cidades.",
            });
        }
    };

    handleFormDespesaCota = async (e) => {
        e.preventDefault();
        const { codigoCota, codigoDespesa, boleto, valor, observacao } =
            this.state;
        if (!codigoCota || !codigoDespesa || !valor) {
            this.setState({
                error: "Preencha os campos obrigatórios para continuar!",
            });
        } else {
            try {
                Swal.fire({
                    title: "Aguarde ...",
                    onBeforeOpen: () => {
                        Swal.showLoading();
                    },
                });

                const formData = new FormData();
                formData.append("file", boleto);
                formData.append("codigoCota", codigoCota);
                formData.append("codigoDespesa", codigoDespesa);
                formData.append("observacao", observacao);
                formData.append("valor", valor);
                const config = {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                };
                const response = await api.post(
                    "api/concash/1.0/despesa-cota/adicionar",
                    formData,
                    config
                );
                this.obtemDespesasCotas(codigoCota);

                Swal.close();
            } catch (err) {
                this.setState({
                    error: "Houve um problema com o processamento da requisição.",
                });
            }
        }
    };

    handleChangeValor = (event, value, maskedValue) => {
        event.preventDefault();
        this.setState({
            valor: maskedValue
                .replace("R$", "")
                .replace(".", "")
                .replace(",", ".")
                .trim(),
        });
    };

    handleFormOpenComprovante = async (comprovante, e) => {
        e.preventDefault();
        try {
            Swal.fire({
                title: "Aguarde ...",
                onBeforeOpen: () => {
                    Swal.showLoading();
                },
            });

            const response = await api.post(
                "api/concash/1.0/despesa-cota/download",
                {
                    comprovante: comprovante,
                },
                { responseType: "blob" }
            );

            const file = new Blob([response.data], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;

            Swal.close();
        } catch (err) {
            this.setState({
                error: "Houve um problema com o processamento da requisição.",
            });
        }
    };

    handleDeletarDespesaCota = async (codigo, e) => {
        var classe = this;
        Swal.fire({
            title: "Você tem certeza que deseja excluir esse despesa?",
            text: "Após exclusão não será possivel a reversão",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim!",
            cancelButtonText: "Não!",
        }).then((result) => {
            if (result.value) {
                classe.handleDeletarDespesaCotaEfetivar(codigo, e);
            }
        });
    };

    handleDeletarDespesaCotaEfetivar = async (codigo, e) => {
        e.preventDefault();
        if (!codigo) {
            this.setState({ error: "Código indefinido!" });
        } else {
            try {
                let codigo = this.state.codigoCota;
                const response = await api.put(
                    "api/concash/1.0/despesa-cota/excluir",
                    {
                        codigo,
                    }
                );
                this.obtemPagamentos(codigo);
                Swal.fire("Despesa excluído com sucesso!");
            } catch (err) {
                this.setState({
                    error: "Houve um problema com a exclusão.",
                });
            }
        }
    };

    render() {
        return (
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Despesas Cotas</h4>
                            <p className="card-description"></p>

                            <form
                                className="forms-sample"
                                onSubmit={this.handleFormDespesaCota}
                            >
                                <div className="row">
                                    <div className="col-md-12 grid-margin stretch-card">
                                        <div className="card">
                                            <div className="card-body">
                                                <Form.Group className="row">
                                                    <label
                                                        htmlFor="examplePropostal"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Tipo de Despesa
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <select
                                                            className="form-control"
                                                            value={
                                                                this.state.tipo
                                                            }
                                                            onChange={(e) =>
                                                                this.callDespesas(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        >
                                                            <option value="">
                                                                Selecione
                                                            </option>
                                                            {this.state.tiposDespesas.map(
                                                                (tp, index) => (
                                                                    <option
                                                                        value={
                                                                            tp.codigo
                                                                        }
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {
                                                                            tp.tipo
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className="row">
                                                    <label
                                                        htmlFor="examplePropostal"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Despesa
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <select
                                                            className="form-control"
                                                            value={
                                                                this.state
                                                                    .codigoDespesa
                                                            }
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    codigoDespesa:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                            id="codigoAdministradora"
                                                        >
                                                            <option value="">
                                                                Selecione
                                                            </option>
                                                            {this.state.despesas.map(
                                                                (
                                                                    despesa,
                                                                    index
                                                                ) => (
                                                                    <option
                                                                        value={
                                                                            despesa.codigo
                                                                        }
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {
                                                                            despesa.despesa
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className="row">
                                                    <label className="col-sm-3 col-form-label">
                                                        Valor
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <IntlCurrencyInput
                                                            currency="BRL"
                                                            value={
                                                                this.state.valor
                                                            }
                                                            config={
                                                                currencyConfig
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChangeValor
                                                            }
                                                        />
                                                    </div>
                                                </Form.Group>

                                                <Form.Group className="row">
                                                    <label
                                                        htmlFor="examplePropostal"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Boleto
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <Form.Control
                                                            type="file"
                                                            className="form-control"
                                                            id="file"
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    boleto: e
                                                                        .target
                                                                        .files[0],
                                                                })
                                                            }
                                                            size="sm"
                                                        />
                                                    </div>
                                                </Form.Group>

                                                <Form.Group className="row">
                                                    <label
                                                        htmlFor="examplePropostal"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Observação
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control"
                                                            id="observacao"
                                                            value={
                                                                this.state
                                                                    .observacao
                                                            }
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    observacao:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                            size="sm"
                                                        />
                                                    </div>
                                                </Form.Group>

                                                <div className="text-right">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary mr-2"
                                                    >
                                                        Adicionar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th> Data </th>
                                            <th> Despesa </th>
                                            <th> Valor </th>
                                            <th> Observação </th>
                                            <th> Boleto </th>
                                            <th> Comprovante </th>
                                            <th className="text-center">
                                                {" "}
                                                Ação{" "}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.despesasCotas.map(
                                            (des, index) => (
                                                <tr key={index}>
                                                    <td> {des.dataIni} </td>
                                                    <td>
                                                        {" "}
                                                        {
                                                            des.despesa.despesa
                                                        }{" "}
                                                    </td>
                                                    <td> {des.valor} </td>
                                                    <td> {des.observacao} </td>
                                                    <td>
                                                        {des.boleto && (
                                                            <button
                                                                type="button"
                                                                onClick={(e) =>
                                                                    this.handleFormOpenComprovante(
                                                                        des.boleto,
                                                                        e
                                                                    )
                                                                }
                                                                className="btn btn-primary mr-2"
                                                            >
                                                                Download
                                                            </button>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {des.comprovante && (
                                                            <button
                                                                type="button"
                                                                onClick={(e) =>
                                                                    this.handleFormOpenComprovante(
                                                                        des.comprovante,
                                                                        e
                                                                    )
                                                                }
                                                                className="btn btn-primary mr-2"
                                                            >
                                                                Download
                                                            </button>
                                                        )}
                                                    </td>

                                                    <td className="text-center">
                                                        <Link
                                                            className="m-3 btn btn-sm btn-danger"
                                                            onClick={(e) =>
                                                                this.handleDeletarDespesaCota(
                                                                    des.codigo,
                                                                    e
                                                                )
                                                            }
                                                        >
                                                            Deletar
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DespesaCota;
