import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Button } from 'react-bootstrap';
import api from "../../services/api";
import Swal from 'sweetalert2'
import { TextField } from '@mui/material';
import FieldSelect from "../../components/Field/FieldSelect";
import FieldSelectComArray from "../../components/Field/FieldSelectComArray";
import FieldFile from "../../components/Field/FieldFile";
import { modalInfo } from "../../components/Modal/ModalInfo";
import { modalLoading } from "../../components/Modal/ModalLoading";
import Warning from "../../assets/warning_amarelo.svg";

const statusOptions = [
  { value: "0", label: "Cancelada" },
  { value: "1", label: "Ativa" },
  { value: "2", label: "Em Atraso" },
];

const situacaoOptions = [
  { value: "1", label: "Contemplada" },
  { value: "0", label: "Não Contemplada" },
];


export class NovaCota extends Component {
  constructor(props){
    super(props);
    this.state = {
      administradora: '',
      tipoConsorcio: '',
      statusCota: '',
      situacaoCota: '',
      extrato: '',
      submitandoInfos: false,
      isMobile: false,
      erroFormulario: false,
    }
  }

  async componentDidMount() {
    this.setState({ isMobile: window.innerWidth <= 800 });
  }

  onChange(field, value) {
    this.setState( { [field]: value } );
  }

  async submeterFormulario(){
    
    let erroFormato = false;
    var erro = "Houve um erro para salvar os dados";
    if (this.state.extrato) {
      let arqParts = String(this.state.extrato.name).split(".");
      if (arqParts[arqParts.length - 1] != "pdf") {
        erroFormato = true;
      }
    }
    if (erroFormato) {
      modalInfo("error", "Atenção", "Selecione um arquivo em formato PDF para continuar!");
    } else if (!this.state.extrato || !this.state.administradora || !this.state.tipoConsorcio || !this.state.statusCota || !this.state.situacaoCota) {
      this.setState({ erroFormulario: true });
      modalInfo("error", "Atenção", "Informe a administradora, categoria, status, situação e arquivo no formato PDF para continuar!");
    } else {
      modalLoading("Gravando informações", "Por favor, aguarde enquanto gravamos as informações!");
      this.setState({ submitandoInfos: true });
      try {
        const formData = new FormData();
        formData.append("file", this.state.extrato);
        formData.append("administradora", this.state.administradora);
        formData.append("tipoConsorcio", this.state.tipoConsorcio);
        formData.append("statusCota", this.state.statusCota);
        formData.append("situacaoCota", this.state.situacaoCota);
        const config = { headers: { "content-type": "multipart/form-data", }, };

        const response = await api.post( `/api/concash/1.0/cliente/importar-cota`, formData, config );

        if (response.status === 200) {
          Swal.close();
          this.props.history.push('/sucesso-nova-cota');
        } else {
          Swal.close();
          if(!typeof response.data === 'string'){
            erro =  response.data;
          }
          modalInfo("error", "Erro", erro);
          this.setState({ submitandoInfos: false });
        }
      } catch (err) {
        Swal.close();
        if(typeof err.response.data === 'string'){
          erro = err.response.data;
        }
        modalInfo("error", "Erro", erro);
        this.setState({ submitandoInfos: false });
      }
    }
  }

  render() {
    return (
      <>
        {this.state.isMobile ? (
          <>
            <div className="row-mobile">
              <div className='col-sm-12 col-12 m-auto' align="center" style={{ font: "normal normal bold 24px/30px Roboto" }}>
                Nova proposta
              </div>
            </div>

            <div className="row-mobile" style={{ marginTop: "40px" }}>
              <div className='col-sm-12 col-12 m-auto' align="center" style={{ width: "100%" }}>
                <FieldSelect atributo="administradora" atributoId="sadId" atributoLabel="sadNome" label="Administradora *" caminho="cliente-area/administradoras" atributoObjeto={this.state.administradora} onChange={this.onChange.bind(this)} erro={this.state.erroFormulario} mensagemErro="* Preencha o campo"/>
              </div>
            </div>

            <div className="row-mobile">
              <div className='col-sm-12 col-12' align="center">
                <FieldSelect atributo="tipoConsorcio" atributoId="ctcId" atributoLabel="ctcDescricao" label="Categoria *" caminho="cliente-area/tipos-consorcio" atributoObjeto={this.state.tipoConsorcio} onChange={this.onChange.bind(this)} erro={this.state.erroFormulario} mensagemErro="* Preencha o campo"/>
              </div>
            </div>

            <div className="row-mobile">
              <div className='col-sm-12 col-12 m-auto' align="center">
                <FieldSelectComArray opcoes={statusOptions} atributo="statusCota" label="Status da Cota *" atributoObjeto={this.state.statusCota} onChange={this.onChange.bind(this)} erro={this.state.erroFormulario} mensagemErro="* Preencha o campo"/>
              </div>
            </div>

            <div className="row-mobile">
              <div className='col-sm-12 col-12 m-auto' align="center">
                <FieldSelectComArray opcoes={situacaoOptions} atributo="situacaoCota" label="Situação da Cota *" atributoObjeto={this.state.situacaoCota} onChange={this.onChange.bind(this)} erro={this.state.erroFormulario} mensagemErro="* Preencha o campo"/>
              </div>
            </div>

            <div className="row-mobile">
              <div className='col-sm-12 col-12 m-auto' align="center">
                <TextField type="file" id="extrato" label=" " accept=".pdf" onChange={ (e) => this.setState({ extrato: e.target.files[0] }) } variant="outlined" />
              </div>
            </div>

            <div className="row-mobile" style={{ marginTop: "15px" }}>
              <div className="col-sm-12 col-12 m-auto" align="center">
                <div style={{ "height" : "90px", "width" : "100%", backgroundColor: '#FDFFCE', border: "solid", borderRadius: "10px", borderColor: "#E2D489", font: "normal normal normal 15px/20px Roboto"  }}>
                  <br/>
                  <div style={{ marginLeft: "5px" }}>
                    <img src={Warning}/> <b>**Texto orientativo sobre o que deve ser submetido neste campo**</b>
                  </div>
                </div>
              </div>
            </div>

            <div className="row-mobile">
              <div className='col-sm-12 col-12 m-auto' style={{ font: "italic normal normal 15px/30px Roboto" }}>
                * Preenchimento obrigatório
              </div>
            </div>

            <div className="row-mobile">
              <div className='col-sm-12 col-12 m-auto' style={{ font: "italic normal normal 15px/30px Roboto" }}>
                <Button disabled={this.state.submitandoInfos} onClick={ () => { this.submeterFormulario() }  } style={{ borderColor: "#16898E", borderRadius: "10px", height: "45px", width: "100%", backgroundColor: "#16898E" }}  > 
                  <span style={{ font: "normal normal bold 15px/20px Roboto", color: "#FFFFFF" }}> Solicitar nova proposta</span>
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row">
              <div className='col-md-11'>
                <span style={{ font: "normal normal bold 30px/30px Roboto" }}> Nova proposta </span>
              </div>
            </div>

            <div className="row" style={{ marginTop: "50px" }}>
              <div style={{ width: "45%", marginLeft: "5px" }} >
                <FieldSelect atributo="administradora" atributoId="sadId" atributoLabel="sadNome" label="Administradora *" caminho="cliente-area/administradoras" atributoObjeto={this.state.administradora} onChange={this.onChange.bind(this)} erro={this.state.erroFormulario} mensagemErro="* Preencha o campo"/>
              </div>
              <div style={{ width: "45%" }} >
              <FieldSelect atributo="tipoConsorcio" atributoId="ctcId" atributoLabel="ctcDescricao" label="Categoria *" caminho="cliente-area/tipos-consorcio" atributoObjeto={this.state.tipoConsorcio} onChange={this.onChange.bind(this)} erro={this.state.erroFormulario} mensagemErro="* Preencha o campo"/>
              </div>
            </div>

            <div className="row" style={{ marginTop: "15px" }}>
              <div style={{ width: "45%", marginLeft: "5px" }} >
                <FieldSelectComArray opcoes={statusOptions} atributo="statusCota" label="Status da Cota *" atributoObjeto={this.state.statusCota} onChange={this.onChange.bind(this)} erro={this.state.erroFormulario} mensagemErro="* Preencha o campo" />
              </div>
              <div style={{ width: "45%" }} >
                <FieldSelectComArray opcoes={situacaoOptions} atributo="situacaoCota" label="Situação da Cota *" atributoObjeto={this.state.situacaoCota} onChange={this.onChange.bind(this)} erro={this.state.erroFormulario} mensagemErro="* Preencha o campo" />
              </div>
            </div>
            
            <div className="row" style={{ marginTop: "45px" }}>
              <div style={{ width: "45%", marginLeft: "10px" }} >
                <FieldFile tamanho="510" style={{ width: "100%" }} label="Extrato *" arquivo={this.state.extrato} atributo="extrato" onChange={this.onChange.bind(this)}/>
              </div>
            </div>

            <div className="row" style={{ marginTop: "15px" }}>
              <div style={{ marginLeft: "10px", height: "70px", width: "45%", backgroundColor: '#FDFFCE', border: "solid", borderRadius: "10px", borderColor: "#E2D489", font: "normal normal normal 15px/20px Roboto"  }}>
                <br/>
                <div style={{ marginLeft: "5px" }}>
                  <img src={Warning}/> <b>**Texto orientativo sobre o que deve ser submetido neste campo**</b>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: "30px" }}>
              <div style={{ marginLeft: "10px", font: "italic normal normal 15px/30px Roboto" }} >
              * Preenchimento obrigatório
              </div>
            </div>

            <div className="row" style={{ marginTop: "30px" }}>
              <div style={{ width: "45%", marginLeft: "10px" }} >
                <Button disabled={this.state.submitandoInfos} onClick={ () => { this.submeterFormulario() }  } style={{ borderColor: "#16898E", borderRadius: "10px", height: "45px", width: "100%", backgroundColor: "#16898E" }}  > 
                  <span style={{ font: "normal normal bold 15px/20px Roboto", color: "#FFFFFF" }}> Solicitar nova proposta</span>
                </Button>
              </div>
            </div>
          </>
        )}
      </>
    )
  }
}

export default withRouter(NovaCota)