import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import api from "../../services/api";
import Swal from 'sweetalert2';


const bubleOperador = {

   color:      "white",
   textAlign:  "center",
   padding:    "15px",
   marginTop:  "15px",
   marginRight:  "15px",
   fontSize:   "12px",
   background: "blue",
   borderRadius:"10px",
}


export class BoxArquivo extends Component {


  constructor(props){

        super(props);

        this.state = {
           userPhoto: ""
        }

        var ext = props.arquivo.substr(props.arquivo.lastIndexOf('.') + 1);
        if(ext.toLowerCase() == "jpg" || ext.toLowerCase() == "jpeg" || ext.toLowerCase() == "png"){
            this.LoadImagem(props.arquivo);
        }

  }

  LoadImagem = async (documento) => {


      try {

            const response  = await api.post("api/concash/1.0/whatsapp-mensagem/download",{
              arquivo:documento
            },{ responseType: 'blob' });

             var reader     = new FileReader();
             var base64data = "";
             var obj        = this;
             reader.readAsDataURL(response.data);
             reader.onloadend = function() {
                 var base64data = reader.result;
                 obj.setState({ userPhoto : base64data });
             }

      } catch (err) {

      }
  };


  handleFormOpenDocumento = async (e,documento) => {
    e.preventDefault();
      try {

            Swal.fire ({
               title: 'Aguarde ...',
               onBeforeOpen: () => {
                 Swal.showLoading ()
               }
            });

            const response  = await api.post("api/concash/1.0/whatsapp-mensagem/download",{
              arquivo:documento
            },{ responseType: 'blob' });

            var tipo = "";
            var ext  = documento.substr(documento.lastIndexOf('.') + 1);

            if(ext.toLowerCase() == "jpg"){
               tipo = "image/jpeg";
            }else if(ext.toLowerCase() == "jpeg"){
               tipo = "image/jpg";
            }else if(ext.toLowerCase() == "png"){
               tipo = "image/png";
            }else if(ext.toLowerCase() == "pdf"){
               tipo = "application/pdf";
            }else if(ext.toLowerCase() == "xls"){
               tipo = "application/excel";
            }else if(ext.toLowerCase() == "word"){
               tipo = "application/msword";
            }else if(ext.toLowerCase() == "xlsx"){
               tipo = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            }else if(ext.toLowerCase() == "docx"){
               tipo = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            }


            const file      = new Blob([response.data], { type: "image/jpeg" });
            //Build a URL from the file
            const fileURL   = URL.createObjectURL(file);
            //Open the URL on new Window
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;

            Swal.close();

      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o processamento da requisição."
        });
      }
  };



  render() {

    const { arquivo }  = this.props;

    return (
      <>
        {arquivo &&
             <div className="col-md-8 offset-md-4" style={bubleOperador}>
                 {!this.state.userPhoto &&
                   <img src="https://www.concash.com.br/mail/icon_arquivo.png" onClick={ e => this.handleFormOpenDocumento(e,arquivo)} style={{cursor:"pointer"}} />
                 }
                 {this.state.userPhoto &&
                   <>
                    <img className="pic__photo" src={this.state.userPhoto} style={{width:"90%"}} />
                    <img src="https://www.concash.com.br/mail/search.png" widht="32px" onClick={ e => this.handleFormOpenDocumento(e,arquivo)} style={{cursor:"pointer", width:"32px", marginTop:"10px"}} />
                   </>
                 }
             </div>
        }
      </>
    )
  }
}

export default BoxArquivo;
