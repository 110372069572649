import React, { Component, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import Logo from "../../assets/logo.png";
import { RECAPTCHA_KEY } from "../../site.config";
import api from "../../services/api";
import Swal from "sweetalert2";
import {
  login,
  setCpfCnpj as docStorage,
  tipoPessoa as tpStorage,
} from "../../services/auth";
import * as S from "./signin.styles";
import family from "../../assets/images/novo/family.png";
import ConcashLogo from "../../styles/themes/v3/assets/logo Concash.png";
import { V3Link, V3Text5, V3Title } from "../../styles/themes/components/texts";
import { V3TextCustom } from "../../styles/themes/components/input";
import { V3Button, V3ButtonLink } from "../../styles/themes/components/button";
import { v3Colors } from "../../styles/themes/v3/colors";
import { v3FormataCNPJ, v3MaskCPF } from "../../styles/themes/utils/format";
import { InfoModal, V3Modal } from "../../styles/themes/components/modal";
import { v3ValidaCpfCnpj } from "../../styles/themes/utils/validate";
import { Backdrop } from "@mui/material";
import Lottie from "lottie-react";
import Loading from "../../assets/lottie/loading.json";

class SignIn extends Component {
  state = {
    mobile: false,
    cpfCnpj: "",
    cpfCnpjErr: false,
    password: "",
    displayModal: "none",
    error: "",
    open: false,
    hasSuccess: false,
    hasError: false,
    errorText: "",
    successText: "",
  };

  componentDidMount() {
    const mediaQuery = window.innerWidth <= 768;
    if (mediaQuery) {
      this.setState({ mobile: true });
    }
  }

  handleAbreModal = (e) => {
    this.setState({ displayModal: "block" });
  };

  handleSignIn = async (e) => {
    e.preventDefault();
    this.setState({ open: true });
    const { cpfCnpj, password } = this.state;
    if (!cpfCnpj || !password) {
      this.setState({
        hasError: true,
        open: false,
        errorText: "Preencha CPF/CNPJ,senha e o recaptcha para continuar!",
      });
    } else {
      try {
        const regex = /\d/g;
        let newCpfCnpj = cpfCnpj.match(regex);
        newCpfCnpj = newCpfCnpj.join("");
        const response = await api.post("/api/auth/signin-client", {
          cpfCnpj: newCpfCnpj,
          password,
        });

        login(response.data.accessToken);
        docStorage(newCpfCnpj);
        this.setState({ open: false });
        if (!response.data.primeiroAcesso) {
          this.props.history.push(`/nova-senha/${response.data.accessToken}`);
        } else {
          if (response.data.temProposta) {
            this.props.history.push("/consorcio-grupo-cota");
          } else {
            this.props.history.push("/consorcio-grupo-cota");
          }
        }
      } catch (err) {
        this.setState({
          hasError: true,
          open: false,
          errorText: "Login ou senha inválido!",
        });
      }
    }
  };

  handlePass = (text) => {
    this.setState({ error: false, password: text });
  };

  handleCpfCnpj = (text) => {
    this.setState({ error: false });
    const validating = text.replace(/[^\d]/g, "");
    if (validating.length <= 14) {
      if (validating.length <= 11) {
        const c = v3MaskCPF(validating);
        this.setState({ cpfCnpj: c });
      } else {
        const cn = v3FormataCNPJ(validating);
        this.setState({ cpfCnpj: cn });
      }
    }
  };

  render() {
    return (
      <>
        <V3Modal
          open={this.state.hasError}
          actionClose={() => this.setState({ hasError: false })}
          title={this.state.errorText}
          type="warning"
        />
        <V3Modal
          open={this.state.hasSuccess}
          actionClose={() => this.setState({ hasSuccess: false })}
          title={this.state.successText}
          type="success"
        />
        <Backdrop
          sx={{
            color: "#16B6C5",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={this.state.open}
        >
          <Lottie animationData={Loading} />
        </Backdrop>
        {!this.state.mobile ? (
          <>
            <div>
              <S.Container>
                <S.BoxLogin>
                  <S.Row>
                    <img src={ConcashLogo} width={162} height={44} />
                  </S.Row>
                  <S.RowCenter style={{ marginTop: 30, marginBottom: 30 }}>
                    <V3Title>Minha Conta</V3Title>
                  </S.RowCenter>
                  {this.error && (
                    <S.Row>
                      <InfoModal
                        style={{ marginBottom: 15 }}
                        text="Os dados não correspondem às informações em nossos registros. Verifique-as e tente novamente."
                        type="error"
                      />
                    </S.Row>
                  )}
                  <S.Row>
                    <V3TextCustom
                      id="cpfCnpj"
                      size="small"
                      sx={{ width: 447 }}
                      label="CPF / CNPJ"
                      onChange={(e) => {
                        this.handleCpfCnpj(e.target.value);
                      }}
                      error={this.state.cpfCnpjErr}
                      value={this.state.cpfCnpj}
                      helperText={
                        this.state.cpfCnpjErr && "CPF / CNPJ inválido."
                      }
                      required
                    />
                  </S.Row>
                  <S.Row style={{ marginTop: 15, marginBottom: 15 }}>
                    <V3TextCustom
                      id="pass"
                      size="small"
                      label="Senha"
                      onChange={(e) => this.handlePass(e.target.value)}
                      sx={{
                        width: 447,
                      }}
                      value={this.state.password}
                      type="password"
                    />
                  </S.Row>
                  <S.RowCenter>
                    <V3Button
                      id="button-entrar"
                      onClick={this.handleSignIn}
                      estilo="primary"
                      width={"100%"}
                      text="Entrar"
                    />
                  </S.RowCenter>
                  <S.RowCenter
                    style={{
                      paddingBottom: 30,
                      paddingTop: 30,
                      borderBottom: "2px solid  #ededed",
                      marginBottom: 30,
                    }}
                  >
                    <V3Link
                      href="/recuperar-senha"
                      style={{ color: v3Colors.primary, fontWeight: "bold" }}
                    >
                      Esqueci a senha
                    </V3Link>
                  </S.RowCenter>
                  <S.RowCenter>
                    <V3Text5
                      style={{ color: v3Colors.black, fontWeight: "bold" }}
                    >
                      Não faz parte da Concash?
                    </V3Text5>
                  </S.RowCenter>
                  <S.RowCenter style={{ marginTop: 15 }}>
                    <V3ButtonLink
                      href="/cadastro"
                      estilo="neutro"
                      text="CRIAR CONTA"
                      width="100%"
                    />
                  </S.RowCenter>
                </S.BoxLogin>
                <S.BoxSideRight>
                  <img src={family} />
                </S.BoxSideRight>
              </S.Container>
            </div>
          </>
        ) : (
          <>
            <div>
              <S.Container>
                <S.BoxLoginMobile>
                  <S.RowCenter>
                    <img src={ConcashLogo} width={162} height={44} />
                  </S.RowCenter>
                  <S.RowCenter style={{ marginTop: 30, marginBottom: 30 }}>
                    <V3Title>Minha Conta</V3Title>
                  </S.RowCenter>
                  {this.state.error && (
                    <S.Row>
                      <InfoModal
                        style={{ marginBottom: 15 }}
                        text="Os dados não correspondem às informações em nossos registros. Verifique-as e tente novamente."
                        type="error"
                      />
                    </S.Row>
                  )}
                  <S.Row>
                    <V3TextCustom
                      size="small"
                      sx={{ width: 447 }}
                      label="CPF / CNPJ"
                      onChange={(e) => {
                        this.handleCpfCnpj(e.target.value);
                      }}
                      error={this.state.cpfCnpjErr}
                      value={this.state.cpfCnpj}
                      helperText={
                        this.state.cpfCnpjErr && "CPF / CNPJ inválido."
                      }
                      required
                    />
                  </S.Row>
                  <S.Row style={{ marginTop: 15, marginBottom: 15 }}>
                    <V3TextCustom
                      size="small"
                      label="Senha"
                      onChange={(e) => this.handlePass(e.target.value)}
                      sx={{
                        width: 447,
                      }}
                      value={this.state.password}
                      type="password"
                    />
                  </S.Row>
                  <S.RowCenter>
                    <V3Button
                      onClick={this.handleSignIn}
                      estilo="primary"
                      width={"100%"}
                      text="Entrar"
                    />
                  </S.RowCenter>
                  <S.RowCenter
                    style={{
                      paddingBottom: 30,
                      paddingTop: 30,
                      borderBottom: "2px solid  #ededed",
                      marginBottom: 30,
                    }}
                  >
                    <V3Link
                      href="/recuperar-senha"
                      style={{ color: v3Colors.primary, fontWeight: "bold" }}
                    >
                      Esqueci a senha
                    </V3Link>
                  </S.RowCenter>
                  <S.RowCenter>
                    <V3Text5
                      style={{ color: v3Colors.black, fontWeight: "bold" }}
                    >
                      Não faz parte da Concash?
                    </V3Text5>
                  </S.RowCenter>
                  <S.RowCenter style={{ marginTop: 15 }}>
                    <V3ButtonLink
                      href="/cadastro"
                      estilo="neutro"
                      text="CRIAR CONTA"
                      width="100%"
                    />
                  </S.RowCenter>
                </S.BoxLoginMobile>
              </S.Container>
            </div>
          </>
        )}
      </>
    );
  }
}

export default withRouter(SignIn);
