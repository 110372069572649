import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";



export class FormWhatsApp extends Component {



  constructor(props){

    super(props);

    this.state = {
          nome: '',
          responsavel: '',
          url: '',
          token: '',
          codigo: '',
          tipo: '',
          labelForm: '',
          labelButton: '',
          users: [],
          error: "",
          action: ""
    };
  }


  async componentDidMount() {

    if(this.props[0].location.state !== undefined){
        const whatsapp  = this.props[0].location.state.whatsapp
        this.setState({nome: whatsapp.nome,token: whatsapp.token,codigo: whatsapp.codigo,url: whatsapp.url,responsavel: whatsapp.responsavel});
        this.setState({labelForm: "Editar WhatsApp",labelButton: "Editar",action: "editar"});
    }else{
        this.setState({labelForm: "Adicionar WhatsApp",labelButton: "Adicionar",action: "adicionar"});
    }

    try {
          const response = await api.post("/api/sistema/1.0/usuario/registros", {});
          this.setState({ users: response.data});
    } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter a lista de colaboradores."
          });
    }
  }

  handleForm = async e => {
    e.preventDefault();
    const { nome,
            responsavel,
            url,
            token,
            codigo,
            action} = this.state;
    if (!nome
        || !responsavel
        || !url
        || !token
        || !action) {
      this.setState({ error: "Preencha os campos obrigatórios para continuar!" });
    } else {
      try {

        if(action == "editar"){
          const response = await api.put("/api/sistema/1.0/whatsapp/"+action, {
              nome,responsavel,url,codigo,token
            });
            this.setState({ error: "Registro editado com sucesso!" });
        }else{
          const response = await api.post("/api/sistema/1.0/whatsapp/"+action, {
              nome,responsavel,url,token
            });
            this.props.history.push("/whatsapp");
        }

      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o processamento da requisição."
        });
      }
    }
  };

  render() {


    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> {this.state.labelForm} </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>

        <div className="row">

          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"></h4>
                {this.state.error && <p className="card-description">{this.state.error}</p>}
                <form className="forms-sample" onSubmit={this.handleForm}>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group className="row" >
                      <label className="col-sm-5 col-form-label">Responsável</label>
                      <div className="col-sm-7">
                        <select className="form-control" value={this.state.responsavel} onChange={e => this.setState({ responsavel: e.target.value })}>
                          {this.state.users.map((user,index) => (
                            <option value={user.id} key={index}>{user.name}</option>
                          ))}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Nome</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="nome" value={this.state.nome} placeholder="Nome da Conta" onChange={e => this.setState({ nome: e.target.value })} size="sm" />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">URL</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="url" value={this.state.url} placeholder="URL" size="sm" onChange={e => this.setState({ url: e.target.value })} />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Token</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="token" value={this.state.token} placeholder="token" size="sm" onChange={e => this.setState({ token: e.target.value })} />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="text-right">
                    <button type="submit" className="btn btn-primary mr-2">{this.state.labelButton}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
         </div>
       </div>
    )
  }
}

export default withRouter(FormWhatsApp)
