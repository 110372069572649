import React from 'react';
import { ButtonMain } from '../components/buttons';
import { HeaderAuto } from '../components/Header';
import AutoJumpInputs from '../../../components/AutoJump';
import S from "../Autoservico.module.css";
import { FormControlLabel, Radio } from '@mui/material';
import { V3TextCustom } from '../../../styles/themes/components/input';
import family from "../../../assets/images/family.png";


export const LoginScreenMobile = ({handleLogin, estilo, selectedMethod, solicitado, isActive, isMobile, setCode, code, seconds, handleBackToRequest, handleRequestCode, listMethods, setSelectedMethod, cpfCnpj, handleCpfCnpj, cpfErr, handleSearchByCpfCnpj}) => {
    return (
         <>
          <HeaderAuto estilo={estilo} adm={estilo?.urlAdm} mobile={isMobile} />

          {solicitado ? (
            <>
              <>
                <div className={S.contentLoginMobile}>
                  <div className={S.rowTitleLoginMobile}>
                    <h1 className={S.title24}>Insira o código enviado</h1>
                    <h3
                      className={S.subtitle15}
                      style={{ textAlign: "center", padding: "0 60px" }}
                    >
                      Confira o código enviado para <b>{selectedMethod?.valor}</b> e
                      digite-o no campo abaixo:
                    </h3>
                  </div>
                </div>
                <div className={S.boxDefaultCenteredMobile}>
                  <AutoJumpInputs acao={setCode} />
                </div>
                <div
                  className={S.rowTitleLoginMobile}
                  style={{ marginTop: 20 }}
                >
                  {isActive ? (
                    <h3
                      className={S.subtitle15}
                      style={{ textAlign: "center", padding: "0 60px" }}
                    >{`Não recebeu o código? Aguarde ${seconds} segundos e poderá solicitar novo código.`}</h3>
                  ) : (
                    <h3
                      className={S.subtitle15}
                      style={{ textAlign: "center", padding: "0 60px" }}
                    >
                      Não recebeu o código?{" "}
                      <b
                        style={{
                          color: estilo?.secondary,
                          cursor: "pointer",
                        }}
                        onClick={handleBackToRequest}
                      >
                        Clique aqui{" "}
                      </b>
                      para enviarmos novamente.
                    </h3>
                  )}
                </div>
                <div
                  className={S.boxDefaultMobile}
                  style={{ marginTop: 20, marginBottom: 20, padding: 30 }}
                >
                  <ButtonMain
                    estilo={{...estilo, primary: "#fff212", textColor: "#0067ab"}}
                    disabled={!selectedMethod}
                    texto={"Confirmar"}
                    tamanho={"100%"}
                    textColor={"#0067ab"}
                    onClick={handleLogin}
                  />
                </div>
              </>
            </>
          ) : (
            <>
              {listMethods ? (
                <>
                  <div className={S.contentLoginMobile}>
                    <div className={S.rowTitleLoginMobile}>
                      <h1 className={S.title24}>
                        Selecione um método de confirmação
                      </h1>
                      <h3
                        className={S.subtitle15}
                        style={{ textAlign: "center", padding: "0 50px" }}
                      >
                        Para acessar sua conta, selecione abaixo uma das opções
                        para receber seu código de acesso:
                      </h3>
                    </div>
                  </div>
                  <div className={S.boxDefaultColumnMethods}>
                    {listMethods.map((i) => (
                      <div>
                        <h3
                          className={S.textLabelMethod}
                          style={{ color: estilo?.secondary }}
                        >
                          CONFIRMAR POR {i.tipo}:
                        </h3>
                        <FormControlLabel
                          value={i.valor}
                          control={
                            <Radio
                              checked={selectedMethod.valor === i.valor}
                              onClick={() => setSelectedMethod(i)}
                            />
                          }
                          label={i.valor}
                        />
                      </div>
                    ))}
                  </div>
                  <div
                    className={S.boxDefaultMobile}
                    style={{ marginTop: 20, marginBottom: 20, padding: 30 }}
                  >
                    <ButtonMain
                      estilo={{...estilo, primary: "#fff212", textColor: "#0067ab"}}
                      disabled={!selectedMethod}
                      texto="Receber Código"
                      tamanho={"100%"}
                      textColor={"#0067ab"}
                      onClick={handleRequestCode}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={S.contentLoginMobile}>
                    <div className={S.rowTitleLoginMobile}>
                      <h1 className={S.title24}>Olá,</h1>
                      <h3
                        className={S.subtitle15}
                        style={{ textAlign: "center" }}
                      >
                        Por aqui você acompanha suas propostas e vendas em andamento. Para iniciarmos, peço que confirme seu CPF/CNPJ no campo abaixo:
                      </h3>
                    </div>
                  </div>
                  <div className={S.boxMobile}>
                    <V3TextCustom
                      label="CPF / CNPJ"
                      value={cpfCnpj}
                      onChange={(e) => { handleCpfCnpj(e.target.value) }}
                      error={cpfErr}
                      helperText={cpfErr && "CPF / CNPJ inválido."}
                    />
                    <h3 className={S.subtitle15}>
                      Ao prosseguir, você concorda com os{" "}
                      <a style={{ textDecoration: "none" }} href="https://concash-prod-files-pub.s3.sa-east-1.amazonaws.com/ConCash/Termos/Politica_Privacidade.pdf" target='_blank' rel="noreferrer"><b style={{ color: estilo?.secondary }}>
                        termos de uso e política de privacidade
                      </b></a>
                    </h3>
                  </div>
                  <div
                    className={S.boxDefaultMobile}
                    style={{ marginTop: 20, marginBottom: 20, padding: 30 }}
                  >
                    <ButtonMain
                      estilo={{...estilo, primary: "#fff212", textColor: "#0067ab"}}
                      texto="Continuar"
                      tamanho={"100%"}
                      textColor={"#0067ab"}
                      onClick={handleSearchByCpfCnpj}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </>
    );
}

export const LoginScreenDesktop = ({handleLogin, estilo, selectedMethod, solicitado, isActive, isMobile, setCode, code, seconds, handleBackToRequest, handleRequestCode, listMethods, setSelectedMethod, cpfCnpj, handleCpfCnpj, cpfErr, handleSearchByCpfCnpj}) => {

    return(
        <>
        <div className={S.screenDesktop}>
            <div className={S.colLeft}>
              <LoginScreenMobile
                handleLogin={handleLogin}
                estilo={estilo}
                selectedMethod={selectedMethod}
                solicitado={solicitado}
                isActive={isActive}
                isMobile={isMobile}
                setCode={setCode}
                code={code}
                seconds={seconds}
                handleBackToRequest={handleBackToRequest}
                handleRequestCode={handleRequestCode}
                listMethods={listMethods}
                setSelectedMethod={setSelectedMethod}
                cpfCnpj={cpfCnpj}
                handleCpfCnpj={handleCpfCnpj}
                cpfErr={cpfErr}
                handleSearchByCpfCnpj={handleSearchByCpfCnpj}
              />
            </div>
            <div
              className={S.colRight}
              style={{ backgroundColor: estilo?.secondary }}
            >
              <img src={family} alt="family" />
            </div>
          </div>
        </>
    )
}