import React from "react";
import styled from "styled-components";

export const CadastroTitle = styled.div`
  text-align: left;
  font: normal normal bold 30px/30px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`;

export const ContentBox = styled.div`
  display: flex;
  flex: 1;
  padding: 20px;
  flex-direction: column;
  gap: 15px;
`;

export const ContentBoxMobile = styled.div`
  display: flex;
  flex: 1;
  padding: 0px;
  flex-direction: column;
  gap: 15px;
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
  gap: 15px;
`;

export const CadastroAba = ({ icon, title, onClick, active }) => {
  const Box = styled.div`
    display: flex;
    height: 45px;

    min-width: 125px;
    background: ${!active
      ? "#F5F5F5 0% 0% no-repeat padding-box"
      : "#ddf7fa 0% 0% no-repeat padding-box"};
    border: ${!active ? "1px solid #F5F5F5" : "1px solid #a3e7eb"};
    border-radius: 40px;
    opacity: 1;
    align-items: center;
    justify-content: flex-start;
    color: ${!active ? "#7A7A7A" : "#008990"};
    cursor: pointer;
    gap: 5px;
    padding: 12px 15px;
    &:hover {
      opacity: 0.55;
    }
  `;

  const Text = styled.span`
    display: flex;
    flex: 1;
    font: normal normal normal 15px/30px Roboto;
    letter-spacing: 0px;
    color: ${!active ? "#7A7A7A" : "#008990"};
    opacity: 1;
    flex-wrap: nowrap;
    line-height: 1;
    text-overflow: clip;
  `;
  return (
    <>
      <Box onClick={onClick}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: !active ? "#7A7A7A" : "#008990",
          }}
        >
          {icon}
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Text>{title}</Text>
        </div>
      </Box>
    </>
  );
};
