import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import api from "../../services/api";

import { Link, withRouter } from "react-router-dom";
import cep from "cep-promise";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Card } from "../../pages/Backoffice/styles";
import { maskCEP } from "../../mask/maskCEP";
import * as S from "../../pages/Backoffice/cadastro.styles";
import { V3Modal, V3ModalConfirm } from "../../styles/themes/components/modal";

import Lottie from "lottie-react";
import Loading from "../../assets/lottie/loading.json";

import "./styles.css";
import { V3Text5 } from "../../styles/themes/components/texts";
import {
  V3Autocomplete,
  V3TextCustom,
} from "../../styles/themes/components/input";
import { V3Button } from "../../styles/themes/components/button";
import { RxTrash } from "react-icons/rx";
import { MdOutlineLocationOn } from "react-icons/md";

export class Endereco extends Component {
  constructor(props) {
    super(props);

    const { codigoCliente } = this.props;

    this.state = {
      codigoCliente: codigoCliente,
      ufs: [],
      dataEstados: [],
      cidades: [],
      enderecos: [],
      tipoEnderecos: [],
      error: "",
      open: true,
      tipoEnderecoDesc: "",
      tipoEndereco: null,
      melhorContatoDesc: "",
      melhorContato: null,
      ufDesc: "",
      uf: null,
      cidade: null,
      cidadeId: null,
      ufId: null,
      cep: null,
      bairro: null,
      logradouro: null,
      complemento: null,
      isMobile: false,
      openConfirm: false,
      deleteId: null,
      hasSuccess: false,
      successText: "",
      hasError: false,
      errorText: "",
    };
  }

  async componentDidMount() {
    const tamanho = window.innerWidth;
    if (tamanho <= 800) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }

    await this.obtemUFs();
    await this.obtemTipoEndereco();
    await this.obtemEnderecos(this.state.codigoCliente);
    this.setState({ open: false });
  }

  async obtemEnderecos(codigo) {
    try {
      const response = await api.get("api/concash/1.0/cliente-area/enderecos");
      this.setState({ enderecos: response.data });
    } catch (err) {
      Swal.fire(err.response.data);
    }
  }

  async obtemTipoEndereco() {
    try {
      const response = await api.post(
        "/api/concash/1.0/cliente-area/tipos-endereco",
        {}
      );

      var newResp = [];
      response.data.map(function (item) {
        newResp.push({ id: item.tpeId, label: item.tpeNome });
      });
      this.setState({ tipoEnderecos: newResp });
    } catch (err) {
      this.setState({
        hasError: true,
        errorText: "Erro ao obter endereços.",
      });
    }
  }

  async obtemUFs() {
    try {
      const response = await api.post(
        "api/concash/1.0/cliente-area/estados",
        {}
      );
      var newResp = [];
      response.data.map(function (item) {
        newResp.push({ id: item.estId, label: item.estNome });
      });
      this.setState({ ufs: newResp, dataEstados: response.data });
    } catch (err) {
      this.setState({
        open: false,
        hasError: true,
        errorText: "Erro ao obter estados.",
      });
    }
  }

  findState(uf) {
    const estadoBuscado = this.state.dataEStados.find((s) => s.estUf === uf);
    const estado = this.state.ufs.find(
      (e) => e.label === estadoBuscado.estNome
    );
    return estado;
  }

  async onSelectTag(e, value) {
    this.setState({ uf: value.id });
    let estado = value.id;
    try {
      var classe = this;
      const response = await api.post("api/concash/1.0/cliente-area/cidades", {
        estId: estado,
      });
      var newResp = [];
      response.data.map(function (item) {
        newResp.push({ id: item.estId, label: item.estNome });
      });
      this.setState({ cidades: response.data });
    } catch (err) {
      this.setState({
        error: err.response.data,
      });
    }
  }

  cancelDelete() {
    this.setState({ openConfirm: false, deleteId: null });
  }

  deleteItem(item) {
    this.setState({ openConfirm: true, deleteId: item });
  }

  callCidades = async (e, city, text) => {
    this.setState({ uf: e, ufDesc: text });
    let estado = e;
    try {
      var classe = this;
      const response = await api.post("api/concash/1.0/cliente-area/cidades", {
        estId: estado,
      });
      var newResp = [];
      response.data.map(function (item) {
        newResp.push({ id: item.sidId, label: item.sidNome });
      });
      this.setState({ cidades: newResp }, () => {
        classe.state.cidades.map((cidade, index) => {
          if (city == cidade.sidNome) {
            classe.setState({ cidade: cidade.sidId });
          }
        });
      });
    } catch (err) {
      this.setState({
        error: err.response.data,
      });
    }
  };

  callCep = async (e) => {
    const cepString = maskCEP(e);
    const cepNum = parseFloat(e);
    const num = e.replace(/[^\d]/g, "");

    if (num.length <= 8) {
      this.setState({ cep: maskCEP(num) });

      if (num.length === 8) {
        await api
          .get(`/api/concash/1.0/lead-base/busca?cep=${num}`)
          .then(({ data }) => {
            if (data.siafi) {
              this.setState({
                bairro: data.bairro,
                cidade: data.localidade,
                uf: data.uf,
                ufId: data.idEstado,
                cidadeId: data.idCidade,
                logradouro: data.logradouro,
              });
            } else {
              this.setState({
                bairro: "",
                cidade: "",
                uf: "",
                logradouro: "",
                hasError: true,
                errorText: "CEP Não localizado na base ou não existe.",
              });
            }
          });
      }
    }
  };

  handleFormEndereco = async (e) => {
    e.preventDefault();
    const {
      cep,
      cidade,
      uf,
      numero,
      bairro,
      logradouro,
      tipoEndereco,
      complemento,
      melhorContato,
    } = this.state;

    if (!melhorContato || melhorContato === "") {
      return this.setState({
        hasError: true,
        errorText: "Selecione se este é o endereço principal",
      });
    }
    if (!cep) {
      return this.setState({ hasError: true, errorText: "Preencha o CEP" });
    }
    if (!logradouro) {
      return this.setState({
        hasError: true,
        errorText: "Preencha o campo logradouro",
      });
    }
    if (!numero) {
      return this.setState({
        hasError: true,
        errorText: "Informe o número do imóvel.",
      });
    }
    if (!bairro) {
      return this.setState({
        hasError: true,
        errorText: "Preencha o campo bairro.",
      });
    }

    if (
      !cep ||
      !cidade ||
      !uf ||
      !numero ||
      !logradouro ||
      !bairro ||
      melhorContato === ""
    ) {
    } else {
      try {
        this.setState({ open: true });
        let codigoCliente = this.state.codigoCliente;
        let cepNew = parseInt(cep.replace("-", ""));
        const response = await api.post(
          "api/concash/1.0/cliente-area/cadastrar-endereco",
          {
            cep: cepNew,
            estado: this.state.ufId,
            numero,
            logradouro,
            bairro,
            cidade: this.state.cidadeId,
            cliente: codigoCliente,
            complemento: complemento,
            principal: melhorContato == 2 ? 0 : 1,
          }
        );
        this.obtemEnderecos(codigoCliente);
        this.setState({
          cep: "",
          cidade: "",
          uf: "",
          numero: "",
          bairro: "",
          logradouro: "",
          tipoEndereco: "",
          melhorContato: null,
          complemento: "",
          melhorContatoDesc: "",
          open: false,
          hasSuccess: true,
          successText: "Endereço cadastrado com sucesso!",
        });
      } catch (err) {
        this.setState({
          open: false,
          hasError: true,
          errorText: err.response.data
            ? err.response.data
            : "Erro ao cadastrar endereço.",
        });
      }
    }
  };

  handleDeletarEndereco = async (e) => {
    try {
      this.setState({ open: true, openConfirm: false });
      let codigoCliente = this.state.codigo;
      const response = await api.delete(
        "api/concash/1.0/cliente-area/excluir-endereco/" + this.state.deleteId
      );
      this.setState({ enderecos: [] });
      this.obtemEnderecos(codigoCliente);
      this.setState({
        open: false,
        hasSuccess: true,
        successText: "Endereço excluído com sucesso!",
      });
    } catch (err) {
      this.setState({
        open: false,
        hasError: true,
        errorText: "Erro ao excluir endereço.",
      });
    }
  };

  melhorContOptions = [
    { label: "Sim", id: 1 },
    { label: "Não", id: 2 },
  ];

  render() {
    return (
      <>
        <V3Modal
          open={this.state.hasError}
          actionClose={() => this.setState({ hasError: false })}
          title={"Atenção!"}
          text={this.state.errorText}
          type="error"
        />
        <V3Modal
          open={this.state.hasSuccess}
          actionClose={() => this.setState({ hasSuccess: false })}
          title={"Sucesso!"}
          text={this.state.successText}
          type="success"
        />
        {this.state.isMobile ? (
          <>
            <V3ModalConfirm
              open={this.state.openConfirm}
              title="Tem certeza que deseja excluir este endereço?"
              text="A exclusão é irreversível."
              actionClose={() => this.cancelDelete()}
              actionConfirm={() => this.handleDeletarEndereco()}
              textCancel="Não"
              textConfirm="Sim"
            />
            <S.ContentBoxMobile>
              {this.state.enderecos &&
                this.state.enderecos.map((endereco, index) => (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                      minHeight: 120,
                      borderBottom: "1px solid #979797",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        color: "#000",
                      }}
                    >
                      <V3Text5 style={{ color: "#000" }}>
                        {endereco.logradouro}
                        {", "}
                        {endereco.numero}{" "}
                      </V3Text5>
                      <V3Text5 style={{ color: "#000" }}>
                        CEP: {endereco.cep}
                      </V3Text5>
                      <V3Text5 style={{ color: "#000" }}>
                        {endereco.bairro} {endereco.complement}
                      </V3Text5>
                      <V3Text5 style={{ color: "#000" }}>
                        {endereco.cidade} / {endereco.estado}
                      </V3Text5>
                      {endereco.principal == 1 && (
                        <V3Text5 style={{ color: "#000", fontWeight: "bold" }}>
                          Endereço Principal
                        </V3Text5>
                      )}
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={(e) => this.deleteItem(endereco.id, e)}
                    >
                      <RxTrash color="#000" size={25} />
                    </div>
                  </div>
                ))}
              {this.state.enderecos !== null &&
                this.state.enderecos.length !== 0 && (
                  <S.Row>
                    <V3Text5>ADICIONAR OUTRO ENDEREÇO</V3Text5>
                  </S.Row>
                )}
              {!this.state.enderecos.length && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    minHeight: 150,
                  }}
                >
                  <MdOutlineLocationOn color="#979797" size={40} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <V3Text5>Nenhum endereço cadastrado</V3Text5>
                    <V3Text5 style={{ fontWeight: "bold" }}>
                      Preencha abaixo para adicionar
                    </V3Text5>
                  </div>
                </div>
              )}

              <S.Row>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <V3Text5 style={{ color: "#000", marginRight: 15 }}>
                    É o principal?
                  </V3Text5>
                  <Radio
                    checked={this.state.melhorContato === "1"}
                    onChange={() => this.setState({ melhorContato: "1" })}
                    value="1"
                    name="radio-buttons"
                    label="Sim"
                    inputProps={{ "aria-label": "A" }}
                  />
                  <V3Text5 style={{ color: "#000" }}>Sim</V3Text5>
                  <Radio
                    checked={this.state.melhorContato === "2"}
                    onChange={() => this.setState({ melhorContato: "2" })}
                    value="2"
                    name="radio-buttons"
                    label="Não"
                    inputProps={{ "aria-label": "B" }}
                  />
                  <V3Text5 style={{ color: "#000" }}>Não</V3Text5>
                </div>
              </S.Row>
              <S.Row>
                <V3TextCustom
                  className="input-form-miu"
                  id="cep"
                  size="small"
                  label="CEP"
                  value={this.state.cep ? this.state.cep : ""}
                  onChange={(e) => this.callCep(e.target.value)}
                  variant="outlined"
                />
              </S.Row>
              <S.Row>
                <V3TextCustom
                  className="input-form-miu"
                  id="Logradouro"
                  size="small"
                  label="Logradouro"
                  value={this.state.logradouro ? this.state.logradouro : ""}
                  onChange={(e) =>
                    this.setState({ logradouro: e.target.value })
                  }
                  variant="outlined"
                />
              </S.Row>
              <S.Row>
                <V3TextCustom
                  className="input-form-miu"
                  id="numero"
                  size="small"
                  label="Numero"
                  value={this.state.numero ? this.state.numero : ""}
                  onChange={(e) => this.setState({ numero: e.target.value })}
                  variant="outlined"
                />
              </S.Row>
              <S.Row>
                <V3TextCustom
                  className="input-form-miu"
                  size="small"
                  id="complemento"
                  label="Complemento"
                  value={this.state.complemento ? this.state.complemento : ""}
                  onChange={(e) =>
                    this.setState({ complemento: e.target.value })
                  }
                  variant="outlined"
                />
              </S.Row>
              <S.Row>
                <V3TextCustom
                  className="input-form-miu"
                  id="bairro"
                  size="small"
                  label="Bairro"
                  value={this.state.bairro ? this.state.bairro : ""}
                  onChange={(e) => this.setState({ bairro: e.target.value })}
                  variant="outlined"
                />
              </S.Row>
              <S.Row>
                <V3TextCustom
                  className="input-form-miu"
                  style={{ display: "flex", flex: 1, maxWidth: "100%" }}
                  id="uf"
                  size="small"
                  label="Estado"
                  disabled
                  value={this.state.uf ? this.state.uf : ""}
                  variant="outlined"
                />
              </S.Row>
              <S.Row>
                <V3TextCustom
                  className="input-form-miu"
                  style={{ display: "flex", flex: 1, maxWidth: "100%" }}
                  id="cidade"
                  size="small"
                  label="Cidade"
                  disabled
                  value={this.state.cidade ? this.state.cidade : ""}
                  variant="outlined"
                />
              </S.Row>
              <S.Row>
                <V3Button
                  onClick={this.handleFormEndereco}
                  estilo="primary"
                  width={"100%"}
                  text="Salvar"
                />
                <Backdrop
                  sx={{
                    color: "#16B6C5",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={this.state.open}
                >
                  <Lottie animationData={Loading} />
                </Backdrop>
              </S.Row>
            </S.ContentBoxMobile>
          </>
        ) : (
          <>
            <V3ModalConfirm
              open={this.state.openConfirm}
              title="Tem certeza que deseja excluir este endereço?"
              text="A exclusão é irreversível."
              actionClose={() => this.cancelDelete()}
              actionConfirm={() => this.handleDeletarEndereco()}
              textCancel="Não"
              textConfirm="Sim"
            />
            <S.ContentBox>
              {this.state.enderecos &&
                this.state.enderecos.map((endereco, index) => (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                      minHeight: 120,
                      borderBottom: "1px solid #979797",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        color: "#000",
                      }}
                    >
                      <V3Text5 style={{ color: "#000" }}>
                        {endereco.logradouro}
                        {", "}
                        {endereco.numero}{" "}
                      </V3Text5>
                      <V3Text5 style={{ color: "#000" }}>
                        CEP: {endereco.cep}
                      </V3Text5>
                      <V3Text5 style={{ color: "#000" }}>
                        {endereco.bairro} {endereco.complement}
                      </V3Text5>
                      <V3Text5 style={{ color: "#000" }}>
                        {endereco.cidade} / {endereco.estado}
                      </V3Text5>
                      {endereco.principal == 1 && (
                        <V3Text5 style={{ color: "#000", fontWeight: "bold" }}>
                          Endereço Principal
                        </V3Text5>
                      )}
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={(e) => this.deleteItem(endereco.id, e)}
                    >
                      <RxTrash color="#000" size={25} />
                    </div>
                  </div>
                ))}
              {this.state.enderecos !== null &&
                this.state.enderecos.length !== 0 && (
                  <S.Row>
                    <V3Text5>ADICIONAR OUTRO ENDEREÇO</V3Text5>
                  </S.Row>
                )}
              {!this.state.enderecos.length && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    minHeight: 150,
                  }}
                >
                  <MdOutlineLocationOn color="#979797" size={40} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <V3Text5>Nenhum endereço cadastrado</V3Text5>
                    <V3Text5 style={{ fontWeight: "bold" }}>
                      Preencha abaixo para adicionar
                    </V3Text5>
                  </div>
                </div>
              )}

              <S.Row style={{ width: "100%" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <V3Text5 style={{ color: "#000", marginRight: 15 }}>
                    É o principal?
                  </V3Text5>
                  <Radio
                    checked={this.state.melhorContato === "1"}
                    onChange={() => this.setState({ melhorContato: "1" })}
                    value="1"
                    name="radio-buttons"
                    label="Sim"
                    inputProps={{ "aria-label": "A" }}
                  />
                  <V3Text5 style={{ color: "#000" }}>Sim</V3Text5>
                  <Radio
                    checked={this.state.melhorContato === "2"}
                    onChange={() => this.setState({ melhorContato: "2" })}
                    value="2"
                    name="radio-buttons"
                    label="Não"
                    inputProps={{ "aria-label": "B" }}
                  />
                  <V3Text5 style={{ color: "#000" }}>Não</V3Text5>
                </div>
              </S.Row>
              <S.Row>
                <V3TextCustom
                  className="input-form-miu"
                  id="cep"
                  size="small"
                  label="CEP"
                  value={this.state.cep ? this.state.cep : ""}
                  onChange={(e) => this.callCep(e.target.value)}
                  variant="outlined"
                />
                <V3TextCustom
                  className="input-form-miu"
                  id="Logradouro"
                  size="small"
                  label="Logradouro"
                  value={this.state.logradouro ? this.state.logradouro : ""}
                  onChange={(e) =>
                    this.setState({ logradouro: e.target.value })
                  }
                  variant="outlined"
                />
              </S.Row>
              <S.Row>
                <V3TextCustom
                  className="input-form-miu"
                  id="numero"
                  size="small"
                  label="Numero"
                  value={this.state.numero ? this.state.numero : ""}
                  onChange={(e) => this.setState({ numero: e.target.value })}
                  variant="outlined"
                />
                <V3TextCustom
                  className="input-form-miu"
                  size="small"
                  id="complemento"
                  label="Complemento"
                  value={this.state.complemento ? this.state.complemento : ""}
                  onChange={(e) =>
                    this.setState({ complemento: e.target.value })
                  }
                  variant="outlined"
                />
              </S.Row>
              <S.Row>
                <V3TextCustom
                  className="input-form-miu"
                  id="bairro"
                  size="small"
                  label="Bairro"
                  disabled
                  value={this.state.bairro ? this.state.bairro : ""}
                  onChange={(e) => this.setState({ bairro: e.target.value })}
                  variant="outlined"
                />
                <V3TextCustom
                  className="input-form-miu"
                  style={{ display: "flex", flex: 1, maxWidth: "50%" }}
                  id="uf"
                  size="small"
                  label="Estado"
                  disabled
                  value={this.state.uf ? this.state.uf : ""}
                  variant="outlined"
                />
              </S.Row>
              <S.Row>
                <V3TextCustom
                  className="input-form-miu"
                  style={{ display: "flex", flex: 1, maxWidth: "50%" }}
                  id="cidade"
                  size="small"
                  label="Cidade"
                  disabled
                  value={this.state.cidade ? this.state.cidade : ""}
                  variant="outlined"
                />
              </S.Row>
              <S.Row>
                <V3Button
                  onClick={this.handleFormEndereco}
                  estilo="primary"
                  width={"49%"}
                  text="Salvar"
                />
                <Backdrop
                  sx={{
                    color: "#16B6C5",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={this.state.open}
                >
                  <Lottie animationData={Loading} />
                </Backdrop>
              </S.Row>
            </S.ContentBox>
          </>
        )}
      </>
    );
  }
}

export default Endereco;
