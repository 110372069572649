import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";



export class FormUsuarioVisualizacao extends Component {



  constructor(props){

    super(props);

    this.state = {
          name:              '',
          proprios:          '',
          editarProprios:    '',
          editarTodos:       '',
          excluirHierarquia: '',
          excluirTodos:      '',
          editarHierarquia:  '',
          excluirProprios:   '',
          todos:             '',
          hierarquia:        '',
          id:                '',
          labelForm:         '',
          labelButton:       '',
          error:             "",
          action:            ""
    };
  }


  async componentDidMount() {

        if(this.props[0].location.state !== undefined){
            const usuario  = this.props[0].location.state.usuario
            this.obtemRegrasVisualizacao(usuario.id);
            this.setState({name: usuario.name,id: usuario.id});
            this.setState({labelForm: "Editar Regras de Visualização",labelButton: "Editar",action: "editar"});
        }else{
            this.setState({labelForm: "Editar Regras de Visualização",labelButton: "Adicionar",action: "adicionar"});
        }
  }

  async obtemRegrasVisualizacao(id) {
    try {

          const response = await api.post("api/sistema/1.0/usuario-visualizacao/listar", {  userCodigo: id });

          this.setState( {
            proprios:          response.data.proprios,
            editarProprios:    response.data.editarProprios,
            editarTodos:       response.data.editarTodos,
            excluirHierarquia: response.data.excluirHierarquia,
            excluirTodos:      response.data.excluirTodos,
            editarHierarquia:  response.data.editarHierarquia,
            excluirProprios:   response.data.excluirProprios,
            todos:             response.data.todos,
            hierarquia:        response.data.hierarquia
          });

    } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter as configurações."
          });
    }
  }

  handleForm = async e => {
    e.preventDefault();
    const { proprios,
            todos,
            editarHierarquia,
            editarTodos,
            editarProprios,
            excluirProprios,
            excluirTodos,
            excluirHierarquia,
            hierarquia,
            id } = this.state;
    if (!proprios
        || !todos
        || !id
        || !editarTodos
        || !excluirProprios
        || !editarHierarquia
        || !editarProprios
        || !excluirHierarquia
        || !excluirTodos
        || !hierarquia) {
      this.setState({ error: "Preencha os campos obrigatórios para continuar!" });
    } else {
      try {
          const response = await api.put("api/sistema/1.0/usuario-visualizacao/editar", {
              proprios,todos,userCodigo:id,hierarquia,editarTodos,excluirProprios,editarHierarquia,editarProprios,excluirHierarquia,excluirTodos
          });
          this.props.history.push("/usuario");
      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o processamento da requisição."
        });
      }
    }
  };

  render() {


    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> {this.state.labelForm} - Usuário {this.state.name} </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>

        <div className="row">

          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"></h4>
                {this.state.error && <p className="card-description">{this.state.error}</p>}
                <form className="forms-sample" onSubmit={this.handleForm}>

                <div className="row">
                    <div className="col-md-12">
                    <Form.Group className="row" >
                      <label className="col-sm-5 col-form-label">Visualizar apenas os próprios registros?</label>
                      <div className="col-sm-7">
                        <select className="form-control" value={this.state.proprios} onChange={e => this.setState({ proprios: e.target.value })}>
                            <option value="" >Selecione</option>
                            <option value="SIM" >Sim</option>
                            <option value="NAO" >Não</option>
                        </select>
                      </div>
                    </Form.Group>
                    <Form.Group className="row" >
                      <label className="col-sm-5 col-form-label">Visualizar registros de sua hierarquia?</label>
                      <div className="col-sm-7">
                        <select className="form-control" value={this.state.hierarquia} onChange={e => this.setState({ hierarquia: e.target.value })}>
                            <option value="" >Selecione</option>
                            <option value="SIM" >Sim</option>
                            <option value="NAO" >Não</option>
                        </select>
                      </div>
                    </Form.Group>
                    <Form.Group className="row" >
                      <label className="col-sm-5 col-form-label">Visualizar todos os registros?</label>
                      <div className="col-sm-7">
                        <select className="form-control" value={this.state.todos} onChange={e => this.setState({ todos: e.target.value })}>
                            <option value="" >Selecione</option>
                            <option value="SIM" >Sim</option>
                            <option value="NAO" >Não</option>
                        </select>
                      </div>
                    </Form.Group>
                    <Form.Group className="row" >
                      <label className="col-sm-5 col-form-label">Editar apenas os próprios registros?</label>
                      <div className="col-sm-7">
                        <select className="form-control" value={this.state.editarProprios} onChange={e => this.setState({ editarProprios: e.target.value })}>
                            <option value="" >Selecione</option>
                            <option value="SIM" >Sim</option>
                            <option value="NAO" >Não</option>
                        </select>
                      </div>
                    </Form.Group>
                    <Form.Group className="row" >
                      <label className="col-sm-5 col-form-label">Editar apenas registros da hierarquia?</label>
                      <div className="col-sm-7">
                        <select className="form-control" value={this.state.editarHierarquia} onChange={e => this.setState({ editarHierarquia: e.target.value })}>
                            <option value="" >Selecione</option>
                            <option value="SIM" >Sim</option>
                            <option value="NAO" >Não</option>
                        </select>
                      </div>
                    </Form.Group>
                    <Form.Group className="row" >
                      <label className="col-sm-5 col-form-label">Editar todos os registros?</label>
                      <div className="col-sm-7">
                        <select className="form-control" value={this.state.editarTodos} onChange={e => this.setState({ editarTodos: e.target.value })}>
                            <option value="" >Selecione</option>
                            <option value="SIM" >Sim</option>
                            <option value="NAO" >Não</option>
                        </select>
                      </div>
                    </Form.Group>
                    <Form.Group className="row" >
                      <label className="col-sm-5 col-form-label">Excluir apenas os próprios registros?</label>
                      <div className="col-sm-7">
                        <select className="form-control" value={this.state.excluirProprios} onChange={e => this.setState({ excluirProprios: e.target.value })}>
                            <option value="" >Selecione</option>
                            <option value="SIM" >Sim</option>
                            <option value="NAO" >Não</option>
                        </select>
                      </div>
                    </Form.Group>
                    <Form.Group className="row" >
                      <label className="col-sm-5 col-form-label">Excluir apenas os registros da hierarquia?</label>
                      <div className="col-sm-7">
                        <select className="form-control" value={this.state.excluirHierarquia} onChange={e => this.setState({ excluirHierarquia: e.target.value })}>
                            <option value="" >Selecione</option>
                            <option value="SIM" >Sim</option>
                            <option value="NAO" >Não</option>
                        </select>
                      </div>
                    </Form.Group>
                    <Form.Group className="row" >
                      <label className="col-sm-5 col-form-label">Excluir todos os registros?</label>
                      <div className="col-sm-7">
                        <select className="form-control" value={this.state.excluirTodos} onChange={e => this.setState({ excluirTodos: e.target.value })}>
                            <option value="" >Selecione</option>
                            <option value="SIM" >Sim</option>
                            <option value="NAO" >Não</option>
                        </select>
                      </div>
                    </Form.Group>
                    </div>
                  </div>
                  <div className="text-right">
                    <button type="submit" className="btn btn-primary mr-2">{this.state.labelButton}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
         </div>
       </div>
    )
  }
}

export default withRouter(FormUsuarioVisualizacao)
