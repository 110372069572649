import React from 'react';
import { TopbarDesktop, TopbarMobile } from './Topbar';
import { SidebarDesktop } from './Sidebar';
import S from "./Base.module.css";

export const BaseDesktop = ({children, estilo}) => {

  if (estilo) return (
    <div className={S.baseContent}>
      <TopbarDesktop estilo={estilo} />
      <div className={S.contentMainDesktop}>
        <SidebarDesktop estilo={estilo} />
      {children}
      </div>
    </div>
  );
}

export const BaseMobile = ({children, estilo}) => {

  if (estilo) return (
    <div className={S.baseContent}>
      <TopbarMobile estilo={estilo} />
      <div className={S.contentMainMobile}>
      {children}
      </div>
    </div>
  );
}

