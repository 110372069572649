import React, { Component, Fragment } from "react";
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2'
import api from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import { Link, withRouter } from "react-router-dom";



export class Kyc extends Component {


  constructor(props) {

    super(props);

    const { codigoCliente,origem }     = this.props;

    this.state = {
      codigoCliente: codigoCliente,
      questionario:[],
      error: ""
    };

  }


  async componentDidMount() {

      this.obtemQuestionario(this.state.codigoCliente);
  }


  async  obtemQuestionario(codigo){
    try {
          const response = await api.post("api/concash/1.0/cliente/questionario", {codigo:codigo});
          if(response.data){
              this.setState({ questionario: response.data.Events[0].Questions});
          }
    } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter o questionário."
          });
    }
  }



  render() {

    return (

      <div className="row">

        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Questionário</h4>
              <p className="card-description"></p>

                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th> Pergunta   </th>
                          <th> Resposta </th>
                          <th> Resposta Correta </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.questionario.map((questao,index) => (
                        <tr key={index}>
                          <td> {questao.Text} </td>
                          <td> {questao.Answer} </td>
                          <td> {questao.Correct} </td>
                        </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

            </div>
          </div>
        </div>

      </div>

    )
  }
}


export default Kyc;
