import React, { Component, Fragment } from "react";
// import { Form } from 'react-bootstrap';
import Swal from "sweetalert2";
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";

export class ClienteCampos extends Component {
  constructor(props) {
    super(props);
    const { teste } = this.props;

    this.state = {
      teste: teste,
      error: "",
    };
  }

  async componentDidMount() {}

  render() {
    return (
      <>
        {/* <Form.Group className="row">
          <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">
            Teste
          </label>
          <div className="col-sm-9">
            <Form.Control
              type="text"
              className="form-control"
              id="teste"
              value={this.state.teste}
              onChange={(e) => this.setState({ teste: e.target.value })}
              size="sm"
            />
          </div>
        </Form.Group> */}
      </>
    );
  }
}

export default ClienteCampos;
