import React, { Component, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import Logo from "../../assets/logo.png";
import { RECAPTCHA_KEY } from "../../site.config";
import { Form } from "react-bootstrap";
import api from "../../services/api";
import Swal from "sweetalert2";
import { login } from "../../services/auth";
import styled, { css } from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";
import SolicitacaoEsqueciSenha from "../../components/SolicitacaoEsqueciSenha";
import CriarConta from "../../components/CriarConta";
import PasswordChecker from "../../components/PaswordChecker";
import { cpf, cnpj } from "cpf-cnpj-validator";
import { cpfCnpj } from "../../mask/cpfcnpj";
import { validatorEmail } from "validator";
import isEmail from "validator/lib/isEmail";
import { maskCEP } from "../../mask/maskCEP";
import cep from "cep-promise";
import { isValidCNPJ } from "../../utils/validateCNPJ";
import * as S from "./recuperar.styles";
import family from "../../assets/images/novo/family.png";
import ConcashLogo from "../../styles/themes/v3/assets/logo Concash.png";
import { V3Link, V3Text5, V3Title } from "../../styles/themes/components/texts";
import { V3TextCustom } from "../../styles/themes/components/input";
import { V3Button } from "../../styles/themes/components/button";
import { v3Colors } from "../../styles/themes/v3/colors";
import {
  maskDataDeNascimento,
  v3FormataCNPJ,
  v3MaskCPF,
} from "../../styles/themes/utils/format";
import { InfoModal, V3Modal } from "../../styles/themes/components/modal";
import {
  hasLowerCase,
  hasNumber,
  hasSpecialChar,
  hasUpperCase,
  isPastDate,
  isValidDate,
  v3ValidaCpfCnpj,
  v3ValidateEmail,
} from "../../styles/themes/utils/validate";
import { BsCheck } from "react-icons/bs";
import moment from "moment";

const RecuperarSenha = (props) => {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    const tela = window.matchMedia("(max-width:768px)").matches;
    setMobile(tela);
  }, []);

  return (
    <>
      <div>
        {mobile ? (
          <RecuperarMobile p={props} />
        ) : (
          <RecuperarDesktop p={props} />
        )}
      </div>
    </>
  );
};

const RecuperarDesktop = (props) => {
  let { p } = props;
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [cpfCnpjErr, setCpfCnpjErr] = useState(false);
  const [error, setError] = useState(false);
  const [sucesso, setSucesso] = useState(false);

  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [errorText, setErrorText] = useState("");

  async function handleRecuperar(e) {
    e.preventDefault();
    if (!cpfCnpj || !v3ValidaCpfCnpj(cpfCnpj) || cpfCnpjErr) {
      setHasError(true);
      setErrorText("Preencha o campo com um CPF / CNPJ válido.");
      return setCpfCnpjErr(true);
    }

    const newCpfCnpj = cpfCnpj.replace(/[^0-9]/g, "");

    await api
      .post("api/site/1.0/esqueci-senha/enviar-lembrete", {
        cpfCnpj: newCpfCnpj,
        email: newCpfCnpj,
      })
      .then(({ data }) => {
        setSucesso(true);
      });
  }

  function handleCpfCnpj(text) {
    const valid = v3ValidaCpfCnpj(text);

    const validating = text.replace(/[^\d]/g, "");
    if (validating.length <= 14) {
      if (validating.length <= 11) {
        const c = v3MaskCPF(validating);
        setCpfCnpjErr(!valid);
        setCpfCnpj(c);
      } else {
        setCpfCnpjErr(!valid);

        const cn = v3FormataCNPJ(validating);
        setCpfCnpj(cn);
      }
    }
  }

  function valida() {
    if (!cpfCnpj) {
      return true;
    } else {
      if (!v3ValidaCpfCnpj(cpfCnpj)) {
        return false;
      } else {
        return true;
      }
    }
  }

  return (
    <>
      <V3Modal
        open={hasError}
        style={{ zIndex: 999 }}
        actionClose={() => setHasError(false)}
        title={errorText}
        type="warning"
      />
      <V3Modal
        open={hasSuccess}
        style={{ zIndex: 999 }}
        actionClose={() => setHasSuccess(false)}
        title={successText}
        type="success"
      />
      <div>
        <S.Container>
          <S.BoxLogin style={{ zIndex: 998 }}>
            <S.Row>
              <img src={ConcashLogo} width={162} height={44} />
            </S.Row>
            {sucesso ? (
              <>
                <S.RowCenter
                  style={{ marginTop: 30, marginBottom: 10 }}
                ></S.RowCenter>
                <S.RowCenter style={{ marginBottom: 30 }}>
                  <V3Title style={{ textAlign: "center" }}>
                    Enviamos um link para redefinir a sua senha no seguinte
                    email:
                  </V3Title>
                </S.RowCenter>
                <S.RowCenter style={{ marginBottom: 30 }}>
                  <V3Text5 style={{ color: v3Colors.black }}>
                    Caso você não receba o e-mail em alguns minutos, verifique a
                    sua caixa de spam ou repita o processo.
                  </V3Text5>
                </S.RowCenter>
              </>
            ) : (
              <>
                <S.RowCenter style={{ marginTop: 30, marginBottom: 15 }}>
                  <V3Title>Esqueci a senha</V3Title>
                </S.RowCenter>

                <S.Row style={{ marginTop: 15, marginBottom: 15 }}>
                  <V3TextCustom
                    size="small"
                    sx={{ width: 447 }}
                    label="CPF / CNPJ"
                    onChange={(e) => {
                      handleCpfCnpj(e.target.value);
                    }}
                    error={cpfCnpjErr}
                    value={cpfCnpj}
                    helperText={
                      cpfCnpjErr &&
                      "Este CPF / CNPJ não está cadastrado. Crie sua conta."
                    }
                    required
                  />
                </S.Row>

                <S.RowCenter>
                  <V3Button
                    onClick={handleRecuperar}
                    estilo="primary"
                    width={"100%"}
                    text="Enviar"
                  />
                </S.RowCenter>
              </>
            )}
          </S.BoxLogin>
          <S.BoxSideRight>
            <img src={family} style={{ position: "fixed", top: 0 }} />
          </S.BoxSideRight>
        </S.Container>
      </div>
    </>
  );
};
const RecuperarMobile = (props) => {
  let { p } = props;
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [cpfCnpjErr, setCpfCnpjErr] = useState(false);
  const [error, setError] = useState(false);
  const [sucesso, setSucesso] = useState(false);

  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [errorText, setErrorText] = useState("");

  async function handleRecuperar(e) {
    e.preventDefault();
    if (!cpfCnpj || !v3ValidaCpfCnpj(cpfCnpj) || cpfCnpjErr) {
      setHasError(true);
      setErrorText("Preencha o campo com um CPF / CNPJ válido.");
      return setCpfCnpjErr(true);
    }

    const newCpfCnpj = cpfCnpj.replace(/[^0-9]/g, "");

    await api
      .post("api/site/1.0/esqueci-senha/enviar-lembrete", {
        cpfCnpj: newCpfCnpj,
        email: newCpfCnpj,
      })
      .then(({ data }) => {
        setSucesso(true);
      });
  }

  function handleCpfCnpj(text) {
    const valid = v3ValidaCpfCnpj(text);

    const validating = text.replace(/[^\d]/g, "");
    if (validating.length <= 14) {
      if (validating.length <= 11) {
        const c = v3MaskCPF(validating);
        setCpfCnpjErr(!valid);
        setCpfCnpj(c);
      } else {
        setCpfCnpjErr(!valid);
        const cn = v3FormataCNPJ(validating);
        setCpfCnpj(cn);
      }
    }
  }

  return (
    <>
      <V3Modal
        open={hasError}
        style={{ zIndex: 999 }}
        actionClose={() => setHasError(false)}
        title={errorText}
        type="warning"
      />
      <V3Modal
        open={hasSuccess}
        style={{ zIndex: 999 }}
        actionClose={() => setHasSuccess(false)}
        title={successText}
        type="success"
      />
      <div>
        <S.Container>
          <S.BoxLoginMobile style={{ zIndex: 998 }}>
            <S.Row>
              <img src={ConcashLogo} width={162} height={44} />
            </S.Row>
            {sucesso ? (
              <>
                <S.RowCenter
                  style={{ marginTop: 30, marginBottom: 10 }}
                ></S.RowCenter>
                <S.RowCenter style={{ marginBottom: 30 }}>
                  <V3Title style={{ textAlign: "center" }}>
                    Enviamos um link para redefinir a sua senha no seguinte
                    email:
                  </V3Title>
                </S.RowCenter>
                <S.RowCenter style={{ marginBottom: 30 }}>
                  <V3Text5 style={{ color: v3Colors.black }}>
                    Caso você não receba o e-mail em alguns minutos, verifique a
                    sua caixa de spam ou repita o processo.
                  </V3Text5>
                </S.RowCenter>
              </>
            ) : (
              <>
                <S.RowCenter style={{ marginTop: 30, marginBottom: 15 }}>
                  <V3Title>Esqueci a senha</V3Title>
                </S.RowCenter>

                <S.Row style={{ marginTop: 15, marginBottom: 15 }}>
                  <V3TextCustom
                    size="small"
                    sx={{ width: 447 }}
                    label="CPF / CNPJ"
                    onChange={(e) => {
                      handleCpfCnpj(e.target.value);
                    }}
                    error={cpfCnpjErr}
                    value={cpfCnpj}
                    helperText={
                      cpfCnpjErr &&
                      "Este CPF / CNPJ não está cadastrado. Crie sua conta."
                    }
                    required
                  />
                </S.Row>

                <S.RowCenter>
                  <V3Button
                    onClick={handleRecuperar}
                    estilo="primary"
                    width={"100%"}
                    text="Enviar"
                  />
                </S.RowCenter>
              </>
            )}
          </S.BoxLoginMobile>
        </S.Container>
      </div>
    </>
  );
};

export default withRouter(RecuperarSenha);
