import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import { Link } from "react-router-dom";


export class ListProspectSessao extends Component {


  constructor(props){
        super(props);
  }


  render() {

    const { sessao, index }  = this.props;

    return (
      <tr key={index}>
        <td> {sessao.prospect.nome} </td>
        <td> {sessao.prospect.cpf} </td>
        <td> {sessao.prospect.email} </td>
        <td className="text-center"><Link
                        to={{pathname: "/whatsapp-sessao/conversa",state: {
                          codigoCliente: null, codigoProspect: sessao.prospect.codigo 
                        }}}
                        className="m-3 btn btn-sm btn-success"
                      >
                      Conversa
            </Link>
        </td>
      </tr>
    )
  }
}

export default ListProspectSessao;
