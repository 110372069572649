export const listTiposPix = [
  { id: 3, label: "Celular" },
  { id: 4, label: "Chave Aleatória" },
  { id: 1, label: "CPF ou CNPJ"},
  { id: 2, label: "E-mail" },
];

export const listTiposConta = [{id: 1, label: "Conta Corrente"}, {id: 3, label:"Conta Poupança"}];

export const listaSituacaoEconomica = [
  { value: "NAO-RECUPERACAO", label: "Não Recuperação" },
];