import React, { useState, useEffect } from "react";
import { BaseDesktop, BaseMobile } from "../components/common/Base";
import S from "../Autoservico.module.css";
import { ButtonMain } from "../components/buttons";
import { FaCheck, FaCheckCircle } from "react-icons/fa";
import api from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import LoadingCustom from "../../../components/LoadingCustom";
import { idAdm } from "./detalhes";
import { logoutAuto } from "../../../store/autoservico";
import { V3Modal } from "../../../styles/themes/components/modal";

export const Assinar = ({ estilo }) => {
  const service = window.location.pathname.replace("/autoservico/", "").split("/");
  const adm = service[0];
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const state = useSelector(state => state.autoservico);
  const [loading, setLoading] = useState(false);
  const [ dadosPessoais, setDadosPessoais ] = useState("");
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [ cota, setCota ] = useState();
  const [ valorCota, setValorCota ] = useState(null);
  const [ assinado, setAssinado ] = useState(false);

  async function handleGetDadosPessoais() {
    await api.get("/api/concash/1.0/consorcio-grupo-cota/recuperar-dados-atendimento", {
      headers: {
        Authorization: "Bearer " + state.token,
      },
    })
    .then(({data}) => {
      setDadosPessoais({...data?.dadosPessoais});
    })
    .catch((e) => {
      dispatch(logoutAuto());
      window.location.href = "/autoservico/"+service[0]+"/login"
      return console.log(e)
    });
  }


  async function handleGetCota(){
    setLoading(true);
    // setTimeout(() => {

    // }, 2000);
    await api
    .get(
      `/api/concash/1.0/consorcio-grupo-cota/negociacoes/${service[2]}?cpfCnpj=${state.cpfCnpj.replace(/\D/g, "")}&idAdministradora=${idAdm[adm]}&Tokenconsort=${state?.otp}`, {
        headers: {
          TokenConsort: state.tokenConsort
        }
      }).then(({data}) => {
        const pago = data?.etapas.find(e => e.fase === "Payment");
        const assinado = data?.etapas.find(e => e.fase === "AnalisysTermSingNotificationPartner");
        setDadosPessoais({...dadosPessoais, 
          linkSelfie: data?.linkSelfie, 
          linkContrato: data?.linkContrato, 
          linkTermo: data?.linkTermo,
          selfie: !!data?.selfieCaptured,
          assContrato: data?.linkContratoCaptured,
          assTermo: data?.linkTermoCaptured,
          assinados: assinado.status === "Finished",
          pago: pago?.status === "Finished"
        });
        setCota(data);
        setValorCota(data?.propostas[0].valorProposta);
        setLoading(false);
    })
    .catch((e) => {
      setLoading(false);
      setErrorText("Cota não encontrada ou não pode ser assinada.");
      setHasError(true);
    })

  }

  const openNewTab = (link) => {
    window.open(link, '_blank');
  };

  useEffect(() => {
    if(!service[2]) {
      window.location.href = `/autoservico/${service[0]}/minhas-cotas`;
    }
    handleGetCota();
    handleGetDadosPessoais();
  }, [])


    return (
      <div>
        <LoadingCustom open={loading} />
        <V3Modal
          open={hasError}
          actionClose={() => {
            window.location.href = `/autoservico/${service[0]}/minhas-cotas`;
          }}
          title={errorText}
          customButtonColor={estilo?.primary}
          type="warning"
        />
        {isMobile ? (
          <>
            <BaseMobile estilo={estilo}>
              <div className={S.contentIntern} style={{ alignItems:"center", padding: "15px 10px"}}>
                <h1 className={S.titlePage} style={{textAlign: "center"}}>{dadosPessoais?.assinados ? "Pronto, agora é só aguardar o pagamento" : "Pronto, agora só falta assinar o contrato"}</h1>
                <h3 className={S.text15} style={{textAlign: "center"}}>{dadosPessoais?.assinados ? "Como você já assinou tudo agora é só esperar o pagamento na conta informada." : "Para confirmar a venda, você deve assinar digitalmente o contrato. Toque no botão abaixo para iniciar e siga as instruções."}</h3>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex"}}>
          <div style={{ minWidth: 135, fontWeight: 'bold', fontSize: 15}}><span>Cota selecionada</span></div>
           <span>{cota?.administradora}</span>
        </div>
        <div style={{ display: "flex"}}>
          <div style={{ minWidth: 135, fontWeight: 'bold', fontSize: 15}}><span>Grupo</span></div>
           <span>{cota?.grupo}</span>
        </div>
        <div style={{ display: "flex"}}>
          <div style={{ minWidth: 135, fontWeight: 'bold', fontSize: 15}}><span>Cota</span></div>
           <span>{cota?.cota}</span>
        </div>
        <div style={{ display: "flex"}}>
          <div style={{ minWidth: 135, fontWeight: 'bold', fontSize: 15}}><span>Contrato</span></div>
           <span>{cota?.contrato}</span>
        </div>
        <div style={{ display: "flex"}}>
          <div style={{ minWidth: 135, fontWeight: 'bold', fontSize: 15}}><span>Valor do Bem</span></div>
           <span>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(cota?.valorDoBem))}</span>
        </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column", marginTop:30 }}>
                <span className={S.text15}><b>Preço de venda</b></span>
                <span className={S.title24} style={{ textAlign: "left",color: estilo?.secondary, fontWeight: "normal"}}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(valorCota))}</span>
              </div>
              <div style={{ display: "flex", flexDirection: "column", marginTop: 30, gap: 20 }}>
                <span className={S.text15}>Com pagamento à vista em até <b>10 dias úteis</b>,<br /> mediante a transferência da cota.</span>
                <>
                {
                  dadosPessoais?.assContrato ? (
                    <ButtonMain estilo={estilo} texto={`${<FaCheckCircle color="#fff" size={24}/>} Contrato Assinado`} disabledColor tamanho={"100%"} onClick={() => openNewTab(dadosPessoais.linkContrato)} disabled={!dadosPessoais.linkContrato} />
                  ) : (
                    <ButtonMain estilo={estilo} texto={dadosPessoais.linkTermo ? "Assinar Termo": "Gerando Termo"} tamanho={"100%"} onClick={() => openNewTab(dadosPessoais.linkContrato)} disabled={!dadosPessoais.linkContrato} />
                  )
                }
                </>
                <>
                {
                  dadosPessoais?.assTermo ? (
                    <ButtonMain estilo={estilo} texto={`Termo de Cessão Assinado`} icon={<FaCheckCircle color="#fff" size={24}/>} disabledColor tamanho={"100%"} onClick={() => openNewTab(dadosPessoais.linkTermo)} disabled />
                  ) : (
                    <ButtonMain estilo={estilo} texto={dadosPessoais.linkTermo ? "Assinar Termo": "Gerando Termo"} tamanho={"100%"} onClick={() => openNewTab(dadosPessoais.linkTermo)} disabled={!dadosPessoais.linkTermo} />
                  )
                }
                </>
                <>
                {
                  dadosPessoais?.selfie ? (
                    <ButtonMain estilo={estilo} texto={`Selfie Finalizada`} icon={<FaCheckCircle color="#fff" size={24}/>} disabledColor tamanho={"100%"} onClick={() => openNewTab(dadosPessoais.linkSelfie)} disabled />
                  ) : (
                    <ButtonMain estilo={estilo} texto={dadosPessoais.linkSelfie ? "Tirar Selfie" : "Gerando Link de Selfie"} tamanho={"100%"} onClick={() => openNewTab(dadosPessoais.linkSelfie)} disabled={!dadosPessoais.linkSelfie} />
                  )
                }
                </>
              </div>
            </BaseMobile>
            </>

        ) : (
          <>
          <BaseDesktop estilo={estilo}>
            <div className={S.flexColumn} style={{ width: "100%" }}>
              <h1 className={S.titlePage}>Pronto, agora só falta assinar o contrato</h1>
              <h3 className={S.text15}>Para confirmar a venda, você deve assinar digitalmente o contrato. Toque no botão abaixo para iniciar e siga as instruções.</h3>
           <div style={{ display: "flex", flexDirection: "column", marginTop: 30, marginBottom: 30 }}>
              <div style={{ display: "flex"}}>
          <div style={{ minWidth: 135, fontWeight: 'bold', fontSize: 15}}><span  className={S.text15}>Cota selecionada</span></div>
           <span  className={S.text15}>{cota?.administradora}</span>
        </div>
        <div style={{ display: "flex"}}>
          <div  className={S.text15} style={{ minWidth: 135, fontWeight: 'bold', fontSize: 15}}><span  className={S.text15}>Grupo</span></div>
           <span  className={S.text15}>{cota?.grupo}</span>
        </div>
        <div style={{ display: "flex"}}>
          <div style={{ minWidth: 135, fontWeight: 'bold', fontSize: 15}}><span  className={S.text15}>Cota</span></div>
           <span  className={S.text15}>{cota?.cota}</span>
        </div>
        <div style={{ display: "flex"}}>
          <div style={{ minWidth: 135, fontWeight: 'bold', fontSize: 15}}><span  className={S.text15}>Contrato</span></div>
           <span  className={S.text15}>{cota?.contrato}</span>
        </div>
        <div style={{ display: "flex"}}>
          <div style={{ minWidth: 135, fontWeight: 'bold', fontSize: 15}}><span  className={S.text15}>Valor do Bem</span></div>
           <span  className={S.text15}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(cota?.valorDoBem))}</span>
        </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span className={S.text15}><b>Preço de venda</b></span>
                <span className={S.title24} style={{ textAlign: "left",color: estilo?.secondary, fontWeight: "normal"}}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(valorCota))}</span>
              </div>
              <div style={{ display: "flex", flexDirection: "column", marginTop: 30, gap: 20 }}>
                <span className={S.text15}>Com pagamento à vista em até <b>10 dias úteis</b>,<br /> mediante a transferência da cota.</span>
                {
                  dadosPessoais?.assContrato ? (
                    <ButtonMain estilo={estilo} texto={`Contrato Assinado`} icon={<FaCheckCircle color="#fff" size={24}/>} disabledColor tamanho={"360px"} onClick={() => openNewTab(dadosPessoais.linkContrato)} disabled />
                  ) : (
                    <ButtonMain estilo={estilo} texto={dadosPessoais.linkContrato ? "Assinar Contrato" : "Gerando Contrato"} tamanho={"360px"} onClick={() => openNewTab(dadosPessoais.linkContrato)} disabled={!dadosPessoais.linkContrato} />
                  )
                }
                <>
                {
                  dadosPessoais?.assTermo ? (
                    <ButtonMain estilo={estilo} texto={`Termo de Cessão Assinado`} icon={<FaCheckCircle color="#fff" size={24}/>} disabledColor tamanho={"360px"} onClick={() => openNewTab(dadosPessoais.linkTermo)} disabled={!dadosPessoais.linkTermo} />
                  ) : (
                    <ButtonMain estilo={estilo} texto={dadosPessoais.linkTermo ? "Assinar Termo": "Gerando Termo"} tamanho={"360px"} onClick={() => openNewTab(dadosPessoais.linkTermo)} disabled={!dadosPessoais.linkTermo} />
                  )
                }
                </>
              </div>
            </div>
          </BaseDesktop>
          </>
        )}
      </div>
    );
};
