import React, { useCallback, useEffect, useState } from 'react';
import MainStyle from "../../Autoservico.module.css";
import S from "./Forms.module.css";
import { Backdrop, Radio } from '@mui/material';
import { AutoServicoFileInput, V3Autocomplete, V3TextCustom } from '../../../../styles/themes/components/input';
import { MdOutlineClose } from "react-icons/md";
import { InfoDefault } from '../../components/common/Info';
import { maskDataDeNascimento, v3MaskCPF, v3MaskPhone } from '../../../../styles/themes/utils/format';
import { maskCEP } from '../../../../mask/maskCEP';
import { formatCnae, formatCpfCnpj } from "../../../../utils/format";
import { ButtonAction, ButtonMain, ButtonOutlined, SimpleButtonIcon } from '../../components/buttons';
import axios from 'axios';
import FrenteDoc from "../../../../assets/images/frente-doc.png";
import VersoDoc from "../../../../assets/images/verso-doc.png";
import Comprov from "../../../../assets/images/c-resid.png";
import { GrDocumentPdf } from "react-icons/gr";
import { FaCheck, FaFileContract, FaPlus } from "react-icons/fa";
import api from "../../../../services/api";
import useAutoservico from '../../../../store/autoservico/useAutoservico';
import { ModalMain } from '../../components/common/ModalAutoServico';
import { listTiposConta, listTiposPix, listaSituacaoEconomica } from './defaults';
import { useSelector } from 'react-redux';
import { IoIosArrowRoundBack } from 'react-icons/io';
import LoadingCustom from '../../../../components/LoadingCustom';
import { V3Modal, V3ModalConfirm, V3ModalConfirmAutoServico } from '../../../../styles/themes/components/modal';
import { validateDate, validateEmail, validateMobilePhone } from '../../../../utils/validadores';

const FormBase = ({children, estilo, open}) => {
  return (
    <Backdrop open={open}           
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <div className={S.boxMain}>
          
        {children}
      </div>
    </Backdrop>
  );
}

export const FormEndereco = ({estilo, open, closeScreen, clienteData, atualiza }) => {
  const {getTokenAutoServico} = useAutoservico();
  const token = getTokenAutoServico();
  const state = useSelector(state => state.autoservico);
  const config = { headers: { "Authorization": "Bearer " + token }};
  const isMobile = window.innerWidth <= 768;
  const service = window.location.pathname.replace("/autoservico/", "").split("/");
  const [ hasError, setHasError ] = useState(false);
  const [ errorText, setErrorText ] = useState("");
  const [showScreen, setShowScreen] = useState("FORM");
  const [errors, setErrors] = useState([]);
  const [ nome, setNome ] = useState("");
  const [ cpfCnpj, setCpfCnpj ] = useState("");
  const [ cliente, setCliente] = useState("");
  const [ cep, setCep ] = useState("");
  const [ logradouro, setLogradouro ] = useState("");
  const [ numero, setNumero ] = useState("");
  const [ complemento, setComplemento ] = useState("");
  const [ bairro, setBairro ] = useState("");
  const [ cidade, setCidade ] = useState("");
  const [ estado, setEstado ] = useState("");
  const [ uf, setUf ] = useState("");
  const [ idEstado, setIdEstado ] = useState("");
  const [ idCidade, setIdCidade ] = useState("");
  const [ listEstados, setListEstados ] = useState([]);
  const [ docComp, setDocComp ] = useState("");
  const [ comprovante, setComprovante ] = useState("");

  async function handleCEP(e){
    if(e.target.value.replace("-", "").length <= 8) {
      setCep(maskCEP(e.target.value))
      if(e.target.value.replace("-", "").length === 8) {
        await api.get(`/api/concash/1.0/lead-base/busca?cep=${e.target.value}`).then(({data}) => {
          setIdCidade(data.idCidade);
          setIdEstado(data.idEstado);
          setCidade(data.localidade);
          setLogradouro(data.logradouro);
          setBairro(data.bairro);
          const et = listEstados.find((es) => es.label.includes(data.uf))
          setEstado(et);
        })
        .catch((e) => {
          setErrorText("Erro ao buscar com o CEP informado!");
          setHasError(true);
        })
      }
    }

  }

  function handleImageComp(event){
    const file = event.target.files[0];
    if (file) {
      setComprovante(file);
      const reader = new FileReader();
      reader.onload = () => {
        setDocComp(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  async function handleGetUFs() {
    await api.post("/api/concash/1.0/cliente-area/estados", {})
    .then(({data}) => {
      const ests = data.map((d) => {return {id: d.estId, label: `${d.estUf} - ${d.estNome}`}})
      setListEstados(ests)
    })
    .catch((e) => {
      setErrorText("Erro ao carregar lista de estados!");
      setHasError(true);
    })
  }


  async function handleGetData() {
    setCliente(clienteData);
    if(clienteData) {
      setNome(clienteData?.nomeRazao);
      setCpfCnpj(clienteData?.cpfCnpj);
      setCep(clienteData?.cep);
      setLogradouro(clienteData?.logradouro);
      setNumero(clienteData?.numero);
      setComplemento(clienteData?.complemento);
      setBairro(clienteData?.bairro);
      setCidade(clienteData?.cidade);
      setIdCidade(clienteData?.idCidade);
      setIdEstado(clienteData?.idEstado);
      
    }
  }

  useEffect(() => {
    if(clienteData.estado && listEstados) {
      const est = listEstados.find((es) => es.label.toLowerCase().includes(clienteData.estado.toLowerCase()));
      setEstado(est);
    }
  }, [clienteData, listEstados])

  async function handleSendComprovante() {
    let formData = new FormData();
    formData.append("idAtendimento", clienteData.idAtendimento);
    formData.append("comprovanteResidencia", comprovante);
    await api.post("/api/concash/1.0/consorcio-grupo-cota/cadastrar-comprovante-residencia", formData).then(() => {
      atualiza();
      setShowScreen("sucesso");
    })
    .catch((e) => {
      setErrorText("Erro ao cadastrar comprovante de residencia!");
      setHasError(true);
    })
  }

  async function handleSaveAddress() {

    if(!cep || cep === "") {
      setErrorText("Forneça um CEP!");
      setHasError(true);
      return;
    }
    if(cep && cep.replace("-", "").length < 8) {
      setErrorText("CEP inválido!");
      setHasError(true);
      return;
    }
    if(!logradouro) {
      setErrorText("Campo logradouro inválido!");
      setHasError(true);
      return;
    }
    if(!bairro) {
      setErrorText("Campo bairro inválido!");
      setHasError(true);
      return;
    }

    if(!idCidade || !cidade) {
      setErrorText("Campo cidade inválido!");
      setHasError(true);
      return;
    }


    if(!idEstado || !estado) {
      setErrorText("Campo estado inválido!");
      setHasError(true);
      return;
    }
    let formData = new FormData();
    formData.append("idAtendimento", clienteData?.idAtendimento);
    formData.append("bairro", bairro);
    formData.append("cep", cep.replace("-", ""));
    formData.append("cidade", cidade);
    formData.append("complemento", complemento);
    estado && formData.append("estado", estado.id);
    idEstado && formData.append("idEstado", idEstado);
    idCidade && formData.append("idCidade", idCidade);
    formData.append("logradouro", logradouro);
    formData.append("numero", numero);
    await api.post("/api/concash/1.0/consorcio-grupo-cota/cadastrar-endereco", formData).then(
      ({data}) => {
        atualiza();
        state.tipoPessoa === "PJ" ? setShowScreen("sucesso") : setShowScreen("TELA-COMPROVANTE");

    })
    .catch((e) => {
      setErrorText("Erro ao cadastrar o endereço!");
      setHasError(true);
    })
  }

  useEffect(() => {
    listEstados.length === 0 && handleGetUFs();
  }, [])


  return(<FormBase estilo={estilo} open={open}>
    {!cliente && handleGetData()}
    <ModalMain width={280} height={240} onClose={() => setHasError(false)} open={hasError} estilo={estilo} texto={errorText} title="Atenção!" />
    {showScreen === "FORM" && (
    <>
    <h1 className={MainStyle.titlePage}>Endereço</h1>
          <h3 className={MainStyle.text15} style={{ textAlign: "center" }}>Preencha os dados do endereço que<br />devem constar no contrato.</h3>
          <InfoDefault message={<span><b>Importante:</b> o endereço deve ser obrigatoriamente o do titular da conta bancária que receberá o pagamento.</span>} />
        <div className={S.buttonExit} onClick={closeScreen}>
          <MdOutlineClose size={28} />
        </div>
      <V3TextCustom 
      // label="Titular da Conta" 
      size='small' sx={{ width: "100%", }} value={nome} disabled />
      <V3TextCustom label="CPF / CNPJ" size='small'  sx={{ width: "100%" }} value={cpfCnpj}   InputLabelProps={{ shrink: !!cpfCnpj }}   disabled />
      <div style={{ width: 60}} />
      <V3TextCustom label="CEP" size='small' sx={{ width: "100%" }}  onChange={handleCEP} value={cep}   InputLabelProps={{ shrink: !!cep }}  required />
      <V3TextCustom label="Logradouro" size='small'  sx={{ width: "100%" }} value={logradouro} onChange={(e) => setLogradouro(e.target.value)}   InputLabelProps={{ shrink: !!logradouro }}  required   />
      <V3TextCustom label="Número"   sx={{ width: "100%" }} value={numero} onChange={(e) => setNumero(e.target.value)} size='small'   InputLabelProps={{ shrink: !!numero }}  required />
      <V3TextCustom label="Complemento" value={complemento} onChange={(e) => setComplemento(e.target.value)}  sx={{ width: "100%" }}   InputLabelProps={{ shrink: !!complemento }}  size='small' />
      <V3TextCustom label="Bairro" size='small'  value={bairro} onChange={(e) => setBairro(e.target.value)}  sx={{ width: "100%" }}   InputLabelProps={{ shrink: !!bairro }} required />
      <V3TextCustom label="Cidade" size='small'  value={cidade} onChange={(e) => setCidade(e.target.value)}   sx={{ width: "100%" }}   InputLabelProps={{ shrink: !!cidade }}  required />
      <V3Autocomplete
        size="small"
        id="estado"
        options={listEstados}
        value={estado}
        onChange={(e, v) => { v ? setEstado(v) : setEstado("")}}
        sx={{ width: "100%" }}
        renderInput={(params) => (
          <V3TextCustom {...params} label="Estado" required />
          )}
          />      
      <ButtonMain estilo={estilo} texto={"Salvar"} tamanho={"100%"} onClick={handleSaveAddress} />
    </>)}
    {showScreen === "TELA-COMPROVANTE" && (
      <>
      <h1 className={MainStyle.title24}>Comprovante de<br />{state.tipoPessoa === "PJ" ? "endereço" : "residência"}</h1>
          <h3 className={MainStyle.text15} style={{ textAlign: "center" }}>Envie uma foto de um comprovante de {state.tipoPessoa === "PJ" ? "endereço" : "residência"}.</h3>
        <div className={S.buttonExit} onClick={closeScreen}>
          <MdOutlineClose size={28} />
        </div>
        <div style={{ display: "flex", width: "100%", flexDirection:"column", justifyContent: "center", gap: 30}}>
          {docComp ? ( 
          <>
          {docComp?.type == "application/pdf" ? (
            <GrDocumentPdf size={60} style={{ alignSelf: "center" }} />
          ) : (
            <img src={docComp} alt="Verso" width={"auto"} height={138} style={{ alignSelf: "center"}} />
          )}
          </>
          ) : (
            <img src={Comprov} alt="Verso" width={"auto"} height={138} style={{ alignSelf: "center"}} />
          )}
          <div  style={{ display: "flex", flexDirection:"column", justifyContent: "center"}}>
            <span className={MainStyle.title18}>Importante</span>
            <span className={MainStyle.text15}>● O vencimento deve ser recente (até 3 meses)</span>
            <span className={MainStyle.text15}>● O documento precisa estar em nome do proprietário da cota.</span>
            <span className={MainStyle.text15}>● As informações devem estar legíveis.</span>
            <span className={MainStyle.text15}>● A foto deve ser colorida e do documento original.</span>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection:"column", width:"100%"}}>
          { docComp ? (
            <>
              <ButtonMain estilo={estilo} texto={"Prosseguir com esta"} onClick={handleSendComprovante} />
              <div style={{ height: 10}} />
              <ButtonOutlined estilo={estilo} texto={"Escolher outro Arquivo"} onClick={() => setDocComp("")} />
              <div style={{ height: 10}} />
            </>
          ) : (
            <>
              <AutoServicoFileInput label="Escolher Arquivo PDF" width="100%" estilo={estilo} onChange={(e) => {
                setDocComp(e.target.files[0]);
                setComprovante(e.target.files[0]);
              }} accept={".pdf"} /> 
              <AutoServicoFileInput label="Escolher Imagem" width="100%" estilo={estilo} onChange={handleImageComp} accept={"image/png, image/jpeg, image/jpg, image.jfif, image/bmp, image/webp"}  /> 
            </>
          )}
          <ButtonOutlined  estilo={estilo} tamanho={"100%"} texto={"Voltar"} onClick={() => setShowScreen("FORM")} />
        </div>
      </>
    )}

{showScreen === "sucesso" && (
  <div style={{ display: "flex", width: "100%", alignItems:"center", flexDirection: "column"}}>
    <div style={{ display:"flex", alignItems: "center", justifyContent: "center", width: 50, height: 50, backgroundColor: estilo?.secondary, borderRadius: "50%" }}>
      <FaCheck size={34} color="#FFF" />
    </div>
  <div style={{ marginTop: 15}}>
    <h1 className={MainStyle.title24}>Comprovante de residência recebido</h1>
  </div>

  <div style={{ display:"flex", width: "100%", marginTop: 15}}>
    <ButtonMain estilo={estilo} tamanho="100%" texto={"Continuar"}  onClick={() => {
        setShowScreen("FORM");
        closeScreen();
      }} />
  </div>
    <div className={S.buttonExit} onClick={() => {
        setShowScreen("FORM");
        closeScreen();
      }}>
      <MdOutlineClose size={28} />
    </div>
  </div>
)}


      <div style={{ width: 60}} />

  </FormBase>)
}

export const FormDadosBancarios = ({estilo, open, closeScreen, atualiza, clienteData }) => {
  const service = window.location.pathname.replace("/autoservico/", "").split("/");
  const [ hasError, setHasError ] = useState(false);
  const [ errorText, setErrorText ] = useState("");
  const [ cliente, setCliente] = useState("");
  const [ listBancos, setListBancos ] = useState([]);
  const [ listTipoConta, setListTipoConta ] = useState(listTiposConta);
  const [ listTipoPix, setistTipoPix ] = useState(listTiposPix);
  const [ banco, setBanco ] = useState("");
  const [ agencia, setAgencia ] = useState("");
  const [ conta, setConta ] = useState("");
  const [ digitoConta, setDigitoConta] = useState("");
  const [ tipoConta, setTipoConta ] = useState("");
  const [pix, setPix] = useState("");
  const [ tipoPix, setTipoPix ] = useState("");
  const [showScreen, setShowScreen] = useState("FORM");

  async function handleGetBancos() {
    await api.get("/api/concash/1.0/banco/listar-bancos").then(({data}) => {
      const banks = data.map((d) => {return {id: d.id, label: d.nome}});
      setListBancos(banks)
    }).catch((e) => {
      setErrorText("Não foi possível carregar a lista de bancos!");
      setHasError(true);
    })
  }
  async function handleSendDataBank() {

    if(!banco) {
      setErrorText("Banco é obrigatório");
      return setHasError(true);
    }
    if(!tipoConta){
      setErrorText("É obrigatório informar o tipo de conta");
      return setHasError(true);
    }
    if(!agencia) {
      setErrorText("Agência é obrigatório");
      return setHasError(true);
    }
    if(!conta) {
      setErrorText("Conta é obrigatório");
      return setHasError(true);
    }
    if(!digitoConta) {
      setErrorText("Dígito da Conta é obrigatório");
      return setHasError(true);
    }

    if(tipoPix && !pix) {
      setErrorText("Após selecionar tipo de PIX por favor informar a chave.");
      return setHasError(true);
    }

    let formData = new FormData();
    agencia && formData.append("agencia", agencia);
    banco && formData.append("banco", banco?.id);
    formData.append("conta", conta);
    formData.append("digitoConta", digitoConta);
    formData.append("idAtendimento", cliente.idAtendimento);
    formData.append("nomeBanco", banco?.label);
    pix && formData.append("pix", pix);
    tipoConta && formData.append("tipoConta", tipoConta?.id);
    tipoPix && formData.append("tipoPix", tipoPix?.id);
    await api.post("/api/concash/1.0/consorcio-grupo-cota/cadastrar-dados-bancarios", formData).then(({data}) => {
      atualiza();
      setShowScreen("sucesso");
    }).catch((e) => {
      setErrorText("Erro ao cadastrar conta bancária!");
      setHasError(true);
    })
  }

  async function handleGetData() {
    setCliente(clienteData);
    if(clienteData.tipoChavePIXID) {
      const cp = listTipoPix.find((t) => t.id == clienteData.tipoChavePIXID);
      setTipoPix(cp)
    }
    if(clienteData.tipoContaId) {
      const cp = listTipoConta.find((t) => t.id == clienteData.tipoContaId);
      setTipoConta(cp)
    }
    if(clienteData.idBanco) {
      const cp = listBancos.find((t) => t.id == clienteData.idBanco);
      setBanco(cp)
    }
  }

  useEffect(() => {
    handleGetBancos();
  }, [])



  return(
  
  <FormBase estilo={estilo} open={open}>
    <>
    {!cliente && handleGetData()}
    <ModalMain width={280} height={240} onClose={() => setHasError(false)} open={hasError} estilo={estilo} texto={errorText} title="Atenção!" />
    {showScreen === "FORM" && (
      <>
    <h1 className={MainStyle.titlePage}>Dados Bancários</h1>
          <h3 className={MainStyle.text15} style={{ textAlign: "center" }}>Preencha os dados bancários em que<br />deve ser feito o pagamento.</h3>
          <InfoDefault message={<span><b>Importante:</b> os dados devem ser obrigatoriamente da mesma titularidade do proprietário desta cota.</span>} />
        <div className={S.buttonExit} onClick={closeScreen}>
          <MdOutlineClose size={28} />
        </div>
      <V3TextCustom  
      // label="Titular da Conta" 
      variant="outlined" size='small' sx={{ width: "100%", }}  value={cliente?.nome} disabled  />
      <V3TextCustom label="CPF / CNPJ" size='small'  sx={{ width: "100%" }} value={cliente?.cpfCnpj}  disabled />
      <div style={{ width: 60}} />
      <V3Autocomplete
      size="small"
      id="banco"
      options={listBancos}
      value={banco}
      onChange={(e, v) => {
        if (!v) {
          setBanco("");
        } else {
          setBanco(v)
        }
      }}
      sx={{ width: "100%" }}
      renderInput={(params) => (
        <V3TextCustom {...params} label="Banco" required />
      )}
      />
      <V3Autocomplete
      size="small"
      id="tipoConta"
      options={listTipoConta}
      value={tipoConta}
      onChange={(e, v) => {
        if (!v) {
          setTipoConta("");
        } else {
          setTipoConta(v)
        }
      }}
      sx={{ width: "100%" }}
      renderInput={(params) => (
        <V3TextCustom {...params} label="Tipo Conta" required />
      )}
      />

      <V3TextCustom label="Agencia" size='small'  sx={{ width: "100%" }} required 
      onChange={(e) => {
        const text = e.target.value.replace(/[^0-9]/g, '');
        if(text.length <= 4){
          setAgencia(text);
        }}} value={agencia}
      />
      <V3TextCustom label="Conta" size='small'  sx={{ width: "100%" }} required onChange={(e) => setConta(e.target.value)} value={conta}  />
      <V3TextCustom label="Digito Conta" size='small'  sx={{ width: "100%" }} required onChange={(e) =>  e.target.value.length <= 1 && setDigitoConta(e.target.value)} value={digitoConta}  />
      <V3Autocomplete
      size="small"
      id="tipoPix"
      options={listTipoPix}
      value={tipoPix}
      onChange={(e, v) => {
        if (!v) {
          setTipoPix("");
        } else {
          setTipoPix(v)
        }
      }}
      sx={{ width: "100%" }}
      renderInput={(params) => (
        <V3TextCustom {...params} label="Tipo Chave PIX" required />
      )}
      />     
      <V3TextCustom label="PIX" size='small'  sx={{ width: "100%" }}  onChange={(e) =>  setPix(e.target.value)} value={pix} required  />
      <ButtonMain estilo={estilo} texto={"Salvar"} tamanho={"100%"} onClick={handleSendDataBank} />
      </>
    )}

      
{showScreen === "sucesso" && (
  <div style={{ display: "flex", width: "100%", alignItems:"center", flexDirection: "column"}}>
    <div style={{ display:"flex", alignItems: "center", justifyContent: "center", width: 50, height: 50, backgroundColor: estilo?.secondary, borderRadius: "50%" }}>
      <FaCheck size={34} color="#FFF" />
    </div>
  <div style={{ marginTop: 15}}>
    <h1 className={MainStyle.title24}>Dados recebidos</h1>
  </div>
  <div style={{ display:"flex", width: "100%", marginTop: 15}}>
    <ButtonMain estilo={estilo} tamanho="100%" texto={"Continuar"}  onClick={() => {
        atualiza();
        setShowScreen("FORM");
        closeScreen();
      }} />
  </div>
    <div className={S.buttonExit} onClick={() => {
        setShowScreen("FORM");
        closeScreen();
      }}>
      <MdOutlineClose size={28} />
    </div>
  </div>
)}
    </>
      <div style={{ width: 60}} />

  </FormBase>)
}

export const FormDadosPessoais = ({estilo, open, closeScreen, sendDoc, clienteData, atualiza}) => {
  const {getTokenAutoServico} = useAutoservico();
  const token = getTokenAutoServico();
  const state = useSelector(state => state.autoservico);
  const [ hasError, setHasError ] = useState(false);
  const [ errorText, setErrorText ] = useState("");
  const config = { headers: { Authorization: "Bearer " + token }};
  const isMobile = window.innerWidth <= 768;
  const service = window.location.pathname.replace("/autoservico/", "").split("/");
  const [showScreen, setShowScreen] = useState(sendDoc ? "ESCOLHER-TIPO-DOCUMENTO" : "FORM");
  const [errors, setErrors] = useState([]);
  const [ loading, setLoading ] = useState(false);

  const [listNacionalidades, setListNacionalidades] = useState([]);
  const [listGeneros, setListGeneros] = useState([]);
  const [listProfissoes, setListProfissoes] = useState([]);
  const [listEstadosCivis, setListEstadosCivis] = useState([]);

  const [ cliente, setCliente] = useState("");
  const [ nome, setNome ] = useState("");
  const [ cpfCnpj, setCpfCnpj] = useState("");
  const [ dataNasc, setDataNasc] = useState("");
  const [telefone, setTelefone] = useState("");
  const [telefoneAlt, setTelefoneAlt] = useState("");
  const [ email, setEmail] = useState("");
  const [rg, setRg] = useState("");
  const [orgaoEmissor, setOrgaoEmissor] = useState("");
  const [nacionalidade, setNacionalidade] = useState("");
  const [genero, setGenero] =useState("");
  const [profissao, setProfissao] = useState("");
  const [estadoCivil, setEstadoCivil] = useState("");

  const [ cnhFrente, setCnhFrente] = useState("");
  const [ cnhFrenteBin, setCnhFrenteBin] = useState("");
  const [ cnhVerso, setCnhVerso] = useState("");
  const [ cnhVersoBin, setCnhVersoBin] = useState("");
  const [ cpfFrente, setCpfFrente] = useState("");
  const [ cpfFrenteBin, setCpfFrenteBin] = useState("");
  const [ rgFrente, setRgFrente] = useState("");
  const [ rgFrenteBin, setRgFrenteBin] = useState("");
  const [ rgVerso, setRgVerso] = useState("");
  const [ rgVersoBin, setRgVersoBin] = useState("");

  const [ paisCompanhia, setPaisCompanhia] = useState("");
  const [ tipoCompanhia, setTipoCompanhia] = useState("");
  const [ cnae, setCnae ] = useState("");
  const [ nomeFantasia, setNomeFantasia] = useState("");
  const [ situacaoEconomica, setSituacaoEconomica] = useState("");
  const [ listaPaises, setListaPaises] = useState([]);
  const [ listaTiposCompanhia, setListaTiposCompanhia] = useState([]);

  function handleCnhFrenteChange(event){
    const file = event.target.files[0];
    if (file) {
      setCnhFrenteBin(file);
      const reader = new FileReader();
      reader.onload = () => {
        setCnhFrente(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  function handleCnhVersoChange(event){
    const file = event.target.files[0];
    if (file) {
      setCnhVersoBin(file);
      const reader = new FileReader();
      reader.onload = () => {
        setCnhVerso(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  function handleRgFrenteChange(event){
    const file = event.target.files[0];
    if (file) {
      setRgFrenteBin(file);
      const reader = new FileReader();
      reader.onload = () => {
        setRgFrente(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  function handleRgVersoChange(event){
    const file = event.target.files[0];
    if (file) {
      setRgVersoBin(file);
      const reader = new FileReader();
      reader.onload = () => {
        setRgVerso(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  function handleCpfChange(event){
    const file = event.target.files[0];
    if (file) {
      setCpfFrenteBin(file);
      const reader = new FileReader();
      reader.onload = () => {
        setCpfFrente(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if(clienteData.nacionalidade && listNacionalidades) {
      const cp = listNacionalidades.find((t) => t.id == clienteData.nacionalidade);
      setNacionalidade(cp)
    }
  }, [clienteData, listNacionalidades])

  useEffect(() => {
    if(clienteData.paisCompanhia && listaPaises) {
      const cp = listaPaises.find((t) => t.id == clienteData.paisCompanhia);
      setPaisCompanhia(cp)
    }
  }, [clienteData, listaPaises])
  useEffect(() => {
    if(clienteData.tipoCompanhia && listaTiposCompanhia) {
      const cp = listaTiposCompanhia.find((t) => t.id == clienteData.tipoCompanhia);
      setTipoCompanhia(cp)
    }
  }, [clienteData, listaTiposCompanhia])

  useEffect(() => {
    if(clienteData.situacaoEconomica && listaSituacaoEconomica) {
      const cp = listaSituacaoEconomica.find((t) => t.value == clienteData?.situacaoEconomica);
      setSituacaoEconomica(cp)
    }
  }, [clienteData])
  
  useEffect(() => {
    if(clienteData.genero && listGeneros) {
      const cp = listGeneros.find((t) => t.id == clienteData.genero);
      setGenero(cp)
    }
  }, [clienteData, listGeneros])

  useEffect(() => {
    if(clienteData.profissao && listProfissoes) {
      const cp = listProfissoes.find((t) => t.id == clienteData.profissao);
      setProfissao(cp)
    }
  }, [clienteData, listProfissoes])

  useEffect(() => {
    if(clienteData && listEstadosCivis) {
      const cp = listEstadosCivis.find((t) => t.id == clienteData.estadoCivil);
      setEstadoCivil(cp)
    }
  }, [clienteData, listEstadosCivis])
  useEffect(() => {
    if(clienteData && listEstadosCivis) {
      const cp = listNacionalidades.find((t) => t.id == clienteData.nacionalidade);
      setNacionalidade(cp)
    }
  }, [clienteData, listNacionalidades])

  async function handleGetData() {
    setCliente(clienteData);
    if(clienteData) {
      setNome(clienteData?.nome);
      setNomeFantasia(clienteData?.nomeFantasia);
      setCnae(clienteData?.cnae);
      setCpfCnpj(formatCpfCnpj(String(clienteData?.cpfCnpj)));
       setDataNasc(clienteData?.dataNascimento ? clienteData?.dataNascimento : "");
      setTelefone(v3MaskPhone(clienteData?.telefone));
      setEmail(clienteData?.email);
      setRg(clienteData?.rg);
      setOrgaoEmissor(clienteData?.orgaoEmissor);
      const nac = listNacionalidades.filter((n) =>  clienteData.nacionalidade == n.id);
      nac.length > 0 ? setNacionalidade(nac[0]) : setNacionalidade("");
    }
  }

  async function handleGetNacionalidades() {
    const { data } = await api.get("/api/concash/1.0/cliente-area/nacionalidades").catch((e) => {
      setErrorText("Erro ao carregar lista de nacionalides!");
      setHasError(true);
    })
      const dados = await data?.map(({nacId, nacNome}) => { 
        return {id: nacId, label: nacNome}  
      });
      setListNacionalidades(dados)
  }
  async function handleGetPaises() {
    const { data } = await api.get("/api/concash/1.0/geo/paises").catch((e) => {
      setErrorText("Erro ao carregar lista de países!");
      setHasError(true);
    });
      const dados = await data?.map(({paiId, paiNomePt}) => { 
        return {id: paiId, label: paiNomePt}  
      });
      setListaPaises(dados);
  }
  async function handleGetTiposCompanhia() {
    const { data } = await api.get("/api/concash/1.0/cliente-area/tipo-companhia").catch((e) => {
      setErrorText("Erro ao carregar lista de tipos de companhia!");
      setHasError(true);
    });
      const dados = await data?.map(({ctcoId, ctcoDescricao}) => { 
        return {id: ctcoId, label: ctcoDescricao}  
      });
      setListaTiposCompanhia(dados);
  }
  async function handleGetProfissoes() {
    const { data } = await api.get("/api/concash/1.0/cliente-area/profissoes").catch((e) => {
      setErrorText("Erro ao carregar lista de profissões!");
      setHasError(true);
    });
  
      const dados = await data?.map(({prfId, prfNome}) => { return {id: prfId, label: prfNome}});
      setListProfissoes(dados)
  }
  async function handleGetGeneros() {
    const { data } = await api.post("/api/concash/1.0/cliente-area/generos",{}).catch((e) => {
      setErrorText("Erro ao carregar generos!");
      setHasError(true);
    });
      const dados = await data?.map(({sexId, sexSexo}) => { return {id: sexId, label: sexSexo}});
      setListGeneros(dados)
  }
  async function handleGetEstadosCivis() {
    const { data } = await api.post("/api/concash/1.0/cliente-area/estados-civis",{}).catch((e) => {
      setErrorText("Erro ao carregar lista de estados civis!");
      setHasError(true);
    });
      const dados = await data?.map(({etcId, etcEstadoCivil}) => { return {id: etcId, label: etcEstadoCivil}});
      setListEstadosCivis(dados)
  }

  const dados = useSelector(data => data.autoservico);

  async function handleSendForm() {
    if(!cpfCnpj) {
      setErrorText("CPF/CNPJ é obrigatório");
      setHasError(true);
      return;
    }
    if(!nome) {
      setErrorText("Nome/Razão Social é obrigatório");
      setHasError(true);
      return;
    }
    
    if(!dataNasc || validateDate(dataNasc) === false) {
      
      setErrorText(`Data de ${state?.tipoPessoa === "PJ" ? "Fundação" : "Nascimento"} inválida.`);
      setHasError(true);
      return;
    }
    
    if(!telefone || validateMobilePhone(telefone) === false) {
      setErrorText("Telefone celular inválido");
      setHasError(true);
      return;
    }

    if(!email) {
      setErrorText("E-mail é obrigatório");
      setHasError(true);
      return;
    }

    if(!validateEmail(email)) {
      setErrorText("E-mail inválido!");
      setHasError(true);
      return;
    }

    if(state?.tipoPessoa === "PJ") {
      if(!situacaoEconomica) {
        setErrorText("Situação Econômica é obrigatório");
        setHasError(true);
        return;
      }
      if(!cnae) {
        setErrorText("CNAE é obrigatório");
        setHasError(true);
        return;
      }
      if(!tipoCompanhia) {
        setErrorText("Tipo de Companhia é obrigatório");
        setHasError(true);
        return;
      }

      if(!paisCompanhia) {
        setErrorText("Pais da Companhia é obrigatório");
        setHasError(true);
        return;
      }
      if(!nomeFantasia) {
        setErrorText("Nome Fantasia é obrigatório");
        setHasError(true);
        return;
      }
    } else {
      if(!rg) {
        setErrorText("RG é obrigatório");
        setHasError(true);
        return;
      }
      if(!orgaoEmissor) {
        setErrorText("Orgão Emissor é obrigatório");
        setHasError(true);
        return;
      }
      if(!nacionalidade) {
        setErrorText("Nacionalidade é obrigatório");
        setHasError(true);
        return;
      }
      if(!genero) {
        setErrorText("Genero é obrigatório");
        setHasError(true);
        return;
      }
      if(!profissao) {
        setErrorText("Profissão é obrigatório");
        setHasError(true);
        return;
      }
      if(!estadoCivil) {
        setErrorText("Estado Civil é obrigatório");
        setHasError(true);
        return;
      }
    }

    const dc = cpfCnpj.replace(/[^0-9]/g, '');
    let hash = `?grupo=${clienteData?.grupo}&cota=${clienteData?.cota}&`;
    if(cpfCnpj){ hash = `${hash}cpfCnpj=${dc}&`};
    if(nome){ hash = `${hash}nomeRazao=${nome}&`};
    if(email){ hash = `${hash}email=${email}&`};
    if(dataNasc){ hash = `${hash}dataNascimento=${dataNasc}&`};
    if(telefone){ hash = `${hash}telefone=${telefone}&`};

    if(dados?.tipoPessoa === "PJ") {
      
      if(tipoCompanhia){ hash = `${hash}tipoCompanhia=${tipoCompanhia.id}&`};
      if(paisCompanhia){ hash = `${hash}paisCompanhia=${paisCompanhia.id}&`};
      if(situacaoEconomica){ hash = `${hash}situacaoEconomica=${situacaoEconomica?.value}&`};
      if(cnae){ hash = `${hash}cnae=${cnae}&`};
      if(nomeFantasia){ hash = `${hash}nomeFantasia=${nomeFantasia}&`};
    } else {
      if(estadoCivil){ hash = `${hash}estadoCivil=${estadoCivil.id}&`};
      if(genero){ hash = `${hash}genero=${genero.id}&`};
      if(rg){ hash = `${hash}rg=${rg}&`};
      if(orgaoEmissor){ hash = `${hash}orgaoEmissor=${orgaoEmissor}&`};
      if(nacionalidade){ hash = `${hash}nacionalidade=${nacionalidade.id}&`};
      if(profissao){ hash = `${hash}profissao=${profissao.id}&`};

    }

    await api.post(`/api/concash/1.0/consorcio-grupo-cota/cadastrar-dados-pessoais${hash}`, {})
    .then(({data}) => {
      atualiza();
      setShowScreen(state.tipoPessoa === "PJ" ? "ESCOLHER-CONTRATO" : "ESCOLHER-TIPO-DOCUMENTO")
    })
    .catch((e) => {
      setErrorText("Erro ao atualizar os dados pessoais!");
      setHasError(true);
    })
  }

  function handleShowDocScreen() {
    setShowScreen("ESCOLHER-TIPO-DOCUMENTO");
  }

  function handleExit() {
    atualiza();
    closeScreen();
  }

  async function handleSendCNHFrente() {
    let formData = new FormData();
    formData.append("documentoPessoal", cnhFrenteBin);
    formData.append("idAtendimento", cliente.idAtendimento);
    await api.post("/api/concash/1.0/consorcio-grupo-cota/cadastrar-documentos-pessoais", formData)
    .then((e) => {
      atualiza();
      setShowScreen(state.tipoPessoa === "PJ" ? "sucesso" : "CNHVerso");
    })
    .catch((e) => {
      setErrorText("Erro ao cadastrar os documentos!");
      setHasError(true);
    })
  }

  async function handleSendCNHFundo() {
    let formData = new FormData();
    formData.append("documentoPessoalVerso", cnhVersoBin);
    formData.append("idAtendimento", cliente.idAtendimento);
    await api.post("/api/concash/1.0/consorcio-grupo-cota/cadastrar-documentos-pessoais", formData)
    .then((e) => {
      atualiza();
      setShowScreen("sucesso")
    })
    .catch((e) => {
      setErrorText("Erro ao cadastrar os documentos!");
      setHasError(true);
    })
  }

    async function handleSendRGFrente() {
    let formData = new FormData();
    formData.append("documentoPessoal", rgFrenteBin);
    formData.append("idAtendimento", cliente.idAtendimento);
    await api.post("/api/concash/1.0/consorcio-grupo-cota/cadastrar-documentos-pessoais", formData)
    .then((e) => {
      atualiza();
      setShowScreen("RgComVerso")
    })
    .catch((e) => {
      setErrorText("Erro ao cadastrar os documentos!");
      setHasError(true);
    })
  }
    async function handleSendRGFundo() {
    let formData = new FormData();
    formData.append("documentoPessoalVerso", rgVersoBin);
    formData.append("idAtendimento", cliente.idAtendimento);
    await api.post("/api/concash/1.0/consorcio-grupo-cota/cadastrar-documentos-pessoais", formData)
    .then((e) => {
      atualiza();
      setShowScreen("sucesso")
    })
    .catch((e) => {
      setErrorText("Erro ao cadastrar os documentos!");
      setHasError(true);
    })
  }
    async function handleSendRGSemFundo() {
    let formData = new FormData();
    formData.append("documentoPessoalVerso", rgVersoBin);
    formData.append("idAtendimento", cliente.idAtendimento);
    await api.post("/api/concash/1.0/consorcio-grupo-cota/cadastrar-documentos-pessoais", formData)
    .then((e) => {
      atualiza();
      setShowScreen("CpfDoc")
    })
    .catch((e) => {
      setErrorText("Erro ao cadastrar os documentos!");
      setHasError(true);
    })
  }

  useEffect(() => {
    if(listNacionalidades.length <= 1) {
      handleGetNacionalidades()
    }
    if(listaTiposCompanhia.length < 1) {
      handleGetTiposCompanhia()
    }
    if(listaPaises.length < 1) {
      handleGetPaises();
    }

    if(listProfissoes.length <= 1) {
      handleGetProfissoes();
    }
    if(listGeneros.length <= 1) {
      handleGetGeneros();
    }
    if(listEstadosCivis.length <= 1) {
      handleGetEstadosCivis();
    }

  },[listNacionalidades, listProfissoes, listGeneros, listEstadosCivis])

  useEffect(() => {
    
    state.tipoPessoa === "PF" && setShowScreen(sendDoc ? "ESCOLHER-TIPO-DOCUMENTO" : "FORM");
    state.tipoPessoa === "PJ" && setShowScreen(sendDoc ? "ESCOLHER-CONTRATO" : "FORM");
  }, [sendDoc])


  return(<FormBase estilo={estilo} open={open}>
    {!cliente && handleGetData()}
    <ModalMain width={280} height={240} onClose={() => setHasError(false)} open={hasError} estilo={estilo} texto={errorText} title="Atenção!" />
    {showScreen === "FORM" && (
      <>
      <h1 className={MainStyle.titlePage}>Dados {dados?.tipoPessoa === "PJ" ? "da Empresa" : "Pessoais"}</h1>
          <h3 className={MainStyle.text15} style={{ textAlign: "center" }}>Confirme ou atualize os dados do titular<br />desta cota.</h3>
        <div className={S.buttonExit} onClick={handleExit}>
          <MdOutlineClose size={28} />
        </div>
      <V3TextCustom label="Titular da Conta" size='small' sx={{ width: "100%", }} value={nome} onChange={(e) => setNome(e.target.value)} InputLabelProps={{ shrink: !!nome }} required />
      <V3TextCustom label="CPF / CNPJ" size='small' value={cpfCnpj}  InputLabelProps={{ shrink: !!cpfCnpj }}
      onChange={(e) => {
        if(e.target.value) {
          setCpfCnpj(v3MaskCPF(String(e.target.value)))
        }
      }} sx={{ width: "100%" }} required  />
      <V3TextCustom label={dados?.tipoPessoa === "PJ" ? "Data de Fundação" : "Data de Nascimento"} size='small'  sx={{ width: "100%" }} value={dataNasc} onChange={(e) => {
        if(e.target.value.length <=10) {
          setDataNasc(maskDataDeNascimento(e.target.value))
        }
        }}  InputLabelProps={{ shrink: !!dataNasc }}  required />
      <V3TextCustom label="Telefone Celular" size='small'  InputLabelProps={{ shrink: !!telefone }}  sx={{ width: "100%" }} value={telefone} onChange={(e) => setTelefone(v3MaskPhone(e.target.value))} required />
      <V3TextCustom label="E-mail" size='small'  sx={{ width: "100%" }}  InputLabelProps={{ shrink: !!email }} value={email} onChange={(e) => setEmail(e.target.value)} required />
      {dados?.tipoPessoa === "PJ" ? (
      <>
             <V3TextCustom label="Nome Fantasia" size='small'  sx={{ width: "100%" }}  InputLabelProps={{ shrink: !!nomeFantasia }} value={nomeFantasia} onChange={(e) => setNomeFantasia(e.target.value)} required />
             <V3TextCustom label="CNAE" size='small'  sx={{ width: "100%" }} value={cnae}  InputLabelProps={{ shrink: !!cnae }} onChange={(e) => setCnae(formatCnae(e.target.value))} required />
             <V3Autocomplete
                size="small"
                id="paisCompanhia"
                options={listaPaises}
                value={paisCompanhia}
                onChange={(e, v) => { v ? setPaisCompanhia(v) : setPaisCompanhia("")}}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <V3TextCustom {...params} label="País da Companhia" />
                )}
                required
                />
             <V3Autocomplete
                size="small"
                id="situacaoEconomica"
                options={listaSituacaoEconomica}
                value={situacaoEconomica}
                onChange={(e, v) => { v ? setSituacaoEconomica(v) : setSituacaoEconomica("")}}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <V3TextCustom {...params} label="Situação Econômica" />
                )}
                required
                />
             <V3Autocomplete
                size="small"
                id="tipoCompanhia"
                options={listaTiposCompanhia}
                value={tipoCompanhia}
                onChange={(e, v) => { v ? setTipoCompanhia(v) : setTipoCompanhia("")}}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <V3TextCustom {...params} label="Tipo Companhia" />
                )}
                required
                />
      </>
      ) : (
      <>
       <V3TextCustom label="RG / RNE" size='small'  sx={{ width: "100%" }} value={rg} onChange={(e) => setRg(e.target.value)} required />
      <V3TextCustom label="Órgão Emissor" size='small'  sx={{ width: "100%" }} value={orgaoEmissor} onChange={(e) => setOrgaoEmissor(e.target.value)} required />
      <V3Autocomplete
      size="small"
      id="nacionalidade"
      options={listNacionalidades}
      value={nacionalidade}
      onChange={(e, v) => { v ? setNacionalidade(v) : setNacionalidade("")}}
      sx={{ width: "100%" }}
      renderInput={(params) => (
        <V3TextCustom {...params} label="Nacionalidade" required />
      )}
      required
      />
      <V3Autocomplete
        size="small"
        id="genero"
        options={listGeneros}
        value={genero}
        onChange={(e, v) => {v ? setGenero(v) : setGenero("")}}
        sx={{ width: "100%" }}
        renderInput={(params) => (
          <V3TextCustom {...params} label="Genero" required />
        )}
      />
      <V3Autocomplete
        size="small"
        id="profissao"
        options={listProfissoes}
        value={profissao}
        onChange={(e, v) => {v ? setProfissao(v) : setProfissao("")}}
        sx={{ width: "100%" }}
        renderInput={(params) => (
          <V3TextCustom {...params} label="Profissão" required />
        )}
      />
      <V3Autocomplete
      size="small"
      id="estadoCivil"
      options={listEstadosCivis}
      value={estadoCivil}
      onChange={(e, v) => {
        if (!v) {
          setEstadoCivil("");
        } else {
          setEstadoCivil(v)
        }
      }}
      sx={{ width: "100%" }}
      renderInput={(params) => (
        <V3TextCustom {...params} label="Estado Civil" required />
      )}
      />
      </>
      )}
      
     
      <div style={{ display: 'flex', flex: 1, textAlign: "left"}}>

      <p className={S.textItalic} style={{ textAlign:"left"}}>* Preenchimento obrigatório</p>
      </div>
      
      <ButtonMain estilo={estilo} texto={"Continuar"} onClick={handleSendForm} tamanho={"100%"} disabled={!clienteData.grupo || !clienteData.cota} />
      </>
    )}
    {showScreen === "ESCOLHER-TIPO-DOCUMENTO" && (
      <>
      <h1 className={MainStyle.titlePage}>Envie um documento</h1>
          <h3 className={MainStyle.text15} style={{ textAlign: "center" }}>Para continuar, é necessário envia a<br />imagem de um documento de<br/ ><b>{nome}</b><br/><br/> Escolha o tipo:</h3>
        <div className={S.buttonExit} onClick={closeScreen}>
          <MdOutlineClose size={28} />
        </div>
        <div style={{ display: "flex", flexDirection:"column", width:"100%", gap:0}}>
        <ButtonAction estilo={estilo} tamanho={"100%"} texto={"CARTEIRA DE MOTORISTA"} onClick={() => setShowScreen("CNHFrente")} />
        <ButtonAction estilo={estilo} tamanho={"100%"} texto={"RG COM CPF"} onClick={() => setShowScreen("RgComFrente")} />
        <ButtonAction estilo={estilo} tamanho={"100%"} texto={"RG/RNE E CPF"} onClick={() => setShowScreen("RgSemFrente")} />
        </div>
      <span onClick={() => setShowScreen("FORM")} style={{ fontFamily: "sans-serif", cursor: "pointer"}}><IoIosArrowRoundBack size={28} />Voltar ao formulário</span>
      </>
    )}
    {showScreen === "ESCOLHER-CONTRATO" && (
      <>
      <h1 className={MainStyle.titlePage}>Envie um documento</h1>
          <h3 className={MainStyle.text15} style={{ textAlign: "center" }}>Para continuar, é necessário envia a<br />imagem de um documento de<br/ ><b>{nome}</b><br/><br/> Escolha o tipo:</h3>
        <div className={S.buttonExit} onClick={closeScreen}>
          <MdOutlineClose size={28} />
        </div>
        <div style={{ display: "flex", flexDirection:"column", width:"100%", gap:0}}>
        <ButtonAction estilo={estilo} tamanho={"100%"} texto={"CONTRATO SOCIAL"} onClick={() => setShowScreen("CONTRATO-SOCIAL")} />
        </div>
      <span onClick={() => setShowScreen("FORM")} style={{ fontFamily: "sans-serif", cursor: "pointer"}}><IoIosArrowRoundBack size={28} />Voltar ao formulário</span>
      </>
    )}
    {showScreen === "CNHFrente" && (
      <>
      <h1 className={MainStyle.titlePage}>Frente da CNH</h1>
          <h3 className={MainStyle.text15} style={{ textAlign: "center" }}>Envie uma foto da frente da sua CNH</h3>
        <div className={S.buttonExit} onClick={closeScreen}>
          <MdOutlineClose size={28} />
        </div>
        <div style={{ display: "flex", width: "100%", flexDirection:"column", justifyContent: "center", gap: 30}}>
          {cnhFrente ? ( 
          <>
          {cnhFrente?.type == "application/pdf" ? (
            <GrDocumentPdf size={60} style={{ alignSelf: "center" }} />
          ) : (
            <img src={cnhFrente} alt="Frente" width={"auto"} height={138} style={{ alignSelf: "center"}} />
          )}
          </>
          ) : (
            <img src={FrenteDoc} alt="Frente" width={"auto"} height={138} style={{ alignSelf: "center"}} />
          )}
          <div  style={{ display: "flex", flexDirection:"column", justifyContent: "center"}}>
            <span className={MainStyle.title18}>Importante</span>
            <span className={MainStyle.text15}>● Garanta o enquadramento de todo o documento, sem cortes.</span>
            <span className={MainStyle.text15}>● As informações devem estar legíveis.</span>
            <span className={MainStyle.text15}>● O documento deve ser original.</span>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection:"column", width:"100%"}}>
          { cnhFrente ? (
            <>
              <ButtonMain estilo={estilo} texto={"Prosseguir com esta"} onClick={handleSendCNHFrente} />
              <div style={{ height: 10}} />
              <ButtonOutlined estilo={estilo} texto={"Escolher outro Arquivo"} onClick={() => {
                setCnhFrenteBin("")
                setCnhFrente("")
              }} />
              <div style={{ height: 10}} />
            </>
          ) : (
            <>
              <AutoServicoFileInput label="Escolher Arquivo PDF" width="100%" estilo={estilo} onChange={(e) => {
                setCnhFrenteBin(e.target.files[0])
                setCnhFrente(e.target.files[0])
                }} accept={".pdf"} /> 
              <AutoServicoFileInput label="Escolher Imagem" width="100%" estilo={estilo} onChange={handleCnhFrenteChange} accept={"image/png, image/jpeg, image/jpg, image.jfif, image/bmp, image/webp"} /> 
            </>
          )}
          <ButtonOutlined  estilo={estilo} tamanho={"100%"} texto={"Voltar"} onClick={() => setShowScreen("ESCOLHER-TIPO-DOCUMENTO")} />
        </div>
      </>
    )}
    {showScreen === "CONTRATO-SOCIAL" && (
      <>
      <h1 className={MainStyle.titlePage}>Contrato Social</h1>
          <h3 className={MainStyle.text15} style={{ textAlign: "center" }}>Envie o Contrato Social da Empresa</h3>
        <div className={S.buttonExit} onClick={closeScreen}>
          <MdOutlineClose size={28} />
        </div>
        <div style={{ display: "flex", width: "100%", flexDirection:"column", justifyContent: "center", gap: 30}}>
          {cnhFrente ? ( 
          <>
          {cnhFrente?.type == "application/pdf" ? (
            <GrDocumentPdf size={60} style={{ alignSelf: "center" }} />
          ) : (
            <img src={cnhFrente} alt="Frente" width={"auto"} height={138} style={{ alignSelf: "center"}} />
          )}
          </>
          ) : (
            <div style={{ display: "flex", flexDirection:"column", alignItems: "center", justifyContent: "space-around", width: 110, height: 145, border: "2px solid #ABABAB", borderRadius: 12, alignSelf: "center" }}>
              <FaFileContract size={40} color={"#ABABAB"} />
            <span style={{ fontFamily: "sans-serif", fontSize: 12, fontWeight: "bold", textAlign: "center", color: "#ABABAB"}}>CONTRATO SOCIAL</span>
            </div>
          )}
          <div  style={{ display: "flex", flexDirection:"column", justifyContent: "center"}}>
            <span className={MainStyle.title18}>Importante</span>
            <span className={MainStyle.text15}>● Garanta o enquadramento de todo o documento, sem cortes.</span>
            <span className={MainStyle.text15}>● As informações devem estar legíveis.</span>
            <span className={MainStyle.text15}>● O documento deve ser original.</span>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection:"column", width:"100%"}}>
          { cnhFrente ? (
            <>
              <ButtonMain estilo={estilo} texto={"Prosseguir com esta"} onClick={handleSendCNHFrente} />
              <div style={{ height: 10}} />
              <ButtonOutlined estilo={estilo} texto={"Escolher outro Arquivo"} onClick={() => {
                setCnhFrenteBin("")
                setCnhFrente("")
              }} />
              <div style={{ height: 10}} />
            </>
          ) : (
            <>
              <AutoServicoFileInput label="Escolher Arquivo PDF" width="100%" estilo={estilo} onChange={(e) => {
                 setCnhFrenteBin(e.target.files[0])
                 setCnhFrente(e.target.files[0])
                 }} accept={".pdf"} /> 
              <AutoServicoFileInput label="Escolher Imagem" width="100%" estilo={estilo} onChange={handleCnhFrenteChange} accept={"image/png, image/jpeg, image/jpg, image.jfif, image/bmp, image/webp"} /> 
            </>
          )}
          <ButtonOutlined  estilo={estilo} tamanho={"100%"} texto={"Voltar"} onClick={() => setShowScreen("ESCOLHER-CONTRATO")} />
        </div>
      </>
    )}
    {showScreen === "CNHVerso" && (
      <>
      <h1 className={MainStyle.titlePage}>Verso da CNH</h1>
          <h3 className={MainStyle.text15} style={{ textAlign: "center" }}>Envie uma foto do verso da sua CNH</h3>
        <div className={S.buttonExit} onClick={closeScreen}>
          <MdOutlineClose size={28} />
        </div>
        <div style={{ display: "flex", width: "100%", flexDirection:"column", justifyContent: "center", gap: 30}}>
          {cnhVerso ? ( 
          <>
          {cnhVerso?.type == "application/pdf" ? (
            <GrDocumentPdf size={60} style={{ alignSelf: "center" }} />
          ) : (
            <img src={cnhVerso} alt="Verso" width={"auto"} height={138} style={{ alignSelf: "center"}} />
          )}
          </>
          ) : (
            <img src={VersoDoc} alt="Verso" width={"auto"} height={138} style={{ alignSelf: "center"}} />
          )}
          <div  style={{ display: "flex", flexDirection:"column", justifyContent: "center"}}>
            <span className={MainStyle.title18}>Importante</span>
            <span className={MainStyle.text15}>● Garanta o enquadramento de todo o documento, sem cortes.</span>
            <span className={MainStyle.text15}>● As informações devem estar legíveis.</span>
            <span className={MainStyle.text15}>● O documento deve ser original.</span>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection:"column", width:"100%"}}>
          { cnhVerso ? (
            <>
              <ButtonMain estilo={estilo} texto={"Prosseguir com esta"} onClick={handleSendCNHFundo} />
              <div style={{ height: 10}} />
              <ButtonOutlined estilo={estilo} texto={"Escolher outro Arquivo"} onClick={() =>{
                setCnhVersoBin("")
                setCnhVerso("")
              }} />
              <div style={{ height: 10}} />
            </>
          ) : (
            <>
              <AutoServicoFileInput label="Escolher Arquivo PDF" width="100%" estilo={estilo} onChange={(e) => {
                setCnhVersoBin(e.target.files[0])
                setCnhVerso(e.target.files[0])
              }} accept={".pdf"} /> 
              <AutoServicoFileInput label="Escolher Imagem" width="100%" estilo={estilo} onChange={handleCnhVersoChange} accept={"image/png, image/jpeg, image/jpg, image.jfif, image/bmp, image/webp"}  /> 
            </>
          )}
          <ButtonOutlined  estilo={estilo} tamanho={"100%"} texto={"Voltar"} onClick={() => setShowScreen("CNHFrente")} />
        </div>
      </>
    )}

    {showScreen === "RgComFrente" && (
      <>
      <h1 className={MainStyle.titlePage}>Frente do RG</h1>
          <h3 className={MainStyle.text15} style={{ textAlign: "center" }}>Envie uma foto da frente do seu RG</h3>
        <div className={S.buttonExit} onClick={closeScreen}>
          <MdOutlineClose size={28} />
        </div>
        <div style={{ display: "flex", width: "100%", flexDirection:"column", justifyContent: "center", gap: 30}}>
          {rgFrente ? ( 
          <>
          {rgFrente?.type == "application/pdf" ? (
            <GrDocumentPdf size={60} style={{ alignSelf: "center" }} />
          ) : (
            <img src={rgFrente} alt="Frente" width={"auto"} height={138} style={{ alignSelf: "center"}} />
          )}
          </>
          ) : (
            <img src={FrenteDoc} alt="Frente" width={"auto"} height={138} style={{ alignSelf: "center"}} />
          )}
          <div  style={{ display: "flex", flexDirection:"column", justifyContent: "center"}}>
            <span className={MainStyle.title18}>Importante</span>
            <span className={MainStyle.text15}>● Garanta o enquadramento de todo o documento, sem cortes.</span>
            <span className={MainStyle.text15}>● As informações devem estar legíveis.</span>
            <span className={MainStyle.text15}>● O documento deve ser original.</span>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection:"column", width:"100%"}}>
          { rgFrente ? (
            <>
              <ButtonMain estilo={estilo} texto={"Prosseguir com esta"} onClick={handleSendRGFrente} />
              <div style={{ height: 10}} />
              <ButtonOutlined estilo={estilo} texto={"Escolher outro Arquivo"} onClick={() => {
                setRgFrenteBin("")
                setRgFrente("")
              }} />
              <div style={{ height: 10}} />
            </>
          ) : (
            <>
              <AutoServicoFileInput label="Escolher Arquivo PDF" width="100%" estilo={estilo} 
              onChange={(e) => {
                setRgFrenteBin(e.target.files[0])
                setRgFrente(e.target.files[0])
              }}
                  accept={".pdf"}
                  /> 
              <AutoServicoFileInput label="Escolher Imagem" width="100%" estilo={estilo} onChange={handleRgFrenteChange} accept={"image/png, image/jpeg, image/jpg, image.jfif, image/bmp, image/webp"}  /> 
            </>
          )}
          <ButtonOutlined  estilo={estilo} tamanho={"100%"} texto={"Voltar"} onClick={() => setShowScreen("ESCOLHER-TIPO-DOCUMENTO")} />
        </div>
      </>
    )}

{showScreen === "RgComVerso" && (
      <>
      <h1 className={MainStyle.titlePage}>Verso do RG</h1>
          <h3 className={MainStyle.text15} style={{ textAlign: "center" }}>Envie uma foto do verso do seu RG</h3>
        <div className={S.buttonExit} onClick={closeScreen}>
          <MdOutlineClose size={28} />
        </div>
        <div style={{ display: "flex", width: "100%", flexDirection:"column", justifyContent: "center", gap: 30}}>
          {rgVerso ? ( 
          <>
          {rgVerso?.type == "application/pdf" ? (
            <GrDocumentPdf size={60} style={{ alignSelf: "center" }} />
          ) : (
            <img src={rgVerso} alt="Verso" width={"auto"} height={138} style={{ alignSelf: "center"}} />
          )}
          </>
          ) : (
            <img src={VersoDoc} alt="Verso" width={"auto"} height={138} style={{ alignSelf: "center"}} />
          )}
          <div  style={{ display: "flex", flexDirection:"column", justifyContent: "center"}}>
            <span className={MainStyle.title18}>Importante</span>
            <span className={MainStyle.text15}>● Garanta o enquadramento de todo o documento, sem cortes.</span>
            <span className={MainStyle.text15}>● As informações devem estar legíveis.</span>
            <span className={MainStyle.text15}>● O documento deve ser original.</span>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection:"column", width:"100%"}}>
          { rgVerso ? (
            <>
              <ButtonMain estilo={estilo} texto={"Prosseguir com esta"} onClick={handleSendRGFundo} />
              <div style={{ height: 10}} />
              <ButtonOutlined estilo={estilo} texto={"Escolher outro Arquivo"} onClick={() => {
                setRgVersoBin("")
                setRgVerso("")
              }} />
              <div style={{ height: 10}} />
            </>
          ) : (
            <>
              <AutoServicoFileInput label="Escolher Arquivo PDF" width="100%" estilo={estilo} onChange={(e) => {
                setRgVersoBin(e.target.files[0])
                setRgVerso(e.target.files[0])
              }} accept={".pdf"} /> 
              <AutoServicoFileInput label="Escolher Imagem" width="100%" estilo={estilo} onChange={handleRgVersoChange} accept={"image/png, image/jpeg, image/jpg, image.jfif, image/bmp, image/webp"} /> 
            </>
          )}
          <ButtonOutlined  estilo={estilo} tamanho={"100%"} texto={"Voltar"} onClick={() => setShowScreen("RgComFrente")} />
        </div>
      </>
    )}


{showScreen === "RgSemFrente" && (
      <>
      <h1 className={MainStyle.titlePage}>Frente do RG</h1>
          <h3 className={MainStyle.text15} style={{ textAlign: "center" }}>Envie uma foto da frente do seu RG</h3>
        <div className={S.buttonExit} onClick={closeScreen}>
          <MdOutlineClose size={28} />
        </div>
        <div style={{ display: "flex", width: "100%", flexDirection:"column", justifyContent: "center", gap: 30}}>
          {rgFrente ? ( 
          <>
          {rgFrente?.type == "application/pdf" ? (
            <GrDocumentPdf size={60} style={{ alignSelf: "center" }} />
          ) : (
            <img src={rgFrente} alt="Frente" width={"auto"} height={138} style={{ alignSelf: "center"}} />
          )}
          </>
          ) : (
            <img src={FrenteDoc} alt="Frente" width={"auto"} height={138} style={{ alignSelf: "center"}} />
          )}
          <div  style={{ display: "flex", flexDirection:"column", justifyContent: "center"}}>
            <span className={MainStyle.title18}>Importante</span>
            <span className={MainStyle.text15}>● Garanta o enquadramento de todo o documento, sem cortes.</span>
            <span className={MainStyle.text15}>● As informações devem estar legíveis.</span>
            <span className={MainStyle.text15}>● O documento deve ser original.</span>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection:"column", width:"100%"}}>
          { rgFrente ? (
            <>
              <ButtonMain estilo={estilo} texto={"Prosseguir com esta"} onClick={handleSendRGFrente} />
              <div style={{ height: 10}} />
              <ButtonOutlined estilo={estilo} texto={"Escolher outro Arquivo"} onClick={() => setRgFrente("")} />
              <div style={{ height: 10}} />
            </>
          ) : (
            <>
              <AutoServicoFileInput label="Escolher Arquivo PDF" width="100%" estilo={estilo} onChange={(e) => {
                setRgFrenteBin(e.target.files[0])
                setRgFrente(e.target.files[0])
              }} accept={".pdf"} /> 
              <AutoServicoFileInput label="Escolher Imagem" width="100%" estilo={estilo} onChange={handleRgFrenteChange} accept={"image/png, image/jpeg, image/jpg, image.jfif, image/bmp, image/webp"}  /> 
            </>
          )}
          <ButtonOutlined  estilo={estilo} tamanho={"100%"} texto={"Voltar"} onClick={() => setShowScreen("ESCOLHER-TIPO-DOCUMENTO")} />
        </div>
      </>
    )}

    
{showScreen === "RgSemVerso" && (
      <>
      <h1 className={MainStyle.titlePage}>Verso do RG</h1>
          <h3 className={MainStyle.text15} style={{ textAlign: "center" }}>Envie uma foto do verso do seu RG</h3>
        <div className={S.buttonExit} onClick={closeScreen}>
          <MdOutlineClose size={28} />
        </div>
        <div style={{ display: "flex", width: "100%", flexDirection:"column", justifyContent: "center", gap: 30}}>
          {rgVerso ? ( 
          <>
          {rgVerso?.type == "application/pdf" ? (
            <GrDocumentPdf size={60} style={{ alignSelf: "center" }} />
          ) : (
            <img src={rgVerso} alt="Verso" width={"auto"} height={138} style={{ alignSelf: "center"}} />
          )}
          </>
          ) : (
            <img src={VersoDoc} alt="Verso" width={"auto"} height={138} style={{ alignSelf: "center"}} />
          )}
          <div  style={{ display: "flex", flexDirection:"column", justifyContent: "center"}}>
            <span className={MainStyle.title18}>Importante</span>
            <span className={MainStyle.text15}>● Garanta o enquadramento de todo o documento, sem cortes.</span>
            <span className={MainStyle.text15}>● As informações devem estar legíveis.</span>
            <span className={MainStyle.text15}>● O documento deve ser original.</span>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection:"column", width:"100%"}}>
          { rgVerso ? (
            <>
              <ButtonMain estilo={estilo} texto={"Prosseguir com esta"} onClick={handleSendRGSemFundo} />
              <div style={{ height: 10}} />
              <ButtonOutlined estilo={estilo} texto={"Escolher outro Arquivo"} onClick={() => {
                setRgVersoBin("")
                setRgVerso("")
              }} />
              <div style={{ height: 10}} />
            </>
          ) : (
            <>
              <AutoServicoFileInput label="Escolher Arquivo PDF" width="100%" estilo={estilo} onChange={(e) => {
                setRgVersoBin(e.target.files[0])
                setRgVerso(e.target.files[0])
              }} accept={".pdf"} /> 
              <AutoServicoFileInput label="Escolher Imagem" width="100%" estilo={estilo} onChange={handleRgVersoChange} accept={"image/png, image/jpeg, image/jpg, image.jfif, image/bmp, image/webp"} /> 
            </>
          )}
          <ButtonOutlined  estilo={estilo} tamanho={"100%"} texto={"Voltar"} onClick={() => setShowScreen("RgSemFrente")} />
        </div>
      </>
    )}

{showScreen === "CpfDoc" && (
      <>
      <h1 className={MainStyle.titlePage}>Envio do CPF</h1>
          <h3 className={MainStyle.text15} style={{ textAlign: "center" }}>Envie uma foto ou arquivo do seu CPF</h3>
        <div className={S.buttonExit} onClick={closeScreen}>
          <MdOutlineClose size={28} />
        </div>
        <div style={{ display: "flex", width: "100%", flexDirection:"column", justifyContent: "center", gap: 30}}>
          {cpfFrente ? ( 
          <>
          {cpfFrente?.type == "application/pdf" ? (
            <GrDocumentPdf size={60} style={{ alignSelf: "center" }} />
          ) : (
            <img src={cpfFrente} alt="Frente" width={"auto"} height={138} style={{ alignSelf: "center"}} />
          )}
          </>
          ) : (
            <img src={FrenteDoc} alt="Frente" width={"auto"} height={138} style={{ alignSelf: "center"}} />
          )}
          <div  style={{ display: "flex", flexDirection:"column", justifyContent: "center"}}>
            <span className={MainStyle.title18}>Importante</span>
            <span className={MainStyle.text15}>● Garanta o enquadramento de todo o documento, sem cortes.</span>
            <span className={MainStyle.text15}>● As informações devem estar legíveis.</span>
            <span className={MainStyle.text15}>● O documento deve ser original.</span>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection:"column", width:"100%"}}>
          { cpfFrente ? (
            <>
              <ButtonMain estilo={estilo} texto={"Prosseguir com esta"} onClick={() => setShowScreen("sucesso")} />
              <div style={{ height: 10}} />
              <ButtonOutlined estilo={estilo} texto={"Escolher outro Arquivo"} onClick={() => {
                setCpfFrenteBin("")
                setCpfFrente("")
              }} />
              <div style={{ height: 10}} />
            </>
          ) : (
            <>
              <AutoServicoFileInput label="Escolher Arquivo PDF" width="100%" estilo={estilo} onChange={(e) => {
                setCpfFrenteBin(e.target.files[0])
                setCpfFrente(e.target.files[0])
              }} accept={".pdf"} /> 
              <AutoServicoFileInput label="Escolher Imagem" width="100%" estilo={estilo} onChange={handleCpfChange} accept={"image/png, image/jpeg, image/jpg, image.jfif, image/bmp, image/webp"}  /> 
            </>
          )}
          <ButtonOutlined  estilo={estilo} tamanho={"100%"} texto={"Voltar"} onClick={() => setShowScreen("RgSemVerso")} />
        </div>
      </>
    )}

    {showScreen === "sucesso" && (
      <div style={{ display: "flex", width: "100%", alignItems:"center", flexDirection: "column"}}>
        <div style={{ display:"flex", alignItems: "center", justifyContent: "center", width: 50, height: 50, backgroundColor: estilo?.secondary, borderRadius: "50%" }}>
          <FaCheck size={34} color="#FFF" />
        </div>
      <div style={{ marginTop: 15}}>
        <h1 className={MainStyle.title24}>Documento recebido</h1>
      </div>

      <div style={{ display:"flex", width: "100%", marginTop: 15}}>
        <ButtonMain estilo={estilo} tamanho="100%" texto={"Continuar"}  onClick={() => {
            setShowScreen("FORM");
            closeScreen();
          }} />
      </div>
        <div className={S.buttonExit} onClick={() => {
            setShowScreen("FORM");
            closeScreen();
          }}>
          <MdOutlineClose size={28} />
        </div>
      </div>
    )}
      <div style={{ width: 80}} />
  </FormBase>)
}

export const FinalizarEscolherConta = ({estilo, open, closeScreen, novaConta, contas, cota}) => {
  const service = window.location.pathname.replace("/autoservico/", "").split("/");
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [hasSuccess, setHasSuccess] = useState(false);
  const [textSuccess, setTextSuccess] = useState("");
  const [ loading, setLoading ]= useState(false);
  const [ aceitarOferta, setAceitarOferta ] = useState(false);

  const [ contaSelecionada, setContaSelecionada ] = useState("");
  const state = useSelector(state => state.autoservico);

  async function handleAceitaCota() {
    setLoading(true);
    await api.post(`/api/concash/1.0/consorcio-grupo-cota/aceitar-proposta-autosservico?cpfCnpj=${state?.cpfCnpj.replace(/\D/g, "")}&idAtendimento=${state?.idAtendimento}&idBanco=${contaSelecionada?.idClienteBanco}`,null, {
      headers: {
        tokenConsort: state?.tokenConsort
      }
    })
    .then(({data}) => {
      setLoading(false);
      setHasSuccess(true);
      setAceitarOferta(false);
      return setTextSuccess("Você aceitou a oferta, aguarde que nossa equipe entrará em contato.");
    })
    .catch((e) => {
      console.log(e);
      setLoading(false);
      setAceitarOferta(false);
      setErrorText("Erro ao aceitar cota, se o problema persistir ligue para nossa equipe.");
      return setHasError(true);
    })
  }

  return(
    <>
    <FormBase estilo={estilo} open={open} style={{ height:"100%", paddingBottom: 30}}>
    <LoadingCustom open={loading} />
        <V3Modal
          open={hasError}
          actionClose={() => {
            window.location.href = `/autoservico/${service[0]}/minhas-cotas`;
          }}
          title={errorText}
          customButtonColor={estilo?.primary}
          type="warning"
        />
        <V3Modal
          open={hasSuccess}
          actionClose={() => {
            window.location.href = `/autoservico/${service[0]}/minhas-cotas`;
          }}
          title={textSuccess}
          customButtonColor={estilo?.primary}
          type="success"
        />

        <V3ModalConfirmAutoServico
          open={aceitarOferta}
          title="Atenção!"
          text="Tem certeza que quer aceitar a oferta?"
          actionClose={() => setAceitarOferta(false)}
          actionConfirm={handleAceitaCota}
          textCancel="Voltar"
          textConfirm="Aceitar oferta"
          customButtonColor={estilo?.primary}
        />
    <>
      <h1 className={MainStyle.title24}>Escolha uma conta</h1>
          <h3 className={MainStyle.text15} style={{ textAlign: "center" }}>Selecione a conta na qual você quer receber o pagamento.</h3>
        <div className={S.buttonExit} onClick={closeScreen}>
          <MdOutlineClose size={28} />
        </div>

        <div style={{ display: "flex", flex:1,  flexDirection:"column", width:"100%", gap:10, height: "100%", paddingBottom: 30}}>
          {contas && contas.map((ct) => (
          <div className={contaSelecionada.idClienteBanco === ct.idClienteBanco ? S.boxContaSelected : S.boxConta} style={{ borderColor: contaSelecionada.idClienteBanco === ct.idClienteBanco && estilo?.primary  }}>
            <div className={S.rowTitle}>
            <Radio 
             sx={{
              position: "relative",
              zIndex: 400,
              color: estilo.secondary,
               '&.Mui-checked': {
               color:  estilo.primary,
              },
            }}

              onClick={() => setContaSelecionada(ct)}
              checked={contaSelecionada.idClienteBanco === ct.idClienteBanco}
            />
            <span className={S.text15}><b>{ct?.nome}</b></span>
            </div>
            <div className={S.rowTitleLeft}>
              <div style={{ display: "flex"}}>
                <div style={{ minWidth: 84, fontSize: 15}}><span>Banco</span></div>
                <span>{ct.banco} - {ct.nomeBanco}</span>
              </div>
            </div>
            <div className={S.rowTitleLeft}>
              <div style={{ display: "flex"}}>
                <div style={{ minWidth: 84, fontSize: 15}}><span>Agência</span></div>
                <span>{ct.agencia}</span>
              </div>
            </div>
            <div className={S.rowTitleLeft}>
              <div style={{ display: "flex"}}>
                <div style={{ minWidth: 84, fontSize: 15}}><span>Conta</span></div>
                <span>{ct.conta}-{ct.digitoConta}</span>
              </div>
            </div>
          </div>
          ))}
  <div style={{ display: "flex", height: 60, alignItems: "center", justifyContent: "flex-end"}}>
            <SimpleButtonIcon estilo={estilo} texto="Adicionar nova conta" icon={<FaPlus size="15" color={estilo.primary} />} onClick={novaConta} />
  </div>

          <ButtonMain  estilo={estilo} tamanho={"100%"} texto={"Continuar"} onClick={() =>
            {
              if(contaSelecionada) {
                return setAceitarOferta(true);
              } else {
                return;
              }
            } 
            } disabled={!contaSelecionada} />
          <div style={{ display: "flex", flex: 1, height: 200, width: "100%", marginBottom: 130, color: "#FFF", boxSizing: "border-box"}}>Concash</div>
        </div>
      </>

    </FormBase>
      </>
  );
}

export const FormDadosRepresentante = ({estilo, open, closeScreen, sendDoc, clienteData, atualiza}) => {
  const {getTokenAutoServico} = useAutoservico();
  const token = getTokenAutoServico();
  const [ hasError, setHasError ] = useState(false);
  const [ errorText, setErrorText ] = useState("");
  const config = { headers: { Authorization: "Bearer " + token }};
  const isMobile = window.innerWidth <= 768;
  const service = window.location.pathname.replace("/autoservico/", "").split("/");
  const [showScreen, setShowScreen] = useState(sendDoc ? "ESCOLHER-TIPO-DOCUMENTO" : "FORM");
  const [errors, setErrors] = useState([]);
  const [ loading, setLoading ] = useState(false);

  const [ cliente, setCliente] = useState("");
  const [ nome, setNome ] = useState("");
  const [ cpfCnpj, setCpfCnpj] = useState("");
  const [ dataNasc, setDataNasc] = useState("");

  const [ email, setEmail] = useState("");


  async function handleGetData() {
    setCliente(clienteData);
    if(clienteData) {
      setNome(clienteData?.nomeRepresentante);
      setCpfCnpj(formatCpfCnpj(String(clienteData?.cpfRepresentante?.padStart(11, "0"))));
      setDataNasc(clienteData?.dataNascimentoRepresentante ? clienteData?.dataNascimentoRepresentante : "");
      setEmail(clienteData?.emailRepresentante);
    }
  }


  const dados = useSelector(data => data.autoservico);

  async function handleSendForm() {
    if(!cpfCnpj) {
      setErrorText("CPF/CNPJ do representante é obrigatório");
      return setHasError(true);
    }

    if(!nome) {
      setErrorText("Nome do representante é obrigatório");
      return setHasError(true);
    }
    if(!email) {
      setErrorText("E-mail do representante é obrigatório");
      return setHasError(true);
    }
    if(!validateEmail(email)) {
      setErrorText("E-mail inválido");
      return setHasError(true);
    }
    if(!dataNasc) {
      setErrorText("Data de nascimento do representante é obrigatório");
      return setHasError(true);
    }
    if(!validateDate(dataNasc)) {
      setErrorText("Data de nascimento inválida");
      return setHasError(true);
    }


    const dc = cpfCnpj.replace(/[^0-9]/g, '');
    
    let hash = `?cnpjCliente=${clienteData?.cnpjCliente}&idCliente=${clienteData.idCliente}&idRepresentante=${clienteData.idRepresentante}&`;
    if(cpfCnpj){ hash = `${hash}cpfRepresentante=${dc}&`};
    if(nome){ hash = `${hash}nomeRepresentante=${nome}&`};
    if(email){ hash = `${hash}emailRepresentante=${email}&`};
    if(dataNasc){ hash = `${hash}dataNascimentoRepresentante=${dataNasc}&`};

    await api.post(`/api/concash/1.0/consorcio-grupo-cota/cadastrar-dados-representante${hash}`, {})
    .then(({data}) => {
      atualiza();
      setShowScreen("SUCESSO")
    })
    .catch((e) => {
      setErrorText("Erro ao atualizar os dados pessoais!");
      setHasError(true);
    })
  }


  function handleExit() {
    closeScreen();
  }

  return(<FormBase estilo={estilo} open={open}>
    {!cliente && handleGetData()}
    <ModalMain width={280} height={240} onClose={() => setHasError(false)} open={hasError} estilo={estilo} texto={errorText} title="Atenção!" />
    {showScreen === "FORM" && (
      <>
      <h1 className={MainStyle.titlePage}>Representante</h1>
          <h3 className={MainStyle.text15} style={{ textAlign: "center" }}>Confirme ou atualize os dados do representante da empresa.</h3>
        <div className={S.buttonExit} onClick={handleExit}>
          <MdOutlineClose size={28} />
        </div>
      <V3TextCustom label="Nome do Representante" size='small' sx={{ width: "100%", }} value={nome} onChange={(e) => setNome(e.target.value)} />
      <V3TextCustom label="CPF / CNPJ" size='small' value={cpfCnpj} onChange={(e) => {
        if(e.target.value) {
          setCpfCnpj(v3MaskCPF(String(e.target.value)))
        }
      }} sx={{ width: "100%" }}   />
      <V3TextCustom label={"Data de Nascimento"} size='small'  sx={{ width: "100%" }} value={dataNasc} onChange={(e) => {
        if(e.target.value.length <=10) {
          setDataNasc(maskDataDeNascimento(e.target.value))
        }
        }}  required />
      <V3TextCustom label="E-mail do Representante" size='small'  sx={{ width: "100%" }} value={email} onChange={(e) => setEmail(e.target.value)} required />
      
    
      <div style={{ display: 'flex', flex: 1, textAlign: "left"}}>

      <p className={S.textItalic} style={{ textAlign:"left"}}>* Preenchimento obrigatório</p>
      </div>
      
      <ButtonMain estilo={estilo} texto={"Continuar"} onClick={handleSendForm} tamanho={"100%"} />
      </>
    )}

    {showScreen === "SUCESSO" && (
      <div style={{ display: "flex", width: "100%", alignItems:"center", flexDirection: "column"}}>
        <div style={{ display:"flex", alignItems: "center", justifyContent: "center", width: 50, height: 50, backgroundColor: estilo?.secondary, borderRadius: "50%" }}>
          <FaCheck size={34} color="#FFF" />
        </div>
      <div style={{ marginTop: 15}}>
        <h1 className={MainStyle.title24}>Dados Representante enviado com sucesso</h1>
      </div>

      <div style={{ display:"flex", width: "100%", marginTop: 15}}>
        <ButtonMain estilo={estilo} tamanho="100%" texto={"Continuar"}  onClick={() => {
            setShowScreen("FORM");
            closeScreen();
          }} />
      </div>
        <div className={S.buttonExit} onClick={() => {
            setShowScreen("FORM");
            closeScreen();
          }}>
          <MdOutlineClose size={28} />
        </div>
      </div>
    )}


      <div style={{ width: 80}} />
  </FormBase>)
}