import React from "react";
import styled from "styled-components";
import family from "../../assets/images/novo/family.png";

export const Container = styled.div`
  display: flex;
  flex: 1;
  margin: 0;
  height: 100vh;
  background-color: red;
`;

export const BoxLogin = styled.div`
  display: flex;
  min-width: 530px;
  max-width: 530px;
  background-color: #fff;
  padding: 70px 100px;
  flex-direction: column;
`;
export const BoxLoginMobile = styled.div`
  display: flex;
  width: 100vw;
  background-color: #fff;
  padding: 40px 30px;
  flex-direction: column;
`;

export const BoxSideRight = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: #e4f8f8;
`;

export const Row = styled.div`
  display: flex;
`;
export const RowCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
