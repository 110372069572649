import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";
import { AlteraNome } from '../../actions';




export class TelaComercialInicial extends Component {


  constructor(props){
        super(props);
        this.state = {
          show: false
        };
  }

  async componentDidMount() {

  }

  handleNegociacoes    = async e => {
      e.preventDefault();
      this.props.history.push("/comercial/negociacoes");
  };

  handleProspect = async e => {
      e.preventDefault();
      this.props.history.push("/comercial/prospect");
  };


  render() {


    return (

      <div>
        <div className="page-header">
          <h3 className="page-title"> Comercial </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">

                    <div className="col-md-6" style={{"text-align":"center"}}>
                       <button type="submit" className="btn btn-primary mr-2" style={{"width":"50%","height":"100px"}} onClick={e =>this.handleNegociacoes(e)}>NEGOCIAÇÕES</button>
                    </div>

                    <div className="col-md-6" style={{"text-align":"center"}}>
                       <button type="submit" className="btn btn-primary mr-2" style={{"width":"50%","height":"100px"}} onClick={e =>this.handleProspect(e)}>PROSPECTS</button>
                    </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(TelaComercialInicial);
