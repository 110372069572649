// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-mobile {
    display: flex;
    flex: 1 1;

}

.box-header-mobile{
    display: flex;
    flex: 1 1;
    height: 70px;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background-color: #FFF;
    border-radius: 8px;
    margin: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAO;;AAEX;;AAEA;IACI,aAAa;IACb,SAAO;IACP,YAAY;IACZ,mBAAmB;IACnB,8BAA8B;IAC9B,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[".button-mobile {\n    display: flex;\n    flex: 1;\n\n}\n\n.box-header-mobile{\n    display: flex;\n    flex: 1;\n    height: 70px;\n    align-items: center;\n    justify-content: space-between;\n    padding: 15px;\n    background-color: #FFF;\n    border-radius: 8px;\n    margin: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
