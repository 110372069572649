import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import api from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import { Link, withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import StepCota from "../StepCota";

export class CabecalhoCota extends Component {
  constructor(props) {
    super(props);

    const { cliente, cota, grupo, administradora } = this.props;

    this.state = {
      cliente: cliente,
      cota: cota,
      grupo: grupo,
      administradora: administradora,
      error: "",
    };
  }

  async componentDidMount() {}

  render() {
    return (
      <div className="row" style={{ backgroundColor: "rgb(222, 226, 230)" }}>
        <ReactTooltip />
        <div className="col-md-12" style={{ marginTop: "30px" }}>
          <div className="row">
            <div className="col-md-3">
              <Form.Group className="row">
                <label
                  htmlFor="examplePropostal"
                  className="col-sm-12 col-form-label"
                  style={{ textAlign: "center" }}
                >
                  CPF/CNPJ Cliente ​&nbsp;&nbsp;
                  <Link
                    to={{
                      pathname: "/backoffice/detalhamento-cliente",
                      state: {
                        cliente: this.state.cliente,
                      },
                    }}
                    data-tip="Área do Cliente"
                  >
                    <span
                      className="mdi mdi-account-box"
                      style={{
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    ></span>
                  </Link>
                </label>
              </Form.Group>
            </div>
            <div className="col-md-3">
              <Form.Group className="row">
                <label
                  htmlFor="examplePropostal"
                  className="col-sm-12 col-form-label"
                  style={{ textAlign: "center" }}
                >
                  Nome Consorciado
                </label>
              </Form.Group>
            </div>
            <div className="col-md-2">
              <Form.Group className="row">
                <label
                  htmlFor="examplePropostal"
                  className="col-sm-12 col-form-label"
                  style={{ textAlign: "center" }}
                >
                  Administradora
                </label>
              </Form.Group>
            </div>
            <div className="col-md-2">
              <Form.Group className="row">
                <label
                  htmlFor="examplePropostal"
                  className="col-sm-12 col-form-label"
                  style={{ textAlign: "center" }}
                >
                  Grupo
                </label>
              </Form.Group>
            </div>
            <div className="col-md-2">
              <Form.Group className="row">
                <label
                  htmlFor="examplePropostal"
                  className="col-sm-12 col-form-label"
                  style={{ textAlign: "center" }}
                >
                  Cota ​&nbsp;&nbsp;
                  <Link
                    to={{
                      pathname: "/backoffice/detalhamento-cota",
                      state: {
                        codigo: this.state.cota.codigo,
                      },
                    }}
                    data-tip="Detalhe da Cota"
                  >
                    <span
                      className="mdi mdi-ballot-outline"
                      style={{
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    ></span>
                  </Link>
                </label>
              </Form.Group>
            </div>
          </div>

          <div className="row" style={{ marginTop: "-20px" }}>
            <div className="col-md-3">
              <Form.Group className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <Form.Control
                    type="text"
                    style={{ height: "30px" }}
                    className="form-control"
                    id="cpf"
                    value={this.state.cliente.cpf}
                    onChange={(e) =>
                      this.setState({
                        cpf: e.target.value,
                      })
                    }
                  />
                </div>
              </Form.Group>
            </div>
            <div className="col-md-3">
              <Form.Group className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <Form.Control
                    type="text"
                    style={{ height: "30px" }}
                    className="form-control"
                    id="clienteNome"
                    value={this.state.cliente.nome}
                    onChange={(e) =>
                      this.setState({
                        clienteNome: e.target.value,
                      })
                    }
                  />
                </div>
              </Form.Group>
            </div>
            <div className="col-md-2">
              <Form.Group className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <Form.Control
                    type="text"
                    style={{ height: "30px" }}
                    className="form-control"
                    id="administradoraNome"
                    value={this.state.administradora.nome}
                    onChange={(e) =>
                      this.setState({
                        administradoraNome: e.target.value,
                      })
                    }
                  />
                </div>
              </Form.Group>
            </div>
            <div className="col-md-2">
              <Form.Group className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <Form.Control
                    type="text"
                    style={{ height: "30px" }}
                    className="form-control"
                    id="grupo"
                    value={this.state.grupo.grupo}
                    onChange={(e) =>
                      this.setState({
                        grupo: e.target.value,
                      })
                    }
                  />
                </div>
              </Form.Group>
            </div>
            <div className="col-md-2">
              <Form.Group className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <Form.Control
                    type="text"
                    style={{ height: "30px" }}
                    className="form-control"
                    id="cota"
                    value={this.state.cota.contrato}
                    onChange={(e) =>
                      this.setState({
                        cota: e.target.value,
                      })
                    }
                  />
                </div>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <StepCota cota={this.state.cota} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CabecalhoCota;
