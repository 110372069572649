// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cards_cardMain__tS1ud {
  min-height: 200px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid;
  border-radius: 10px;
  opacity: 1;
  @media (max-width: 768px) {
    margin: 10px 0;
  }
  @media (min-width: 769px) {
    width: 435px;
  }
}

.Cards_row__nBOVa {
  display: flex;
  width: 100%;
  padding: 0 25px;
  align-items: center;
}

.Cards_title__4cHsw {
  text-align: left;
  font: normal normal bold 15px/20px Roboto;
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
}

.Cards_editarBox__BU1\\+N {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 0;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/pages/AutoServico/components/Cards/CardsDados/Cards.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,mEAAmE;EACnE,+CAA+C;EAC/C,iBAAiB;EACjB,mBAAmB;EACnB,UAAU;EACV;IACE,cAAc;EAChB;EACA;IACE,YAAY;EACd;AACF;;AAEA;EACE,aAAa;EACb,WAAW;EACX,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,yCAAyC;EACzC,mBAAmB;EACnB,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,cAAc;EACd,iCAAiC;EACjC,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".cardMain {\n  min-height: 200px;\n  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;\n  background: #ffffff 0% 0% no-repeat padding-box;\n  border: 2px solid;\n  border-radius: 10px;\n  opacity: 1;\n  @media (max-width: 768px) {\n    margin: 10px 0;\n  }\n  @media (min-width: 769px) {\n    width: 435px;\n  }\n}\n\n.row {\n  display: flex;\n  width: 100%;\n  padding: 0 25px;\n  align-items: center;\n}\n\n.title {\n  text-align: left;\n  font: normal normal bold 15px/20px Roboto;\n  letter-spacing: 0px;\n  color: #000000;\n  text-transform: uppercase;\n}\n\n.editarBox {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  margin: 20px 0;\n  font-family: \"Roboto\", sans-serif;\n  font-size: 15px;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardMain": `Cards_cardMain__tS1ud`,
	"row": `Cards_row__nBOVa`,
	"title": `Cards_title__4cHsw`,
	"editarBox": `Cards_editarBox__BU1+N`
};
export default ___CSS_LOADER_EXPORT___;
