import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";
import Swal from 'sweetalert2'



export class FormImportacaoEmail extends Component {



  constructor(props){

    super(props);

    this.state = {
          file: '',
          assunto: '',
          peca: '',
          error: "",
          action: ""
    };
  }


  async componentDidMount() {
  }

  handleFormExtrato = async e => {
    e.preventDefault();
    const { file,assunto,peca} = this.state;
    if (!file || !assunto || !peca) {
      this.setState({ error: "Preencha os campos obrigatórios para continuar!" });
    } else {
      try {

            Swal.fire ({
               title: 'Aguarde ...',
               onBeforeOpen: () => {
                 Swal.showLoading ()
               }
            });

            let codigo        = this.state.codigoCota;
            const formData    = new FormData();
            formData.append('file',file);
            formData.append('assunto',assunto);
            formData.append('peca',peca);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            const response  = await api.post("api/concash/1.0/importacao-email/importar",formData,config);
            Swal.close();

      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o processamento da requisição."
        });
      }
    }
  };

  render() {


    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Importação para Envio de E-mail </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>

        <div className="row">

          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Importar Arquivo (CSV)</h4>
                <p className="card-description"></p>

                <form className="forms-sample" onSubmit={this.handleFormExtrato}>

                <div className="row">

                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                            <Form.Group className="row">
                              <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Arquivo: (Campos:E-Mail/PEÇA/Assunto).</label>
                              <div className="col-sm-9">
                              <Form.Control type="file" className="form-control" id="file" onChange={e =>  this.setState({file:e.target.files[0]}) }   size="sm"/>
                              </div>
                            </Form.Group>
                            <Form.Group className="row">
                              <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Assunto</label>
                              <div className="col-sm-9">
                              <Form.Control type="text"  className="form-control" id="assunto" value={this.state.assunto} onChange={e =>  this.setState({assunto:e.target.value}) }   size="sm"/>
                              </div>
                            </Form.Group>
                            <Form.Group className="row" >
                              <label className="col-sm-3 col-form-label">Peça</label>
                              <div className="col-sm-9">
                                <select className="form-control" value={this.state.peca} onChange={e => this.setState({ peca: e.target.value })}>
                                    <option value="" >Selecione</option>
                                    <option value="mailMarketingAnoNovo" >Ano Novo</option>
                                    <option value="mailBlackFriday" >Black Friday</option>
                                    <option value="mailMarketingNatal" >Natal</option>
                                </select>
                              </div>
                            </Form.Group>
                            <div className="text-right">
                              <button type="submit" className="btn btn-primary mr-2">Enviar E-mail</button>
                            </div>
                      </div>
                    </div>
                  </div>

                </div>

                </form>

                <div className="table-responsive">

                </div>
              </div>
            </div>
          </div>
        </div>
       </div>
    )
  }
}

export default withRouter(FormImportacaoEmail)
