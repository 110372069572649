import React, { Component, PropTypes } from 'react';
import { Form } from 'react-bootstrap';
import api from "../services/api";

class ParametroField extends Component {

  contabilizado   = 0;

  constructor(props){

    super(props);
    const { configurados }       = this.props;
    const { parametro }          = this.props;
    const { administradora }     = this.props;
    var valor                    = "";

    if(configurados[parametro.nome]  !== undefined){
          valor = configurados[parametro.nome];
     }

    this.state = {
      parametro     : parametro,
      administradora: administradora,
      valor         : valor,
      error         : ""
    }

  }

  handleForm = async e => {
    const { valor } = this.state;
    var codigoAdministradora =  this.state.administradora.codigo;
    var codigoParametro      =  this.state.parametro.codigo;

    if (!valor) {
      this.setState({ error: "Preencha os campos obrigatórios para continuar!" });
    } else {
      try {
          const response = await api.put("api/concash/1.0/parametro-administradora/editar", {
              valor:valor,codigoAdministradora:codigoAdministradora,codigoParametro:codigoParametro
            });

      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o processamento da requisição."
        });
      }
    }
  };


  render() {

    const { parametro }     = this.props;

    return (

      <div className="row" >
        <div className="col-md-12">
          <Form.Group className="row" >
            <label className="col-sm-5 col-form-label">{parametro.nome}</label>
            <div className="col-sm-5">
              <Form.Control type="text" value={this.state.valor}  onChange={e => this.setState({ valor: e.target.value })}   size="sm" />
            </div>
            <div className="col-sm-2">
              <button type="button" className="btn btn-primary mr-2" onClick={this.handleForm}>Salvar</button>
            </div>
          </Form.Group>
        </div>
      </div>
    );
  }
}


export default ParametroField;
