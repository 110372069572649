import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { logout, setCliente, tipoPessoa } from "../../services/auth";
import { Link, withRouter } from "react-router-dom";
import api from "../../services/api";
import { TiThMenu } from "react-icons/ti";
import { AiOutlineClose } from "react-icons/ai";
import { MdArrowForwardIos } from "react-icons/md";
import ReactTooltip from "react-tooltip";
import LogoMobile from "../../assets/images/logo-dashboard.png";
import LogoW from "../../assets/images/logo_footer.png";
import {
  ButtonLarge,
  HeaderContainerMobile,
  LinkWhite,
  MenuNavMobile,
  NewNavBar,
  NewNavBarMobile,
  TextUsers,
} from "./Navbar.styled";

import { HiOutlineMenu } from "react-icons/hi";

import ConcashLogo from "../../styles/themes/v3/assets/logo Concash.png";
import { Backdrop } from "@mui/material";
import { colors } from "../../styles/colors";
import { BlockUser } from "../../components/BlockUser";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nome: "",
      firstName: "",
      total: 0,
      totalProcessos: 0,
      nameSliced: "",
      nameUser: "",
      mobileOpen: false,
      menuNav: false,
    };
  }

  ajustarTela() {
    const tamanho = window.innerWidth;
    if (tamanho <= 800) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  }

  async componentDidMount() {
    const tamanho = window.innerWidth;
    if (tamanho <= 800) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }

    await api
      .post("api/sistema/1.0/usuario/detalhe", {})
      .then(async (response) => {
        this.setState({ nome: await response.data.name });
        this.setState({
          nameUser: await response.data.name,
        });
        tipoPessoa(await response.data.tipoPessoa);
      })
      .catch((error) => {
        this.setState({
          error: "Houve um problema para obter os grupos de ações.",
        });
      });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.intervalProcesso);
    const tamanho = window.innerWidth;
    if (tamanho <= 800) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  }

  toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }

  redirectPaginaPendencias() {
    window.location = "/whatsapp/pendentes";
  }

  redirectPaginaProcessos() {
    window.location = "/processo/pendentes";
  }

  executaLogout() {
    logout();
    window.location.reload(false);
  }

  render() {
    const { total, totalProcessos } = this.state;

    return (
      <>
        {this.state.isMobile ? (
          <>
            <NewNavBarMobile>
              <a
                href="/app"
                style={{
                  display: "flex",
                  textDecoration: "none",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={ConcashLogo} width={110} height={30} />
              </a>

              <div
                onClick={() => this.setState({ menuNav: !this.state.menuNav })}
              >
                <HiOutlineMenu color="#16898E" size={28} />
              </div>
            </NewNavBarMobile>
            <Backdrop
              open={this.state.menuNav}
              style={{ alignItems: "flex-end", padding: 0, zIndex: 999 }}
            >
              <MenuNavMobile
                action={() => this.setState({ menuNav: false })}
                user={this.state.nameUser?.split(" ")[0]}
              />
            </Backdrop>
          </>
        ) : (
          <>
            <NewNavBar>
              <a
                href="/app"
                style={{
                  display: "flex",
                  textDecoration: "none",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={ConcashLogo} width={130} height={35} />
              </a>
              <BlockUser user={this.state.nameUser?.split(" ")[0]} />
            </NewNavBar>
          </>
        )}
      </>
    );
  }
}

export default withRouter(Navbar);
