import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import BoxMensagem from './BoxMensagem';
import BoxArquivo from './BoxArquivo';


const bubleCliente = {

   color:      "white",
   textAlign:  "justify",
   padding:    "15px",
   marginTop:  "15px",
   marginLeft: "15px",
   fontSize:   "12px",
   background: "green",
   borderRadius:"10px",
}

const bubleClienteData = {

   color:      "black",
   fontSize:   "8px",
   marginLeft: "15px",
   textAlign:  "left",
}


export class BubbleCliente extends Component {


  constructor(props){
        super(props);
  }


  render() {

    const { mensagem,data,arquivo }  = this.props;

    return (
      <>
        <div className="row">
           {arquivo
             ? <BoxArquivo  arquivo={arquivo} />
             : <BoxMensagem mensagem={mensagem} />
           } 
        </div>
        <div className="row">
           <div className="col-md-8" style={bubleClienteData}>
                {data}
           </div>
        </div>
      </>
    )
  }
}

export default BubbleCliente;
