import React, { Component, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";
import { AlteraNome } from '../../actions';
import BubbleOperador from './BubbleOperador';
import BubbleCliente from './BubbleCliente';
import styled, { css } from 'styled-components';
import Swal from 'sweetalert2';
import ConverteCliente from "../../components/ConverteCliente";



const BoxInformativo = styled.div`

      text-align:center;
      color: white;
      padding:10px;
      background: #2196f3;
      height: 80px;
      width: 150px;
      margin-top:20px;
      border-radius: 10px;

`;


export class WhatsAppConversa extends Component {


  constructor(props){
        super(props);
        this.state = {
          sessao:[],
          file: '',
          clienteNome: '',
          convertClienteNome: '',
          convertClienteCPF: '',
          convertClienteEmail: '',
          cliente:[],
          caption:"",
          ECliente:false,
          prospect:[],
          whatsapp:[],
          mensagens:[],
          mensagem: '',
          show: false
        };
  }

  async componentDidMount() {


        if(this.props[0].location.state !== undefined){

            if(this.props[0].location.state.sessao !== undefined){
                  const obj_sessao  = this.props[0].location.state.sessao
                  var           obj = this;
                  this.setState({sessao:obj_sessao,ECliente:true,prospect: obj_sessao.prospect,clienteNome: obj_sessao.cliente.nome,cliente: obj_sessao.cliente,whatsapp:obj_sessao.whatsapp},() => {
                      obj.obtemWhatsappMensagens();
                  });
             }else{

                  const{ codigoCliente, codigoProspect } = this.props[0].location.state;
                  const response = await api.post("api/concash/1.0/whatsapp-sessao/adicionar", {
                    codigoCliente,codigoProspect
                  });

                  var           obj = this;
                  if(response.data.prospect !== null){
                    this.setState({ECliente:false,clienteNome: response.data.prospect.nome, convertClienteNome: response.data.prospect.nome,convertClienteCPF: response.data.prospect.cpf,convertClienteEmail: response.data.prospect.email},() => {
                    });
                  }
                  if(response.data.cliente !== null){
                    this.setState({ECliente:true,clienteNome: response.data.cliente.nome},() => {
                    });
                  }
                  this.setState({sessao:response.data,prospect: response.data.prospect,cliente: response.data.cliente,whatsapp:response.data.whatsapp},() => {
                        obj.obtemWhatsappMensagens();
                  });
              }
        }

        this.interval = setInterval(() => {
            this.obtemWhatsappMensagens();
        }, 10000);

  }

  componentWillUnmount() {
      clearInterval(this.interval);
  }

  async  obtemWhatsappMensagens(){
      try {

            let codigoCliente  = null;
            let codigoProspect = null;

            if(this.state.ECliente == false){
                codigoProspect = this.state.prospect.codigo;
            }else{
                codigoCliente  = this.state.cliente.codigo;
            }
            if(codigoCliente != null || codigoProspect != null){
                const response = await api.post("api/concash/1.0/whatsapp-mensagem/listar", {codigoCliente:codigoCliente,codigoProspect: codigoProspect,codigoWhatsapp:this.state.whatsapp.codigo});
                if(response.data.length > this.state.mensagens.length){
                    this.setState({ mensagens: response.data });
                }
           }

      } catch (err) {
            this.setState({
              error:
                "Houve um problema para obter as mensagens."
            });
      }
  }


  handleFormMensagem = async e => {

    e.preventDefault();
    const { mensagem } = this.state;
    if (!mensagem) {
      Swal.fire('Por favor, informe a mensagem!');
    } else {
      try {

            Swal.fire ({
               title: 'Aguarde ...',
               onBeforeOpen: () => {
                 Swal.showLoading ()
               }
            });

            var codigoWhatsappSessao = this.state.sessao.codigo;

            const response = await api.post("api/concash/1.0/whatsapp-mensagem/adicionar", {
              mensagem,codigoWhatsappSessao:codigoWhatsappSessao
            });

            this.setState({ mensagem: "" });
            this.obtemWhatsappMensagens();

            Swal.fire ('Mensagem cadastrada com sucesso!');

      } catch (err) {
            Swal.fire ('Houve um problema na requisição!');
      }
    }
  };




  handleFormArquivo = async e => {
    e.preventDefault();
    const { file,caption} = this.state;
    if (!file || !caption) {
      this.setState({ error: "Preencha os campos obrigatórios para continuar!" });
    } else {
      try {

            Swal.fire ({
               title: 'Aguarde ...',
               onBeforeOpen: () => {
                 Swal.showLoading ()
               }
            });

            var codigoWhatsappSessao = this.state.sessao.codigo;
            const formData           = new FormData();

            formData.append('file',file);
            formData.append('caption',caption);
            formData.append('codigoWhatsappSessao',codigoWhatsappSessao);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            const response  = await api.post("api/concash/1.0/whatsapp-mensagem/upload",formData,config);

            this.setState({ caption: "",file: "" });
            this.obtemWhatsappMensagens();
            Swal.close();

            Swal.fire ('Arquivo enviado com sucesso!');

      } catch (err) {
            Swal.fire ('Houve um problema na requisição!');
      }
    }
  };


  render() {


    return (

      <div>
        <div className="page-header">
          <h3 className="page-title"> Conversa: Cliente ({this.state.clienteNome}) / WhatsApp ({this.state.whatsapp.nome})  </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>
        <div className="row">

          <div className="col-md-8 grid-margin stretch-card" style={{
                                      backgroundImage: "url(" + "https://www.concash.com.br/mail/ipad-concash.png" + ")",
                                      backgroundPosition: 'center',
                                      backgroundSize: '450px 490px',
                                      backgroundRepeat: 'no-repeat',
                                      backgroundSize: '100% 100%',
                                      minHeight:"800px",
                                      maxHeight:"800px",
                                      padding:"60px",
                                      paddingRight:"70px",
                                      paddingTop:"40px",

                        }}>

             <div className="card" style={{background: "transparent", height:"660px", marginTop:"30px", overflowY:"auto"}}>

                <div className="card-body" style={{background: "transparent"}}>

                    <div className="row" style={{background: "transparent"}}>

                                <div className="col-md-12">

                                    {this.state.mensagens.map((mensagem,index) => (


                                          <div key={index}>
                                            {mensagem.origem == "CLIENTE"
                                               ? <BubbleCliente  mensagem={mensagem.mensagem} arquivo={mensagem.arquivo} data={mensagem.dataIni}/>
                                               : <BubbleOperador mensagem={mensagem.mensagem} arquivo={mensagem.arquivo} data={mensagem.dataIni}/>
                                            }
                                            </div>

                                    ))}

                                </div>


                    </div>

              </div>
            </div>
          </div>

          <div className="col-md-4 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h3 className="page-title"></h3>

                      <div className="row">
                          <div className="col-md-12">

                              <Form.Group className="row">
                                <div className="col-sm-12">
                                  <textarea  id="mensagem" style={{ height:"200px", width: "100%" }} value={this.state.mensagem} placeholder="mensagem"  onChange={e => this.setState({ mensagem: e.target.value })}  size="sm" >
                                  </textarea>
                                </div>
                              </Form.Group>

                          </div>
                      </div>

                      <div className="row">
                          <div className="col-md-12">

                            <div className="text-right">
                              <button type="button" className="btn btn-primary mr-2" onClick={ e => this.handleFormMensagem(e)}>Enviar Mensagem</button>
                            </div>

                          </div>
                      </div>

                      <form className="forms-sample" onSubmit={this.handleFormArquivo}>

                          <div className="row" style={{marginTop:"40px"}}>

                            <div className="col-md-12 grid-margin stretch-card">
                              <div className="card">
                                <div className="card-body">
                                      <Form.Group className="row">
                                        <label htmlFor="examplePropostal" className="col-sm-12 col-form-label">Arquivo: (PNG, JPG).</label>
                                        <div className="col-sm-12">
                                        <Form.Control type="file" className="form-control" id="file" onChange={e =>  this.setState({file:e.target.files[0]}) }   size="sm"/>
                                        </div>
                                      </Form.Group>
                                      <Form.Group className="row">
                                        <label htmlFor="examplePropostal" className="col-sm-12 col-form-label">Caption</label>
                                        <div className="col-sm-12">
                                        <Form.Control type="text"  className="form-control" id="caption" value={this.state.caption} onChange={e =>  this.setState({caption:e.target.value}) }   size="sm"/>
                                        </div>
                                      </Form.Group>
                                      <div className="text-right">
                                        <button type="submit" className="btn btn-primary mr-2">Enviar Arquivo</button>
                                      </div>
                                </div>
                              </div>
                            </div>

                          </div>

                      </form>

                      {this.state.ECliente &&

                        <div className="text-center">
                            <Link
                                to={{pathname: "/cliente/detalhe",state: {
                                    cliente: this.state.cliente
                                }}}
                                    className="m-3 btn btn-sm btn-success"
                                >
                                Editar Cliente
                                </Link>
                        </div>

                      }

                      {!this.state.ECliente &&
                            <ConverteCliente prospect={this.state.prospect} convertClienteNome={this.state.convertClienteNome} convertClienteCPF={this.state.convertClienteCPF} convertClienteEmail={this.state.convertClienteEmail} />
                      }

              </div>
            </div>
          </div>




        </div>
      </div>
    )
  }
}

export default withRouter(WhatsAppConversa);
