import React, { Component } from "react";
import api from "../../services/api";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import SimuladorSimplificado from "../../components/SimuladorSimplificado";
import Proposta from "../../components/Proposta";
import Assinatura from "../../components/Assinatura";
import Pagamento from "../../components/Pagamento";
import Processo from "../../components/Processo";
import Parcela from "../../components/Parcela";
import Lance from "../../components/Lance";
import DespesaCota from "../../components/DespesaCota";
// import CotaInfo from "../../components/CotaInfo";
import CotaArquivo from "../../components/CotaArquivo";
import CabecalhoCota from "../../components/CabecalhoCota";

const BoxInformativo = styled.div`
  text-align: center;
  color: white;
  padding: 10px;
  background: #2196f3;
  width: 150px;
  height: 40px;
  margin-top: 20px;
  border-radius: 10px 10px 0px 0px;
  cursor: pointer;

  :hover {
    background: #16898e;
  }
`;

export class BackofficeDetalhamentoCota extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      cota: [],
      cliente: [],
      grupo: [],
      administradora: [],
      blocoCota: false,
      blocoProposta: false,
      blocoDespesa: false,
      blocoPagamento: false,
      blocoInlira: false,
      blocoTransferencia: false,
      blocoSimulador: false,
      blocoAssinatura: false,
      blocoParcela: false,
      blocoLance: false,
      blocoProcesso: false,
      backgroundCota: "#ccc",
      backgroundProposta: "#2196f3",
      backgroundDespesa: "#2196f3",
      backgroundPagamento: "#2196f3",
      backgroundInlira: "#2196f3",
      backgroundTransferencia: "#2196f3",
      backgroundSimulador: "#2196f3",
      backgroundAssinatura: "#2196f3",
      backgroundParcela: "#2196f3",
      backgroundLance: "#2196f3",
      backgroundProcesso: "#2196f3",
      assinaturas: [],
      pagamentos: [],
      documentos: [],
      reanalises: [],
      propostas: [],
    };
  }

  async componentDidMount() {
    if (this.props[0].location.state !== undefined) {
      const codigo = this.props[0].location.state.codigo;
      this.obtemCota(codigo);
    }
  }

  async obtemCota(codigo) {
    try {
      const response = await api.post(
        "api/concash/1.0/consorcio-grupo-cota/detalhe",
        { codigo: codigo }
      );
      this.setState({
        cota: response.data,
        blocoCota: true,
        cliente: response.data.cliente,
        grupo: response.data.grupo,
        administradora: response.data.grupo.consorcio.administradora,
      });
    } catch (err) {
      this.setState({
        error: "Houve um problema para obter a cota.",
      });
    }
  }

  handleNegociacoes = async (e) => {
    e.preventDefault();
    this.props.history.push("/comercial/negociacoes");
  };

  handleProspect = async (e) => {
    e.preventDefault();
    this.props.history.push("/comercial/prospect");
  };

  alteraAbas = (aba) => {
    const abas = [
      "Cota",
      "Propostas",
      "Assinaturas",
      "Pagamentos",
      "Despesas",
      "Inlira",
      "Simulador",
      "Transferência",
      "Parcela",
      "Lance",
      "Processo",
    ];

    var tmp_cota = false;
    var tmp_proposta = false;
    var tmp_assinatura = false;
    var tmp_pagamento = false;
    var tmp_despesa = false;
    var tmp_inlira = false;
    var tmp_simulador = false;
    var tmp_transferencia = false;
    var tmp_parcela = false;
    var tmp_lance = false;
    var tmp_processo = false;
    var bck_cota = "#2196f3";
    var bck_proposta = "#2196f3";
    var bck_assinatura = "#2196f3";
    var bck_pagamento = "#2196f3";
    var bck_transferencia = "#2196f3";
    var bck_despesa = "#2196f3";
    var bck_inlira = "#2196f3";
    var bck_simulador = "#2196f3";
    var bck_parcela = "#2196f3";
    var bck_lance = "#2196f3";
    var bck_processo = "#2196f3";

    for (var i = 0; i < abas.length; i++) {
      if (aba === "Cota") {
        tmp_cota = true;
        bck_cota = "#ccc";
      }
      if (aba === "Propostas") {
        tmp_proposta = true;
        bck_proposta = "#ccc";
      }
      if (aba === "Assinaturas") {
        tmp_assinatura = true;
        bck_assinatura = "#ccc";
      }
      if (aba === "Pagamentos") {
        tmp_pagamento = true;
        bck_pagamento = "#ccc";
      }
      if (aba === "Despesas") {
        tmp_despesa = true;
        bck_despesa = "#ccc";
      }
      if (aba === "Inlira") {
        tmp_inlira = true;
        bck_inlira = "#ccc";
      }
      if (aba === "Simulador") {
        tmp_simulador = true;
        bck_simulador = "#ccc";
      }
      if (aba === "Transferência") {
        tmp_transferencia = true;
        bck_transferencia = "#ccc";
      }
      if (aba === "Parcela") {
        tmp_parcela = true;
        bck_parcela = "#ccc";
      }
      if (aba === "Lance") {
        tmp_lance = true;
        bck_lance = "#ccc";
      }
      if (aba === "Processo") {
        tmp_processo = true;
        bck_processo = "#ccc";
      }
    }

    this.setState({
      blocoCota: tmp_cota,
      blocoProposta: tmp_proposta,
      blocoAssinatura: tmp_assinatura,
      blocoTransferencia: tmp_transferencia,
      blocoPagamento: tmp_pagamento,
      blocoDespesa: tmp_despesa,
      blocoInlira: tmp_inlira,
      blocoSimulador: tmp_simulador,
      blocoParcela: tmp_parcela,
      blocoLance: tmp_lance,
      blocoProcesso: tmp_processo,
    });
    this.setState({
      backgroundCota: bck_cota,
      backgroundProposta: bck_proposta,
      backgroundAssinatura: bck_assinatura,
      backgroundTransferencia: bck_transferencia,
      backgroundPagamento: bck_pagamento,
      backgroundDespesa: bck_despesa,
      backgroundInlira: bck_inlira,
      backgroundSimulador: bck_simulador,
      backgroundParcela: bck_parcela,
      backgroundLance: bck_lance,
      backgroundProcesso: bck_processo,
    });
  };

  render() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">
            {" "}
            Cota - Detalhamento ({this.state.cota.contrato}){" "}
          </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb"></ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                {this.state.cliente.cpf && (
                  <CabecalhoCota
                    cliente={this.state.cliente}
                    cota={this.state.cota}
                    grupo={this.state.grupo}
                    administradora={this.state.administradora}
                  />
                )}

                <div className="row">
                  <BoxInformativo
                    className="col-md-2"
                    style={{
                      background: this.state.backgroundCota,
                      fontSize: "10px",
                    }}
                    onClick={(e) => this.alteraAbas("Cota")}
                  >
                    Cota
                  </BoxInformativo>

                  <BoxInformativo
                    className="col-md-2"
                    style={{
                      background: this.state.backgroundProposta,
                      fontSize: "10px",
                    }}
                    onClick={(e) => this.alteraAbas("Propostas")}
                  >
                    Propostas
                  </BoxInformativo>

                  <BoxInformativo
                    className="col-md-1"
                    style={{
                      background: this.state.backgroundAssinatura,
                      fontSize: "10px",
                    }}
                    onClick={(e) => this.alteraAbas("Assinaturas")}
                  >
                    Assinatura
                  </BoxInformativo>

                  <BoxInformativo
                    className="col-md-1"
                    style={{
                      background: this.state.backgroundPagamento,
                      fontSize: "10px",
                    }}
                    onClick={(e) => this.alteraAbas("Pagamentos")}
                  >
                    Pagamento
                  </BoxInformativo>

                  <BoxInformativo
                    className="col-md-1"
                    style={{
                      background: this.state.backgroundDespesa,
                      fontSize: "10px",
                    }}
                    onClick={(e) => this.alteraAbas("Despesas")}
                  >
                    Despesa
                  </BoxInformativo>

                  <BoxInformativo
                    className="col-md-1"
                    style={{
                      background: this.state.backgroundParcela,
                      fontSize: "10px",
                    }}
                    onClick={(e) => this.alteraAbas("Parcela")}
                  >
                    Parcelas
                  </BoxInformativo>

                  <BoxInformativo
                    className="col-md-1"
                    style={{
                      background: this.state.backgroundLance,
                      fontSize: "10px",
                    }}
                    onClick={(e) => this.alteraAbas("Lance")}
                  >
                    Lances
                  </BoxInformativo>

                  <BoxInformativo
                    className="col-md-1"
                    style={{
                      background: this.state.backgroundProcesso,
                      fontSize: "10px",
                    }}
                    onClick={(e) => this.alteraAbas("Processo")}
                  >
                    Processos
                  </BoxInformativo>

                  <BoxInformativo
                    className="col-md-1"
                    style={{
                      background: this.state.backgroundTransferencia,
                      fontSize: "10px",
                    }}
                    onClick={(e) => this.alteraAbas("Transferência")}
                  >
                    Transferênc.
                  </BoxInformativo>

                  <BoxInformativo
                    className="col-md-1"
                    style={{
                      background: this.state.backgroundSimulador,
                      fontSize: "10px",
                    }}
                    onClick={(e) => this.alteraAbas("Simulador")}
                  >
                    Simulador
                  </BoxInformativo>
                </div>

                {this.state.blocoCota && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        background: "#ccc",
                        minHeight: "300px",
                        padding: "20px",
                      }}
                    >
                      {/* <CotaInfo cota={this.state.cota} administradora={this.state.administradora} grupo={this.state.grupo} /> */}
                    </div>
                  </div>
                )}

                {this.state.blocoProposta && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        background: "#ccc",
                        minHeight: "300px",
                        padding: "20px",
                      }}
                    >
                      <Proposta codigoCota={this.state.cota.codigo} />
                    </div>
                  </div>
                )}

                {this.state.blocoAssinatura && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        background: "#ccc",
                        minHeight: "300px",
                        padding: "20px",
                      }}
                    >
                      <Assinatura
                        codigoCota={this.state.cota.codigo}
                        situacao={this.state.cota.situacao}
                      />
                    </div>
                  </div>
                )}

                {this.state.blocoPagamento && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        background: "#ccc",
                        minHeight: "300px",
                        padding: "20px",
                      }}
                    >
                      <Pagamento
                        codigoCota={this.state.cota.codigo}
                        codigoCliente={this.state.cliente.codigo}
                      />
                    </div>
                  </div>
                )}

                {this.state.blocoDespesa && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        background: "#ccc",
                        minHeight: "300px",
                        padding: "20px",
                      }}
                    >
                      <DespesaCota codigoCota={this.state.cota.codigo} />
                    </div>
                  </div>
                )}

                {this.state.blocoTransferencia && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        background: "#ccc",
                        minHeight: "300px",
                        padding: "20px",
                      }}
                    >
                      <CotaArquivo
                        codigoCota={this.state.cota.codigo}
                        transferido={this.state.cota.transferido}
                        codigoExterno={this.state.cota.codigoExterno}
                      />
                    </div>
                  </div>
                )}

                {this.state.blocoParcela && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        background: "#ccc",
                        minHeight: "300px",
                        padding: "20px",
                      }}
                    >
                      <Parcela codigoCota={this.state.cota.codigo} />
                    </div>
                  </div>
                )}

                {this.state.blocoLance && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        background: "#ccc",
                        minHeight: "300px",
                        padding: "20px",
                      }}
                    >
                      <Lance codigoCota={this.state.cota.codigo} />
                    </div>
                  </div>
                )}

                {this.state.blocoProcesso && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        background: "#ccc",
                        minHeight: "300px",
                        padding: "20px",
                      }}
                    >
                      <Processo codigoCota={this.state.cota.codigo} />
                    </div>
                  </div>
                )}

                {this.state.blocoSimulador && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        background: "#ccc",
                        minHeight: "300px",
                        padding: "20px",
                      }}
                    >
                      <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                          <div className="card-body">
                            <SimuladorSimplificado
                              dados={this.state}
                              cota={this.state.cota}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(BackofficeDetalhamentoCota);
