import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import api from "../../services/api";
import { Link } from "react-router-dom";
import SweetAlert from 'sweetalert2-react';
import Swal from 'sweetalert2';
import Pagination from "../../components/Pagination";
import ReactTooltip from 'react-tooltip';



export class BasicElements extends Component {



  constructor(){
        super();
        this.state = {
          bancos: [],
          currentPage:1,
          totalRecords:null,
          nome: '',
          cnpj: '',
          show: false
        };
  }

  async componentDidMount() {
      this.obtemListaBancos();
  }

  async obtemListaBancos(){

      try {

        const {
          currentPage,
          nome,
          cnpj
        } = this.state;

        const response = await api.post("api/concash/1.0/banco/listar", { pagina: currentPage, nome:nome, cnpj: cnpj });
        this.setState({ totalRecords: response.data.totalElements,bancos: response.data.content});
      } catch (err) {
        this.setState({
          error:
            "Houve um problema para obter a lista de bancos."
        });
      }
  }

  onPageChanged = data => {

    var classe = this;
    const { currentPage, totalPages, pageLimit } = data;
    this.setState({ currentPage },() => {
        classe.obtemListaBancos();
    });
  };

  handleCnpj = async (e) => {
      e.preventDefault();
      var classe = this;
      this.setState({ nome: "",totalRecords:null}, () => {
          classe.obtemListaBancos();
      });
  };

  handleNome = async (e) => {
      e.preventDefault();
      var classe = this;
      this.setState({ cnpj: "",totalRecords:null}, () => {
          classe.obtemListaBancos();
      });
  };

  handleKeyPressNome = (event) => {
    
    if(event.key === 'Enter'){
      var classe = this;
      this.setState({ cnpj: "",totalRecords:null}, () => {
        classe.obtemListaBancos();
      });
    }
  }

  handleKeyPressCnpj = (event) => {
    
    if(event.key === 'Enter'){
      var classe = this;
      this.setState({ nome: "",totalRecords:null}, () => {
        classe.obtemListaBancos();
      });
    }
  }

  handleDeletar = async (codigo,e) => {

    var classe = this;
    Swal.fire({
       title: 'Você tem certeza que deseja excluir esse registro?',
       text: 'Após exclusão não será possivel a reversão',
       icon: 'warning',
       showCancelButton: true,
       confirmButtonColor: '#3085d6',
       cancelButtonColor: '#d33',
       confirmButtonText: 'Confirmar!'
    }).then((result) => {
       if(result.value){
         classe.handleDeletarEfetivar(codigo,e);
      }
    })
  };

  handleDeletarEfetivar = async (codigo,e) => {

      e.preventDefault();
      if (!codigo) {
        this.setState({ error: "Código indefinido!" });
      } else {
        try {
           const response = await api.put("api/concash/1.0/banco/excluir", {
                codigo
           });
           this.obtemListaBancos();
        } catch (err) {
          this.setState({
            error:
              "Houve um problema com a exclusão."
          });
        }
      }
  };

  render() {

    const {
      totalRecords
    } = this.state;


    return (
      <div>
        <ReactTooltip />
        <div className="page-header">
          <h3 className="page-title"> Bancos </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">

              <div className="row" style={{backgroundColor:'rgb(222, 226, 230)'}}>

                <div className="col-md-6" style={{marginTop:'30px'}}>
                    <div className="row">
                      <div className="col-md-9">
                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Nome</label>
                            <div className="col-sm-9">
                              <Form.Control type="text" style={{height:'30px'}} className="form-control" id="nome" onKeyPress={this.handleKeyPressNome} value={this.state.nome} onChange={e => this.setState({ nome: e.target.value })} />
                            </div>
                          </Form.Group>
                      </div>
                      <div className="col-md-3">
                        <button type="submit" className="btn btn-primary mr-2" onClick={e =>this.handleNome(e)}>Filtrar</button>
                      </div>
                    </div>
                  </div>
                <div className="col-md-6" style={{marginTop:'30px'}}>
                      <div className="row">
                        <div className="col-md-9">
                            <Form.Group className="row">
                              <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">CNPJ</label>
                              <div className="col-sm-9">
                                <Form.Control type="text" style={{height:'30px'}} className="form-control" id="cnpj" onKeyPress={this.handleKeyPressCnpj} value={this.state.cnpj} onChange={e => this.setState({ cnpj: e.target.value })} />
                              </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3">
                          <button type="submit" className="btn btn-primary mr-2" onClick={e =>this.handleCnpj(e)}>Filtrar</button>
                        </div>
                      </div>
                  </div>
                </div>

                  <p className="card-description text-right">
                                <Link
                                  to={{pathname: "/banco/detalhe"}}
                                  className="m-3 btn btn-sm btn-success"
                                >
                                Cadastrar
                      </Link></p>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th> Banco </th>
                          <th> Número </th>
                          <th> CNPJ </th>
                          <th className="text-center"> Ação </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.bancos.map((banco,index) => (
                        <tr key={index}>
                          <td>
                                <Link
                                          to={{pathname: "/log-banco",state: {
                                            registroTitulo: banco.nome , registroIdentificador:banco.codigo, registroTabela:'banco'
                                          }}}

                                >
                                        <span className="mdi mdi-alarm-check" data-tip="Histórico"></span>
                                </Link>
                                &nbsp;&nbsp;{banco.nome}
                          </td>
                          <td> {banco.numero}  </td>
                          <td> {banco.cnpj}  </td>
                          <td className="text-center"><Link
                                          to={{pathname: "/banco/detalhe",state: {
                                            banco: banco
                                          }}}
                                          className="m-3 btn btn-sm btn-success"
                                        >
                                        Editar
                              </Link>
                              <Link
                                className="m-3 btn btn-sm btn-danger"
                                onClick={e =>this.handleDeletar(banco.codigo,e)}
                              >
                                Deletar
                              </Link>
                          </td>
                        </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div style={{
                                display: 'flex',
                                marginTop: '50px',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                  {!!this.state.totalRecords &&
                  <Pagination
                    totalRecords={totalRecords}
                    pageLimit={20}
                    pageNeighbours={1}
                    onPageChanged={this.onPageChanged}
                  />
                 }
                 </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BasicElements
