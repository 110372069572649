import React from 'react';
import S from "./Cards.module.css";
import MainStyle from "../../../Autoservico.module.css";
import { MdModeEditOutline  } from "react-icons/md";
import { ButtonOutlined } from '../../buttons';
import { maskCEP } from '../../../../../mask/maskCEP';
import { useSelector } from 'react-redux';
import { formatCpfCnpj, formataCPF } from '../../../../../utils/format';
import { v3MaskPhone } from '../../../../../styles/themes/utils/format';
import moment from 'moment';


export const CardDadosPessoais = ({concluido, estilo, dados, openScreen, openDoc}) => {
  const state = useSelector(state =>  state.autoservico);
  const service = window.location.pathname.replace("/autoservico/", "").split("/");

  return (
    <div style={{ display: "flex", borderColor: concluido ? "#31B500" : "#c9c9c9", maxWidth: "100%", boxSizing: "border-box"}} className={S.cardMain}>
      <div style={{ display: "flex", flex: 1, width: "100%"}}>
        <div style={{ display: "flex", flex: 1, flexDirection: "column", maxWidth: "100%"}}>
          <div style={{ backgroundColor: concluido ? "#31B500" : "#c9c9c9"}} className={MainStyle.badgeDados}>
          <span>{concluido ? "CONCLUÍDO" : "PENDENTE"}</span>
        </div>
        <div className={S.row} style={{ gap: 10, marginBottom: 10}}>
          <span className={S.title}>DADOS {state?.tipoPessoa === "PJ" ? "DA EMPRESA" : "PESSOAIS"}</span>
        </div>
        <div className={S.row}>
        <div style={{ minWidth: 120}}><span className={MainStyle.text15}>Nome / Razão:</span></div>
          <span className={MainStyle.text15}>{dados?.nome}</span>
        </div>
        <div className={S.row}>
          <div style={{ minWidth: 120}}><span className={MainStyle.text15}>CPF / CNPJ:</span></div>
            <span className={MainStyle.text15}>{dados?.cpfCnpj}</span>
        </div>
        {state?.tipoPessoa === "PF" ? (  
          <>
            <div className={S.row}>
              <div style={{ minWidth: 120}}><span className={MainStyle.text15}>RG:</span></div>
                <span className={MainStyle.text15}>{dados?.rg}</span>
            </div>
          </>        
        ) : (
          <>
            <div className={S.row}>
              <div style={{ minWidth: 120}}><span className={MainStyle.text15}>Nome Fantasia:</span></div>
                <span className={MainStyle.text15}>{dados?.nomeFantasia}</span>
            </div>
          </>
        )}
        <div className={S.row}>
          <div style={{ minWidth: 120}}><span className={MainStyle.text15}>E-mail:</span></div>
            <span className={MainStyle.text15}>{dados?.email}</span>
        </div>
        <div className={S.row}>
          <div style={{ minWidth: 120}}><span className={MainStyle.text15}>Telefone:</span></div>
            <div  style={{ display: "flex", flex: 1, justifyContent: "space-between", alignItems: "center", width: "100%" }}>
              <span className={MainStyle.text15}>{v3MaskPhone(dados?.telefone.replace(/\D/g, ""))}</span>

            </div>          
        </div>
        <div className={S.row} style={{ display: "flex", marginTop: 20, marginBottom: 20 }}>
        <div style={{  minWidth: 120}}><span className={MainStyle.text15}>{state?.tipoPessoa === "PJ" ? "Contrato Social" : "Fotos do Doc:"}</span></div>
        <div style={{ display: "flex", flex: 1, justifyContent: "space-between", alignItems: "center" }}>
          <span className={MainStyle.text15}>{dados?.fotosDoc ? "Enviado" : "Pendente"}</span>
          
          {
          !dados.fotosDoc ?
              <ButtonOutlined estilo={estilo} texto={"Enviar Foto"} onClick={() => {
                openScreen();
                openDoc();
                }} tamanho={110} /> : (<></>)}
        </div>
      </div>
      <div style={{ display: "flex", width: "100%", justifyContent: "flex-end", marginBottom: 20, padding: "0 25px"}}>
        {concluido ? (
                  <ButtonOutlined estilo={estilo} texto={"Editar"} tamanho={110}  onClick={openScreen}  />
                ) : (
                  <ButtonOutlined estilo={estilo} texto={"Preencher"} tamanho={110}  onClick={openScreen} />
                )}
      </div>
      </div>

      </div>

    </div>
  );
};
export const CardEndereco = ({concluido, estilo, dados, openScreen}) => {
  const service = window.location.pathname.replace("/autoservico/", "").split("/");

  return (
    <div style={{ borderColor: concluido ? "#31B500" : "#c9c9c9"}} className={S.cardMain}>
      <div style={{ backgroundColor: concluido ? "#31B500" : "#c9c9c9"}} className={MainStyle.badgeDados}>
        <span>{concluido ? "CONCLUÍDO" : "PENDENTE"}</span>
      </div>
      <div className={S.row} style={{ gap: 10, marginBottom: 10}}>
        <span className={S.title}>ENDEREÇO</span>
      </div>

      <div className={S.row}>
        <div style={{ minWidth: 120}}><span className={MainStyle.text15}>CEP:</span></div>
          <span className={MainStyle.text15}>{dados?.cep && maskCEP(dados?.cep?.padStart("8", 0))}</span>
      </div>
      <div className={S.row}>
        <div style={{ minWidth: 120}}><span className={MainStyle.text15}>Logradouro:</span></div>
          <span className={MainStyle.text15}>{dados?.logradouro}, {dados?.numero}</span>
      </div>
      <div className={S.row}>
        <div style={{ minWidth: 120}}><span className={MainStyle.text15}>Complemento:</span></div>
          <span className={MainStyle.text15}>{dados?.complemento}</span>
      </div>
      <div className={S.row}>
        <div style={{ minWidth: 120}}><span className={MainStyle.text15}>Bairro:</span></div>
          <span className={MainStyle.text15}>{dados?.bairro}</span>
      </div>
      <div className={S.row}>
        <div style={{ minWidth: 120}}><span className={MainStyle.text15}>Cidade - Estado:</span></div>
          <span className={MainStyle.text15}>{dados?.cidade} - {dados?.uf}</span>
      </div>
      <div className={S.row}>
        <div style={{ minWidth: 120}}><span className={MainStyle.text15}>Comprovante:</span></div>
          <span className={MainStyle.text15}>{dados?.comprovante ? "Enviado" : "Pendente"}</span>
      </div>
      <div className={S.row} style={{ flex: 1, justifyContent:"flex-end" }}>
        {!concluido ? (
                  <div onClick={openScreen} className={S.editarBox} style={{ color: estilo?.primary, gap: 10, cursor: "pointer" }}>
                    <ButtonOutlined estilo={estilo} texto={"Preencher"} tamanho={110} />
                  </div>
        ) : (
        <div onClick={openScreen} className={S.editarBox} style={{ color: estilo?.primary, gap: 10, cursor: "pointer" }}>
        <ButtonOutlined estilo={estilo} texto={"Editar"} tamanho={110} onClick={openScreen} />
        </div>
        )}
      </div>
    </div>
  );
};
export const CardDadosBancarios = ({concluido, estilo, dados, openScreen}) => {
  const service = window.location.pathname.replace("/autoservico/", "").split("/");

  return (
    <div style={{ borderColor: concluido ? "#31B500" : "#c9c9c9"}} className={S.cardMain}>
      <div style={{ backgroundColor: concluido ? "#31B500" : "#c9c9c9"}} className={MainStyle.badgeDados}>
        <span>{concluido ? "CONCLUÍDO" : "PENDENTE"}</span>
      </div>
      <div className={S.row} style={{ gap: 10, marginBottom: 10}}>
        <span className={S.title}>DADOS BANCÁRIOS</span>
      </div>

      <div className={S.row}>
        <div style={{ minWidth: 120}}><span className={MainStyle.text15}>Banco:</span></div>
          <span className={MainStyle.text15}>{dados?.idBanco} - {dados?.banco}</span>
      </div>
      <div className={S.row}>
        <div style={{ minWidth: 120}}><span className={MainStyle.text15}>Agência:</span></div>
          <span className={MainStyle.text15}>{dados?.agencia}</span>
      </div>
      <div className={S.row}>
        <div style={{ minWidth: 120}}><span className={MainStyle.text15}>Conta:</span></div>
          <span className={MainStyle.text15}>{dados?.conta}-{dados?.digitoConta}</span>
      </div>
      <div className={S.row}>
        <div style={{ minWidth: 120}}><span className={MainStyle.text15}>Tipo Conta:</span></div>
          <span className={MainStyle.text15}>{dados?.tipoConta}</span>
      </div>
      <div className={S.row}>
        <div style={{ minWidth: 120}}><span className={MainStyle.text15}>Chave PIX:</span></div>
          <span className={MainStyle.text15}>{dados?.chavePIX}</span>
      </div>
      <div className={S.row}>
        <div style={{ minWidth: 120}}><span className={MainStyle.text15}>Tipo PIX:</span></div>
          <span className={MainStyle.text15}>{dados?.tipoChavePIX}</span>
      </div>

      <div className={S.row} style={{ flex: 1, justifyContent:"flex-end" }}>
        {concluido ? (
                  <div onClick={openScreen} className={S.editarBox} style={{ color: estilo?.primary, gap: 10, cursor: "pointer" }}>
                    <ButtonOutlined estilo={estilo} texto={"Preencher"} tamanho={110} />
                  </div>
        ) : (
        <div onClick={openScreen} className={S.editarBox} style={{ color: estilo?.primary, gap: 10, cursor: "pointer" }}>
        <ButtonOutlined estilo={estilo} texto={"Editar"} tamanho={110} onClick={openScreen} />
        </div>
        )}
      </div>
    </div>
  );
};
export const CardDadosRepresentante = ({concluido, estilo, dados, openScreen}) => {
  const service = window.location.pathname.replace("/autoservico/", "").split("/");

  return (
    <div style={{ borderColor: concluido ? "#31B500" : "#c9c9c9"}} className={S.cardMain}>
      <div style={{ backgroundColor: concluido ? "#31B500" : "#c9c9c9"}} className={MainStyle.badgeDados}>
        <span>{concluido ? "CONCLUÍDO" : "PENDENTE"}</span>
      </div>
      <div className={S.row} style={{ gap: 10, marginBottom: 10}}>
        <span className={S.title}>DADOS DO REPRESENTANTE</span>
      </div>

      <div className={S.row}>
        <div style={{ minWidth: 120}}><span className={MainStyle.text15}>Nome:</span></div>
          <span className={MainStyle.text15}>{dados?.nomeRepresentante}</span>
      </div>
      <div className={S.row}>
        <div style={{ minWidth: 120}}><span className={MainStyle.text15}>CPF:</span></div>
          <span className={MainStyle.text15}>{dados?.cpfRepresentante && formatCpfCnpj(dados?.cpfRepresentante?.padStart(11, "0"))}</span>
      </div>
      <div className={S.row}>
        <div style={{ minWidth: 120}}><span className={MainStyle.text15}>Data de Nasc.:</span></div>
          <span className={MainStyle.text15}>{dados?.dataNascimentoRepresentante}</span>
      </div>
      <div className={S.row}>
        <div style={{ minWidth: 120}}><span className={MainStyle.text15}>E-mail:</span></div>
          <span className={MainStyle.text15}>{dados?.emailRepresentante}</span>
      </div>
      {/* <div className={S.row}>
        <div style={{ minWidth: 120}}><span className={MainStyle.text15}>Contrato Social:</span></div>
        {dados?.contratoSocial ? (
          <div className={S.row}>
             <span className={MainStyle.text15}>{"Enviado"}</span>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center", flex: 1, justifyContent: "space-between"}}>
            <span className={MainStyle.text15}>{"Pendente"}</span>
            <ButtonOutlined estilo={estilo} texto={"Enviar"} tamanho={110} />
          </div>
        )}
         
      </div> */}

      <div className={S.row} style={{ flex: 1, justifyContent:"flex-end" }}>
        {concluido ? (
                  <div onClick={openScreen} className={S.editarBox} style={{ color: estilo?.primary, gap: 10, cursor: "pointer" }}>
                    <ButtonOutlined estilo={estilo} texto={"Preencher"} tamanho={110} />
                  </div>
        ) : (
        <div onClick={openScreen} className={S.editarBox} style={{ color: estilo?.primary, gap: 10, cursor: "pointer" }}>
        <ButtonOutlined estilo={estilo} texto={"Editar"} tamanho={110} onClick={openScreen} />
        </div>
        )}
      </div>
    </div>
  );
};
