import React, { useEffect, useState } from "react";
import { BaseDesktop, BaseMobile } from "../components/common/Base";
import S from "../Autoservico.module.css";
import { InfoDefault } from "../components/common/Info";
import { CotaCard } from "../components/Cards/CotaCard";
import LoadingCustom from "../../../components/LoadingCustom";
import { Checkbox } from "@mui/material";
import { PropostaCard } from "../components/Cards/PropostaCard";
import { ModalAutoServico, ModalStandard } from "../components/common/ModalAutoServico";
import { FinalizarEscolherConta } from "./Forms";
import api from "../../../services/api";
import { useSelector } from "react-redux";
import { idAdm } from "./detalhes";
import moment from "moment";
import { FaCheckCircle } from "react-icons/fa";
import { ButtonMain } from "../components/buttons";
import { V3Modal } from "../../../styles/themes/components/modal";



export const DetalheCota = ({ estilo }) => {
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const service = window.location.pathname.replace("/autoservico/", "").split("/");
  const adm = service[0];
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [loading, setLoading] = useState(false);
  const [perguntar, setPerguntar] = useState(false);
  const [ cota, setCota ] = useState("");
  const [ aceitou, setAceitou ] = useState(false);
  const [ propostaSelecionada, setPropostaSelecionada] = useState("");
  const [ finalizar, setFinalizar ] = useState(false);
  const state = useSelector(state => state.autoservico);
  function handleNovaConta() {
    window.location.href = `/autoservico/${service[0]}/meus-dados/dados-bancarios`;
  }

  async function handleGetCota(){
    const indice = parseInt(service[2]);
    await api
    .get(
      `/api/concash/1.0/consorcio-grupo-cota/negociacoes/${service[2]}?cpfCnpj=${state.cpfCnpj.replace(/\D/g, "")}&idAdministradora=${idAdm[adm]}&Tokenconsort=${state?.otp}`, {
        headers: {
          TokenConsort: state.tokenConsort
        }
      }).then(({data}) => {
        setAceitou(data?.statusAtendimento == 11 ? true : false);
        
      setCota({
        id: indice,
        idAtendimento: data?.idAtendimento,
        adm: data?.administradora?.replace("Assurance", "Seguradora"),
        grupo: data?.grupo,
        cota: data?.cota,
        contrato: data?.contrato,
        valorCredito: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(data?.valorDoBem)),
        dataEncerramento: moment(data?.dataEncerramentoGrupo).format("DD/MM/YYYY"),
        propostas: data?.propostas.map((p, i) => { return {id: i, prazo: p.prazo, proposta: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(p.valorProposta)), desagio: p.desagioProposta, dias: 10, melhorProposta: p.melhorProposta }}) ,
      });
      return setLoading(true);
    })
    .catch((e) => {
      setLoading(false);
      setErrorText("Não foi possível carregar a cota solicitada.")
      setHasError(true);
    })

  }

  useEffect(() => {
    handleGetCota();
  }, [])

  if (estilo && cota) {
    return (
      <div>
        {isMobile ? (
          <>
          <FinalizarEscolherConta idAtendimento={cota?.idAtendimento} estilo={estilo}  open={finalizar} contas={[]} closeScreen={() => setFinalizar(false)}  novaConta={handleNovaConta} />

          <ModalAutoServico proposta={propostaSelecionada}  estilo={estilo} onClick={() => window.location.href = `/autoservico/${service[0]}/meus-dados`} />
          <ModalStandard  open={perguntar} estilo={estilo} title={"Entenda o valor da venda"} onClose={() => setPerguntar(false)} texto={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla tempus tortor vitae finibus lacinia. Morbi commodo euismod massa. Aenean sit amet ligula id dolor blandit congue eu eget erat"}  />
            <BaseMobile estilo={estilo}>
              <div className={S.contentMainMobile}>
              <h1 className={S.titlePage} style={{ textAlign: 'center' }}>{cota.adm.replace("Assurance", "Seguradora")}</h1>
              <h3 className={S.subtitle18} style={{ textAlign: 'center' }}>{cota.propostas.length} proposta{cota.propostas.length > 1 && "s"}</h3>

              </div>
              <div className={S.contentCota}>
              <div className={S.cotaDados}>
                <div style={{ display: "flex"}}>
                  <div style={{ minWidth: 84, fontWeight: 'bold', fontSize: 15}}><span>Grupo</span></div>
                  <span>{cota.grupo}</span>
                </div>
                <div style={{ display: "flex"}}>
                  <div style={{ minWidth: 84, fontWeight: 'bold', fontSize: 15}}><span>Cota</span></div>
                  <span>{cota.cota}</span>
                </div>
                <div style={{ display: "flex"}}>
                  <div style={{ minWidth: 84, fontWeight: 'bold', fontSize: 15}}><span>Contrato</span></div>
                  <span>{cota.contrato}</span>
                </div>
              </div>
                <div style={{ display: "flex", alignItems:  'center'}}>
                  <div className={S.detalheCota1}>
                    <p style={{ fontWeight:"bold"}}>Valor a receber no final do grupo:</p>
                    <p>{cota.valorCredito} em {cota.dataEncerramento}</p>
                  </div>
                </div>

              </div>
              {!aceitou ? (
              <div>
                {cota.propostas.length && (<h1 className={S.subtitlePage} style={{ textAlign: 'center' }}>Selecione a proposta para vender:</h1>)}
                {cota.propostas.length && cota.propostas.map((ct) => (
                  <PropostaCard propostaSelecionada={propostaSelecionada} setPropostaSelecionada={setPropostaSelecionada} estilo={estilo} ct={ct} />
                )
                )}
              </div>
              ) : (
                <div className={S.cotaContentBox}>
                <div style={{ display: "flex", alignItems: "center", gap: 6}}>
                  <FaCheckCircle color="#00B423" size={24}/> <span className={S.title18}>Você aceitou a oferta de {cota?.propostas[0]?.proposta}</span>
                </div>
                <div  style={{ display: "flex", marginTop: 30}}>
                <ButtonMain estilo={estilo} texto={"Verificar Assinaturas"} tamanho={"100%"} onClick={() => window.location.href = `/autoservico/${service[0]}/assinar/${service[2]}`} disabled={false} />
                </div>
              </div>
              )}

            </BaseMobile>
          </>
        ) : (
          <BaseDesktop estilo={estilo}>
            <FinalizarEscolherConta estilo={estilo} cota={cota} open={finalizar} closeScreen={() => setFinalizar(false)}  novaConta={handleNovaConta} />

            <ModalAutoServico idAtendimento={cota?.idAtendimento} proposta={propostaSelecionada}  estilo={estilo}   />
            <div className={S.flexColumn} style={{ width: "100%", marginRight:83 }}>
              <h1 className={S.titlePage} style={{ textAlign: 'left' }}>{cota.adm}</h1>
              <h3 className={S.text18}>{cota.propostas.length} proposta{cota.propostas.length > 1 && "s"}</h3>
              <div style={{ display: "flex" }}>
              <div className={S.contentCota}>
                <div className={S.cotaDados}>
                  <div style={{ display: "flex"}}>
                    <div style={{ minWidth: 84, fontWeight: 'bold', fontSize: 15}}><span>Grupo</span></div>
                    <span>{cota.grupo}</span>
                  </div>
                  <div style={{ display: "flex"}}>
                    <div style={{ minWidth: 84, fontWeight: 'bold', fontSize: 15}}><span>Cota</span></div>
                    <span>{cota.cota}</span>
                  </div>
                  <div style={{ display: "flex"}}>
                    <div style={{ minWidth: 84, fontWeight: 'bold', fontSize: 15}}><span>Contrato</span></div>
                    <span>{cota.contrato}</span>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems:  'center', gap: 10, lineHeight:0}}>
                  <div className={S.detalheCota1}>
                    <span style={{ fontWeight:"bold"}}>Valor a receber no final do grupo: </span>{" "}
                    <span>{cota.valorCredito} em {cota.dataEncerramento}</span>
                  </div>
                </div>

              </div>

              </div>
              {!aceitou ? (
              <div className={S.cotaContentBox}>
                <div className={S.cotaRow}>
                  <h2 className={S.title18}>Selecione a proposta para vender:</h2>
                </div>
                <div className={S.cotaListRow}>
                  {cota.propostas.length && cota.propostas.map((ct) => (
                    <PropostaCard propostaSelecionada={propostaSelecionada} setPropostaSelecionada={setPropostaSelecionada} estilo={estilo} ct={ct} />
                  )) }
                </div>
              </div>

              ) : (
                <div className={S.cotaContentBox}>
                  <div style={{ display: "flex", alignItems: "center", gap: 6}}>
                    <FaCheckCircle color="#00B423" size={24}/> <span className={S.title18}>Você aceitou a oferta de {cota?.propostas[0]?.proposta}</span>
                  </div>
                  <div style={{ display: "flex", marginTop: 30}}>
                  <ButtonMain estilo={estilo} texto={"Verificar Assinaturas"} tamanho={"350px"} onClick={() => window.location.href = `/autoservico/${service[0]}/assinar/${service[2]}`} disabled={false} />
                  </div>
                </div>
              )}
            </div>
          </BaseDesktop>
        )}
      </div>
    );
  } else {
    if(hasError) {
      <V3Modal
        open={hasError}
        actionClose={() => {
          setHasError(false);
          return window.location.href = "/autoservico/"+service[0]+"/minhas-cotas"
        }}
        title={errorText}
        customButtonColor={estilo?.primary}
        type="warning"
      />
    }
   return <LoadingCustom open />
  }
};
