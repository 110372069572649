import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";



export class FormImpostoPercentual extends Component {



  constructor(props){

    super(props);

    this.state = {
          codigo: '',
          percentual: '',
          impostoCodigo: '',
          baseCodigo: '',
          regimeTributarioCodigo: '',
          Impostos: [],
          Bases: [],
          Regimes: [],
          labelForm: '',
          labelButton: '',
          error: "",
          action: ""
    };
  }


  async componentDidMount() {

    if(this.props[0].location.state !== undefined){
        const impostoPercentual  = this.props[0].location.state.impostoPercentual
        this.setState({percentual: impostoPercentual.percentual,codigo: impostoPercentual.codigo,impostoCodigo: impostoPercentual.imposto.codigo,baseCodigo: impostoPercentual.base.codigo,regimeTributarioCodigo: impostoPercentual.regimeTributario.codigo});
        this.setState({labelForm: "Editar Imposto Percentual",labelButton: "Editar",action: "editar"});
    }else{
        this.setState({labelForm: "Adicionar Imposto Percentual",labelButton: "Adicionar",action: "adicionar"});
    }


    try {
          const response = await api.post("api/concash/1.0/imposto/listar", {});
          this.setState({ Impostos: response.data});
    } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter os impostos."
          });
    }

    try {
          const response = await api.post("api/concash/1.0/imposto-regime-tributario/listar", {});
          this.setState({ Regimes: response.data});
    } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter os regimes tributários."
          });
    }

    try {
          const response = await api.post("api/concash/1.0/imposto-base/listar", {});
          this.setState({ Bases: response.data});
    } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter os impostos bases."
          });
    }


  }

  handleForm = async e => {
    e.preventDefault();
    const { action } = this.state;
    const { percentual }   = this.state;
    const { impostoCodigo }   = this.state;
    const { baseCodigo }   = this.state;
    const { regimeTributarioCodigo }   = this.state;
    const { codigo } = this.state;
    if (!percentual
        || !impostoCodigo
        || !baseCodigo
        || !regimeTributarioCodigo
        || !action) {
      this.setState({ error: "Preencha os campos obrigatórios para continuar!" });
    } else {
      try {

        if(action == "editar"){
          const response = await api.put("api/concash/1.0/imposto-percentual/"+action, {
              codigo,percentual,impostoCodigo,baseCodigo,regimeTributarioCodigo
            });
        }else{
          const response = await api.post("api/concash/1.0/imposto-percentual/"+action, {
              percentual,impostoCodigo,baseCodigo,regimeTributarioCodigo
            });
        }

        this.props.history.push("/imposto-percentual");

      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o processamento da requisição."
        });
      }
    }
  };

  render() {


    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> {this.state.labelForm} </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>

        <div className="row">

          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"></h4>
                {this.state.error && <p className="card-description">{this.state.error}</p>}
                <form className="forms-sample" onSubmit={this.handleForm}>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group className="row" >
                      <label className="col-sm-5 col-form-label">Imposto</label>
                      <div className="col-sm-7">
                        <select className="form-control" value={this.state.impostoCodigo} onChange={e => this.setState({ impostoCodigo: e.target.value })}>
                          <option value="" key={0}>Selecione</option>
                          {this.state.Impostos.map((imposto,index) => (
                            <option value={imposto.codigo} key={index}>{imposto.imposto}</option>
                          ))}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group className="row" >
                      <label className="col-sm-5 col-form-label">Base</label>
                      <div className="col-sm-7">
                        <select className="form-control" value={this.state.baseCodigo} onChange={e => this.setState({ baseCodigo: e.target.value })}>
                          <option value="" key={0}>Selecione</option>
                          {this.state.Bases.map((base,index) => (
                            <option value={base.codigo} key={index}>{base.base}</option>
                          ))}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group className="row" >
                      <label className="col-sm-5 col-form-label">Regime Tributário</label>
                      <div className="col-sm-7">
                        <select className="form-control" value={this.state.regimeTributarioCodigo} onChange={e => this.setState({ regimeTributarioCodigo: e.target.value })}>
                          <option value="" key={0}>Selecione</option>
                          {this.state.Regimes.map((regime,index) => (
                            <option value={regime.codigo} key={index}>{regime.regime}</option>
                          ))}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Percentual</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="percentual" value={this.state.percentual} placeholder="Percentual" onChange={e => this.setState({percentual: e.target.value })} size="sm" />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="text-right">
                    <button type="submit" className="btn btn-primary mr-2">{this.state.labelButton}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
         </div>
       </div>
    )
  }
}

export default withRouter(FormImpostoPercentual)
