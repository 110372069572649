import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";
import { AlteraNome } from '../../actions';




export class TelaFinanceiraInicial extends Component {


  constructor(props){
        super(props);
        this.state = {
          show: false
        };
  }

  async componentDidMount() {

  }

  handleSendDemanda    = async e => {
      e.preventDefault();
      this.props.history.push("/financeiro/demanda");
  };

  handleInclusaoValores = async e => {
      e.preventDefault();
      this.props.history.push("/financeiro/inclusao-valores");
  };

  handleOrdemPagamento = async e => {
      e.preventDefault();
      this.props.history.push("/financeiro/lista-ordem-pagamento");
  };

  handlePagamentosAutorizados = async e => {
      e.preventDefault();
      this.props.history.push("/financeiro/lista-pagamentos-autorizados");
  };

  handleCadastroLances = async e => {
      e.preventDefault();
      this.props.history.push("/financeiro/lista-cadastro-lances");
  };

  handleCadastroParcelas = async e => {
      e.preventDefault();
      this.props.history.push("/financeiro/lista-cadastro-parcelas");
  };

  handleAssembleias = async e => {
      e.preventDefault();
      this.props.history.push("/financeiro/lista-assembleias");
  };



  render() {


    return (

      <div>
        <div className="page-header">
          <h3 className="page-title"> Financeiro </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">

                    <div className="col-md-4" style={{"text-align":"center"}}>
                       <button type="button" className="btn btn-primary mr-2" style={{"width":"80%","height":"100px"}} onClick={e =>this.handleCadastroLances(e)}>LANCES</button>
                    </div>

                    <div className="col-md-4" style={{"text-align":"center"}}>
                       <button type="button" className="btn btn-primary mr-2" style={{"width":"80%","height":"100px"}} onClick={e =>this.handleOrdemPagamento(e)}>ORDEM DE PAGAMENTO</button>
                    </div>

                    <div className="col-md-4" style={{"text-align":"center"}}>
                       <button type="button" className="btn btn-primary mr-2" style={{"width":"80%","height":"100px"}} onClick={e =>this.handleInclusaoValores(e)}>INCLUSÃO DE VALORES</button>
                    </div>

                </div>

                <div className="row" style={{"marginTop":"20px"}}>

                    <div className="col-md-4" style={{"text-align":"center"}}>
                       <button type="button" className="btn btn-primary mr-2" style={{"width":"80%","height":"100px"}} onClick={e =>this.handlePagamentosAutorizados(e)}>PAGAMENTOS</button>
                    </div>

                    <div className="col-md-4" style={{"text-align":"center"}}>
                       <button type="button" className="btn btn-primary mr-2" style={{"width":"80%","height":"100px"}} onClick={e =>this.handleCadastroParcelas(e)}>PARCELAS</button>
                    </div>

                    <div className="col-md-4" style={{"text-align":"center"}}>
                       <button type="button" className="btn btn-primary mr-2" style={{"width":"80%","height":"100px"}} onClick={e =>this.handleAssembleias(e)}>ASSEMBLÉIAS</button>
                    </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(TelaFinanceiraInicial);
