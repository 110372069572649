import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import api from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import { Link, withRouter } from "react-router-dom";
import { Card } from "../../pages/Backoffice/styles";

export class Cota extends Component {
  constructor(props) {
    super(props);

    const { cliente, origem } = this.props;

    this.state = {
      codigoCliente: cliente.codigo,
      obj_cliente: cliente,
      cotas: [],
      origem: origem,
      error: "",
      isMobile: false,
    };
  }

  async componentDidMount() {
    this.setState({ isMobile: window.innerWidth <= 800 });

    this.obtemCotas(this.state.codigoCliente);
  }

  async obtemCotas(codigo) {
    try {
      const response = await api.post("api/concash/1.0/cliente/cotas", {
        codigo: codigo,
      });
      this.setState({ cotas: response.data });
    } catch (err) {
      this.setState({
        error: err.response.data,
      });
    }
  }

  render() {
    return (
      <div className="row">
        {this.state.isMobile ? (
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <h4 className="card-title">Cotas</h4>

              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th> Grupo </th>
                      <th> Cota </th>
                      <th> Valor </th>
                      <th> Status </th>
                      <th className="text-center"> Ação </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.cotas.map((cota, index) => (
                      <tr key={index}>
                        <td> {cota.grupo.grupo} </td>
                        <td> {cota.contrato} </td>
                        <td> {cota.valorCredito} </td>
                        <td> {cota.situacao} </td>
                        <td className="text-center">
                          {this.state.origem == "CLIENTE" && (
                            <Link
                              to={{
                                pathname: "/consorcio-grupo-cota/detalhe",
                                state: {
                                  cota: cota,
                                  cliente: cota.cliente,
                                },
                              }}
                              className="m-3 btn btn-sm btn-success"
                            >
                              Detalhe
                            </Link>
                          )}
                          {this.state.origem == "BACKOFFICE" && (
                            <Link
                              to={{
                                pathname:
                                  "/backoffice/detalhamento-cota-backoffice",
                                state: {
                                  codigo: cota.codigo,
                                },
                              }}
                              className="m-3 btn btn-sm btn-success"
                            >
                              Detalhe
                            </Link>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="text-center" colspan="5">
                        <Card>
                          <Link
                            to={{
                              pathname: "/upload-cota",
                              state: {
                                cliente: this.state.obj_cliente,
                              },
                            }}
                            className="m-3 btn btn-sm btn-success"
                          >
                            Cadastrar Cota
                          </Link>
                        </Card>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Cotas</h4>
                <p className="card-description"></p>

                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th> Grupo </th>
                        <th> Cota </th>
                        <th> Valor </th>
                        <th> Status </th>
                        <th className="text-center"> Ação </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.cotas.map((cota, index) => (
                        <tr key={index}>
                          <td> {cota.grupo.grupo} </td>
                          <td> {cota.contrato} </td>
                          <td> {cota.valorCredito} </td>
                          <td> {cota.situacao} </td>
                          <td className="text-center">
                            {this.state.origem == "CLIENTE" && (
                              <Link
                                to={{
                                  pathname: "/consorcio-grupo-cota/detalhe",
                                  state: {
                                    cota: cota,
                                    cliente: cota.cliente,
                                  },
                                }}
                                className="m-3 btn btn-sm btn-success"
                              >
                                Detalhe
                              </Link>
                            )}
                            {this.state.origem == "BACKOFFICE" && (
                              <Link
                                to={{
                                  pathname:
                                    "/backoffice/detalhamento-cota-backoffice",
                                  state: {
                                    codigo: cota.codigo,
                                  },
                                }}
                                className="m-3 btn btn-sm btn-success"
                              >
                                Detalhe
                              </Link>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className="text-center" colspan="5">
                          <Card>
                            <Link
                              to={{
                                pathname: "/upload-cota",
                                state: {
                                  cliente: this.state.obj_cliente,
                                },
                              }}
                              className="m-3 btn btn-sm btn-success"
                            >
                              Cadastrar Cota
                            </Link>
                          </Card>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Cota;
