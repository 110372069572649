// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row-mobile {
    display: flex;
    flex: 1 1;
    margin: 12px 5px;
}

.input-flex {
    display: flex;
    flex: 1 1;
}

.section-mobile {
    padding: 15px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Endereco/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAO;IACP,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,SAAO;AACX;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".row-mobile {\n    display: flex;\n    flex: 1;\n    margin: 12px 5px;\n}\n\n.input-flex {\n    display: flex;\n    flex: 1;\n}\n\n.section-mobile {\n    padding: 15px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
