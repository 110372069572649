import { Checkbox } from '@mui/material';
import React from 'react';
import S from "../../../Autoservico.module.css";
import { MdCalendarToday } from "react-icons/md";
import { FaArrowDown } from 'react-icons/fa';

export const PropostaCard = ({propostaSelecionada, setPropostaSelecionada, estilo, ct}) => {
  return (
    <div style={{ borderColor: propostaSelecionada.id === ct.id && estilo.primary }} className={propostaSelecionada.id === ct.id ? S.boxCotaPropostaSelected : S.boxCotaProposta}>
                      <div className={S.boxCotaPropostaRow1}>
                        {ct.melhorProposta && <div className={S.badge} style={{ backgroundColor: estilo.primary }}><span>MELHOR PROPOSTA</span></div>}
                        <span className={S.boxCotaPropostaSelec}>Selecionar</span>
                        <Checkbox 
                        sx={{
                          position: "relative",
                          zIndex: 400,
                          color: estilo.secondary,
                          '&.Mui-checked': {
                            color:  estilo.primary,
                          },
                        }}
                         checked={propostaSelecionada.id === ct.id} onChange={() => {
                          if(propostaSelecionada.id == ct.id) {
                            setPropostaSelecionada("");
                          } else {
                            setPropostaSelecionada(ct)
                          }
                          }} />
                      </div>
                      <div className={S.boxCotaPropostaRow2}>
                        <h1 className={S.subtitlePage} style={{ textAlign: 'center' }}>Proposta</h1>
                      </div>
                      <div  className={S.boxCotaPropostaRow3}>
                        <h3 className={S.subtitle24}>{ct.proposta}</h3>
                      </div>
                      <div className={S.boxCotaPropostaRow4}>
                          <div className={S.subRow4}>
                          <MdCalendarToday size={24} />
                            <div className={S.boxTextSubRow}>
                              <span className={S.boxTextSubRow}>Pagamento em</span>
                              <span className={S.boxTextBoldSubRow}>10 dias</span>
                            </div>
                          </div>
                          <div className={S.subRow4}>
                          <FaArrowDown size={24} />
                            <div className={S.boxTextSubRow}>
                              <span className={S.boxTextSubRow}>Deságio</span>
                              <span className={S.boxTextBoldSubRow}>{ct.desagio}</span>
                            </div>
                          </div>
                          <div></div>
                      </div>
                    </div>
  );
}
