import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import api from "../../services/api";
import { Link } from "react-router-dom";
import SweetAlert from 'sweetalert2-react';
import Swal from 'sweetalert2'



export class BasicElements extends Component {



  constructor(){
        super();
        this.state = {
          cessionarias: [],
          show: false
        };
  }

  async componentDidMount() {

        try {
          const response = await api.post("api/concash/1.0/cessionaria/listar", {});
          this.setState({ cessionarias: response.data});
        } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter os cessionaria."
          });
        }
  }

  handleDeletar = async (codigo,e) => {

    var classe = this;
    Swal.fire({
       title: 'Você tem certeza que deseja excluir esse registro?',
       text: 'Após exclusão não será possivel a reversão',
       icon: 'warning',
       showCancelButton: true,
       confirmButtonColor: '#3085d6',
       cancelButtonColor: '#d33',
       confirmButtonText: 'Confirmar!'
    }).then((result) => {
       if(result.value){
         classe.handleDeletarEfetivar(codigo,e);
      }
    })
  };

  handleDeletarEfetivar = async (codigo,e) => {

      e.preventDefault();
      if (!codigo) {
        this.setState({ error: "Código indefinido!" });
      } else {
        try {
           const response = await api.put("api/concash/1.0/cessionaria/excluir", {
                codigo
           });
        } catch (err) {
          this.setState({
            error:
              "Houve um problema com a exclusão."
          });
        }
      }
  };

  render() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Cessionárias </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                  <p className="card-description text-right">
                                <Link
                                  to={{pathname: "/cessionaria/detalhe"}}
                                  className="m-3 btn btn-sm btn-success"
                                >
                                Cadastrar
                      </Link></p>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th> Nome Fantasia</th>
                          <th> Razão Social </th>
                          <th> CNPJ </th>
                          <th className="text-center"> Ação </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.cessionarias.map((cessionaria,index) => (
                        <tr key={index}>
                          <td> {cessionaria.nomeFantasia} </td>
                          <td> {cessionaria.razaoSocial} </td>
                          <td> {cessionaria.cnpj} </td>
                          <td className="text-center"><Link
                                          to={{pathname: "/cessionaria/detalhe",state: {
                                            cessionaria: cessionaria
                                          }}}
                                          className="m-3 btn btn-sm btn-success"
                                        >
                                        Editar
                              </Link>
                              <Link
                                          to={{pathname: "/cessionaria/configurar",state: {
                                                cessionaria: cessionaria
                                              }}}
                                              className="m-3 btn btn-sm btn-success"
                                            >
                                            Configurar
                              </Link>
                              <Link
                                className="m-3 btn btn-sm btn-danger"
                                onClick={e =>this.handleDeletar(cessionaria.codigo,e)}
                              >
                                Deletar
                              </Link>
                          </td>
                        </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BasicElements
