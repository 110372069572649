// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-box {
    text-align: left;
    font: normal normal normal 15px/20px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.text-label {
    text-align: left;
    font: normal normal normal 15px/20px Roboto;
    letter-spacing: 0px;
    color: #979797;
    opacity: 1;
}

.text-header-label {
    text-align: left;
    font: normal normal normal 11px/15px Roboto;
    letter-spacing: 0px;
    color: #979797;
    opacity: 1;
}

.input-personalizado {
    cursor: pointer;
}

.gradient-box {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent linear-gradient(180deg, #ffffff 0%, #dfdfdf 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px #00000066;
    border-radius: 5px;
    width: 117px;
    height: 25px;
}

.input-file {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Field/style.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,2CAA2C;IAC3C,mBAAmB;IACnB,cAAc;IACd,UAAU;AACd;;AAEA;IACI,gBAAgB;IAChB,2CAA2C;IAC3C,mBAAmB;IACnB,cAAc;IACd,UAAU;AACd;;AAEA;IACI,gBAAgB;IAChB,2CAA2C;IAC3C,mBAAmB;IACnB,cAAc;IACd,UAAU;AACd;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,qGAAqG;IACrG,iCAAiC;IACjC,kBAAkB;IAClB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".text-box {\n    text-align: left;\n    font: normal normal normal 15px/20px Roboto;\n    letter-spacing: 0px;\n    color: #000000;\n    opacity: 1;\n}\n\n.text-label {\n    text-align: left;\n    font: normal normal normal 15px/20px Roboto;\n    letter-spacing: 0px;\n    color: #979797;\n    opacity: 1;\n}\n\n.text-header-label {\n    text-align: left;\n    font: normal normal normal 11px/15px Roboto;\n    letter-spacing: 0px;\n    color: #979797;\n    opacity: 1;\n}\n\n.input-personalizado {\n    cursor: pointer;\n}\n\n.gradient-box {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: transparent linear-gradient(180deg, #ffffff 0%, #dfdfdf 100%) 0% 0% no-repeat padding-box;\n    box-shadow: 0px 1px 3px #00000066;\n    border-radius: 5px;\n    width: 117px;\n    height: 25px;\n}\n\n.input-file {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
