import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";
import { AlteraNome } from '../../actions';




export class TelaBackOfficeInicial extends Component {


  constructor(props){
        super(props);
        this.state = {
          show: false
        };
  }

  async componentDidMount() {

  }

  handleTransferenciaCota = async e => {
      e.preventDefault();
      this.props.history.push("/backoffice/transferencia-cota");
  };

  handleAguardandoDocumentacao = async e => {
      e.preventDefault();
      this.props.history.push("/backoffice/documentacoes-em-aberto");
  };

  handleKYC = async e => {
      e.preventDefault();
      this.props.history.push("/visao-cliente-bloqueado-questionario");
  };

  handlePropostasRecusadas = async e => {
    e.preventDefault();
    this.props.history.push("/visao-cliente-proposta-recusada");
  };


  render() {


    return (

      <div>
        <div className="page-header">
          <h3 className="page-title"> BackOffice </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">

                    <div className="col-md-3" style={{"text-align":"center"}}>
                       <button type="submit" className="btn btn-primary mr-2" style={{"width":"70%","height":"100px"}} onClick={e =>this.handleAguardandoDocumentacao(e)}>AGUARDANDO DOCUMENTAÇÃO</button>
                    </div>

                    <div className="col-md-3" style={{"text-align":"center"}}>
                       <button type="submit" className="btn btn-primary mr-2" style={{"width":"70%","height":"100px"}} onClick={e =>this.handleTransferenciaCota(e)}>TRANSFERÊNCIA COTA</button>
                    </div>

                    <div className="col-md-3" style={{"text-align":"center"}}>
                       <button type="submit" className="btn btn-primary mr-2" style={{"width":"70%","height":"100px"}} onClick={e =>this.handleKYC(e)}>KYC</button>
                    </div>

                    <div className="col-md-3" style={{"text-align":"center"}}>
                       <button type="submit" className="btn btn-primary mr-2" style={{"width":"70%","height":"100px"}} onClick={e =>this.handlePropostasRecusadas(e)}>Propostas Recusadas</button>
                    </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(TelaBackOfficeInicial);
