import React, { Component } from "react";
import { registerLocale } from "react-datepicker";
import moment from "moment";
import api from "../../services/api";
import ptBR from "date-fns/locale/pt-BR";
import { Link, withRouter } from "react-router-dom";
import Backward from "../../assets/backward.svg";
import "./style.css";
import {
  BoxEtapa,
  CellEtapa,
  ContainerDots,
  ContainerEtapa,
  Dots,
  DotsCheck,
  TextCell,
  TextDescricaoPasso,
  TextInfoPasso,
} from "../Backoffice/styles";
import BoasVindas from "../../assets/boas_vindas_branco.svg";
import TriagemBoasVindas from "../../assets/triagem_boas_vindas_branca.svg";
import EnvioDeProposta from "../../assets/envio_de_proposta_branca.svg";
import CadastroDoCliente from "../../assets/cadastro_do_cliente_branca.svg";
import TriagemDocumentos from "../../assets/triagem_documentos_branca.svg";
import RegistroDeProposta from "../../assets/registro_de_proposta_branca.svg";
import TriagemTransferencia from "../../assets/triagem_transferencia_branca.svg";
import Pagamento from "../../assets/pagamento_branca.svg";
import ConfirmacaoPagamento from "../../assets/confirmacao_pagamento_branca.svg";
import Checked from "../../assets/checked_verde.svg";

registerLocale("pt-BR", ptBR);

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export class ConsorcioGrupoCotaForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cota: "",
      isOpen: false,
      isMobile: false,
    };
  }

  async componentDidMount() {
    this.setState({ isOpen: true });
    this.setState({ isMobile: window.innerWidth <= 800 });
    if (this.props[0].location.state !== undefined) {
      const codigo = this.props[0].location.state.codigo;
      const response = await api.get(
        `api/concash/1.0/consorcio-grupo-cota/buscar/${codigo}`
      );
      this.setState({ cota: response.data, isOpen: false });
    } else {
      this.setState({ isOpen: false });
    }
  }

  montaLinha(titulo, valor) {
    if (null != valor && valor != "Invalid date" && valor != "null%") {
      return (
        <tr>
          <td style={{ paddingLeft: "30px", width: "20%" }}>{titulo}</td>
          <td style={{ width: "80%" }}>{valor}</td>
        </tr>
      );
    }
  }

  measures = [0, 8, 25.5, 46, 79.5, 130, 215, 400, 1190, 4000];
  measuresMobile = [0, 9.5, 25, 47.5, 77.5, 125.5, 210.5, 380.5, 1130.5, 3000];
  tooltips = [
    "",
    "Boas Vindas",
    "Triagem boas vindas",
    "Envio de proposta",
    "Cadastro do cliente",
    "Triagem documentos",
    "Registro de proposta",
    "Triagem transferência ",
    "Pagamento",
    "Confirmação de pagamento",
  ];

  tooltipsPt1 = [
    "",
    "Boas Vindas",
    "Triagem boas",
    "Envio de",
    "Cadastro do",
    "Triagem",
    "Registro de",
    "Triagem Transferência ",
    "Pagamento",
    "Confirmação de",
  ];

  tooltipsPt2 = [
    "",
    "",
    "vindas",
    "proposta",
    "cliente",
    "documentos",
    "proposta",
    "",
    "",
    "pagamento",
  ];

  iconeEtapa(idEtapa, element) {
    switch (idEtapa) {
      case 1:
        return <img src={BoasVindas} data-tooltip={element} width={25} />;
      case 2:
        return <img src={TriagemBoasVindas} />;
      case 3:
        return <img src={EnvioDeProposta} />;
      case 4:
        return <img src={CadastroDoCliente} />;
      case 5:
        return <img src={TriagemDocumentos} />;
      case 6:
        return <img src={RegistroDeProposta} />;
      case 7:
        return <img src={TriagemTransferencia} />;
      case 8:
        return <img src={Pagamento} />;
      case 9:
        return <img src={ConfirmacaoPagamento} />;
      default:
        return "-";
    }
  }

  formatarMoeda(valorCredito) {
    if (typeof valorCredito !== "undefined" && valorCredito != null) {
      return valorCredito.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    }
  }

  render() {
    return (
      <>
        {this.state.isMobile ? (
          <>
            <div className="row-mobile" style={{ marginTop: "0px" }}>
              <div
                className="col-sm-1 col-1 m-auto"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Link to={{ pathname: "/consorcio-grupo-cota", state: {} }}>
                  <img src={Backward} />
                </Link>
              </div>
              <div className="col-sm-11 col-11 m-auto" align="center">
                <span
                  style={{
                    font: "normal normal bold 24px/30px Roboto",
                    marginLeft: "-19px",
                    letterSpacing: "0px",
                    color: "#000000",
                  }}
                >
                  {" "}
                  Detalhes da cota{" "}
                </span>
              </div>
            </div>
            <div
              className="row-mobile"
              style={{ marginTop: "20px", textAlign: "center" }}
            >
              <div className="col-sm-12 col-12 m-auto">
                <span style={{ font: "normal normal normal 21px/25px Roboto" }}>
                  {" "}
                  {this.state.cota.cgcSadNome}{" "}
                </span>
              </div>
            </div>
            <div
              style={{
                height: "230px",
                width: "130%",
                overflowX: "auto",
                marginLeft: "-25px",
                paddingRight: 0,
                whiteSpace: "nowrap",
                backgroundColor: "#DFF7FA",
              }}
            >
              <div
                className="row"
                style={{
                  marginTop: "10px",
                  marginLeft: "10px",
                }}
              >
                <div className="col-md-12">
                  <span
                    style={{
                      font: "normal normal normal 12px/14px Roboto",
                      opacity: "0.5",
                    }}
                  >
                    {" "}
                    PROGRESSO DA SUA COTA
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "60em",
                  display: "flex",
                  flex: 1,
                  marginLeft: "10px",
                  marginRight: "50px",
                  paddingRight: 30,
                  justifyContent: "space-between",
                }}
              >
                <ContainerDots>
                  {this.tooltips.map((element, index) => {
                    if (index === 0) {
                      return;
                    } else {
                      if (index <= this.state.cota.cgcEtapa) {
                        return (
                          <>
                            <div align="center" style={{ marginLeft: 15 }}>
                              <img src={Checked} width={15} />
                            </div>
                            <DotsCheck
                              data-tooltip={element}
                              style={{ marginLeft: -100, marginTop: "-15px" }}
                            >
                              {this.iconeEtapa(index, element)}
                            </DotsCheck>
                          </>
                        );
                      } else if (index > this.state.cota.cgcEtapa) {
                        return (
                          <>
                            <div align="center" style={{ marginLeft: 15 }}>
                              <img
                                src={Checked}
                                width={15}
                                style={{ opacity: "0.0" }}
                              />
                            </div>
                            <Dots
                              data-tooltip={element}
                              style={{ marginLeft: -100, marginTop: "-15px" }}
                            >
                              {this.iconeEtapa(index, element)}
                            </Dots>
                          </>
                        );
                      }
                    }
                  })}
                </ContainerDots>
              </div>
              <div
                style={{
                  width: "60em",
                  flex: 1,
                  marginLeft: "10px",
                  justifyContent: "space-between",
                }}
              >
                <ContainerEtapa>
                  <div
                    style={{
                      width: `${
                        this.measuresMobile[this.state.cota.cgcEtapa]
                      }%`,
                    }}
                    className="progress-bar-cota"
                  ></div>
                  <div className="progress-bar-cota-big"></div>
                </ContainerEtapa>
              </div>
              <div
                style={{
                  width: "60em",
                  display: "flex",
                  flex: 1,
                  marginLeft: "5px",
                  marginRight: "80px",
                  marginTop: 10,
                  marginBottom: 30,
                  justifyContent: "space-between",
                }}
              >
                {this.tooltipsPt1.map((element, index) => {
                  if (index != 0) {
                    return (
                      <CellEtapa
                        key={index}
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <TextDescricaoPasso
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          {element}{" "}
                        </TextDescricaoPasso>
                      </CellEtapa>
                    );
                  }
                })}
              </div>
              <div
                style={{
                  width: "60em",
                  display: "flex",
                  flex: 1,
                  marginLeft: "5px",
                  marginRight: "80px",
                  marginTop: -30,
                  marginBottom: 0,
                  justifyContent: "space-between",
                }}
              >
                {this.tooltipsPt2.map((element, index) => {
                  if (index != 0) {
                    return (
                      <CellEtapa
                        key={index}
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <TextDescricaoPasso
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          {element}{" "}
                        </TextDescricaoPasso>
                      </CellEtapa>
                    );
                  }
                })}
              </div>
              <div
                style={{
                  width: "60em",
                  display: "flex",
                  flex: 1,
                  marginLeft: "5px",
                  marginRight: "80px",
                  justifyContent: "space-between",
                }}
              >
                {this.tooltips.map((element, index) => {
                  if (index != 0) {
                    return (
                      <CellEtapa
                        key={index}
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <TextInfoPasso>PASSO {index}/9</TextInfoPasso>
                      </CellEtapa>
                    );
                  }
                })}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row">
              <div className="col-md-1">
                <Link to={{ pathname: "/consorcio-grupo-cota", state: {} }}>
                  <img src={Backward} />
                </Link>
              </div>
              <div className="col-md-11" style={{ left: "-30px" }}>
                <span style={{ font: "normal normal bold 30px/30px Roboto" }}>
                  {" "}
                  Detalhes da cota{" "}
                </span>
              </div>
            </div>
            <div className="row" style={{ marginTop: "40px" }}>
              <div className="col-md-12">
                <span style={{ font: "normal normal normal 21px/25px Roboto" }}>
                  {" "}
                  {this.state.cota.cgcSadNome}{" "}
                </span>
              </div>
            </div>
            <div className="row" style={{ marginTop: "40px" }}>
              <div className="col-md-12">
                <span
                  style={{
                    font: "normal normal normal 12px/14px Roboto",
                    opacity: "0.5",
                  }}
                >
                  {" "}
                  PROGRESSO DA SUA COTA{" "}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    marginLeft: 40,
                    marginRight: 30,
                    justifyContent: "space-between",
                  }}
                >
                  {this.tooltips.map((element, index) => {
                    if (index != 0) {
                      return (
                        <>
                          <img
                            key={index}
                            src={Checked}
                            width={15}
                            style={{
                              opacity:
                                this.state.cota.cgcEtapa >= index
                                  ? "1.0"
                                  : "0.0",
                            }}
                          />
                        </>
                      );
                    }
                  })}
                </div>
                <ContainerDots style={{ marginTop: "-60px" }}>
                  {this.tooltips.map((element, index) => {
                    if (index === 0) {
                      return;
                    } else {
                      if (index <= this.state.cota.cgcEtapa) {
                        return (
                          <>
                            <DotsCheck data-tooltip={element}>
                              {this.iconeEtapa(index, element)}
                            </DotsCheck>
                          </>
                        );
                      } else if (index > this.state.cota.cgcEtapa) {
                        return (
                          <>
                            <Dots data-tooltip={element}>
                              {this.iconeEtapa(index, element)}
                            </Dots>
                          </>
                        );
                      }
                    }
                  })}
                </ContainerDots>
                <ContainerEtapa>
                  <div
                    style={{
                      width: `${this.measures[this.state.cota.cgcEtapa]}%`,
                    }}
                    className="progress-bar-cota"
                  ></div>
                  <div className="progress-bar-cota-big"></div>
                </ContainerEtapa>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    marginLeft: -40,
                    marginRight: -50,
                    marginTop: 10,
                    marginBottom: 30,
                    justifyContent: "space-between",
                  }}
                >
                  {this.tooltips.map((element, index) => {
                    if (index != 0) {
                      return (
                        <CellEtapa
                          key={index}
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        ></CellEtapa>
                      );
                    }
                  })}
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,

                    marginTop: -30,
                    marginBottom: 0,
                    justifyContent: "space-between",
                  }}
                >
                  {this.tooltips.map((element, index) => {
                    if (index != 0) {
                      return (
                        <>
                          <BoxEtapa
                            key={index}
                            style={{
                              maxWidth: 90,
                            }}
                          >
                            <TextDescricaoPasso>{element}</TextDescricaoPasso>
                            <TextInfoPasso>PASSO {index}/9</TextInfoPasso>
                          </BoxEtapa>
                        </>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </>
        )}
        <hr />
        <div className="row">
          <div className="col-md-12">
            <table>
              <tbody>
                {this.montaLinha("Grupo", this.state.cota.cgcGrupo)}
                {this.montaLinha("Cota", this.state.cota.cgcCota)}
                {this.montaLinha("Versão", this.state.cota.cgcVersao)}
                {this.montaLinha("Contrato", this.state.cota.cgcContrato)}
                {this.montaLinha(
                  "Tipo de consórcio",
                  this.state.cota.cgcDescricaoConsorcio
                )}
                {this.montaLinha(
                  "Data da venda",
                  moment(this.state.cota.cgcDataVenda).format("DD/MM/YYYY")
                )}
                {this.montaLinha(
                  "Status da negociação",
                  this.state.cota.descricaoNegociacao
                )}
                {this.montaLinha(
                  "Valor da Proposta",
                  // this.state.cota.descricaoNegociacao
                  "R$ 0,00"
                )}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ConsorcioGrupoCotaForm);
