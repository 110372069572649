import React, { useEffect, useState } from 'react';
import { colors } from '../../../../../styles/colors';
import {BsCheckLg, BsClockHistory, BsHourglassSplit} from "react-icons/bs";
import { CgClose } from 'react-icons/cg';

export function BadgeEtapaDesktop({descricao, concluido, active, status}) {
    const [ borda, setBorda] = useState("");
    const [ icon, setIcon ] = useState(null);
    const [ bg, setBg] = useState("");
    const [ textColor, setTextColor] = useState("");

    useEffect(() => {
        if(status === "Finished"){
            setBg("#83c5be")
            setIcon(<BsCheckLg color={colors.altGreen}  size={36} />)
            setBorda(colors.darkGreen)
            setTextColor(colors.darkGreen)
        }else if(status === "Running"){
            setBorda('#FC0')
            setBg("#ffc857")
            setIcon(<BsClockHistory  size={36} />)
            setTextColor("#000")
        }else if(status === "Pendency" || status === "NotStarted"){
            setBg("#cfcfcf")
            setIcon(<BsHourglassSplit color={"#787575"}  size={42} />)
            setTextColor("#787575")
            setBorda("#787575")
        }else{
            setBg("#ff686b")
            setIcon(<CgClose color={"#85182a"}  size={42} />)
            setTextColor("#85182a")
            setBorda("#85182a")
        }

    }, [])

  return(
    <>
        <div style={{ display: 'flex', width: 80, height: 120, maxHeight: 130, borderRadius: 9, border: `2px solid ${borda}`, flex: 1, backgroundColor: bg, alignItems: "space-around" }}>
            <div style={{ display: 'flex', flex:1, flexDirection: 'column', alignContent: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', gap: 10, padding: 5}}>
            <div style={{ display: 'flex', width: '100%', height: 56, backgroundColor: "rgba(255, 255, 255, 0.55)", borderRadius: 8, alignItems: 'center', justifyContent: 'center', color: textColor}}>{icon}</div>
            <span style={{ fontSize: 10, textAlign: 'center', color: textColor}}>{descricao}</span>
            </div>
        </div>
    </>
  )
}
export function BadgeEtapaMobile({descricao, concluido, active, status}) {
    const [ borda, setBorda] = useState("");
    const [ icon, setIcon ] = useState(null);
    const [ bg, setBg] = useState("");
    const [ textColor, setTextColor] = useState("");
    useEffect(() => {
        if(status === "Finished"){
            setBg("#83c5be")
            setIcon(<BsCheckLg color={colors.altGreen}  size={36} />)
            setBorda(colors.darkGreen)
            setTextColor(colors.darkGreen)
        }else if(status === "Running"){
            setBorda('#FC0')
            setBg("#ffc857")
            setIcon(<BsClockHistory  size={36} />)
            setTextColor("#000")
        }else if(status === "Pendency" || status === "NotStarted"){
            setBg("#cfcfcf")
            setIcon(<BsHourglassSplit color={"#787575"}  size={36} />)
            setTextColor("#787575")
            setBorda("#787575")
        }else{
            setBg("#ff686b")
            setIcon(<CgClose color={"#85182a"}  size={36} />)
            setTextColor("#85182a")
            setBorda("#85182a")
        }
    }, [])

  return(
    <>
        <div style={{ display: 'flex', flexDirection: 'row', width: '95%', height: 85, borderRadius: 9, border: `2px solid ${borda}`, flex: 1, backgroundColor: bg, padding: "0 10px" }}>
            <div style={{ display: 'flex', flex:1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', justifyContent: 'space-between', gap: 10, padding: 5}}>
            {icon}
            <div>
            <span style={{ fontSize: 13, textAlign: 'center', color: textColor}}>{descricao}</span>
            </div>
            </div>
        </div>
    </>
  )
}
