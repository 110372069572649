import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import api from "../../services/api";
import { Link } from "react-router-dom";
import SweetAlert from 'sweetalert2-react';
import Swal from 'sweetalert2'
import Pagination from "../../components/Pagination";



export class ProdutosReanaliseAnalise extends Component {



  constructor(){
        super();
        this.state = {
          cotas: [],
          proposta:'',
          proposta1:0,
          proposta2:0,
          proposta3:0,
          codigo:'',
          origem:'',
          parametros:'',
          currentPage:1,
          totalRecords:null,
          show: false
        };
  }

  async componentDidMount() {


    if(this.props[0].location.state !== undefined){

      
        var obj_parametro  = JSON.parse(this.props[0].location.state.proposta.reanalise.proposta.parametros);
        var resultado      = JSON.parse(this.props[0].location.state.proposta.reanalise.proposta.resultado);
        const reanalise    = this.props[0].location.state.proposta.reanalise;
        this.setState({
          cpf:                      reanalise.cota.cliente.cpf,
          clienteNome:              reanalise.cota.cliente.nome,
          administradoraNome:       reanalise.cota.grupo.consorcio.administradora.nome,
          grupo:                    reanalise.cota.grupo.grupo,
          cota:                     reanalise.cota.contrato,
          creditoValor:             reanalise.cota.valorCredito,
          codigo:                   reanalise.codigo,
          remuneracaoConCash:       '',
          parametros:               obj_parametro,
          taxaAtratividadeConCash:  obj_parametro.atratividadeAnualConCashCar,
          prazoCota:                obj_parametro.prazoPlano,
          remuneracaoFIDC:          obj_parametro.fidcRemuneracaoConcash,
          taxaAtratividadeFIDC:     obj_parametro.atratividadeAnualFundo,
          assembleiaRealizar:       obj_parametro.assembleiaRealizar,
          remuneracaoMB:            obj_parametro.mbRemuneracaoConcash,
          taxaAtratividadeMB:       obj_parametro.atratividadeAnualFundoMB,
          atrasos:                  obj_parametro.encargosMultasTaxas,
          remuneracaoPlugi:         obj_parametro.comissaoPlugi,
          taxaTransferencia:        obj_parametro.taxaTransferencia,
          saldoDevedor:             obj_parametro.saldoDevedorAtualizado,
          recebimentoComissao:      '',
          formaPagamentoAtraso:     obj_parametro.formaPagamentoAtraso,
          lanceMaximo:              '',
          lanceMinimo:              '',
          percentualFundoComum:     obj_parametro.percentualFundoComumPago,
          dataAtualizacaoCredito:   obj_parametro.mesAtualizacaoCredito
        });
    }

  }

  async obtemListaCotas(){

      try {

        const {
          currentPage,
          contrato
        } = this.state;

        const response = await api.post("api/concash/1.0/consorcio-grupo-cota/listar", { pagina: currentPage, contrato: contrato });
        this.setState({ totalRecords: response.data.totalElements,cotas: response.data.content});
      } catch (err) {
        this.setState({
          error:
            "Houve um problema para obter a lista de cotas."
        });
      }

  }

  handleFormPrecificar = async e => {

      e.preventDefault();
      const { parametros } = this.state;

      parametros.prazoPlano                  = this.state.prazoCota;
      parametros.atratividadeAnualConCashCar = this.state.taxaAtratividadeConCash;
      parametros.fidcRemuneracaoConcash      = this.state.remuneracaoFIDC;
      parametros.atratividadeAnualFundo      = this.state.taxaAtratividadeFIDC;
      parametros.assembleiaRealizar          = this.state.assembleiaRealizar;
      parametros.mbRemuneracaoConcash        = this.state.remuneracaoMB;
      parametros.atratividadeAnualFundoMB    = this.state.taxaAtratividadeMB;
      parametros.encargosMultasTaxas         = this.state.atrasos;
      parametros.comissaoPlugi               = this.state.remuneracaoPlugi;
      parametros.taxaTransferencia           = this.state.taxaTransferencia;
      parametros.saldoDevedorAtualizado      = this.state.saldoDevedor;
      parametros.formaPagamentoAtraso        = this.state.formaPagamentoAtraso;
      parametros.percentualFundoComumPago    = this.state.percentualFundoComum;
      parametros.mesAtualizacaoCredito       = this.state.dataAtualizacaoCredito;
      parametros.codigoReanalise             = this.codigo;
      /*
      recebimentoComissao:      '',
      lanceMaximo:              '',
      lanceMinimo:              '',
      dataAtualizacaoCredito:
      */
      try {

            Swal.fire ({
               title: 'Aguarde ...',
               onBeforeOpen: () => {
                 Swal.showLoading ()
               }
            });

            const response = await api.post("api/concash/1.0/proposta/precificar", {
                parametros
            });


            this.setState({ proposta1: response.data.Proposta1ValorPuro,proposta2: response.data.Proposta2ValorPuro,proposta3: response.data.Proposta3ValorPuro });
            Swal.close();
            

       } catch (err) {
         this.setState({
           error:
             "Houve um erro na rotina de precificação."
         });
       }
    };



    handleEnviarProposta = async (e,proposta) => {

        e.preventDefault();
        const { codigo, origem } = this.state;

        var valor        = 0.0;

        if(proposta == "Proposta1"){
           valor         = this.state.proposta1;
        }else if(proposta == "Proposta2"){
           valor         = this.state.proposta2;
        }else if(proposta == "Proposta3"){
           valor         = this.state.proposta3;
        }


        try {

              Swal.fire ({
                 title: 'Aguarde ...',
                 onBeforeOpen: () => {
                   Swal.showLoading ()
                 }
              });

              const response = await api.put("api/concash/1.0/consorcio-grupo-cota-reanalise/aprovar", {
                  codigo:codigo, valor: valor , origem: origem
              });
              Swal.close();

              Swal.fire ('Reanalise enviada com sucesso!');

         } catch (err) {
              Swal.close();
         }
      };


   handleRejeitarProposta = async (e) => {

          e.preventDefault();
          const { codigo } = this.state;

          try {

                Swal.fire ({
                   title: 'Aguarde ...',
                   onBeforeOpen: () => {
                     Swal.showLoading ()
                   }
                });

                const response = await api.put("api/concash/1.0/consorcio-grupo-cota-reanalise/rejeitar", {
                    codigo:codigo
                });
                Swal.close();

                Swal.fire ('Reanalise enviada com sucesso!');

           } catch (err) {
                Swal.close();
           }
   };


  render() {

    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Análise </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">

              <div className="row" style={{backgroundColor:'rgb(222, 226, 230)'}}>

                <div className="col-md-12" style={{marginTop:'30px'}}>
                <div className="row">
                  <div className="col-md-3">
                      <Form.Group className="row">
                        <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>CPF/CNPJ Cliente​</label>
                      </Form.Group>
                  </div>
                  <div className="col-md-3">
                      <Form.Group className="row">
                        <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Nome Consorciado</label>
                      </Form.Group>
                  </div>
                  <div className="col-md-2">
                      <Form.Group className="row">
                        <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Administradora</label>
                      </Form.Group>
                  </div>
                  <div className="col-md-2">
                      <Form.Group className="row">
                        <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Grupo</label>
                      </Form.Group>
                  </div>
                  <div className="col-md-2">
                      <Form.Group className="row">
                        <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Cota</label>
                      </Form.Group>
                  </div>
                </div>
                <div className="row" style={{marginTop:'-20px'}}>
                      <div className="col-md-3">
                          <Form.Group className="row">
                            <div className="col-sm-12"  style={{textAlign:'center'}}>
                              <Form.Control type="text" style={{height:'30px'}} className="form-control" id="cpf" value={this.state.cpf} readOnly onChange={e => this.setState({ cpf: e.target.value })} />
                            </div>
                          </Form.Group>
                      </div>
                      <div className="col-md-3">
                          <Form.Group className="row">
                            <div className="col-sm-12" style={{textAlign:'center'}}>
                              <Form.Control type="text" style={{height:'30px'}} className="form-control" id="clienteNome" value={this.state.clienteNome} readOnly onChange={e => this.setState({ clienteNome: e.target.value })} />
                            </div>
                          </Form.Group>
                      </div>
                      <div className="col-md-2">
                          <Form.Group className="row">
                            <div className="col-sm-12"  style={{textAlign:'center'}}>
                              <Form.Control type="text" style={{height:'30px'}} className="form-control" id="administradoraNome" value={this.state.administradoraNome} readOnly onChange={e => this.setState({ administradoraNome: e.target.value })} />
                            </div>
                          </Form.Group>
                      </div>
                      <div className="col-md-2">
                          <Form.Group className="row">
                            <div className="col-sm-12"  style={{textAlign:'center'}}>
                              <Form.Control type="text" style={{height:'30px'}} className="form-control" id="grupo" value={this.state.grupo} readOnly onChange={e => this.setState({ grupo: e.target.value })} />
                            </div>
                          </Form.Group>
                      </div>
                      <div className="col-md-2">
                          <Form.Group className="row">
                            <div className="col-sm-12"  style={{textAlign:'center'}}>
                              <Form.Control type="text" style={{height:'30px'}} className="form-control" id="cota" value={this.state.cota} readOnly onChange={e => this.setState({ cota: e.target.value })} />
                            </div>
                          </Form.Group>
                      </div>
                    </div>
                    <div className="row" style={{marginTop:'30px'}}>
                          <div className="col-md-4">
                              <Form.Group className="row">
                                <label htmlFor="examplePropostal" className="col-sm-5 col-form-label" style={{textAlign:'center',marginTop:'-12px'}}>Valor do Crédito</label>
                                <div className="col-sm-7"  style={{textAlign:'center'}}>
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="creditoValor" value={this.state.creditoValor} readOnly onChange={e => this.setState({ creditoValor: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                          <div className="col-md-4">
                              <Form.Group className="row">
                                <label htmlFor="examplePropostal" className="col-sm-5 col-form-label" style={{textAlign:'center',marginTop:'-12px'}}>Remuneração ConCash</label>
                                <div className="col-sm-7" style={{textAlign:'center'}}>
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="remuneracaoConCash" value={this.state.remuneracaoConCash} onChange={e => this.setState({ remuneracaoConCash: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                          <div className="col-md-4">
                              <Form.Group className="row">
                                <label htmlFor="examplePropostal" className="col-sm-5 col-form-label" style={{textAlign:'center',marginTop:'-12px'}}>Tx Atratividade Concash</label>
                                <div className="col-sm-7"  style={{textAlign:'center'}}>
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="taxaAtratividadeConCash" value={this.state.taxaAtratividadeConCash} onChange={e => this.setState({ taxaAtratividadeConCash: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                     </div>
                     <div className="row" style={{marginTop:'-20px'}}>
                           <div className="col-md-4">
                               <Form.Group className="row">
                                 <label htmlFor="examplePropostal" className="col-sm-5 col-form-label" style={{textAlign:'center',marginTop:'-12px'}}>Prazo da Cota</label>
                                 <div className="col-sm-7"  style={{textAlign:'center'}}>
                                   <Form.Control type="text" style={{height:'30px'}} className="form-control" id="prazoCota" value={this.state.prazoCota} readOnly onChange={e => this.setState({ prazoCota: e.target.value })} />
                                 </div>
                               </Form.Group>
                           </div>
                           <div className="col-md-4">
                               <Form.Group className="row">
                                 <label htmlFor="examplePropostal" className="col-sm-5 col-form-label" style={{textAlign:'center',marginTop:'-12px'}}>Remuneração FIDC</label>
                                 <div className="col-sm-7" style={{textAlign:'center'}}>
                                   <Form.Control type="text" style={{height:'30px'}} className="form-control" id="remuneracaoFIDC" value={this.state.remuneracaoFIDC} onChange={e => this.setState({ remuneracaoFIDC: e.target.value })} />
                                 </div>
                               </Form.Group>
                           </div>
                           <div className="col-md-4">
                               <Form.Group className="row">
                                 <label htmlFor="examplePropostal" className="col-sm-5 col-form-label" style={{textAlign:'center',marginTop:'-12px'}}>Tx Atratividade FIDC</label>
                                 <div className="col-sm-7"  style={{textAlign:'center'}}>
                                   <Form.Control type="text" style={{height:'30px'}} className="form-control" id="taxaAtratividadeFIDC" value={this.state.taxaAtratividadeFIDC} onChange={e => this.setState({ taxaAtratividadeFIDC: e.target.value })} />
                                 </div>
                               </Form.Group>
                           </div>
                      </div>
                      <div className="row" style={{marginTop:'-20px'}}>
                            <div className="col-md-4">
                                <Form.Group className="row">
                                  <label htmlFor="examplePropostal" className="col-sm-5 col-form-label" style={{textAlign:'center',marginTop:'-12px'}}>Assemb Realizar</label>
                                  <div className="col-sm-7"  style={{textAlign:'center'}}>
                                    <Form.Control type="text" style={{height:'30px'}} className="form-control" id="assembleiaRealizar" readOnly value={this.state.assembleiaRealizar} onChange={e => this.setState({ assembleiaRealizar: e.target.value })} />
                                  </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group className="row">
                                  <label htmlFor="examplePropostal" className="col-sm-5 col-form-label" style={{textAlign:'center',marginTop:'-12px'}}>Remuneração MB</label>
                                  <div className="col-sm-7" style={{textAlign:'center'}}>
                                    <Form.Control type="text" style={{height:'30px'}} className="form-control" id="remuneracaoMB" value={this.state.remuneracaoMB} onChange={e => this.setState({ remuneracaoMB: e.target.value })} />
                                  </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group className="row">
                                  <label htmlFor="examplePropostal" className="col-sm-5 col-form-label" style={{textAlign:'center',marginTop:'-12px'}}>Tx Atratividade MB</label>
                                  <div className="col-sm-7"  style={{textAlign:'center'}}>
                                    <Form.Control type="text" style={{height:'30px'}} className="form-control" id="taxaAtratividadeMB" value={this.state.taxaAtratividadeMB} onChange={e => this.setState({ taxaAtratividadeMB: e.target.value })} />
                                  </div>
                                </Form.Group>
                            </div>
                       </div>
                       <div className="row" style={{marginTop:'-20px'}}>
                             <div className="col-md-4">
                                 <Form.Group className="row">
                                   <label htmlFor="examplePropostal" className="col-sm-5 col-form-label" style={{textAlign:'center',marginTop:'-12px'}}>Atrasos</label>
                                   <div className="col-sm-7"  style={{textAlign:'center'}}>
                                     <Form.Control type="text" style={{height:'30px'}} className="form-control" id="atrasos" readOnly value={this.state.atrasos} onChange={e => this.setState({ atrasos: e.target.value })} />
                                   </div>
                                 </Form.Group>
                             </div>
                             <div className="col-md-4">
                                 <Form.Group className="row">
                                   <label htmlFor="examplePropostal" className="col-sm-5 col-form-label" style={{textAlign:'center',marginTop:'-12px'}}>Remuneração Plugi</label>
                                   <div className="col-sm-7" style={{textAlign:'center'}}>
                                     <Form.Control type="text" style={{height:'30px'}} className="form-control" id="remuneracaoPlugi" value={this.state.remuneracaoPlugi} onChange={e => this.setState({ remuneracaoPlugi: e.target.value })} />
                                   </div>
                                 </Form.Group>
                             </div>
                             <div className="col-md-4">
                                 <Form.Group className="row">
                                   <label htmlFor="examplePropostal" className="col-sm-5 col-form-label" style={{textAlign:'center',marginTop:'-12px'}}>Tx Transferência</label>
                                   <div className="col-sm-7"  style={{textAlign:'center'}}>
                                     <Form.Control type="text" style={{height:'30px'}} className="form-control" id="taxaTransferencia" value={this.state.taxaTransferencia} onChange={e => this.setState({ taxaTransferencia: e.target.value })} />
                                   </div>
                                 </Form.Group>
                             </div>
                        </div>
                        <div className="row" style={{marginTop:'-20px'}}>
                              <div className="col-md-4">
                                  <Form.Group className="row">
                                    <label htmlFor="examplePropostal" className="col-sm-5 col-form-label" style={{textAlign:'center',marginTop:'-12px'}}>Saldo Devedor</label>
                                    <div className="col-sm-7"  style={{textAlign:'center'}}>
                                      <Form.Control type="text" style={{height:'30px'}} className="form-control" id="saldoDevedor" value={this.state.saldoDevedor} readOnly onChange={e => this.setState({ saldoDevedor: e.target.value })} />
                                    </div>
                                  </Form.Group>
                              </div>
                              <div className="col-md-4">
                                  <Form.Group className="row">
                                    <label htmlFor="examplePropostal" className="col-sm-5 col-form-label" style={{textAlign:'center',marginTop:'-12px'}}>Recebimento Comissão</label>
                                    <div className="col-sm-7" style={{textAlign:'center'}}>
                                      <Form.Control type="text" style={{height:'30px'}} className="form-control" id="recebimentoComissao" value={this.state.recebimentoComissao} onChange={e => this.setState({ recebimentoComissao: e.target.value })} />
                                    </div>
                                  </Form.Group>
                              </div>
                              <div className="col-md-4">
                              </div>
                       </div>
                       <div className="row" style={{marginTop:'-20px'}}>
                             <div className="col-md-4">
                                 <Form.Group className="row">
                                   <label htmlFor="examplePropostal" className="col-sm-5 col-form-label" style={{textAlign:'center',marginTop:'-12px'}}>Forma Pag Atraso</label>
                                   <div className="col-sm-7"  style={{textAlign:'center'}}>
                                     <Form.Control type="text" style={{height:'30px'}} className="form-control" id="formaPagamentoAtraso" value={this.state.formaPagamentoAtraso} onChange={e => this.setState({ formaPagamentoAtraso: e.target.value })} />
                                   </div>
                                 </Form.Group>
                             </div>
                             <div className="col-md-8">
                             </div>
                        </div>
                        <div className="row" style={{marginTop:'-20px'}}>
                              <div className="col-md-4">
                                  <Form.Group className="row">
                                    <label htmlFor="examplePropostal" className="col-sm-5 col-form-label" style={{textAlign:'center',marginTop:'-12px'}}>Lance Máximo</label>
                                    <div className="col-sm-7"  style={{textAlign:'center'}}>
                                      <Form.Control type="text" style={{height:'30px'}} className="form-control" id="lanceMaximo" value={this.state.lanceMaximo} onChange={e => this.setState({ lanceMaximo: e.target.value })} />
                                    </div>
                                  </Form.Group>
                              </div>
                              <div className="col-md-8">

                                  <div className="row" style={{marginTop:'-20px'}}>
                                        <div className="col-md-2">
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center',marginTop:'0px'}}>Proposta 1</label>
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center',marginTop:'0px'}}>Proposta 2</label>
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center',marginTop:'0px'}}>Proposta 3</label>
                                        </div>
                                        <div className="col-md-1">
                                        </div>
                                  </div>

                                  <div className="row" style={{marginTop:'0px'}}>
                                        <div className="col-md-2">
                                        </div>
                                        <div className="col-md-3">
                                            <Form.Group className="row">
                                              <div className="col-sm-12"  style={{textAlign:'center'}}>
                                                <Form.Control type="text" style={{height:'30px'}} className="form-control" id="proposta1" value={this.state.proposta1} onChange={e => this.setState({ proposta1: e.target.value })} />
                                              </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-3">
                                            <Form.Group className="row">
                                              <div className="col-sm-12"  style={{textAlign:'center'}}>
                                                <Form.Control type="text" style={{height:'30px'}} className="form-control" id="proposta2" value={this.state.proposta2} onChange={e => this.setState({ proposta2: e.target.value })} />
                                              </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-3">
                                            <Form.Group className="row">
                                              <div className="col-sm-12"  style={{textAlign:'center'}}>
                                                <Form.Control type="text" style={{height:'30px'}} className="form-control" id="proposta3" value={this.state.proposta3} onChange={e => this.setState({ proposta3: e.target.value })} />
                                              </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-1">
                                        </div>
                                  </div>


                                  <div className="row" style={{marginTop:'0px'}}>
                                        <div className="col-md-2">
                                        </div>
                                        <div className="col-md-3">
                                              <div className="col-md-12" style={{textAlign:'center'}}>
                                                  <button type="button" className="btn btn-primary" onClick={e =>this.handleEnviarProposta(e,"Proposta3")}>Enviar Proposta 1</button>
                                              </div>
                                        </div>
                                        <div className="col-md-3">
                                              <div className="col-md-12" style={{textAlign:'center'}}>
                                                  <button type="button" className="btn btn-primary" onClick={e =>this.handleEnviarProposta(e,"Proposta2")}>Enviar Proposta 2</button>
                                              </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="col-sm-12" style={{textAlign:'center'}}>
                                                <button type="button" className="btn btn-primary" onClick={e =>this.handleEnviarProposta(e,"Proposta3")}>Enviar Proposta 3</button>
                                            </div>
                                        </div>
                                        <div className="col-md-1">
                                        </div>
                                  </div>


                              </div>
                         </div>
                         <div className="row" style={{marginTop:'-10px'}}>
                               <div className="col-md-4">
                                   <Form.Group className="row">
                                     <label htmlFor="examplePropostal" className="col-sm-5 col-form-label" style={{textAlign:'center',marginTop:'-12px'}}>Lance Minímo</label>
                                     <div className="col-sm-7"  style={{textAlign:'center'}}>
                                       <Form.Control type="text" style={{height:'30px'}} className="form-control" id="lanceMinimo" value={this.state.lanceMinimo} onChange={e => this.setState({ lanceMinimo: e.target.value })} />
                                     </div>
                                   </Form.Group>
                               </div>
                               <div className="col-md-8">
                               </div>
                         </div>
                         <div className="row" style={{marginTop:'-10px'}}>
                               <div className="col-md-4">
                                   <Form.Group className="row">
                                     <label htmlFor="examplePropostal" className="col-sm-5 col-form-label" style={{textAlign:'center',marginTop:'-12px'}}>% de Fundo Comum</label>
                                     <div className="col-sm-7"  style={{textAlign:'center'}}>
                                       <Form.Control type="text" style={{height:'30px'}} className="form-control" id="percentualFundoComum" value={this.state.percentualFundoComum} onChange={e => this.setState({ percentualFundoComum: e.target.value })} />
                                     </div>
                                   </Form.Group>
                               </div>
                               <div className="col-md-8">
                                     <div className="row" style={{marginTop:'0px'}}>
                                           <div className="col-md-2">
                                           </div>
                                           <div className="col-md-3" style={{textAlign:'center'}}>
                                               <button type="submit" className="btn btn-primary mr-2" onClick={e =>this.handleFormPrecificar(e)}>Precificar</button>
                                           </div>
                                           <div className="col-md-3" style={{textAlign:'center'}}>
                                           </div>
                                           <div className="col-md-3" style={{textAlign:'center'}}>
                                                <button type="submit" className="btn btn-primary mr-2" onClick={e =>this.handleNome(e)}>Negar</button>
                                           </div>
                                           <div className="col-md-1">
                                           </div>
                                     </div>
                               </div>
                          </div>
                          <div className="row" style={{marginTop:'-20px'}}>
                                <div className="col-md-4">
                                    <Form.Group className="row">
                                      <label htmlFor="examplePropostal" className="col-sm-5 col-form-label" style={{textAlign:'center',marginTop:'-12px'}}>Dt Atuali Crédito</label>
                                      <div className="col-sm-7"  style={{textAlign:'center'}}>
                                        <Form.Control type="text" style={{height:'30px'}} className="form-control" id="dataAtualizacaoCredito" value={this.state.dataAtualizacaoCredito} onChange={e => this.setState({ dataAtualizacaoCredito: e.target.value })} />
                                      </div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-8">
                                </div>
                           </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ProdutosReanaliseAnalise
