import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";


export class FormProspect extends Component {



  constructor(props){

    super(props);

    this.state = {
          nome: '',
          cpf: '',
          email: '',
          genero: '',
          situacao: '',
          ddd1: '',
          telefone1: '',
          ddd2: '',
          telefone2: '',
          codigo:'',
          profissao:'',
          cidade:'',
          uf:'',
          labelForm: '',
          labelButton: '',
          error: "",
          action: ""
    };
  }


  async componentDidMount() {

    if(this.props[0].location.state !== undefined){
        const prospect  = this.props[0].location.state.prospect
        this.setState({
                       nome:      prospect.nome,
                       cpf:       prospect.cpf,
                       codigo:    prospect.codigo,
                       email:     prospect.email,
                       ddd1:      prospect.ddd1,
                       cidade:    prospect.cidade,
                       uf:        prospect.uf,
                       telefone1: prospect.telefone1,
                       telefone2: prospect.telefone2,
                       profissao: prospect.profissao,
                       situacao:  prospect.situacao,
                       genero:    prospect.genero
                       });
        this.setState({labelForm: "Editar Prospect",labelButton: "Editar",action: "editar"});
    }else{
        this.setState({labelForm: "Adicionar Prospect",labelButton: "Adicionar",action: "adicionar"});
    }

  }

  handleForm = async e => {
    e.preventDefault();
    const { nome,
            cpf,
            email,
            genero,
            cidade,
            situacao,
            uf,
            ddd1,
            telefone1,
            profissao,
            ddd2,
            telefone2,
            codigo,
            action} = this.state;
    if (!nome
        || !email
        || !situacao
        || !action) {
      this.setState({ error: "Preencha os campos obrigatórios para continuar!" });
    } else {
      try {

        if(action == "editar"){
          const response = await api.put("api/concash/1.0/prospect/"+action, {
              nome,cpf,codigo,situacao,email,genero,cidade,uf,ddd1,telefone1,ddd2,telefone2,profissao
          });
        }else{
          const response = await api.post("api/concash/1.0/prospect/"+action, {
              nome,cpf,email,genero,situacao,cidade,uf,ddd1,telefone1,ddd2,telefone2,profissao
          });
        }

        this.props.history.push("/prospect");

      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o processamento da requisição."
        });
      }
    }
  };

  render() {


    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> {this.state.labelForm} </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>

        <div className="row">

          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"></h4>
                {this.state.error && <p className="card-description">{this.state.error}</p>}
                <form className="forms-sample" onSubmit={this.handleForm}>

                <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="row">
                          <select className="form-control" value={this.state.situacao} onChange={e => this.setState({ situacao: e.target.value })} id="origem">
                            <option value="">Selecione</option>
                            <option value="EM_ABERTO">EM_ABERTO</option>
                            <option value="EM_PROCESSO">EM_PROCESSO</option>
                            <option value="FINALIZADO">FINALIZADO</option>
                          </select>
                       </Form.Group>
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Nome</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="nome" value={this.state.nome} placeholder="Nome do Cliente"  onChange={e => this.setState({ nome: e.target.value })}  size="sm" />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">CPF</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="cpf" value={this.state.cpf} placeholder="CPF"  onChange={e => this.setState({ cpf: e.target.value })}  size="sm" />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">E-mail</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="email" value={this.state.email} placeholder="E-mail"  onChange={e => this.setState({ email: e.target.value })}  size="sm" />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Gênero</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="genero" value={this.state.genero} placeholder="Gênero"  onChange={e => this.setState({ genero: e.target.value })}  size="sm" />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Profissão</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="profissao" value={this.state.profissao} placeholder="Profissão"  onChange={e => this.setState({ profissao: e.target.value })}  size="sm" />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Cidade</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="cidade" value={this.state.cidade} placeholder="Cidade"  onChange={e => this.setState({ cidade: e.target.value })}  size="sm" />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">UF</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="uf" value={this.state.uf} placeholder="UF"  onChange={e => this.setState({ uf: e.target.value })}  size="sm" />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">DDD1</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="ddd1" value={this.state.ddd1} placeholder="DDD1"  onChange={e => this.setState({ ddd1: e.target.value })}  size="sm" />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Telefone 1</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="telefone1" value={this.state.telefone1} placeholder="Telefone 1"  onChange={e => this.setState({ telefone1: e.target.value })}  size="sm" />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">DDD2</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="ddd2" value={this.state.ddd2} placeholder="DDD2"  onChange={e => this.setState({ ddd2: e.target.value })}  size="sm" />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Telefone 2</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="telefone2" value={this.state.telefone2} placeholder="Telefone 2"  onChange={e => this.setState({ telefone2: e.target.value })}  size="sm" />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="text-right">
                    <button type="submit" className="btn btn-primary mr-2">{this.state.labelButton}</button>
                  </div>

                  {this.state.telefone1 && this.state.ddd1 &&

                    <div className="text-center">
                          <Link
                                      to={{pathname: "/whatsapp-sessao/conversa",state: {
                                        codigoProspect: this.state.codigo, codigoCliente: null
                                      }}}
                                      className="m-3 btn btn-sm btn-success"
                                    >
                                    WhatsApp
                          </Link>
                    </div>

                  }

                </form>
              </div>
            </div>
          </div>
         </div>
       </div>
    )
  }
}

export default withRouter(FormProspect)
