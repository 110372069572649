
import { useDispatch, useSelector } from 'react-redux';
import { logoutAuto, setEstilo, autoAuth} from './index';
import moment from 'moment/moment';
import decode from 'jwt-decode';


const useAutoservico = () => {
  const estado = useSelector((state) => state);
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(logoutAuto());
  };

  const getData = () => {
    return estado.autoservico;
  }

  const getTokenAutoServico = () => {
    return estado.autoservico.token;
  }

  const login = async (user) => {
    console.log("iser ", user)
    return dispatch(autoAuth(await user));
  }

  const estilizar = (estilo) => {
    dispatch(setEstilo(estilo));
  }

  const tokenValido = () => {
    const {token} = estado.autoservico;
    const decodedToken = decode(token);
    if(decodedToken.exp <= Math.floor(new Date() / 1000)) {
      logout();
      return false;
    } else {
      return true;
    }
  }


  return { logout, getData, login, estilizar, getTokenAutoServico, tokenValido };
};

export default useAutoservico;
