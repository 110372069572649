function realNaN(chart) {
    const value = parseInt(chart, 10);
    return isNaN(value);
}

function createFormater(mask) {
    const computedMask = mask.split('');

    return (value) => {
        if (value.length === 0) {
            return '';
        }

        let valueIndex = 0;
        return computedMask
            .map((chart, index) => {
                const v = value[valueIndex];

                if (!v && realNaN(chart) && index === 0) {
                    return chart;
                }

                if (!v) {
                    return '';
                }

                if (realNaN(chart)) {
                    return chart;
                }

                valueIndex += 1;
                return v;
            })
            .join('');
    };
}

const cellphone = createFormater('(11) 9 1234 - 5678');
const phone = createFormater('(11) 1234 - 5678');

export const cellphoneFormater = (text) => {
    text = text.replace(/([^0-9])/g, '').slice(0, 11);

    if (text.length < 11) {
        return phone(text);
    }
    return cellphone(text);
};

export const currencyFormater = (text) => {
    const numero = text.replace(/([^0-9])/g, '');
    return new Intl.NumberFormat("pt-BR", {
    style: "decimal"
  }).format(numero);
};

export const numberFormater = (text) => {
    return text.replace(/([^0-9])/g, '');
};
