import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import api from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import { Link, withRouter } from "react-router-dom";

export class Inlira extends Component {
    constructor(props) {
        super(props);

        const { codigoCota, codigoExterno } = this.props;

        this.state = {
            codigoCota: codigoCota,
            codigoExterno: codigoExterno,
            error: "",
        };
    }

    async componentDidMount() {}

    handleFormInLira = async (e) => {
        e.preventDefault();

        try {
            let codigo = this.state.codigoCota;
            const response = await api.put(
                "api/concash/1.0/consorcio-grupo-cota/inlira",
                {
                    codigo,
                }
            );
            Swal.fire("Cota processada para envio!");
        } catch (err) {
            this.setState({
                error: "Houve um problema com a exclusão.",
            });
        }
    };

    render() {
        return (
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">
                                Envia Cota para InLira
                            </h4>
                            <p className="card-description"></p>

                            <form
                                className="forms-sample"
                                onSubmit={this.handleFormInLira}
                            >
                                <div className="row">
                                    <div className="col-md-12 grid-margin stretch-card">
                                        <div className="card">
                                            <div className="card-body">
                                                <Form.Group className="row">
                                                    <label
                                                        htmlFor="examplePropostal"
                                                        className="col-sm-12 col-form-label"
                                                    >
                                                        Caso a cota não seja
                                                        viável, por favor,
                                                        enviar para InLira.
                                                    </label>
                                                </Form.Group>
                                                {!this.state.codigoExterno ? (
                                                    <div className="text-right">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary mr-2"
                                                        >
                                                            Enviar Cota Para
                                                            InLira
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div className="text-right">
                                                        Cota já enviada para
                                                        InLira, Ticke ID :{" "}
                                                        {
                                                            this.state
                                                                .codigoExterno
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Inlira;
