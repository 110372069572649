import React, { Component } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { registerLocale } from "react-datepicker";
import api from "../../services/api";
import ptBR from "date-fns/locale/pt-BR";
import Swal from 'sweetalert2'
import { withRouter } from "react-router-dom";
import { Backdrop, Checkbox, CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import styled from 'styled-components';
import { Aba } from '../Backoffice/styles';
import { BsCardList, BsCashCoin } from 'react-icons/bs';
import HistoricoProposta from '../../components/HistoricoProposta';
import { money } from '../../mask/money';


registerLocale("pt-BR", ptBR);

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};


export class PropostaForm extends Component {
  constructor(props){

        super(props);
        
        this.state = {
          cota: '',
          isOpen: false,
          blocoCadastro: true,
          blocoHistorico: false,
          blocoAberto:"Cadastro",
          valorProposta:"",
          idClienteConsorcio:"",
          codigoProposta:"",
          isChecked:false,
          valor: null,
          ultimaProposta: false,
          bloqueado: false
        }
  }

  seekProposal = async () =>{
    this.setState({isOpen:true});
    const codigo = this.props[0].location.state.cota.idClienteConsorcio;

    const response = await api.get(`/api/concash/1.0/cliente-proposta/proposta-atual/${codigo}`);
    if(response.data){
      if(!response.data.valorProposta){
        if(response.data.statusNegociacao == '11'){
          this.setState({
            isOpen:false,
            ultimaProposta:true,
            bloqueado:false,
            valorProposta: response.data.retorno,
          });
        }else if(response.data.statusNegociacao == '2'){
          this.setState({
            isOpen:false,
            bloqueado:false,
            ultimaProposta:false,
            codigoProposta:response.data.retorno.idProposta,
            valorProposta: response.data.retorno.valorProposta.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}),
          });
        }else{
          this.setState({
            isOpen:false,
            bloqueado:true,
            valorProposta: response.data.retorno,
          });
        }
      }else{
        this.setState({
          valorProposta: response.data.retorno.valorProposta ? response.data.retorno.valorProposta.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : response.data,
          isOpen:false,
          codigoProposta:response.data.retorno.idProposta,
          bloqueado:false,
        });  
      }
    }else{
      this.setState({
        isOpen:false,
        codigoProposta:5,
        valorProposta: String('0,00').toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}),
      });  
    }
  }

  async componentDidMount() {
      this.setState({isOpen:true});
      if (this.props[0].location.state !== undefined) {
        this.props[0].location.state.cota.cgcValorCredito = this.props[0].location.state.cota.cgcValorCredito.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
        this.setState({
          cota:this.props[0].location.state.cota,
          idClienteConsorcio : this.props[0].location.state.cota.idClienteConsorcio
        })
        this.seekProposal();  
      }else{
        this.setState({isOpen:false});
      }
  }

  alteraAbas = async aba => {
    this.setState({ blocoAberto: aba });
    const abas = ["Cadastro","Historico"];

    if(aba == 'Cadastro'){
      this.seekProposal();
    }

    var tmp_cadastro = false;
    var tmp_historico = false;
    var bck_cadastro = "#16B6C5";
    var bck_historicao = "#16B6C5";

    for(var i = 0; i < abas.length; i++){
      if(aba == "Cadastro"){ 
        tmp_cadastro = true; 
        bck_cadastro = "#ccc";  
      }
      if(aba == "Historico"){ 
        tmp_historico = true; bck_historicao = "#ccc"; 
      }
    }

    this.setState({ blocoCadastro : tmp_cadastro, blocoHistorico: tmp_historico });
    this.setState({ backgroundCadastro : bck_cadastro, backgroundEndereco: bck_historicao });

  };

  handleRecusar = async (e) => {
    var classe = this;
    Swal.fire({
        title: "Atenção!",
        text: "Você tem certeza que deseja reprovar essa proposta?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim!",
        cancelButtonText: "Não!",
    }).then((result) => {
        if (result.value) {
          classe.handleEfetivarRecusa(e);
        }
    }); 
  };

  handleAceitar = async (e) => {
    if(this.state.isChecked && !this.state.valor){
      Swal.fire({
        title: "Atenção!",
        text: this.state.ultimaProposta ? "Informe o valor da Proposta" : "Informe o valor da contraproposta",
        icon: "warning",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      }).then((result) => {}); 
    }else{
      var classe = this;
      Swal.fire({
          title: "Atenção!",
          text: this.state.isChecked ? "Você tem certeza que deseja enviar essa contraproposta?" : "Você tem certeza que deseja aprovar essa proposta?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim!",
          cancelButtonText: "Não!",
      }).then((result) => {
          if (result.value) {
            classe.handleEfetivarAprovacao(e);
          }
      }); 
    }
  };

  handleEfetivarRecusa = async (e) => {
    e.preventDefault();
    
    try {
      this.setState({isOpen:true});
        const response = await api.put(
            "/api/concash/1.0/cliente-proposta/recusa-proposta",
            { codigoProposta: this.state.codigoProposta }
        );
        this.setState({isOpen:false});
        Swal.fire({
          title: "Atenção!",
          text: "Operação realizada com sucesso!",
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        }).then((result) => {
            if (result.value) {
              this.props[0].history.push("/consorcio-grupo-cota");
            }
        }); 
    } catch (err) {
      this.setState({isOpen:false});
        Swal.fire(err.response.data);
    }
  };

  handleEfetivarAprovacao = async (e) => {
    e.preventDefault();
    
    try {
      this.setState({isOpen:true});
      let val = money(String(this.state.valor));
      let valFormt = val.replace('.','');
      if(this.state.isChecked && !this.state.ultimaProposta){
        const response = await api.put(
          "/api/concash/1.0/cliente-proposta/registrar-contraproposta",
          {
            codigoProposta:this.state.codigoProposta,
            valorContraProposta: valFormt.replace(',','.'),
          }
        );
      }else if(this.state.isChecked && this.state.ultimaProposta){
        const response = await api.put(
          "/api/concash/1.0/cliente-proposta/registrar-contraproposta",
          {
            clienteConsorcio:this.state.cota.idClienteConsorcio,
            valorContraProposta: valFormt.replace(',','.'),
          }
        );
      }else{
        const response = await api.put(
            "/api/concash/1.0/cliente-proposta/aceite-proposta",
            { codigoProposta:this.state.codigoProposta }
        );
      }
        this.setState({isOpen:false});
        Swal.fire({
          title: "Atenção!",
          text: "Operação realizada com sucesso!",
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
      }).then((result) => {
          if (result.value) {
            this.props[0].history.push("/consorcio-grupo-cota");
          }
      }); 
        
    } catch (err) {
        this.setState({isOpen:false});
        Swal.fire(err.response.data);
    }
  };
  toggleCheckboxChange = (e) => {
    this.setState(({ isChecked }) => (
      { isChecked: !isChecked, }
    ));
  }

  handleChangeContra(valor){  
      const numBruto = parseFloat(valor);  
      this.setState({ valor: numBruto.toLocaleString('pt-br', {minimumFractionDigits: 2})})
  }

  handleBack(){
    this.props[0].history.push("/consorcio-grupo-cota");
  }

  render() {
    const { isChecked } = this.state;
    
    return (
      this.state.isOpen ? (
        <div style={{ display: 'flex', flex:1, width: '100%', height:'100%', alignItems:'center', justifyContent:'center'}}>
          <Backdrop sx={{ color: '#16B6C5', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={this.state.isOpen} >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      ) : (
        <div>
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                      <Aba
                          text="Proposta"
                          initial={ this.state.blocoAberto === "Cadastro" ? true : false }
                          icon={<BsCashCoin />}
                          action={(e) => this.alteraAbas("Cadastro")}
                      />
                      <Aba
                          text="Histórico"
                          initial={this.state.blocoAberto === "Historico" ? true : false}
                          icon={<BsCardList />}
                          action={(e) => this.alteraAbas("Historico")}
                      />
                  </div>

                  {this.state.blocoCadastro &&
                    <div className="row">
                      <div className="col-md-12" 
                        style={{
                            background: "#fff",
                            minHeight: "300px",
                            borderRadius: 15,
                            margin: 0,
                            boxShadow: "0 0 5px 5px rgba(204,204,204,0.6)",
                          }}
                      >
                        <div className="row" style={{marginTop:'20px'}}>
                          <div className="col-md-12 grid-margin stretch-card">
                            <div className="card">
                              <div className="card-body">
                                <form className="forms-sample">
                                  <div className="row" style={{marginTop:'-10px'}}>
                                    <div className='box-form-input-miu'>
                                      <TextField className='input-form-miu' inputProps={{ readOnly: true, }} id="Grupo" label="Grupo" value={this.state.cota.cgcGrupo} variant="outlined" required />                                                
                                    </div>
                                    <div className='box-form-input-miu'>
                                      <TextField className='input-form-miu' id="Cota" label="Cota" inputProps={{ readOnly: true, }}
                                        value={ this.state.cota.cgcCota } variant="outlined"/>
                                    </div>
                                    <div className='box-form-input-miu'>
                                      <TextField className='input-form-miu'  id="versao" label="Versão" inputProps={{ readOnly: true, }} value={this.state.cota.cgcVersao} variant="outlined" />                                                
                                    </div>
                                    <div className='box-form-input-miu'>
                                      <TextField className='input-form-miu' id="valor" label="Valor(Crédito)" inputProps={{ readOnly: true, }} value={this.state.cota.cgcValorCredito} variant="outlined" />                                                
                                    </div>
                                  </div>
                                  <div className="row" style={{marginTop:'10px'}}>
                                    <div className='box-form-input-miu'>
                                      <TextField className='input-form-miu' id="nome" label="Nome" inputProps={{ readOnly: true, }} value={this.state.cota.cgcSadNome} variant="outlined" />                                                
                                    </div>
                                    <div className='box-form-input-miu'>
                                      <TextField className='input-form-miu' id="bem" label="Descrição do bem" inputProps={{ readOnly: true, }}
                                        value={ this.state.cota.cgcDescricaoBem } variant="outlined"/>
                                    </div>
                                  </div>
                                  <div className="row" style={{marginTop:'10px'}}>
                                    {!this.state.ultimaProposta ? 
                                    (
                                      <div className='box-form-input-miu' style={{ minWidth: '49.5%' }}>
                                        <TextField className='input-form-miu' id="proposta" label="Proposta" inputProps={{ readOnly: true, }} value={this.state.valorProposta} variant="outlined" required />                                                
                                      </div>                                      
                                    ) : (
                                      <div className='box-form-input-miu' style={{ minWidth: '49.5%' }}></div>
                                    )}
                                    {!this.state.bloqueado && (
                                      <> 
                                        <div className='col-sm-3'>
                                          <Checkbox checked={isChecked} onChange={e => this.toggleCheckboxChange(e)}/>
                                          <label htmlFor="examplePropostal" >{this.state.ultimaProposta ? 'Fazer uma última proposta ?' : 'Fazer uma contraproposta'}</label>
                                        </div>
                                      </> 
                                    )}
                                    {isChecked ? 
                                    (
                                      <div className='box-form-input-miu' style={{width:'25%'}}>            
                                        {this.state.ultimaProposta && (
                                          <TextField className='input-form-miu' id="valor"  label="Proposta" onChange={e => this.setState({valor: e.target.value})}  value={money(this.state.valor)} variant="outlined"  />
                                        )}
                                        {!this.state.ultimaProposta && (
                                          <TextField className='input-form-miu' id="valor"  label="Contraproposta" onChange={e => this.setState({valor: e.target.value})}  value={money(this.state.valor)} variant="outlined"  />
                                        )}                           
                                      </div>
                                    ) : (
                                      <div className='box-form-input-miu'></div>   
                                    )}
                                  </div>  
                                  <div className="row" style={{marginTop:'10px'}}>
                                    <div className='box-form-input-miu' style={{float:"left"}}>
                                      <ButtonGroup
                                          variant="contained"
                                          size="small"
                                          aria-label="small button group"
                                          style={{width: "40%",height:45, border:"1px solid white"}}
                                        >
                                          <Button
                                            className="button-action-page"
                                            style={{ marginRight:15, width:"50%" }}
                                            onClick={ (e) => this.handleBack() }
                                          >
                                            <span style={{ fontSize: 13, color: "white" }} > Voltar </span>
                                          </Button>
                                      </ButtonGroup>
                                    </div>
                                    <div className='box-form-input-miu' style={{display:"flex", justifyContent:"flex-end",}}>
                                      {(this.state.codigoProposta != 5 && !this.state.bloqueado) &&(
                                        <ButtonGroup
                                          variant="contained"
                                          size="small"
                                          aria-label="small button group"
                                          className='pull-right'
                                          style={{width: "40%",height:45,float:"right", border:"1px solid white"}}
                                        >
                                          <Button
                                            className="button-action-page"
                                            style={{
                                              backgroundColor:"4caf50", width:"50%",
                                              marginRight:isChecked || this.state.ultimaProposta  ? 0 : 15
                                            }}
                                            onClick={(e) => this.handleAceitar(e) }
                                          >
                                            <span style={{ fontSize: 13, color: "white" }}>
                                              {isChecked && !this.state.ultimaProposta ? 'Enviar Contraproposta' : this.state.ultimaProposta ? 'Enviar' : 'Aprovar'}
                                            </span>
                                          </Button>
                                          {(!isChecked && !this.state.ultimaProposta) &&(
                                            <Button
                                              style={{ backgroundColor:"#ef5350", width:"50%" }}
                                              onClick={(e) => this.handleRecusar(e)}
                                            >
                                              <span style={{ fontSize: 13, color: "white" }} > Reprovar </span>
                                            </Button>
                                          )}
                                        </ButtonGroup>
                                      )}
                                    </div>
                                  </div>  
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  {this.state.blocoHistorico &&
                      <div className="row">
                          <div className="col-md-12" style={{
                            background: "#fff",
                            minHeight: "300px",
                            borderRadius: 15,
                            margin: 0,
                            boxShadow: "0 0 5px 5px rgba(204,204,204,0.6)",
                          }}>
                              <HistoricoProposta idClienteConsorcio={this.state.idClienteConsorcio} />
                          </div>
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    )
  }
}

export default withRouter(PropostaForm)
