import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker, { registerLocale } from "react-datepicker";
import FloatingLabelInput from 'react-floating-label-input';
import moment from 'moment';
import api from "../../services/api";
import SimuladorSimplificado from "../../components/SimuladorSimplificado";
import Proposta from "../../components/Proposta";
import Assinatura from "../../components/Assinatura";
import Inlira from "../../components/Inlira";
import Pagamento  from "../../components/Pagamento";
import Extrato    from "../../components/Extrato";
import { cellphoneFormater,currencyFormater,numberFormater} from '../../lib/formaters';
import IntlCurrencyInput from "react-intl-currency-input";
import InputFloat from 'react-floating-input';
import ptBR from "date-fns/locale/pt-BR";
import Swal from 'sweetalert2'
import { Link, withRouter } from "react-router-dom";
import { cpfCnpj } from '../../mask/cpfcnpj';
import { Card, Content } from './styles';


registerLocale("pt-BR", ptBR);

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};


export class UploadCotaForm extends Component {



  constructor(props){

        super(props);

        this.state = {
          extrato: "",
          error: ""
        };
  }



  async componentDidMount() {      

  } 

  handleForm = async e => {
    e.preventDefault();

    const {extrato} = this.state;
    if (!extrato) {
      this.setState({ error: "Selecione o extrato para continuar!" });
    } else {


      try {
        const formData    = new FormData();
        formData.append('extrato',extrato);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        const response  = await api.post(`/api/concash/1.0/cliente/importar-cota`,formData,config);              
        
        if(response.status === 200){
          Swal.fire('Arquivo importado com sucesso!');
        }else{
          Swal.fire('Houve um problema ao importar o arquivo, tente novamente mais tarde!');
        }
         
      } catch (err) {
        Swal.fire('Cota não cadastrada!');
      }
    }

  };  

  render() {


    const const_cliente = this.state.cliente;

    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> {this.state.labelForm} </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>

        <div className="row">


          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
              <Card>
                <form className="forms-sample" onSubmit={this.handleForm}>
                  <div className="row">

                    <div className="col-md-12 grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">
                          <div className='row'>
                            <div className="col-md-12">
                              <Content>
                                <h3>Cota</h3>
                              </Content>
                            </div>
                            <p className="card-description"></p>
                            {this.state.error && <p className="card-description">{this.state.error}</p>}
                            <div className="col-md-12">
                              <Form.Group className="row">
                                <label htmlFor="examplePropostal" className="col-sm-12 col-form-label">Arquivo</label>
                                <div className="col-sm-7">
                                  <Form.Control type="file" className="form-control" id="file" onChange={e =>  this.setState({extrato : e.target.files[0]}) } accept=".pdf"   size="sm"/>
                                </div>
                                <div className='col-sm-5'>
                                  <div className="text-left">
                                      <button type="submit" className="btn btn-primary mr-4">Importar</button>
                                  </div>
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>                
                  </div>
                </form>
              </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(UploadCotaForm)
