import Swal from "sweetalert2";

export function modalLoading(title, text){
    Swal.fire({
        title: title,
        html: text,
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
        },
    })
}