import { Backdrop } from '@mui/material';
import React, { useState } from 'react';
import S from "./Modal.module.css";
import { ButtonMain } from '../../buttons';
import { MdOutlineClose } from "react-icons/md";
import { useDispatch } from 'react-redux';
import { negociarCota } from '../../../../../store/autoservico';


export const ModalAutoServico = ({proposta, estilo, onClick, idAtendimento}) => {
  const service = window.location.pathname.replace("/autoservico/", "").split("/");
  const adm = service[0];
  const dispatch = useDispatch();

  function handleGotoAccept() {
    dispatch(negociarCota(idAtendimento));
    window.location.href = `/autoservico/${service[0]}/meus-dados`
  }

  if(proposta) return (
    <div className={S.container}>
      <div className={S.textContentBox}>
        <div className={S.contentRow}>
        <span className={S.text15}>Valor da venda:</span>
        </div>
        <div  className={S.contentRow}>
        <span className={S.propostaText}>{proposta?.proposta}</span> 
        </div>
        <div  className={S.contentRow}>
        <span className={S.standardText}>Com pagamento à vista em até {proposta?.dias} dias úteis, mediante a transferência da cota.</span> 
        </div>
      </div>
      <div className={S.buttonBox}>
      <ButtonMain estilo={estilo} tamanho={96} texto={"VENDER"} onClick={handleGotoAccept}  />
      </div>
    </div>
  );
}

export const ModalStandard = ({open, estilo, onClose, texto}) => {


  return (
    <Backdrop open={open}   
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <div className={S.containerStandard}>
        <div style={{ display:"flex"}}>
          <h1 className={S.title}>Entenda o valor da venda</h1>
          <div className={S.buttonClose} onClick={onClose}>
            <MdOutlineClose size={34} />
          </div>
        </div>
        <div className={S.textBox}>
            <span className={S.textMain}>{texto}</span>
        </div>
        <ButtonMain estilo={estilo} onClick={onClose} texto={"Entendi"} tamanho={"100%"} />
      </div>
    </Backdrop>
  );
}
export const ModalMain = ({open, estilo, onClose, texto, title, width, height}) => {


  return (
    <Backdrop open={open}   
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <div className={S.modalMain} style={{ width: width, height: height}}>
        <div style={{ display:"flex", width: '100%'}}>
          <div style={{ display: "flex", flex: 1}}>
          <h1 className={S.title}>{title ? title : "Atenção..."}</h1>
          </div>
          <div className={S.buttonCloseMain} onClick={onClose}>
            <MdOutlineClose size={34} />
          </div>
        </div>
        <div className={S.textBox}>
            <span className={S.textMain}>{texto}</span>
        </div>
        <ButtonMain estilo={estilo} onClick={onClose} texto={"Entendi"} tamanho={"100%"} />
      </div>
    </Backdrop>
  );
}

export const ModalFinalizar = ({estilo, onClick, disabled}) => {
  const isMobile = window.innerWidth <= 768;

  return (
    <div className={S.modalStandard} style={{ zIndex: 1000 }}>
      <span>Para continuar, nenhum dado acima pode estar pendente.</span>
      <ButtonMain estilo={estilo} texto="Continuar" tamanho={isMobile ? "100%" : 412} onClick={onClick} disabled={disabled} />
    </div>
  )
}
