import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import api from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import { Link, withRouter } from "react-router-dom";

export class Processo extends Component {
    constructor(props) {
        super(props);

        const { codigoCota } = this.props;

        this.state = {
            codigoCota: codigoCota,
            processos: [],
            error: "",
        };
    }

    async componentDidMount() {
        this.obtemProcessos(this.state.codigoCota);
    }

    async obtemProcessos(codigo) {
        try {
            const response = await api.post("api/concash/1.0/processo/listar", {
                codigoCota: codigo,
            });
            this.setState({ processos: response.data });
        } catch (err) {
            Swal.fire("Houve um problema para obter os processos.");
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Processos</h4>
                            <p className="card-description"></p>

                            <div className="table-responsive">
                                <table
                                    className="table table-striped"
                                    style={{ marginTop: "50px" }}
                                >
                                    <thead>
                                        <tr>
                                            <th> Código </th>
                                            <th> Tipo </th>
                                            <th> Data </th>
                                            <th> Status </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.processos.map(
                                            (processo, index) => (
                                                <tr key={index}>
                                                    <td> {processo.codigo} </td>
                                                    <td> {processo.etapa} </td>
                                                    <td>
                                                        {" "}
                                                        {processo.dataIni}{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        {processo.situacao}{" "}
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Processo;
