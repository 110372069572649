import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import api from "../../services/api";
import { Link } from "react-router-dom";
import SweetAlert from 'sweetalert2-react';
import Swal from 'sweetalert2'
import Pagination from "../../components/Pagination";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AlteraNome } from '../../actions';




export class ComercialListaCotaAguardandoDocumentacao extends Component {



  constructor(props){

        super(props);

        this.state = {
          propostas: [],
        };
  }

  async componentDidMount() {

        this.obtemCotasReprovadas();
  }

  async obtemCotasReprovadas(){

      try {
        const response = await api.post("api/concash/1.0/visao-cota-aguardando-documentacao/listar", {  });
        this.setState({ propostas: response.data});
      } catch (err) {
        this.setState({
          error:
            "Houve um problema para obter a lista de cotas propostas recusadas."
        });
      }
  }


  render() {



    return (

      <div>
        <div className="page-header">
          <h3 className="page-title"> Cotas Aguardando Documentação </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>


        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">

                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th> Cliente        </th>
                          <th> CPF            </th>
                          <th> Cota           </th>
                          <th> Grupo          </th>
                          <th> Administradora </th>
                          <th className="text-center"> Ação </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.propostas.map((proposta,index) => (
                        <tr key={index}>
                          <td> {proposta.nome} </td>
                          <td> {proposta.cpf} </td>
                          <td> {proposta.contrato} </td>
                          <td> {proposta.grupo} </td>
                          <td> {proposta.administradora} </td>
                          <td className="text-center"><Link
                                          to={{pathname: "/comercial/negociacoes/detalhamento",state: {
                                            codigo: proposta.codigo
                                          }}}
                                          className="m-3 btn btn-sm btn-success"
                                        >
                                        Detalhe
                              </Link>
                          </td>
                        </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = store => ({
  payload: store.AutenticacaoReducer.nome
});

function mapDispatchToProps(dispatch) {
    return {
        alteraNome: (id) => {
            dispatch(AlteraNome(id));
        },
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(ComercialListaCotaAguardandoDocumentacao);
