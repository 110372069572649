import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker, { registerLocale } from "react-datepicker";
import FloatingLabelInput from 'react-floating-label-input';
import moment from 'moment';
import api from "../../services/api";
import SimuladorSimplificado from "../../components/SimuladorSimplificado";
import { cellphoneFormater,currencyFormater,numberFormater} from '../../lib/formaters';
import IntlCurrencyInput from "react-intl-currency-input";
import InputFloat from 'react-floating-input';
import ptBR from "date-fns/locale/pt-BR";
import Swal from 'sweetalert2';
import { Link, withRouter } from "react-router-dom";
import cep from 'cep-promise';
import SolicitarReanalise from "../../components/SolicitarReanalise";

registerLocale("pt-BR", ptBR);

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};


export class AnalisarCotaReprovada extends Component {



  constructor(props){

        super(props);

        this.state = {
          startDate: new Date(),
          inputVal: '',
          grupo: '',
          cota: '',
          clienteNome: '',
          codigoProposta: '',
          origem: '',
          valorProposta: 0,
          administradoraNome: '',
          cpf: '',
          propostaValor1: 0,
          valorContraProposta: 0,
          propostaValor2: 0,
          propostaValor3: 0,
          propostas:[],
          reanalises:[],
          codigo: '',
          error: ""
        };
  }



  async componentDidMount() {

      if(this.props[0].location.state !== undefined){

          const obj_cota  = this.props[0].location.state.cota
          this.setState({clienteNome:obj_cota.nome,cpf: obj_cota.cpf,administradoraNome:obj_cota.administradora,cota: obj_cota.contrato,grupo: obj_cota.grupo,codigo: obj_cota.codigo});
          this.obtemPropostas(obj_cota.codigo);
          this.setState({labelForm: "Analisar Propostas",labelButton: "Editar",action: "editar"});
      }
  }

  handleChangeValorContraProposta = (event, value, maskedValue) => {
    event.preventDefault();
    this.setState({ valorContraProposta: maskedValue.replace("R$","").replace(".","").replace(",",".").trim()   });
  };

  async  obtemPropostas(codigo){
    try {
          const response = await api.post("api/concash/1.0/consorcio-grupo-cota/proposta", {codigoCota:codigo});
          const resultado   = JSON.parse(response.data[response.data.length -1].resultado);
          this.setState({ propostas: response.data, codigoProposta: response.data[response.data.length -1].codigo ,propostaValor1: resultado["Proposta1ValorPuro"], propostaValor2: resultado["Proposta2ValorPuro"],propostaValor3: resultado["Proposta3ValorPuro"]});
    } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter as propostas."
          });
    }
  }



  handleFormProposta = async e => {
    e.preventDefault();
    const { origem,
            valorProposta} = this.state;
    if (!origem
        || !valorProposta) {
      this.setState({ error: "Preencha os campos obrigatórios para continuar!" });
    } else {
      try {
            let codigo        = this.state.codigo;
            const response    = await api.post("api/concash/1.0/consorcio-grupo-cota-proposta/adicionar", {
              origem:origem,valor:valorProposta,cota:codigo
            });
            this.obtemPropostas(codigo);
            Swal.fire ('Proposta cadastrada com sucesso!');

      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o processamento da requisição."
        });
      }
    }
  };




  render() {


    const const_cliente = this.state.cliente;

    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> {this.state.labelForm} </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>

        <div className="row">


          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">

                  <div className="row" style={{backgroundColor:'rgb(222, 226, 230)'}}>

                    <div className="col-md-12" style={{marginTop:'30px'}}>
                    <div className="row">
                      <div className="col-md-3">
                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>CPF/CNPJ Cliente​</label>
                          </Form.Group>
                      </div>
                      <div className="col-md-3">
                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Nome Consorciado</label>
                          </Form.Group>
                      </div>
                      <div className="col-md-2">
                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Administradora</label>
                          </Form.Group>
                      </div>
                      <div className="col-md-2">
                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Grupo</label>
                          </Form.Group>
                      </div>
                      <div className="col-md-2">
                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Cota</label>
                          </Form.Group>
                      </div>
                    </div>
                    <div className="row" style={{marginTop:'-20px'}}>
                          <div className="col-md-3">
                              <Form.Group className="row">
                                <div className="col-sm-12"  style={{textAlign:'center'}}>
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="cpf" value={this.state.cpf} readOnly onChange={e => this.setState({ cpf: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                          <div className="col-md-3">
                              <Form.Group className="row">
                                <div className="col-sm-12" style={{textAlign:'center'}}>
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="clienteNome" value={this.state.clienteNome} readOnly onChange={e => this.setState({ clienteNome: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                          <div className="col-md-2">
                              <Form.Group className="row">
                                <div className="col-sm-12"  style={{textAlign:'center'}}>
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="administradoraNome" value={this.state.administradoraNome} readOnly onChange={e => this.setState({ administradoraNome: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                          <div className="col-md-2">
                              <Form.Group className="row">
                                <div className="col-sm-12"  style={{textAlign:'center'}}>
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="grupo" value={this.state.grupo} readOnly onChange={e => this.setState({ grupo: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                          <div className="col-md-2">
                              <Form.Group className="row">
                                <div className="col-sm-12"  style={{textAlign:'center'}}>
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="cota" value={this.state.cota} readOnly onChange={e => this.setState({ cota: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                      </div>


                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>


          <div className="row">

            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Propostas</h4>
                  <p className="card-description"></p>


                  {this.state.propostaValor1 &&
                  <form className="forms-sample" onSubmit={this.handleFormProposta}>


                  <div className="row">

                    <div className="col-md-12 grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">
                              <Form.Group className="row">
                                <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Origem</label>
                                <div className="col-sm-9">
                                  <select className="form-control" value={this.state.origem} onChange={e => this.setState({ origem: e.target.value })} id="origem">
                                    <option value="">Selecione</option>
                                    <option value="RESULTADO_COTA">RESULTADO_COTA</option>
                                    <option value="ORIGINACAO_FUNDO">ORIGINACAO_FUNDO</option>
                                    <option value="FUNDO_MB">FUNDO_MB</option>
                                    <option value="FUNDO_CARREGAMENTO">FUNDO_CARREGAMENTO</option>
                                    <option value="CONCASH_CARREGAMENTO">CONCASH_CARREGAMENTO</option>
                                  </select>
                                </div>
                              </Form.Group>
                              <Form.Group className="row">
                                <label className="col-sm-3 col-form-label">Valor Proposta</label>
                                <div className="col-sm-9">
                                      <select className="form-control"  onChange={e => this.setState({ valorProposta: e.target.value })} id="valorProposta">
                                        <option value="">Selecione</option>
                                        <option value={this.state.propostaValor1}>{this.state.propostaValor1}</option>
                                        <option value={this.state.propostaValor2}>{this.state.propostaValor2}</option>
                                        <option value={this.state.propostaValor3}>{this.state.propostaValor3}</option>
                                      </select>
                                </div>
                              </Form.Group>
                              <div className="text-right">
                                <button type="submit" className="btn btn-primary mr-2">Adicionar</button>
                              </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  </form>
                  }

                  <SolicitarReanalise codigoCota={this.state.codigo} />

                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th> Código </th>
                          <th> Valor  </th>
                          <th> Origem </th>
                          <th> Status </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.propostas.map((proposta,index) => (
                        <tr key={index}>
                          <td> {proposta.codigo} </td>
                          <td> {proposta.valor} </td>
                          <td> {proposta.origem} </td>
                          <td> {proposta.aprovado} </td>
                        </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

      </div>
    )
  }
}

export default withRouter(AnalisarCotaReprovada)
