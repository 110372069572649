import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import ParametroFieldTipoConsorcio from "../../lib/ParametroFieldTipoConsorcio";
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";



export class BasicElements extends Component {



  constructor(props){

    super(props);

    this.state = {
          tipoConsorcio: '',
          labelForm: '',
          labelButton: '',
          configurados: [],
          tipoConsorcioParametros: [],
          marcados:[],
          error: "",
          action: ""
    };
  }


  async componentDidMount() {

        if(this.props[0].location.state !== undefined){
            const tpConsorcio  = this.props[0].location.state.tipoConsorcio
            this.setState({tipoConsorcio: tpConsorcio},this.obtemTipoConsorcioParametros);
            this.setState({labelForm: "Editar Parâmetro ",labelButton: "Configurar"});
        }else{
            this.setState({labelForm: "Adicionar  Parâmetro ",labelButton: "Configurar"});
        }
  }

  async obtemTipoConsorcioParametros() {
    try {
          const tpConsorcio  = this.state.tipoConsorcio;
          const response     = await api.post("api/concash/1.0/parametro-tipo-consorcio/listar", {  codigoTipoConsorcio: this.state.tipoConsorcio.codigo,status: 'ATIVO' });
          var fAcoes         = [];

          for(var i=0; i < response.data.length;i++){
              fAcoes[response.data[i].parametro.nome] = response.data[i].valor;
          }
          this.setState({ configurados: fAcoes});

          try {
                const response2 = await api.post("api/concash/1.0/tipo-parametro/listar", {});
                var   codigo = "";
                for(var i=0; i < response2.data.length;i++){
                    if(response2.data[i].tipo == "Tipo Consórcio"){
                        codigo = response2.data[i].codigo;
                    }
                }

                try {
                      const response3 = await api.post("api/concash/1.0/parametro/listar", {tipo: codigo});
                      this.setState({ tipoConsorcioParametros: response3.data});
                } catch (err) {
                      this.setState({
                        error:
                          "Houve um problema para obter os parametros e ações."
                      });
                }

          } catch (err) {
                this.setState({
                  error:
                    "Houve um problema para obter os parametros e ações."
                });
          }


    } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter os parametros."
          });
    }
  }



  render() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> {this.state.labelForm} Tipo Consórcio : {this.state.tipoConsorcio.produto} </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>

        <div className="row">

          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"></h4>
                {this.state.error && <p className="card-description">{this.state.error}</p>}
                <form className="forms-sample" onSubmit={this.handleForm}>
                {this.state.tipoConsorcioParametros.map((ap,index) => (
                  <ParametroFieldTipoConsorcio tipoConsorcio={this.state.tipoConsorcio}  parametro={ap} configurados={this.state.configurados} key={index}/>
                ))}
                <div className="row">
                    <div className="col-md-12">
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
         </div>
       </div>
    )
  }
}

export default withRouter(BasicElements);
