import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";
import Swal from 'sweetalert2';


export class FormMinhaConta extends Component {



  constructor(props){

    super(props);

    this.state = {
          nome: '',
          email: '',
          username: '',
          password: '',
          error: ""
    };
  }


  async componentDidMount() {


    const response = await api.post("/api/sistema/1.0/usuario/detalhe", {  });
    this.setState({ nome: response.data.name,email: response.data.email,username: response.data.username});
  }

  handleAlterarSenha = async e => {

    e.preventDefault();
    const { password } = this.state;
    if (!password) {
      this.setState({ error: "Preencha os campos obrigatórios para continuar!" });
    } else {

        try {

              const response = await api.put("api/sistema/1.0/usuario/alterar-senha", {
                password
              });

              Swal.fire ('Senha alterada com sucesso!');
        } catch (err) {
              Swal.fire ('Houve um problema com o processamento da requisição.');
        }
    }
  };

  render() {


    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Minha Conta </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>

        <div className="row">

          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"></h4>

                <form className="forms-sample" onSubmit={this.handleAlterarSenha}>

                <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Nome</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="nome" value={this.state.nome}  placeholder="Nome" size="sm" disabled />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">E-mail</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="email" value={this.state.email} placeholder="E-mail"  size="sm"  disabled />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Username</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="username" value={this.state.username} placeholder="Username" size="sm"  disabled />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Senha</label>
                        <div className="col-sm-7">
                        <Form.Control type="password" id="password" value={this.state.password} placeholder="Senha" size="sm"  onChange={e => this.setState({ password: e.target.value })} />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="text-right">
                    <button type="submit" className="btn btn-primary mr-2">Alterar Senha</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
         </div>
       </div>
    )
  }
}

export default withRouter(FormMinhaConta)
