import React, { Component, PropTypes } from 'react';
import { Form } from 'react-bootstrap';
import api from "../services/api";

class CheckboxUsuarioFuncionalidade extends Component {

  contabilizado   = 0;
  contadorExcluir = 0;

  constructor(props){

    super(props);
    const { marcados }           = this.props;
    const { funcionalidade }     = this.props;
    const { user }               = this.props;
    var marcado                  = false;

    if(marcados.includes(funcionalidade.codigo)){
        marcado = true;
    }
    this.state = {
      isChecked: marcado,
      funcionalidade: funcionalidade,
      user: user
    }


  }

  toggleCheckboxChange = (e) => {


    this.setState(({ isChecked }) => (
      {
        isChecked: !isChecked,
      }
    ));
  }

  shouldComponentUpdate(nextProps,nextState){

     if(nextState.isChecked == true){
         if(this.contabilizado == 0){
           this.handleAdicionaConfiguracao();
           this.contabilizado = 1;
         }else{
           this.contabilizado = 0;
         }
     }else{
       if(this.contadorExcluir == 0){
         this.handleRemoveConfiguracao();
         this.contadorExcluir = 1;
       }else{
         this.contadorExcluir = 0;
       }
     }

     return true;
  }

  async handleRemoveConfiguracao(){

    try {
         const response = await api.put("api/sistema/1.0/usuario-permissao/excluir", {
            codigoFuncionalidadeAcao: this.state.funcionalidade.codigo ,user: this.state.user
        });
    } catch (err) {
    }

  }

  async handleAdicionaConfiguracao(){

    try {
         const response = await api.post("api/sistema/1.0/usuario-permissao/adicionar", {
            codigoFuncionalidadeAcao: this.state.funcionalidade.codigo ,user: this.state.user
          });
    } catch (err) {
    }

  }



  render() {

    const { isChecked }          = this.state;
    const { funcionalidade }     = this.props;

    return (

      <div className="row" >
        <div className="col-md-12">
          <Form.Group className="row" >
            <label className="col-sm-5 col-form-label">{funcionalidade.funcionalidade.funcionalidade} - {funcionalidade.acao.acao}</label>
            <div className="col-sm-7">
              <Form.Control type="checkbox" value={funcionalidade.codigo} checked={isChecked} onChange={ e => this.toggleCheckboxChange(e)}   size="sm" />
            </div>
          </Form.Group>
        </div>
      </div>
    );
  }
}


export default CheckboxUsuarioFuncionalidade;
