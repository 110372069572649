import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import api from "../../services/api";
import { Link } from "react-router-dom";
import SweetAlert from 'sweetalert2-react';
import Swal from 'sweetalert2'



export class VisaoClientePropostaRecusada extends Component {



  constructor(){
        super();
        this.state = {
          clientes: [],
          show: false
        };
  }

  async componentDidMount() {

        try {
          const response = await api.post("api/concash/1.0/visao-cliente-proposta-recusada/listar", {});
          this.setState({ clientes: response.data});
        } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter os clientes bloqueados."
          });
        }
  }


  render() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Clientes Proposta Recusada</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th> ID </th>
                          <th> Administradora </th>
                          <th> Cota </th>
                          <th> Grupo </th>
                          <th> Valor Proposta </th>
                          <th> Cliente </th>
                          <th> Motivo Recusa</th>
                          <th className="text-center"> Ação </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.clientes.map((cliente,index) => (
                        <tr key={index}>
                          <td> {cliente.id} </td>
                          <td> {cliente.administradora} </td>
                          <td> {cliente.cota} </td>
                          <td> {cliente.grupo} </td>
                          <td> {cliente.valorproposta} </td>
                          <td> {cliente.usuario} </td>
                          <td> {cliente.motivo} </td>
                          <td className="text-center"><Link
                                          to={{pathname: "/backoffice/detalhamento-cota",state: {
                                            codigo: cliente.codigo
                                          }}}
                                          className="m-3 btn btn-sm btn-success"
                                        >
                                        Contra Proposta
                              </Link>
                          </td>
                        </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default VisaoClientePropostaRecusada
