export var maskPhoneWithoutDDD = value => { 
    const numero = value.replace(/\D/g, "");
    let textoAjustado;
    



    if(numero.length === 9) {
        const parte1 = numero.slice(0,5);
        const parte2 = numero.slice(5,9);
        return textoAjustado = `${parte1}-${parte2}`        
    } else {
        const parte1 = numero.slice(0,4);
        const parte2 = numero.slice(4,8);
        return textoAjustado = `${parte1}-${parte2}`
    }

}

    // return numero.replace(/^(\d{5})(\d{4})+?$/, "$1-$2");
