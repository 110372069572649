import React from 'react';
import CodeInput from 'react-code-input';
import "./style.css";

const AutoJumpInputs = ({acao}) => {
  const numInputs = 1;
  const maxInputLength = 6;
  const inputRefs = Array.from({ length: numInputs }, () => React.createRef());

  const handleInputChange = (index, value) => {
    if (value.length >= maxInputLength && index < numInputs - 1) {
      inputRefs[index + 1].current.focus();
    }
  };


  const props = {
    inputStyle: {
      fontFamily: 'Roboto',
      margin:  '4px',
      MozAppearance: 'textfield',
      alignItems: "center",
      width: '37px',
      borderRadius: '8px',
      fontSize: '20px',
      height: '45px',
      backgroundColor: 'white',
      color: '#ABABAB',
      border: '1px solid #ABABAB',
      textAlign: 'center'
    },
    inputStyleInvalid: {
      fontFamily: 'monospace',
      margin:  '4px',
      MozAppearance: 'textfield',
      width: '15px',
      borderRadius: '3px',
      fontSize: '14px',
      height: '26px',
      paddingLeft: '7px',
      backgroundColor: 'black',
      color: 'red',
      border: '1px solid red'
    }
  }


  return (
    <div>
      {inputRefs.map((inputRef, index) => (
        <CodeInput
        {...props}
          key={index}
          ref={inputRef}
          type="text"
          fields={maxInputLength}
          onChange={(value) => {
            if(value !== "") {
              acao(value)
            } else {
              acao("")
            }
            handleInputChange(index, value);
          }}
          onComplete={(value) => {
            if (index < numInputs - 1) {
              inputRefs[index + 1].current.focus();
            }
          }}
        />
      ))}
    </div>
  );
};

export default AutoJumpInputs;
