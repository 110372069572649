import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker, { registerLocale } from "react-datepicker";
import FloatingLabelInput from 'react-floating-label-input';
import moment from 'moment';
import api from "../../services/api";
import SimuladorSimplificado from "../../components/SimuladorSimplificado";
import { cellphoneFormater,currencyFormater,numberFormater} from '../../lib/formaters';
import IntlCurrencyInput from "react-intl-currency-input"
import InputFloat from 'react-floating-input'
import ptBR from "date-fns/locale/pt-BR";
import Swal from 'sweetalert2'
import { Link, withRouter } from "react-router-dom";
import cep from 'cep-promise'

registerLocale("pt-BR", ptBR);

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};


export class ConsorcioGrupoCotaForm extends Component {



  constructor(props){

        super(props);

        this.state = {
          startDate: new Date(),
          inputVal: '',
          grupo: '',
          cota: '',
          clienteNome: '',
          administradoraNome: '',
          cpf: '',
          documentos:[],
          codigo: '',
          error: ""
        };
  }



  async componentDidMount() {

      if(this.props[0].location.state !== undefined){

          const obj_cota  = this.props[0].location.state.cota
          this.setState({clienteNome:obj_cota.nome,cpf: obj_cota.cpf,administradoraNome:obj_cota.administradora,cota: obj_cota.contrato,grupo: obj_cota.grupo,codigo: obj_cota.codigo});
          this.obtemDocumentos(obj_cota.codigo);
          this.setState({labelForm: "Homologar Documentação",labelButton: "Editar",action: "editar"});
      }
  }


  async  obtemDocumentos(codigo){
    try {
          const response = await api.post("api/concash/1.0/consorcio-grupo-cota/documentos", {codigoCota:codigo});
          this.setState({ documentos: response.data});
    } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter os documentos."
          });
    }

  }


  handleFormOpenDocumento = async (e,documento) => {
    e.preventDefault();
      try {

            Swal.fire ({
               title: 'Aguarde ...',
               onBeforeOpen: () => {
                 Swal.showLoading ()
               }
            });

            const response  = await api.post("api/concash/1.0/consorcio-grupo-cota/download",{
              extrato:documento
            },{ responseType: 'blob' });

            const file      = new Blob([response.data], { type: "application/pdf" });
            //Build a URL from the file
            const fileURL   = URL.createObjectURL(file);
            //Open the URL on new Window
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;

            Swal.close();

      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o processamento da requisição."
        });
      }
  };


  handleFormOpenZIP = async (e,documento) => {
    e.preventDefault();
      try {

            Swal.fire ({
               title: 'Aguarde ...',
               onBeforeOpen: () => {
                 Swal.showLoading ()
               }
            });

            const response  = await api.post("api/concash/1.0/consorcio-grupo-cota/download-zip",{
              extrato:documento
            },{ responseType: 'blob' });

            const file      = new Blob([response.data], { type: "application/zip" });
            //Build a URL from the file
            const fileURL   = URL.createObjectURL(file);
            //Open the URL on new Window
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;

            Swal.close();

      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o processamento da requisição."
        });
      }
  };


  handleHomologaDocumentos = async (e) => {

    var classe = this;
    Swal.fire({
       title: 'Você tem certeza que deseja aprovar esses documentos?',
       text: 'Após a aprovação não será possivel a reversão',
       icon: 'warning',
       showCancelButton: true,
       confirmButtonColor: '#3085d6',
       cancelButtonColor: '#d33',
       confirmButtonText: 'Confirmar!'
    }).then((result) => {
       if(result.value){
         classe.handleEfetivarHomologaDocumentos(e);
      }
    })
  };


  handleEfetivarHomologaDocumentos = async (e) => {

      e.preventDefault();
        try {
           let codigo        = this.state.codigo;
           const response    = await api.put("api/concash/1.0/consorcio-grupo-cota/aprovar/", {
                codigo
           });
           Swal.fire ('Documentos homologados com sucesso!');

        } catch (err) {
          this.setState({
            error:
              "Houve um problema com a homologação."
          });
        }
  };





  render() {


    const const_cliente = this.state.cliente;

    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> {this.state.labelForm} </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>

        <div className="row">


          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">

                  <div className="row" style={{backgroundColor:'rgb(222, 226, 230)'}}>

                    <div className="col-md-12" style={{marginTop:'30px'}}>
                    <div className="row">
                      <div className="col-md-3">
                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>CPF/CNPJ Cliente​</label>
                          </Form.Group>
                      </div>
                      <div className="col-md-3">
                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Nome Consorciado</label>
                          </Form.Group>
                      </div>
                      <div className="col-md-2">
                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Administradora</label>
                          </Form.Group>
                      </div>
                      <div className="col-md-2">
                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Grupo</label>
                          </Form.Group>
                      </div>
                      <div className="col-md-2">
                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Cota</label>
                          </Form.Group>
                      </div>
                    </div>
                    <div className="row" style={{marginTop:'-20px'}}>
                          <div className="col-md-3">
                              <Form.Group className="row">
                                <div className="col-sm-12"  style={{textAlign:'center'}}>
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="cpf" value={this.state.cpf} readOnly onChange={e => this.setState({ cpf: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                          <div className="col-md-3">
                              <Form.Group className="row">
                                <div className="col-sm-12" style={{textAlign:'center'}}>
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="clienteNome" value={this.state.clienteNome} readOnly onChange={e => this.setState({ clienteNome: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                          <div className="col-md-2">
                              <Form.Group className="row">
                                <div className="col-sm-12"  style={{textAlign:'center'}}>
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="administradoraNome" value={this.state.administradoraNome} readOnly onChange={e => this.setState({ administradoraNome: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                          <div className="col-md-2">
                              <Form.Group className="row">
                                <div className="col-sm-12"  style={{textAlign:'center'}}>
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="grupo" value={this.state.grupo} readOnly onChange={e => this.setState({ grupo: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                          <div className="col-md-2">
                              <Form.Group className="row">
                                <div className="col-sm-12"  style={{textAlign:'center'}}>
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="cota" value={this.state.cota} readOnly onChange={e => this.setState({ cota: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                      </div>


                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>


        <div className="row">

          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Documentos</h4>
                <p className="card-description"></p>

                <div className="text-right" style={{marginBottom:"20px"}}>
                  <button type="submit" className="btn btn-primary mr-2" onClick={e =>this.handleHomologaDocumentos(e)} >Aprovar Documentos</button>
                </div>


                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th> Tipo    </th>
                        <th> Status </th>
                        <th className="text-center"> Documento </th>
                        <th className="text-center"> Assinatura </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.documentos.map((documento,index) => (
                      <tr key={index}>
                        <td> {documento.tipo} </td>
                        <td> {documento.action} </td>
                        <td className="text-center">

                          <div className="text-right">
                            <button type="submit" onClick={ e => this.handleFormOpenDocumento(e,documento.fileUrl)} className="btn btn-primary mr-2">Visualizar Documento</button>
                          </div>

                        </td>
                        <td className="text-center">
                        {!!documento.apiDownload &&
                          <div className="text-right">
                            <button type="submit" onClick={ e => this.handleFormOpenZIP(e,documento.apiDownload)} className="btn btn-primary mr-2">Visualizar Assinatura</button>
                          </div>
                        }
                        </td>
                      </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default withRouter(ConsorcioGrupoCotaForm)
