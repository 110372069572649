import React, { Component }  from "react";
import api from "../../services/api";
import { Autocomplete, TextField } from "@mui/material";

export class FieldSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchObjeto: "",
      searchObjetos: [],
      searchObjetoDesc: "",
    };
  }

  componentDidMount() {
    this.obtemListaObjetos();
  }

  async obtemListaObjetos() {
    try {
      const response = await api
        .get("/api/concash/1.0/"+this.props.caminho)
        .then(({ data }) => {
          let objetosArr = [];
          data.forEach((item) => {
            objetosArr.push({
              label: item[this.props.atributoLabel],
              id: item[this.props.atributoId],
            });
          });
          return objetosArr;
        });

      this.setState({ searchObjetos: response });
    } catch {
      this.setState({
        error: "Houve um problema para obter a lista de "+this.props.label+".",
      });
    }
  }

  onFieldChange(id) {
    const fieldName = this.props.atributo;
    const fieldValue = id;
    this.props.onChange(fieldName, fieldValue);
  }

  render() {
    return (
      <div>
        <Autocomplete
          id={this.props.atributo}
          className="input-form-miu alinharFiltro"
          options={this.state.searchObjetos}
          onChange={(e, input) => {
            var id = "";
            var label = "";
            if(null != input){
              id = input.id;
              label = input.label;
            }
            this.setState({
              searchObjeto: id,
              searchObjetoDesc: label,
            });
            this.onFieldChange(id)
          }}
          value={ this.props.atributoObjeto == "" ? "" : this.state.searchObjetoDesc  }
          renderInput={(params) => (
            <TextField {...params} style={{ width: "100%"}} label={this.props.label} error={ this.props.erro && this.props.atributoObjeto == "" ? true : false } helperText={ this.props.erro && this.props.atributoObjeto == "" ? this.props.mensagemErro : '' }/>
          )}
          noOptionsText={'Nenhum resultado'}
        />
      </div>
    )
  }
}
export default FieldSelect;