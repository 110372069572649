import React, { Component, PropTypes } from 'react';
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import api from "../services/api";

class ReguaCheckBox extends Component {

  contabilizado   = 0;
  contadorExcluir = 0;

  constructor(props){

    super(props);
    const { marcados }           = this.props;
    const { label }              = this.props;
    const { valor }              = this.props;
    const { administradora }     = this.props;
    const { cessionaria }        = this.props;
    const { modelos }            = this.props;
    const { reguasSelect }       = this.props;
    var marcado                  = false;
    var modelo                   = "";
    var sequencia                = 0;
    
    if(marcados[cessionaria.codigo]){
        marcado    = true;
        let quebra = marcados[cessionaria.codigo].split("|");
        sequencia  = quebra[0];
        modelo     = quebra[1];
    }

    this.state = {
      isChecked: marcado,
      valor: valor,
      sequencia: sequencia,
      administradora: administradora,
      cessionaria:    cessionaria,
      modelos: modelos,
      modelo: modelo,
      reguasSelect: reguasSelect
    }


  }

  toggleCheckboxChange = (e) => {


    this.setState(({ isChecked }) => (
      {
        isChecked: !isChecked,
      }
    ));
  }

  shouldComponentUpdate(nextProps,nextState){

     if(nextState.isChecked == true){
         if(this.contabilizado == 0){
           this.handleAdicionaConfiguracao();
           this.contabilizado = 1;
         }else{
           this.contabilizado = 0;
         }
     }else{
       if(this.contadorExcluir == 0){
         this.handleRemoveConfiguracao();
         this.contadorExcluir = 1;
       }else{
         this.contadorExcluir = 0;
       }
     }

     return true;
  }

  async handleRemoveConfiguracao(){

    if(!this.state.cessionaria.codigo
    || !this.state.administradora.codigo
    || !this.state.modelo
    || !this.state.sequencia){

        

    }else{

      try {
       
        const response = await api.put("api/concash/1.0/regra-proposta/excluir", {
          codigoCessionaria:         this.state.cessionaria.codigo
          ,codigoAdministradora:      this.state.administradora.codigo
        });
          
        if(response){
          Swal.fire("Régua alterada com sucesso!");
        }
           
      } catch (err) {
      }
    }

  } 

  async handleAdicionaConfiguracao(){
    if(!this.state.cessionaria.codigo
    || !this.state.administradora.codigo
    || !this.state.modelo
    || !this.state.sequencia){

        

    }else{

        
        try {
          const reguas = this.state.reguasSelect;
          
          reguas.push({codigoCessionaria:this.state.cessionaria.codigo, codigoAdministradora:  this.state.administradora.codigo,codigoModeloRegraProposta: this.state.modelo,sequencia:this.state.sequencia})
          
          /* this.setState({
            reguasSelect: { $push: {codigoCessionaria:this.state.cessionaria.codigo, codigoAdministradora:  this.state.administradora.codigo,codigoModeloRegraProposta: this.state.modelo,sequencia:this.state.sequencia} }
          }) */
                  
          /*this.setState({ reguasSelect: reguas });*/
            /* const response = await api.put("api/concash/1.0/regra-proposta/editar", {
                 codigoCessionaria:         this.state.cessionaria.codigo
                ,codigoAdministradora:      this.state.administradora.codigo
                ,codigoModeloRegraProposta: this.state.modelo
                ,sequencia:                 this.state.sequencia
              });*/
        } catch (err) {
        }

    }


  }





  render() {

    const { label }              = this.props;
    const { isChecked }          = this.state;
    const { valor }              = this.props;
    const { modelos }            = this.props;

    return (

      <div className="row" >
        <div className="col-md-12">
          <Form.Group className="row" >
            <div className="col-sm-2">
              <Form.Control type="checkbox" value={valor} checked={isChecked} onChange={ e => this.toggleCheckboxChange(e)}   size="sm" />
            </div>
            <div className="col-sm-2">
              <Form.Control type="number" value={this.state.sequencia}   onChange={e => this.setState({ sequencia: e.target.value })}   size="sm" />
            </div>
            <div className="col-sm-4">
              <select className="form-control" value={this.state.modelo} onChange={e => this.setState({ modelo: e.target.value })}>
                  <option value="" key={0}>Selecione</option>
                 {modelos.map((gp,index) => (
                  <option value={gp.codigo} key={index}>{gp.nome}</option>
                ))}
              </select>
            </div>
            <label className="col-sm-4 col-form-label">{label}</label>

          </Form.Group>
        </div>
      </div>
    );
  }
}


export default ReguaCheckBox;
