import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  username: "",
  idAtendimento: "",
  tokenConsort: "",
  cpfCnpj: "",
  otp: "",
  token: "",
  userType: "",
  method: "",
  nome: "",
  email: "",
  apelido: "",
  phone: "",
  tipoPessoa: "",
  logged: false,
  estilo: "",
};
const clearState = {
  username: "",
  idAtendimento: "",
  tokenConsort: "",
  otp: "",
  cpfCnpj: "",
  tipoPessoa: "",
  token: "",
  userType: "",
  method: "",
  nome: "",
  email: "",
  apelido: "",
  phone: "",
  logged: false,
  estilo: "",
};

export const autoservicoSlice = createSlice({
  name: "autoservico",
  initialState,
  reducers: {
    autoAuth(state, action) {
      const {
        username,
        userType,
        token,
        tokenConsort,
        otp,
        nome,
        phone,
        tipoPessoa,
        cpfCnpj,
        method,
        email,
        apelido,
        idAtendimento
      } = action.payload;
      state.username = username;
      state.userType = userType;
      state.idAtendimento = idAtendimento;
      state.nome = nome;
      state.tipoPessoa = tipoPessoa;
      state.tokenConsort = tokenConsort;
      state.otp = otp;
      state.token = token;
      state.email = email;
      state.phone = phone;
      state.apelido = apelido;
      state.cpfCnpj = cpfCnpj;
      state.method = method;
      state.logged = true;
      return state;
    },
    updateAtendimento(state, action) {
      if(action?.payload?.idAtendimento) state.idAtendimento = action?.payload.idAtendimento;
      if(action?.payload?.grupo) state.grupo = action?.payload?.grupo;
      if(action?.payload?.cota) state.cota = action?.payload?.cota;
      return state;
    },
    getAutoServico(state) {
      return state;
    },
    getTipoPessoa(state) {
      return state.tipoPessoa;
    },
    atribuirTokenConsort(state, action) {
      state.tokenConsort = action.payload;
      return state;
    },
    negociarCota(state, action) {
      state.idAtendimento = action.payload;
      return state;
    },
    logoutAuto(state) {
      localStorage.clear();
      state = {
        username: "",
        cpfCnpj: "",
        token: "",
        userType: "",
        method: "",
        nome: "",
        email: "",
        apelido: "",
        phone: "",
        idAtendimento: "",
        grupo: "",
        cota: "",
        tokenConsort: "",
        logged: false,
        estilo: "",
      };
      return state;
    },
    logando(state) {
      state.logged = true;
      return state;
    },
    setEstilo(state, action) {
      state.estilo = action.payload;
      return state;
    },
  },
});

export const { autoAuth, logoutAuto, getAutoServico, logando, setEstilo, updateAtendimento, atribuirTokenConsort, negociarCota, getTipoPessoa } =
  autoservicoSlice.actions;
export const autoservicoReducer = autoservicoSlice.reducer;
