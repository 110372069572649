import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import styled, { css } from 'styled-components';


export class BoxIMG extends Component {

  constructor(props){
        super(props);
  }


  render() {

    const { base64 }  = this.props;

    return (
      <>
         <img className="pic__photo" src={base64} style={{width:"90%"}} />
      </>
    )
  }
}

export default BoxIMG;
