import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import api from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";

export class SimuladorSimplificado extends Component {
    constructor(props) {
        super(props);

        const obj_cota = this.props.cota;

        this.state = {
            startDate: new Date(),
            inputVal: "",
            codigoAdministradora: "",
            codigoTipoConsorcio: "",
            valorCota: "",
            codigoCota: "",
            prazoPlano: "",
            assembleiaRealizar: "",
            parcelasPagas: "",
            percentualLance: "40.00",
            percentualFundoComumPago: 0.0,
            saldoDevedorAtualizado: "",
            encargosMultasTaxas: "",
            formaPagamentoAtraso: "",
            valorMedioParcela: "",
            fundoComumPago: "",
            cotaSituacao: "",
            proximoVencimento: "",
            mesAtualizacaoCredito: "",
            concashProposta1Valor: "",
            concashProposta1Percentual: "",
            concashProposta2Valor: "",
            concashProposta2Percentual: "",
            concashProposta3Valor: "",
            concashProposta3Percentual: "",
            originacaoMBProposta1Valor: "",
            originacaoMBProposta1Percentual: "",
            originacaoMBProposta2Valor: "",
            originacaoMBProposta2Percentual: "",
            originacaoMBProposta3Valor: "",
            originacaoMBProposta3Percentual: "",
            originacaoFundoMBProposta1Valor: "",
            originacaoFundoMBProposta1Percentual: "",
            originacaoFundoMBProposta2Valor: "",
            originacaoFundoMBProposta2Percentual: "",
            originacaoFundoMBProposta3Valor: "",
            originacaoFundoMBProposta3Percentual: "",
            originacaoFundoProposta1Valor: "",
            originacaoFundoProposta1Percentual: "",
            originacaoFundoProposta2Valor: "",
            originacaoFundoProposta2Percentual: "",
            originacaoFundoProposta3Valor: "",
            originacaoFundoProposta3Percentual: "",
            originacaoFundoCarregProposta1Valor: "",
            originacaoFundoCarregProposta1Percentual: "",
            originacaoFundoCarregProposta2Valor: "",
            originacaoFundoCarregProposta2Percentual: "",
            originacaoFundoCarregProposta3Valor: "",
            originacaoFundoCarregProposta3Percentual: "",
            originacaoConCashCarregProposta1Valor: "",
            originacaoConCashCarregProposta1Percentual: "",
            originacaoConCashCarregProposta2Valor: "",
            originacaoConCashCarregProposta2Percentual: "",
            originacaoConCashCarregProposta3Valor: "",
            originacaoConCashCarregProposta3Percentual: "",
            desagioComercial: "",
            administradoras: [],
            valorReceber: "",
            cota: obj_cota,
            error: "",
        };
    }

    async componentDidMount() {
        if (this.props.dados !== undefined) {
            const cota = this.state.cota;

            this.setState({
                parcelasPagas: cota.parcelasPaga,
                saldoDevedorAtualizado: cota.saldoDevedor,

                assembleiaRealizar: cota.assembleiaRealizar,
                percentualLance: 40,
                valorCota: cota.valorCredito,

                prazoPlano: cota.parcela,
                encargosMultasTaxas: cota.atrasadoValor,
                formaPagamentoAtraso: "Diluir no prazo",
                codigoCota: cota.codigo,
                proximoVencimento: cota.proximoVencimento,
                fundoComumPago: cota.fundoComumPago,
                percentualFundoComumPago: cota.percentualFundoComumPago,
                percentualLance: cota.contempladoLanceLivre,

                cotaSituacao: cota.cotaSituacao,
            });
        }
    }

    handleSimular = async (e) => {
        e.preventDefault();

        const {
            codigoAdministradora,
            codigoTipoConsorcio,
            valorCota,
            prazoPlano,
            codigoCota,
            assembleiaRealizar,
            formaPagamentoAtraso,
            encargosMultasTaxas,
            proximoVencimento,
            saldoDevedorAtualizado,
            fundoComumPago,
            percentualFundoComumPago,
            mesAtualizacaoCredito,
            percentualLance,
            cotaSituacao,
            parcelasPagas,
        } = this.state;
        if (1 > 2) {
            this.setState({
                error: "Preencha os campos obrigatórios para continuar!",
            });
        } else {
            try {
                Swal.fire({
                    title: "Simulando ...",
                    onBeforeOpen: () => {
                        Swal.showLoading();
                    },
                });

                const response = await api.post(
                    "api/concash/1.0/simulador/listar",
                    {
                        codigoTipoConsorcio,
                        valorCota,
                        codigoAdministradora,
                        prazoPlano,
                        codigoCota,
                        formaPagamentoAtraso,
                        assembleiaRealizar,
                        encargosMultasTaxas,
                        percentualFundoComumPago,
                        fundoComumPago,
                        percentualLance,
                        cotaSituacao,
                        proximoVencimento,
                        mesAtualizacaoCredito,
                        saldoDevedorAtualizado,
                        parcelasPagas,
                    }
                );
                this.setState({
                    valorReceber:
                        response.data.RESULTADO_COTA.Resumo.ValorReceber,
                });
                this.setState({
                    desagioComercial:
                        response.data.RESULTADO_COTA.Resumo.DesagioComercial +
                        "%",
                });
                this.setState({
                    concashProposta1Valor:
                        response.data.RESULTADO_COTA.Resumo.Proposta1Valor,
                });
                this.setState({
                    concashProposta1Percentual:
                        response.data.RESULTADO_COTA.Resumo
                            .Proposta1Percentual + "%",
                });
                this.setState({
                    concashProposta2Valor:
                        response.data.RESULTADO_COTA.Resumo.Proposta2Valor,
                });
                this.setState({
                    concashProposta2Percentual:
                        response.data.RESULTADO_COTA.Resumo
                            .Proposta2Percentual + "%",
                });
                this.setState({
                    concashProposta3Valor:
                        response.data.RESULTADO_COTA.Resumo.Proposta3Valor,
                });
                this.setState({
                    concashProposta3Percentual:
                        response.data.RESULTADO_COTA.Resumo
                            .Proposta3Percentual + "%",
                });
                this.setState({
                    originacaoMBProposta1Valor:
                        response.data.FUNDO_MB.Resumo.Proposta1Valor,
                });
                this.setState({
                    originacaoMBProposta1Percentual:
                        response.data.FUNDO_MB.Resumo.Proposta1Percentual + "%",
                });
                this.setState({
                    originacaoMBProposta2Valor:
                        response.data.FUNDO_MB.Resumo.Proposta2Valor,
                });
                this.setState({
                    originacaoMBProposta2Percentual:
                        response.data.FUNDO_MB.Resumo.Proposta2Percentual + "%",
                });
                this.setState({
                    originacaoMBProposta3Valor:
                        response.data.FUNDO_MB.Resumo.Proposta3Valor,
                });
                this.setState({
                    originacaoMBProposta3Percentual:
                        response.data.FUNDO_MB.Resumo.Proposta3Percentual + "%",
                });
                this.setState({
                    originacaoFundoMBProposta1Valor:
                        response.data.FUNDO_MB_CARREGAMENTO.Resumo
                            .Proposta1Valor,
                });
                this.setState({
                    originacaoFundoMBProposta1Percentual:
                        response.data.FUNDO_MB_CARREGAMENTO.Resumo
                            .Proposta1Percentual + "%",
                });
                this.setState({
                    originacaoFundoMBProposta2Valor:
                        response.data.FUNDO_MB_CARREGAMENTO.Resumo
                            .Proposta2Valor,
                });
                this.setState({
                    originacaoFundoMBProposta2Percentual:
                        response.data.FUNDO_MB_CARREGAMENTO.Resumo
                            .Proposta2Percentual + "%",
                });
                this.setState({
                    originacaoFundoMBProposta3Valor:
                        response.data.FUNDO_MB_CARREGAMENTO.Resumo
                            .Proposta3Valor,
                });
                this.setState({
                    originacaoFundoMBProposta3Percentual:
                        response.data.FUNDO_MB_CARREGAMENTO.Resumo
                            .Proposta3Percentual + "%",
                });
                this.setState({
                    originacaoFundoProposta1Valor:
                        response.data.ORIGINACAO_FUNDO.Resumo.Proposta1Valor,
                });
                this.setState({
                    originacaoFundoProposta1Percentual:
                        response.data.ORIGINACAO_FUNDO.Resumo
                            .Proposta1Percentual + "%",
                });
                this.setState({
                    originacaoFundoProposta2Valor:
                        response.data.ORIGINACAO_FUNDO.Resumo.Proposta2Valor,
                });
                this.setState({
                    originacaoFundoProposta2Percentual:
                        response.data.ORIGINACAO_FUNDO.Resumo
                            .Proposta2Percentual + "%",
                });
                this.setState({
                    originacaoFundoProposta3Valor:
                        response.data.ORIGINACAO_FUNDO.Resumo.Proposta3Valor,
                });
                this.setState({
                    originacaoFundoProposta3Percentual:
                        response.data.ORIGINACAO_FUNDO.Resumo
                            .Proposta3Percentual + "%",
                });
                this.setState({
                    originacaoFundoCarregProposta1Valor:
                        response.data.FUNDO_CARREGAMENTO.Resumo.Proposta1Valor,
                });
                this.setState({
                    originacaoFundoCarregProposta1Percentual:
                        response.data.FUNDO_CARREGAMENTO.Resumo
                            .Proposta1Percentual + "%",
                });
                this.setState({
                    originacaoFundoCarregProposta2Valor:
                        response.data.FUNDO_CARREGAMENTO.Resumo.Proposta2Valor,
                });
                this.setState({
                    originacaoFundoCarregProposta2Percentual:
                        response.data.FUNDO_CARREGAMENTO.Resumo
                            .Proposta2Percentual + "%",
                });
                this.setState({
                    originacaoFundoCarregProposta3Valor:
                        response.data.FUNDO_CARREGAMENTO.Resumo.Proposta3Valor,
                });
                this.setState({
                    originacaoFundoCarregProposta3Percentual:
                        response.data.FUNDO_CARREGAMENTO.Resumo
                            .Proposta3Percentual + "%",
                });
                this.setState({
                    originacaoConCashCarregProposta1Valor:
                        response.data.CONCASH_CARREGAMENTO.Resumo
                            .Proposta1Valor,
                });
                this.setState({
                    originacaoConCashCarregProposta1Percentual:
                        response.data.CONCASH_CARREGAMENTO.Resumo
                            .Proposta1Percentual + "%",
                });
                this.setState({
                    originacaoConCashCarregProposta2Valor:
                        response.data.CONCASH_CARREGAMENTO.Resumo
                            .Proposta2Valor,
                });
                this.setState({
                    originacaoConCashCarregProposta2Percentual:
                        response.data.CONCASH_CARREGAMENTO.Resumo
                            .Proposta2Percentual + "%",
                });
                this.setState({
                    originacaoConCashCarregProposta3Valor:
                        response.data.CONCASH_CARREGAMENTO.Resumo
                            .Proposta3Valor,
                });
                this.setState({
                    originacaoConCashCarregProposta3Percentual:
                        response.data.CONCASH_CARREGAMENTO.Resumo
                            .Proposta3Percentual + "%",
                });
                this.setState({ error: "" });
                Swal.close();
            } catch (err) {
                this.setState({
                    error: "Houve um erro com o simulador, por favor, tente mais tarde.",
                });
                Swal.close();
            }
        }
    };

    render() {
        return (
            <div>
                <div className="page-header">
                    <h3 className="page-title"> Simulador </h3>
                    {this.state.error && (
                        <p className="card-description">{this.state.error}</p>
                    )}
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb"></ol>
                    </nav>
                </div>

                <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Resultado</h4>
                                <p className="card-description"></p>
                                <form className="forms-sample">
                                    <p className="card-description">
                                        Resultado Consorciado
                                    </p>
                                    <Form.Group className="row">
                                        <label
                                            htmlFor="examplePropostal"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Deságio Comercial
                                        </label>
                                        <div className="col-sm-9">
                                            <Form.Control
                                                type="text"
                                                readOnly="true"
                                                className="form-control"
                                                id="desagioComercial"
                                                value={
                                                    this.state.desagioComercial
                                                }
                                                size="sm"
                                            />
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <label
                                            htmlFor="examplePropostal"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Valor a Receber
                                        </label>
                                        <div className="col-sm-9">
                                            <Form.Control
                                                type="text"
                                                readOnly="true"
                                                className="form-control"
                                                id="valorReceber"
                                                value={this.state.valorReceber}
                                                size="sm"
                                            />
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="row">
                                        <div className="col-sm-6">
                                            <p className="card-description">
                                                Resultado ConCash
                                            </p>
                                            <Form.Group className="row">
                                                <label
                                                    htmlFor="examplePropostal"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Proposta 1
                                                </label>
                                                <div className="col-sm-5">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="concashProposta1Valor"
                                                        value={
                                                            this.state
                                                                .concashProposta1Valor
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="concashProposta1Percentual"
                                                        value={
                                                            this.state
                                                                .concashProposta1Percentual
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="row">
                                                <label
                                                    htmlFor="exampleProposta2"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Proposta 2
                                                </label>
                                                <div className="col-sm-5">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="concashProposta2Valor"
                                                        value={
                                                            this.state
                                                                .concashProposta2Valor
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="concashProposta2Percentual"
                                                        value={
                                                            this.state
                                                                .concashProposta2Percentual
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="row">
                                                <label
                                                    htmlFor="exampleProposta3"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Proposta 3
                                                </label>
                                                <div className="col-sm-5">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="concashProposta3Valor"
                                                        value={
                                                            this.state
                                                                .concashProposta3Valor
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="concashProposta3Percentual"
                                                        value={
                                                            this.state
                                                                .concashProposta3Percentual
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>

                                        <div className="col-sm-6">
                                            <p className="card-description">
                                                Resultado Originação ConCash
                                                Carregamento
                                            </p>
                                            <Form.Group className="row">
                                                <label
                                                    htmlFor="exampleProposta13"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Proposta 1
                                                </label>
                                                <div className="col-sm-5">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoConCashCarregProposta1Valor"
                                                        value={
                                                            this.state
                                                                .originacaoConCashCarregProposta1Valor
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoConCashCarregProposta1Percentual"
                                                        value={
                                                            this.state
                                                                .originacaoConCashCarregProposta1Percentual
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="row">
                                                <label
                                                    htmlFor="exampleProposta14"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Proposta 2
                                                </label>
                                                <div className="col-sm-5">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoConCashCarregProposta2Valor"
                                                        value={
                                                            this.state
                                                                .originacaoConCashCarregProposta2Valor
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoConCashCarregProposta2Percentual"
                                                        value={
                                                            this.state
                                                                .originacaoConCashCarregProposta2Percentual
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="row">
                                                <label
                                                    htmlFor="exampleProposta15"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Proposta 3
                                                </label>
                                                <div className="col-sm-5">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoConCashCarregProposta3Valor"
                                                        value={
                                                            this.state
                                                                .originacaoConCashCarregProposta3Valor
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoConCashCarregProposta3Percentual"
                                                        value={
                                                            this.state
                                                                .originacaoConCashCarregProposta3Percentual
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="row">
                                        <div className="col-sm-6">
                                            <p className="card-description">
                                                Resultado Originação Fundo
                                            </p>
                                            <Form.Group className="row">
                                                <label
                                                    htmlFor="exampleProposta7"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Proposta 1
                                                </label>
                                                <div className="col-sm-5">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoFundoProposta1Valor"
                                                        value={
                                                            this.state
                                                                .originacaoFundoProposta1Valor
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoFundoProposta1Percentual"
                                                        value={
                                                            this.state
                                                                .originacaoFundoProposta1Percentual
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="row">
                                                <label
                                                    htmlFor="exampleProposta8"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Proposta 2
                                                </label>
                                                <div className="col-sm-5">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoFundoProposta2Valor"
                                                        value={
                                                            this.state
                                                                .originacaoFundoProposta2Valor
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoFundoProposta2Percentual"
                                                        value={
                                                            this.state
                                                                .originacaoFundoProposta2Percentual
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="row">
                                                <label
                                                    htmlFor="exampleProposta9"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Proposta 3
                                                </label>
                                                <div className="col-sm-5">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoFundoProposta3Valor"
                                                        value={
                                                            this.state
                                                                .originacaoFundoProposta3Valor
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoFundoProposta3Percentual"
                                                        value={
                                                            this.state
                                                                .originacaoFundoProposta3Percentual
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>

                                        <div className="col-sm-6">
                                            <p className="card-description">
                                                Resultado Originação Fundo
                                                Carregamento
                                            </p>
                                            <Form.Group className="row">
                                                <label
                                                    htmlFor="exampleProposta10"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Proposta 1
                                                </label>
                                                <div className="col-sm-5">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoFundoCarregProposta1Valor"
                                                        value={
                                                            this.state
                                                                .originacaoFundoCarregProposta1Valor
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoFundoCarregProposta1Percentual"
                                                        value={
                                                            this.state
                                                                .originacaoFundoCarregProposta1Percentual
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="row">
                                                <label
                                                    htmlFor="exampleProposta11"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Proposta 2
                                                </label>
                                                <div className="col-sm-5">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoFundoCarregProposta2Valor"
                                                        value={
                                                            this.state
                                                                .originacaoFundoCarregProposta2Valor
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoFundoCarregProposta2Percentual"
                                                        value={
                                                            this.state
                                                                .originacaoFundoCarregProposta2Percentual
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="row">
                                                <label
                                                    htmlFor="exampleProposta12"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Proposta 3
                                                </label>
                                                <div className="col-sm-5">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoFundoCarregProposta3Valor"
                                                        value={
                                                            this.state
                                                                .originacaoFundoCarregProposta3Valor
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoFundoCarregProposta3Percentual"
                                                        value={
                                                            this.state
                                                                .originacaoFundoCarregProposta3Percentual
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="row">
                                        <div className="col-sm-6">
                                            <p className="card-description">
                                                Resultado Originação MB
                                            </p>
                                            <Form.Group className="row">
                                                <label
                                                    htmlFor="exampleProposta4"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Proposta 1
                                                </label>
                                                <div className="col-sm-5">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoMBProposta1Valor"
                                                        value={
                                                            this.state
                                                                .originacaoMBProposta1Valor
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoMBProposta1Percentual"
                                                        value={
                                                            this.state
                                                                .originacaoMBProposta1Percentual
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="row">
                                                <label
                                                    htmlFor="exampleProposta5"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Proposta 2
                                                </label>
                                                <div className="col-sm-5">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoMBProposta2Valor"
                                                        value={
                                                            this.state
                                                                .originacaoMBProposta2Valor
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoMBProposta2Percentual"
                                                        value={
                                                            this.state
                                                                .originacaoMBProposta2Percentual
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="row">
                                                <label
                                                    htmlFor="exampleProposta6"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Proposta 3
                                                </label>
                                                <div className="col-sm-5">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoMBProposta3Valor"
                                                        value={
                                                            this.state
                                                                .originacaoMBProposta3Valor
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoMBProposta3Percentual"
                                                        value={
                                                            this.state
                                                                .originacaoMBProposta3Percentual
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>

                                        <div className="col-sm-6">
                                            <p className="card-description">
                                                Resultado Originação MB
                                                Carregamento
                                            </p>
                                            <Form.Group className="row">
                                                <label
                                                    htmlFor="exampleProposta4"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Proposta 1
                                                </label>
                                                <div className="col-sm-5">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoFundoMBProposta1Valor"
                                                        value={
                                                            this.state
                                                                .originacaoFundoMBProposta1Valor
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoFundoMBProposta1Percentual"
                                                        value={
                                                            this.state
                                                                .originacaoFundoMBProposta1Percentual
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="row">
                                                <label
                                                    htmlFor="exampleProposta5"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Proposta 2
                                                </label>
                                                <div className="col-sm-5">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoFundoMBProposta2Valor"
                                                        value={
                                                            this.state
                                                                .originacaoFundoMBProposta2Valor
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoFundoMBProposta2Percentual"
                                                        value={
                                                            this.state
                                                                .originacaoFundoMBProposta2Percentual
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="row">
                                                <label
                                                    htmlFor="exampleProposta6"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Proposta 3
                                                </label>
                                                <div className="col-sm-5">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoFundoMBProposta3Valor"
                                                        value={
                                                            this.state
                                                                .originacaoFundoMBProposta3Valor
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <Form.Control
                                                        type="text"
                                                        readOnly="true"
                                                        className="form-control"
                                                        id="originacaoFundoMBProposta3Percentual"
                                                        value={
                                                            this.state
                                                                .originacaoFundoMBProposta3Percentual
                                                        }
                                                        size="sm"
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="row">
                                        <div className="col-sm-12">
                                            <div className="text-right">
                                                <button
                                                    type="submit"
                                                    style={{
                                                        marginTop: "200px;",
                                                    }}
                                                    className="btn btn-primary mr-2"
                                                    onClick={this.handleSimular}
                                                >
                                                    Simular
                                                </button>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SimuladorSimplificado;
