import React, { Component } from "react";
import { Form } from "react-bootstrap";
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";
import { AlteraNome } from "../../actions";
import styled, { css } from "styled-components";
import Endereco from "../../components/Endereco";
import Telefone from "../../components/Telefone";
import Banco from "../../components/Banco";
import PropostaRange from "../../components/PropostaRange";
// import CotaInfo from "../../components/CotaInfo";
import SolicitarReanalise from "../../components/SolicitarReanalise";

const BoxInformativo = styled.div`
  text-align: center;
  color: white;
  padding: 10px;
  background: #2196f3;
  width: 150px;
  height: 40px;
  margin-top: 20px;
  border-radius: 10px 10px 0px 0px;
  cursor: pointer;

  :hover {
    background: #16898e;
  }
`;

export class ComercialDetalhamento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      cota: [],
      cliente: [],
      grupo: [],
      administradora: [],
      blocoCadastro: true,
      blocoReanalise: false,
      blocoTelefone: false,
      blocoBanco: false,
      blocoCota: false,
      blocoProposta: false,
      backgroundCadastro: "#ccc",
      backgroundReanalise: "#2196f3",
      backgroundTelefone: "#2196f3",
      backgroundBanco: "#2196f3",
      backgroundCota: "#2196f3",
      backgroundProposta: "#2196f3",
      enderecos: [],
      recebimentos: [],
      telefones: [],
      propostas: [],
    };
  }

  async componentDidMount() {
    if (this.props[0].location.state !== undefined) {
      const codigo = this.props[0].location.state.codigo;
      this.obtemCota(codigo);
    }
  }

  async obtemCota(codigo) {
    try {
      const response = await api.post(
        "api/concash/1.0/consorcio-grupo-cota/detalhe",
        { codigo: codigo }
      );
      this.setState({
        cota: response.data,
        cliente: response.data.cliente,
        grupo: response.data.grupo,
        administradora: response.data.grupo.consorcio.administradora,
      });
    } catch (err) {
      this.setState({
        error: "Houve um problema para obter a cota.",
      });
    }
  }

  handleNegociacoes = async (e) => {
    e.preventDefault();
    this.props.history.push("/comercial/negociacoes");
  };

  handleProspect = async (e) => {
    e.preventDefault();
    this.props.history.push("/comercial/prospect");
  };

  alteraAbas = (aba) => {
    const abas = [
      "Cadastro",
      "Reanalise",
      "Telefone",
      "Banco",
      "Cota",
      "Proposta",
    ];

    var tmp_cadastro = false;
    var tmp_reanalise = false;
    var tmp_telefone = false;
    var tmp_banco = false;
    var tmp_cota = false;
    var tmp_proposta = false;
    var bck_cadastro = "#2196f3";
    var bck_reanalise = "#2196f3";
    var bck_telefone = "#2196f3";
    var bck_banco = "#2196f3";
    var bck_cota = "#2196f3";
    var bck_proposta = "#2196f3";

    for (var i = 0; i < abas.length; i++) {
      if (aba == "Cadastro") {
        tmp_cadastro = true;
        bck_cadastro = "#ccc";
      }
      if (aba == "Reanalise") {
        tmp_reanalise = true;
        bck_reanalise = "#ccc";
      }
      if (aba == "Telefone") {
        tmp_telefone = true;
        bck_telefone = "#ccc";
      }
      if (aba == "Banco") {
        tmp_banco = true;
        bck_banco = "#ccc";
      }
      if (aba == "Cota") {
        tmp_cota = true;
        bck_cota = "#ccc";
      }
      if (aba == "Proposta") {
        tmp_proposta = true;
        bck_proposta = "#ccc";
      }
    }

    this.setState({
      blocoCadastro: tmp_cadastro,
      blocoReanalise: tmp_reanalise,
      blocoTelefone: tmp_telefone,
      blocoBanco: tmp_banco,
      blocoCota: tmp_cota,
      blocoProposta: tmp_proposta,
    });
    this.setState({
      backgroundCadastro: bck_cadastro,
      backgroundReanalise: bck_reanalise,
      backgroundTelefone: bck_telefone,
      backgroundBanco: bck_banco,
      backgroundCota: bck_cota,
      backgroundProposta: bck_proposta,
    });
  };

  render() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">
            {" "}
            Comercial - Detalhamento / Cota: {this.state.cota.contrato}{" "}
          </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb"></ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div
                  className="row"
                  style={{ backgroundColor: "rgb(222, 226, 230)" }}
                >
                  <div className="col-md-12" style={{ marginTop: "30px" }}>
                    <div className="row">
                      <div className="col-md-3">
                        <Form.Group className="row">
                          <label
                            htmlFor="examplePropostal"
                            className="col-sm-12 col-form-label"
                            style={{ textAlign: "center" }}
                          >
                            CPF/CNPJ Cliente​
                          </label>
                        </Form.Group>
                      </div>
                      <div className="col-md-3">
                        <Form.Group className="row">
                          <label
                            htmlFor="examplePropostal"
                            className="col-sm-12 col-form-label"
                            style={{ textAlign: "center" }}
                          >
                            Nome Consorciado
                          </label>
                        </Form.Group>
                      </div>
                      <div className="col-md-2">
                        <Form.Group className="row">
                          <label
                            htmlFor="examplePropostal"
                            className="col-sm-12 col-form-label"
                            style={{ textAlign: "center" }}
                          >
                            Administradora
                          </label>
                        </Form.Group>
                      </div>
                      <div className="col-md-2">
                        <Form.Group className="row">
                          <label
                            htmlFor="examplePropostal"
                            className="col-sm-12 col-form-label"
                            style={{ textAlign: "center" }}
                          >
                            Grupo
                          </label>
                        </Form.Group>
                      </div>
                      <div className="col-md-2">
                        <Form.Group className="row">
                          <label
                            htmlFor="examplePropostal"
                            className="col-sm-12 col-form-label"
                            style={{ textAlign: "center" }}
                          >
                            Cota
                          </label>
                        </Form.Group>
                      </div>
                    </div>

                    <div className="row" style={{ marginTop: "-20px" }}>
                      <div className="col-md-3">
                        <Form.Group className="row">
                          <div
                            className="col-sm-12"
                            style={{ textAlign: "center" }}
                          >
                            <Form.Control
                              type="text"
                              style={{ height: "30px" }}
                              className="form-control"
                              id="cpf"
                              value={this.state.cliente.cpf}
                              onChange={(e) =>
                                this.setState({ cpf: e.target.value })
                              }
                            />
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-3">
                        <Form.Group className="row">
                          <div
                            className="col-sm-12"
                            style={{ textAlign: "center" }}
                          >
                            <Form.Control
                              type="text"
                              style={{ height: "30px" }}
                              className="form-control"
                              id="clienteNome"
                              value={this.state.cliente.nome}
                              onChange={(e) =>
                                this.setState({ clienteNome: e.target.value })
                              }
                            />
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-2">
                        <Form.Group className="row">
                          <div
                            className="col-sm-12"
                            style={{ textAlign: "center" }}
                          >
                            <Form.Control
                              type="text"
                              style={{ height: "30px" }}
                              className="form-control"
                              id="administradoraNome"
                              value={this.state.administradora.nome}
                              onChange={(e) =>
                                this.setState({
                                  administradoraNome: e.target.value,
                                })
                              }
                            />
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-2">
                        <Form.Group className="row">
                          <div
                            className="col-sm-12"
                            style={{ textAlign: "center" }}
                          >
                            <Form.Control
                              type="text"
                              style={{ height: "30px" }}
                              className="form-control"
                              id="grupo"
                              value={this.state.grupo.grupo}
                              onChange={(e) =>
                                this.setState({ grupo: e.target.value })
                              }
                            />
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-2">
                        <Form.Group className="row">
                          <div
                            className="col-sm-12"
                            style={{ textAlign: "center" }}
                          >
                            <Form.Control
                              type="text"
                              style={{ height: "30px" }}
                              className="form-control"
                              id="cota"
                              value={this.state.cota.contrato}
                              onChange={(e) =>
                                this.setState({ cota: e.target.value })
                              }
                            />
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <BoxInformativo
                    className="col-md-2"
                    style={{ background: this.state.backgroundCadastro }}
                    onClick={(e) => this.alteraAbas("Cadastro")}
                  >
                    Cadastro
                  </BoxInformativo>

                  <BoxInformativo
                    className="col-md-2"
                    style={{ background: this.state.backgroundTelefone }}
                    onClick={(e) => this.alteraAbas("Telefone")}
                  >
                    Telefone
                  </BoxInformativo>

                  <BoxInformativo
                    className="col-md-2"
                    style={{ background: this.state.backgroundBanco }}
                    onClick={(e) => this.alteraAbas("Banco")}
                  >
                    Banco
                  </BoxInformativo>

                  <BoxInformativo
                    className="col-md-2"
                    style={{ background: this.state.backgroundCota }}
                    onClick={(e) => this.alteraAbas("Cota")}
                  >
                    Cota
                  </BoxInformativo>

                  <BoxInformativo
                    className="col-md-2"
                    style={{ background: this.state.backgroundProposta }}
                    onClick={(e) => this.alteraAbas("Proposta")}
                  >
                    Proposta
                  </BoxInformativo>

                  <BoxInformativo
                    className="col-md-2"
                    style={{ background: this.state.backgroundReanalise }}
                    onClick={(e) => this.alteraAbas("Reanalise")}
                  >
                    Reanalise
                  </BoxInformativo>
                </div>

                {this.state.blocoCadastro && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{ background: "#ccc", minHeight: "300px" }}
                    >
                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-sm-2">
                          <b>Nome:</b>
                        </div>
                        <div className="col-sm-2">
                          {this.state.cliente.nome}
                        </div>
                        <div className="col-sm-2">
                          <b>CPF:</b>
                        </div>
                        <div className="col-sm-2">{this.state.cliente.cpf}</div>
                        <div className="col-sm-2">
                          <b>RG:</b>
                        </div>
                        <div className="col-sm-2">{this.state.cliente.rg}</div>
                      </div>

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-sm-2">
                          <b>E-mail:</b>
                        </div>
                        <div className="col-sm-2">
                          {this.state.cliente.email}
                        </div>
                        <div className="col-sm-2">
                          <b>Nacionalidade:</b>
                        </div>
                        <div className="col-sm-2">
                          {this.state.cliente.nacionalidade}
                        </div>
                        <div className="col-sm-2">
                          <b>Estado Civil:</b>
                        </div>
                        <div className="col-sm-2">
                          {this.state.cliente.estadoCivil}
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-sm-2">
                          <b>Profissão:</b>
                        </div>
                        <div className="col-sm-2">
                          {this.state.cliente.profissao}
                        </div>
                        <div className="col-sm-2">
                          <b>Renda:</b>
                        </div>
                        <div className="col-sm-2">
                          {this.state.cliente.renda}
                        </div>
                        <div className="col-sm-2">
                          <b>Gênero:</b>
                        </div>
                        <div className="col-sm-2">
                          {this.state.cliente.genero}
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-sm-2">
                          <b>Data de Entrada:</b>
                        </div>
                        <div className="col-sm-2">
                          {this.state.cliente.dataIni}
                        </div>
                        <div className="col-sm-2">
                          <b>Canal:</b>
                        </div>
                        <div className="col-sm-2">
                          {this.state.cliente.canal}
                        </div>
                        <div className="col-sm-2">
                          <b>Forma de Contato:</b>
                        </div>
                        <div className="col-sm-2"></div>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.blocoTelefone && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        background: "#ccc",
                        minHeight: "300px",
                        padding: "20px",
                      }}
                    >
                      <Telefone codigoCliente={this.state.cliente.codigo} />
                    </div>
                  </div>
                )}

                {this.state.blocoBanco && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        background: "#ccc",
                        minHeight: "300px",
                        padding: "20px",
                      }}
                    >
                      <Banco codigoCliente={this.state.cliente.codigo} />
                    </div>
                  </div>
                )}

                {this.state.blocoCota && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        background: "#ccc",
                        minHeight: "300px",
                        padding: "20px",
                      }}
                    >
                      {/* <CotaInfo cota={this.state.cota} administradora={this.state.administradora} grupo={this.state.grupo} /> */}
                    </div>
                  </div>
                )}

                {this.state.blocoProposta && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        background: "#ccc",
                        minHeight: "300px",
                        padding: "20px",
                      }}
                    >
                      <PropostaRange codigoCota={this.state.cota.codigo} />
                    </div>
                  </div>
                )}

                {this.state.blocoReanalise && (
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        background: "#ccc",
                        minHeight: "300px",
                        padding: "20px",
                      }}
                    >
                      <SolicitarReanalise codigoCota={this.state.cota.codigo} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ComercialDetalhamento);
