import React, { Component, Fragment } from "react";
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2'
import Stepper from 'react-stepper-horizontal';



export class StepCota extends Component {


  constructor(props) {

    super(props);


    const { cota }  = this.props;

    this.state      = {
      cota:cota,
      activeStep:0
    };

  }

  
  async componentDidMount() {


        if(this.state.cota.situacao == "AGUARDANDO_PROPOSTA"){
            this.setState({activeStep:0});
        }else if(this.state.cota.situacao == "EM_ANALISE"){
            this.setState({activeStep:1});
        }else if(this.state.cota.situacao == "SEM_VIABILIDADE"){
            this.setState({activeStep:2});
        }else if(this.state.cota.situacao == "RECUSADA"){
            this.setState({activeStep:3});
        }else if(this.state.cota.situacao == "ACEITA"){
            this.setState({activeStep:4});
        }else if(this.state.cota.situacao == "DOCUMENTACAO" || this.state.cota.situacao == "DOCUMENTACAO_ASSINADA"){
            this.setState({activeStep:5});
        }else if(this.state.cota.situacao == "FINALIZADA_SUCESSO"){
            this.setState({activeStep:7});
        }else if(this.state.cota.situacao == "FINALIZADA_INSUCESSO"){
            this.setState({activeStep:6});
        }


  }

  render() {

        return (

              <div className="row" >
                  <div className="col-md-12" style={{backgroundColor:"white",marginBottom:10,borderLeft:"15px solid rgb(222, 226, 230)",borderRight:"15px solid rgb(222, 226, 230)"}}>
                      <Stepper steps={ [{title: 'Aguard. Prop.'}, {title: 'Em Analise'}, {title: 'Sem Viabilidade'}, {title: 'Recusada'}, {title: 'Aceita'}, {title: 'Documentação'}, {title: 'Insucesso'}, {title: 'Sucesso'}] } activeStep={ this.state.activeStep } />
                  </div>
              </div>
        )

  }

 
}

export default StepCota;
