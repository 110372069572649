import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import api from "../../services/api";
import { Link } from "react-router-dom";
import SweetAlert from 'sweetalert2-react';
import Swal from 'sweetalert2'



export class LogBanco extends Component {



  constructor(props){

        super(props);

        const variaveis  = this.props[0].location.state

        this.state = {
          registroTitulo:        variaveis.registroTitulo,
          registroIdentificador: variaveis.registroIdentificador,
          registroTabela:        variaveis.registroTabela,
          logs: [],
          show: false
        };
  }

  async componentDidMount() {

        try {
          const response = await api.post("/api/sistema/1.0/log-banco/listar", {identificador: this.state.registroIdentificador, tabela: this.state.registroTabela});
          this.setState({ logs: response.data});
        } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter os logs."
          });
        }
  }



  render() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Log: {this.state.registroTitulo} - {this.state.registroIdentificador}  </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th> Código </th>
                          <th> Data </th>
                          <th> Ação </th>
                          <th> Responsável </th>
                          <th> LOG </th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.logs.map((log,index) => (
                          <tr key={index}>
                              <td> {log.codigo}  </td>
                              <td> {log.dataIni}   </td>
                              <td> {log.acao}   </td>
                              <td> {log.usuario} </td>
                              <td> {log.query} </td>
                          </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LogBanco;
