import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker, { registerLocale } from "react-datepicker";
import FloatingLabelInput from 'react-floating-label-input';
import moment from 'moment';
import api from "../../services/api";
import SimuladorSimplificado from "../../components/SimuladorSimplificado";
import { cellphoneFormater,currencyFormater,numberFormater} from '../../lib/formaters';
import IntlCurrencyInput from "react-intl-currency-input"
import InputFloat from 'react-floating-input'
import ptBR from "date-fns/locale/pt-BR";
import Swal from 'sweetalert2'
import { Link, withRouter } from "react-router-dom";
import cep from 'cep-promise'

registerLocale("pt-BR", ptBR);

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};


export class ReanaliseAprovada extends Component {


  constructor(props){

        super(props);

        this.state = {
          startDate: new Date(),
          inputVal: '',
          reanalise:[],
          codigo: '',
          error: ""
        };
  }



  async componentDidMount() {

      if(this.props[0].location.state !== undefined){

          const obj_reanalise  = this.props[0].location.state.reanalise
          this.setState({reanalise:obj_reanalise});
          this.setState({labelForm: "Enviar Proposta ao Cliente",labelButton: "Editar",action: "editar"});
      }
  }



  handleAprovarReanalise = async e => {
      try {
            let codigo        = this.state.reanalise.codigo;
            const response    = await api.post("api/concash/1.0/consorcio-grupo-cota-reanalise/transferir", {
              codigo:codigo
            });
            Swal.fire ('Proposta enviada para o cliente!');

      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o processamento da requisição."
        });
      }
  };



  render() {


    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> {this.state.labelForm} </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>

        <div className="row">


          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">

                  <div className="row" style={{backgroundColor:'rgb(222, 226, 230)'}}>

                    <div className="col-md-12" style={{marginTop:'30px'}}>
                    <div className="row">
                      <div className="col-md-3">
                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>CPF/CNPJ Cliente​</label>
                          </Form.Group>
                      </div>
                      <div className="col-md-3">
                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Nome Consorciado</label>
                          </Form.Group>
                      </div>
                      <div className="col-md-2">
                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Administradora</label>
                          </Form.Group>
                      </div>
                      <div className="col-md-2">
                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Grupo</label>
                          </Form.Group>
                      </div>
                      <div className="col-md-2">
                          <Form.Group className="row">
                            <label htmlFor="examplePropostal" className="col-sm-12 col-form-label" style={{textAlign:'center'}}>Cota</label>
                          </Form.Group>
                      </div>
                    </div>
                    <div className="row" style={{marginTop:'-20px'}}>
                          <div className="col-md-3">
                              <Form.Group className="row">
                                <div className="col-sm-12"  style={{textAlign:'center'}}>
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="cpf" value={this.state.reanalise.cota.cliente.cpf} readOnly onChange={e => this.setState({ cpf: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                          <div className="col-md-3">
                              <Form.Group className="row">
                                <div className="col-sm-12" style={{textAlign:'center'}}>
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="clienteNome" value={this.state.reanalise.cota.cliente.nome} readOnly onChange={e => this.setState({ clienteNome: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                          <div className="col-md-2">
                              <Form.Group className="row">
                                <div className="col-sm-12"  style={{textAlign:'center'}}>
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="administradoraNome" value={this.state.reanalise.cota.grupo.consorcio.nome} readOnly onChange={e => this.setState({ administradoraNome: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                          <div className="col-md-2">
                              <Form.Group className="row">
                                <div className="col-sm-12"  style={{textAlign:'center'}}>
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="grupo" value={this.state.reanalise.cota.grupo.grupo} readOnly onChange={e => this.setState({ grupo: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                          <div className="col-md-2">
                              <Form.Group className="row">
                                <div className="col-sm-12"  style={{textAlign:'center'}}>
                                  <Form.Control type="text" style={{height:'30px'}} className="form-control" id="cota" value={this.state.reanalise.cota.contrato} readOnly onChange={e => this.setState({ cota: e.target.value })} />
                                </div>
                              </Form.Group>
                          </div>
                      </div>

                        <div className="row" style={{marginTop:'-20px'}}>
                            <div className="row">
                                <div className="text-center">
                                  <button type="button" onClick={this.handleAprovarReanalise} className="btn btn-primary mr-2">Enviar Proposta ao Cliente</button>
                                </div>
                            </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>


      </div>
    )
  }
}

export default withRouter(ReanaliseAprovada)
