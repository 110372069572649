import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import api from "../../services/api";
import { Link } from "react-router-dom";
import SweetAlert from 'sweetalert2-react';
import Swal from 'sweetalert2'



export class VisaoClienteBloqueadoKYC extends Component {



  constructor(){
        super();
        this.state = {
          clientes: [],
          show: false
        };
  }

  async componentDidMount() {

        try {
          const response = await api.post("api/concash/1.0/visao-cliente-bloqueado-questionario/listar", {});
          this.setState({ clientes: response.data});
        } catch (err) {
          this.setState({
            error:
              "Houve um problema para obter os clientes bloqueados."
          });
        }
  }


  render() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Clientes Bloqueados Questionário </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th> Nome </th>
                          <th> CPF </th>
                          <th> E-mail </th>
                          <th className="text-center"> Ação </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.clientes.map((cliente,index) => (
                        <tr key={index}>
                          <td> {cliente.nome} </td>
                          <td> {cliente.cpf} </td>
                          <td> {cliente.email} </td>
                          <td className="text-center"><Link
                                          to={{pathname: "/backoffice/detalhamento-cliente",state: {
                                            cliente: cliente
                                          }}}
                                          className="m-3 btn btn-sm btn-success"
                                        >
                                        Editar
                              </Link>
                          </td>
                        </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default VisaoClienteBloqueadoKYC
