import React, { Component, Fragment } from "react";
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2'
import api from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import { Link, withRouter } from "react-router-dom";



export class Mensagem extends Component {


  constructor(props) {

    super(props);

    const { codigoCliente,origem }     = this.props;

    this.state = {
      codigoCliente: codigoCliente,
      mensagens:[],
      error: ""
    };

  }


  async componentDidMount() {
      this.obtemMensagens(this.state.codigoCliente);
  }


  async obtemMensagens(codigo){

      try {
            const response = await api.post("api/concash/1.0/cliente/mensagens", {codigo:codigo});
            this.setState({ mensagens: response.data});
      } catch (err) {
            this.setState({
              error:
                "Houve um problema para obter as mensagens."
            });
      }

  }

  render() {

    return (

      <div className="row">

        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Mensagens</h4>
              <p className="card-description"></p>

              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th> Canal </th>
                      <th> Data </th>
                      <th> Mensagem </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.mensagens.map((mensagem,index) => (
                    <tr key={index}>
                      <td> {mensagem.canal} </td>
                      <td> {mensagem.dataIni} </td>
                      <td> {mensagem.resposta} </td>
                    </tr>
                    ))}
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>

      </div>
      
    )
  }
}


export default Mensagem;
