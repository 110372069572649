import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import api from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import IntlCurrencyInput from "react-intl-currency-input";
import { Link, withRouter } from "react-router-dom";

const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};

export class Pagamento extends Component {
    constructor(props) {
        super(props);

        const { codigoCota, codigoCliente } = this.props;

        this.state = {
            codigoCota: codigoCota,
            codigoCliente: codigoCliente,
            boleto: "",
            valorPagamento: "",
            codigoBancoCliente: "",
            tipoPagamento: "",
            pagamentos: [],
            recebimentos: [],
            error: "",
        };
    }

    async componentDidMount() {
        this.obtemPagamentos(this.state.codigoCota);
        this.obtemRecebimentos(this.state.codigoCliente);
    }

    async obtemRecebimentos(codigo) {
        try {
            const response = await api.post(
                "api/concash/1.0/cliente/recebimento",
                { codigo: codigo }
            );
            this.setState({ recebimentos: response.data });
        } catch (err) {
            this.setState({
                error: "Houve um problema para obter a lista de bancos.",
            });
        }
    }

    handleChangeValorPagamento = (event, value, maskedValue) => {
        event.preventDefault();
        this.setState({
            valorPagamento: maskedValue
                .replace("R$", "")
                .replace(".", "")
                .replace(",", ".")
                .trim(),
        });
    };

    async obtemPagamentos(codigo) {
        try {
            const response = await api.post(
                "api/concash/1.0/consorcio-grupo-cota-pagamento/listar",
                { codigoCota: codigo }
            );
            this.setState({ pagamentos: response.data });
        } catch (err) {
            this.setState({
                error: "Houve um problema para obter os pagamentos.",
            });
        }
    }

    handleFormPagamento = async (e) => {
        e.preventDefault();
        const { boleto, valorPagamento, codigoBancoCliente, tipoPagamento } =
            this.state;
        if (!valorPagamento) {
            this.setState({
                error: "Preencha os campos obrigatórios para continuar!",
            });
        } else {
            try {
                Swal.fire({
                    title: "Aguarde ...",
                    onBeforeOpen: () => {
                        Swal.showLoading();
                    },
                });

                let codigo = this.state.codigoCota;
                const formData = new FormData();
                formData.append("file", boleto);
                formData.append("codigoCota", codigo);
                formData.append("codigoClienteBanco", codigoBancoCliente);
                formData.append("tipo", tipoPagamento);
                formData.append("valor", valorPagamento);
                const config = {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                };
                const response = await api.post(
                    "api/concash/1.0/consorcio-grupo-cota-pagamento/adicionar",
                    formData,
                    config
                );
                this.obtemPagamentos(codigo);

                Swal.close();
            } catch (err) {
                this.setState({
                    error: "Houve um problema com o processamento da requisição.",
                });
            }
        }
    };

    handleFormOpenPagamento = async (comprovante, e) => {
        e.preventDefault();
        try {
            Swal.fire({
                title: "Aguarde ...",
                onBeforeOpen: () => {
                    Swal.showLoading();
                },
            });

            const response = await api.post(
                "api/concash/1.0/consorcio-grupo-cota-pagamento/download",
                {
                    comprovante: comprovante,
                },
                { responseType: "blob" }
            );

            const file = new Blob([response.data], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;

            Swal.close();
        } catch (err) {
            this.setState({
                error: "Houve um problema com o processamento da requisição.",
            });
        }
    };

    handleDeletarPagamento = async (codigo, e) => {
        var classe = this;
        Swal.fire({
            title: "Você tem certeza que deseja excluir esse pagamento?",
            text: "Após exclusão não será possivel a reversão",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim!",
            cancelButtonText: "Não!",
        }).then((result) => {
            if (result.value) {
                classe.handleDeletarPagamentoEfetivar(codigo, e);
            }
        });
    };

    handleDeletarPagamentoEfetivar = async (codigo, e) => {
        e.preventDefault();
        if (!codigo) {
            this.setState({ error: "Código indefinido!" });
        } else {
            try {
                let codigo = this.state.codigoCota;
                const response = await api.put(
                    "api/concash/1.0/consorcio-grupo-cota-pagamento/excluir",
                    {
                        codigo,
                    }
                );
                this.obtemPagamentos(codigo);
                Swal.fire("Pagamento excluído com sucesso!");
            } catch (err) {
                this.setState({
                    error: "Houve um problema com a exclusão.",
                });
            }
        }
    };

    render() {
        return (
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Pagamentos</h4>
                            <p className="card-description"></p>

                            <form
                                className="forms-sample"
                                onSubmit={this.handleFormPagamento}
                            >
                                <div className="row">
                                    <div className="col-md-12 grid-margin stretch-card">
                                        <div className="card">
                                            <div className="card-body">
                                                <Form.Group className="row">
                                                    <label
                                                        htmlFor="examplePropostal"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Tipo
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <select
                                                            className="form-control"
                                                            value={
                                                                this.state
                                                                    .tipoPagamento
                                                            }
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    tipoPagamento:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                            id="origem"
                                                        >
                                                            <option value="">
                                                                Selecione
                                                            </option>
                                                            <option value="COMPRA_DE_COTA">
                                                                COMPRA_DE_COTA
                                                            </option>
                                                            <option value="QUITACAO_ATRASOS_COTA">
                                                                QUITACAO_ATRASOS_COTA
                                                            </option>
                                                            <option value="TRANSFERENCIA_COTA">
                                                                TRANSFERENCIA_COTA
                                                            </option>
                                                        </select>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className="row">
                                                    <label
                                                        htmlFor="examplePropostal"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Conta Bancária
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <select
                                                            className="form-control"
                                                            value={
                                                                this.state
                                                                    .codigoBancoCliente
                                                            }
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    codigoBancoCliente:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                            id="codigoAdministradora"
                                                        >
                                                            <option value="">
                                                                Selecione
                                                            </option>
                                                            {this.state.recebimentos.map(
                                                                (
                                                                    recebimento,
                                                                    index
                                                                ) => (
                                                                    <option
                                                                        value={
                                                                            recebimento.codigo
                                                                        }
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {
                                                                            recebimento
                                                                                .banco
                                                                                .nome
                                                                        }{" "}
                                                                        -{" "}
                                                                        {
                                                                            recebimento.agencia
                                                                        }{" "}
                                                                        -{" "}
                                                                        {
                                                                            recebimento.conta
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className="row">
                                                    <label className="col-sm-3 col-form-label">
                                                        Valor
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <IntlCurrencyInput
                                                            currency="BRL"
                                                            value={
                                                                this.state
                                                                    .valorPagamento
                                                            }
                                                            config={
                                                                currencyConfig
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChangeValorPagamento
                                                            }
                                                        />
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className="row">
                                                    <label
                                                        htmlFor="examplePropostal"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Boleto
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <Form.Control
                                                            type="file"
                                                            className="form-control"
                                                            id="file"
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    boleto: e
                                                                        .target
                                                                        .files[0],
                                                                })
                                                            }
                                                            size="sm"
                                                        />
                                                    </div>
                                                </Form.Group>

                                                <div className="text-right">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary mr-2"
                                                    >
                                                        Adicionar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th> Data </th>
                                            <th> Tipo </th>
                                            <th> Valor </th>
                                            <th> Conta Bancária </th>
                                            <th> Boleto </th>
                                            <th> Comprovante </th>
                                            <th className="text-center">
                                                {" "}
                                                Ação{" "}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.pagamentos.map(
                                            (pagamento, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {" "}
                                                        {pagamento.dataIni}{" "}
                                                    </td>
                                                    <td> {pagamento.tipo} </td>
                                                    <td> {pagamento.valor} </td>
                                                    <td>
                                                        {pagamento.clienteBanco && (
                                                            <span>
                                                                {
                                                                    pagamento
                                                                        .clienteBanco
                                                                        .banco
                                                                        .nome
                                                                }{" "}
                                                                -{" "}
                                                                {
                                                                    pagamento
                                                                        .clienteBanco
                                                                        .agencia
                                                                }{" "}
                                                                -{" "}
                                                                {
                                                                    pagamento
                                                                        .clienteBanco
                                                                        .conta
                                                                }{" "}
                                                                -{" "}
                                                                {
                                                                    pagamento
                                                                        .clienteBanco
                                                                        .digito
                                                                }{" "}
                                                            </span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {pagamento.boleto && (
                                                            <button
                                                                type="button"
                                                                onClick={(e) =>
                                                                    this.handleFormOpenPagamento(
                                                                        pagamento.boleto,
                                                                        e
                                                                    )
                                                                }
                                                                className="btn btn-primary mr-2"
                                                            >
                                                                Download
                                                            </button>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {pagamento.comprovante && (
                                                            <button
                                                                type="button"
                                                                onClick={(e) =>
                                                                    this.handleFormOpenPagamento(
                                                                        pagamento.comprovante,
                                                                        e
                                                                    )
                                                                }
                                                                className="btn btn-primary mr-2"
                                                            >
                                                                Download
                                                            </button>
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        <Link
                                                            className="m-3 btn btn-sm btn-danger"
                                                            onClick={(e) =>
                                                                this.handleDeletarPagamento(
                                                                    pagamento.codigo,
                                                                    e
                                                                )
                                                            }
                                                        >
                                                            Deletar
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Pagamento;
