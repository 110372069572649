import React, { Component } from "react";
import api from "../../services/api";
import { Link } from "react-router-dom";
import { TextField } from "@mui/material";
import { MdAdd } from "react-icons/md";
import BoasVindas from "../../assets/boas_vindas_verde.svg";
import TriagemBoasVindas from "../../assets/triagem_boas_vindas_verde.svg";
import EnvioDeProposta from "../../assets/envio_de_proposta_verde.svg";
import CadastroDoCliente from "../../assets/cadastro_do_cliente_verde.svg";
import TriagemDocumentos from "../../assets/triagem_documentos_verde.svg";
import RegistroDeProposta from "../../assets/registro_de_proposta_verde.svg";
import TriagemTransferencia from "../../assets/triagem_transferencia_verde.svg";
import Pagamento from "../../assets/pagamento_verde.svg";
import ConfirmacaoPagamento from "../../assets/confirmacao_pagamento_verde.svg";

export class ConsorcioGrupoCotaLista extends Component {
  constructor() {
    super();
    this.state = {
      cotas: [],
      cotasFiltered: [],
      isLoading: false,
      isMobile: false,
      filtro: "",
    };
  }

  async componentDidMount() {
    this.setState({ isMobile: window.innerWidth <= 800 });
    this.obtemListaCotas();
  }

  async obtemListaCotas() {
    this.setState({ isLoading: true });
    try {
      const response = await api.get(
        `/api/concash/1.0/cliente/listar-cotas`,
        {}
      );
      this.setState({ cotas: response.data });
      this.setState({ cotasFiltered: response.data });
    } catch (err) {
      this.setState({
        error: "Houve um problema para obter a lista de cotas.",
      });
    }
    this.setState({ isLoading: false });
  }

  iconeEtapa(idEtapa) {
    switch (idEtapa) {
      case 1:
        return <img src={BoasVindas} />;
      case 2:
        return <img src={TriagemBoasVindas} />;
      case 3:
        return <img src={EnvioDeProposta} />;
      case 4:
        return <img src={CadastroDoCliente} />;
      case 5:
        return <img src={TriagemDocumentos} />;
      case 6:
        return <img src={RegistroDeProposta} />;
      case 7:
        return <img src={TriagemTransferencia} />;
      case 8:
        return <img src={Pagamento} />;
      case 9:
        return <img src={ConfirmacaoPagamento} />;
      default:
        return "-";
    }
  }

  descricaoEtapa(idEtapa) {
    switch (idEtapa) {
      case 1:
        return "Boas vindas";
      case 2:
        return "Triagem boas vindas";
      case 3:
        return "Envio de proposta";
      case 4:
        return "Cadastro de cliente";
      case 5:
        return "Triagem documentos";
      case 6:
        return "Registro de proposta";
      case 7:
        return "Triagem transferência";
      case 8:
        return "Pagamento";
      case 9:
        return "Confirmação de pagamento";
      default:
        return "-";
    }
  }

  filtrar(valor) {
    if (valor == "") {
      this.setState({ filtro: "" });
      this.setState({ cotasFiltered: this.state.cotas });
    } else {
      this.setState({ filtro: valor });
      var data = this.state.cotas;
      data = data.filter(
        (item) =>
          item.cgcGrupo
            .toString()
            .toLowerCase()
            .includes(valor.toString().toLowerCase()) ||
          item.cgcCota
            .toString()
            .toLowerCase()
            .includes(valor.toString().toLowerCase()) ||
          item.cgcVersao
            .toString()
            .toLowerCase()
            .includes(valor.toString().toLowerCase())
      );
      this.setState({ cotasFiltered: data });
    }
  }

  renderSemCota(valorMarginTop, posicaoBotao) {
    return (
      <>
        <div className="row" style={{ marginTop: valorMarginTop }}>
          <div className="col-md-12" align="center">
            <img src={require("../../assets/file-document-outline-gray.png")} />
          </div>
        </div>
        <br />
        <div class="row">
          <div className="col-md-12" align="center">
            <p
              style={{
                fontSize: "15px",
                fontFamily: "Roboto",
                color: "#959595",
              }}
            >
              <b>Nenhuma cota adquirida</b>
              <br />
              Solicite uma proposta no botão {posicaoBotao}
            </p>
          </div>
        </div>
      </>
    );
  }

  montarBotaoVisualizar(cota) {
    return (
      <Link
        to={{
          pathname: "/consorcio-grupo-cota/detalhe",
          state: { codigo: cota.id, cota: cota, cliente: cota.cliente },
        }}
      >
        <img src={require("../../assets/forward.png")} />
      </Link>
    );
  }

  montarSemResultados() {
    return (
      <>
        <div className="row" style={{ marginTop: "300px" }}>
          <div className="col-md-12" align="center">
            <img src={require("../../assets/dangerous_gray.png")} />
          </div>
        </div>
        <br />
        <div class="row">
          <div className="col-md-12" align="center">
            <p
              style={{
                fontSize: "15px",
                fontFamily: "Roboto",
                color: "#959595",
              }}
            >
              <b>Nenhum resultado encontrado</b>
              <br />
              Tente novamente
            </p>
          </div>
        </div>
        <div
          class="row"
          style={{ cursor: "pointer" }}
          onClick={() => this.filtrar("")}
        >
          <div
            className="col-md-12"
            align="center"
            style={{
              font: "normal normal bold 15px/20px Roboto",
              color: "#05898F",
            }}
          >
            <i
              className="mdi mdi-refresh"
              style={{ color: "#05898F", fontSize: "1.5em" }}
            ></i>{" "}
            Limpar resultados
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <>
        {this.state.isMobile ? (
          <div className="row">
            <div
              className="col-md-12"
              style={{
                marginTop: "80px",
                position: "relative",
                width: "screen.width",
                overflowY: "auto",
                height: "580px",
              }}
            >
              <div className="row">
                <div className="col-md-12 grid-margin" align="center">
                  <span style={{ font: "normal normal bold 24px/30px Roboto" }}>
                    {" "}
                    Minhas cotas{" "}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 grid-margin" align="center">
                  <TextField
                    className="input-form-miu"
                    label="Buscar por grupo, cota ou versão"
                    style={{ width: "100%", borderRadius: "50px!important" }}
                    value={this.state.filtro}
                    variant="outlined"
                    onChange={(e) => this.filtrar(e.target.value)}
                  />
                </div>
              </div>
              {this.state.cotas == [] ? (
                <>{this.renderSemCota("150px", "abaixo")}</>
              ) : (
                <>
                  {this.state.cotasFiltered.length == 0 ? (
                    <>{this.montarSemResultados()}</>
                  ) : (
                    <>
                      {this.state.cotasFiltered.map((cota, index) => (
                        <>
                          <div className="row-mobile">
                            <div
                              className="col-sm-3 col-3 m-auto"
                              style={{ left: "-15px" }}
                            >
                              <div
                                style={{
                                  height: "120px",
                                  width: "150%",
                                  backgroundColor: "#DFF7FA",
                                  border: "1px solid #A5E7EA",
                                  borderRadius: "10px",
                                }}
                                align="center"
                              >
                                <br />
                                {this.iconeEtapa(cota.cgcEtapa)}
                                <div
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: "Roboto",
                                    color: "#05898F",
                                  }}
                                >
                                  {this.descricaoEtapa(cota.cgcEtapa)}
                                </div>
                                <div
                                  style={{
                                    fontSize: "11px",
                                    fontFamily: "Roboto",
                                    color: "#000000",
                                    opacity: "0.5",
                                    letterSpacing: "0px",
                                  }}
                                >
                                  PASSO {cota.cgcEtapa}/9
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-sm-8 col-8 m-auto"
                              style={{ verticalAlign: "middle" }}
                            >
                              <h1
                                style={{
                                  font: "normal normal bold 15px/20px Roboto",
                                }}
                              >
                                {cota.cgcSadNome}
                              </h1>
                              <h1
                                style={{
                                  font: "normal normal normal 12px/20px Roboto",
                                }}
                              >
                                Grupo: {cota.cgcGrupo} Cota: {cota.cgcCota}{" "}
                                <br />
                                Versão: {cota.cgcVersao} Contrato:{" "}
                                {cota.cgcContrato}
                              </h1>
                            </div>
                            <div
                              className="col-sm-1 col-1 m-auto grid-margin stretch-card"
                              align="right"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {this.montarBotaoVisualizar(cota)}
                            </div>
                          </div>
                          <hr />
                        </>
                      ))}
                    </>
                  )}
                </>
              )}
              <div
                id="absolute"
                style={{
                  position: "absolute",
                  bottom: "40px",
                  right: "120px",
                  marginRight: "35px",
                }}
              >
                <Link to={{ pathname: "/nova-cota", state: {} }}>
                  <button
                    className="btn btn-primary mr-2"
                    style={{
                      width: "150px",
                      position: "fixed",
                      bottom: 20,
                      height: "45px",
                      borderColor: "#16898E",
                      borderRadius: "10px",
                      boxShadow: "0px 10px 20px #00000040",
                      background: "#16898E 0% 0% no-repeat padding-box",
                      font: "normal normal bold 15px/20px Roboto",
                    }}
                  >
                    <MdAdd color="#ffffff" size={18}></MdAdd> Nova proposta
                  </button>
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-md-2">
                <p style={{ fontSize: "30px", fontFamily: "Roboto" }}>
                  <b>Minhas cotas</b>
                </p>
              </div>
              <div className="col-md-7">
                <TextField
                  className="input-form-miu"
                  label="Buscar por grupo, cota ou versão"
                  style={{ width: "50%", borderRadius: "10px" }}
                  value={this.state.filtro}
                  variant="outlined"
                  onChange={(e) => this.filtrar(e.target.value)}
                />
              </div>
              <div className="col-md-3" align="right">
                <Link to={{ pathname: "/nova-cota", state: {} }}>
                  <button
                    className="btn btn-primary mr-2"
                    style={{
                      height: "45px",
                      borderColor: "#16898E",
                      borderRadius: "10px",
                      boxShadow: "0px 10px 20px #00000040",
                      background: "#16898E 0% 0% no-repeat padding-box",
                      font: "normal normal bold 15px/20px Roboto",
                    }}
                  >
                    <MdAdd color="#ffffff" size={18}></MdAdd> Nova proposta
                  </button>
                </Link>
              </div>
            </div>

            {this.state.cotas == [] ? (
              <>{this.renderSemCota("300px", "acima")}</>
            ) : (
              <>
                {this.state.cotasFiltered.length == 0 ? (
                  <>{this.montarSemResultados()}</>
                ) : (
                  <>
                    {this.state.cotasFiltered.map((cota, index) => (
                      <>
                        <div className="row" style={{ marginTop: "50px" }}>
                          <div className="green-box">
                            <div
                              style={{
                                height: "100px",
                                width: "100px",
                                backgroundColor: "#DFF7FA",
                                border: "1px solid #A5E7EA",
                                borderRadius: "10px",
                              }}
                              align="center"
                            >
                              <br />
                              {this.iconeEtapa(cota.cgcEtapa)}
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "Roboto",
                                  color: "#05898F",
                                }}
                              >
                                {this.descricaoEtapa(cota.cgcEtapa)}
                              </div>
                              <div
                                style={{
                                  fontSize: "11px",
                                  fontFamily: "Roboto",
                                  color: "#000000",
                                  opacity: "0.5",
                                  letterSpacing: "0px",
                                }}
                              >
                                PASSO {cota.cgcEtapa}/9
                              </div>
                            </div>
                          </div>
                          <div
                            className="mid-box"
                            style={{ verticalAlign: "left" }}
                          >
                            <br />
                            <div
                              style={{
                                font: "normal normal bold 15px/20px Roboto",
                              }}
                            >
                              {cota.cgcSadNome}
                            </div>
                            <div
                              style={{
                                font: "normal normal normal 12px/20px Roboto",
                              }}
                            >
                              Grupo: {cota.cgcGrupo} Cota: {cota.cgcCota}{" "}
                              Versão: {cota.cgcVersao} <br />
                              Contrato: {cota.cgcContrato}
                            </div>
                          </div>
                          <div
                            className="col-md-1"
                            align="right"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {this.montarBotaoVisualizar(cota)}
                          </div>
                        </div>
                        <hr />
                      </>
                    ))}
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default ConsorcioGrupoCotaLista;
