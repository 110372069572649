import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  username: "",
  cpfCnpj: "",
  token: "",
  userType: "",
  method: "",
  nome: "",
  email: "",
  phone: "",
  tokenConsort: "",
  logged: false,
  menus: []
};

export const otpCnpSlice = createSlice({
  name: "otpCnp",
  initialState,
  reducers: {
    updateAuth(state, action) {
      const { username, userType, token } = action.payload;
      state.username = username;
      state.userType = userType;
      state.token = token;
      return state;
    },
    getUserType: (state) => {
      return state.auth.userType;
    },
    updateUserData(state, {payload}){
      const {nome, email} = payload;
      state.email = email;
      state.nome = nome;
      return state;
    },
    updateMenus(state, {payload}){
      state.menus = payload;
      return state;
    },
    updateAuthenticate(state, {payload}){
      state.token = payload.token;
      state.cpfCnpj = payload.cpfCnpj;
      state.email = payload.email;
      state.phone = payload.phone;
      state.tokenConsort = payload.tokenConsort;
      state.logged = true;
      return state;
    },
    logout(state){
      state = initialState;
      return state;
    }
  },
});

export const { updateAuth, getUserType, updateUserData, updateMenus, updateAuthenticate, logout } = otpCnpSlice.actions;
export const otpCnpReducer = otpCnpSlice.reducer;