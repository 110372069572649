import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import api from "../../services/api";

import IntlCurrencyInput from "react-intl-currency-input";
import { Link } from "react-router-dom";

const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};

export class Proposta extends Component {
    constructor(props) {
        super(props);

        const { codigoCota } = this.props;

        this.state = {
            codigoCota: codigoCota,
            propostas: [],
            error: "",
        };
    }

    async componentDidMount() {
        this.obtemPropostas(this.state.codigoCota);
    }

    async obtemPropostas(codigo) {
        try {
            const response = await api.post(
                "api/concash/1.0/consorcio-grupo-cota/proposta",
                { codigoCota: codigo }
            );

            this.setState({ propostas: response.data });
        } catch (err) {
            this.setState({
                error: "Houve um problema para obter as propostas.",
            });
        }
    }

    handleChangeValorProposta = (event, value, maskedValue) => {
        event.preventDefault();
        this.setState({
            valorProposta: maskedValue
                .replace("R$", "")
                .replace(".", "")
                .replace(",", ".")
                .trim(),
        });
    };

    handleDeletarProposta = async (codigo, e) => {
        var classe = this;
        Swal.fire({
            title: "Você tem certeza que deseja enviar esta proposta?",
            text: "Após o envio não será possivel a reversão",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim!",
            cancelButtonText: "Não!",
        }).then((result) => {
            if (result.value) {
                classe.handleDeletarPropostaEfetivar(codigo, e);
            }
        });
    };

    handleDeletarPropostaEfetivar = async (codigo, e) => {
        e.preventDefault();
        if (!codigo) {
            this.setState({ error: "Código indefinido!" });
        } else {
            try {
                //let codigo        = this.state.codigoCota;
                const response = await api.put(
                    "api/concash/1.0/consorcio-grupo-cota-proposta/excluir",
                    {
                        codigo,
                    }
                );
                this.obtemPropostas(codigo);
                Swal.fire("Proposta enviada com sucesso!");
            } catch (err) {
                this.setState({
                    error: "Houve um problema com a exclusão.",
                });
            }
        }
    };

    handleFormProposta = async (e) => {
        e.preventDefault();
        const { origem, valorProposta } = this.state;
        if (!origem || !valorProposta) {
            this.setState({
                error: "Preencha os campos obrigatórios para continuar!",
            });
        } else {
            try {
                
                let codigo = this.state.codigoCota;
                await api.post(
                    "api/concash/1.0/consorcio-grupo-cota-proposta/adicionar",
                    {
                        origem: origem,
                        valor: valorProposta.replace(/\s/g, ""),
                        cota: codigo,
                    }
                );
                this.obtemPropostas(codigo);
                Swal.fire("Proposta cadastrada com sucesso!");
            } catch (err) {
                this.setState({
                    error: "Houve um problema com o processamento da requisição.",
                });
            }
        }
    };

    render() {
        return (
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Propostas</h4>
                            <p className="card-description"></p>

                            <form
                                className="forms-sample"
                                onSubmit={this.handleFormProposta}
                            >
                                <div className="row">
                                    <div className="col-md-12 grid-margin stretch-card">
                                        <div className="card">
                                            <div className="card-body">
                                                <Form.Group className="row">
                                                    <div className="col-sm-9">
                                                        <select
                                                            className="form-control"
                                                            value={
                                                                this.state
                                                                    .origem
                                                            }
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    origem: e
                                                                        .target
                                                                        .value,
                                                                })
                                                            }
                                                            id="origem"
                                                        >
                                                            <option value="">
                                                                Selecione
                                                            </option>
                                                            <option value="RESULTADO_COTA">
                                                                RESULTADO_COTA
                                                            </option>
                                                            <option value="ORIGINACAO_FUNDO">
                                                                ORIGINACAO_FUNDO
                                                            </option>
                                                            <option value="FUNDO_MB">
                                                                FUNDO_MB
                                                            </option>
                                                            <option value="FUNDO_CARREGAMENTO">
                                                                FUNDO_CARREGAMENTO
                                                            </option>
                                                            <option value="CONCASH_CARREGAMENTO">
                                                                CONCASH_CARREGAMENTO
                                                            </option>
                                                        </select>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className="row">
                                                    <label className="col-sm-3 col-form-label">
                                                        Valor Proposta
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <IntlCurrencyInput
                                                            currency="BRL"
                                                            value={
                                                                this.state
                                                                    .valorProposta
                                                            }
                                                            config={
                                                                currencyConfig
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChangeValorProposta
                                                            }
                                                        />
                                                    </div>
                                                </Form.Group>
                                                <div className="text-right">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary mr-2"
                                                    >
                                                        Adicionar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th> Código </th>
                                            <th> Valor </th>
                                            {/* <th> Origem </th> */}
                                            <th> Status </th>
                                            <th className="text-center">
                                                {" "}
                                                Ação{" "}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.propostas.map(
                                            (proposta, index) => (
                                                <tr key={index}>
                                                    <td> {proposta.codigo} </td>
                                                    <td> {proposta.valor} </td>
                                                    {/* <td> {proposta.origem} </td> */}
                                                    <td>
                                                        {" "}
                                                        {proposta.aprovado}{" "}
                                                    </td>
                                                    <td className="text-center">
                                                        {this.state
                                                            .statusInlira ===
                                                            "PROPOSTA_ACEITA" && (
                                                            <Link
                                                                className="m-3 btn btn-sm btn-primary"
                                                                onClick={(e) =>
                                                                    this.handleHomologaAceitePropostaInLira(
                                                                        e
                                                                    )
                                                                }
                                                            >
                                                                Homologar InLira
                                                            </Link>
                                                        )}
                                                        <Link
                                                            className="m-3 btn btn-sm btn-success"
                                                            onClick={(e) =>
                                                                this.handleDeletarProposta(
                                                                    proposta.codigo,
                                                                    e
                                                                )
                                                            }
                                                        >
                                                            Enviar Proposta
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Proposta;
