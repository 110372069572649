import React from 'react';
import S from "../components/common/Base.module.css";

const Cell = ({tipo, texto, icone, link, onClick, estilo}) => {

  return (
    <div>
      {tipo === "PROFILE" && (
        <div className={S.cellMain}>
          <div className={S.circleLetter} style={{ backgroundColor: estilo?.secondary}}>{texto[0]}</div>
          <div className={S.cellTitleName}>
            Olá, {texto}
          </div>
        </div>
      )}
      {tipo === "MENU" && (
        <a href={link} style={{ textDecoration: 'none !important', color: estilo?.secondary}} className={S.cellLinkMenu}>
          {icone}<span className={S.textMenu} style={{ color: estilo?.secondary, textDecoration: "none"}}>{texto}</span>
        </a>
      )}
      {tipo === "LOGOUT" && (
        <div onClick={onClick} style={{ textDecoration: 'none'}} className={S.cellLinkMenu}>
          {icone}<span className={S.textMenu} style={{ color: estilo?.secondary, textDecoration: "none"}}>{texto}</span>
        </div>
      )}
    </div>
  );
}

export default Cell;
