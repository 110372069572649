import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import api from "../../services/api";
import { Link, withRouter } from "react-router-dom";


export class FormTipoProcesso extends Component {



  constructor(props){

    super(props);

    this.state = {
          codigo: '',
          nome: '',
          labelForm: '',
          labelButton: '',
          tipoProcesso: [],
          error: "",
          action: ""
    };
  }


  async componentDidMount() {

    if(this.props[0].location.state !== undefined){
        const tipoProcesso  = this.props[0].location.state.tipoProcesso
        this.setState({nome: tipoProcesso.nome,codigo: tipoProcesso.codigo});
        this.setState({labelForm: "Editar Tipo Processo",labelButton: "Editar",action: "editar"});
    }else{
        this.setState({labelForm: "Adicionar Tipo Processo",labelButton: "Adicionar",action: "adicionar"});
    }

  }

  handleForm = async e => {
    e.preventDefault();
    const { action} = this.state;
    const { nome}   = this.state;
    const { codigo} = this.state;
    if (!nome
        || !action) {
      this.setState({ error: "Preencha os campos obrigatórios para continuar!" });
    } else {
      try {

        if(action == "editar"){
          const response = await api.put("api/concash/1.0/tipo-processo/"+action, {
              codigo,nome
            });
        }else{
          const response = await api.post("api/concash/1.0/tipo-processo/"+action, {
              nome
            });
        }

        this.props.history.push("/tipo-processo");

      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o processamento da requisição."
        });
      }
    }
  };

  render() {


    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> {this.state.labelForm} </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>

        <div className="row">

          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"></h4>
                {this.state.error && <p className="card-description">{this.state.error}</p>}
                <form className="forms-sample" onSubmit={this.handleForm}>
                <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Nome</label>
                        <div className="col-sm-7">
                        <Form.Control type="text" id="nome" value={this.state.nome} placeholder="Nome do Tipo de Processo" onChange={e => this.setState({ nome: e.target.value })} size="sm" />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="text-right">
                    <button type="submit" className="btn btn-primary mr-2">{this.state.labelButton}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
         </div>
       </div>
    )
  }
}

export default withRouter(FormTipoProcesso)
