export const money = (v) => {
  if(v){
    v = v.replace(/\D/g, "")
    v = v + '';
    v = parseInt(v.replace(/[\D]+/g,''));
    v = v + '';
    v = v.replace(/([0-9]{2})$/g, ",$1");

    if (v.length > 6) {
      v = v.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }    
  }
  
    return v
  }