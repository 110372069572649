import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import styled, { css } from 'styled-components';


const bubleCliente = {

   color:      "white",
   textAlign:  "justify",
   padding:    "15px",
   marginTop:  "15px",
   marginLeft: "15px",
   fontSize:   "12px",
   background: "green",
   borderRadius:"10px",
}

const bubleClienteData = {

   color:      "black",
   fontSize:   "8px",
   marginLeft: "15px",
   textAlign:  "left",
}


export class BoxMensagem extends Component {


  constructor(props){
        super(props);
  }


  render() {

    const { mensagem }  = this.props;

    return (
      <>
           <div className="col-md-8" style={bubleCliente}>
            {mensagem}
           </div>
      </>
    )
  }
}

export default BoxMensagem;
