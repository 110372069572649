import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker, { registerLocale } from "react-datepicker";
import FloatingLabelInput from 'react-floating-label-input';
import moment from 'moment';
import api from "../../services/api";
import { cellphoneFormater,currencyFormater,numberFormater} from '../../lib/formaters';
import IntlCurrencyInput from "react-intl-currency-input"
import InputFloat from 'react-floating-input'
import ptBR from "date-fns/locale/pt-BR";
import Swal from 'sweetalert2'

registerLocale("pt-BR", ptBR);

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};


export class BasicElements extends Component {
  state = {
    startDate: new Date(),
    inputVal: '',
    codigoAdministradora:'',
    codigoTipoConsorcio: '',
    valorCota:'',
    prazoPlano:'',
    assembleiaRealizar: '',
    parcelasPagas: '',
    percentualLance: '40.00',
    saldoDevedorAtualizado: '',
    encargosMultasTaxas: '',
    formaPagamentoAtraso: '',
    valorMedioParcela: '',
    fundoComumPago: '',
    proximoVencimento: '',
    mesAtualizacaoCredito: '',
    concashProposta1Valor: '',
    concashProposta1Percentual: '',
    concashProposta2Valor: '',
    concashProposta2Percentual: '',
    concashProposta3Valor: '',
    concashProposta3Percentual: '',
    originacaoMBProposta1Valor: '',
    originacaoMBProposta1Percentual: '',
    originacaoMBProposta2Valor: '',
    originacaoMBProposta2Percentual: '',
    originacaoMBProposta3Valor: '',
    originacaoMBProposta3Percentual: '',
    originacaoFundoProposta1Valor: '',
    originacaoFundoProposta1Percentual: '',
    originacaoFundoProposta2Valor: '',
    originacaoFundoProposta2Percentual: '',
    originacaoFundoProposta3Valor: '',
    originacaoFundoProposta3Percentual: '',
    originacaoFundoCarregProposta1Valor: '',
    originacaoFundoCarregProposta1Percentual: '',
    originacaoFundoCarregProposta2Valor: '',
    originacaoFundoCarregProposta2Percentual: '',
    originacaoFundoCarregProposta3Valor: '',
    originacaoFundoCarregProposta3Percentual: '',
    originacaoConCashCarregProposta1Valor: '',
    originacaoConCashCarregProposta1Percentual: '',
    originacaoConCashCarregProposta2Valor: '',
    originacaoConCashCarregProposta2Percentual: '',
    originacaoConCashCarregProposta3Valor: '',
    originacaoConCashCarregProposta3Percentual: '',
    desagioComercial: '',
    administradoras: [],
    valorReceber: '',
    error: ""
  };

  async componentDidMount() {

      try {
            const response = await api.post("api/concash/1.0/administradora/registros", {});
            this.setState({ administradoras: response.data});
      } catch (err) {
            this.setState({
              error:
                "Houve um problema para obter os tipos de parâmetros."
            });
      }

  }

  handleChangeValorCota = (event, value, maskedValue) => {
    event.preventDefault();
    this.setState({ valorCota: maskedValue.replace("R$","").replace(".","").replace(",",".").trim()   });
  };

  handleChangeFundoComumPago = (event, value, maskedValue) => {
    event.preventDefault();
    this.setState({ fundoComumPago: maskedValue.replace("R$","").replace(".","").replace(",",".").trim()   });
  };

  handleChangeEncargosMultasTaxas = (event, value, maskedValue) => {
    event.preventDefault();
    this.setState({ encargosMultasTaxas: maskedValue.replace("R$","").replace(".","").replace(",",".").trim()   });
  };

  handleChangeSaldoDevedorAtualizado = (event, value, maskedValue) => {
    event.preventDefault();
    this.setState({ saldoDevedorAtualizado: maskedValue.replace("R$","").replace(".","").replace(",",".").trim()   });
  };

  handleChange = date => {
    this.setState({
      startDate: date
    });
    this.setState({
      proximoVencimento: moment(date).format("DD/MM/yyyy")
    });
  };


  handleSignIn = async e => {
    e.preventDefault();

    const { codigoAdministradora,
            codigoTipoConsorcio,
            valorCota,
            prazoPlano,
            assembleiaRealizar,
            formaPagamentoAtraso,
            encargosMultasTaxas,
            proximoVencimento,
            saldoDevedorAtualizado,
            fundoComumPago,
            mesAtualizacaoCredito,
            percentualLance,
            parcelasPagas } = this.state;
    if (1>2) {
      this.setState({ error: "Preencha os campos obrigatórios para continuar!" });
    } else {
      try {

        Swal.fire ({
           title: 'Aguarde ...',
           onBeforeOpen: () => {
             Swal.showLoading ()
           }
        });

        const response = await api.post("api/concash/1.0/simulador/listar", {
            codigoTipoConsorcio,
            valorCota,
            codigoAdministradora,
            prazoPlano,
            formaPagamentoAtraso,
            assembleiaRealizar,
            encargosMultasTaxas,
            fundoComumPago,
            percentualLance,
            proximoVencimento,
            mesAtualizacaoCredito,
            saldoDevedorAtualizado,
            parcelasPagas
          });
        this.setState({ valorReceber: response.data.RESULTADO_COTA.Resumo.ValorReceber });
        this.setState({ desagioComercial: response.data.RESULTADO_COTA.Resumo.DesagioComercial + "%" });
        this.setState({ concashProposta1Valor: response.data.RESULTADO_COTA.Resumo.Proposta1Valor });
        this.setState({ concashProposta1Percentual: response.data.RESULTADO_COTA.Resumo.Proposta1Percentual  + "%" });
        this.setState({ concashProposta2Valor: response.data.RESULTADO_COTA.Resumo.Proposta2Valor });
        this.setState({ concashProposta2Percentual: response.data.RESULTADO_COTA.Resumo.Proposta2Percentual  + "%" });
        this.setState({ concashProposta3Valor: response.data.RESULTADO_COTA.Resumo.Proposta3Valor });
        this.setState({ concashProposta3Percentual: response.data.RESULTADO_COTA.Resumo.Proposta3Percentual  + "%" });
        this.setState({ originacaoMBProposta1Valor: response.data.FUNDO_MB.Resumo.Proposta1Valor });
        this.setState({ originacaoMBProposta1Percentual: response.data.FUNDO_MB.Resumo.Proposta1Percentual  + "%" });
        this.setState({ originacaoMBProposta2Valor: response.data.FUNDO_MB.Resumo.Proposta2Valor });
        this.setState({ originacaoMBProposta2Percentual: response.data.FUNDO_MB.Resumo.Proposta2Percentual  + "%" });
        this.setState({ originacaoMBProposta3Valor: response.data.FUNDO_MB.Resumo.Proposta3Valor });
        this.setState({ originacaoMBProposta3Percentual: response.data.FUNDO_MB.Resumo.Proposta3Percentual   + "%"});
        this.setState({ originacaoFundoProposta1Valor: response.data.ORIGINACAO_FUNDO.Resumo.Proposta1Valor });
        this.setState({ originacaoFundoProposta1Percentual: response.data.ORIGINACAO_FUNDO.Resumo.Proposta1Percentual  + "%"});
        this.setState({ originacaoFundoProposta2Valor: response.data.ORIGINACAO_FUNDO.Resumo.Proposta2Valor });
        this.setState({ originacaoFundoProposta2Percentual: response.data.ORIGINACAO_FUNDO.Resumo.Proposta2Percentual  + "%"});
        this.setState({ originacaoFundoProposta3Valor: response.data.ORIGINACAO_FUNDO.Resumo.Proposta3Valor });
        this.setState({ originacaoFundoProposta3Percentual: response.data.ORIGINACAO_FUNDO.Resumo.Proposta3Percentual  + "%" });
        this.setState({ originacaoFundoCarregProposta1Valor: response.data.FUNDO_CARREGAMENTO.Resumo.Proposta1Valor });
        this.setState({ originacaoFundoCarregProposta1Percentual: response.data.FUNDO_CARREGAMENTO.Resumo.Proposta1Percentual  + "%" });
        this.setState({ originacaoFundoCarregProposta2Valor: response.data.FUNDO_CARREGAMENTO.Resumo.Proposta2Valor });
        this.setState({ originacaoFundoCarregProposta2Percentual: response.data.FUNDO_CARREGAMENTO.Resumo.Proposta2Percentual  + "%"});
        this.setState({ originacaoFundoCarregProposta3Valor: response.data.FUNDO_CARREGAMENTO.Resumo.Proposta3Valor });
        this.setState({ originacaoFundoCarregProposta3Percentual: response.data.FUNDO_CARREGAMENTO.Resumo.Proposta3Percentual  + "%"});
        this.setState({ originacaoConCashCarregProposta1Valor: response.data.CONCASH_CARREGAMENTO.Resumo.Proposta1Valor });
        this.setState({ originacaoConCashCarregProposta1Percentual: response.data.CONCASH_CARREGAMENTO.Resumo.Proposta1Percentual  + "%" });
        this.setState({ originacaoConCashCarregProposta2Valor: response.data.CONCASH_CARREGAMENTO.Resumo.Proposta2Valor });
        this.setState({ originacaoConCashCarregProposta2Percentual: response.data.CONCASH_CARREGAMENTO.Resumo.Proposta2Percentual  + "%"});
        this.setState({ originacaoConCashCarregProposta3Valor: response.data.CONCASH_CARREGAMENTO.Resumo.Proposta3Valor });
        this.setState({ originacaoConCashCarregProposta3Percentual: response.data.CONCASH_CARREGAMENTO.Resumo.Proposta3Percentual  + "%"});
        Swal.close();

      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o login, verifique suas credenciais."
        });
        Swal.close();
      }
    }

  };


  render() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Simulador </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            </ol>
          </nav>
        </div>

        <div className="row">

          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Dados da Cota</h4>
                <p className="card-description"></p>
                {this.state.error && <p className="card-description">{this.state.error}</p>}

                <form className="forms-sample" onSubmit={this.handleSignIn}>

                <div className="row">
                  <div className="col-md-12">
                    <Form.Group className="row" >
                      <label className="col-sm-5 col-form-label">Administradora</label>
                      <div className="col-sm-7">
                        <select className="form-control" value={this.state.tipo} onChange={e => this.setState({ codigoAdministradora: e.target.value })} id="codigoAdministradora">
                          <option value="">Selecione</option>
                          {this.state.administradoras.map((ad,index) => (
                            <option value={ad.codigo} key={index}>{ad.nome}</option>
                          ))}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <Form.Group className="row">
                      <label className="col-sm-5 col-form-label">Produto</label>
                      <div className="col-sm-7">
                        <select className="form-control" id="codigoTipoConsorcio" onChange={e => this.setState({ codigoTipoConsorcio: e.target.value })}>
                          <option value="">Selecione</option>
                          <option value="001">IMOVEL</option>
                          <option value="002">SERVICO</option>
                          <option value="003">VEICULO</option>
                          <option value="004">OUTROS</option>
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Crédito (Valor da Cota)</label>
                        <div className="col-sm-7">
                        <IntlCurrencyInput currency="BRL" config={currencyConfig}
       onChange={this.handleChangeValorCota} />
                        </div>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Prazo do Plano</label>
                        <div className="col-sm-7">
                        <Form.Control type="number" id="prazoPlano" placeholder="Prazo do Plano" size="sm" onChange={e => this.setState({ prazoPlano: e.target.value })}/>
                        </div>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Assembléias a Realizar</label>
                        <div className="col-sm-7">
                        <Form.Control type="number" id="assembleiaRealizar" placeholder="Assembléias a Realizar" size="sm" onChange={e => this.setState({ assembleiaRealizar: e.target.value })}/>
                        </div>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Parcelas Pagas</label>
                        <div className="col-sm-7">
                        <Form.Control type="number" id="parcelasPagas" placeholder="Parcelas Pagas" size="sm" onChange={e => this.setState({ parcelasPagas: e.target.value })}/>
                        </div>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Saldo Devedor Atualizado</label>
                        <div className="col-sm-7">
                        <IntlCurrencyInput currency="BRL" config={currencyConfig}
       onChange={this.handleChangeSaldoDevedorAtualizado} />
                        </div>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Percentual de encargos, multas e taxas:</label>
                        <div className="col-sm-7">
                        <Form.Control type="number"  id="encargosMultasTaxas" value={this.state.encargosMultasTaxas} size="sm" onChange={e => this.setState({ encargosMultasTaxas: e.target.value })}/>
                        </div>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Forma Pagamento Atraso</label>
                        <div className="col-sm-7">
                          <select className="form-control" id="formaPagamentoAtraso" onChange={e => this.setState({ formaPagamentoAtraso: e.target.value })}>
                            <option value="">Selecione</option>
                            <option value="A vista">A vista</option>
                            <option value="Diluir no Prazo">Diluir no Prazo</option>
                          </select>
                        </div>
                      </Form.Group>
                    </div>
                  </div>


                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Fundo Comum Pago</label>
                        <div className="col-sm-7">
                        <IntlCurrencyInput currency="BRL" config={currencyConfig}
       onChange={this.handleChangeFundoComumPago} />
                        </div>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Próximo Vencimento</label>
                        <div className="col-sm-7">
                          <DatePicker className="form-control w-100" id="proximoVencimento"
                            dateFormat="dd/MM/yyyy"
                            selected={this.state.startDate}
                            onChange={this.handleChange}
                            locale='pt-BR'
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <label className="col-sm-5 col-form-label">Mês de Atualização Crédito</label>
                        <div className="col-sm-7">
                          <select className="form-control" id="mesAtualizacaoCredito" onChange={e => this.setState({ mesAtualizacaoCredito: e.target.value })}>
                            <option value="1">Janeiro</option>
                            <option value="2">Fevereiro</option>
                            <option value="3">Março</option>
                            <option value="4">Abril</option>
                            <option value="5">Maio</option>
                            <option value="6">Junho</option>
                            <option value="7">Julho</option>
                            <option value="8">Agosto</option>
                            <option value="9">Setembro</option>
                            <option value="10">Outubro</option>
                            <option value="11">Novembro</option>
                            <option value="12">Dezembro</option>
                          </select>
                        </div>
                      </Form.Group>
                    </div>
                  </div>

                  <button type="submit" className="btn btn-primary mr-2">Enviar</button>
                </form>
              </div>
            </div>
          </div>

          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Resultado</h4>
                <p className="card-description"></p>
                <form className="forms-sample">
                  <p className="card-description">Resultado Consorciado</p>
                  <Form.Group className="row">
                    <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Deságio Comercial</label>
                    <div className="col-sm-9">
                    <Form.Control type="text" readOnly="true" className="form-control" id="desagioComercial" value={this.state.desagioComercial} size="sm"/>
                    </div>
                  </Form.Group>
                  <Form.Group className="row">
                    <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Valor a Receber</label>
                    <div className="col-sm-9">
                    <Form.Control type="text" readOnly="true" className="form-control" id="valorReceber" value={this.state.valorReceber} size="sm"/>
                    </div>
                  </Form.Group>
                  <p className="card-description">Resultado ConCash</p>
                  <Form.Group className="row">
                    <label htmlFor="examplePropostal" className="col-sm-3 col-form-label">Proposta 1</label>
                    <div className="col-sm-5">
                    <Form.Control type="text" readOnly="true" className="form-control" id="concashProposta1Valor" value={this.state.concashProposta1Valor}   size="sm"/>
                    </div>
                    <div className="col-sm-4">
                    <Form.Control type="text" readOnly="true" className="form-control" id="concashProposta1Percentual" value={this.state.concashProposta1Percentual} size="sm"/>
                    </div>
                  </Form.Group>
                  <Form.Group className="row">
                    <label htmlFor="exampleProposta2" className="col-sm-3 col-form-label">Proposta 2</label>
                    <div className="col-sm-5">
                    <Form.Control type="text" readOnly="true" className="form-control" id="concashProposta2Valor" value={this.state.concashProposta2Valor} size="sm" />
                    </div>
                    <div className="col-sm-4">
                    <Form.Control type="text" readOnly="true" className="form-control" id="concashProposta2Percentual" value={this.state.concashProposta2Percentual} size="sm"/>
                    </div>
                  </Form.Group>
                  <Form.Group className="row">
                    <label htmlFor="exampleProposta3" className="col-sm-3 col-form-label">Proposta 3</label>
                    <div className="col-sm-5">
                    <Form.Control type="text" readOnly="true" className="form-control" id="concashProposta3Valor" value={this.state.concashProposta3Valor} size="sm" />
                    </div>
                    <div className="col-sm-4">
                    <Form.Control type="text" readOnly="true" className="form-control" id="concashProposta3Percentual" value={this.state.concashProposta3Percentual} size="sm"/>
                    </div>
                  </Form.Group>

                  <p className="card-description">Resultado Originação MB</p>
                  <Form.Group className="row">
                    <label htmlFor="exampleProposta4" className="col-sm-3 col-form-label">Proposta 1</label>
                    <div className="col-sm-5">
                    <Form.Control type="text" readOnly="true" className="form-control" id="originacaoMBProposta1Valor" value={this.state.originacaoMBProposta1Valor} size="sm"/>
                    </div>
                    <div className="col-sm-4">
                    <Form.Control type="text" readOnly="true" className="form-control" id="originacaoMBProposta1Percentual" value={this.state.originacaoMBProposta1Percentual} size="sm"/>
                    </div>
                  </Form.Group>
                  <Form.Group className="row">
                    <label htmlFor="exampleProposta5" className="col-sm-3 col-form-label">Proposta 2</label>
                    <div className="col-sm-5">
                    <Form.Control type="text" readOnly="true" className="form-control" id="originacaoMBProposta2Valor" value={this.state.originacaoMBProposta2Valor} size="sm" />
                    </div>
                    <div className="col-sm-4">
                    <Form.Control type="text" readOnly="true" className="form-control" id="originacaoMBProposta2Percentual" value={this.state.originacaoMBProposta2Percentual} size="sm"/>
                    </div>
                  </Form.Group>
                  <Form.Group className="row">
                    <label htmlFor="exampleProposta6" className="col-sm-3 col-form-label">Proposta 3</label>
                    <div className="col-sm-5">
                    <Form.Control type="text" readOnly="true" className="form-control" id="originacaoMBProposta3Valor" value={this.state.originacaoMBProposta3Valor} size="sm" />
                    </div>
                    <div className="col-sm-4">
                    <Form.Control type="text" readOnly="true" className="form-control" id="originacaoMBProposta3Percentual" value={this.state.originacaoMBProposta3Percentual} size="sm"/>
                    </div>
                  </Form.Group>

                  <p className="card-description">Resultado Originação Fundo</p>
                  <Form.Group className="row">
                    <label htmlFor="exampleProposta7" className="col-sm-3 col-form-label">Proposta 1</label>
                    <div className="col-sm-5">
                    <Form.Control type="text" readOnly="true" className="form-control" id="originacaoFundoProposta1Valor" value={this.state.originacaoFundoProposta1Valor} size="sm"/>
                    </div>
                    <div className="col-sm-4">
                    <Form.Control type="text" readOnly="true" className="form-control" id="originacaoFundoProposta1Percentual" value={this.state.originacaoFundoProposta1Percentual} size="sm"/>
                    </div>
                  </Form.Group>
                  <Form.Group className="row">
                    <label htmlFor="exampleProposta8" className="col-sm-3 col-form-label">Proposta 2</label>
                    <div className="col-sm-5">
                    <Form.Control type="text" readOnly="true" className="form-control" id="originacaoFundoProposta2Valor" value={this.state.originacaoFundoProposta2Valor} size="sm" />
                    </div>
                    <div className="col-sm-4">
                    <Form.Control type="text" readOnly="true" className="form-control" id="originacaoFundoProposta2Percentual" value={this.state.originacaoFundoProposta2Percentual} size="sm"/>
                    </div>
                  </Form.Group>
                  <Form.Group className="row">
                    <label htmlFor="exampleProposta9" className="col-sm-3 col-form-label">Proposta 3</label>
                    <div className="col-sm-5">
                    <Form.Control type="text" readOnly="true" className="form-control" id="originacaoFundoProposta3Valor" value={this.state.originacaoFundoProposta3Valor} size="sm" />
                    </div>
                    <div className="col-sm-4">
                    <Form.Control type="text" readOnly="true" className="form-control" id="originacaoFundoProposta3Percentual" value={this.state.originacaoFundoProposta3Percentual} size="sm"/>
                    </div>
                  </Form.Group>

                  <p className="card-description">Resultado Originação Fundo Carregamento</p>
                  <Form.Group className="row">
                    <label htmlFor="exampleProposta10" className="col-sm-3 col-form-label">Proposta 1</label>
                    <div className="col-sm-5">
                    <Form.Control type="text" readOnly="true" className="form-control" id="originacaoFundoCarregProposta1Valor" value={this.state.originacaoFundoCarregProposta1Valor} size="sm"/>
                    </div>
                    <div className="col-sm-4">
                    <Form.Control type="text" readOnly="true" className="form-control" id="originacaoFundoCarregProposta1Percentual" value={this.state.originacaoFundoCarregProposta1Percentual} size="sm"/>
                    </div>
                  </Form.Group>
                  <Form.Group className="row">
                    <label htmlFor="exampleProposta11" className="col-sm-3 col-form-label">Proposta 2</label>
                    <div className="col-sm-5">
                    <Form.Control type="text" readOnly="true" className="form-control" id="originacaoFundoCarregProposta2Valor" value={this.state.originacaoFundoCarregProposta2Valor} size="sm" />
                    </div>
                    <div className="col-sm-4">
                    <Form.Control type="text" readOnly="true" className="form-control" id="originacaoFundoCarregProposta2Percentual" value={this.state.originacaoFundoCarregProposta2Percentual} size="sm"/>
                    </div>
                  </Form.Group>
                  <Form.Group className="row">
                    <label htmlFor="exampleProposta12" className="col-sm-3 col-form-label">Proposta 3</label>
                    <div className="col-sm-5">
                    <Form.Control type="text" readOnly="true" className="form-control" id="originacaoFundoCarregProposta3Valor" value={this.state.originacaoFundoCarregProposta3Valor} size="sm" />
                    </div>
                    <div className="col-sm-4">
                    <Form.Control type="text" readOnly="true" className="form-control" id="originacaoFundoCarregProposta3Percentual" value={this.state.originacaoFundoCarregProposta3Percentual} size="sm"/>
                    </div>
                  </Form.Group>

                  <p className="card-description">Resultado Originação ConCash Carregamento</p>
                  <Form.Group className="row">
                    <label htmlFor="exampleProposta13" className="col-sm-3 col-form-label">Proposta 1</label>
                    <div className="col-sm-5">
                    <Form.Control type="text" readOnly="true" className="form-control" id="originacaoConCashCarregProposta1Valor" value={this.state.originacaoConCashCarregProposta1Valor} size="sm"/>
                    </div>
                    <div className="col-sm-4">
                    <Form.Control type="text" readOnly="true" className="form-control" id="originacaoConCashCarregProposta1Percentual" value={this.state.originacaoConCashCarregProposta1Percentual} size="sm"/>
                    </div>
                  </Form.Group>
                  <Form.Group className="row">
                    <label htmlFor="exampleProposta14" className="col-sm-3 col-form-label">Proposta 2</label>
                    <div className="col-sm-5">
                    <Form.Control type="text" readOnly="true" className="form-control" id="originacaoConCashCarregProposta2Valor" value={this.state.originacaoConCashCarregProposta2Valor} size="sm" />
                    </div>
                    <div className="col-sm-4">
                    <Form.Control type="text" readOnly="true" className="form-control" id="originacaoConCashCarregProposta2Percentual" value={this.state.originacaoConCashCarregProposta2Percentual} size="sm"/>
                    </div>
                  </Form.Group>
                  <Form.Group className="row">
                    <label htmlFor="exampleProposta15" className="col-sm-3 col-form-label">Proposta 3</label>
                    <div className="col-sm-5">
                    <Form.Control type="text" readOnly="true" className="form-control" id="originacaoConCashCarregProposta3Valor" value={this.state.originacaoConCashCarregProposta3Valor} size="sm" />
                    </div>
                    <div className="col-sm-4">
                    <Form.Control type="text" readOnly="true" className="form-control" id="originacaoConCashCarregProposta3Percentual" value={this.state.originacaoConCashCarregProposta3Percentual} size="sm"/>
                    </div>
                  </Form.Group>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BasicElements
